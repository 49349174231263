import { format, parse } from 'date-fns';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ErrorMessage, RadioIcon } from '@src/components/atoms';
import { ThemeButton } from '@src/components/molecules';
import Dialog, { blackCircleCloseStyles, smallButtonStyles } from '@src/components/molecules/Dialog';
import TimePicker, { timeDefaultFormat } from '@src/components/molecules/TimePicker';
import { DEFAULT_FNS_DATE_FORMAT } from '@src/libs/constant';
import { getDateTimeInPickerFormat } from '@src/libs/date';
import { THEME } from '@src/libs/theme';
import DayPicker from '../DayPicker';
import Label from '../Label';
import RichEditor from '../RichEditor';
import { TextForm } from '../TextForm';
import RecipientSelect, { RecipientSelectOption } from './RecipientSelect';

export const dateFormat = 'dd/MM/yyyy HH:mm';

export interface FormInformation {
  fans: number[];
  id?: number;
  isDraft: boolean;
  message: string;
  scheduleDate: any;
  sender: string;
  sendNow: boolean;
  subject: string;
  tags: number[];
}

interface FormProps {
  onSubmit: (information: FormInformation) => Promise<void>;
}

const Form = ({ onSubmit }: FormProps) => {
  const [date, setDate] = useState<string>(format(new Date(), DEFAULT_FNS_DATE_FORMAT));
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [time, setTime] = useState<string>('');
  const { t } = useTranslation();
  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    register,
    setValue,
    trigger,
    watch,
  } = useFormContext<FormInformation>();
  const [fans, message, scheduleDate, sendNow, tags] = watch(['fans', 'message', 'scheduleDate', 'sendNow', 'tags']);
  const isScheduleForLater = !sendNow;

  useEffect(() => {
    const selectedDate = parse(scheduleDate, dateFormat, new Date());
    const { date: dateString, time: timeString } = getDateTimeInPickerFormat(selectedDate);

    setDate(dateString);
    setTime(timeString);
  }, [scheduleDate]);

  const onChangeDateTime = (dateString: string, timeString: string) => {
    const parseDate = format(new Date(Date.parse(`${dateString} ${timeString}`)), dateFormat);
    setValue('scheduleDate', parseDate);
  };

  const onClickSubmit = () => {
    if (isScheduleForLater) {
      setValue('isDraft', false);
      handleSubmit(onSubmit)();
    } else {
      trigger().then(isValid => {
        if (isValid) {
          setDialogOpen(true);
        } else {
          handleSubmit(onSubmit)();
        }
      });
    }
  };

  const onClickDialogSendNow = () => {
    setValue('isDraft', false);
    handleSubmit(onSubmit)();
  };

  const onClickSaveDraft = () => {
    setValue('isDraft', true);
    handleSubmit(onSubmit)();
  };

  return (
    <div>
      <Dialog
        cancelButtonProps={{
          styles: smallButtonStyles,
          onClick: () => setDialogOpen(false),
        }}
        closeButtonProps={{
          styles: blackCircleCloseStyles,
        }}
        contentStyles={{ borderRadius: 3, maxWidth: 648 }}
        nextButtonProps={{
          styles: smallButtonStyles,
          text: 'Send Now',
          theme: 'blue',
          onClick: onClickDialogSendNow,
        }}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      >
        <div css={styles.dialogContent}>
          <div>{t('Title.Email Send Now')}</div>
          <div>{`※ ${t('Annotation.Dialog Send Now 1')}`}</div>
          <div>{`※ ${t('Annotation.Dialog Send Now 2')}`}</div>
        </div>
      </Dialog>

      <div css={styles.container}>
        <div css={styles.infoContainer}>
          <InputContainer>
            <StyledTextForm
              error={!!errors.subject}
              placeholder={t('TextForm.Enter Subject')}
              title="Subject"
              {...register('subject')}
            />
            <ErrorMessage message={errors.subject?.message} />
          </InputContainer>

          <InputContainer>
            <RecipientSelect
              error={!!(errors.fans || errors.tags)}
              fanIds={fans}
              tagIds={tags}
              title="To"
              onChange={value => {
                setValue(
                  'fans',
                  (value as RecipientSelectOption[])?.filter(option => option.isFan).map(option => option.id)
                );
                setValue(
                  'tags',
                  (value as RecipientSelectOption[])?.filter(option => !option.isFan).map(option => option.id)
                );
              }}
            />
            {(errors.fans || errors.tags) && <ErrorMessage message={errors.fans?.message || errors.tags?.message} />}
          </InputContainer>

          <InputContainer>
            <StyledTextForm
              error={!!errors.sender}
              placeholder="sample@anymindgroup.com"
              title="From"
              {...register('sender')}
            />
            <ErrorMessage message={errors.sender?.message} />
          </InputContainer>

          <InputContainer>
            <div css={styles.richEditorContainer}>
              <RichEditor title="Message" value={message} onChange={value => setValue('message', value)} />
            </div>
            <ErrorMessage message={errors.message?.message} />
          </InputContainer>

          <div css={styles.deliveryScheduleContainer}>
            <Label css={styles.label} title="Delivery Schedule" />
            <RadioGroup onClick={() => setValue('sendNow', false)}>
              <RadioIcon isChecked={isScheduleForLater} />
              <span>{t('Schedule for later')}</span>
            </RadioGroup>
            <div css={styles.datetimeContainer}>
              <DayPicker
                css={{ width: 150 }}
                disableClear
                disabledRange={{ before: new Date() }}
                numberOfMonths={2}
                value={date}
                onChange={value => onChangeDateTime(value, time)}
              />
              <TimePicker
                use12Hours
                value={moment(time, timeDefaultFormat)}
                onChange={value => onChangeDateTime(date, value.format(timeDefaultFormat))}
              />
            </div>
            <ErrorMessage message={errors.scheduleDate?.message as string} />
            <RadioGroup onClick={() => setValue('sendNow', true)}>
              <RadioIcon isChecked={!isScheduleForLater} />
              <span>{t('Sent Now')}</span>
            </RadioGroup>
            <Annotation>{`※ ${t('Annotation.Sent Now 1')}`}</Annotation>
            <Annotation>{`※ ${t('Annotation.Sent Now 2')}`}</Annotation>
          </div>
        </div>

        <div css={styles.actionContainer}>
          <ThemeButton disabled={isSubmitting} text="Save Draft" width="max-content" onClick={onClickSaveDraft} />
          <ThemeButton
            disabled={isSubmitting}
            text={isScheduleForLater ? 'Schedule' : 'Send Now'}
            theme="blue"
            width="max-content"
            onClick={onClickSubmit}
          />
        </div>
      </div>
    </div>
  );
};

const Annotation = styled.p({
  color: THEME.font.colors.black.main,
  fontSize: THEME.font.sizes.subordinate,
  fontWeight: 500,
});

const InputContainer = styled.div({
  marginBottom: 24,
  width: '100%',
});

const RadioGroup = styled.div({
  alignItems: 'center',
  cursor: 'pointer',
  display: 'flex',
  flexWrap: 'wrap',
  marginBottom: 8,
  marginTop: 16,
  width: 'fit-content',

  '& > span': {
    color: THEME.font.colors.black.main,
    fontSize: THEME.font.sizes.normal,
    marginLeft: 8,
  },
});

const StyledTextForm = styled(TextForm)({
  '& > label': {
    color: THEME.font.colors.black.main,
    fontSize: THEME.font.sizes.normal,
    fontWeight: 600,
  },

  '& input': {
    borderRadius: 3,
    height: 32,
  },
});

const styles = {
  actionContainer: css({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    padding: '16px 24px',

    '& > button:nth-of-type(1)': {
      marginRight: 8,
    },
  }),
  container: css({
    backgroundColor: THEME.colors.white,
  }),
  datetimeContainer: css({
    display: 'flex',
    flexWrap: 'wrap',
    gap: THEME.box.gaps.s,
  }),
  deliveryScheduleContainer: css({
    marginBottom: 8,
  }),
  dialogContent: css({
    '& > div:nth-of-type(1)': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.heading,
      marginBottom: 24,
    },

    '& > div:not(:nth-of-type(1))': {
      color: THEME.font.colors.error,
      fontSize: THEME.font.sizes.normal,
      fontWeight: 500,
    },
  }),
  label: css({
    color: THEME.font.colors.black.main,
    fontSize: THEME.font.sizes.normal,
    fontWeight: 600,
  }),
  infoContainer: css({
    borderBottom: '1px solid #dee5ec',
    padding: 24,
  }),
  richEditorContainer: css({
    backgroundColor: THEME.colors.white,

    '& > div > label': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.normal,
      fontWeight: 600,
      marginBottom: 4,
    },

    '& > div > div > div > div > div:nth-of-type(3)': {
      maxWidth: '50vw',
      width: '100%',
    },

    '& .se-container': {
      border: '1px solid #e4e9ed',
    },

    '& .sun-editor-common': {
      zIndex: 0,
    },

    '& .sun-editor-editable': {
      height: 'auto !important',
      minHeight: '370px !important',
    },
  }),
};

export default Form;
