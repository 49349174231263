import React, { useEffect, ReactElement } from 'react';
import { sendAmplitudeEvent } from '@src/amplitude';
import { CrowdinWidget } from '@src/components/molecules';
import { useAuthSetup } from '@src/components/organisms/AuthSetUp/useAuthSetup';
import { checkSignedIn, removeToken, setToken } from '@src/libs/auth';
import { useAuthData, useDeepCompareEffect, useQueryHelper, useUuumInfluencerView } from '@src/libs/hooks';
import { generatePath, ROUTES } from '@src/shared/routes';
import { SocialAccountType } from '@src/__generated__/globalTypes';

interface PublicRouteProps {
  amplitudeEvent?: string;
  element: ReactElement;
}

const PublicRoute = ({ amplitudeEvent, element }: PublicRouteProps) => {
  const { isYoutubeCmsRevenue } = useAuthData();
  const isSignedIn = checkSignedIn();
  const { setUp } = useAuthSetup();
  const { enqueueSnackbar, navigate, pathname, state, t } = useQueryHelper();
  const { isUuumView } = useUuumInfluencerView();

  // chrome version 113 and above with new feature storage partition
  // this cause popup window cannot share localStorage with parent iframe

  // UUUM application will need popup window for youtube authentication
  // so pass token into state to reset into localStorage
  useDeepCompareEffect(() => {
    const refreshToken = state?.refreshToken;
    const token = state?.token;

    const setAuthentication = async () => {
      try {
        setToken(token, refreshToken);
        await setUp(token);
        navigate(pathname, { replace: true, state: null });
      } catch (err) {
        removeToken();
        enqueueSnackbar(t(err.message), { variant: 'error' });
      }
    };

    if (isUuumView && (refreshToken || token)) {
      setAuthentication();
    }
  }, [isUuumView, state]);

  useEffect(() => {
    if (amplitudeEvent) {
      sendAmplitudeEvent(amplitudeEvent);
    }
  }, [amplitudeEvent]);

  useEffect(() => {
    if (isSignedIn) {
      const path = isUuumView
        ? generatePath(ROUTES.ANALYTICS, undefined, { sm: SocialAccountType.YOUTUBE })
        : isYoutubeCmsRevenue
        ? ROUTES.YOUTUBE_CMS_REVENUE
        : ROUTES.FIND_JOBS;

      // state update on iframe will causing re-render even after react router navigation
      // to prevent this, use setTimeout and window.location.replace
      if (window.location !== window.parent.location) {
        setTimeout(() => {
          window.location.replace(path);
        }, 500);
      } else {
        navigate(path, { replace: true });
      }
    }
  }, [isSignedIn, isUuumView]);

  return (
    <>
      {element}
      <CrowdinWidget />
    </>
  );
};

export default PublicRoute;
