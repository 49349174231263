import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Navigate } from 'react-router-dom';
import * as yup from 'yup';
import { css } from '@emotion/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { eventTypes, sendAmplitudeEvent } from '@src/amplitude';
import { ErrorMessage, Icomoon } from '@src/components/atoms';
import { BackNavigatorV2, ThemeButton } from '@src/components/molecules';
import { SentenceFormV2, TextFormV2 } from '@src/components/shared';
import { useAuthData, useCopy, usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { mainBlack } from '@src/libs/palette';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import { useCreateLineChannelMutation, useGetLinePublicKeyQuery } from '@src/graphql/hooks';
import { ROUTES } from '@src/shared/routes';

interface FormInformation {
  liffEndpointUrl: string;
  liffId: string;
  lineChannelId: string;
  lineChannelSecret: string;
  lineKid: string;
  lineLoginChannelId: string;
  publicKeyId: string;
}

enum Steps {
  CHANNEL_ID_SECRET = 'CHANNEL_ID_SECRET',
  ENDPOINT_URL = 'ENDPOINT_URL',
  KID = 'KID',
  LOGIN_CHANNEL_ID_LIFF_ID = 'LOGIN_CHANNEL_ID_LIFF_ID',
  PUBLIC_KEY = 'PUBLIC_KEY',
}

const AddLine = () => {
  const { limitedLogin } = useAuthData();
  if (limitedLogin) {
    return <Navigate to={ROUTES.MY_PAGE} />;
  }

  const [currStep, setCurrStep] = useState<Steps>(Steps.CHANNEL_ID_SECRET);
  const { handleCopyHasText } = useCopy();
  const { isMobileView } = usePageLayout();
  const { enqueueSnackbar, navigate, t } = useQueryHelper();

  const { nextStep, validationSchema } = (() => {
    switch (currStep) {
      case Steps.CHANNEL_ID_SECRET:
        return {
          nextStep: Steps.PUBLIC_KEY,
          validationSchema: yup.object().shape({
            lineChannelId: yup.string().required('requiredFieldMessage'),
            lineChannelSecret: yup.string().required('requiredFieldMessage'),
          }),
        };
      case Steps.PUBLIC_KEY:
        return {
          nextStep: Steps.KID,
          validationSchema: null,
        };
      case Steps.KID:
        return {
          nextStep: Steps.LOGIN_CHANNEL_ID_LIFF_ID,
          validationSchema: yup.object().shape({
            lineKid: yup.string().required('requiredFieldMessage'),
          }),
        };
      case Steps.LOGIN_CHANNEL_ID_LIFF_ID:
        return {
          nextStep: Steps.ENDPOINT_URL,
          validationSchema: yup.object().shape({
            lineLoginChannelId: yup.string().required('requiredFieldMessage'),
            liffId: yup.string().required('requiredFieldMessage'),
          }),
        };
      default:
        return { nextStep: null, validationSchema: null };
    }
  })();

  const defaultValues = {
    liffEndpointUrl: '',
    liffId: '',
    lineChannelId: '',
    lineChannelSecret: '',
    lineKid: '',
    lineLoginChannelId: '',
    publicKeyId: '',
  };
  const {
    formState: { errors },
    handleSubmit,
    register,
    setValue,
    trigger,
    watch,
  } = useForm<FormInformation>({
    defaultValues,
    ...(validationSchema && { resolver: yupResolver(validationSchema) }),
  });
  const [liffId] = watch(['liffId']);

  const { data, loading } = useGetLinePublicKeyQuery({
    onCompleted: ({ getLinePublicKey }) => {
      setValue('publicKeyId', getLinePublicKey?.id);
    },
  });

  const [createLineChannel, { loading: loadingCreateLineChannel }] = useCreateLineChannelMutation({});

  const onClickNext = () => {
    trigger().then(isValid => {
      if (isValid) {
        if (nextStep) {
          if (currStep === Steps.LOGIN_CHANNEL_ID_LIFF_ID) {
            setValue('liffEndpointUrl', `${origin}/liff/${liffId}`);
          }
          setCurrStep(nextStep);
        } else {
          handleSubmit(onSubmit)();
        }
      }
    });
  };

  const onSubmit = async (values: FormInformation) => {
    try {
      await createLineChannel({
        variables: {
          input: {
            lineChannelId: values.lineChannelId,
            lineChannelSecret: values.lineChannelSecret,
            publicKeyId: Number(values.publicKeyId),
            lineKid: values.lineKid,
            lineLoginChannelId: values.lineLoginChannelId,
            liffId: values.liffId,
          },
        },
      });
      sendAmplitudeEvent(eventTypes.addLine);
      enqueueSnackbar(t('Successfully connected LINE channel'), { variant: 'success' });
      navigate(ROUTES.MY_PAGE_CHANNEL_CONNECT);
    } catch (err) {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    }
  };

  return (
    <div css={styles.container}>
      {!isMobileView && <BackNavigatorV2 backPath={ROUTES.MY_PAGE_CHANNEL_CONNECT} title="LINE Connection" />}
      <div css={styles.contentContainer}>
        <div css={styles.form}>
          <div>
            <div>{t('Select Sales Channel')}</div>
            <Icomoon icon="line-color" size={24} />
            <div>LINE</div>
          </div>

          <div>
            {currStep === Steps.CHANNEL_ID_SECRET ? (
              <>
                <div>
                  <TextFormV2
                    isRequired
                    placeholder={t('TextForm.LINE Channel ID')}
                    title="LINE Channel ID"
                    {...register('lineChannelId')}
                  />
                  <ErrorMessage message={errors.lineChannelId?.message} />
                </div>

                <div>
                  <TextFormV2
                    isRequired
                    placeholder={t('TextForm.LINE Channel secret')}
                    title="LINE Channel secret"
                    {...register('lineChannelSecret')}
                  />
                  <ErrorMessage message={errors.lineChannelSecret?.message} />
                </div>
              </>
            ) : currStep === Steps.PUBLIC_KEY ? (
              <>
                <div>
                  <SentenceFormV2
                    css={styles.sentenceForm}
                    placeholder=""
                    readOnly
                    title="Public Key"
                    value={data?.getLinePublicKey?.publicKey}
                  />
                </div>
                <ThemeButton text="Copy" onClick={() => handleCopyHasText(data?.getLinePublicKey?.publicKey || '')} />
              </>
            ) : currStep === Steps.KID ? (
              <div>
                <TextFormV2 isRequired placeholder={t('TextForm.Kid')} title="Kid" {...register('lineKid')} />
                <ErrorMessage message={errors.lineKid?.message} />
              </div>
            ) : currStep === Steps.LOGIN_CHANNEL_ID_LIFF_ID ? (
              <>
                <div>
                  <TextFormV2
                    isRequired
                    placeholder={t('TextForm.LINE Login Channel ID')}
                    title="LINE Login Channel ID"
                    {...register('lineLoginChannelId')}
                  />
                  <ErrorMessage message={errors.lineLoginChannelId?.message} />
                </div>

                <div>
                  <TextFormV2 isRequired placeholder={t('TextForm.LIFF ID')} title="LIFF ID" {...register('liffId')} />
                  <ErrorMessage message={errors.liffId?.message} />
                </div>
              </>
            ) : (
              <TextFormV2 title="LIFF endpoint URL" readOnly {...register('liffEndpointUrl')} />
            )}
            <ThemeButton
              css={styles.nextBtn}
              customPalette={mainBlack}
              disabled={loading || loadingCreateLineChannel}
              text={!nextStep ? 'Save' : 'Next'}
              onClick={onClickNext}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: css({
    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      padding: 24,
    },
  }),
  contentContainer: css({
    padding: '32px 16px',

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      padding: 24,
    },
  }),
  form: css({
    background: THEME.colors.white,
    borderRadius: 15,

    '& > div:nth-of-type(1)': {
      alignItems: 'center',
      borderBottom: '1px solid #eef3f7',
      color: THEME.font.colors.black.main,
      display: 'flex',
      fontSize: THEME.font.sizes.normal,
      fontWeight: 600,
      gap: THEME.box.gaps.s,
      padding: '24px 16px',

      [`@media (min-width: ${ViewportType.TABLET}px)`]: {
        fontSize: THEME.font.sizes.subHeading,
      },

      '& > div:nth-of-type(1)': {
        flex: 1,
      },

      '& > div:nth-of-type(2)': {
        fontSize: THEME.font.sizes.subHeading,
      },
    },

    '& > div:nth-of-type(2)': {
      display: 'grid',
      gap: THEME.box.gaps.xxl,
      padding: '24px 16px',
    },
  }),
  nextBtn: css({
    borderRadius: 9,
    height: 56,

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      height: 48,
    },

    '& span': {
      fontSize: THEME.font.sizes.subHeading,
      fontWeight: 600,
    },
  }),
  sentenceForm: css({
    '& > textarea': {
      height: 66,
    },
  }),
};

export default AddLine;
