import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { THEME } from '@src/libs/theme';
import { EmailTemplateStatus } from '@src/__generated__/globalTypes';

interface StatusIndicatorProps {
  status: EmailTemplateStatus;
}

const EmailStatusIndicator = ({ status }: StatusIndicatorProps) => {
  const { t } = useTranslation();
  const color =
    status === EmailTemplateStatus.DRAFT ? '#fff0d8' : status === EmailTemplateStatus.PUBLISHED ? '#d9f1e5' : '#eae1f5';

  return <Container color={color}>{t(status)}</Container>;
};

const Container = styled.div<{ color: string }>(({ color }) => ({
  alignItems: 'center',
  backgroundColor: color,
  borderRadius: 35,
  color: THEME.font.colors.black.main,
  display: 'flex',
  fontSize: THEME.font.sizes.subordinate,
  height: 24,
  justifyContent: 'center',
  padding: '0 8px',
  width: 'fit-content',
}));

export default EmailStatusIndicator;
