import { format } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import { Icomoon, Icon, TextCutter } from '@src/components/atoms';
import { BackNavigatorV2, ListLoading } from '@src/components/molecules';
import { useInfluencerPaymentRequestsQuery, useInfluencerProfileV2Query } from '@src/graphql/hooks';
import { CURRENCY_SYMBOLS, LIMIT } from '@src/libs/constant';
import { formatNumberWithCommas } from '@src/libs/format';
import { useAuthData, usePageLayout } from '@src/libs/hooks';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import { generatePath, ROUTES } from '@src/shared/routes';
import { CampaignType } from '@src/__generated__/globalTypes';
import PaymentStatus from './PaymentStatus';

const PaymentHistory = () => {
  const { t } = useTranslation();
  const { isAvailableCountry, userId } = useAuthData();
  const { isMobileView } = usePageLayout();
  const { data: dataPaymentRequests, loading: loadingPaymentRequests } = useInfluencerPaymentRequestsQuery({
    skip: !isAvailableCountry,
    variables: {
      limit: LIMIT,
      offset: 0,
    },
  });
  const { data: dataInfluencerProfile, loading: loadingInfluencerProfile } = useInfluencerProfileV2Query({
    variables: {
      pk: Number(userId),
    },
  });

  const paymentRequests = dataPaymentRequests?.influencerPaymentRequests.paymentRequests || [];
  const isJPInfluencer = dataInfluencerProfile?.influencerProfileV2?.country.id === 'JP';

  return (
    <div css={{ padding: '24px 16px' }}>
      {!isMobileView && <BackNavigatorV2 backPath={ROUTES.MY_PAGE} title="Payment History" />}
      <div css={styles.contentContainer}>
        {loadingInfluencerProfile || loadingPaymentRequests ? (
          <ListLoading />
        ) : paymentRequests.length ? (
          <div css={styles.cardsContainer}>
            {paymentRequests.map(
              ({
                campaigns,
                consumptionTax,
                currency,
                dueDate,
                fee,
                grossRevenue,
                id,
                netRevenue,
                paidDate,
                requestedDate,
                status,
                withholdingFee,
              }) => {
                const currencySymbol = CURRENCY_SYMBOLS[currency] || '';

                return (
                  <div css={styles.card} key={id}>
                    <div>
                      <div>{t('Payment Status')}</div>
                      <PaymentStatus status={status} />
                    </div>

                    <div>
                      <div>
                        <div>{t('HeaderColumn.Finalized Date')}</div>
                        <div>{requestedDate ? format(new Date(requestedDate), 'MMM dd, yyyy') : '-'}</div>
                      </div>
                      <div>
                        <div>{t('HeaderColumn.Due Date')}</div>
                        <div>{dueDate ? format(new Date(dueDate), 'MMM dd, yyyy') : '-'}</div>
                      </div>
                      <div>
                        <div>{t('HeaderColumn.Paid Date')}</div>
                        <div>{paidDate ? format(new Date(paidDate), 'MMM dd, yyyy') : '-'}</div>
                      </div>
                    </div>

                    <div>
                      {campaigns?.map(({ campaignType, id: campaignId, revenue, title }) => (
                        <div key={campaignId}>
                          <Link
                            to={generatePath(
                              campaignType === CampaignType.AUTO_MANAGED
                                ? ROUTES.MY_JOBS_AUTO_MANAGED_ID
                                : campaignType === CampaignType.ENGAGEMENT
                                ? ROUTES.MY_JOBS_CASTING_YOUR_JOB_ID
                                : campaignType === CampaignType.TIKTOK_SPECIAL
                                ? ROUTES.MY_JOBS_TIKTOK_SPECIAL_ID
                                : ROUTES.MY_JOBS_MARKETPLACE_ID,
                              { id: campaignId }
                            )}
                          >
                            <TextCutter lines={2} text={title} />
                            <Icon>
                              <Icomoon css={{ transform: 'rotate(270deg)' }} icon="arrow-down" size={8} />
                            </Icon>
                          </Link>
                          <div>{`${currencySymbol}${formatNumberWithCommas(revenue, undefined, currency)}`}</div>
                        </div>
                      ))}
                    </div>

                    <div>
                      <div>
                        <div>{t('Column.SubTotal')}</div>
                        <div>
                          {grossRevenue
                            ? `${currencySymbol}${formatNumberWithCommas(grossRevenue, undefined, currency)}`
                            : '-'}
                        </div>
                      </div>
                      {isJPInfluencer && (
                        <div>
                          <div>{t('Column.Consumption Tax')}</div>
                          <div>
                            {consumptionTax
                              ? `${currencySymbol}${formatNumberWithCommas(consumptionTax, undefined, currency)}`
                              : '-'}
                          </div>
                        </div>
                      )}
                      <div>
                        <div>{t('Column.Withholding fee')}</div>
                        <div>
                          {withholdingFee
                            ? `${currencySymbol}${formatNumberWithCommas(withholdingFee, undefined, currency)}`
                            : '-'}
                        </div>
                      </div>
                      <div>
                        <div>{t('Column.Transfer fee')}</div>
                        <div>{fee ? `${currencySymbol}${formatNumberWithCommas(fee, undefined, currency)}` : '-'}</div>
                      </div>
                    </div>

                    <div>
                      <div>{t('Payment Amount')}</div>
                      <div>{`${currencySymbol}${formatNumberWithCommas(netRevenue, undefined, currency)}`}</div>
                    </div>
                  </div>
                );
              }
            )}
          </div>
        ) : (
          <div css={styles.emptyPaymentHistory}>
            <div>{t('No payment history yet')}</div>
          </div>
        )}
      </div>
    </div>
  );
};

const styles = {
  card: css({
    background: THEME.colors.white,
    borderRadius: 15,
    boxShadow: THEME.box.shadows.outer,
    display: 'grid',
    gap: THEME.box.gaps.l,
    padding: 16,

    '& > div:nth-of-type(1)': {
      alignItems: 'center',
      display: 'flex',

      '& > div:nth-of-type(1)': {
        color: THEME.font.colors.black.main,
        fontSize: THEME.font.sizes.subHeading,
        fontWeight: 600,
        width: 'fill-available',
      },
    },

    '& > div:nth-of-type(2)': {
      background: '#eef3f7',
      border: '1px solid #eef3f7',
      borderRadius: 10,
      display: 'flex',
      gap: 1,
      overflow: 'hidden',

      '& > div': {
        background: THEME.colors.white,
        color: THEME.font.colors.black.main,
        display: 'grid',
        gap: THEME.box.gaps.s,
        fontSize: THEME.font.sizes.subordinate,
        padding: '14px 0',
        textAlign: 'center',
        width: 'fill-available',

        '& > div:nth-of-type(1)': {
          [`@media (min-width: ${ViewportType.TABLET}px)`]: {
            fontSize: THEME.font.sizes.hint,
          },
        },

        '& > div:nth-of-type(2)': {
          fontWeight: 600,
        },
      },
    },

    '& > div:nth-of-type(3)': {
      borderBottom: '1px solid #eef3f7',
      display: 'grid',
      gap: THEME.box.gaps.l,
      paddingBottom: 16,

      '& > div': {
        alignItems: 'center',
        color: THEME.font.colors.black.main,
        display: 'flex',
        gap: 18,
        maxWidth: '100%',
        overflow: 'hidden',
        position: 'relative',

        '& > a': {
          display: 'flex',
          fontSize: THEME.font.sizes.subordinate,
          gap: THEME.box.gaps.s,
          width: 'fill-available',
        },

        '& > div': {
          fontSize: THEME.font.sizes.normal,
          fontWeight: 600,
        },
      },
    },

    '& > div:nth-of-type(4)': {
      borderBottom: '1px solid #eef3f7',
      display: 'grid',
      gap: THEME.box.gaps.l,
      padding: '16px 0',

      '& > div': {
        alignItems: 'center',
        color: THEME.font.colors.black.main,
        display: 'flex',
        fontSize: THEME.font.sizes.normal,
        gap: THEME.box.gaps.s,

        '& > div:nth-of-type(1)': {
          width: 'fill-available',
        },
      },
    },

    '& > div:nth-of-type(5)': {
      alignItems: 'center',
      color: THEME.font.colors.black.main,
      display: 'flex',
      fontSize: THEME.font.sizes.subTitle,
      fontWeight: 600,
      gap: THEME.box.gaps.s,

      '& > div:nth-of-type(1)': {
        fontSize: THEME.font.sizes.normal,
        width: 'fill-available',
      },
    },
  }),
  cardsContainer: css({
    display: 'grid',
    gap: THEME.box.gaps.m,

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      gap: THEME.box.gaps.xxl,
    },
  }),
  contentContainer: css({
    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      padding: 24,
    },
  }),
  emptyPaymentHistory: css({
    color: THEME.font.colors.gray.main,
    display: 'flex',
    fontSize: THEME.font.sizes.normal,
    justifyContent: 'center',
    paddingTop: 24,
  }),
};

export default PaymentHistory;
