import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  CheckBox,
  ErrorMessage,
  // RadioIcon,
} from '@src/components/atoms';
import { ThemeButton } from '@src/components/molecules';
import { QuestionType } from '@src/__generated__/globalTypes';
import { usePageLayout } from '@src/libs/hooks';
import { THEME } from '@src/libs/theme';
import Label from '../Label';
import { Select } from '../Select';
import { TextForm } from '../TextForm';

declare const RECAPTCHA_SITE_KEY: string;

interface Questions {
  answeredOptionIds: string[];
  answeredTexts: string[];
  image: string | null;
  isRequired: boolean;
  options: {
    id: string;
    optionTitle: string;
  }[];
  questionId: string;
  questionTitle: string;
  questionType: QuestionType;
}

export interface SubmitFormInformation {
  id: string;
  questions: Questions[];
  recaptchaResponse: string;
}

interface FormSubmitProps {
  description: string;
  formValues: SubmitFormInformation;
  hash?: string;
  isMobileMode?: boolean;
  title: string;
  onSubmit: (information: SubmitFormInformation) => Promise<void>;
}

const Form = ({ description, formValues, hash, isMobileMode, title, onSubmit }: FormSubmitProps) => {
  const { t } = useTranslation();
  const { isMobileView, isSmallView } = usePageLayout();
  const {
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useFormContext<SubmitFormInformation>();
  const [formId, questions] = watch(['id', 'questions']);

  useEffect(() => {
    if (hash) {
      const script = document.createElement('script');
      script.src = `https://www.google.com/recaptcha/enterprise.js?render=${RECAPTCHA_SITE_KEY}`;
      script.async = true;
      document.body.appendChild(script);
    }
  }, []);

  const onChangeTexts = (index: number, value: string, textIndex: number) => {
    const items = [...questions];
    items[index].answeredTexts[textIndex] = value;

    setValue('questions', items);
  };

  const onClickCheckbox = (index: number, checked: boolean, id: string) => {
    const items = [...questions];
    const selectedIds = items[index].answeredOptionIds;
    if (checked) {
      const selectedIndex = selectedIds.findIndex(questionId => questionId === id);
      selectedIds.splice(selectedIndex, 1);
    } else {
      selectedIds.push(id);
    }

    setValue('questions', items);
  };

  const onClickCheckBoxDropdown = (index: number, id: string) => {
    const items = [...questions];
    items[index].answeredOptionIds = [id];

    setValue('questions', items);
  };

  const onSubmitWithRecaptcha = async (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e?.preventDefault();
    if (hash) {
      const token = await validateRecaptcha();
      setValue('recaptchaResponse', token as string);
    }
    handleSubmit(onSubmit)();
  };

  const validateRecaptcha = () =>
    new Promise(resolve => {
      // eslint-disable-next-line no-undef
      grecaptcha.enterprise.ready(() => {
        // eslint-disable-next-line no-undef
        grecaptcha.enterprise.execute(RECAPTCHA_SITE_KEY, { action: 'form_submit' }).then((token: string) => {
          resolve(token);
        });
      });
    });
  const infoWidth = isSmallView || isMobileMode ? '100%' : isMobileView ? '83%' : '65%';
  const nameInputWidth = isMobileView || isMobileMode ? '100%' : '50%';

  return (
    <div>
      <div css={styles.headerContainer}>
        <div css={{ width: infoWidth }}>
          <div>{title}</div>
          <div>{description}</div>
        </div>
      </div>
      <div css={styles.container}>
        <div css={{ width: infoWidth }}>
          <div css={styles.formContainer}>
            {formValues.questions.map((value, index) => {
              const { image, isRequired, options, questionId, questionType, questionTitle } = value;
              const idsInput = questions[index]?.answeredOptionIds;
              const firstTextInput = questions[index]?.answeredTexts[0];
              const secondTextInput = questions[index]?.answeredTexts[1];

              // errors possible content questions.answeredTexts with message or message[]
              const { isInvalidEmail, isRequiredEmail } = (
                errors?.questions as unknown as {
                  answeredTexts?: { message: string } | { message: string }[];
                }[]
              )?.reduce(
                (obj, val) => {
                  const isArray = Array.isArray(val.answeredTexts);

                  return {
                    ...obj,
                    ...(isArray &&
                    (val.answeredTexts as { message: string }[])?.find(
                      validation => validation.message === 'invalidEmailMessage'
                    )
                      ? { isInvalidEmail: true }
                      : {}),
                    ...((!isArray && (val.answeredTexts as { message: string })?.message === 'requiredEmailMessage') ||
                    (isArray &&
                      (val.answeredTexts as { message: string }[])?.find(
                        validation => validation.message === 'requiredEmailMessage'
                      ))
                      ? { isRequiredEmail: true }
                      : {}),
                  };
                },
                { isInvalidEmail: false, isRequiredEmail: false }
              ) || { isInvalidEmail: false, isRequiredEmail: false };

              return questionType === QuestionType.NAME ? (
                <NameInputContainer isMobileMode={isMobileMode || isMobileView} key={questionId}>
                  <div css={{ width: nameInputWidth }}>
                    <StyledTextForm
                      error={!!errors.questions && !firstTextInput}
                      isRequired={isRequired}
                      placeholder={t('TextForm.First Name')}
                      title="First Name"
                      value={firstTextInput || ''}
                      onChange={e => onChangeTexts(index, e.target.value, 0)}
                    />
                    {!!errors.questions && isRequired && !firstTextInput && (
                      <ErrorMessage message={'requiredFirstNameMessage'} />
                    )}
                  </div>
                  <div css={{ width: nameInputWidth }}>
                    <div>
                      <StyledTextForm
                        error={!!errors.questions && !secondTextInput}
                        isRequired={isRequired}
                        placeholder={t('TextForm.Last Name')}
                        title="Last Name"
                        value={secondTextInput || ''}
                        onChange={e => onChangeTexts(index, e.target.value, 1)}
                      />
                      {!!errors.questions && isRequired && !secondTextInput && (
                        <ErrorMessage message={'requiredLastNameMessage'} />
                      )}
                    </div>
                  </div>
                </NameInputContainer>
              ) : questionType === QuestionType.EMAIL ? (
                <InputContainer key={questionId}>
                  <StyledTextForm
                    error={isInvalidEmail || isRequiredEmail}
                    isRequired={isRequired}
                    placeholder="sample@example.com"
                    title="Email Address"
                    value={firstTextInput || ''}
                    onChange={e => onChangeTexts(index, e.target.value, 0)}
                  />
                  {(isInvalidEmail || isRequiredEmail) && (
                    <ErrorMessage message={isInvalidEmail ? 'invalidEmailMessage' : 'requiredEmailMessage'} />
                  )}
                </InputContainer>
              ) : questionType === QuestionType.CHECKBOX ? (
                <InputContainer key={questionId}>
                  <StyledLabel isRequired={isRequired} title={questionTitle} />
                  {image && (
                    <InputImgContainer>
                      <img alt="inputImg" src={image} />
                    </InputImgContainer>
                  )}
                  {/* TODO:??? */}
                  {options.map(({ id: optionId, optionTitle }) => (
                    <CheckBox
                      css={styles.checkbox}
                      key={optionId}
                      label={optionTitle}
                      checked={idsInput ? idsInput.includes(optionId) : false}
                      onChange={checked => onClickCheckbox(index, !checked, optionId)}
                    />
                  ))}
                  {!!errors.questions && isRequired && idsInput.length <= 0 && (
                    <ErrorMessage message={'requiredOptionMessage'} />
                  )}
                </InputContainer>
              ) : questionType === QuestionType.DROPDOWN ? (
                <InputContainer key={questionId}>
                  <DropdownContainer isMobileMode={isMobileMode || isMobileView}>
                    <StyledLabel isRequired={isRequired} title={questionTitle} />
                    {image && (
                      <InputImgContainer>
                        <img alt="inputImg" src={image} />
                      </InputImgContainer>
                    )}
                    <Select
                      error={!!errors.questions && isRequired && idsInput.length <= 0}
                      isRequired={isRequired}
                      options={options.map(({ id, optionTitle }) => ({ label: optionTitle, value: id }))}
                      value={idsInput ? idsInput[0] : ''}
                      onChange={val => onClickCheckBoxDropdown(index, val)}
                    />
                  </DropdownContainer>
                  {!!errors.questions && isRequired && idsInput.length <= 0 && (
                    <ErrorMessage message={'requiredOptionMessage'} />
                  )}
                </InputContainer>
              ) : // applicable for radio group in future
              // : questionType === QuestionType.DROPDOWN ? (
              //   <InputContainer key={questionId}>
              //     <StyledLabel isRequired={isRequired} title={questionTitle} />
              //     {options.map(option => {
              //       const { id: optionId, optionTitle } = option;

              //       return (
              //         <div css={styles.radioContainer} key={optionId}>
              //           <RadioIcon
              //             isChecked={idsInput ? idsInput.includes(optionId) : false}
              //             onClick={() => onClickCheckBoxDropdown(index, optionId)}
              //           />
              //           <span>{optionTitle}</span>
              //         </div>
              //       );
              //     })}
              //     {!!questionsMeta.error && isRequired && idsInput.length <= 0 && (
              //       <ErrorMessage message={t('requiredOptionMessage')} />
              //     )}
              //   </InputContainer>
              // )
              questionType === QuestionType.SHORT_ANSWER ? (
                <InputContainer key={questionId}>
                  <ShortAnswerContainer isMobileMode={isMobileMode || isMobileView}>
                    <StyledLabel isRequired={isRequired} title={questionTitle} />
                    {image && (
                      <InputImgContainer>
                        <img alt="inputImg" src={image} />
                      </InputImgContainer>
                    )}
                    <StyledTextForm
                      error={!!errors.questions && isRequired && !firstTextInput}
                      isRequired={isRequired}
                      placeholder=""
                      value={firstTextInput || ''}
                      onChange={e => onChangeTexts(index, e.target.value, 0)}
                    />
                  </ShortAnswerContainer>
                  {!!errors.questions && isRequired && !firstTextInput && (
                    <ErrorMessage message={'requiredFieldMessage'} />
                  )}
                </InputContainer>
              ) : null;
            })}
            <div css={styles.actionContainer}>
              <ThemeButton
                theme="blue"
                disabled={!formId}
                text="Submit"
                width="max-content"
                onClick={e => onSubmitWithRecaptcha(e as React.MouseEvent<HTMLButtonElement, MouseEvent>)}
                {...((isMobileMode || isMobileView) && {
                  width: '100%',
                  size: 'large',
                })}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const DropdownContainer = styled.div<{ isMobileMode: boolean }>(({ isMobileMode }) => ({
  width: isMobileMode ? '100%' : '40%',
}));

const InputContainer = styled.div({
  borderBottom: '1px solid #dee5ec',
  marginBottom: 24,
  paddingBottom: 24,
  width: '100%',
});

const InputImgContainer = styled.div({
  backgroundColor: '#000',
  margin: '8px 0',
  width: 213,

  '& > img': {
    height: 120,
    objectFit: 'contain',
    width: '100%',
  },
});

const NameInputContainer = styled.div<{ isMobileMode?: boolean }>(({ isMobileMode }) => ({
  borderBottom: '1px solid #dee5ec',
  display: 'flex',
  flexBasis: '100%',
  flexWrap: 'wrap',
  marginBottom: 32,
  paddingBottom: 24,

  '& > div:nth-of-type(1)': {
    '& > div': {
      ...(!isMobileMode && { marginRight: 8 }),
    },
  },

  '& > div:nth-of-type(2)': {
    '& > div': {
      ...(!isMobileMode ? { marginLeft: 8 } : { marginTop: 24 }),
    },
  },
}));

const ShortAnswerContainer = styled.div<{ isMobileMode: boolean }>(({ isMobileMode }) => ({
  width: isMobileMode ? '100%' : '50%',
}));

const StyledLabel = styled(Label)({
  color: THEME.font.colors.black.main,
  fontSize: THEME.font.sizes.normal,
  fontWeight: 600,
});

const StyledTextForm = styled(TextForm)({
  '& > label': {
    color: THEME.font.colors.black.main,
    fontSize: THEME.font.sizes.normal,
    fontWeight: 600,
  },

  '& input': {
    borderRadius: 3,
    height: 32,
  },
});

const styles = {
  actionContainer: css({
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
  }),
  checkbox: css({
    padding: '10px 10px 10px 0',

    '& > label': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.normal,
    },
  }),
  container: css({
    backgroundColor: THEME.colors.white,
    display: 'flex',
    justifyContent: 'center',
    padding: '24px 32px',
  }),
  formContainer: css({
    display: 'flex',
    flexWrap: 'wrap',
  }),
  headerContainer: css({
    display: 'flex',
    justifyContent: 'center',
    padding: '0 32px',

    '& > div': {
      '& > div:nth-of-type(1)': {
        color: THEME.font.colors.black.main,
        fontSize: THEME.font.sizes.heading,
        fontWeight: 600,
        marginBottom: 16,
      },

      '& > div:nth-of-type(2)': {
        color: THEME.font.colors.gray.main,
        fontSize: THEME.font.sizes.normal,
        marginBottom: 16,
      },
    },
  }),
  radioContainer: css({
    display: 'flex',
    flexWrap: 'wrap',
    padding: '10px 10px 10px 0',

    '& > span': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.normal,
      marginLeft: 8,
    },
  }),
};

export default Form;
