import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { yupResolver } from '@hookform/resolvers/yup';
import anyCreatorLogo from '@src/assets/img/anyCreatorBlack.png';
import formThanksPage from '@src/assets/img/forms/thanksPage.png';
import { useSubmitFormInternalMutation, useSubmitFormMutation } from '@src/graphql/hooks';
import { useDeepCompareEffect, useQueryHelper } from '@src/libs/hooks';
import { THEME } from '@src/libs/theme';
import { QuestionType } from '@src/__generated__/globalTypes';
import { ViewportType } from '@src/libs/types';
import Form, { SubmitFormInformation } from './Form';

interface Options {
  id: string;
  optionTitle: string;
  order: number;
}

interface Questions {
  id: string;
  image?: string | null;
  isRequired: boolean;
  options: Options[];
  order: number;
  questionType: QuestionType;
  title: string;
}

interface IndexProps {
  description: string;
  hash?: string;
  id: string;
  isMobileMode?: boolean;
  questions: Questions[];
  thankDescription: string;
  thankTitle: string;
  title: string;
}

const Index = ({ description, hash, id, isMobileMode, questions, thankDescription, thankTitle, title }: IndexProps) => {
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const { enqueueSnackbar, t } = useQueryHelper();

  const initialValues = {
    id,
    questions: questions.map(question => {
      const { id: questionId, image, isRequired, options, questionType, title: questionTitle } = question;

      return {
        answeredOptionIds: [],
        answeredTexts: [],
        questionId,
        image: image || '',
        isRequired,
        options,
        questionTitle,
        questionType,
      };
    }),
    recaptchaResponse: '',
  };

  const validationSchema = yup.object().shape({
    questions: yup.array().of(
      yup.object().shape(
        {
          answeredOptionIds: yup.array().when(['isRequired', 'questionType'], {
            is: (isRequired: boolean, questionType: QuestionType) =>
              isRequired && [QuestionType.CHECKBOX, QuestionType.DROPDOWN].includes(questionType),
            then: yup.array().min(1, 'requiredOptionMessage'),
          }),
          answeredTexts: yup
            .array()
            .when(['isRequired', 'questionType'], {
              is: (isRequired: boolean, questionType: QuestionType) =>
                isRequired && questionType === QuestionType.EMAIL,
              then: yup.array().min(1, 'requiredEmailMessage').of(yup.string().required('requiredEmailMessage')),
            })
            .when(['answeredTexts', 'questionType'], {
              is: (answeredTexts: string[], questionType: QuestionType) =>
                questionType === QuestionType.EMAIL && answeredTexts?.length > 0,
              then: yup.array().of(yup.string().email('invalidEmailMessage')),
            })
            .when(['isRequired', 'questionType'], {
              is: (isRequired: boolean, questionType: QuestionType) => isRequired && questionType === QuestionType.NAME,
              then: yup.array().min(2, 'requiredNameMessage'),
            })
            .when(['isRequired', 'questionType'], {
              is: (isRequired: boolean, questionType: QuestionType) =>
                isRequired && questionType === QuestionType.SHORT_ANSWER,
              then: yup.array().min(1, 'requiredFieldMessage'),
            }),
        },
        [['answeredTexts', 'answeredTexts']]
      )
    ),
  });

  const methods = useForm<SubmitFormInformation>({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  useDeepCompareEffect(() => {
    methods.reset(initialValues);
  }, [initialValues]);

  const [submitForm] = useSubmitFormMutation({
    onCompleted: () => {
      enqueueSnackbar(t('succeededInCreating'), { variant: 'success' });
      setIsSubmitted(true);
    },
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  const [executeSubmitFormInternal] = useSubmitFormInternalMutation({
    onCompleted: () => {
      enqueueSnackbar(t('succeededInCreating'), { variant: 'success' });
      setIsSubmitted(true);
    },
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  const onSubmit = async (values: SubmitFormInformation) => {
    const input = {
      id: values.id,
      questions: values.questions.map(question => ({
        answeredOptionIds: question.answeredOptionIds.map(optionId => Number(optionId)),
        answeredTexts: question.answeredTexts.map(text => text.trim()),
        questionId: question.questionId,
      })),
    };
    if (hash) {
      await submitForm({
        variables: {
          input: {
            ...input,
            recaptchaResponse: values.recaptchaResponse,
          },
        },
      });
    } else {
      await executeSubmitFormInternal({
        variables: {
          input,
        },
      });
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        {isSubmitted ? (
          <div css={styles.thanksContainer}>
            <ThanksContent isMobileMode={!!isMobileMode}>
              <div>
                <img alt="thanksImage" height="120" src={formThanksPage} width="180" />
              </div>
              <div>{thankTitle}</div>
              <div>{thankDescription}</div>
            </ThanksContent>
          </div>
        ) : (
          <div style={hash ? { paddingTop: 64 } : {}}>
            <Form
              description={description}
              hash={hash}
              formValues={initialValues}
              isMobileMode={isMobileMode}
              title={title}
              onSubmit={onSubmit}
            />
          </div>
        )}
        <div css={styles.footer}>
          <div>{t('Powered by')}</div>
          <Link target="_blank" to={'https://any-creator.com/'}>
            <img alt="logo" height="32" src={anyCreatorLogo} width="151" />
          </Link>
        </div>
      </form>
    </FormProvider>
  );
};

const ThanksContent = styled.div<{ isMobileMode: boolean }>(({ isMobileMode }) => ({
  maxWidth: isMobileMode ? 295 : 440,
  width: '100%',
}));

const styles = {
  footer: css({
    display: 'grid',
    justifyContent: 'center',
    marginTop: 65,
    textAlign: 'center',

    '& > div:nth-of-type(1)': {
      color: '#000',
      fontSize: THEME.font.sizes.normal,
      marginBottom: 8,
    },
  }),
  thanksContainer: css({
    alignItems: 'center',
    backgroundColor: THEME.colors.white,
    display: 'flex',
    height: 747,
    justifyContent: 'center',
    padding: '0 16px 0 16px',

    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      height: 540,
    },

    '& > div': {
      display: 'grid',

      '& > div:nth-of-type(1)': {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 24,
      },

      '& > div:nth-of-type(2)': {
        color: THEME.font.colors.black.main,
        display: 'flex',
        fontSize: THEME.font.sizes.subHeading,
        fontWeight: 600,
        justifyContent: 'center',
        marginBottom: 16,
      },

      '& > div:nth-of-type(3)': {
        color: THEME.font.colors.black.main,
        display: 'flex',
        fontSize: THEME.font.sizes.normal,
        justifyContent: 'center',
      },
    },
  }),
};

export default Index;
