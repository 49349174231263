import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Navigate } from 'react-router-dom';
import { css } from '@emotion/react';
import { Icomoon, Icon } from '@src/components/atoms';
import { BackNavigatorV2 } from '@src/components/molecules';
import { useAuthData, usePageLayout } from '@src/libs/hooks';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import { ROUTES } from '@src/shared/routes';

const Index = () => {
  const { limitedLogin } = useAuthData();
  if (limitedLogin) {
    return <Navigate to={ROUTES.MY_PAGE} />;
  }

  const { t } = useTranslation();
  const { isMobileView } = usePageLayout();
  const menu = [
    { icon: 'chats-grey-light', title: 'Chat', to: ROUTES.MY_PAGE_MESSAGE_CHAT },
    { icon: 'broadcast-grey-light', title: 'Broadcast for LINE', to: ROUTES.MY_PAGE_MESSAGE_BROADCAST },
  ];

  return (
    <div css={{ padding: '24px 16px' }}>
      {!isMobileView && <BackNavigatorV2 backPath={ROUTES.MY_PAGE} css={{ marginBottom: 24 }} title="Message" />}

      <div css={styles.menuContainer}>
        {menu.map(({ icon, title, to }) => (
          <Link key={title} to={to}>
            <Icomoon icon={icon} size={20} />
            <div>{t(title)}</div>
            <Icon>
              <Icomoon css={{ transform: 'rotate(270deg)' }} icon="arrow-down" size={8} />
            </Icon>
          </Link>
        ))}
      </div>
    </div>
  );
};

const styles = {
  menuContainer: css({
    background: '#eef3f7',
    borderRadius: 15,
    display: 'grid',
    gap: 1,
    overflow: 'hidden',

    '& > a': {
      alignItems: 'center',
      background: THEME.colors.white,
      display: 'flex',
      gap: THEME.box.gaps.s,
      padding: '16px 12px',

      '& > div': {
        color: THEME.font.colors.black.main,
        fontSize: THEME.font.sizes.subHeading,
        fontWeight: 600,
        width: 'fill-available',

        [`@media (min-width: ${ViewportType.TABLET}px)`]: {
          fontSize: THEME.font.sizes.normal,
        },
      },

      '& > span': {
        alignItems: 'center',
        display: 'flex',
        height: 18,
        justifyContent: 'center',
        width: 18,
      },
    },
  }),
};

export default Index;
