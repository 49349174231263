import { format } from 'date-fns';
import React from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { BroadcastForm } from '@src/components/shared';
import { dateFormat } from '@src/components/shared/BroadcastForm/FormInput/Inputs/helpers';
import { useGetLineBroadcastMessageQuery } from '@src/graphql/hooks';
import { useAuthData } from '@src/libs/hooks';
import { ROUTES } from '@src/shared/routes';
import { DeliveryTimingType, LineBroadcastStatus, FontSize, RecipientType } from '@src/__generated__/globalTypes';

const Edit = () => {
  const { limitedLogin } = useAuthData();
  if (limitedLogin) {
    return <Navigate to={ROUTES.MY_PAGE} />;
  }

  const params = useParams();
  const lineBroadcastMessageId = params.id as string;
  const { data } = useGetLineBroadcastMessageQuery({
    variables: {
      input: { lineBroadcastMessageId },
    },
  });

  const getLineBroadcastMessage = data?.getLineBroadcastMessage;
  const deliveryTiming = getLineBroadcastMessage?.deliveryTiming;
  const isDisabled = [
    LineBroadcastStatus.DELIVERED,
    LineBroadcastStatus.SCHEDULED,
    LineBroadcastStatus.SENDING,
  ].includes(getLineBroadcastMessage?.status as LineBroadcastStatus);

  const lineBroadcastMessage = {
    deliveryTiming: {
      scheduleDate: deliveryTiming?.scheduleDate ? format(new Date(deliveryTiming.scheduleDate), dateFormat) : '',
      type: deliveryTiming?.type || DeliveryTimingType.IMMEDIATELY_SEND,
    },
    id: getLineBroadcastMessage?.id || '',
    lineContent:
      getLineBroadcastMessage?.content.map(item => {
        const { buttonMessage, imageMessage, plainTextMessage, richMessage, type } = item;

        return {
          genId: uuidv4(),
          type,
          ...(buttonMessage
            ? {
                buttonMessage: {
                  cards: buttonMessage.cards.map(card => {
                    const { actionLabels, description, imageUrl, title } = card;

                    return {
                      actionLabels: actionLabels.map(actionLabel => ({
                        ...actionLabel,
                        genId: uuidv4(),
                      })),
                      description: description || {
                        fontSize: FontSize.SM,
                        text: '',
                        weighted: false,
                      },
                      genId: uuidv4(),
                      imageUrl: imageUrl || '',
                      title: title || {
                        fontSize: FontSize.LG,
                        text: '',
                        weighted: false,
                      },
                    };
                  }),
                },
              }
            : {}),
          ...(imageMessage
            ? {
                imageMessage: {
                  images: imageMessage.images.map(image => {
                    const { imageUrl, label, url } = image;

                    return {
                      genId: uuidv4(),
                      imageUrl: imageUrl || '',
                      label: label || '',
                      url: url || '',
                    };
                  }),
                },
              }
            : {}),
          ...(plainTextMessage ? { plainTextMessage } : {}),
          ...(richMessage
            ? {
                richMessage: {
                  imageUrl: richMessage.imageUrl || '',
                  url: richMessage.url || '',
                },
              }
            : {}),
        };
      }) || [],
    recipient: getLineBroadcastMessage?.recipient || {
      tagNames: [],
      type: RecipientType.ALL,
    },
    status: getLineBroadcastMessage?.status || LineBroadcastStatus.DRAFT,
    title: getLineBroadcastMessage?.title || '',
  };

  return <BroadcastForm isDisabled={isDisabled} isEdit lineBroadcastMessage={lineBroadcastMessage} />;
};

export default Edit;
