import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  businessImg,
  entertainmentImg,
  foodAndDrinkImg,
  healthAndBeautyImg,
  livelihoodImg,
  telescopeSearchImg,
  uncategorizedImg,
} from '@src/assets/img/findJobs';
import anyCreator from '@src/assets/img/anyCreatorBlack.png';
import { Icomoon, Icon } from '@src/components/atoms';
import { ListLoading, NotificationBadge, YoutubeCmsWidget } from '@src/components/molecules';
import { useAllAnyCreatorCategoriesQuery, useGetAllCampaignsForSearchJobQuery } from '@src/graphql/hooks';
import { LIMIT } from '@src/libs/constant';
import { useHelpCenterUrl } from '@src/libs/help';
import {
  useBottomScrollListener,
  useAuthData,
  useDeepCompareEffect,
  useFilter,
  usePageLayout,
  useUrl,
} from '@src/libs/hooks';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import {
  AllCampaignsSearchJobsOrderBy,
  CampaignSocialMediaType,
  GetAllCampaignsForSearchJobQuery,
  SearchJobFilterParticipationType,
  SearchJobFilterRevenueCondition,
  SearchJobFilterRevenueType,
} from '@src/__generated__/globalTypes';
import Card from './Card';
import InputFilter, { FilterItems } from './InputFilter';

type GetAllCampaignsForSearchJob = NonNullable<GetAllCampaignsForSearchJobQuery['getAllCampaignsForSearchJob']>;

const FindJobs = () => {
  const [campaigns, setCampaigns] = useState<GetAllCampaignsForSearchJob['campaigns']>([]);
  const [limit, setLimit] = useState<number>(LIMIT);
  const { isAvailableCountry } = useAuthData();
  const { searchParams } = useUrl();
  const { filter, setFilter } = useFilter({
    category: searchParams.get('category') || '',
    participationTypes: searchParams.get('participationTypes') || '',
    promotionMethods: searchParams.get('promotionMethods') || '',
    revenueConditions: searchParams.get('revenueConditions') || '',
    revenueTypes: searchParams.get('revenueTypes') || '',
    showOnlyAvailableJobs: searchParams.get('showOnlyAvailableJobs') || '',
  });
  const { helpCenterUrl } = useHelpCenterUrl();
  const { isMobileView } = usePageLayout();
  const { t } = useTranslation();
  const promotionMethods = [
    { icon: 'instagram-color', value: CampaignSocialMediaType.INSTAGRAM },
    { icon: 'tiktok-color', value: CampaignSocialMediaType.TIKTOK },
    { icon: 'youtube-color', value: CampaignSocialMediaType.YOUTUBE },
    { icon: 'facebook-round-color', value: CampaignSocialMediaType.FACEBOOK },
    { icon: 'x-twitter-color', value: CampaignSocialMediaType.TWITTER },
    { title: 'Other', value: CampaignSocialMediaType.OTHER },
  ];
  const isAllPromotionMethods = promotionMethods.every(method =>
    filter.promotionMethods?.split(',').includes(method.value)
  );
  const isFilterByCategory = !!filter.category;

  const { data: dataCategories } = useAllAnyCreatorCategoriesQuery();
  const categories =
    dataCategories?.allAnycreatorCategories?.map(category => {
      const { image, width } = [
        { image: healthAndBeautyImg, name: 'Health & Beauty', width: 48 },
        { image: foodAndDrinkImg, name: 'Food & Drink', width: 43 },
        { image: businessImg, name: 'Business', width: 50 },
        { image: entertainmentImg, name: 'Entertainment', width: 44 },
        { image: livelihoodImg, name: 'Livelihood', width: 45 },
        { image: uncategorizedImg, name: 'Uncategorized', width: 33 },
      ].find(c => c.name === category.name) || { image: undefined, width: undefined };

      return {
        ...category,
        ...(image && { image }),
        ...(width && { width }),
      };
    }) || [];
  const { loading: loadingCampaigns } = useGetAllCampaignsForSearchJobQuery({
    fetchPolicy: 'no-cache',
    skip: !isAvailableCountry,
    variables: {
      input: {
        limit,
        offset: 0,
        orderBy: AllCampaignsSearchJobsOrderBy.ORDER_NUMBER,
        promotionMethods: (filter.promotionMethods?.split(',').filter(Boolean) as CampaignSocialMediaType[]) || [],
        ...(filter.category && { anyCreatorCategoryId: Number(filter.category) }),
        ...(filter.showOnlyAvailableJobs && { needOnlyAvailableJobs: true }),
        ...(filter.participationTypes && {
          participationTypes: filter.participationTypes
            .split(',')
            .filter(Boolean) as SearchJobFilterParticipationType[],
        }),
        ...(filter.revenueConditions && {
          revenueConditions: filter.revenueConditions.split(',').filter(Boolean) as SearchJobFilterRevenueCondition[],
        }),
        ...(filter.revenueTypes && {
          revenueTypes: filter.revenueTypes.split(',').filter(Boolean) as SearchJobFilterRevenueType[],
        }),
      },
    },
    onCompleted: data => {
      setCampaigns(data.getAllCampaignsForSearchJob?.campaigns || []);
    },
  });

  useEffect(() => {
    setFilter({
      ...filter,
      participationTypes: [
        SearchJobFilterParticipationType.OPEN_CAMPAIGN,
        SearchJobFilterParticipationType.SELECTION_CAMPAIGN,
      ].join(','),
      promotionMethods: [
        CampaignSocialMediaType.FACEBOOK,
        CampaignSocialMediaType.INSTAGRAM,
        CampaignSocialMediaType.OTHER,
        CampaignSocialMediaType.TIKTOK,
        CampaignSocialMediaType.TWITTER,
        CampaignSocialMediaType.YOUTUBE,
      ].join(','),
      revenueConditions: [
        SearchJobFilterRevenueCondition.PER_CLICK,
        SearchJobFilterRevenueCondition.PER_COMMENT,
        SearchJobFilterRevenueCondition.PER_COMMISSION_RATE,
        SearchJobFilterRevenueCondition.PER_CONVERSION,
        SearchJobFilterRevenueCondition.PER_LIKE,
        SearchJobFilterRevenueCondition.PER_POST,
        SearchJobFilterRevenueCondition.PER_SHARE,
        SearchJobFilterRevenueCondition.PER_VIEW,
      ].join(','),
      revenueTypes: [SearchJobFilterRevenueType.AFFILIATE_CAMPAIGN, SearchJobFilterRevenueType.POST_CAMPAIGN].join(','),
    });
  }, []);

  useDeepCompareEffect(() => {
    setLimit(LIMIT);
  }, [filter]);

  const onClickAllPromotionMethods = () => {
    let methods = '';
    if (!isAllPromotionMethods) {
      methods = promotionMethods.map(method => method.value).join(',');
    }
    setFilter({
      ...filter,
      promotionMethods: methods,
    });
  };

  const onClickPromotionMethod = (isActive: boolean, method: CampaignSocialMediaType) => {
    const items = filter.promotionMethods?.split(',') || [];
    if (isActive) {
      items.push(method);
    } else {
      const index = items.indexOf(method);
      if (index > -1) {
        items.splice(index, 1);
      }
    }
    setFilter({
      ...filter,
      promotionMethods: items.join(','),
    });
  };

  const onScroll = () => {
    if (campaigns.length === limit) {
      setLimit(max => max + LIMIT);
    }
  };

  useBottomScrollListener(onScroll, [campaigns.length, limit]);

  return (
    <div css={styles.container}>
      <div css={styles.contentContainer}>
        {!(isFilterByCategory && isMobileView) && (
          <div css={styles.toolbar}>
            {isMobileView ? (
              <div className="logo">
                <img height="28" src={anyCreator} width="131" />
              </div>
            ) : (
              <label className="title">{t('Title.Find Jobs')}</label>
            )}
            <Link className="help" target="_blank" to={helpCenterUrl}>
              <Icomoon icon="help-outlined" size={20} />
            </Link>
            <div className="notification">
              <NotificationBadge size={18} />
            </div>
          </div>
        )}

        {!isFilterByCategory && (
          <div css={styles.categories}>
            {categories.map(({ id, image, name, width }, index) => (
              <div className="category" key={index}>
                <div className="image" onClick={() => setFilter({ ...filter, category: id })}>
                  <img src={image} width={width} />
                </div>
                <label className="title">{t(`Option.${name}`)}</label>
              </div>
            ))}
          </div>
        )}

        <JobsContainer isFilterByCategory={isFilterByCategory}>
          <CategoryTitle isFilterByCategory={isFilterByCategory}>
            {isFilterByCategory && (
              <Icon className="back-btn" onClick={() => setFilter({ ...filter, category: '' })}>
                <Icomoon css={{ transform: 'rotate(90deg)' }} icon="arrow-down" size={10} />
              </Icon>
            )}
            <label className="title">
              {t(
                isFilterByCategory
                  ? `Option.${categories.find(c => c.id === Number(filter.category))?.name}` || ''
                  : 'Title.Recommend Jobs'
              )}
            </label>
          </CategoryTitle>

          <div css={{ display: 'grid', gap: THEME.box.gaps.m }}>
            <InputFilter filter={filter as FilterItems} setFilter={setFilter} />
            <div css={styles.promotionMethods}>
              <PromotionMethod isActive={isAllPromotionMethods} isIcon={false} onClick={onClickAllPromotionMethods}>
                <label>{t('All')}</label>
              </PromotionMethod>
              {promotionMethods.map(({ icon, title, value }, index) => {
                const isActive = !!filter.promotionMethods?.split(',').includes(value);

                return (
                  <PromotionMethod
                    isActive={isActive}
                    isIcon={!!icon}
                    key={index}
                    onClick={() => onClickPromotionMethod(!isActive, value)}
                  >
                    {title ? <label>{t(title)}</label> : <Icomoon icon={icon as string} size={14} />}
                  </PromotionMethod>
                );
              })}
            </div>
          </div>

          {loadingCampaigns && !campaigns.length ? (
            <ListLoading isTransparentBg />
          ) : campaigns.length <= 0 ? (
            <div css={styles.emptyList}>
              <img className="image" height={80} src={telescopeSearchImg} width={160} />
              <label className="subtitle">{t('Annotation.No Campaigns found')}</label>
              <label className="description">
                {t('Annotation.Please modify or remove some of your filters and try again')}
              </label>
            </div>
          ) : (
            <div css={styles.listContainer}>
              {campaigns.map((campaign, index) => (
                <Card campaign={campaign} key={index} />
              ))}
            </div>
          )}

          {loadingCampaigns && !!campaigns.length && (
            <ListLoading height="unset" isTransparentBg spinnerProps={{ size: '32px' }} />
          )}
        </JobsContainer>
      </div>

      <YoutubeCmsWidget />
    </div>
  );
};

const CategoryTitle = styled.div<{ isFilterByCategory: boolean }>(({ isFilterByCategory }) => ({
  alignItems: 'center',
  display: 'flex',
  gap: THEME.box.gaps.l,
  position: 'relative',

  [`@media (max-width: ${ViewportType.SMALL}px)`]: {
    ...(isFilterByCategory && { textAlign: 'center' }),
  },

  '& > .back-btn': {
    [`@media (max-width: ${ViewportType.SMALL}px)`]: {
      position: 'absolute',
    },
  },

  '& > .title': {
    color: THEME.font.colors.black.main,
    fontSize: THEME.font.sizes.subHeading,
    fontWeight: 600,
    width: 'fill-available',

    [`@media (min-width: ${ViewportType.SMALL}px)`]: {
      fontSize: THEME.font.sizes.heading,
    },
  },
}));

const JobsContainer = styled.div<{ isFilterByCategory: boolean }>(({ isFilterByCategory }) => ({
  '--categoryHeight': '108px',
  '--menuHeight': '64px',
  '--gaps': '32px',
  '--padding': '40px',
  '--toolbarHeight': '48px',

  background: THEME.colors.white,
  display: 'grid',
  gap: THEME.box.gaps.xxl,
  gridAutoRows: 'min-content',
  maxWidth: 672,
  minHeight: `calc(100vh  ${
    !isFilterByCategory ? '- var(--categoryHeight) - var(--gaps) - var(--toolbarHeight)' : ''
  } - var(--menuHeight) - var(--padding))`,
  padding: '20px 16px',
  ...(isFilterByCategory
    ? { borderRadius: 'unset' }
    : {
        borderBottomLeftRadius: 'unset',
        borderBottomRightRadius: 'unset',
      }),

  [`@media (min-width: ${ViewportType.SMALL}px)`]: {
    borderRadius: THEME.box.borderRadius.xl,
    minHeight: 491,
    padding: 32,
    width: 'fill-available',
  },
}));

const PromotionMethod = styled.div<{ isActive: boolean; isIcon: boolean }>(({ isActive, isIcon }) => ({
  alignItems: 'center',
  borderRadius: THEME.box.borderRadius.xxl,
  border: isActive ? THEME.box.borders.red.ff8d8d : THEME.box.borders.gray.dfe2e5,
  color: THEME.font.colors.gray.main,
  cursor: 'pointer',
  display: 'grid',
  fontSize: THEME.font.sizes.subordinate,
  fontWeight: 500,
  justifyContent: 'center',
  padding: isIcon ? '7px 16px' : '7px 20px',
  width: 'fit-content',

  '& > label': {
    cursor: 'pointer',
    width: 'max-content',
  },
}));

const styles = {
  categories: css({
    display: 'flex',
    gap: THEME.box.gaps.xxl,
    justifySelf: 'center',

    [`@media (max-width: ${ViewportType.SMALL}px)`]: {
      justifySelf: 'unset',
      overflow: 'auto',
      padding: '0 16px',
      width: 'calc(100vw - 32px)',
    },

    '& > .category': {
      alignContent: 'flex-start',
      display: 'grid',
      gap: THEME.box.gaps.xs,
      width: 74,

      '& > .image': {
        alignItems: 'center',
        background: THEME.colors.white,
        borderRadius: '50%',
        cursor: 'pointer',
        display: 'grid',
        height: 74,
        justifyContent: 'center',
        width: 74,
      },

      '& > .title': {
        color: THEME.font.colors.black.main,
        fontSize: THEME.font.sizes.hint,
        fontWeight: 600,
        textAlign: 'center',
      },
    },
  }),
  container: css({
    display: 'flex',
    justifyContent: 'center',

    [`@media (min-width: ${ViewportType.SMALL}px)`]: {
      padding: '24px 0',
    },
  }),
  contentContainer: css({
    display: 'grid',
    gap: THEME.box.gaps.l,
    maxWidth: 672,
    width: '100%',
  }),
  emptyList: css({
    display: 'grid',
    margin: 'auto',
    marginTop: 80,
    textAlign: 'center',
    width: 214,

    '& > .image': {
      justifySelf: 'center',
      marginBottom: 24,
    },

    '& > .subtitle': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.subTitle,
      fontWeight: 600,
      marginBottom: 12,
    },

    '& > .description': {
      color: THEME.font.colors.gray.main,
      fontSize: THEME.font.sizes.subordinate,
    },
  }),
  listContainer: css({
    display: 'grid',
    columnGap: THEME.box.gaps.l,
    gridTemplateColumns: '1fr 1fr',
    rowGap: THEME.box.gaps.l,

    [`@media (min-width: ${ViewportType.SMALL}px)`]: {
      gridTemplateColumns: '1fr 1fr 1fr',
      rowGap: THEME.box.gaps.xxl,
    },
  }),
  promotionMethods: css({
    display: 'flex',
    gap: THEME.box.gaps.xs,
    overflow: 'auto',
  }),
  toolbar: css({
    alignItems: 'center',
    color: THEME.font.colors.black.main,
    display: 'flex',
    fontSize: THEME.font.sizes.subTitle,
    fontWeight: 600,
    gap: THEME.box.gaps.s,
    width: 'fill-available',

    [`@media (max-width: ${ViewportType.SMALL}px)`]: {
      padding: '8px 16px',
    },

    '& > .logo, .title': {
      width: 'fill-available',
    },

    '& > .help, .notification': {
      alignItems: 'center',
      background: THEME.colors.white,
      borderRadius: 7,
      display: 'grid',
      height: 32,
      justifyContent: 'center',
      minWidth: 32,
    },
  }),
};

export default FindJobs;
