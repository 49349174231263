import { format } from 'date-fns';
import React, { useState } from 'react';
import { useIntercom } from 'react-use-intercom';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { eventTypes, sendAmplitudeEvent } from '@src/amplitude';
import { Icomoon, VideoPreview } from '@src/components/atoms';
import { BackNavigator, ThemeButton } from '@src/components/molecules';
import StatusStepper from '@src/components/shared/Campaign/StatusStepper';
import { LIMIT } from '@src/libs/constant';
import { usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { defaultEmptyImage, isVideoThumbnail } from '@src/libs/image';
import { getSocialMediaUrl, switchImage } from '@src/libs/socialMedia';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import {
  AnyXEngagementCampaignStatus,
  CampaignSocialMediaType,
  CampaignStatusForInfluencer,
  CampaignType,
  SocialAccountType,
  TTCMStatus,
} from '@src/__generated__/globalTypes';
import { localizedDateFormatter } from '@src/libs/date';
import { AppLanguage } from '@src/libs/i18n/languageDetector/utils';
import { ROUTES, generatePath } from '@src/shared/routes';
import Dialog, { smallButtonStyles } from '@src/components/molecules/Dialog';
import {
  useAllEngagementPostsForInfluencerQuery,
  useDeleteEngagementPostForInfluencerMutation,
  useEngagementForInfluencerQuery,
  useEngagementPostSocialAccountsForInfluencerQuery,
} from '@src/graphql/hooks';
import { EngagementPostSocialAccount } from '@src/__generated__/globalTypes';
import DialogContent from './DialogContent';
import ReasonWidget from './ReasonWidget';

const Posts = () => {
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [selectedDeleteId, setSelectedDeleteId] = useState<number | null>(null);
  const [selectedPostId, setSelectedPostId] = useState<number | null>(null);
  const { isMobileView } = usePageLayout();
  const { enqueueSnackbar, params, t, i18n } = useQueryHelper();
  const { show } = useIntercom();
  const engagementId = Number(params.id);
  const { data } = useAllEngagementPostsForInfluencerQuery({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      engagementId,
      limit: LIMIT,
      offset: 0,
    },
  });
  const { data: dataCampaignDetails } = useEngagementForInfluencerQuery({
    variables: {
      pk: engagementId,
    },
  });
  const { data: dataSocialAccounts } = useEngagementPostSocialAccountsForInfluencerQuery({
    variables: {
      pk: engagementId,
    },
  });
  const [deleteEngagementPostForInfluencer] = useDeleteEngagementPostForInfluencerMutation({
    refetchQueries: ['EngagementCampaignPosts'],
    onCompleted: () => {
      enqueueSnackbar(t('succeededInDeleting'), { variant: 'success' });
      sendAmplitudeEvent(eventTypes.deleteEngagementPost, { postId: Number(selectedDeleteId) });
      setSelectedDeleteId(null);
    },
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  const onClickDeletePost = () => {
    setIsDeleting(true);
    deleteEngagementPostForInfluencer({
      variables: {
        id: Number(selectedDeleteId),
      },
    }).finally(() => setIsDeleting(false));
  };

  const campaignPosts = data?.allEngagementPostsForInfluencer || [];
  const campaignDetails = dataCampaignDetails?.engagementForInfluencer;
  const isCampaignAvailable = campaignDetails?.status !== CampaignStatusForInfluencer.FINISHED;
  const isTtcmCampaign = campaignDetails?.isTtcmCampaign;

  const socialAccounts = dataSocialAccounts?.engagementPostSocialAccountsForInfluencer || [];
  const { isAddDraftPost, ttcmAccount } = socialAccounts.reduce(
    (accounts, value) => {
      const isTtcmAccount =
        isTtcmCampaign && value.socialMedia === SocialAccountType.TIKTOK && value.ttcmStatus === TTCMStatus.APPROVED;

      return {
        ...accounts,
        ...(!isTtcmAccount && { isAddDraftPost: true }),
        // ...(isTtcmAccount && { ttcmAccount: value }),
      };
    },
    {
      isAddDraftPost: false,
      ttcmAccount: null as EngagementPostSocialAccount | null,
    }
  );

  const urlTiktokUsername = `${getSocialMediaUrl(CampaignSocialMediaType.TIKTOK)}${
    ttcmAccount ? `/@${ttcmAccount.username}` : ''
  }`;

  return (
    <>
      <div css={styles.container}>
        <Dialog
          contentStyles={{
            backgroundColor: 'transparent',
            border: 'none',
            maxWidth: 700,
            width: '90%',
          }}
          open={!!selectedPostId}
          onClose={() => setSelectedPostId(null)}
        >
          <div>
            {!!selectedPostId && <DialogContent postId={selectedPostId} onClose={() => setSelectedPostId(null)} />}
          </div>
        </Dialog>

        <Dialog
          cancelButtonProps={{
            styles: smallButtonStyles,
            onClick: () => setSelectedDeleteId(null),
          }}
          nextButtonProps={{
            disabled: isDeleting,
            styles: smallButtonStyles,
            text: 'Delete',
            theme: 'red',
            onClick: onClickDeletePost,
          }}
          open={!!selectedDeleteId}
          onClose={() => setSelectedDeleteId(null)}
        >
          <div css={styles.dialog}>
            <div css={styles.dialogTitle}>{t('Confirmation required')}</div>
            <div css={styles.dialogMessage}>{t('Dialog.Are you sure you want to delete the post?')}</div>
          </div>
        </Dialog>

        {!isMobileView && (
          <div css={styles.navigatorContainer}>
            <div>
              <BackNavigator
                title="Post List"
                to={generatePath(ROUTES.MY_JOBS_CASTING_YOUR_JOB_ID, { id: engagementId })}
              />
            </div>
            {isCampaignAvailable && (
              <div css={styles.actionHeaderContainer}>
                {isAddDraftPost && (
                  <ThemeButton
                    text="Add Draft Post"
                    theme="blue"
                    to={generatePath(ROUTES.MY_JOBS_CASTING_YOUR_JOB_ID_POST_ADD, { id: engagementId })}
                    width="max-content"
                  />
                )}
                {!!ttcmAccount && (
                  <ThemeButton
                    target="_blank"
                    text="Add Draft Video"
                    theme="blue"
                    to={urlTiktokUsername}
                    width="max-content"
                  />
                )}
              </div>
            )}
          </div>
        )}

        <div css={{ display: 'grid', gap: THEME.box.gaps.xxl, padding: '16px 0 16px' }}>
          {campaignPosts.map(post => {
            const {
              anyXStatus,
              content,
              id: postId,
              inappropriateDetailedReason,
              inappropriateReason,
              planedPostDate,
              postedDate,
              socialMedia,
              thumbNail,
              ttcmInviteLink,
            } = post;

            const isUploadedFromTiktok =
              isTtcmCampaign &&
              campaignDetails?.ttcmStatus === TTCMStatus.APPROVED &&
              socialMedia === CampaignSocialMediaType.TIKTOK;

            const showReason = [
              AnyXEngagementCampaignStatus.WARNING_EFFECTIVE,
              AnyXEngagementCampaignStatus.WARNING_REVIEWING,
            ].includes(anyXStatus);

            return (
              <div css={styles.postsContainer} key={postId}>
                <div css={styles.postContainer}>
                  <div css={styles.infoSection}>
                    {!isMobileView && showReason && (
                      <ReasonContainer>
                        <ReasonWidget
                          content={inappropriateDetailedReason}
                          status={anyXStatus}
                          title={inappropriateReason}
                        />
                      </ReasonContainer>
                    )}

                    <div css={styles.headerContainer}>
                      <div css={styles.stepperContainer}>
                        <HeaderTitle>{t('Post Status')}</HeaderTitle>
                        <StatusStepper
                          activeColor={THEME.colors.black.main}
                          status={anyXStatus}
                          type={CampaignType.ENGAGEMENT}
                        />
                      </div>

                      {isMobileView && showReason && (
                        <ReasonContainer>
                          <ReasonWidget
                            content={inappropriateDetailedReason}
                            status={anyXStatus}
                            title={inappropriateReason}
                          />
                        </ReasonContainer>
                      )}

                      <div css={styles.tableContainer}>
                        <HeaderInfo flexBasis="20%">
                          <HeaderTitle>{t('SNS')}</HeaderTitle>
                          <HeaderContent>
                            <Icomoon icon={switchImage({ socialMedia })} size={24} />
                          </HeaderContent>
                        </HeaderInfo>
                        <HeaderInfo flexBasis="40%">
                          <HeaderTitle>{t('Planed Post Date')}</HeaderTitle>
                          <HeaderContent>
                            {localizedDateFormatter(
                              new Date(planedPostDate),
                              'MMMM dd, yyyy',
                              i18n.language as AppLanguage
                            )}
                          </HeaderContent>
                        </HeaderInfo>
                        <HeaderInfo flexBasis="40%">
                          <HeaderTitle>{t('Posted Date')}</HeaderTitle>
                          <HeaderContent>
                            {[
                              AnyXEngagementCampaignStatus.DRAFTING,
                              AnyXEngagementCampaignStatus.REVIEWING,
                              AnyXEngagementCampaignStatus.WARNING_REVIEWING,
                            ].includes(anyXStatus)
                              ? '- - -'
                              : postedDate
                              ? format(new Date(postedDate), 'MMMM dd, yyyy')
                              : '- - -'}
                          </HeaderContent>
                        </HeaderInfo>
                      </div>
                    </div>

                    <div css={styles.contentSection}>
                      <div>
                        <div>
                          {isVideoThumbnail(thumbNail) ? (
                            <VideoPreview height="86" url={thumbNail || ''} width="152" />
                          ) : (
                            <img alt="postImg" height="86" src={defaultEmptyImage(thumbNail)} width="152" />
                          )}
                        </div>
                      </div>
                      <div onClick={() => setSelectedPostId(postId)}>{content}</div>
                    </div>
                  </div>

                  {![AnyXEngagementCampaignStatus.DRAFTING].includes(anyXStatus) && (
                    <div css={styles.actionSection}>
                      {[
                        AnyXEngagementCampaignStatus.REVIEWING,
                        AnyXEngagementCampaignStatus.WARNING_REVIEWING,
                      ].includes(anyXStatus) ? (
                        <>
                          <ThemeButton text="Delete" css={styles.button} onClick={() => setSelectedDeleteId(postId)} />
                          <ThemeButton
                            text={isUploadedFromTiktok ? 'Check Video' : 'Edit'}
                            theme="blue"
                            css={styles.button}
                            {...(isUploadedFromTiktok
                              ? {
                                  target: '_blank',
                                  to: urlTiktokUsername,
                                }
                              : {
                                  to: generatePath(ROUTES.MY_JOBS_CASTING_YOUR_JOB_ID_POST_EDIT, {
                                    id: engagementId,
                                    postId,
                                  }),
                                })}
                          />
                        </>
                      ) : anyXStatus === AnyXEngagementCampaignStatus.POSTING ? (
                        <ThemeButton
                          target="_blank"
                          theme="blue"
                          text="Post Now"
                          to={isTtcmCampaign ? ttcmInviteLink || urlTiktokUsername : getSocialMediaUrl(socialMedia)}
                          css={styles.button}
                        />
                      ) : anyXStatus === AnyXEngagementCampaignStatus.WARNING_EFFECTIVE ? (
                        <ThemeButton text="Contact" onClick={show} width="max-content" css={styles.button} />
                      ) : (
                        <ThemeButton
                          text="View Report"
                          to={generatePath(ROUTES.MY_JOBS_CASTING_YOUR_JOB_ID_REPORT, { id: engagementId })}
                          css={styles.button}
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {isMobileView && isCampaignAvailable && (
        <div css={styles.actionContainer}>
          {isAddDraftPost && (
            <ThemeButton
              height="40px"
              text="Add Draft Post"
              theme="blue"
              to={generatePath(ROUTES.MY_JOBS_CASTING_YOUR_JOB_ID_POST_ADD, { id: engagementId })}
            />
          )}
          {!!ttcmAccount && (
            <ThemeButton height="40px" target="_blank" text="Add Draft Video" theme="blue" to={urlTiktokUsername} />
          )}
        </div>
      )}
    </>
  );
};

const HeaderContent = styled.div({
  color: THEME.font.colors.black.main,
  fontSize: 13,
  marginTop: 8,
});

const HeaderInfo = styled.div<{ flexBasis: string }>(({ flexBasis }) => ({
  display: 'grid',
  flexBasis,
}));

const HeaderTitle = styled.div({
  color: THEME.font.colors.black.main,
  fontSize: 13,
  fontWeight: 600,
});

const ReasonContainer = styled.div({
  display: 'flex',
  flexBasis: '100%',
  marginBottom: 16,
});

const styles = {
  actionContainer: css({
    '--paddingSides': '16px',

    alignItems: 'center',
    backgroundColor: THEME.colors.white,
    bottom: 0,
    boxShadow: THEME.box.shadows.outer,
    display: 'flex',
    gap: THEME.box.gaps.s,
    height: 72,
    position: 'fixed',
    width: 'calc(100% - var(--paddingSides) * 2)',
    padding: '0 var(--paddingSides)',
  }),
  actionHeaderContainer: css({
    alignItems: 'center',
    display: 'flex',
    gap: THEME.box.gaps.s,
    justifyContent: 'flex-end',
  }),
  actionSection: css({
    display: 'flex',
    justifyContent: 'flex-end',
    padding: 24,
    gap: THEME.box.gaps.s,
  }),
  container: css({
    margin: 16,
  }),
  contentSection: css({
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: 16,

    '& > div:nth-of-type(1)': {
      height: 86,
      maxWidth: 168,
      width: '35%',

      '& > div': {
        backgroundColor: '#000',
        marginRight: 16,

        '& > img': {
          objectFit: 'contain',
          width: '100%',
        },
      },

      [`@media (max-width: ${ViewportType.MEDIUM}px)`]: {
        width: '50%',
      },
    },

    '& > div:nth-of-type(2)': {
      color: THEME.font.colors.blue.main,
      cursor: 'pointer',
      display: '-webkit-box',
      fontSize: 13,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '65%',
      wordBreak: 'break-all',
      '-webkit-line-clamp': 5,
      '-webkit-box-orient': 'vertical',

      '&:hover': {
        textDecoration: 'underline',
      },

      [`@media (max-width: ${ViewportType.MEDIUM}px)`]: {
        width: '50%',
      },
    },
  }),
  dialog: css({
    backgroundColor: THEME.colors.white,
    borderRadius: 5,
  }),
  dialogCloseBtn: css({
    alignItems: 'center',
    backgroundColor: '#000',
    borderRadius: '50%',
    display: 'flex',
    height: 40,
    justifyContent: 'center',
    position: 'absolute',
    right: -12,
    top: -16,
    width: 40,
  }),
  dialogMessage: css({
    color: THEME.font.colors.black.main,
    fontSize: THEME.font.sizes.subordinate,
    margin: '8px 0',
  }),
  dialogTitle: css({
    color: THEME.font.colors.black.main,
    fontSize: THEME.font.sizes.heading,
    fontWeight: 600,
  }),
  headerContainer: css({
    display: 'flex',
    flexWrap: 'wrap',
  }),
  infoSection: css({
    borderBottom: '1px solid #eef3f7',
    padding: 24,
  }),
  navigatorContainer: css({
    display: 'flex',
    flex: 'wrap',
    justifyContent: 'space-between',

    '& > div': {
      display: 'flex',
      flexBasis: '50%',
    },
  }),
  postContainer: css({
    backgroundColor: THEME.colors.white,
    borderRadius: 5,
    boxShadow: THEME.box.shadows.outer,
    maxWidth: 648,
    width: '100%',
  }),
  postsContainer: css({
    display: 'flex',
    justifyContent: 'center',
  }),
  stepperContainer: css({
    width: '50%',

    '& > div:nth-of-type(2)': {
      width: 'auto',
    },

    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      borderBottom: '1px solid #eef3f7',
      marginBottom: 16,
      paddingBottom: 8,
      width: '100%',
    },
  }),
  tableContainer: css({
    display: 'flex',
    width: '100%',

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      width: '50%',
    },
  }),
  button: css({
    width: 'max-content',

    [`@media (max-width: ${ViewportType.SMALL}px)`]: {
      height: 40,
      width: 'fill-available',
    },
  }),
};

export default Posts;
