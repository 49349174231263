import * as React from 'react';
import { css } from '@emotion/react';
import TopPageHeader from '@src/components/organisms/TopPage/Header';
import { usePageLayout } from '@src/libs/hooks';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import TopPageFooter from '@src/components/organisms/TopPage/Footer';
import { ROUTES } from '@src/shared/routes';
import ResetPasswordForm from './ResetPasswordForm';

const Template = () => {
  const { isMobileView } = usePageLayout();

  return (
    <div css={styles.wrapper}>
      <div css={styles.container}>
        <TopPageHeader css={{ paddingTop: 16 }} href={ROUTES.ROOT} />
        <section css={styles.contentWrapper}>
          <ResetPasswordForm />
        </section>
        {!isMobileView && <TopPageFooter css={styles.footer} />}
      </div>
    </div>
  );
};

const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
    width: 856,
    alignItems: 'center',
  }),
  contentWrapper: css({
    boxShadow: THEME.box.shadows.outer,
    width: '100%',
    padding: 48,
    backgroundColor: THEME.colors.white,
    borderRadius: 3,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxHeight: 367,

    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      borderRadius: 0,
      boxShadow: 'none',
      padding: '32px 48px 32px',
      flex: 1,
    },
  }),
  footer: css({
    marginTop: 'initial',
    width: '100%',
  }),
  wrapper: css({
    width: '100%',
    minHeight: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',

    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      backgroundColor: THEME.colors.white,
    },
  }),
};

export default Template;
