import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Navigate } from 'react-router-dom';
import { css } from '@emotion/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { eventTypes, sendAmplitudeEvent } from '@src/amplitude';
import { CheckBox, ErrorMessage } from '@src/components/atoms';
import { BackNavigatorV2, ThemeButton } from '@src/components/molecules';
import { SentenceFormV2, TextFormV2 } from '@src/components/shared';
import { removeToken } from '@src/libs/auth';
import { useAuthData, useDeepCompareEffect, usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import { mainLightRed } from '@src/libs/palette';
import yup, { InfluencerEmailSchema } from '@src/libs/validation';
import { useRequestDeleteAccountMutation } from '@src/graphql/hooks';
import { ROUTES } from '@src/shared/routes';

interface Information {
  comments: string;
  email: string;
  otherServices: string;
  reasons: string[];
}

const DeleteAccount = () => {
  const { limitedLogin, userId } = useAuthData();
  if (limitedLogin) {
    return <Navigate to={ROUTES.MY_PAGE_ACCOUNT} />;
  }

  const [selectedReasons, setSelectedReasons] = useState<string[]>([]);
  const { enqueueSnackbar, navigate, t } = useQueryHelper();
  const { isMobileView, isYoutubeCmsPath } = usePageLayout();
  const [requestDeleteAccount] = useRequestDeleteAccountMutation();
  const validationSchema = yup.object().shape({
    email: InfluencerEmailSchema,
    reasons: yup.array().min(1, 'requiredFieldMessage'),
  });
  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    register,
    setValue,
  } = useForm<Information>({
    defaultValues: {
      comments: '',
      email: '',
      otherServices: '',
      reasons: [],
    },
    resolver: yupResolver(validationSchema),
  });
  const reasons = [
    'AccountDeletionReason1',
    'AccountDeletionReason2',
    'AccountDeletionReason3',
    'AccountDeletionReason4',
  ];

  useDeepCompareEffect(() => {
    setValue(
      'reasons',
      selectedReasons.map(value => t(`Label.${value}`))
    );
  }, [selectedReasons]);

  const onClickReason = (checked: boolean, value: string) => {
    const items = [...selectedReasons];
    if (checked) {
      items.push(value);
    } else {
      const index = items.findIndex(item => item === value);
      if (index > -1) {
        items.splice(index, 1);
      }
    }
    setSelectedReasons(items);
  };

  const onSubmit = async (values: Information) => {
    try {
      await requestDeleteAccount({
        variables: {
          input: values,
        },
      });
      enqueueSnackbar(t('succeededInDeleting'), { variant: 'success' });
      removeToken();
      sendAmplitudeEvent(eventTypes.deleteAccount, { influencerId: userId });
      navigate(ROUTES.SIGN_UP);
    } catch (err) {
      enqueueSnackbar(t(err), { variant: 'error' });
    }
  };

  return (
    <form css={{ padding: '24px 16px' }} onSubmit={handleSubmit(onSubmit)}>
      {!isMobileView && (
        <BackNavigatorV2
          backPath={isYoutubeCmsPath ? ROUTES.YOUTUBE_CMS_MY_PAGE_ACCOUNT : ROUTES.MY_PAGE_ACCOUNT}
          title="Delete Account"
        />
      )}

      <div css={styles.contentContainer}>
        <div css={styles.description}>
          {t(
            'Annotation.Please let us know if there are any points you would like us to address in deleting your account'
          )}
        </div>
        <div css={styles.inputContainer}>
          <div>
            <TextFormV2 error={!!errors.email} isRequired placeholder="" title="Email Address" {...register('email')} />
            <ErrorMessage message={errors.email?.message} />
          </div>

          <div>
            <div css={styles.reasonLabel}>
              <span>{t('Annotation.What is your reason for wanting to delete your account')}</span>
              <span>*</span>
              <span>{`(${t('Multiple selections possible')})`}</span>
            </div>
            <div css={styles.checkboxContainer}>
              {reasons.map(value => (
                <div key={value}>
                  <CheckBox
                    checked={selectedReasons.includes(value)}
                    label={`Label.${value}`}
                    onChange={checked => onClickReason(checked, value)}
                  />
                </div>
              ))}
            </div>
            <ErrorMessage message={errors.reasons?.message} />
          </div>

          <SentenceFormV2
            placeholder=""
            title="If you have any other comments about our services, please write them below"
            {...register('comments')}
          />

          <SentenceFormV2
            placeholder=""
            title="Please let us know if there are any other job search apps/influencer services you use"
          />

          <div css={styles.thanksMessage}>{t('Annotation.DeletionThankYouMessage')}</div>

          <ThemeButton
            css={{ borderRadius: 9, height: 48 }}
            customPalette={mainLightRed}
            disabled={isSubmitting}
            text="Delete"
            type="submit"
          />
        </div>
      </div>
    </form>
  );
};

const styles = {
  checkboxContainer: css({
    display: 'grid',
    gap: THEME.box.gaps.l,

    '& > div': {
      '& label': {
        color: THEME.font.colors.black.main,
        fontSize: THEME.font.sizes.normal,
      },
    },
  }),
  contentContainer: css({
    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      padding: 24,
    },
  }),
  description: css({
    color: THEME.font.colors.black.main,
    fontSize: THEME.font.sizes.normal,
    marginBottom: 16,
  }),
  inputContainer: css({
    background: THEME.colors.white,
    borderRadius: 15,
    boxShadow: THEME.box.shadows.outer,
    display: 'grid',
    gap: THEME.box.gaps.xxl,
    padding: '24px 16px',
  }),
  reasonLabel: css({
    fontSize: THEME.font.sizes.normal,
    marginBottom: 16,

    '& > span:nth-of-type(1)': {
      color: THEME.font.colors.black.main,
    },

    '& > span:nth-of-type(2)': {
      color: THEME.font.colors.error,
    },

    '& > span:nth-of-type(3)': {
      color: THEME.font.colors.gray.main,
    },
  }),
  thanksMessage: css({
    color: THEME.font.colors.black.main,
    fontSize: THEME.font.sizes.normal,
    fontWeight: 600,
    textAlign: 'center',
  }),
};

export default DeleteAccount;
