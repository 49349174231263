import React from 'react';
import { useParams } from 'react-router-dom';
import { css } from '@emotion/react';
import { BackNavigator } from '@src/components/molecules';
import EngagementPostForm from '@src/components/shared/Campaign/EngagementPostForm';
import { useEngagementForInfluencerQuery } from '@src/graphql/hooks';
import { usePageLayout } from '@src/libs/hooks';
import { generatePath, ROUTES } from '@src/shared/routes';

const AddPost = () => {
  const params = useParams<Record<any, string>>();
  const { isMobileView } = usePageLayout();
  const engagementId = Number(params.id);
  const { data } = useEngagementForInfluencerQuery({
    fetchPolicy: 'no-cache',
    variables: {
      pk: engagementId,
    },
  });

  return (
    <div css={styles.container}>
      {!isMobileView && (
        <BackNavigator
          title="Upload Draft Post"
          to={generatePath(
            data?.engagementForInfluencer?.hasDraftPosts
              ? ROUTES.MY_JOBS_CASTING_YOUR_JOB_ID_POST
              : ROUTES.MY_JOBS_CASTING_YOUR_JOB_ID,
            { id: engagementId }
          )}
        />
      )}

      <div css={styles.formContainer}>
        <EngagementPostForm engagementId={engagementId} />
      </div>
    </div>
  );
};

const styles = {
  container: css({
    padding: 24,
  }),
  formContainer: css({
    marginTop: 16,
  }),
};

export default AddPost;
