const colors = {
  black: { main: '#27313b' },
  blue: { main: '#3892e5' },
  disabled: '#d4d8dc',
  error: '#ff5f5f',
  gray: {
    596570: '#596570',
    a8b4bf: '#a8b4bf',
    c5d0da: '#c5d0da',
    d9d9d9: '#d9d9d9',
    dee5ec: '#dee5ec',
    eef3f7: '#eef3f7',
    f6f8fa: '#f6f8fa',
    main: '#6e7c89',
  },
  red: {
    fe6565: '#fe6565',
    ff4470: '#ff4470',
    ffeef2: '#ffeef2',
    fff0f0: '#fff0f0',
  },
  success: '#40b87c',
  white: '#fff',
};

export const THEME = {
  box: {
    borders: {
      black: {
        main: '1px solid #27313b',
      },
      gray: {
        c5d0da: '1px solid #c5d0da',
        dfe2e5: '1px solid #dfe2e5',
        eef3f7: '1px solid #eef3f7',
        main: '1px solid #dee5ec',
      },
      red: {
        ff8d8d: '1px solid #ff8d8d',
        ffd8d8: '1px solid #ffd8d8',
      },
    },
    borderRadius: {
      xxl: 32,
      xl: 24,
      l: 15,
      m: 9,
      s: 5,
      xs: 3,
    },
    gaps: {
      xxl: 24,
      xl: 20,
      l: 16,
      m: 12,
      s: 8,
      xs: 4,
    },
    shadows: {
      inner: 'inset 0 .0625rem .125rem #0003',
      outer: '0 0 24px rgba(110, 124, 137, 0.05), 0 0 1px rgba(110, 124, 137, 0.2)',
    },
  },
  font: {
    colors: {
      black: { ...colors.black },
      blue: { ...colors.blue },
      error: colors.error,
      gray: { ...colors.gray },
      success: colors.success,
      white: colors.white,
    },
    sizes: {
      title: 24,
      subTitle: 20,
      heading: 18,
      subHeading: 16,
      normal: 14,
      subordinate: 12,
      hint: 10,
    },
  },
  ...{ colors },
};
