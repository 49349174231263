import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';
import { Icomoon } from '@src/components/atoms';
import { useAuthData, useGlobalLayout } from '@src/libs/hooks';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import { ROUTES } from '@src/shared/routes';

const YoutubeCmsWidget = () => {
  const { isYoutubeCmsRevenue } = useAuthData();
  const { hasIntercomWidget, hasLineWidget, hasMobileMenu, setGlobalLayout } = useGlobalLayout();
  const { t } = useTranslation();

  useEffect(() => {
    setGlobalLayout({ hasYoutubeCmsWidget: isYoutubeCmsRevenue });
  }, [isYoutubeCmsRevenue]);

  return isYoutubeCmsRevenue ? (
    <Container
      hasIntercomWidget={hasIntercomWidget}
      hasLineWidget={hasLineWidget}
      hasMobileMenu={hasMobileMenu}
      to={ROUTES.YOUTUBE_CMS_REVENUE}
    >
      <Icomoon color={THEME.colors.white} icon="music-note-single-bar" size={16} />
      <label>{t('Title.BGM Report')}</label>
      <Icomoon color={THEME.colors.white} icon="arrow-up-right" size={12} />
    </Container>
  ) : null;
};

const Container = styled(Link, { shouldForwardProp: prop => isPropValid(prop) })<{
  hasIntercomWidget: boolean;
  hasLineWidget: boolean;
  hasMobileMenu: boolean;
}>(({ hasIntercomWidget, hasLineWidget, hasMobileMenu }) => ({
  '--gap': '12px',
  '--intercomWidgetWidth': '48px',
  '--lineWidgetWidth': '72px',
  '--pagePadding': '12px',

  alignItems: 'center',
  background: THEME.colors.black.main,
  borderRadius: 50,
  bottom: hasMobileMenu ? 76 : 12,
  color: THEME.font.colors.white,
  cursor: 'pointer',
  display: 'flex',
  fontSize: THEME.font.sizes.normal,
  fontWeight: 600,
  gap: THEME.box.gaps.s,
  padding: 16,
  position: 'fixed',
  right: `calc(${
    hasIntercomWidget || hasLineWidget
      ? `var(--gap) + var(${hasIntercomWidget ? '--intercomWidgetWidth' : '--lineWidgetWidth'}) +`
      : ''
  } var(--pagePadding))`,
  width: `calc(100% ${
    hasIntercomWidget || hasLineWidget
      ? `- var(${
          hasIntercomWidget ? '--intercomWidgetWidth' : '--lineWidgetWidth'
        }) - (var(--pagePadding) * 2) - var(--gap)`
      : ''
  } - 32px)`,
  zIndex: 2,

  [`@media (min-width: ${ViewportType.TABLET}px)`]: {
    width: 168,
  },

  '& > svg:nth-of-type(1)': {
    minWidth: 16,
  },

  '& > label': {
    width: 'fill-available',
  },

  '& > svg:nth-of-type(2)': {
    minWidth: 12,
  },
}));

export default YoutubeCmsWidget;
