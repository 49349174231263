import isEqual from 'lodash/isEqual';
import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import Markdown from 'react-markdown';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Icomoon } from '@src/components/atoms';
import { ThemeButton } from '@src/components/molecules';
import { SentenceForm } from '@src/components/shared';
import { useHelpCenterUrl } from '@src/libs/help';
import {
  useAuthData,
  useCopy,
  useDeepCompareEffect,
  useIntervalIncrement,
  usePageLayout,
  useQueryHelper,
} from '@src/libs/hooks';
import {
  useGenerateMarketplacePostCaptionMutation,
  useGenerateTikTokSpecialPostCaptionMutation,
  useGetGeneratedMarketplacePostCaptionQuery,
  useGetGeneratedTikTokSpecialPostCaptionQuery,
} from '@src/graphql/hooks';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import {
  GenerateMarketplacePostCaptionInput,
  GenerateTikTokSpecialPostCaptionInput,
  GetGeneratedMarketplacePostCaptionInput,
  GetGeneratedMarketplacePostCaptionPayload,
  GetGeneratedMarketplacePostCaptionQuery,
  GetGeneratedPostCaptionRecentRequestStatus,
  GetGeneratedTikTokSpecialPostCaptionInput,
  GetGeneratedTikTokSpecialPostCaptionQuery,
} from '@src/__generated__/globalTypes';

export enum CaptionGeneratorTabs {
  CUSTOMIZE = 'CUSTOMIZE',
  GENERATE_NOW = 'GENERATE_NOW',
}

interface CaptionGeneratorProps {
  isTikTokSpecialCampaign?: boolean;
  marketplaceId: string;
}

const CaptionGenerator = ({ isTikTokSpecialCampaign, marketplaceId }: CaptionGeneratorProps) => {
  const [caption, setCaption] = useState<string>('');
  const [generatedPostCaption, setGeneratedPostCaption] = useState<GetGeneratedMarketplacePostCaptionPayload | null>(
    null
  );
  const [isManualGenerate, setIsManualGenerate] = useState<boolean>(false);
  const [isGenerating, setIsGenerating] = useState<boolean>(true);
  const [selectedTab, setTab] = useState<CaptionGeneratorTabs>(CaptionGeneratorTabs.GENERATE_NOW);
  const { limitedLogin } = useAuthData();
  const { isCopying, handleCopyHasText } = useCopy();
  const { learnAboutAIFeature } = useHelpCenterUrl();
  const { counter, start, stop } = useIntervalIncrement({ maxCount: 99, milliseconds: 500 });
  const { isMobileView } = usePageLayout();
  const { enqueueSnackbar, t } = useQueryHelper();
  const useGetGeneratedPostCaptionQuery = isTikTokSpecialCampaign
    ? useGetGeneratedTikTokSpecialPostCaptionQuery
    : useGetGeneratedMarketplacePostCaptionQuery;
  const { data } = useGetGeneratedPostCaptionQuery({
    // pollInterval not working with skip after rerender, set 0 to stop polling
    pollInterval: isGenerating ? 5000 : 0,
    skip: !isGenerating,
    variables: {
      input: {
        ...(isTikTokSpecialCampaign
          ? { tikTokSpecialCampaignId: marketplaceId }
          : { marketplaceCampaignId: marketplaceId }),
      } as GetGeneratedMarketplacePostCaptionInput & GetGeneratedTikTokSpecialPostCaptionInput,
    },
  });

  const [generateMarketplacePostCaption] = useGenerateMarketplacePostCaptionMutation();
  const [generateTikTokPostCaption] = useGenerateTikTokSpecialPostCaptionMutation();
  const captionGeneratorTabs = [
    { title: 'Tab.Generate Now', value: CaptionGeneratorTabs.GENERATE_NOW },
    { title: 'Tab.Customize', value: CaptionGeneratorTabs.CUSTOMIZE },
  ];

  const dataGetGeneratedPostCaption =
    (data as GetGeneratedMarketplacePostCaptionQuery)?.getGeneratedMarketplacePostCaption ||
    (data as GetGeneratedTikTokSpecialPostCaptionQuery)?.getGeneratedTikTokSpecialPostCaption;
  useDeepCompareEffect(() => {
    if (dataGetGeneratedPostCaption) {
      // if Dify is not working, recall query getGeneratedMarketplacePostCaption might get the same result with status 'ERROR'
      // to prompt error message & stop polling the API
      if (
        isEqual(generatedPostCaption, dataGetGeneratedPostCaption) &&
        generatedPostCaption?.recentRequestStatus === GetGeneratedPostCaptionRecentRequestStatus.ERROR
      ) {
        enqueueSnackbar(t('Caption generation failed'), { variant: 'error' });
        stopInterval();
      } else {
        setGeneratedPostCaption(dataGetGeneratedPostCaption);
      }
    }
  }, [dataGetGeneratedPostCaption]);

  useDeepCompareEffect(() => {
    if (
      [
        GetGeneratedPostCaptionRecentRequestStatus.ERROR,
        GetGeneratedPostCaptionRecentRequestStatus.NOT_REQUESTED,
        GetGeneratedPostCaptionRecentRequestStatus.SUCCEEDED,
      ].includes(generatedPostCaption?.recentRequestStatus as GetGeneratedPostCaptionRecentRequestStatus)
    ) {
      if (
        isManualGenerate &&
        generatedPostCaption?.recentRequestStatus === GetGeneratedPostCaptionRecentRequestStatus.ERROR
      ) {
        enqueueSnackbar(t('Caption generation failed'), { variant: 'error' });
      }
      stopInterval();
    }
  }, [generatedPostCaption]);

  const stopInterval = () => {
    stop();
    setIsGenerating(false);
  };

  const onClickGenerateCaption = async () => {
    setIsManualGenerate(true);
    setIsGenerating(true);
    start();
    try {
      const generatePostCaption = isTikTokSpecialCampaign ? generateTikTokPostCaption : generateMarketplacePostCaption;
      await generatePostCaption({
        variables: {
          input: {
            ...(isTikTokSpecialCampaign
              ? { tikTokSpecialCampaignId: marketplaceId }
              : { marketplaceCampaignId: marketplaceId }),
            ...(caption && { userInputCaption: caption }),
          } as GenerateMarketplacePostCaptionInput & GenerateTikTokSpecialPostCaptionInput,
        },
      });
    } catch (err) {
      stopInterval();
      enqueueSnackbar(t(err.message || 'Caption generation failed'), { variant: 'error' });
    }
  };

  const onClickSetTab = (tab: CaptionGeneratorTabs) => {
    setCaption('');
    setTab(tab);
  };

  return (
    <div css={styles.captionGenerator}>
      <div className="info-container">
        <div className="title">
          <label>{t('Label.Caption Generator by AI')}</label>
          <div>{t('Beta')}</div>
        </div>
        <div className="description">{t('Annotation.Just click Generate and let AI create a post')}</div>
        <div className="link">
          <Icomoon color={THEME.colors.blue.main} icon="info-circle" size={14} />
          <Link target="_blank" to={learnAboutAIFeature}>
            {t('Annotation.Learn more about AI feature')}
          </Link>
        </div>
      </div>
      <div css={{ alignItems: 'center', borderBottom: THEME.box.borders.gray.main, display: 'flex' }}>
        {captionGeneratorTabs.map(({ title: tabTitle, value }) => {
          const isActive = selectedTab === value;

          return (
            <GeneratorCaptionTab isActive={isActive} key={value} onClick={() => onClickSetTab(value)}>
              {t(tabTitle)}
            </GeneratorCaptionTab>
          );
        })}
      </div>
      <div css={{ padding: isMobileView ? 16 : 32 }}>
        {selectedTab === CaptionGeneratorTabs.CUSTOMIZE && (
          <div className="caption-container">
            <label>{t('Annotation.By pasting your usual post caption')}</label>
            <SentenceForm
              disabled={isGenerating || !generatedPostCaption?.attemptsRemaining}
              placeholder="AI caption placeholder"
              title="Input your caption"
              value={caption}
              onChange={e => setCaption(e.target.value)}
            />
          </div>
        )}
        <div className="generate-button-container">
          <GenerateButton
            disabled={isGenerating || limitedLogin}
            isNotAvailable={!generatedPostCaption?.attemptsRemaining}
            onClick={onClickGenerateCaption}
          >
            <div>
              {(!!generatedPostCaption?.attemptsRemaining || isGenerating) && (
                <Icomoon icon="generate-caption-stars" size={16} />
              )}
              <label>
                {isGenerating
                  ? `${t('Button.Generating')} ${counter}%`
                  : !generatedPostCaption?.attemptsRemaining
                  ? t('Button.not available')
                  : t('Button.Generate')}
              </label>
            </div>
          </GenerateButton>
          <label className="remaining-count">
            <Trans i18nKey="Generate Count" values={{ count: generatedPostCaption?.attemptsRemaining }} />
          </label>
        </div>
        {generatedPostCaption?.generatedCaption && (
          <div className="generated-caption-container">
            <label className="label">{t('Label.Generated Caption')}</label>
            <Markdown className="generated-caption">{generatedPostCaption.generatedCaption}</Markdown>
            <ThemeButton
              disabled={isCopying}
              text="Copy"
              onClick={() => handleCopyHasText(generatedPostCaption.generatedCaption || '')}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const GenerateButton = styled.div<{ disabled: boolean; isNotAvailable: boolean }>(({ disabled, isNotAvailable }) => ({
  alignItems: 'center',
  background:
    disabled || isNotAvailable ? THEME.colors.gray.eef3f7 : 'conic-gradient(#ffd235, #ff942e, #5c5bff, #15d4ff)',
  borderRadius: THEME.box.borderRadius.m,
  cursor: 'pointer',
  display: 'grid',
  height: 48,
  justifyItems: 'center',
  maxWidth: 424,
  width: '100%',
  ...((disabled || isNotAvailable) && { pointerEvents: 'none' }),

  '& > div': {
    alignItems: 'center',
    background: disabled || isNotAvailable ? THEME.colors.gray.eef3f7 : THEME.colors.white,
    borderRadius: THEME.box.borderRadius.m,
    color: isNotAvailable
      ? THEME.font.colors.white
      : disabled
      ? THEME.font.colors.gray.a8b4bf
      : THEME.font.colors.gray.main,
    display: 'flex',
    fontSize: THEME.font.sizes.subHeading,
    fontWeight: 600,
    gap: THEME.box.gaps.s,
    height: 46,
    justifyContent: 'center',
    maxWidth: 422,
    width: '100%',

    '& > label': {
      cursor: 'pointer',
    },
  },
}));

const GeneratorCaptionTab = styled.div<{ isActive: boolean }>(({ isActive }) => ({
  alignItems: 'center',
  color: isActive ? THEME.font.colors.black.main : THEME.font.colors.gray.a8b4bf,
  cursor: 'pointer',
  display: 'grid',
  fontSize: THEME.font.sizes.subHeading,
  fontWeight: 600,
  height: 50,
  justifyContent: 'center',
  margin: '0 32px',
  width: 'fill-available',
  ...(isActive && { borderBottom: '2px solid #27313b' }),
}));

const styles = {
  captionGenerator: css({
    border: THEME.box.borders.gray.main,
    borderRadius: THEME.box.borderRadius.xl,
    boxShadow: THEME.box.shadows.outer,
    overflow: 'hidden',
    width: '100%',

    '&::before': {
      background: 'linear-gradient(101deg, #5C5BFF 29.39%, #15D4FF 43.46%, #FFD235 66.45%, #FF942E 82.33%)',
      content: '""',
      display: 'block',
      height: 8,
    },

    '& > .info-container': {
      background:
        'conic-gradient(from 136deg at 54.7% 53.44%, rgba(92, 91, 255, 0.08) 0deg, rgba(21, 212, 255, 0.08) 144.0000021457672deg, rgba(255, 210, 53, 0.08) 251.99999570846558deg, rgba(255, 148, 46, 0.08) 360deg), #FFF',
      padding: 32,

      '& > .title': {
        alignItems: 'center',
        color: THEME.font.colors.black.main,
        display: 'flex',
        fontSize: THEME.font.sizes.subHeading,
        fontWeight: 600,
        gap: THEME.box.gaps.s,
        marginBottom: 16,

        [`@media (min-width: ${ViewportType.TABLET}px)`]: {
          fontSize: THEME.font.sizes.heading,
        },

        '& > div': {
          border: '1px solid #acacac',
          borderRadius: THEME.box.borderRadius.xs,
          color: '#acacac',
          fontSize: THEME.font.sizes.subordinate,
          fontWeight: 600,
          padding: '0 6px',
        },
      },

      '& > .description': {
        color: THEME.font.colors.gray[596570],
        fontSize: THEME.font.sizes.subHeading,
        marginBottom: 8,
      },

      '& > .link': {
        alignItems: 'center',
        display: 'flex',
        gap: THEME.box.gaps.xs,

        '& > a': {
          color: THEME.font.colors.blue.main,
          fontSize: THEME.font.sizes.normal,
        },
      },
    },

    '& .caption-container': {
      color: THEME.font.colors.gray[596570],
      display: 'grid',
      fontSize: THEME.font.sizes.subHeading,
      gap: THEME.box.gaps.xxl,
      marginBottom: 16,

      '& > div': {
        '& > div > div': {
          color: THEME.font.colors.black.main,
          fontSize: THEME.font.sizes.subHeading,
        },

        '& > textarea': {
          borderRadius: THEME.box.borderRadius.m,
        },
      },
    },

    '& .generate-button-container': {
      display: 'grid',
      gap: THEME.box.gaps.s,
      justifyItems: 'center',

      '& > .remaining-count': {
        color: THEME.font.colors.gray.a8b4bf,
        fontSize: THEME.font.sizes.subordinate,
      },
    },

    '& .generated-caption-container': {
      borderTop: THEME.box.borders.gray.eef3f7,
      display: 'grid',
      gap: THEME.box.gaps.l,
      paddingTop: 24,
      marginTop: 24,

      [`@media (min-width: ${ViewportType.TABLET}px)`]: {
        paddingTop: 32,
        marginTop: 32,
      },

      '& > .label': {
        color: THEME.font.colors.black.main,
        fontSize: THEME.font.sizes.subHeading,
        fontWeight: 600,
      },

      '& > .generated-caption': {
        color: THEME.font.colors.gray[596570],
        fontSize: THEME.font.sizes.subHeading,
        wordBreak: 'break-word',
      },

      '& > button': {
        borderRadius: THEME.box.borderRadius.m,
        color: THEME.font.colors.gray.main,
        fontSize: THEME.font.sizes.subHeading,
        fontWeight: 600,
        height: 48,
        justifySelf: 'center',
        maxWidth: 424,
        width: '100%',
      },
    },
  }),
};

export default CaptionGenerator;
