import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { CheckBox, Switch } from '@src/components/atoms';
import { ThemeButton } from '@src/components/molecules';
import EditAccess, { defaultWidth } from '@src/components/shared/TiktokInstructions/EditAccess';
import ManageAccess from '@src/components/shared/TiktokInstructions/ManageAccess';
import { useDimensions } from '@src/libs/hooks';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';

interface TiktokGrantPermissionProps {
  isConnect?: boolean;
  onClickConnect?: () => void;
  onClickReconnect?: () => void;
}

const TiktokGrantPermission = ({ isConnect, onClickConnect, onClickReconnect }: TiktokGrantPermissionProps) => {
  const [confirmedNotification, setConfirmedNotification] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation();
  const { width } = useDimensions(containerRef);

  const imageWidth = width / 2 - 30;
  const imageRequiredWidth = imageWidth > defaultWidth ? defaultWidth : imageWidth;

  const steps = [
    {
      description: <StepDescription>{t('Annotation.Click Edit access')}</StepDescription>,
      image: <EditAccess width={imageRequiredWidth} />,
    },
    {
      description: (
        <StepDescription>
          <div>{t('Annotation.Turn on toggle')}</div>
          <Switch checked css={styles.switch} color="#66dca0" />
        </StepDescription>
      ),
      image: <ManageAccess width={imageRequiredWidth} />,
    },
  ];

  return (
    <div css={{ display: 'flex', justifyContent: 'center' }}>
      <div css={styles.contentContainer}>
        <div css={{ padding: 24 }}>
          <div css={styles.title}>{t('pageTitle.Grant permission')}</div>
          <div css={styles.description}>
            {t(
              'Annotation.In order to fetch your post to the campaign, AnyCreator needs access to your public information'
            )}
          </div>

          <div css={styles.tiktokInfoContainer} ref={containerRef}>
            <div>{t('Tutorial.Text.TiktokGrantPermissionTwo')}</div>
            <div>{t('Annotation.Read your public videos on TikTok')}</div>
            <div>{t('Annotation.TikTok’s access permission page')}</div>
            <div>
              {steps.map((step, index) => {
                const { description, image } = step;

                return (
                  <div css={styles.stepContainer} key={index}>
                    <div>{index + 1}</div>
                    <div>{image}</div>
                    <div>{description}</div>
                  </div>
                );
              })}
            </div>
          </div>

          <div css={{ display: 'flex', justifyContent: 'center' }}>
            <CheckBox
              checked={confirmedNotification}
              label="Label.I have confirmed this notification"
              onChange={val => setConfirmedNotification(val)}
            />
          </div>
        </div>

        <div css={styles.actionContainer}>
          <div css={styles.buttonContainer}>
            <ThemeButton
              disabled={!confirmedNotification}
              text={isConnect ? 'Connect' : 'Reconnect'}
              theme="blue"
              onClick={isConnect ? onClickConnect : onClickReconnect}
            />
            <div>*{t('Annotation.Enable button with checking a checkbox below')}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const StepDescription = styled.div({
  alignItems: 'center',
  color: THEME.font.colors.black.main,
  display: 'flex',
  fontSize: THEME.font.sizes.normal,
  fontWeight: 700,
  gap: THEME.box.gaps.xs,
});

const styles = {
  actionContainer: css({
    borderTop: '1px solid #dee5ec',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: 16,
  }),
  buttonContainer: css({
    display: 'grid',
    gap: THEME.box.gaps.xs,
    width: 280,

    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      width: '100%',
    },

    '& > button': {
      [`@media (max-width: ${ViewportType.TABLET}px)`]: {
        height: 40,
      },
    },

    '& > div': {
      color: THEME.font.colors.gray.main,
      fontSize: 11,
    },
  }),
  contentContainer: css({
    backgroundColor: THEME.colors.white,
    borderRadius: 5,
    boxShadow: THEME.box.shadows.outer,
    width: 750,
  }),
  description: css({
    color: THEME.font.colors.black.main,
    fontSize: THEME.font.sizes.normal,
    marginBottom: 24,
  }),
  stepContainer: css({
    position: 'relative',

    '& > div:nth-of-type(1)': {
      alignItems: 'center',
      backgroundColor: THEME.colors.black.main,
      borderRadius: '50%',
      color: THEME.font.colors.white,
      display: 'flex',
      fontSize: THEME.font.sizes.subordinate,
      fontWeight: 600,
      height: 24,
      justifyContent: 'center',
      left: -10,
      position: 'absolute',
      top: -10,
      width: 24,
      zIndex: 1,
    },

    '& > div:nth-of-type(2)': {
      marginBottom: 8,
    },

    '& > div:nth-of-type(3)': {
      display: 'flex',
      justifyContent: 'center',
    },
  }),
  switch: css({
    '& > button': {
      height: 18,
      width: 42,

      '& > span': {
        height: 15,
        transform: 'translate(18px) !important',
        width: 15,
      },

      '& > label': {
        fontSize: THEME.font.sizes.hint,
        top: 3,

        '[dir="rtl"] &': {
          right: 22,
        },
      },

      '[dir="rtl"] &': {
        direction: 'ltr',
      },
    },
  }),
  tiktokInfoContainer: css({
    border: THEME.box.borders.gray.main,
    borderRadius: 5,
    marginBottom: 32,
    padding: '24px 16px',
    textAlign: 'center',

    '& > div:nth-of-type(1)': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.normal,
      marginBottom: 8,
    },

    '& > div:nth-of-type(2)': {
      borderBottom: '1px solid #eef3f7',
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.normal,
      fontWeight: 700,
      marginBottom: 24,
      paddingBottom: 16,
    },

    '& > div:nth-of-type(3)': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.normal,
      marginBottom: 24,
    },

    '& > div:nth-of-type(4)': {
      display: 'flex',
      gap: THEME.box.gaps.m,
      justifyContent: 'center',
    },
  }),
  title: css({
    color: THEME.font.colors.black.main,
    fontSize: THEME.font.sizes.heading,
    fontWeight: 600,
    marginBottom: 16,
  }),
};

export default TiktokGrantPermission;
