import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { CheckBox, Icomoon } from '@src/components/atoms';
import { CrossPlatformDrawer } from '@src/components/shared';
import { useDeepCompareEffect } from '@src/libs/hooks';
import { THEME } from '@src/libs/theme';
import {
  SearchJobFilterParticipationType,
  SearchJobFilterRevenueCondition,
  SearchJobFilterRevenueType,
} from '@src/__generated__/globalTypes';
import Switch from './Switch';

export interface FilterItems {
  participationTypes: string;
  revenueConditions: string;
  revenueTypes: string;
  showOnlyAvailableJobs: string;
}

interface InputFilterProps {
  filter: FilterItems;
  setFilter: (items: FilterItems) => void;
}

const InputFilter = ({ filter, setFilter }: InputFilterProps) => {
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [inputFilter, setInputFilter] = useState<FilterItems>(filter);
  const { t } = useTranslation();
  const revenueType = [
    { title: 'Affiliate Campaign', value: SearchJobFilterRevenueType.AFFILIATE_CAMPAIGN },
    { title: 'Post Campaign', value: SearchJobFilterRevenueType.POST_CAMPAIGN },
  ];
  const revenueConditions = [
    { title: 'per Post', value: SearchJobFilterRevenueCondition.PER_POST },
    { title: 'per Likes', value: SearchJobFilterRevenueCondition.PER_LIKE },
    { title: 'per View', value: SearchJobFilterRevenueCondition.PER_VIEW },
    { title: 'per Share', value: SearchJobFilterRevenueCondition.PER_SHARE },
    { title: 'per Comment', value: SearchJobFilterRevenueCondition.PER_COMMENT },
    { title: 'per Conversation', value: SearchJobFilterRevenueCondition.PER_CONVERSION },
    { title: 'per Click', value: SearchJobFilterRevenueCondition.PER_CLICK },
    { title: 'per Commission Rate', value: SearchJobFilterRevenueCondition.PER_COMMISSION_RATE },
  ];
  const participationType = [
    { title: 'Selection Campaign', value: SearchJobFilterParticipationType.SELECTION_CAMPAIGN },
    { title: 'Open Campaign', value: SearchJobFilterParticipationType.OPEN_CAMPAIGN },
  ];
  const isAllRevenueConditions = revenueConditions.every(condition =>
    inputFilter.revenueConditions.split(',').includes(condition.value)
  );
  const isFilter = filter.participationTypes || filter.revenueConditions || filter.revenueTypes;

  useDeepCompareEffect(() => {
    setInputFilter(filter);
  }, [filter]);

  const onChange = (field: keyof FilterItems, checked: boolean, value: string) => {
    const items = { ...inputFilter };
    const array = items[field].split(',');
    if (checked) {
      array.push(value);
    } else {
      const index = array.indexOf(value);
      if (index > -1) {
        array.splice(index, 1);
      }
    }
    items[field] = array.join(',');
    setInputFilter(items);
  };

  const onChangeAllRevenueConditions = (checked: boolean) => {
    const items = { ...inputFilter };
    if (checked) {
      items.revenueConditions = revenueConditions.map(condition => condition.value).join(',');
    } else {
      items.revenueConditions = '';
    }
    setInputFilter(items);
  };

  const onClickApply = () => {
    setFilter(inputFilter);
    setDrawerOpen(false);
  };

  const onClose = () => {
    setDrawerOpen(false);
    setInputFilter(filter);
  };

  return (
    <div css={styles.input}>
      <CrossPlatformDrawer
        open={drawerOpen}
        onClose={onClose}
        onClickApply={onClickApply}
        onClickClearConditions={() =>
          setInputFilter({
            ...inputFilter,
            participationTypes: '',
            revenueConditions: '',
            revenueTypes: '',
          })
        }
      >
        <Title>{t('Revenue Type')}</Title>
        <Options>
          {revenueType.map(({ title, value }, index) => {
            const isChecked = inputFilter.revenueTypes.split(',').includes(value);

            return (
              <div key={index}>
                <label>{t(`Option.${title}`)}</label>
                <CheckBox
                  checked={isChecked}
                  color={THEME.colors.red.fe6565}
                  onChange={checked => onChange('revenueTypes', checked, value)}
                />
              </div>
            );
          })}
        </Options>

        <Title>{t('Revenue Conditions')}</Title>
        <Options>
          <div css={styles.option}>
            <label css={isAllRevenueConditions && { color: THEME.colors.red.fe6565, fontWeight: 600 }}>
              {t('All Revenue Conditions')}
            </label>
            <CheckBox
              checked={isAllRevenueConditions}
              color={THEME.colors.red.fe6565}
              onChange={checked => onChangeAllRevenueConditions(checked)}
            />
          </div>
          {revenueConditions.map(({ title, value }, index) => {
            const isChecked = inputFilter.revenueConditions.split(',').includes(value);

            return (
              <div key={index}>
                <label>{t(`Option.${title}`)}</label>
                <CheckBox
                  checked={isChecked}
                  color={THEME.colors.red.fe6565}
                  onChange={checked => onChange('revenueConditions', checked, value)}
                />
              </div>
            );
          })}
        </Options>

        <Title>{t('Participation Type')}</Title>
        <Options>
          {participationType.map(({ title, value }, index) => {
            const isChecked = inputFilter.participationTypes.split(',').includes(value);

            return (
              <div key={index}>
                <label>{t(`Option.${title}`)}</label>
                <CheckBox
                  checked={isChecked}
                  color={THEME.colors.red.fe6565}
                  onChange={checked => onChange('participationTypes', checked, value)}
                />
              </div>
            );
          })}
        </Options>
      </CrossPlatformDrawer>

      <label className="label">{t('Show only available jobs')}</label>
      <Switch
        checked={Boolean(filter.showOnlyAvailableJobs)}
        onChange={checked =>
          setFilter({
            ...filter,
            showOnlyAvailableJobs: checked ? 'true' : '',
          })
        }
      />
      <div className="filter">
        <Icomoon
          color={isFilter ? THEME.colors.red.fe6565 : THEME.colors.gray.main}
          icon="air-filter"
          size={26}
          onClick={() => setDrawerOpen(true)}
        />
      </div>
    </div>
  );
};

const Options = styled.div({
  background: THEME.colors.gray.eef3f7,
  border: THEME.box.borders.gray.eef3f7,
  display: 'grid',
  gap: 1,

  '& > div': {
    alignItems: 'center',
    background: THEME.colors.white,
    color: THEME.font.colors.black.main,
    display: 'flex',
    fontSize: THEME.font.sizes.subHeading,
    gap: THEME.box.gaps.s,
    padding: '12px 24px',

    '& > label': {
      width: 'fill-available',
    },
  },
});

const Title = styled.div({
  background: THEME.colors.gray.f6f8fa,
  color: THEME.font.colors.gray.a8b4bf,
  fontSize: THEME.font.sizes.normal,
  fontWeight: 600,
  padding: '16px 24px',
});

const styles = {
  input: css({
    alignItems: 'center',
    border: THEME.box.borders.gray.c5d0da,
    borderRadius: THEME.box.borderRadius.m,
    display: 'flex',
    gap: THEME.box.gaps.s,
    padding: '8px 16px',

    '& > .label': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.subordinate,
      minWidth: 'max-content',
    },

    '& > .filter': {
      alignItems: 'center',
      display: 'flex',
      cursor: 'pointer',
      gap: THEME.box.gaps.s,
      justifyContent: 'flex-end',
      width: 'fill-available',

      '&::before': {
        background: THEME.colors.gray.d9d9d9,
        content: '""',
        height: 32,
        width: 1,
      },
    },
  }),
  option: css({
    alignItems: 'center',
    background: THEME.colors.white,
    color: THEME.font.colors.black.main,
    display: 'flex',
    fontSize: THEME.font.sizes.subHeading,
    gap: THEME.box.gaps.s,
    padding: '12px 24px',

    '& > label': {
      width: 'fill-available',
    },
  }),
};

export default InputFilter;
