import React from 'react';
import { useSearchParams, Navigate } from 'react-router-dom';
import { css } from '@emotion/react';
import { BackNavigator, Pager, ThemeButton } from '@src/components/molecules';
import { getCurrentPage, getOffset, getPageInfo } from '@src/components/molecules/Pager/helpers';
import {
  useGetLineBroadcastsCountQuery,
  useGetLineBroadcastsQuery,
  useGetLineBroadcastSummaryQuery,
} from '@src/graphql/hooks';
import { LIMIT_10 } from '@src/libs/constant';
import { useAuthData, useFilter, usePageLayout } from '@src/libs/hooks';
import { THEME } from '@src/libs/theme';
import useGetLineChannels from '@src/pages/MyPage/Message/Chat/useGetLineChannels';
import { ROUTES } from '@src/shared/routes';
import { LineBroadcastStatus } from '@src/__generated__/globalTypes';
import BroadcastList from './BroadcastList';
import BroadcastListFilter, { FilterItems } from './BroadcastListFilter';
import BroadcastListSummary from './BroadcastListSummary';

const Listing = () => {
  const { limitedLogin } = useAuthData();
  if (limitedLogin) {
    return <Navigate to={ROUTES.MY_PAGE} />;
  }

  const { hasNoLineConnected, lineConnectStatus } = useGetLineChannels();
  const { isMobileView } = usePageLayout();
  const [searchParams] = useSearchParams();
  const { filter, setFilter } = useFilter({
    from: searchParams.get('from') || '',
    keyword: searchParams.get('keyword') || '',
    status: (searchParams.get('status') || '') as LineBroadcastStatus,
    tag: searchParams.get('tag') || '',
    to: searchParams.get('to') || '',
  });
  const currentPage = getCurrentPage();
  const isEmptyFilter = !Object.keys(filter).find(key => !!filter[key as keyof FilterItems]);
  const { from, keyword, status, tag, to } = filter;

  const { data, loading, error } = useGetLineBroadcastsQuery({
    skip: hasNoLineConnected,
    variables: {
      input: {
        limit: LIMIT_10, // in AnyChat they use 10 as default
        offset: getOffset(currentPage, LIMIT_10),
        ...(from ? { from } : {}),
        ...(keyword ? { keyword } : {}),
        ...(status ? { status } : {}),
        ...(tag ? { tag } : {}),
        ...(to ? { to } : {}),
      },
    },
  });
  const { data: dataLineBroadcastsCount } = useGetLineBroadcastsCountQuery({
    variables: {
      input: {
        ...(from ? { from } : {}),
        ...(keyword ? { keyword } : {}),
        ...(status ? { status } : {}),
        ...(tag ? { tag } : {}),
        ...(to ? { to } : {}),
      },
    },
  });
  const { data: dataSummary } = useGetLineBroadcastSummaryQuery({
    skip: hasNoLineConnected,
    variables: {
      input: {
        ...(from ? { from } : {}),
        ...(to ? { to } : {}),
      },
    },
  });

  const lineBroadcastSummary = dataSummary?.getLineBroadcastSummary;
  const lineBroadcastItems = data?.getLineBroadcasts?.broadcasts || [];
  const totalItems = dataLineBroadcastsCount?.getLineBroadcastsCount?.total || 0;

  const { firstIndex, lastIndex, totalPages } = getPageInfo(currentPage, totalItems, LIMIT_10);

  return (
    <div css={styles.container}>
      {!isMobileView && (
        <div css={styles.navigatorContainer}>
          <BackNavigator title="Broadcast for LINE" />
          <ThemeButton
            css={{ width: 'fit-content' }}
            text="Add Broadcast"
            theme="blue"
            to={ROUTES.MY_PAGE_MESSAGE_BROADCAST_ADD}
          />
        </div>
      )}

      <div css={styles.detailsContainer}>
        <BroadcastListSummary lineBroadcastSummary={lineBroadcastSummary} />
        <BroadcastListFilter filter={filter as FilterItems} setFilter={setFilter} />
        <BroadcastList
          error={error}
          loading={loading}
          lineConnectStatus={lineConnectStatus}
          items={lineBroadcastItems}
          isEmptyFilter={isEmptyFilter}
        />
      </div>

      <Pager
        currentPage={currentPage}
        first={firstIndex}
        last={lastIndex}
        totalCount={totalItems}
        totalPages={totalPages}
      />
    </div>
  );
};

const styles = {
  container: css({
    padding: 24,
  }),
  detailsContainer: css({
    borderRadius: 3,
    boxShadow: THEME.box.shadows.outer,
  }),
  navigatorContainer: css({
    alignItems: 'center',
    display: 'flex',
    marginBottom: 16,

    '& > div:nth-of-type(1)': {
      flex: 1,
    },
  }),
};

export default Listing;
