import { useSearchParams } from 'react-router-dom';

const useUrl = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const updateSearchParams = (params: Record<string, any>) => {
    const currentSearchParams = Object.fromEntries(searchParams);
    setSearchParams({
      ...currentSearchParams,
      ...params,
    });
  };

  const validateUrl = (url?: string | null) => {
    if (!url) {
      return false;
    }

    try {
      new URL(url);

      return encodeURI(url);
    } catch {
      return false;
    }
  };

  return {
    searchParams,
    updateSearchParams,
    validateUrl,
  };
};

export default useUrl;
