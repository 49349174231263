import { useMutation } from '@apollo/client';
import * as VERIFY_TOKEN from '@src/graphql/mutations/VerifyToken.graphql';
import { UNEXPECTED_ERROR } from '@src/libs/error';
import {
  InfluencerType,
  UserRoles,
  VerifyTokenMutation,
  VerifyTokenMutationVariables,
} from '@src/__generated__/globalTypes';

export const useVerifyToken = () => {
  const [verifyTokenMutation] = useMutation<VerifyTokenMutation, VerifyTokenMutationVariables>(VERIFY_TOKEN);

  const verifyToken = async (
    token: string
  ): Promise<{
    countryId: string;
    creatorType: InfluencerType;
    limitedLogin: boolean;
    userId: number;
    role: UserRoles;
  }> => {
    try {
      const { data } = await verifyTokenMutation({
        variables: {
          input: {
            token,
          },
        },
      });

      if (!data?.verifyToken?.ok) {
        throw new Error(UNEXPECTED_ERROR);
      }

      const { countryId, creatorType, limitedLogin, role, userId } = data.verifyToken;

      if (
        !countryId ||
        !userId ||
        !role ||
        !creatorType ||
        ![UserRoles.CREATOR_STAFF, UserRoles.INFLUENCER].includes(role)
      ) {
        throw new Error(UNEXPECTED_ERROR);
      }

      return { countryId, creatorType, limitedLogin, role, userId };
    } catch (error) {
      throw new Error(error);
    }
  };

  return { verifyToken };
};
