import React from 'react';
import { useLocation } from 'react-router-dom';
import { css } from '@emotion/react';
import { InstagramInstructions } from '@src/components/shared';
import { FE_REDIRECT_MAPPING } from '@src/libs/constant';
import { useConnectSocialAuth, usePageLayout } from '@src/libs/hooks';
import { ViewportType } from '@src/libs/types';
import { ROUTES } from '@src/shared/routes';
import { SocialAccountType } from '@src/__generated__/globalTypes';

const InstagramConnect = () => {
  const { pathname } = useLocation();
  const { isYoutubeCmsPath } = usePageLayout();
  const { connectSocialAuth } = useConnectSocialAuth(FE_REDIRECT_MAPPING.CONNECT);
  const failedConnect = [
    ROUTES.MY_PAGE_SOCIAL_CONNECT_INSTAGRAM_UNABLE_CONNECT,
    ROUTES.SETTINGS_SOCIAL_CONNECT_IG_UNABLE_CONNECT,
  ].includes(pathname);
  const isMyPage = [
    ROUTES.MY_PAGE_SOCIAL_CONNECT_INSTAGRAM_CONNECT,
    ROUTES.MY_PAGE_SOCIAL_CONNECT_INSTAGRAM_UNABLE_CONNECT,
    ROUTES.YOUTUBE_CMS_MY_PAGE_SOCIAL_CONNECT_INSTAGRAM_CONNECT,
    ROUTES.YOUTUBE_CMS_MY_PAGE_SOCIAL_CONNECT_INSTAGRAM_UNABLE_CONNECT,
  ].includes(pathname);

  const onClickConnect = () => {
    connectSocialAuth(SocialAccountType.INSTAGRAM, { isNewUI: isMyPage, isYoutubeCmsPath });
  };

  return (
    <div css={styles.container}>
      <InstagramInstructions
        backPath={
          isMyPage
            ? isYoutubeCmsPath
              ? ROUTES.YOUTUBE_CMS_MY_PAGE_SOCIAL_CONNECT
              : ROUTES.MY_PAGE_SOCIAL_CONNECT
            : ROUTES.SETTINGS_SOCIAL_CONNECT
        }
        failedConnect={failedConnect}
        onClickConnect={onClickConnect}
      />
    </div>
  );
};

const styles = {
  container: css({
    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      alignItems: 'center',
      display: 'flex',
      minHeight: '100vh',
      justifyContent: 'center',
      padding: '0 24px',
    },
  }),
};

export default InstagramConnect;
