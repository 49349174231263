import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { formatNumber } from '@src/libs/format';
import { usePageLayout } from '@src/libs/hooks';
import { THEME } from '@src/libs/theme';
import ChatAvatar from '../ChatListSection/ChatAvatar';
import { ChatType } from '../ChatListSection/ChatsList/ChatInfoCard';

interface HeaderProps {
  avatar: string;
  created: string;
  currencyId: string;
  firstName?: string;
  lastName?: string;
  lastOrder: string;
  lineName: string;
  totalOrders: number;
  totalSpent: number;
}

const Header = ({ avatar, created, currencyId, lastOrder, lineName, totalOrders, totalSpent }: HeaderProps) => {
  const { t } = useTranslation();
  const { isMobileView } = usePageLayout();

  return (
    <div css={styles.container}>
      <LineInfoContainer isMobileView={isMobileView}>
        <div css={styles.avatarContainer}>
          <ChatAvatar avatar={avatar} type={ChatType.Line} />
        </div>
        <div>
          <div>{lineName}</div>
        </div>
      </LineInfoContainer>

      <CreatorInfoContainer isMobileView={isMobileView}>
        <div>
          <div>{t('Customer for')}</div>
          <div>{created ? formatDistanceToNowStrict(new Date(created + 'Z')) : '-'}</div>
        </div>
        <div>
          <div>{t('Last order')}</div>
          <div>{lastOrder ? formatDistanceToNow(new Date(lastOrder + 'Z'), { addSuffix: true }) : '-'}</div>
        </div>
        <div>
          <div>{t('Order')}</div>
          <div>{`${totalOrders} ${t('orders')}`}</div>
        </div>
        <div>
          <div>{t('Total spent')}</div>
          <div>{`${currencyId} ${formatNumber(totalSpent || null)}`}</div>
        </div>
      </CreatorInfoContainer>
    </div>
  );
};

const CreatorInfoContainer = styled.div<{ isMobileView: boolean }>(({ isMobileView }) => ({
  borderRadius: 3,
  display: 'flex',
  flexWrap: 'wrap',
  ...(!isMobileView && { border: '1px solid #eef3f7', padding: 12 }),

  '& > div': {
    boxShadow: THEME.box.shadows.outer,
    display: 'grid',
    flexBasis: '24.5%',

    '& > div:nth-of-type(1)': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.hint,
      textAlign: 'center',
    },

    '& > div:nth-of-type(2)': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.subordinate,
      fontWeight: 600,
      textAlign: 'center',
    },
  },

  '& > div:nth-of-type(4)': {
    boxShadow: 'none',
  },
}));

const LineInfoContainer = styled.div<{ isMobileView: boolean }>(({ isMobileView }) => ({
  alignItems: 'center',
  display: 'flex',
  flexWrap: 'wrap',
  marginBottom: 8,

  '& > div:nth-of-type(1)': {
    ...(isMobileView ? { display: 'grid', flexBasis: '100%', justifyContent: 'center' } : { marginRight: 16 }),
  },

  '& > div:nth-of-type(2)': {
    display: 'grid',
    flex: 1,
    height: 'fit-content',
    ...(isMobileView && { marginBottom: 16, textAlign: 'center' }),

    '& > div:nth-of-type(1)': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.heading,
      fontWeight: 600,
    },

    '& > div:nth-of-type(2)': {
      color: THEME.font.colors.gray.main,
      fontSize: THEME.font.sizes.subordinate,
    },
  },
}));

const styles = {
  avatarContainer: css({
    '& > div': {
      alignItems: 'center',
      display: 'flex',
      height: 64,
      justifyContent: 'center',
      width: 64,

      '& > img': {
        height: 53,
        right: 0,
        width: 53,
      },

      '& > svg': {
        height: '22px !important',
        right: 0,
        top: 0,
        width: '22px !important',
      },
    },
  }),
  container: css({
    backgroundColor: THEME.colors.white,
    borderBottom: '1px solid #dee5ec',
    display: 'grid',
    padding: 16,
  }),
};

export default Header;
