import React, { useState } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { arrowLeft, arrowRight } from '@src/assets/htmlCodes';
import anyCreatorBlack from '@src/assets/img/anyCreatorBlack.png';
import { CheckBox, Icomoon, Icon, Switch } from '@src/components/atoms';
import { ThemeButton } from '@src/components/molecules';
import { useDirLayout, usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { THEME } from '@src/libs/theme';
import EditAccess from './EditAccess';
import ManageAccess from './ManageAccess';

interface TiktokInstructionsProps {
  backUrl: string;
  onClickEditSettings: () => void;
}

const TiktokInstructions = ({ backUrl, onClickEditSettings }: TiktokInstructionsProps) => {
  const [confirmedNotification, setConfirmedNotification] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const { navigate, t } = useQueryHelper();
  const { isRtl } = useDirLayout();
  const { isMobileView } = usePageLayout();
  const instructions = [
    { description: 'Tutorial.Text.TiktokGrantPermissionOne', image: <EditAccess /> },
    {
      description: 'Tutorial.Text.TiktokGrantPermissionTwo',
      hint: 'Annotation.Read your public videos on TikTok',
      image: <ManageAccess isMobile />,
    },
  ];
  const steps = [
    {
      description: <StepDescription>{`${currentStep + 1}. ${t('Annotation.Click Edit access')}`}</StepDescription>,
      image: <EditAccess />,
    },
    {
      description: (
        <StepDescription>
          <div>{`${currentStep + 1}. ${t('Annotation.Turn on toggle')}`}</div>
          <Switch checked color="#66dca0" />
        </StepDescription>
      ),
      image: <ManageAccess />,
    },
  ];

  const stepDescription = steps[currentStep].description;
  const stepImage = steps[currentStep].image;

  return (
    <>
      <Container isMobileView={isMobileView}>
        <div css={{ display: 'grid', flex: 1 }}>
          {isMobileView && (
            <div css={styles.logoContainer}>
              <span onClick={() => navigate(backUrl)}>{arrowLeft}</span>
              <img alt="logo" src={anyCreatorBlack} width="165" />
            </div>
          )}
          <div css={styles.titleContainer}>
            {!isMobileView && <span onClick={() => navigate(backUrl)}>{isRtl ? arrowRight : arrowLeft}</span>}
            <div>{t('Title.Please grant permission')}</div>
          </div>
          <div css={styles.description}>
            {t(
              'Annotation.In order to fetch your post to the campaign, AnyCreator needs access to your public information'
            )}
          </div>
          <StepsContainer isMobileView={isMobileView}>
            {instructions.map((instruction, index) => {
              const { description, hint, image } = instruction;

              return (
                <div key={index}>
                  <div>
                    <div>{index + 1}</div>
                    <div>{t(description)}</div>
                  </div>
                  {hint && <span>{t(hint)}</span>}
                  {isMobileView && <div css={styles.stepImageContainer}>{image}</div>}
                </div>
              );
            })}
            {isMobileView && (
              <StepDescription css={{ justifyContent: 'center' }}>
                <div>{t('Annotation.Turn on toggle')}</div>
                <Switch checked color="#66dca0" css={styles.switch} />
              </StepDescription>
            )}
          </StepsContainer>
          <CheckBoxContainer isMobileView={isMobileView}>
            <CheckBox
              checked={confirmedNotification}
              label="Label.I have confirmed this notification"
              onChange={val => setConfirmedNotification(val)}
            />
          </CheckBoxContainer>
          {!isMobileView && (
            <ThemeButton
              disabled={!confirmedNotification}
              text="Edit Settings"
              theme="blue"
              onClick={onClickEditSettings}
            />
          )}
        </div>

        {!isMobileView && (
          <div css={styles.instructionContainer}>
            {!!currentStep && (
              <InstructureArrow css={{ left: -20 }} onClick={() => setCurrentStep(step => step - 1)}>
                <Icon>
                  <Icomoon css={{ transform: 'rotate(90deg)' }} icon="arrow-down" size={10} />
                </Icon>
              </InstructureArrow>
            )}
            <div>
              <div css={{ marginBottom: 19 }}>{stepImage}</div>
              <div>{stepDescription}</div>
            </div>
            {currentStep !== steps.length - 1 && (
              <InstructureArrow css={{ right: -20 }} onClick={() => setCurrentStep(step => step + 1)}>
                <Icon>
                  <Icomoon css={{ transform: 'rotate(270deg)' }} icon="arrow-down" size={10} />
                </Icon>
              </InstructureArrow>
            )}
          </div>
        )}
      </Container>
      {isMobileView && (
        <ActionContainer isMobileView={isMobileView}>
          <ThemeButton
            disabled={!confirmedNotification}
            text="Edit Settings"
            theme="blue"
            onClick={onClickEditSettings}
          />
        </ActionContainer>
      )}
    </>
  );
};

const ActionContainer = styled.div<{ isMobileView: boolean }>(({ isMobileView }) => ({
  backgroundColor: THEME.colors.white,
  ...(isMobileView && { borderTop: '1px solid #dee5ec', padding: '16px 32px' }),

  '& > button': {
    height: 40,
  },
}));

const CheckBoxContainer = styled.div<{ isMobileView: boolean }>(({ isMobileView }) => ({
  display: 'flex',
  marginBottom: isMobileView ? '16px' : '40px',
  ...(isMobileView && { justifyContent: 'center' }),
}));

const Container = styled.div<{ isMobileView: boolean }>(({ isMobileView }) => ({
  backgroundColor: THEME.colors.white,
  borderRadius: 5,
  boxShadow: isMobileView ? 'none' : THEME.box.shadows.outer,
  display: 'flex',
  flexWrap: 'wrap',
  gap: 54,
  padding: isMobileView ? '16px 24px' : '32px',
}));

const InstructureArrow = styled(Icon)({
  alignItems: 'center',
  backgroundColor: THEME.colors.white,
  borderRadius: '50%',
  boxShadow: THEME.box.shadows.outer,
  display: 'flex',
  height: 40,
  justifyContent: 'center',
  opacity: 1,
  position: 'absolute',
  width: 40,

  '&:hover': {
    opacity: 1,
  },
});

const StepDescription = styled.div({
  alignItems: 'center',
  color: THEME.font.colors.black.main,
  display: 'flex',
  fontSize: THEME.font.sizes.normal,
  fontWeight: 700,
  gap: THEME.box.gaps.xs,
});

const StepsContainer = styled.div<{ isMobileView: boolean }>(({ isMobileView }) => ({
  margin: isMobileView ? '36px 0 32px' : '48px 0 124px',
  ...(isMobileView && { border: THEME.box.borders.gray.main, borderRadius: 5, padding: '24px 16px' }),

  '& > div': {
    '& > div:nth-of-type(1)': {
      alignItems: 'center',
      display: 'flex',
      gap: THEME.box.gaps.s,
      ...(!isMobileView && { marginBottom: 8 }),

      '& > div:nth-of-type(1)': {
        alignItems: 'center',
        backgroundColor: THEME.colors.black.main,
        borderRadius: '50%',
        color: THEME.font.colors.white,
        display: 'flex',
        fontSize: 13,
        fontWeight: 600,
        height: 24,
        justifyContent: 'center',
        minWidth: 24,
      },

      '& > div:nth-of-type(2)': {
        color: THEME.font.colors.black.main,
        fontSize: THEME.font.sizes.normal,
        fontWeight: 700,
      },
    },

    '& > span': {
      color: THEME.font.colors.gray.main,
      fontSize: THEME.font.sizes.normal,
      fontWeight: 700,
      padding: '0 32px',
    },
  },

  '& > div:nth-of-type(2)': {
    '& > div:nth-of-type(2)': {
      padding: '16px 0',
    },
  },
}));

const styles = {
  description: css({
    color: THEME.font.colors.black.main,
    fontSize: THEME.font.sizes.normal,
  }),
  instructionContainer: css({
    alignItems: 'center',
    border: THEME.box.borders.gray.main,
    borderRadius: 5,
    display: 'grid',
    flex: 1,
    height: 456,
    justifyContent: 'center',
    position: 'relative',
  }),
  logoContainer: css({
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 32,
    position: 'relative',

    '& > span': {
      color: THEME.font.colors.blue.main,
      fontSize: 22,
      left: 0,
      position: 'absolute',
    },
  }),
  stepImageContainer: css({
    display: 'flex',
    justifyContent: 'center',
    padding: '16px 0 35px',
  }),
  switch: css({
    height: 18,
    width: 42,

    '& > .slider:before': {
      height: 15,
      left: -4,
      top: 1,
      width: 15,
    },

    '& > .label-on': {
      fontSize: THEME.font.sizes.hint,
    },
  }),
  titleContainer: css({
    alignItems: 'center',
    display: 'flex',
    gap: THEME.box.gaps.s,
    marginBottom: 16,

    '& > span': {
      color: THEME.font.colors.blue.main,
      cursor: 'pointer',
      fontSize: 22,
    },

    '& > div': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.heading,
      fontWeight: 600,
    },
  }),
};

export default TiktokInstructions;
