import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams, Link, Navigate } from 'react-router-dom';
import { css } from '@emotion/react';
import { BackNavigator } from '@src/components/molecules';
import { ViewportType } from '@src/libs/types';
import { useAuthData, useFilter, usePageLayout } from '@src/libs/hooks';
import { THEME } from '@src/libs/theme';
import { ROUTES } from '@src/shared/routes';
import { EmailListSortableField, EmailTemplateStatus, Order } from '@src/__generated__/globalTypes';
import EmailList from './EmailList';
import EmailListFilter, { FilterItems } from './EmailListFilter';

const Listings = () => {
  const { limitedLogin } = useAuthData();
  if (limitedLogin) {
    return <Navigate to={ROUTES.MY_PAGE} />;
  }

  const [searchParams] = useSearchParams();
  const { filter, setFilter } = useFilter({
    keyword: searchParams.get('keyword') || '',
    orderByField: (searchParams.get('orderByField') as EmailListSortableField) || EmailListSortableField.CREATED,
    orderBySequence: (searchParams.get('orderBySequence') as Order) || Order.DESC,
    status: (searchParams.get('status') || '') as EmailTemplateStatus,
  });
  const { t } = useTranslation();
  const { isMobileView } = usePageLayout();

  return (
    <div css={styles.container}>
      {!isMobileView && (
        <div css={styles.navigatorContainer}>
          <div>
            <BackNavigator title="Emails" />
          </div>
          <div>
            <Link to={ROUTES.MY_PAGE_EMAILS_COMPOSE}>{t('Button.Compose')}</Link>
          </div>
        </div>
      )}

      <div css={styles.emailListContainer}>
        <EmailListFilter values={filter as FilterItems} onChange={setFilter} />
        <EmailList filter={filter as FilterItems} />
      </div>
    </div>
  );
};

const styles = {
  container: css({
    padding: 24,

    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      padding: 16,
    },
  }),
  emailListContainer: css({
    marginTop: 16,
  }),
  navigatorContainer: css({
    display: 'flex',
    flexWrap: 'wrap',

    '& > div:nth-of-type(1)': {
      display: 'flex',
      flexBasis: '50%',
    },

    '& > div:nth-of-type(2)': {
      display: 'flex',
      flexBasis: '50%',
      justifyContent: 'flex-end',

      '& > a': {
        alignItems: 'center',
        backgroundColor: THEME.colors.blue.main,
        borderRadius: 3,
        color: THEME.font.colors.white,
        display: 'flex',
        fontSize: THEME.font.sizes.subordinate,
        fontWeight: 600,
        height: 32,
        justifyContent: 'center',
        width: 105,
      },
    },
  }),
};

export default Listings;
