import { v4 as uuidv4 } from 'uuid';
import React from 'react';
import { useParams } from 'react-router-dom';
import { css } from '@emotion/react';
import { ListLoading } from '@src/components/molecules';
import { FormsSubmitForm } from '@src/components/shared';
import { useGetFormTemplateQuery } from '@src/graphql/hooks';
import PrivateForm from './PrivateForm';

const SubmitForm = () => {
  const params = useParams<Record<any, string>>();
  const hash = params.hash as string;

  const { data, loading } = useGetFormTemplateQuery({
    variables: {
      input: {
        hash,
      },
    },
  });

  if (loading) {
    return <ListLoading />;
  } else if (!data?.getFormTemplate) {
    return <PrivateForm />;
  }

  const description = data?.getFormTemplate?.description || '';
  const id = data?.getFormTemplate?.id || '';
  const questions =
    data?.getFormTemplate?.questions.map(question => {
      const { id: questionId, image, isRequired, options, order, questionType, title: questionTitle } = question;

      return {
        id: questionId,
        genId: uuidv4(),
        image,
        isRequired,
        options,
        order,
        questionType,
        title: questionTitle,
      };
    }) || [];
  const thankDescription = data?.getFormTemplate?.thankDescription || '';
  const thankTitle = data?.getFormTemplate?.thankTitle || '';
  const title = data?.getFormTemplate?.title || '';

  return (
    <div css={styles.container}>
      <FormsSubmitForm
        description={description}
        hash={hash}
        id={id}
        questions={questions}
        thankDescription={thankDescription}
        thankTitle={thankTitle}
        title={title}
      />
    </div>
  );
};

const styles = {
  container: css({
    paddingBottom: 40,
  }),
};

export default SubmitForm;
