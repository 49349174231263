import { generatePath as reactRouterGeneratePath } from 'react-router-dom';

export const ROUTES = {
  ROOT: '/',
  //   sign_up
  SIGN_UP: '/signup',
  SIGN_UP_IG: '/signup/instagram',
  SIGN_UP_IG_UNABLE_CONNECT: '/signup/instagram_unable_connect',
  SIGN_UP_TIKTOK_GRANT_PERMISSION: '/signup/tiktok_grant_permission',
  SIGN_UP_FACEBOOK: '/signup/facebook',
  SIGN_UP_FACEBOOK_GRANT_PERMISSION: '/signup/facebook_grant_permission',
  SIGN_UP_SUCCESS: '/signup/success',
  SIGN_UP_PROFILE: '/signup/profile',
  SIGN_UP_CONNECT: '/signup/connect',

  //   sign_in
  SIGN_IN: '/signin',
  SIGN_IN_IG_UNABLE_CONNECT: '/signin/instagram_unable_connect',
  SIGN_IN_TIKTOK_GRANT_PERMISSION: '/signin/tiktok_grant_permission',
  SIGN_IN_FACEBOOK_GRANT_PERMISSION: '/signin/facebook_grant_permission',
  SIGN_IN_STAFF: '/signin/staff',

  //   password
  PASSWORD_FORGOT: '/password_forgot',
  PASSWORD_REGISTER_TOKEN: '/password_register/:token',
  PASSWORD_RESET_TOKEN: '/password_reset/:token',

  //   redirect
  REDIRECT_SIGN_UP_ENABLED_PROVIDER: '/redirect/signup-enabled/:provider',
  REDIRECT_RECONNECT_IG: '/redirect/reconnect_ig',
  REDIRECT_TIKTOK_RECONNECT: '/redirect/tiktok_reconnect/:provider',
  REDIRECT_TWITTER: '/redirect/twitter',
  REDIRECT_SIGN_UP_PROVIDER: '/redirect/signup/:provider',
  REDIRECT_SIGN_IN_PROVIDER: '/redirect/signin/:provider',
  REDIRECT_SIGN_UP_CONNECT_PROVIDER: '/redirect/signup-connect/:provider',
  REDIRECT_CONNECT_PROVIDER: '/redirect/connect/:provider',
  REDIRECT_ANALYTICS_RECONNECT_PROVIDER: '/redirect/analytics-reconnect/:provider',
  REDIRECT_SHOPIFY_CONNECT: '/redirect/shopify_connect',
  REDIRECT_SIGN_IN_WITH_LINE_PROVIDER: '/redirect/signin_with_line/:provider',
  REDIRECT_LINK_IN_BIO_CONNECT: '/redirect/link_in_bio_connect/:provider',
  REDIRECT_MOBILE_SOCIAL_AUTH: '/redirect/mobile_social_auth/:provider',
  REDIRECT_MOBILE_TIKTOK_CONNECT: '/redirect/mobile_tiktok_connect/:provider',
  REDIRECT_TTCM_RECONNECT: '/redirect/ttcm_reconnect/:provider',

  //   reconnect
  RECONNECT: '/reconnect',

  //   Link In Bio
  LINK_IN_BIO: '/link_in_bio',
  LINK_IN_BIO_LINKS: '/link_in_bio/links',
  LINK_IN_BIO_THEME_COLOR: '/link_in_bio/theme_color',

  //   Analytics
  ANALYTICS: '/analytics',
  ANALYTICS_LINK_IN_BIO: '/analytics/mylink',
  ANALYTICS_ACTIVE_TAB: '/analytics:active_tab',
  ANALYTICS_COMPARE: '/analytics:compare',
  ANALYTICS_IG_POST_FEED: '/analytics/instagram_feed_post',
  ANALYTICS_IG_REEL_POSTS: '/analytics/instagram_reel_posts',
  ANALYTICS_IG_STORY_FEED: '/analytics/instagram_story_feed',
  ANALYTICS_FB_POST_FEED: '/analytics/facebook_feed_post',
  ANALYTICS_YT_VIDEOS_FEED: '/analytics/feed_videos',
  ANALYTICS_YT_TAG_RANKING_LIST: '/analytics/tag_ranking_list',
  ANALYTICS_YT_COMPARE_VIDEOS_FEED: '/analytics:compare/youtube_compare_feed_videos',
  ANALYTICS_IG_INTERACTION: '/analytics:ig_interaction',
  ANALYTICS_IG_INTERACTION_USER_ANALYSIS: '/analytics:ig_interaction/user_analysis',
  ANALYTICS_IG_INTERACTION_TAGGED_POSTS: '/analytics:ig_interaction/tagged_posts',
  ANALYTICS_IG_INTERACTION_MENTIONED_POSTS: '/analytics:ig_interaction/mentioned_posts',

  // initial routes
  INITIAL_ENGAGEMENT_JOB_ID: '/job/engagement/:id',
  INITIAL_ENGAGEMENT_JOB_ID_POST: '/job/engagement/:id/post',
  INITIAL_ENGAGEMENT_JOB_ID_REPORT: '/job/engagement/:id/report',
  INITIAL_MARKETPLACE_JOB_ID: '/job/marketplace/:id',
  INITIAL_MARKETPLACE_SEARCH: '/search',
  INITIAL_MARKETPLACE_SEARCH_ID: '/search/marketplace/:id',
  INITIAL_PAYMENT: '/payment',

  //   forms
  FORM_HASH: '/form/:hash',

  //   settings
  SETTINGS: '/settings',
  SETTINGS_ACCOUNT: '/settings/account',
  SETTINGS_ACCOUNT_DELETE: '/settings/account/delete',
  SETTINGS_ADDRESS: '/settings/address',
  SETTINGS_SOCIAL_CONNECT: '/settings/social_connect',
  SETTINGS_SOCIAL_CONNECT_ADD_METHODS: '/settings/social_connect/add_methods',
  SETTINGS_SOCIAL_CONNECT_IG_CONNECT: '/settings/social_connect/instagram_connect',
  SETTINGS_SOCIAL_CONNECT_IG_UNABLE_CONNECT: '/settings/social_connect/instagram_unable_connect',
  SETTINGS_SOCIAL_CONNECT_TIKTOK_GRANT_PERMISSION: '/settings/social_connect/tiktok_grant_permission',
  SETTINGS_SOCIAL_CONNECT_FACEBOOK_GRANT_PERMISSION: '/settings/social_connect/facebook_grant_permission',
  SETTINGS_USERS: '/settings/users',
  SETTINGS_USERS_USER_ID: '/settings/users/:userId',

  //   liff
  LIFF: '/liff',
  LIFF_ID: '/liff/:liffId',
  LIFF_SIGN_IN_ID: '/liff_signin/:liffIdd',

  // mobile
  MOBILE_TIKTOK_CONNECT: '/mobile_tiktok_connect',
  MOBILE_TIKTOK_GRANT_PERMISSION: '/mobile_tiktok_grant_permission',
  MOBILE_SOCIAL_AUTH: '/mobile_social_auth',

  // terms & privacy
  TERMS: '/terms',
  PRIVACY: '/privacy',

  // new UI
  // youtube CMS
  // revenue
  YOUTUBE_CMS_REVENUE: '/youtube_cms_revenue',
  YOUTUBE_CMS_REVENUE_VIDEO_VIDEOID: '/youtube_cms_revenue/video/:videoId',
  // music
  YOUTUBE_CMS_MUSIC: '/youtube_cms_music',
  YOUTUBE_CMS_MUSIC_CATEGORYID: '/youtube_cms_music/:categoryId',
  // invoice
  YOUTUBE_CMS_INVOICE: '/youtube_cms_invoice',
  // my page
  YOUTUBE_CMS_MY_PAGE: '/youtube_cms_my_page',
  YOUTUBE_CMS_MY_PAGE_ACCOUNT: '/youtube_cms_my_page/account',
  YOUTUBE_CMS_MY_PAGE_ACCOUNT_DELETE: '/youtube_cms_my_page/account/delete',
  YOUTUBE_CMS_MY_PAGE_ADDRESS: '/youtube_cms_my_page/address',
  YOUTUBE_CMS_MY_PAGE_SOCIAL_CONNECT: '/youtube_cms_my_page/social_connect',
  YOUTUBE_CMS_MY_PAGE_SOCIAL_CONNECT_INSTAGRAM_CONNECT: '/youtube_cms_my_page/social_connect/instagram_connect',
  YOUTUBE_CMS_MY_PAGE_SOCIAL_CONNECT_INSTAGRAM_UNABLE_CONNECT:
    '/youtube_cms_my_page/social_connect/instagram_unable_connect',
  YOUTUBE_CMS_MY_PAGE_SOCIAL_CONNECT_FACEBOOK_GRANT_PERMISSION:
    '/youtube_cms_my_page/social_connect/facebook_grant_permission',
  YOUTUBE_CMS_MY_PAGE_SOCIAL_CONNECT_TIKTOK_GRANT_PERMISSION:
    '/youtube_cms_my_page/social_connect/tiktok_grant_permission',
  YOUTUBE_CMS_MY_PAGE_SOCIAL_CONNECT_ADD_METHODS: '/youtube_cms_my_page/social_connect/add_methods',
  YOUTUBE_CMS_MY_PAGE_CONTACT: '/youtube_cms_my_page/contact',

  // find jobs
  FIND_JOBS: '/find_jobs',
  FIND_JOBS_AUTO_MANAGED_ID: '/find_jobs/auto_managed/:id',
  FIND_JOBS_MARKETPLACE_ID: '/find_jobs/marketplace/:id',
  FIND_JOBS_TIKTOK_SPECIAL_ID: '/find_jobs/tiktok_special/:id',
  FIND_JOBS_CAMPAIGN_FINISHED: '/find_jobs/campaign_finished',

  // my jobs
  MY_JOBS: '/my_jobs',
  MY_JOBS_AUTO_MANAGED_ID: '/my_jobs/auto_managed/:id',
  MY_JOBS_AUTO_MANAGED_ID_REPORT: '/my_jobs/auto_managed/:id/report',
  MY_JOBS_ENGAGEMENT_ID: '/my_jobs/engagement/:id',
  MY_JOBS_MARKETPLACE_ID: '/my_jobs/marketplace/:id',
  MY_JOBS_MARKETPLACE_ID_SUBMIT_DRAFT_POST: '/my_jobs/marketplace/:id/submit_draft_post',
  MY_JOBS_MARKETPLACE_ID_REPORT: '/my_jobs/marketplace/:id/report',
  MY_JOBS_MARKETPLACE_ID_POST_REPORT: '/my_jobs/marketplace/:id/post_report',
  MY_JOBS_CASTING_YOUR_JOB_ID: '/my_jobs/engagement/:id',
  MY_JOBS_CASTING_YOUR_JOB_ID_POST: '/my_jobs/engagement/:id/post',
  MY_JOBS_CASTING_YOUR_JOB_ID_POST_ADD: '/my_jobs/engagement/:id/post/add',
  MY_JOBS_CASTING_YOUR_JOB_ID_POST_EDIT: '/my_jobs/engagement/:id/post/:postId',
  MY_JOBS_CASTING_YOUR_JOB_ID_REPORT: '/my_jobs/engagement/:id/report',
  MY_JOBS_WAITING_ID: '/my_jobs/waiting/:id',
  MY_JOBS_TIKTOK_SPECIAL_ID: '/my_jobs/tiktok_special/:id',
  MY_JOBS_TIKTOK_SPECIAL_ID_POST_REPORT: '/my_jobs/tiktok_special/:id/post_report',

  // my page
  MY_PAGE: '/my_page',
  MY_PAGE_PAYMENT_HISTORY: '/my_page/payment_history',
  MY_PAGE_BANK_ACCOUNT: '/my_page/bank_account',
  MY_PAGE_ACCOUNT: '/my_page/account',
  MY_PAGE_ACCOUNT_DELETE: '/my_page/account/delete',
  MY_PAGE_ADDRESS: '/my_page/address',
  MY_PAGE_USERS: '/my_page/users',
  MY_PAGE_SNS_BUDGET: '/my_page/sns_budget',
  // channel connect
  MY_PAGE_CHANNEL_CONNECT: '/my_page/channel_connect',
  MY_PAGE_CHANNEL_CONNECT_ADD_LINE: '/my_page/channel_connect/add_line',
  MY_PAGE_CHANNEL_CONNECT_ADD_SHOPIFY: '/my_page/channel_connect/add_shopify',
  // social connect
  MY_PAGE_SOCIAL_CONNECT: '/my_page/social_connect',
  MY_PAGE_SOCIAL_CONNECT_INSTAGRAM_CONNECT: '/my_page/social_connect/instagram_connect',
  MY_PAGE_SOCIAL_CONNECT_INSTAGRAM_UNABLE_CONNECT: '/my_page/social_connect/instagram_unable_connect',
  MY_PAGE_SOCIAL_CONNECT_FACEBOOK_GRANT_PERMISSION: '/my_page/social_connect/facebook_grant_permission',
  MY_PAGE_SOCIAL_CONNECT_TIKTOK_GRANT_PERMISSION: '/my_page/social_connect/tiktok_grant_permission',
  MY_PAGE_SOCIAL_CONNECT_ADD_METHODS: '/my_page/social_connect/add_methods',
  // fan management
  MY_PAGE_FANS: '/my_page/fans',
  MY_PAGE_FANS_ADD: '/my_page/fans/add',
  MY_PAGE_FANS_EDIT: '/my_page/fans/:id',
  MY_PAGE_FANS_EDIT_DETAILS: '/my_page/fans/:id/details',
  MY_PAGE_FANS_EDIT_ACTIVITY: '/my_page/fans/:id/activity',
  MY_PAGE_FANS_EDIT_ORDERS: '/my_page/fans/:id/orders',
  MY_PAGE_FANS_EDIT_FORMS: '/my_page/fans/:id/forms',
  MY_PAGE_FANS_EDIT_FORMS_SUBMISSION_ID: '/my_page/fans/:id/forms/:submissionId',
  MY_PAGE_EMAILS: '/my_page/emails',
  MY_PAGE_EMAILS_COMPOSE: '/my_page/emails/compose',
  MY_PAGE_EMAILS_EDIT_COMPOSE: '/my_page/emails/:id/compose',
  MY_PAGE_EMAILS_EDIT_OVERVIEW: '/my_page/emails/:id/overview',
  MY_PAGE_EMAILS_EDIT_RECIPIENT: '/my_page/emails/:id/recipient',
  MY_PAGE_MESSAGE: '/my_page/message',
  MY_PAGE_MESSAGE_CHAT: '/my_page/message/chat',
  MY_PAGE_MESSAGE_CHAT_ACTIVE_ID: '/my_page/message/chat/:activeChat?',
  MY_PAGE_MESSAGE_CHAT_ACTIVE_ID_FAN_ID: '/my_page/message/chat/:activeChat?/:fanId',
  MY_PAGE_MESSAGE_CHAT_ACTIVE_ID_FAN_ID_FAN_DETAILS: '/my_page/message/chat/:activeChat?/:fanId?/fan_detail',
  MY_PAGE_MESSAGE_CHAT_ACTIVE_ID_FAN_ID_ACTIVITY: '/my_page/message/chat/:activeChat?/:fanId?/activity',
  MY_PAGE_MESSAGE_CHAT_ACTIVE_ID_FAN_ID_ORDERS: '/my_page/message/chat/:activeChat?/:fanId?/orders',
  MY_PAGE_MESSAGE_BROADCAST: '/my_page/message/broadcast',
  MY_PAGE_MESSAGE_BROADCAST_ADD: '/my_page/message/broadcast/add',
  MY_PAGE_MESSAGE_BROADCAST_EDIT: '/my_page/message/broadcast/:id',
  MY_PAGE_FORMS: '/my_page/forms',
  MY_PAGE_FORMS_ADD: '/my_page/forms/add',
  MY_PAGE_FORMS_ADD_LIVE_PREVIEW: '/my_page/forms/add/live_preview',
  MY_PAGE_FORMS_EDIT_ENTRY: '/my_page/forms/:id/entry',
  MY_PAGE_FORMS_EDIT_REPORT: '/my_page/forms/:id/report',
  MY_PAGE_FORMS_EDIT_REPORT_RESPONDENT: '/my_page/forms/:id/report/respondent',
  MY_PAGE_FORMS_EDIT_REPORT_INDIVIDUAL: '/my_page/forms/:id/report/individual',
  MY_PAGE_FORMS_EDIT_REPORT_INDIVIDUAL_PAGE: '/my_page/forms/:id/report/individual/:page',
  MY_PAGE_FORMS_EDIT_LIVE_PREVIEW: '/my_page/forms/:id/live_preview',
  MY_PAGE_CONTACT: '/my_page/contact',
  // notification
  NOTIFICATION: '/notification',
  CAMPAIGN_DETAILS_PUBLIC: '/public/campaign_details', // used by AnyTag's campaign preview feature https://adasiaholdings.atlassian.net/browse/AT-4170
  CREATOR_ACCESS_PREVIEW: '/creator_access_preview', // limited login feature used to set token from AnyTag
};

export const generatePath = (path: string, params?: Record<string, any>, searchParams?: Record<string, any>) => {
  const search = searchParams ? `?${new URLSearchParams(searchParams).toString()}` : '';

  return `${reactRouterGeneratePath(path, params)}${search}`;
};
