import { format } from 'date-fns';
import React from 'react';
import { Link } from 'react-router-dom';
import isPropValid from '@emotion/is-prop-valid';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import emptyImage from '@src/assets/img/emptyImage.png';
import { Icomoon } from '@src/components/atoms';
import { Carousel, ThemeButton } from '@src/components/molecules';
import { RichEditor, TextForm } from '@src/components/shared';
import { getDayfromDateString, localizedDateFormatter } from '@src/libs/date';
import { formatNumberWithCommas, formatPercent } from '@src/libs/format';
import { useCopy, usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { AppLanguage } from '@src/libs/i18n/languageDetector/utils';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import { getSocialMediaUrl, switchImage, switchText } from '@src/libs/socialMedia';
import {
  AnyXEngagementCampaignStatus,
  AnyXMarketplaceCampaignStatus,
  CampaignPostStatusForInfluencer,
  CampaignSocialMediaType,
  CampaignStatusForInfluencer,
  CampaignType,
  MarketplaceCampaignAppliedStatus,
  MarketplaceCampaignType,
  TTCMStatus,
  WarningReason,
} from '@src/__generated__/globalTypes';
import CampaignBudgetProgressBar from '@src/components/shared/Campaign/Details/Details/CampaignBudgetProgressBar';
import StatusStepper from '@src/components/shared/Campaign/StatusStepper';
import StatusMessage from './StatusMessage';

interface CampaignDetailsProps {
  campaignDetails: {
    anyXStatus?: AnyXEngagementCampaignStatus | AnyXMarketplaceCampaignStatus | null;
    appliedStatus?: MarketplaceCampaignAppliedStatus | null;
    campaignType?: MarketplaceCampaignType;
    clickUrl?: string | null;
    commissionRate?: number | null;
    couponCode?: string | null;
    currency: string;
    endDate?: any | null;
    hashtags: string[];
    isApplied?: boolean;
    isCouponCampaign?: boolean | null;
    isGiftingCampaign?: boolean | null;
    isJoined: boolean;
    isTtcmCampaign?: boolean;
    materials?:
      | {
          id: number;
          url: string;
        }[]
      | null;
    materialUrl?: string | null;
    maximumRevenuePerInfluencer?: number | null;
    minFollowers?: number;
    postStatus?: CampaignPostStatusForInfluencer | null;
    postUrl?: string | null;
    remainingAmount?: number;
    requirement?: string | null;
    requirements?: string | null;
    revenuePerAction?: number | null;
    revenuePerClick?: number | null;
    revenuePerComment?: number | null;
    revenuePerFollower?: number | null;
    revenuePerLike?: number | null;
    revenuePerOrder?: number | null;
    revenuePerPost?: number | null;
    revenuePerShare?: number | null;
    revenuePerView?: number | null;
    rewardAmount?: number;
    serviceInformation: string;
    serviceUrl?: string | null;
    socialMedias: CampaignSocialMediaType[];
    startDate: any;
    status: CampaignStatusForInfluencer;
    title: string;
    thumbNail: string;
    trackingUrl?: string | null;
    totalAmount?: number;
    ttcmCampaignCode?: string | null;
    ttcmStatus?: TTCMStatus | null;
    type: CampaignType;
    warningReason?: WarningReason | null;
  };
}

const CampaignDetails = ({ campaignDetails }: CampaignDetailsProps) => {
  const {
    anyXStatus,
    appliedStatus,
    campaignType,
    clickUrl,
    commissionRate,
    couponCode,
    currency,
    endDate,
    hashtags,
    isApplied,
    isCouponCampaign,
    isGiftingCampaign,
    isJoined,
    materials,
    materialUrl,
    maximumRevenuePerInfluencer,
    minFollowers,
    postStatus,
    postUrl,
    remainingAmount,
    requirement,
    requirements,
    revenuePerAction,
    revenuePerClick,
    revenuePerComment,
    revenuePerFollower,
    revenuePerLike,
    revenuePerOrder,
    revenuePerPost,
    revenuePerShare,
    revenuePerView,
    rewardAmount,
    serviceInformation,
    serviceUrl,
    socialMedias,
    startDate,
    status,
    title,
    thumbNail,
    trackingUrl,
    totalAmount,
    ttcmCampaignCode,
    ttcmStatus,
    type,
    warningReason,
  } = campaignDetails;

  // TODO: to enable new flow when marketplace implement TTCM
  // const isTtcmCampaign = campaignDetails.isTtcmCampaign;
  const isTtcmCampaign = false;

  const { i18n, t } = useQueryHelper();
  const { handleCopyHasHashtag, handleCopyHasText } = useCopy();
  const { isMobileView } = usePageLayout();
  const isAffiliateCampaign = campaignType === MarketplaceCampaignType.AFFILIATE;
  const isApplying = appliedStatus === MarketplaceCampaignAppliedStatus.APPLYING;
  const isCampaignFinishWithPosting =
    ((anyXStatus &&
      [AnyXEngagementCampaignStatus.INEFFECTIVE, AnyXMarketplaceCampaignStatus.INEFFECTIVE].includes(anyXStatus)) ||
      appliedStatus === MarketplaceCampaignAppliedStatus.REJECTED ||
      status === CampaignStatusForInfluencer.FINISHED) &&
    anyXStatus &&
    [AnyXEngagementCampaignStatus.POSTING, AnyXMarketplaceCampaignStatus.POSTING].includes(anyXStatus);
  const isCampaignSocialMedias = !!socialMedias.find(socialMedia => socialMedia !== CampaignSocialMediaType.UNSELECT);
  const isJPLanguage = i18n.language === 'ja';
  const isRejected =
    appliedStatus === MarketplaceCampaignAppliedStatus.REJECTED ||
    anyXStatus === AnyXMarketplaceCampaignStatus.INEFFECTIVE;
  const isSearchCampaign = !isApplied && !isJoined; // For selection marketplace and normal marketplace
  const isTtcmApproved = !!isTtcmCampaign && ttcmStatus === TTCMStatus.APPROVED;
  const isTtcmInvitation =
    !!isTtcmCampaign && [TTCMStatus.INVITED, TTCMStatus.REJECTED].includes(ttcmStatus as TTCMStatus);
  const isUpcomingNormalCampaign =
    status === CampaignStatusForInfluencer.UPCOMING && campaignType === MarketplaceCampaignType.NORMAL;
  const revenueRewards = [
    { title: 'RevenuePer.Action', value: revenuePerAction },
    { title: 'RevenuePer.Click', value: revenuePerClick },
    { title: 'RevenuePer.Comment', value: revenuePerComment },
    { title: 'RevenuePer.Follower', value: revenuePerFollower },
    { title: 'RevenuePer.Like', value: revenuePerLike },
    { title: 'RevenuePer.Order', value: revenuePerOrder },
    { title: 'RevenuePer.Post', value: revenuePerPost },
    { title: 'RevenuePer.Share', value: revenuePerShare },
    { title: 'RevenuePer.View', value: revenuePerView },
  ];
  const showBudgetProgressBar =
    !isAffiliateCampaign && !isGiftingCampaign && remainingAmount !== undefined && totalAmount !== undefined;
  const showShipmentMessage =
    isGiftingCampaign &&
    [AnyXMarketplaceCampaignStatus.SHIPPED, AnyXMarketplaceCampaignStatus.WAITING_FOR_SHIPMENT].includes(
      anyXStatus as AnyXMarketplaceCampaignStatus
    );

  const startDay = getDayfromDateString(startDate);
  const startDayNumber = startDay.substring(0, 2);
  const startDayWord = startDay.substring(2, 3);
  const endDay = getDayfromDateString(endDate);
  const endDayNumber = endDay.substring(0, 2);
  const endDayWord = endDay.substring(2, 3);

  return (
    <div css={styles.infoContainer}>
      <div css={styles.imgSection}>
        <img alt="campaignImg" src={thumbNail} />
      </div>

      <div css={styles.infoSection}>
        <div css={{ width: !isMobileView ? '65%' : '100%' }}>
          {campaignType === MarketplaceCampaignType.SELECTION ? (
            isSearchCampaign || appliedStatus === MarketplaceCampaignAppliedStatus.APPLYING ? (
              <MessageIndicator>
                <MessageSubTitle>{t('Heading.This is a Selection campaign')}</MessageSubTitle>
                <MessageDescription>
                  {t('Annotation.This campaign is only open to those who have applied and been accepted')}
                </MessageDescription>
              </MessageIndicator>
            ) : isRejected ? (
              <MessageIndicator>
                <MessageSubTitle>{t('Heading.Application Rejected')}</MessageSubTitle>
                <MessageDescription>
                  {t(
                    'Annotation.We regret to announce that your application was not approved We look forward to seeing you again soon!'
                  )}
                </MessageDescription>
              </MessageIndicator>
            ) : null
          ) : isRejected ? (
            <MessageIndicator>
              <MessageSubTitle>{t('Heading.Your post is rejected')}</MessageSubTitle>
              <MessageDescription>
                {t(`Annotation.The post content didn't meet the campaign condition`)}
              </MessageDescription>
            </MessageIndicator>
          ) : showShipmentMessage ? (
            <MessageIndicator>
              {anyXStatus === AnyXMarketplaceCampaignStatus.SHIPPED ? (
                <>
                  <MessageSubTitle>{t('Heading.Item has been shipped')}</MessageSubTitle>
                  <MessageDescription>
                    {t(
                      'Annotation.Your order has been shipped to your registered address and will arrive within 3-7 business days'
                    )}
                  </MessageDescription>
                </>
              ) : (
                <>
                  <MessageSubTitle>{t('Heading.Please wait for the product to be shipped')}</MessageSubTitle>
                  <MessageDescription>
                    {t('Annotation.The AnyCreator team will prepare the goods for shipment')}
                  </MessageDescription>
                </>
              )}
            </MessageIndicator>
          ) : warningReason === WarningReason.COUPON_MISSING ? (
            <MessageIndicator color="#ff5f5f">
              <MessageSubTitle>{t(`Heading.Your post doesn't have coupon code`)}</MessageSubTitle>
              <div css={styles.messageCouponCodeInfo}>
                <div>{t('Label.Coupon code')}</div>
                <div>{couponCode}</div>
              </div>
              <ThemeButton
                css={{ height: 40 }}
                target="_blank"
                to={postUrl || getSocialMediaUrl(socialMedias[0])}
                text="Check post"
              />
            </MessageIndicator>
          ) : null}

          {!!anyXStatus &&
            !isAffiliateCampaign &&
            !isApplying &&
            !isCampaignFinishWithPosting &&
            !isRejected &&
            !isSearchCampaign &&
            !isTtcmInvitation &&
            !isUpcomingNormalCampaign && (
              <div css={[styles.stepperContainer, !isTtcmCampaign ? { borderBottom: '1px solid #eef3f7' } : {}]}>
                <div>
                  <StatusStepper
                    isGiftingCampaign={isGiftingCampaign}
                    isTtcmCampaign={isTtcmCampaign}
                    postStatus={postStatus}
                    status={anyXStatus}
                    type={type}
                  />
                </div>
              </div>
            )}

          {isTtcmApproved && <StatusMessage status={anyXStatus as AnyXEngagementCampaignStatus} />}

          {isTtcmInvitation && <div css={styles.invitationIndicator}>{t('Invitation')}</div>}

          <div css={styles.title}>{title}</div>

          {isMobileView && showBudgetProgressBar && (
            <div css={styles.progressBarContainer}>
              <CampaignBudgetProgressBar remainingAmount={remainingAmount} totalAmount={totalAmount} />
            </div>
          )}

          <div css={styles.rewardBudgetContainer}>
            <RewardPeriodContainer showBudgetProgressBar={showBudgetProgressBar}>
              <div>
                <div css={{ gap: '8px' }}>
                  {type === CampaignType.MARKETPLACE && isCampaignSocialMedias && (
                    <Icomoon icon={switchImage({ socialMedia: socialMedias[0] })} size={15} />
                  )}
                  <span>{t('Reward amount')}</span>
                </div>
                {isGiftingCampaign ? (
                  <RewardAmount>{t('Gifting')}</RewardAmount>
                ) : type === CampaignType.ENGAGEMENT ? (
                  rewardAmount && (
                    <div>
                      <RewardAmount large>{formatNumberWithCommas(rewardAmount)}</RewardAmount>
                      &nbsp;
                      <Currency large>{t(currency)}</Currency>
                    </div>
                  )
                ) : (
                  <div style={{ display: 'grid', justifyContent: 'center' }}>
                    {!!commissionRate && (
                      <RewardAmountField>
                        <RewardAmount>{formatPercent(commissionRate, false, 0)}</RewardAmount>
                        &nbsp;
                        <Currency>{`% / ${t('Commission rate')}`}</Currency>
                      </RewardAmountField>
                    )}
                    {revenueRewards.map((revenue, revenueIndex) => {
                      const { title: revenueTitle, value } = revenue;

                      return (
                        value && (
                          <RewardAmountField key={revenueIndex}>
                            <RewardAmount>{formatNumberWithCommas(value, undefined, currency)}</RewardAmount>
                            &nbsp;
                            <Currency>{`${t(currency)} / ${t(revenueTitle)}`}</Currency>
                          </RewardAmountField>
                        )
                      );
                    })}
                  </div>
                )}
              </div>
              <div>
                <div>{t('Campaign Period')}</div>
                <div>
                  <MonthFont isJPLanguage={isJPLanguage}>
                    {localizedDateFormatter(new Date(startDate), 'MMM', i18n.language as AppLanguage)}
                  </MonthFont>
                  <DayFont>
                    {startDayNumber}
                    <DayWord>{startDayWord}</DayWord>
                  </DayFont>
                  {endDate && (
                    <>
                      <span css={styles.periodDash}>-</span>
                      <MonthFont isJPLanguage={isJPLanguage}>
                        {localizedDateFormatter(new Date(endDate), 'MMM', i18n.language as AppLanguage)}
                      </MonthFont>
                      <DayFont>
                        {endDayNumber}
                        <DayWord>{endDayWord}</DayWord>
                      </DayFont>
                    </>
                  )}
                </div>
              </div>
            </RewardPeriodContainer>

            {!isAffiliateCampaign && !isMobileView && showBudgetProgressBar && (
              <div css={{ width: '50%' }}>
                <CampaignBudgetProgressBar remainingAmount={remainingAmount || 0} totalAmount={totalAmount || 99} />
              </div>
            )}
          </div>

          <InfoHeaderTitle>{t('Label.Product information')}</InfoHeaderTitle>
          <InfoField marginBottom>
            <InfoTitle>{t('Label.Product / Service Information')}</InfoTitle>
            <InfoContent>
              <RichEditor
                disable={true}
                height="auto"
                setDefaultStyle="font-size: 14px; padding: 0;"
                setOptions={{ resizingBar: false }}
                hideToolbar
                value={serviceInformation}
              />
              {(clickUrl || serviceUrl) && (
                <StyledLink marginTop rel="noopener noreferrer" target="_blank" to={clickUrl || serviceUrl || ''}>
                  <span>{t('Button.More Detail')}</span>
                  <Icomoon icon="share-arrow-top-right" size={12} />
                </StyledLink>
              )}
            </InfoContent>
          </InfoField>

          <InfoHeaderTitle>{t('Label.Request details')}</InfoHeaderTitle>
          <InfoField>
            <InfoTitle>{t('Label.Posting period')}</InfoTitle>
            <InfoContent>
              {`${format(new Date(startDate), 'yyyy.MM.dd')} ${
                endDate ? `- ${format(new Date(endDate), 'yyyy.MM.dd')}` : ''
              }`}
            </InfoContent>
          </InfoField>

          {isCampaignSocialMedias && (
            <InfoField>
              <InfoTitle>{t('Label.Social Media')}</InfoTitle>
              <InfoContent>
                <div css={{ display: minFollowers ? 'grid' : 'flex', flexWrap: 'wrap' }}>
                  {socialMedias.map((socialMedia, index) => (
                    <div css={styles.socialMedia} key={index}>
                      <Icomoon icon={switchImage({ socialMedia })} size={18} />
                      {minFollowers ? (
                        <span>{`${switchText(socialMedia)}: ${t('Campaign social media required followers', {
                          count: formatNumberWithCommas(minFollowers, 0) as never,
                        })}`}</span>
                      ) : (
                        <span>{switchText(socialMedia)}</span>
                      )}
                    </div>
                  ))}
                </div>
              </InfoContent>
            </InfoField>
          )}

          <InfoField>
            <InfoTitle>{t('Label.Post Requirements')}</InfoTitle>
            <InfoContent>
              <RichEditor
                disable={true}
                height="auto"
                setDefaultStyle="font-size: 14px; padding: 0;"
                setOptions={{ resizingBar: false }}
                hideToolbar
                value={requirement || requirements || ''}
              />
            </InfoContent>
          </InfoField>

          {!!isCouponCampaign && (
            <InfoField>
              <InfoTitle>{t('Label.Coupon code')}</InfoTitle>
              <InfoContent>
                {couponCode ? (
                  <div css={styles.copyCouponCodeContainer}>
                    <div>{couponCode}</div>
                    <ThemeButton theme="blue" text="Copy code" onClick={() => handleCopyHasText(couponCode)} />
                  </div>
                ) : (
                  <div css={styles.couponCodeMessage}>{t('Annotation.Coupon will be displayed after you joined')}</div>
                )}
              </InfoContent>
            </InfoField>
          )}

          {!!campaignDetails.isTtcmCampaign && (
            <InfoField>
              <InfoTitle>{t('Label.TTCM Campaign Code')}</InfoTitle>
              <InfoContent>
                {ttcmCampaignCode ? (
                  <div css={styles.copyCouponCodeContainer}>
                    <div>{ttcmCampaignCode}</div>
                    <ThemeButton text="Copy code" theme="blue" onClick={() => handleCopyHasText(ttcmCampaignCode)} />
                  </div>
                ) : (
                  <div css={styles.couponCodeMessage}>{t('Annotation.Coupon will be displayed after you joined')}</div>
                )}
              </InfoContent>
            </InfoField>
          )}

          {!!trackingUrl && (
            <InfoField>
              <InfoTitle>{t('Label.Tracking URL')}</InfoTitle>
              <InfoContent>
                <div>{t('Annotation.Please add this URL to your post')}</div>
                <div css={styles.trackingUrlContainer}>
                  <TextForm disabled placeholder="" value={trackingUrl} />
                  <ThemeButton text="Copy URL" theme="blue" onClick={() => handleCopyHasText(trackingUrl)} />
                </div>
              </InfoContent>
            </InfoField>
          )}

          {!isAffiliateCampaign && hashtags.length > 0 && (
            <InfoField>
              <InfoTitle>{t('Label.Hash Tags')}</InfoTitle>
              <InfoContent>
                <div css={styles.hashtagsContainer}>
                  {hashtags.map((hastag, index) => (
                    <div css={styles.hashtag} key={index}>
                      {`#${hastag}`}
                    </div>
                  ))}
                </div>
                <ThemeButton
                  css={{ height: 40, width: '100%' }}
                  text="Copy Hashtags"
                  width="max-content"
                  onClick={() => handleCopyHasHashtag(hashtags)}
                />
              </InfoContent>
            </InfoField>
          )}

          {!!maximumRevenuePerInfluencer && (
            <InfoField>
              <InfoTitle>{t('Label.Maximum Revenue')}</InfoTitle>
              <InfoContent>{`${formatNumberWithCommas(maximumRevenuePerInfluencer, 0)} ${t(currency)}`}</InfoContent>
            </InfoField>
          )}

          {!!materialUrl && (
            <InfoField>
              <InfoTitle>{t('Label.Download materials for posting')}</InfoTitle>
              <InfoContent>
                <StyledLink target="_blank" to={materialUrl}>
                  <Icomoon icon="import" size={15} />
                  <span>{t('Button.Download materials')}</span>
                </StyledLink>
              </InfoContent>
            </InfoField>
          )}

          {!!(materials && materials.length > 0) && (
            <InfoField>
              <InfoTitle>{t('Label.Posting Samples')}</InfoTitle>
              <InfoContent css={isMobileView && { display: 'flex', justifyContent: 'center' }}>
                <div css={styles.carouselContainer}>
                  <Carousel height="230px" sources={materials.map(item => item.url) || [emptyImage]} width="300" />
                </div>
              </InfoContent>
            </InfoField>
          )}
        </div>
      </div>
    </div>
  );
};

const Currency = styled.span<{ large?: boolean }>(({ large }) => ({
  color: THEME.font.colors.gray.main,
  fontSize: large ? 11 : 10,
  wordBreak: 'keep-all',
}));

const DayFont = styled.span({
  color: THEME.font.colors.black.main,
  fontSize: THEME.font.sizes.subordinate,
  fontWeight: 600,
});

const DayWord = styled.span({
  color: THEME.font.colors.black.main,
  fontSize: 11,
  fontWeight: 600,
});

const InfoContent = styled.div({
  fontSize: THEME.font.sizes.normal,
  padding: 16,

  '& .slick-arrow': {
    borderRadius: '50%',
    boxShadow: THEME.box.shadows.outer,
    height: '32px !important',
    position: 'absolute',
    width: '32px !important',

    '& > i': {
      marginTop: 1,
    },

    '&.slick-prev': {
      left: '-15px !important',
    },

    '&.slick-next': {
      right: '-15px !important',
    },
  },

  '& .slick-dots': {
    bottom: '-30px !important',
  },

  '& .slick-dots li button::before': {
    color: '#3892e5 !important',
    fontSize: '11px !important',
  },
});

const InfoField = styled.div<{ marginBottom?: boolean }>(({ marginBottom }) => ({
  border: '1px solid #eef3f7',
  ...(marginBottom && { marginBottom: 16 }),
}));

const InfoHeaderTitle = styled.div({
  color: THEME.font.colors.black.main,
  fontSize: THEME.font.sizes.subHeading,
  fontWeight: 600,
  marginBottom: 8,
});

const MessageDescription = styled.div({
  color: THEME.font.colors.gray.main,
  fontSize: 13,
});

const MessageIndicator = styled.div<{ color?: string }>(({ color }) => ({
  borderLeft: `5px solid ${color || '#ffb63d'}`,
  borderRadius: 3,
  boxShadow: THEME.box.shadows.outer,
  margin: '16px 0',
  padding: 16,
}));

const MessageSubTitle = styled.div({
  color: THEME.font.colors.black.main,
  fontSize: 13,
  fontWeight: 600,
});

const MonthFont = styled.span<{ isJPLanguage: boolean }>(({ isJPLanguage }) => ({
  color: THEME.font.colors.black.main,
  fontSize: THEME.font.sizes.subordinate,
  fontWeight: 600,
  ...(!isJPLanguage && { marginRight: 5 }),
}));

const RewardAmount = styled.span<{ large?: boolean }>(({ large }) => ({
  color: THEME.font.colors.black.main,
  fontSize: large ? 16 : 14,
  fontWeight: 600,
}));

const RewardAmountField = styled.div({
  textAlign: 'start',
});

const StyledLink = styled(Link, { shouldForwardProp: prop => isPropValid(prop) })<{ marginTop?: boolean }>(
  ({ marginTop }) => ({
    alignItems: 'center',
    border: THEME.box.borders.gray.main,
    borderRadius: 5,
    display: 'flex',
    flexWrap: 'wrap',
    gap: THEME.box.gaps.s,
    height: 40,
    justifyContent: 'center',
    width: '100%',
    ...(marginTop && { marginTop: 16 }),

    '& > span': {
      color: THEME.font.colors.gray.main,
      fontWeight: 600,
      marginRight: 8,
    },
  })
);

const InfoTitle = styled.div({
  backgroundColor: '#eef3f7',
  fontSize: THEME.font.sizes.subordinate,
  fontWeight: 600,
  padding: 8,
});

const RewardPeriodContainer = styled.div<{ showBudgetProgressBar: boolean }>(({ showBudgetProgressBar }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  width: '100%',

  [`@media (min-width: ${ViewportType.TABLET}px)`]: {
    width: showBudgetProgressBar ? '50%' : '100%',
  },

  '& > div': {
    display: 'grid',
    flexBasis: '49%',
    textAlign: 'center',

    '& > div:nth-of-type(1)': {
      alignItems: 'flex-start',
      color: THEME.font.colors.gray.main,
      display: 'flex',
      flexWrap: 'wrap',
      fontSize: 11,
      fontWeight: 600,
      justifyContent: 'center',
      marginBottom: 5,
    },
  },

  '& > div:nth-of-type(1)': {
    borderRight: '1px solid #dee5ec',
  },
}));

const styles = {
  carouselContainer: css({
    maxWidth: 300,

    '& img': {
      objectFit: 'cover',
    },
  }),
  copyCouponCodeContainer: css({
    display: 'flex',
    gap: THEME.box.gaps.m,

    '& > div': {
      alignItems: 'center',
      border: '1px solid #eef3f7',
      borderRadius: 3,
      color: THEME.font.colors.gray.main,
      fontSize: THEME.font.sizes.subHeading,
      fontWeight: 600,
      display: 'flex',
      flex: 1,
      height: 40,
      justifyContent: 'center',
    },

    '& > button': {
      height: 40,
      width: 168,

      [`@media (max-width: ${ViewportType.TABLET}px)`]: {
        width: 'fit-content',
      },
    },
  }),
  couponCodeMessage: css({
    color: '#97a4af',
    fontSize: THEME.font.sizes.normal,
    textAlign: 'center',
  }),
  hashtag: css({
    backgroundColor: '#d7e9fb',
    color: '#3992e5',
    marginBottom: 8,
    padding: 8,
    width: 'fit-content',
  }),
  hashtagsContainer: css({
    display: 'flex',
    flexWrap: 'wrap',
    gap: THEME.box.gaps.s,
  }),
  imgSection: css({
    backgroundColor: '#000',
    display: 'flex',
    height: 338,
    width: '100%',

    '& > img': {
      objectFit: 'contain',
      width: 'inherit',
    },
  }),
  infoContainer: css({
    display: 'flex',
    flexWrap: 'wrap',
  }),
  infoSection: css({
    backgroundColor: THEME.colors.white,
    display: 'flex',
    justifyContent: 'center',
    padding: 16,
    width: '100%',

    [`@media (max-width: ${ViewportType.SMALL}px)`]: {
      borderRadius: '24px 24px 0 0',
      marginBottom: 32,
      marginTop: -16,
    },
  }),
  invitationIndicator: css({
    border: '1px solid #40b87c',
    borderRadius: 35,
    color: '#40b87c',
    fontSize: THEME.font.sizes.subordinate,
    fontWeight: 600,
    marginBottom: 22,
    padding: '6px 8px',
    width: 'fit-content',
  }),
  messageCouponCodeInfo: css({
    alignItems: 'center',
    display: 'flex',
    gap: 7,
    margin: '12px 0 16px',

    '& > div:nth-of-type(1)': {
      alignItems: 'center',
      border: '1px solid #6e7c89',
      borderRadius: 32,
      color: THEME.font.colors.gray.main,
      display: 'flex',
      fontSize: THEME.font.sizes.subordinate,
      fontWeight: 600,
      height: 24,
      justifyContent: 'center',
      width: 94,
    },

    '& > div:nth-of-type(2)': {
      color: THEME.font.colors.gray.main,
      fontSize: THEME.font.sizes.heading,
      fontWeight: 600,
    },
  }),
  periodDash: css({
    color: THEME.font.colors.black.main,
    fontWeight: 600,
    margin: '0 5px',
  }),
  progressBarContainer: css({
    margin: '16px 0',
  }),
  rewardBudgetContainer: css({
    alignItems: 'center',
    borderBottom: '1px solid #dee5ec',
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: 16,
    paddingBottom: 16,
  }),
  stepperContainer: css({
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 24,

    '& > div': {
      display: 'flex',
      flexBasis: '60%',

      [`@media (max-width: ${ViewportType.SMALL}px)`]: {
        flexBasis: '100%',
      },
    },
  }),
  socialMedia: css({
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: 8,
    marginRight: 16,
    gap: THEME.box.gaps.s,
  }),
  title: css({
    color: THEME.font.colors.black.main,
    fontSize: THEME.font.sizes.heading,
    fontWeight: 600,
    marginBottom: 16,
  }),
  trackingUrlContainer: css({
    display: 'flex',
    gap: THEME.box.gaps.s,
    marginTop: '16px',
    justifyContent: 'flex-end',

    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      flexWrap: 'wrap',
    },

    '& > div': {
      width: 'fill-available',
    },

    '& > button': {
      width: 168,

      [`@media (max-width: ${ViewportType.TABLET}px)`]: {
        width: 'fit-content',
      },
    },
  }),
};

export default CampaignDetails;
