import React from 'react';
import { Navigate } from 'react-router-dom';
import { css } from '@emotion/react';
import { ViewportType } from '@src/libs/types';
import { useRecoil, chatMessageTemplatesState } from '@src/recoilAtoms';
import { useAuthData, useDocumentTitle, usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { THEME } from '@src/libs/theme';
import { useGetMessageTemplatesQuery } from '@src/graphql/hooks';
import useGetLineChannels from '@src/pages/MyPage/Message/Chat/useGetLineChannels';
import { ROUTES } from '@src/shared/routes';
import ChatListSection from './ChatListSection';
import ChatCustomerInformation from './ChatCustomerInformation';
import ChatView from './ChatView';
import { NoLineConnected } from './ChatView/EmptyChats';

const Chat = () => {
  const { limitedLogin } = useAuthData();
  if (limitedLogin) {
    return <Navigate to={ROUTES.MY_PAGE} />;
  }

  const { isMobileView } = usePageLayout();
  const { matchPath, pathname } = useQueryHelper();
  const { setRecoilState: setChatTemplates } = useRecoil(chatMessageTemplatesState);
  useDocumentTitle({ skip: isMobileView, title: 'Chat' });

  const { lineConnectStatus } = useGetLineChannels();
  useGetMessageTemplatesQuery({
    onCompleted: ({ getMessageTemplates }) => {
      if (getMessageTemplates?.templates) {
        setChatTemplates(getMessageTemplates.templates);
      }
    },
  });

  const needsToConnectLine = lineConnectStatus === 'connectLine';

  if (isMobileView) {
    if (matchPath(ROUTES.MY_PAGE_MESSAGE_CHAT, pathname)) {
      return (
        <div css={styles.chatsList}>
          <ChatListSection hasMobileLayout needsToConnectLine={needsToConnectLine} />
        </div>
      );
    } else if (matchPath(ROUTES.MY_PAGE_MESSAGE_CHAT_ACTIVE_ID, pathname)) {
      return (
        <div css={styles.chatWrapperMobile}>
          {/* to make live chatList update continue and PIC & assign|unassign state work */}
          <div css={{ display: 'none' }}>
            <ChatListSection />
          </div>
          {needsToConnectLine ? <NoLineConnected /> : <ChatView hasMobileLayout />}
        </div>
      );
    } else {
      return <ChatCustomerInformation />;
    }
  }

  return (
    <div css={styles.chatWrapper}>
      <div css={styles.chatsList}>
        <ChatListSection hasMobileLayout={isMobileView} />
      </div>
      {needsToConnectLine ? <NoLineConnected /> : <ChatView hasMobileLayout={isMobileView} />}
      <ChatCustomerInformation />
    </div>
  );
};

const styles = {
  chatWrapper: css({
    display: 'grid',
    gridTemplateColumns: '1.2fr 2fr 2.1fr',
    height: 'calc(100vh - 10px)',
  }),
  chatWrapperMobile: css({
    display: 'flex',
    height: '100vh',
  }),
  chatsList: css({
    backgroundColor: THEME.colors.white,
    boxShadow: THEME.box.shadows.outer,
    overflow: 'hidden',

    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      height: 'calc(100vh - 57px)',
    },
  }),
};

export default Chat;
