import React from 'react';
import { Navigate } from 'react-router-dom';
import { css } from '@emotion/react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { eventTypes, sendAmplitudeEvent } from '@src/amplitude';
import { useAddFanMutation } from '@src/graphql/hooks';
import { FanSchema } from '@src/libs/validation';
import { useAuthData, useDocumentTitle, usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { BackNavigator } from '@src/components/molecules';
import { ViewportType } from '@src/libs/types';
import { ROUTES } from '@src/shared/routes';
import AddFanForm from './AddFanForm';
import { FormValues } from './types';

const AddFanComponent = () => {
  const { limitedLogin } = useAuthData();
  if (limitedLogin) {
    return <Navigate to={ROUTES.MY_PAGE} />;
  }

  useDocumentTitle({ title: 'pageTitle.Add Fan' });
  const { isMobileView } = usePageLayout();
  const { enqueueSnackbar, t, navigate } = useQueryHelper();
  const [addFan] = useAddFanMutation();
  const methods = useForm<FormValues>({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      contactNumber: '',
    },
    resolver: yupResolver(FanSchema),
  });

  const onSubmit = async (payload: FormValues) => {
    try {
      const result = await addFan({
        variables: {
          input: payload,
        },
      });
      if (result.data?.addFan?.ok) {
        enqueueSnackbar(t('succeededInCreating'), { variant: 'success' });
        sendAmplitudeEvent(eventTypes.addFan);
        navigate(ROUTES.MY_PAGE_FANS);
      } else {
        const failureMessage = 'failedToCreate';
        enqueueSnackbar(t(failureMessage), { variant: 'error' });
      }
    } catch (err) {
      const { message } = err.graphQLErrors[0];
      if (message === 'fan already exists') {
        methods.setError('email', { message: 'Fan already exists' });
      }
      enqueueSnackbar(t(message || 'failedToCreate'), { variant: 'error' });
    }
  };

  return (
    <div css={styles.container}>
      {!isMobileView && <BackNavigator css={{ marginBottom: 16 }} to={ROUTES.MY_PAGE_FANS} title="Add Fan" />}

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <AddFanForm />
        </form>
      </FormProvider>
    </div>
  );
};

const styles = {
  container: css({
    padding: 16,

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      padding: '16px 24px',
    },
  }),
};

export default AddFanComponent;
