import React from 'react';
import styled from '@emotion/styled';
import { THEME } from '@src/libs/theme';

export interface SwitchProps {
  checked: boolean;
  className?: string;
  onChange?: (checked: boolean) => void;
}

const Switch = ({ checked, className, onChange }: SwitchProps) => (
  <StyledSwitch className={className} checked={checked} onClick={() => onChange?.(!checked)}>
    <input type="checkbox" checked={checked} readOnly />
    <div className="slider" />
  </StyledSwitch>
);

const StyledSwitch = styled.div<{ checked: boolean }>(({ checked }) => ({
  display: 'grid',
  height: 24,
  minWidth: 42,
  position: 'relative',
  width: 42,

  '& > input': {
    height: 0,
    opacity: 0,
    width: 0,
  },

  '& > .slider': {
    background: THEME.colors.gray.f6f8fa,
    border: THEME.box.borders.gray.dfe2e5,
    borderRadius: 35,
    bottom: 0,
    cursor: 'pointer',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
    transition: '.16s',
    '-webkit-transition': '.16s',

    '&:before': {
      background: checked ? THEME.colors.red.fe6565 : THEME.colors.gray.main,
      borderRadius: '50%',
      bottom: 1,
      color: THEME.font.colors.white,
      content: checked ? '"\\2713"' : '"\\2013"',
      height: 20,
      left: 2,
      position: 'absolute',
      textAlign: 'center',
      transition: '.16s',
      width: 20,
      '-webkit-transition': '.16s',

      '[dir="rtl"] &': {
        left: checked ? -15 : 18,
      },
    },
  },

  'input:checked + .slider': {
    background: THEME.colors.red.fff0f0,
    border: THEME.box.borders.red.ffd8d8,
  },

  'input:focus + .slider': {
    boxShadow: THEME.box.shadows.outer,
  },

  'input:checked + .slider:before': {
    transform: 'translateX(17px)',
    '-ms-transform': 'translateX(17px)',
    '-webkit-transform': 'translateX(17px)',
  },
}));

export default Switch;
