import { atom } from 'recoil';
import { InfluencerType, UserRoles } from '@src/__generated__/globalTypes';

export interface AuthStateProps {
  countryId: string | null;
  creatorType: InfluencerType | null;
  isAvailableCountry: boolean;
  isYoutubeCmsRevenue: boolean;
  limitedLogin: boolean;
  role: UserRoles | null;
  userId: number | null;
}

export const authState = atom<AuthStateProps>({
  key: 'authState',
  default: {
    countryId: null,
    creatorType: null,
    isAvailableCountry: false,
    isYoutubeCmsRevenue: false,
    limitedLogin: false,
    role: null,
    userId: null,
  },
});
