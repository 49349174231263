import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModernDrawer from 'react-modern-drawer';
import { css } from '@emotion/react';
import ThemeButton from '@src/components/molecules/ThemeButton';
import usePageLayout from '@src/libs/hooks/usePageLayout';
import { mainBlack } from '@src/libs/palette';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import 'react-modern-drawer/dist/index.css';

interface CrossPlatformDrawerProps {
  children: ReactNode;
  open: boolean;
  title?: string;
  onClickApply: () => void;
  onClickClearConditions: () => void;
  onClose: () => void;
}

const CrossPlatformDrawer = ({
  children,
  open,
  title = 'Filter Conditions',
  onClickApply,
  onClickClearConditions,
  onClose,
}: CrossPlatformDrawerProps) => {
  const { isMobileView } = usePageLayout();
  const { t } = useTranslation();

  const mobileDrawerStyles = {
    borderTopLeftRadius: THEME.box.borderRadius.l,
    borderTopRightRadius: THEME.box.borderRadius.l,
    height: '95vh',
    overflow: 'hidden',
  };
  const webDrawerStyles = {
    width: 440,
  };

  return (
    <ReactModernDrawer
      direction={isMobileView ? 'bottom' : 'right'}
      open={open}
      style={isMobileView ? mobileDrawerStyles : webDrawerStyles}
      onClose={onClose}
    >
      <div css={styles.content}>
        <div css={styles.header}>
          <label className="title">{t(`Title.${title}`)}</label>
          <button className="close-btn" onClick={onClose}>
            {t('Button.Close')}
          </button>
        </div>
        <div css={styles.contentContainer}>{children}</div>
        <div css={styles.actionContainer}>
          <ThemeButton text="Clear Conditions" onClick={onClickClearConditions} />
          <ThemeButton customPalette={mainBlack} text="Apply" onClick={onClickApply} />
        </div>
      </div>
    </ReactModernDrawer>
  );
};

const styles = {
  actionContainer: css({
    background: THEME.colors.white,
    bottom: 0,
    boxShadow: '0px -4px 12px 0px rgba(110, 124, 137, 0.25)',
    display: 'flex',
    gap: THEME.box.gaps.s,
    padding: '16px 18px 32px 18px',
    position: 'absolute',
    width: 'fill-available',

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      padding: '20px 24px',
    },

    '& > button': {
      borderRadius: THEME.box.borderRadius.m,
      fontSize: THEME.font.sizes.normal,
      height: 48,
    },
  }),
  content: css({
    background: THEME.colors.gray.f6f8fa,
    display: 'grid',
    gap: 1,
    gridAutoRows: 'min-content',
    height: '100%',
    position: 'relative',
  }),
  contentContainer: css({
    '--actionContainerHeight': '88px',
    '--headerHeight': '70px',

    height: 'calc(95vh - var(--actionContainerHeight) - var(--headerHeight))',
    overflow: 'auto',

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      height: 'calc(100vh - var(--actionContainerHeight) - var(--headerHeight))',
    },
  }),
  header: css({
    background: THEME.colors.white,
    boxShadow: THEME.box.shadows.outer,
    display: 'flex',
    gap: THEME.box.gaps.s,
    maxHeight: 'min-content',
    padding: 16,
    position: 'relative',

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      padding: 24,
    },

    '& > .title': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.heading,
      fontWeight: 600,
      width: 'fill-available',

      [`@media (max-width: ${ViewportType.TABLET}px)`]: {
        textAlign: 'center',
      },
    },

    '& > .close-btn': {
      bottom: 0,
      color: THEME.font.colors.blue.main,
      cursor: 'pointer',
      fontSize: THEME.font.sizes.subHeading,
      position: 'absolute',
      right: 16,
      margin: 'auto 0',
      top: 0,
    },
  }),
};

export default CrossPlatformDrawer;
