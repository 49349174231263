import { format } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ApolloError } from '@apollo/client';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import connectLineImg from '@src/assets/img/broadcast/connectLine.png';
import { BroadcastStatus, Empty, Image, List } from '@src/components/atoms';
import {
  ListLoading,
  SliderTableComponents,
  SliderTableStyledComponents,
  ThemeButton,
} from '@src/components/molecules';
import { formatPercent, formatNumberWithCommas } from '@src/libs/format';
import { THEME } from '@src/libs/theme';
import { LineConnectStatus } from '@src/pages/MyPage/Message/Chat/useGetLineChannels';
import { generatePath, ROUTES } from '@src/shared/routes';
import { LineBroadcastStatus } from '@src/__generated__/globalTypes';

interface BroadcastListProps {
  error?: ApolloError;
  lineConnectStatus: LineConnectStatus;
  loading: boolean;
  items: {
    click?: number | null;
    clickRate?: number | null;
    createdDateTime: string;
    delivered?: number | null;
    deliveredDateTime?: string | null;
    id: string;
    imageUrl: string;
    open?: number | null;
    openRate?: number | null;
    status: LineBroadcastStatus;
    tags: string[];
    title: string;
  }[];
  isEmptyFilter: boolean;
}

const BroadcastList = ({ error, lineConnectStatus, loading, items, isEmptyFilter }: BroadcastListProps) => {
  const { t } = useTranslation();

  return (
    <div>
      <SliderTableComponents.Container>
        <SliderTableComponents.SliderSection>
          <SliderTableComponents.SliderTable>
            <thead>
              <tr>
                <SliderTableComponents.StickyHeaderTdWrapper>
                  <HeaderColumn title="Image" />
                  <HeaderColumn title="Title" />
                </SliderTableComponents.StickyHeaderTdWrapper>
                <HeaderColumn title="Tag" />
                <HeaderColumn title="Status" />
                <HeaderColumn title="Delivered" />
                <HeaderColumn title="Open" />
                <HeaderColumn title="Open Rate" />
                <HeaderColumn title="Click" />
                <HeaderColumn title="Click Rate" />
                <HeaderColumn css={{ minWidth: 140 }} title="Delivered Date" />
                <HeaderColumn css={{ minWidth: 140 }} title="Created Date" />
              </tr>
            </thead>

            {!loading && (
              <tbody>
                {items.map(item => {
                  const {
                    click,
                    clickRate,
                    createdDateTime,
                    delivered,
                    deliveredDateTime,
                    id,
                    imageUrl,
                    open,
                    openRate,
                    status,
                    tags,
                    title,
                  } = item;

                  return (
                    <SliderTableStyledComponents.StyledRowNew key={id} css={{ borderTop: 'none' }}>
                      <SliderTableComponents.StickyBodyRowWrapper css={styles.clearTopBorder}>
                        <List.TextColumn data={<Image height="56px" src={imageUrl} width="56px" />} />
                        <List.TextLinkColumn
                          data={title}
                          href={generatePath(ROUTES.MY_PAGE_MESSAGE_BROADCAST_EDIT, { id })}
                        />
                      </SliderTableComponents.StickyBodyRowWrapper>
                      <List.TextColumn
                        data={
                          <div css={{ display: 'flex', gap: THEME.box.gaps.xs }}>
                            {tags.map((tag, tagIndex) => (
                              <div css={styles.tag} key={tagIndex}>
                                {tag}
                              </div>
                            ))}
                          </div>
                        }
                      />
                      <List.TextColumn data={<BroadcastStatus status={status} />} />
                      <List.TextColumn data={formatNumberWithCommas(delivered, 0)} />
                      <List.TextColumn data={formatNumberWithCommas(open, 0)} />
                      <List.TextColumn data={formatPercent(openRate)} />
                      <List.TextColumn data={formatNumberWithCommas(click, 0)} />
                      <List.TextColumn data={formatPercent(clickRate)} />
                      <List.TextColumn
                        css={{ minWidth: 140 }}
                        data={
                          status === LineBroadcastStatus.DRAFT || !deliveredDateTime
                            ? '-'
                            : format(new Date(deliveredDateTime + 'Z'), 'dd/MM/yyyy hh:mm')
                        }
                      />
                      <List.TextColumn
                        css={{ minWidth: 140 }}
                        data={createdDateTime ? format(new Date(createdDateTime + 'Z'), 'dd/MM/yyyy hh:mm') : '-'}
                      />
                    </SliderTableStyledComponents.StyledRowNew>
                  );
                })}
              </tbody>
            )}
          </SliderTableComponents.SliderTable>
        </SliderTableComponents.SliderSection>
      </SliderTableComponents.Container>

      {(loading || items.length === 0) && (
        <div css={styles.contentContainer}>
          {loading ? (
            <ListLoading />
          ) : lineConnectStatus !== 'connected' ? (
            <div css={styles.connectLineContainer}>
              <div>
                <img height="162" src={connectLineImg} width="208" />
              </div>
              <div>{t('Connect your LINE Official Account')}</div>
              <div>{t('To send the test broadcast, you need to connect your LINE Official')}</div>
              <div>
                <ThemeButton css={{ width: 'fit-content' }} text="Connect LINE" to={ROUTES.MY_PAGE_CHANNEL_CONNECT} />
              </div>
            </div>
          ) : error ? (
            <Empty title="UnexpectedError" content={error.message} />
          ) : isEmptyFilter ? (
            <Empty
              title="There is No Broadcast"
              content="You can send the same message to all users who are friends with your LINE Official Account"
            />
          ) : (
            <Empty title="No Broadcasts Found" content="Try changing the search term" />
          )}
        </div>
      )}
    </div>
  );
};

const HeaderColumn = styled(List.HeaderColumn)({
  minWidth: 100,

  '& > div > div': {
    color: THEME.font.colors.black.main,
    fontSize: THEME.font.sizes.subordinate,
    fontWeight: 600,
  },
});

const styles = {
  connectLineContainer: css({
    display: 'grid',
    justifyContent: 'center',
    textAlign: 'center',
    width: 500,

    '& > div:nth-of-type(1)': {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: 32,
    },

    '& > div:nth-of-type(2)': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.heading,
      marginBottom: 16,
    },

    '& > div:nth-of-type(3)': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.normal,
      marginBottom: 24,
    },

    '& > div:nth-of-type(4)': {
      display: 'flex',
      justifyContent: 'center',
    },
  }),
  clearTopBorder: css({
    '& > td': {
      borderTop: 'none',
    },
  }),
  contentContainer: css({
    alignItems: 'center',
    backgroundColor: THEME.colors.white,
    display: 'flex',
    height: 500,
    justifyContent: 'center',
    width: '100%',
  }),
  tag: css({
    background: THEME.colors.gray.eef3f7,
    borderRadius: THEME.box.borderRadius.xs,
    color: THEME.font.colors.black.main,
    fontSize: THEME.font.sizes.subordinate,
    padding: '4px 8px',
    width: 'fit-content',
  }),
};

export default BroadcastList;
