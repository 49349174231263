import React from 'react';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import emptyThumbnail from '@src/assets/icon/emptyThumbnail.svg';
import { Empty, Icomoon, Icon, TextCutter } from '@src/components/atoms';
import { ListLoading } from '@src/components/molecules';
import { getPaymentStatusText, getShippingStatusText } from '@src/components/shared/FanOrders/helper';
import { useGetFanOrdersQuery } from '@src/graphql/hooks';
import { localizedDateFormatter } from '@src/libs/date';
import { formatNumberWithCommas } from '@src/libs/format';
import { usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { THEME } from '@src/libs/theme';
import { AppLanguage } from '@src/libs/i18n/languageDetector/utils';
import { ShopifyOrderPaymentStatus, ShopifyOrderShippingStatus } from '@src/__generated__/globalTypes';
import { generatePath, ROUTES } from '@src/shared/routes';

const Orders = () => {
  const { i18n, params, t } = useQueryHelper();
  const { isMobileView } = usePageLayout();
  const { activeChat, fanId } = params;
  const { data, loading } = useGetFanOrdersQuery({
    variables: {
      input: {
        fanId: Number(fanId),
      },
    },
  });
  const ORDER_TIME_FORMAT = `d MMM yyyy 'at' h:mm aaa`;

  const orders = data?.getFanOrders?.orders || [];
  const pathOptions = activeChat && fanId ? { activeChat, fanId } : undefined;

  return (
    <div css={styles.container}>
      {!isMobileView && (
        <div css={styles.navigatorContainer}>
          <Link to={generatePath(ROUTES.MY_PAGE_MESSAGE_CHAT_ACTIVE_ID_FAN_ID, pathOptions)}>
            <Icon>
              <Icomoon css={{ transform: 'rotate(90deg)' }} icon="arrow-down" size={10} />
            </Icon>
          </Link>
          <Icomoon icon="clipboard-orange-light" size={28} />
          <div>{t('MenuTitle.Orders')}</div>
        </div>
      )}

      <ContentContainer isMobileView={isMobileView}>
        {loading ? (
          <ListLoading />
        ) : orders.length > 0 ? (
          orders.map(order => {
            const { currency, items, orderId, orderTime, paymentStatus, shippingStatus } = order;
            const paymentStatusText = getPaymentStatusText(paymentStatus);
            const shippingStatusText = getShippingStatusText(shippingStatus);

            return (
              <div css={styles.orderContainer} key={orderId}>
                <div css={styles.orderHeader}>
                  <div>#{orderId}</div>
                  <StatusIndicator status={paymentStatus}>{t(paymentStatusText)}</StatusIndicator>
                  <StatusIndicator status={shippingStatus}>{t(shippingStatusText)}</StatusIndicator>
                  <div>
                    <span>
                      {localizedDateFormatter(
                        new Date(orderTime + 'Z'),
                        ORDER_TIME_FORMAT,
                        i18n.language as AppLanguage
                      )}
                    </span>
                  </div>
                </div>

                {items.map((item, index) => {
                  const { image, price, productName, quantity, variant } = item;

                  return (
                    <div css={styles.itemContainer} key={index}>
                      <div>
                        <img alt="productImg" src={image || emptyThumbnail} />
                      </div>
                      <div>
                        <TextCutter lines={2} text={productName} />
                        <div>{`Size/Color : ${variant}`}</div>
                      </div>
                      <div>
                        <div>{`${t(currency)}${formatNumberWithCommas(price)} x ${quantity}`}</div>
                        <div>{`${t(currency)}${formatNumberWithCommas(price * quantity)}`}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })
        ) : (
          <Empty />
        )}
      </ContentContainer>
    </div>
  );
};

const ContentContainer = styled.div<{ isMobileView: boolean }>(({ isMobileView }) => ({
  display: 'grid',
  height: isMobileView ? '90vh' : '65vh',
  overflowY: 'auto',
}));

const StatusIndicator = styled.div<{ status?: ShopifyOrderPaymentStatus | ShopifyOrderShippingStatus | null }>(
  ({ status }) => ({
    alignItems: 'center',
    backgroundColor:
      status === ShopifyOrderPaymentStatus.PAID || status === ShopifyOrderShippingStatus.FULFILLED
        ? '#40b87c'
        : '#FFB63D',
    borderRadius: 30,
    color: THEME.font.colors.white,
    display: 'flex',
    fontSize: THEME.font.sizes.hint,
    fontWeight: 600,
    height: 16,
    marginRight: 4,
    padding: '0 8px',
    width: 'fit-content',
  })
);

const styles = {
  container: css({
    display: 'grid',
  }),
  itemContainer: css({
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: 16,

    '& > div:nth-of-type(1)': {
      backgroundColor: '#dee5ec',
      height: 48,
      width: 48,

      '& > img': {
        objectFit: 'fill',
        width: '100%',
      },
    },

    '& > div:nth-of-type(2)': {
      borderRight: '1px solid #eef3f7',
      display: 'grid',
      flex: 1,
      padding: '0 8px',

      '& > p': {
        color: THEME.font.colors.black.main,
        fontSize: THEME.font.sizes.normal,
        fontWeight: 600,
        marginBottom: 8,
      },

      '& > div': {
        color: THEME.font.colors.gray.main,
        fontSize: THEME.font.sizes.subordinate,
      },
    },

    '& > div:nth-of-type(3)': {
      display: 'grid',
      flexBasis: '30%',
      padding: '0 8px',

      '& > div:nth-of-type(1)': {
        color: THEME.font.colors.gray.main,
        fontSize: THEME.font.sizes.subordinate,
        fontWeight: 600,
        marginBottom: 3,
      },

      '& > div:nth-of-type(2)': {
        color: THEME.font.colors.black.main,
        fontSize: 15,
        fontWeight: 600,
      },
    },
  }),
  navigatorContainer: css({
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: 16,

    '& > a': {
      alignItems: 'center',
      backgroundColor: THEME.colors.white,
      borderRadius: 5,
      boxShadow: THEME.box.shadows.outer,
      display: 'grid',
      height: 32,
      justifyContent: 'center',
      marginRight: 8,
      width: 32,

      '& > i': {
        margin: 0,
      },
    },

    '& > div': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.subHeading,
      fontWeight: 600,
      marginLeft: 8,
    },
  }),
  orderContainer: css({
    backgroundColor: THEME.colors.white,
    borderRadius: 7,
    boxShadow: THEME.box.shadows.outer,
    display: 'grid',
    marginBottom: 16,
    padding: 16,
  }),
  orderHeader: css({
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: 16,

    '& > div:nth-of-type(1)': {
      color: THEME.font.colors.blue.main,
      fontSize: THEME.font.sizes.subordinate,
      fontWeight: 600,
      marginRight: 4,
      textDecoration: 'underline',
    },

    '& > div:nth-of-type(4)': {
      display: 'flex',
      flex: 1,
      justifyContent: 'flex-end',
      minWidth: 0,

      '& > span': {
        color: THEME.font.colors.gray.main,
        fontSize: THEME.font.sizes.hint,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
  }),
};

export default Orders;
