import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Navigate } from 'react-router-dom';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import emptyYoutubeCmsRevenue from '@src/assets/img/youtubeCMSDashboard/emptyYoutubeCmsRevenue.png';
import { Avatar, Icomoon, Image, TextCutter, YoutubeCmsServerError } from '@src/components/atoms';
import { ListLoading, NotificationBadge, PagerV2 } from '@src/components/molecules';
import { getPageInfo } from '@src/components/molecules/Pager/helpers';
import { YearMonthPicker } from '@src/components/shared';
import {
  useYoutubeCmsChannelsForInfluencerQuery,
  useYoutubeCmsConfirmedChannelsForInfluencerQuery,
  useYoutubeCmsConfirmedMusicsForInfluencerQuery,
  useYoutubeCmsConfirmedVideosForInfluencerQuery,
  useYoutubeCmsMusicsForInfluencerQuery,
  useYoutubeCmsStartMonthForInfluencerQuery,
  useYoutubeCmsVideosForInfluencerQuery,
} from '@src/graphql/hooks';
import { CURRENCY_SYMBOLS, LIMIT_10 } from '@src/libs/constant';
import { ErrorTypes } from '@src/libs/error';
import { formatNumberWithCommas } from '@src/libs/format';
import { getFirstDayOfTheMonth, isNumber, sliceItemsOnPage } from '@src/libs/functions';
import { useYoutubeCmsRevenue } from '@src/libs/graphql';
import { useHelpCenterUrl } from '@src/libs/help';
import { useFilter, usePageLayout, useUrl } from '@src/libs/hooks';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import { lineWidgetState, useRecoil } from '@src/recoilAtoms';
import { generatePath, ROUTES } from '@src/shared/routes';
import { Types } from './helpers';

const YoutubeCmsRevenue = () => {
  const { searchParams } = useUrl();
  const page = Number(searchParams.get('p')) || 1;
  const { filter, setFilter } = useFilter({
    month: searchParams.get('month') || '',
    p: page,
    type: searchParams.get('type') || Types.CHANNEL,
  });
  const { learnAboutEstimatedRevenue } = useHelpCenterUrl();
  const { isMobileView } = usePageLayout();
  const { t } = useTranslation();
  const { setRecoilState } = useRecoil(lineWidgetState);
  const filterBy = filter.type as Types;
  const imageSize = isMobileView ? 48 : 40;
  const scrollLimit = 5;

  const { data: dataStartMonth, loading: loadingStartMonth } = useYoutubeCmsStartMonthForInfluencerQuery();
  const marginRateStartMonth = dataStartMonth?.youtubeCmsStartMonthForInfluencer?.month;

  const skip = loadingStartMonth || !filter.month;
  const variables = {
    ...(filter.month && { month: filter.month }),
  };
  const { isConfirmedRevenue, loadingRevenue, youtubeCmsRevenue } = useYoutubeCmsRevenue({
    skip,
    ...(filter.month && { month: filter.month }),
  });
  const currencySymbol = youtubeCmsRevenue?.currency ? CURRENCY_SYMBOLS[youtubeCmsRevenue.currency] : '';
  const tabs = {
    [Types.CHANNEL]: {
      columnTitle: 'Channel name',
      title: 'Channel',
    },
    [Types.MUSIC]: {
      columnTitle: 'Rewarded Music',
      title: 'Music',
    },
    [Types.VIDEO]: {
      columnTitle: 'Video with Music',
      title: 'Video',
    },
  };
  const selectedTab = tabs[filterBy];

  const skipQuery = loadingRevenue || isConfirmedRevenue || !youtubeCmsRevenue?.total;
  const {
    data: dataChannels,
    error: errorChannels,
    loading: loadingChannels,
  } = useYoutubeCmsChannelsForInfluencerQuery({
    skip: skipQuery || filterBy !== Types.CHANNEL,
    variables,
  });
  const {
    data: dataMusics,
    error: errorMusic,
    loading: loadingMusics,
  } = useYoutubeCmsMusicsForInfluencerQuery({
    skip: skipQuery || filterBy !== Types.MUSIC,
    variables,
  });
  const {
    data: dataVideos,
    error: errorVideos,
    loading: loadingVideos,
  } = useYoutubeCmsVideosForInfluencerQuery({
    skip: skipQuery || filterBy !== Types.VIDEO,
    variables,
  });

  const skipConfirmedQuery = loadingRevenue || !isConfirmedRevenue || !youtubeCmsRevenue?.total || !filter.month;
  const {
    data: dataConfirmedChannels,
    error: errorConfirmedChannels,
    loading: loadingConfirmedChannels,
  } = useYoutubeCmsConfirmedChannelsForInfluencerQuery({
    skip: skipConfirmedQuery || filterBy !== Types.CHANNEL,
    variables: {
      month: filter.month,
    },
  });
  const {
    data: dataConfirmedMusics,
    error: errorConfirmedMusics,
    loading: loadingConfirmedMusics,
  } = useYoutubeCmsConfirmedMusicsForInfluencerQuery({
    skip: skipConfirmedQuery || filterBy !== Types.MUSIC,
    variables: {
      month: filter.month,
    },
  });
  const {
    data: dataConfirmedVideos,
    error: errorConfirmedVideos,
    loading: loadingConfirmedVideos,
  } = useYoutubeCmsConfirmedVideosForInfluencerQuery({
    skip: skipConfirmedQuery || filterBy !== Types.VIDEO,
    variables: {
      month: filter.month,
    },
  });

  useEffect(() => {
    setRecoilState({ isHidden: true });

    return () => {
      setRecoilState({ isHidden: false });
    };
  }, []);

  useEffect(() => {
    const date = new Date();
    const firstDayOfTheMonth = getFirstDayOfTheMonth();

    if (!filter.month) {
      setFilter({ ...filter, month: firstDayOfTheMonth, p: page });
    } else {
      const marginRateDate = marginRateStartMonth ? new Date(marginRateStartMonth) : null;
      const selectedDate = new Date(filter.month);

      const selectedMonth = marginRateDate
        ? selectedDate.setHours(0, 0, 0, 0) >= marginRateDate.setHours(0, 0, 0, 0) &&
          selectedDate.setHours(0, 0, 0, 0) < date.setHours(0, 0, 0, 0)
          ? filter.month
          : marginRateStartMonth || firstDayOfTheMonth
        : selectedDate.setHours(0, 0, 0, 0) < date.setHours(0, 0, 0, 0)
        ? filter.month
        : firstDayOfTheMonth;
      setFilter({ ...filter, month: selectedMonth, p: page });
    }
  }, [filter.month, filter.type, marginRateStartMonth, page]);

  const isLoadingList =
    loadingChannels ||
    loadingMusics ||
    loadingVideos ||
    loadingConfirmedChannels ||
    loadingConfirmedMusics ||
    loadingConfirmedVideos;
  const isServerError = !![
    errorChannels?.message,
    errorMusic?.message,
    errorVideos?.message,
    errorConfirmedChannels?.message,
    errorConfirmedMusics?.message,
    errorConfirmedVideos?.message,
  ].includes(ErrorTypes.DATA_TIMEOUT);
  const listItems =
    (filterBy === Types.CHANNEL
      ? isConfirmedRevenue
        ? dataConfirmedChannels?.youtubeCmsConfirmedChannelsForInfluencer
        : dataChannels?.youtubeCmsChannelsForInfluencer
      : filterBy === Types.MUSIC
      ? isConfirmedRevenue
        ? dataConfirmedMusics?.youtubeCmsConfirmedMusicsForInfluencer.map(item => ({
            ...item,
            thumbnail: null,
          }))
        : dataMusics?.youtubeCmsMusicsForInfluencer.map(item => ({
            ...item,
            thumbnail: null,
          }))
      : isConfirmedRevenue
      ? dataConfirmedVideos?.youtubeCmsConfirmedVideosForInfluencer
      : dataVideos?.youtubeCmsVideosForInfluencer.map(
          ({ currency, id, revenue, rpm, shareRate, thumbnail, title }) => ({
            currency,
            id,
            name: title,
            revenue,
            rpm,
            shareRate,
            thumbnail,
          })
        )) || [];
  const pageInfo = getPageInfo(page, listItems.length, LIMIT_10);

  const onChangeFilterBy = (type: Types) => {
    setFilter({ ...filter, p: 1, type });
  };

  const onChangePicker = (month: string) => {
    setFilter({ ...filter, month, p: 1 });
  };

  if (filter.month && !loadingStartMonth && !loadingRevenue && !youtubeCmsRevenue && !isServerError) {
    return <Navigate to={ROUTES.FIND_JOBS} />;
  }

  const informationBanner = (
    <div css={styles.informationBanner}>
      <div>
        <Icomoon color={THEME.colors.blue.main} icon="info-circle" size={16} />
        <label className="description">{t('Annotation.CmsBanner')}</label>
      </div>
    </div>
  );

  const yearMonthPicker = (
    <YearMonthPicker
      css={{ width: '60%' }}
      value={filter.month || ''}
      onChange={onChangePicker}
      disabledDates={{ after: new Date(), ...(marginRateStartMonth && { before: new Date(marginRateStartMonth) }) }}
    />
  );

  return (
    <div>
      {isMobileView ? (
        <div css={styles.mobilePickerContainer}>
          {informationBanner}
          {yearMonthPicker}
        </div>
      ) : (
        <div css={styles.header}>
          <div className="selector-container">
            {informationBanner}
            <div className="year-month-picker">{yearMonthPicker}</div>
          </div>
          <div className="badge-container">
            <NotificationBadge />
          </div>
        </div>
      )}

      {loadingRevenue ? (
        <ListLoading css={isMobileView && { paddingTop: 184 }} isTransparentBg />
      ) : (
        <div css={!isMobileView && { display: 'grid', justifyItems: 'center', padding: 16 }}>
          <div css={styles.revenueContainer}>
            <Status isConfirmedRevenue={isConfirmedRevenue}>{t(isConfirmedRevenue ? 'Confirmed' : 'Estimated')}</Status>
            <div className="revenue-rpm-container">
              <label className="revenue">{`${currencySymbol}${formatNumberWithCommas(
                youtubeCmsRevenue?.total,
                undefined,
                youtubeCmsRevenue?.currency
              )}`}</label>
              <label className="rpm">{`${currencySymbol}${formatNumberWithCommas(
                youtubeCmsRevenue?.rpm,
                undefined,
                youtubeCmsRevenue?.currency
              )} ${t('RPM')}`}</label>
            </div>
            <div className="link">
              <Icomoon color={THEME.font.colors.blue.main} icon="warning-circle-outlined" size={16} />
              <Link target="_blank" to={learnAboutEstimatedRevenue}>
                {t(
                  isConfirmedRevenue
                    ? 'Annotation.Amount shown excludes taxes and other deductions'
                    : 'Annotation.Learn more about Estimated Revenue'
                )}
              </Link>
            </div>
          </div>

          {!youtubeCmsRevenue?.total ? (
            <div css={styles.emptyListContainer}>
              <img height={160} src={emptyYoutubeCmsRevenue} width={160} />
              <label>{t('Annotation.No revenue generated yet')}</label>
            </div>
          ) : isServerError ? (
            <div css={styles.serverErrorContainer}>
              <YoutubeCmsServerError pageTitle="Revenue" />
            </div>
          ) : (
            <div css={styles.contentContainer}>
              <div css={styles.tabs}>
                {Object.keys(tabs).map(key => (
                  <Tab isActive={key === filterBy} key={key} onClick={() => onChangeFilterBy(key as Types)}>
                    {t(tabs[key as Types.MUSIC].title)}
                  </Tab>
                ))}
              </div>
              <TableHeader isConfirmedRevenue={isConfirmedRevenue}>
                <div>{t(selectedTab?.columnTitle || '')}</div>
                <div>{t(isConfirmedRevenue ? 'Confirmed Revenue' : 'Estimated Revenue')}</div>
              </TableHeader>
              {isLoadingList ? (
                <ListLoading
                  css={
                    isMobileView
                      ? {
                          position: 'fixed',
                          top: 427, // set is back to 303 when banner is removed
                          width: 'fill-available',
                        }
                      : { background: THEME.colors.white, border: 'none' }
                  }
                  height="250px"
                  spinnerProps={{
                    color: THEME.colors.disabled,
                    size: '32px',
                    thickness: '4px',
                  }}
                />
              ) : (
                <div
                  css={[
                    styles.listContainer,
                    isMobileView &&
                      listItems.length >= scrollLimit && {
                        height: 'calc(100vh - 427px)', // set it back to 'calc(100vh - 303px)' when banner is removed
                      },
                  ]}
                >
                  {(isMobileView ? listItems : sliceItemsOnPage(listItems, page)).map(
                    ({ currency, id, name, revenue, rpm, thumbnail }, index) => {
                      const isOtherAsset = id === 'other';
                      const isVideoList = filterBy === Types.VIDEO && !isOtherAsset;

                      return (
                        <ListItem
                          disabled={!isVideoList}
                          isConfirmedRevenue={isConfirmedRevenue}
                          isOtherAsset={isOtherAsset}
                          key={`${index}_${id}`}
                          to={
                            isVideoList
                              ? generatePath(
                                  ROUTES.YOUTUBE_CMS_REVENUE_VIDEO_VIDEOID,
                                  { videoId: id },
                                  {
                                    currency,
                                    month: filter.month,
                                    rpm,
                                    ...(isConfirmedRevenue
                                      ? { confirmedRevenue: revenue }
                                      : { estimatedRevenue: revenue }),
                                  }
                                )
                              : ''
                          }
                        >
                          <div>
                            {isOtherAsset ? (
                              <div>
                                <Icomoon
                                  icon={filterBy === Types.CHANNEL ? 'avatars' : 'videos'}
                                  size={isMobileView ? 40 : 32}
                                />
                              </div>
                            ) : filterBy === Types.CHANNEL ? (
                              name || thumbnail ? (
                                <Avatar size={isMobileView ? 48 : 40} src={thumbnail || ''} title={name || ''} />
                              ) : (
                                <div css={styles.emptyAvatarContainer}>
                                  <Icomoon icon="image-grey" size={isMobileView ? 16 : 14} />
                                </div>
                              )
                            ) : filterBy === Types.VIDEO ? (
                              thumbnail ? (
                                <Image
                                  css={{ borderRadius: THEME.box.borderRadius.m, minWidth: imageSize }}
                                  height={imageSize}
                                  src={thumbnail}
                                  width={imageSize}
                                />
                              ) : (
                                <div css={styles.emptyImageContainer}>
                                  <Icomoon
                                    color={THEME.colors.gray.c5d0da}
                                    icon="dropdown"
                                    size={isMobileView ? 24 : 20}
                                  />
                                </div>
                              )
                            ) : null}
                            <div>
                              <TextCutter
                                lines={2}
                                text={
                                  isOtherAsset
                                    ? filterBy === Types.CHANNEL
                                      ? t('Annotation.Total Revenue from Other Channels')
                                      : t('Annotation.Total Revenue from Other Videos')
                                    : name || id
                                }
                              />
                            </div>
                          </div>
                          <div>
                            <label>{`${currencySymbol}${formatNumberWithCommas(revenue, undefined, currency)}`}</label>
                            {isNumber(rpm) && (
                              <label>{`${currencySymbol}${formatNumberWithCommas(rpm, undefined, currency)} ${t(
                                'RPM'
                              )}`}</label>
                            )}
                          </div>
                        </ListItem>
                      );
                    }
                  )}

                  {isMobileView && listItems.length >= scrollLimit && (
                    <div css={{ background: THEME.colors.white, height: 180 }} />
                  )}
                </div>
              )}
            </div>
          )}

          {!isMobileView && !isServerError && (
            <PagerV2
              css={{ marginBottom: 80 }}
              currentPage={page}
              first={pageInfo.firstIndex}
              last={pageInfo.lastIndex}
              totalCount={pageInfo.totalCount}
              totalPages={pageInfo.totalPages}
            />
          )}
        </div>
      )}
    </div>
  );
};

const ListItem = styled(Link)<{ disabled: boolean; isConfirmedRevenue: boolean; isOtherAsset: boolean }>(
  ({ disabled, isConfirmedRevenue, isOtherAsset }) => ({
    alignItems: 'center',
    background: THEME.colors.white,
    color: isOtherAsset ? THEME.font.colors.gray.main : THEME.font.colors.black.main,
    display: 'flex',
    fontSize: isOtherAsset ? THEME.font.sizes.subordinate : THEME.font.sizes.normal,
    fontWeight: isOtherAsset ? 600 : 400,
    padding: '12px 24px',
    ...(disabled && { pointerEvents: 'none' }),

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      ...(isOtherAsset && { fontSize: 13 }),
    },

    '& > div:nth-of-type(1)': {
      alignItems: 'center',
      display: 'flex',
      gap: THEME.box.gaps.s,
      minWidth: '60%',

      [`@media (min-width: ${ViewportType.TABLET}px)`]: {
        minWidth: '70%',
      },
    },

    '& > div:nth-of-type(2)': {
      display: 'grid',
      textAlign: 'right',
      width: 'fill-available',

      '& > label:nth-of-type(1)': {
        color: THEME.font.colors.black.main,
        fontSize: THEME.font.sizes.heading,
        fontWeight: 600,
        ...(isConfirmedRevenue && { color: THEME.font.colors.success }),
      },

      '& > label:nth-of-type(2)': {
        color: THEME.font.colors.gray.main,
        fontSize: THEME.font.sizes.hint,
        fontWeight: 400,
      },
    },
  })
);

const Status = styled.label<{ isConfirmedRevenue: boolean }>(({ isConfirmedRevenue }) => ({
  background: isConfirmedRevenue ? '#c6ead8' : THEME.colors.gray.dee5ec,
  borderRadius: THEME.box.borderRadius.m,
  color: THEME.font.colors.black.main,
  fontSize: THEME.font.sizes.subordinate,
  padding: '2px 8px',
  width: 'fit-content',
}));

const Tab = styled.div<{ isActive: boolean }>(({ isActive }) => ({
  color: isActive ? THEME.font.colors.black.main : '#a8b4bf',
  cursor: 'pointer',
  fontSize: THEME.font.sizes.subHeading,
  fontWeight: 600,
  padding: '15px 0 12px',
  textAlign: 'center',
  ...(isActive && { borderBottom: '2px solid #27313b' }),

  [`@media (min-width: ${ViewportType.TABLET}px)`]: {
    padding: '22px 0 12px',
  },
}));

const TableHeader = styled.div<{ isConfirmedRevenue: boolean }>(({ isConfirmedRevenue }) => ({
  background: THEME.colors.white,
  borderBottom: THEME.box.borders.gray.main,
  color: THEME.font.colors.gray.main,
  display: 'flex',
  fontSize: THEME.font.sizes.subordinate,
  padding: '12px 24px',

  [`@media (max-width: ${ViewportType.TABLET}px)`]: {
    position: 'fixed',
    top: 387.5, // set is back to 264 when banner is removed
    width: 'fill-available',
  },

  '& > div:nth-of-type(1)': {
    minWidth: '60%',

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      minWidth: '70%',
    },
  },

  '& > div:nth-of-type(2)': {
    textAlign: 'right',
    width: 'fill-available',
    ...(isConfirmedRevenue && { color: THEME.font.colors.success }),
  },
}));

const styles = {
  contentContainer: css({
    border: THEME.box.borders.gray.main,
    maxWidth: 614,
    width: 'fill-available',

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      borderRadius: THEME.box.borderRadius.l,
      overflow: 'hidden',
    },
  }),
  emptyAvatarContainer: css({
    alignItems: 'center',
    background: THEME.colors.gray.dee5ec,
    borderRadius: '50%',
    display: 'grid',
    justifyContent: 'center',
    minHeight: 48,
    minWidth: 48,

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      minHeight: 40,
      minWidth: 40,
    },
  }),
  emptyImageContainer: css({
    alignItems: 'center',
    background: THEME.colors.gray.dee5ec,
    borderRadius: THEME.box.borderRadius.m,
    display: 'grid',
    justifyContent: 'center',
    minHeight: 48,
    minWidth: 48,

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      minHeight: 40,
      minWidth: 40,
    },

    '& svg': {
      transform: 'rotate(270deg)',
    },
  }),
  emptyListContainer: css({
    alignContent: 'center',
    color: THEME.font.colors.gray.main,
    display: 'grid',
    fontSize: THEME.font.sizes.subHeading,
    gap: THEME.box.gaps.l,
    justifyItems: 'center',
    position: 'fixed',
    textAlign: 'center',
    top: 378, // set it back to 312 when banner is removed
    width: '100%',
    whiteSpace: 'pre-line',

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      borderRadius: THEME.box.borderRadius.l,
      border: THEME.box.borders.gray.main,
      height: 327,
      position: 'unset',
      top: 'unset',
      width: 614,
    },
  }),
  header: css({
    alignItems: 'flex-start',
    background: THEME.colors.white,
    display: 'flex',
    justifyContent: 'center',
    padding: '20px 0',
    position: 'relative',
    width: 'fill-available',

    '& > .selector-container': {
      display: 'grid',
      gap: THEME.box.gaps.m,
      justifyItems: 'center',
      maxWidth: 614,

      '& > .year-month-picker': {
        width: 343,
      },
    },

    '& > .badge-container': {
      right: 40,
      margin: 'auto 0',
      position: 'absolute',
    },
  }),
  informationBanner: css({
    alignItems: 'center',
    display: 'grid',
    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      height: 76,
    },

    '& > div': {
      alignItems: 'flex-start',
      background: '#f3f8fd',
      border: '1px solid #c3def7',
      borderRadius: THEME.box.borderRadius.xs,
      display: 'flex',
      gap: THEME.box.gaps.s,
      padding: 8,

      '& > .description': {
        color: THEME.font.colors.black.main,
        fontSize: THEME.font.sizes.subordinate,
      },
    },
  }),
  listContainer: css({
    background: THEME.colors.gray.dee5ec,
    display: 'grid',
    gap: 1,

    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      overflow: 'auto',
      position: 'fixed',
      top: 427, // set it back to 303 when banner is removed
      width: 'fill-available',
    },
  }),
  mobilePickerContainer: css({
    background: THEME.colors.white,
    display: 'grid',
    gap: THEME.box.gaps.m,
    padding: '8px 16px',
    position: 'fixed',
    width: 'fill-available',
  }),
  revenueContainer: css({
    alignItems: 'center',
    background: THEME.colors.white,
    display: 'grid',
    gap: THEME.box.gaps.s,
    padding: '8px 16px 18px 16px',
    position: 'fixed',
    width: 'fill-available',
    top: 184, // set it back to 96 when banner is removed

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      borderRadius: THEME.box.borderRadius.l,
      boxShadow: THEME.box.shadows.outer,
      justifyItems: 'center',
      marginBottom: 12,
      maxWidth: 582,
      padding: '22px 16px',
      position: 'unset',
    },

    '& > .revenue-rpm-container': {
      display: 'grid',
      gap: THEME.box.gaps.xs,

      [`@media (min-width: ${ViewportType.TABLET}px)`]: {
        textAlign: 'right',
      },

      '& > .revenue': {
        color: THEME.font.colors.black.main,
        fontSize: 32,
        fontWeight: 700,
      },

      '& > .rpm': {
        color: THEME.font.colors.gray.main,
        fontSize: THEME.font.sizes.normal,
      },
    },

    '& > .link': {
      alignItems: 'center',
      borderTop: THEME.box.borders.gray.eef3f7,
      display: 'flex',
      gap: THEME.box.gaps.xs,
      marginTop: 8,
      paddingTop: 8,
      width: 'fill-available',

      [`@media (min-width: ${ViewportType.TABLET}px)`]: {
        justifyContent: 'center',
      },

      '& > a': {
        color: THEME.font.colors.blue.main,
        fontSize: THEME.font.sizes.normal,
      },
    },
  }),
  serverErrorContainer: css({
    alignItems: 'center',
    display: 'grid',
    justifyContent: 'center',
    position: 'fixed',
    top: 378, // set it back to 310 when banner is removed
    width: '100%',

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      border: THEME.box.borders.gray.main,
      borderRadius: THEME.box.borderRadius.l,
      height: 400,
      maxWidth: 614,
      position: 'unset',
    },
  }),
  tabs: css({
    background: '#f6f8fa',
    borderBottom: THEME.box.borders.gray.main,
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    padding: '0 16px',
    position: 'fixed',
    top: 338, // set is back to 214 when banner is removed
    width: 'fill-available',

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      padding: '0 24px',
      position: 'unset',
    },
  }),
};

export default YoutubeCmsRevenue;
