import { format } from 'date-fns';
import React from 'react';
import { Navigate } from 'react-router-dom';
import { css } from '@emotion/react';
import { BackNavigator } from '@src/components/molecules';
import EmailForm, { dateFormat } from '@src/components/shared/EmailForm';
import { useAuthData, usePageLayout } from '@src/libs/hooks';
import { useGetPersonalUserDetailQuery } from '@src/graphql/hooks';
import { ROUTES } from '@src/shared/routes';

const Compose = () => {
  const { limitedLogin } = useAuthData();
  if (limitedLogin) {
    return <Navigate to={ROUTES.MY_PAGE} />;
  }

  const { data } = useGetPersonalUserDetailQuery();
  const { isMobileView } = usePageLayout();

  return (
    <div css={styles.container}>
      {!isMobileView && (
        <div css={styles.navigatorContainer}>
          <BackNavigator title="Compose" to={ROUTES.MY_PAGE_EMAILS} />
        </div>
      )}

      <div css={styles.infoContainer}>
        <EmailForm
          backUrl={ROUTES.MY_PAGE_EMAILS}
          fans={[]}
          isDraft={false}
          message=""
          scheduleDate={format(new Date(), dateFormat)}
          sender={data?.getPersonalUserDetail?.email || ''}
          sendNow={true}
          subject=""
          tags={[]}
        />
      </div>
    </div>
  );
};

const styles = {
  container: css({
    display: 'grid',
    padding: '16px 24px',
  }),
  infoContainer: css({
    justifySelf: 'center',
    maxWidth: 648,
    width: '100%',
  }),
  navigatorContainer: css({
    marginBottom: 16,
  }),
};

export default Compose;
