import React, { useState, useCallback } from 'react';
import { Navigate } from 'react-router-dom';
import { css } from '@emotion/react';
import { getCurrentPage } from '@src/components/molecules/Pager/helpers';
import { useAuthData, usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { ThemeButton } from '@src/components/molecules';
import { useRemoveFansMutation } from '@src/graphql/hooks';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import { ROUTES } from '@src/shared/routes';
import { Order } from '@src/__generated__/globalTypes';
import FansList from './FansList';
import FanListFilter from './FanListFilter';
import { useFilter } from './useFilter';
import useFanSelection from './useFanSelection';
import SelectionNotice from './SelectionNotice';

const Fans = () => {
  const { limitedLogin } = useAuthData();
  if (limitedLogin) {
    return <Navigate to={ROUTES.MY_PAGE} />;
  }

  const [sortOrder, setSortOrder] = useState(Order.DESC);
  const { isMobileView } = usePageLayout();
  const { filter, setFilter } = useFilter();
  const currentPage = getCurrentPage();
  const [removeFans] = useRemoveFansMutation({
    refetchQueries: ['GetFans'],
  });

  const {
    selectedFansId,
    selectedTags,
    isAllSelectedFromCurrentPage,
    totalFanCount,
    setTotalFanCount,
    toggleFanSelection,
    setFansIdFromCurrentPage,
    handleCheckAll,
    resetSelection,
  } = useFanSelection();

  const { t, enqueueSnackbar } = useQueryHelper();

  const handleSort = useCallback(() => {
    setSortOrder(sortOrder === Order.ASC ? Order.DESC : Order.ASC);
  }, [sortOrder]);

  const handleDelete = async () => {
    try {
      await removeFans({
        variables: {
          input: {
            fanIds: Array.from(selectedFansId),
          },
        },
      });
      enqueueSnackbar(t('succeededInDeleting'), { variant: 'success' });
      resetSelection();
    } catch (err) {
      const message = err?.message || 'failedToDelete';
      enqueueSnackbar(t(message), { variant: 'error' });
    }
  };

  return (
    <div css={styles.container}>
      <div css={[styles.header, isMobileView && styles.mobileHeader]}>
        {!isMobileView && <h4 css={styles.title}>{t('pageTitle.Fans')}</h4>}
        <ThemeButton text="Add Contact" theme="blue" to={ROUTES.MY_PAGE_FANS_ADD} width="fit-content" />
      </div>
      <FanListFilter
        filter={filter}
        setFilter={setFilter}
        sortOrder={sortOrder}
        onSort={handleSort}
        selectedFansId={selectedFansId}
      />
      {selectedFansId.size > 0 && (
        <SelectionNotice
          selectedFansId={selectedFansId}
          allSelectedTags={selectedTags}
          totalFanCount={totalFanCount}
          onConfirm={handleDelete}
          onSelectAll={handleCheckAll}
        />
      )}
      <FansList
        filter={filter}
        currentPage={currentPage}
        sortOrder={sortOrder}
        selectedFansId={selectedFansId}
        onChangeFanSelection={toggleFanSelection}
        setFansIdFromCurrentPage={setFansIdFromCurrentPage}
        setTotalFanCount={setTotalFanCount}
        isAllSelectedFromCurrentPage={isAllSelectedFromCurrentPage}
        onCheckAll={handleCheckAll}
      />
    </div>
  );
};

const styles = {
  container: css({
    padding: 16,

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      padding: '16px 24px',
    },
  }),
  header: css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 16,
  }),
  mobileHeader: css({
    justifyContent: 'flex-end',
  }),
  title: css({
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: THEME.font.sizes.heading,
    color: THEME.font.colors.black.main,
  }),
};

export default Fans;
