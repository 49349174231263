import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import ttcmInvitationImg from '@src/assets/img/castingMarketplace/ttcmInvitation.png';
import ttcmPostVideoImg from '@src/assets/img/castingMarketplace/ttcmPostVideo.png';
import ttcmUploadDraftVideoImg from '@src/assets/img/castingMarketplace/ttcmUploadDraftVideo.png';
import searchJobBackgroundImg from '@src/assets/img/castingMarketplace/searchJobBackground.png';
import { BackNavigator, ListLoading } from '@src/components/molecules';
import Dialog, { smallButtonStyles } from '@src/components/molecules/Dialog';
import { useEngagementForInfluencerQuery, useEngagementPostSocialAccountsForInfluencerQuery } from '@src/graphql/hooks';
import { FE_REDIRECT_MAPPING } from '@src/libs/constant';
import { useConnectSocialAuth, usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { getSocialMediaUrl } from '@src/libs/socialMedia';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import {
  AnyXEngagementCampaignStatus,
  AuthSocialAccountType,
  CampaignSocialMediaType,
  CampaignStatusForInfluencer,
  EngagementPostSocialAccount,
  SocialAccountType,
  TTCMStatus,
} from '@src/__generated__/globalTypes';
import { ThemeButton } from '@src/components/molecules';
import { ROUTES, generatePath } from '@src/shared/routes';
import CampaignDetails from './CampaignDetails';

const Details = () => {
  const { isMobileView } = usePageLayout();
  const { navigate, params, search, t } = useQueryHelper();
  const searchParams = new URLSearchParams(search);
  const isUploadDialog = searchParams.get('uploadDialog');
  const [dialogOpen, setDialogOpen] = useState<boolean>(!!isUploadDialog || false);
  const { connectSocialAuth } = useConnectSocialAuth(FE_REDIRECT_MAPPING.TTCM_RECONNECT);
  const engagementId = Number(params.id);
  const { data, loading } = useEngagementForInfluencerQuery({
    fetchPolicy: 'no-cache',
    variables: {
      pk: engagementId,
    },
  });
  const { data: dataSocialAccounts } = useEngagementPostSocialAccountsForInfluencerQuery({
    variables: {
      pk: engagementId,
    },
  });
  const ttcmInvitationProcedure = [
    { description: 'TTCM invitation procedure one' },
    { description: 'TTCM invitation procedure two' },
    { description: 'TTCM invitation procedure three' },
    { description: 'TTCM invitation procedure four' },
  ];
  const ttcmPostVideoProcedure = [
    { description: 'TTCM post video procedure one' },
    { description: 'TTCM post video procedure two' },
    { description: 'TTCM post video procedure three' },
    { description: 'TTCM post video procedure four' },
  ];
  const ttcmUploadDraftVideoProcedure = [
    { description: 'TTCM upload draft video procedure one' },
    { description: 'TTCM upload draft video procedure two' },
    { description: 'TTCM upload draft video procedure three' },
    { description: 'TTCM upload draft video procedure four' },
  ];

  const campaignDetails = data?.engagementForInfluencer;
  const socialAccounts = dataSocialAccounts?.engagementPostSocialAccountsForInfluencer || [];
  if (!loading && !campaignDetails) {
    navigate(ROUTES.MY_JOBS);
  }

  useEffect(() => {
    if (isUploadDialog) {
      searchParams.delete('uploadDialog');
      navigate({ search: searchParams.toString() }, { replace: true });
    }
  }, [isUploadDialog]);

  const hasDraftPosts = campaignDetails?.hasDraftPosts;
  const hasReport = campaignDetails?.hasReport;
  // TODO: to enable new flow when marketplace implement TTCM
  // const isTtcmCampaign = campaignDetails?.isTtcmCampaign;
  const isTtcmCampaign = false;
  const isCampaignFinish =
    campaignDetails?.anyXStatus === AnyXEngagementCampaignStatus.INEFFECTIVE ||
    campaignDetails?.status === CampaignStatusForInfluencer.FINISHED;
  const uploadDraftPost = campaignDetails?.status !== CampaignStatusForInfluencer.FINISHED && !hasDraftPosts;

  const { isNonTtcmAccounts, ttcmAccount } = socialAccounts.reduce(
    (accounts, value) => {
      const isTtcmAccount =
        isTtcmCampaign && value.socialMedia === SocialAccountType.TIKTOK && value.ttcmStatus === TTCMStatus.APPROVED;

      return {
        ...accounts,
        ...(!isTtcmAccount && { isNonTtcmAccounts: true }),
        // ...(isTtcmAccount && { ttcmAccount: value }),
      };
    },
    {
      isNonTtcmAccounts: false,
      ttcmAccount: null as EngagementPostSocialAccount | null,
    }
  );

  const urlTiktokUsername = `${getSocialMediaUrl(CampaignSocialMediaType.TIKTOK)}${
    ttcmAccount ? `/@${ttcmAccount.username}` : ''
  }`;

  const isInvited = campaignDetails?.ttcmStatus === TTCMStatus.INVITED;
  const isTtcmInvitation =
    isTtcmCampaign && [TTCMStatus.INVITED, TTCMStatus.REJECTED].includes(campaignDetails?.ttcmStatus as TTCMStatus);
  const isTtcmPosting = isTtcmCampaign && campaignDetails?.anyXStatus === AnyXEngagementCampaignStatus.POSTING;

  const onClickDialog = () => {
    setDialogOpen(true);
  };

  const onCloseDialog = () => {
    setDialogOpen(false);
  };

  const onClickUploadDraftVideo = async () => {
    if (!campaignDetails?.isTtcmReady) {
      connectSocialAuth(AuthSocialAccountType.TTCM, {
        campaignId: campaignDetails?.id,
        socialAccountId: ttcmAccount?.id,
      });
    } else {
      setDialogOpen(true);
    }
  };

  return (
    <>
      <Dialog
        contentStyles={{
          border: 'none',
          borderRadius: 5,
          width: 600,
          height: 555,
          maxHeight: '90vh',
          maxWidth: '90%',
        }}
        open={dialogOpen}
        onClose={onCloseDialog}
        {...(isTtcmInvitation && !isInvited && !isMobileView
          ? {}
          : isTtcmInvitation
          ? isInvited
            ? {
                cancelButtonProps: {
                  styles: smallButtonStyles,
                  onClick: onCloseDialog,
                },
                nextButtonProps: {
                  styles: smallButtonStyles,
                  target: '_blank',
                  text: 'Open TikTok APP',
                  theme: 'blue',
                  to: urlTiktokUsername,
                },
              }
            : {
                cancelButtonProps: {
                  styles: smallButtonStyles,
                  onClick: onCloseDialog,
                },
              }
          : isTtcmPosting
          ? {
              nextButtonProps: {
                styles: smallButtonStyles,
                target: '_blank',
                text: 'Post Video',
                theme: 'blue',
                to: urlTiktokUsername,
              },
            }
          : {
              nextButtonProps: {
                styles: smallButtonStyles,
                target: '_blank',
                text: 'Upload Draft Video',
                theme: 'blue',
                to: urlTiktokUsername,
              },
            })}
      >
        {isTtcmInvitation ? (
          isInvited ? (
            <div css={styles.dialogTtcmInvitation}>
              <div>{t('Title.Invitation from TikTok Creator Marketplace')}</div>
              <p>
                <Trans
                  components={{
                    1: (
                      <span
                        onClick={() =>
                          window.open(
                            'https://www.tiktok.com/creators/creator-portal/en-us/getting-paid-to-create/creator-marketplace/',
                            '_blank'
                          )
                        }
                      />
                    ),
                  }}
                  i18nKey="Annotation.To join campaign TikTok creator marketplace"
                />
              </p>
              <div>
                <div>
                  <img height={isMobileView ? 122 : 130} src={ttcmInvitationImg} width={isMobileView ? 183 : 195} />
                </div>
                {!isMobileView && <div>{t('Procedure')}</div>}
                <InstructionContainer>
                  {ttcmInvitationProcedure.map((procedure, procedureIndex) => (
                    <div key={procedureIndex}>
                      <div>{`${procedureIndex + 1}. `}</div>
                      <div>
                        <Trans
                          components={{ 1: <span style={{ fontWeight: 600 }} /> }}
                          i18nKey={procedure.description}
                        />
                      </div>
                    </div>
                  ))}
                </InstructionContainer>
              </div>
            </div>
          ) : (
            <div css={styles.dialogTemporaryClosedContainer}>
              <div>
                <div>
                  <img
                    height={isMobileView ? 124 : 115}
                    src={searchJobBackgroundImg}
                    width={isMobileView ? 120 : 111}
                  />
                </div>
                <div>
                  <div>{t('Sorry')}</div>
                  <div>{t('Annotation.The campaign is temporarily closed')}</div>
                </div>
                <div>{t('Annotation.Please wait until the campaign information will be updated')}</div>
                {!isMobileView && (
                  <div>
                    <ThemeButton text="Close" onClick={onCloseDialog} />
                  </div>
                )}
              </div>
            </div>
          )
        ) : (
          <div css={styles.dialogUploadDraftVideoContainer}>
            <div>
              <img height="120" src={isTtcmPosting ? ttcmPostVideoImg : ttcmUploadDraftVideoImg} width="180" />
            </div>
            <div>{t(isTtcmPosting ? `Title.Let's post video` : `Title.Let's upload draft video`)}</div>
            <div>
              {t(
                isTtcmPosting
                  ? 'Annotation.Your video has been approved and you can now post it'
                  : 'Annotation.To post a video, you need to open TikTok app and upload a video'
              )}
            </div>
            <InstructionContainer>
              {(isTtcmPosting ? ttcmPostVideoProcedure : ttcmUploadDraftVideoProcedure).map(
                (procedure, procedureIndex) => (
                  <div key={procedureIndex}>
                    <div>{`${procedureIndex + 1}.`}</div>
                    <div>
                      <Trans components={{ 1: <span style={{ fontWeight: 600 }} /> }} i18nKey={procedure.description} />
                    </div>
                  </div>
                )
              )}
            </InstructionContainer>
          </div>
        )}
      </Dialog>

      <div css={styles.container}>
        {!isMobileView && !loading && (
          <div css={styles.headerContainer}>
            <div>
              <BackNavigator
                title="Campaigns Detail"
                to={`${ROUTES.MY_JOBS}${
                  campaignDetails?.status === CampaignStatusForInfluencer.FINISHED ? '#finished' : ''
                }`}
              />
            </div>
            <div>
              {isTtcmInvitation ? (
                <ThemeButton text="Accept Invitation" theme="blue" onClick={onClickDialog} />
              ) : hasReport ? (
                <>
                  <ThemeButton
                    text={isTtcmCampaign ? 'Video List' : 'Post List'}
                    to={generatePath(ROUTES.MY_JOBS_CASTING_YOUR_JOB_ID_POST, { id: engagementId })}
                    width="max-content"
                  />
                  <ThemeButton
                    text={isTtcmCampaign ? 'Check Report' : 'View Report'}
                    to={generatePath(ROUTES.MY_JOBS_CASTING_YOUR_JOB_ID_REPORT, { id: engagementId })}
                    width="max-content"
                  />
                </>
              ) : !isCampaignFinish && hasDraftPosts ? (
                <>
                  <ThemeButton
                    text={isTtcmCampaign ? 'Video List' : 'Post List'}
                    to={generatePath(ROUTES.MY_JOBS_CASTING_YOUR_JOB_ID_POST, { id: engagementId })}
                    width="max-content"
                  />
                  {isTtcmPosting && <ThemeButton text="Post Now" theme="blue" onClick={onClickDialog} />}
                </>
              ) : uploadDraftPost ? (
                <>
                  {isNonTtcmAccounts && (
                    <ThemeButton
                      text="Upload Draft Post"
                      to={generatePath(ROUTES.MY_JOBS_CASTING_YOUR_JOB_ID_POST_ADD, { id: engagementId })}
                      width="max-content"
                    />
                  )}
                  {!!ttcmAccount && (
                    <ThemeButton
                      text="Upload Draft Video"
                      theme="blue"
                      width="max-content"
                      onClick={onClickUploadDraftVideo}
                    />
                  )}
                </>
              ) : null}
            </div>
          </div>
        )}

        {loading ? <ListLoading /> : campaignDetails && <CampaignDetails campaignDetails={campaignDetails} />}
      </div>

      {isMobileView &&
        !loading &&
        (isTtcmInvitation || uploadDraftPost || (!isCampaignFinish && hasDraftPosts) || hasReport) && (
          <div css={styles.actionContainer}>
            <div>
              {isTtcmInvitation ? (
                <ThemeButton text="Accept Invitation" theme="blue" onClick={onClickDialog} />
              ) : hasReport ? (
                <>
                  <ThemeButton
                    height="40px"
                    text={isTtcmCampaign ? 'Video List' : 'Post List'}
                    to={generatePath(ROUTES.MY_JOBS_CASTING_YOUR_JOB_ID_POST, { id: engagementId })}
                  />
                  <ThemeButton
                    height="40px"
                    text={isTtcmCampaign ? 'Check Report' : 'View Report'}
                    to={generatePath(ROUTES.MY_JOBS_CASTING_YOUR_JOB_ID_REPORT, { id: engagementId })}
                  />
                </>
              ) : !isCampaignFinish && hasDraftPosts ? (
                <>
                  <ThemeButton
                    height="40px"
                    text={isTtcmCampaign ? 'Video List' : 'Post List'}
                    to={generatePath(ROUTES.MY_JOBS_CASTING_YOUR_JOB_ID_POST, { id: engagementId })}
                  />
                  {isTtcmPosting && <ThemeButton text="Post Now" theme="blue" onClick={onClickDialog} />}
                </>
              ) : uploadDraftPost ? (
                <>
                  {isNonTtcmAccounts && (
                    <ThemeButton
                      height="40px"
                      text="Upload Draft Post"
                      to={generatePath(ROUTES.MY_JOBS_CASTING_YOUR_JOB_ID_POST_ADD, { id: engagementId })}
                    />
                  )}
                  {!!ttcmAccount && (
                    <ThemeButton
                      height="40px"
                      text="Upload Draft Video"
                      theme="blue"
                      onClick={onClickUploadDraftVideo}
                    />
                  )}
                </>
              ) : null}
            </div>
          </div>
        )}
    </>
  );
};

const InstructionContainer = styled.div({
  color: THEME.font.colors.black.main,
  display: 'grid',
  gap: THEME.box.gaps.s,
  fontSize: 13,

  [`@media (max-width: ${ViewportType.TABLET}px)`]: {
    fontSize: THEME.font.sizes.normal,
  },

  '& > div': {
    display: 'flex',
    gap: THEME.box.gaps.xs,
  },
});

const styles = {
  actionContainer: css({
    alignItems: 'center',
    backgroundColor: THEME.colors.white,
    bottom: 0,
    boxShadow: THEME.box.shadows.outer,
    display: 'flex',
    height: 72,
    position: 'fixed',
    width: '100%',

    '& > div': {
      display: 'flex',
      justifyContent: 'flex-end',
      margin: '0 16px',
      width: '100%',
      gap: THEME.box.gaps.l,

      '& > button': {
        [`@media (max-width: ${ViewportType.TABLET}px)`]: {
          height: 40,
        },
      },
    },
  }),
  container: css({
    margin: 16,

    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      margin: 0,
    },
  }),
  dialogTemporaryClosedContainer: css({
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    padding: '0 32px',

    '& > div': {
      '& > div:nth-of-type(1)': {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 32,

        [`@media (max-width: ${ViewportType.TABLET}px)`]: {
          marginBottom: 24,
        },
      },

      '& > div:nth-of-type(2)': {
        display: 'flex',
        marginBottom: 16,
        justifyContent: 'center',

        [`@media (max-width: ${ViewportType.TABLET}px)`]: {
          display: 'grid',
        },

        '& > div:nth-of-type(1)': {
          color: THEME.font.colors.black.main,
          display: 'flex',
          fontSize: THEME.font.sizes.heading,
          justifyContent: 'center',

          [`@media (max-width: ${ViewportType.TABLET}px)`]: {
            fontSize: 22,
            marginBottom: 4,
          },
        },

        '& > div:nth-of-type(2)': {
          color: THEME.font.colors.black.main,
          fontSize: THEME.font.sizes.heading,

          [`@media (max-width: ${ViewportType.TABLET}px)`]: {
            fontSize: THEME.font.sizes.normal,
          },
        },
      },

      '& > div:nth-of-type(3)': {
        color: THEME.font.colors.gray.main,
        fontSize: THEME.font.sizes.normal,
        marginBottom: 24,
        textAlign: 'center',
      },

      '& > div:nth-of-type(4)': {
        display: 'flex',
        justifyContent: 'center',

        '& button': {
          height: 40,

          [`@media (min-width: ${ViewportType.TABLET}px)`]: {
            height: 32,
            width: 'fit-content',
          },
        },

        [`@media (max-width: ${ViewportType.TABLET}px)`]: {
          borderTop: '1px solid #dee5ec',
          bottom: 0,
          left: 0,
          padding: 16,
          position: 'absolute',
          width: 'fill-available',
        },
      },
    },
  }),
  dialogTtcmInvitation: css({
    padding: '40px 32px 32px 32px',

    '& > div:nth-of-type(1)': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.heading,
      fontWeight: 600,
      marginBottom: 16,
    },

    '& > div:nth-of-type(2)': {
      border: THEME.box.borders.gray.main,
      borderRadius: 5,
      padding: 32,
      width: 'fill-available',

      '& > div:nth-of-type(1)': {
        display: 'flex',
        justifyContent: 'center',
      },

      '& > div:nth-of-type(2)': {
        [`@media (min-width: ${ViewportType.TABLET}px)`]: {
          color: THEME.font.colors.black.main,
          fontSize: THEME.font.sizes.subHeading,
          fontWeight: 600,
          marginBottom: 8,
        },
      },
    },

    '& > p': {
      color: THEME.font.colors.black.main,
      fontSize: 13,
      fontWeight: 400,
      marginBottom: 24,

      [`@media (min-width: ${ViewportType.TABLET}px)`]: {
        alignItems: 'center',
        display: 'flex',
        flexWrap: 'wrap',
      },

      '& > span': {
        color: THEME.font.colors.blue.main,
        cursor: 'pointer',
        textDecoration: 'underline',

        [`@media (min-width: ${ViewportType.TABLET}px)`]: {
          display: 'flex',
          flexBasis: '100%',
        },
      },
    },
  }),
  dialogUploadDraftVideoContainer: css({
    padding: '34px 16px',
    textAlign: 'center',

    '& > div:nth-of-type(1)': {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: 16,
    },

    '& > div:nth-of-type(2)': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.heading,
      fontWeight: 600,
      marginBottom: 8,
    },

    '& > div:nth-of-type(3)': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.normal,
      marginBottom: 24,
    },

    '& > div:nth-of-type(4)': {
      border: THEME.box.borders.gray.main,
      borderRadius: 5,
      padding: '24px 16px',
      textAlign: 'left',
      width: 'fill-available',
    },
  }),
  headerContainer: css({
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: 16,
    justifyContent: 'space-between',

    '& > div': {
      display: 'flex',
      gap: THEME.box.gaps.s,

      '& > div': {
        color: THEME.font.colors.black.main,
        fontSize: 13,
      },
    },
  }),
};

export default Details;
