export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  BigInt: { input: any; output: any };
  Date: { input: any; output: any };
  DateTime: { input: any; output: any };
  JSON: { input: any; output: any };
  Long: { input: any; output: any };
  _FieldSet: { input: any; output: any };
}

/** An enumeration. */
export enum AIModelName {
  GEMINI = 'GEMINI',
  OPENAI = 'OPENAI',
}

export interface AcceptedPayload {
  accepted: Scalars['Boolean']['output'];
}

export interface AccountAnalyticsAccountManager {
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
}

export interface AccountAnalyticsAdvertiser {
  country: Country;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
}

export interface AccountAnalyticsAdvertiserDetails {
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
}

export interface AccountAnalyticsDetail {
  accountManagers: Array<AccountAnalyticsAccountManager>;
  accountName: Scalars['String']['output'];
  advertiser: AccountAnalyticsAdvertiserDetails;
  id: Scalars['Int']['output'];
  socialMediaAccounts: AccountAnalyticsSocialMediaAccount;
  subscriptionPlan: AnalyticsSubscriptionPlanType;
}

export interface AccountAnalyticsFacebookPageAccount {
  avatar: Scalars['String']['output'];
  fbPageId: Scalars['String']['output'];
  fbPageName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  needReconnect: Scalars['Boolean']['output'];
  pageUrl: Scalars['String']['output'];
}

export interface AccountAnalyticsForAdvertiserPayload {
  accountName: Scalars['String']['output'];
  avatar: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  socialMediaAccounts: AccountAnalyticsSocialMediaAccount;
  subscriptionPlan: AnalyticsSubscriptionPlanType;
}

export interface AccountAnalyticsInstagramAccount {
  avatar: Scalars['String']['output'];
  businessAccountId: Scalars['String']['output'];
  fbPageId: Scalars['String']['output'];
  fbPageName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  instagramAccountName: Scalars['String']['output'];
  needReconnect: Scalars['Boolean']['output'];
}

export interface AccountAnalyticsPayload {
  accountManagers: Array<AccountAnalyticsAccountManager>;
  accountName: Scalars['String']['output'];
  advertiser: AccountAnalyticsAdvertiser;
  avatar: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  socialMediaAccounts: AccountAnalyticsSocialMediaAccount;
  subscriptionPlan: AnalyticsSubscriptionPlanType;
}

export interface AccountAnalyticsSocialMediaAccount {
  facebookPage?: Maybe<AccountAnalyticsFacebookPageAccount>;
  instagram?: Maybe<AccountAnalyticsInstagramAccount>;
  twitter?: Maybe<AccountAnalyticsTwitterAccount>;
  youtube?: Maybe<AccountAnalyticsYouTubeAccount>;
}

export interface AccountAnalyticsTwitterAccount {
  avatar: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  needReconnect: Scalars['Boolean']['output'];
  twitterUserId: Scalars['String']['output'];
  username: Scalars['String']['output'];
}

export interface AccountAnalyticsYouTubeAccount {
  avatar: Scalars['String']['output'];
  channelId: Scalars['String']['output'];
  channelName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  needReconnect: Scalars['Boolean']['output'];
}

export interface AccountAudienceDataRate {
  name: Scalars['String']['output'];
  rate: Scalars['Float']['output'];
}

export interface ActionLabelInput {
  label: Scalars['String']['input'];
  url: Scalars['String']['input'];
}

export interface ActionLabelPayload {
  label: Scalars['String']['output'];
  url: Scalars['String']['output'];
}

export interface ActivityChange {
  key: ChangeType;
  newValue?: Maybe<Scalars['String']['output']>;
  oldValue?: Maybe<Scalars['String']['output']>;
}

export interface AddAllSelectedInfluencers {
  ok: Scalars['Boolean']['output'];
}

export interface AddAllSelectedInfluencersInput {
  marketplaceId: Scalars['Int']['input'];
}

export interface AddCommentInput {
  comment: Scalars['String']['input'];
  fanId: Scalars['Long']['input'];
}

export interface AddCommentPayload {
  ok: Scalars['Boolean']['output'];
}

export interface AddCompareInstagramAccountAnalytics {
  ok: Scalars['Boolean']['output'];
}

export interface AddEmailTemplateInput {
  fans: Array<Scalars['Long']['input']>;
  isDraft: Scalars['Boolean']['input'];
  message: Scalars['String']['input'];
  scheduleDate?: InputMaybe<Scalars['String']['input']>;
  sendNow: Scalars['Boolean']['input'];
  sender: Scalars['String']['input'];
  subject: Scalars['String']['input'];
  tags: Array<Scalars['Long']['input']>;
}

export interface AddEmailTemplatePayload {
  ok: Scalars['Boolean']['output'];
}

export interface AddFanInput {
  contactNumber?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
}

export interface AddFanPayload {
  ok: Scalars['Boolean']['output'];
}

export interface AddFanTagsInput {
  fanIds: Array<Scalars['Long']['input']>;
  tags: Array<Scalars['String']['input']>;
}

export interface AddFanTagsPayload {
  ok: Scalars['Boolean']['output'];
}

export interface AddFormInput {
  description: Scalars['String']['input'];
  hash: Scalars['String']['input'];
  questions: Array<QuestionsInput>;
  thankDescription: Scalars['String']['input'];
  thankTitle: Scalars['String']['input'];
  title: Scalars['String']['input'];
}

export interface AddFormPayload {
  ok: Scalars['Boolean']['output'];
}

export interface AddInfluencerToUserProposal {
  ok: Scalars['Boolean']['output'];
}

export interface AddInfluencerToUserProposalInput {
  influencerIds: Array<Scalars['Int']['input']>;
}

export interface AddInstagramCompareAccountAnalyticsInput {
  /** an Instagram username of an account that we want to compare */
  compareAccountIgUsername: Scalars['String']['input'];
  /** an analytics account's system id that will be compared with the selected Instagram account */
  mainAnalyticsAccountId: Scalars['Int']['input'];
}

export interface AddInstagramHashtagAnalytics {
  ok: Scalars['Boolean']['output'];
}

export interface AddInstagramHashtagAnalyticsInput {
  /** an analytic account's system id where the selected hashtag will be added to */
  analyticsAccountId: Scalars['Int']['input'];
  /** a hashtag that we want to add */
  hashtag: Scalars['String']['input'];
}

export interface AddMarketplacePostUrl {
  ok: Scalars['Boolean']['output'];
}

export interface AddMarketplacePostUrlForInfluencer {
  ok: Scalars['Boolean']['output'];
}

export interface AddMarketplacePostUrlInput {
  influencerId: Scalars['Int']['input'];
  marketplaceId: Scalars['Int']['input'];
  postUrl: Scalars['String']['input'];
}

export interface AddMarketplacePostUrlInputForInfluencer {
  marketplaceId: Scalars['Int']['input'];
  postUrl: Scalars['String']['input'];
}

export interface AddReadTutorial {
  ok: Scalars['Boolean']['output'];
}

export interface AddReadTutorialInput {
  tutorialName: TutorialName;
}

export interface AddSelectedInfluencers {
  ok: Scalars['Boolean']['output'];
}

export interface AddSelectedInfluencersInput {
  influencerIds: Array<Scalars['Int']['input']>;
  marketplaceId: Scalars['Int']['input'];
}

export interface AddSocialAccountToUserProposal {
  ok: Scalars['Boolean']['output'];
}

export interface AddSocialAccountToUserProposalInput {
  influencerIds: Array<Scalars['Int']['input']>;
  socialAccountIds: Array<Scalars['Int']['input']>;
  socialAccountMedia: SocialAccountType;
}

export interface AddStaffAdvertisersToAnotherStaff {
  ok: Scalars['Boolean']['output'];
}

export interface AddStaffAdvertisersToAnotherStaffInput {
  /** List of added advertiser ids */
  advertiserIds: Array<Scalars['Int']['input']>;
  /** The id of a staff user who we need to add advertisers to, */
  otherStaffUserId: Scalars['Int']['input'];
}

export interface AddYoutubeCompareAccountAnalyticsInput {
  mainAccountId: Scalars['Int']['input'];
  url: Scalars['String']['input'];
}

export interface AddYoutubeCompareAccountAnalyticsOutput {
  ok: Scalars['Boolean']['output'];
}

export interface Address {
  info?: Maybe<AddressDetails>;
  isEditable: Scalars['Boolean']['output'];
}

export interface AddressDetails {
  address: Scalars['String']['output'];
  address2: Scalars['String']['output'];
  city: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  postalCode: Scalars['String']['output'];
  province: Scalars['String']['output'];
}

export interface AddressForInfluencer {
  address: Scalars['String']['output'];
  address2: Scalars['String']['output'];
  city: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  postalCode: Scalars['String']['output'];
  province: Scalars['String']['output'];
}

export interface AddressInput {
  address: Scalars['String']['input'];
  address2: Scalars['String']['input'];
  city: Scalars['String']['input'];
  fullName: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  province: Scalars['String']['input'];
}

export interface Advertiser {
  anyxMasterId?: Maybe<Scalars['Int']['output']>;
  anyxMasterName?: Maybe<Scalars['String']['output']>;
  companyUrl?: Maybe<Scalars['String']['output']>;
  country: CountryName;
  hubspotId?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  internalNetsuiteId?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  users: Array<AdvertiserUser>;
}

export interface AdvertiserForAdvertiserUser {
  country: CountryName;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
}

export interface AdvertiserForAdvertiserUserSettings {
  companyUrl?: Maybe<Scalars['String']['output']>;
  country: CountryName;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  phoneNumber: Scalars['String']['output'];
}

export interface AdvertiserForList {
  analyticsPlan?: Maybe<AnalyticsSubscriptionPlanType>;
  companyUrl?: Maybe<Scalars['String']['output']>;
  country: CountryName;
  hubspotId?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  internalNetsuiteId?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  tiktokPlan?: Maybe<TikTokAdvertiserPlanFilter>;
  users: Scalars['Int']['output'];
  verifiedStatus?: Maybe<NetSuiteVerifiedStatus>;
}

export interface AdvertiserForSearch {
  companyUrl?: Maybe<Scalars['String']['output']>;
  country: CountryName;
  hubspotId?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
}

export interface AdvertiserName {
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
}

/** An enumeration. */
export enum AdvertiserType {
  NORMAL = 'NORMAL',
  TIKTOK_BUSINESS = 'TIKTOK_BUSINESS',
}

export interface AdvertiserUser {
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
}

export interface AdvertiserUserInput {
  email: Scalars['String']['input'];
  hasInvitationEmail: Scalars['Boolean']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
  userName: Scalars['String']['input'];
}

export interface AdvertiserUserSettingsDetails {
  advertiser: AdvertiserForAdvertiserUserSettings;
  country: CountryName;
  email: Scalars['String']['output'];
  hasStripeSubscriptionPlan: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  notificationSetting: Scalars['Boolean']['output'];
  subscriptionPlan?: Maybe<SubscriptionPlanForAdvertiserUserSettings>;
}

export interface AdvertiserUserWithRole {
  companyName?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  role: UserRoles;
}

export interface AffiliateCampaignChartReport {
  cost: Scalars['Float']['output'];
  date: Scalars['Date']['output'];
  grossProfit: Scalars['Float']['output'];
  sales: Scalars['Float']['output'];
}

/** An enumeration. */
export enum AffiliateReportShowBy {
  DATE = 'DATE',
  INFLUENCER = 'INFLUENCER',
}

export interface AgenciesForSearch {
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
}

export interface AgencyLogo {
  logoUrl?: Maybe<Scalars['String']['output']>;
}

export interface AgencyUser {
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
}

export interface AgencyUserWithRole {
  companyName?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  role: UserRoles;
}

export interface AgencyWithAdvertisers {
  advertiserIds: Array<Scalars['Int']['output']>;
  companyUrl?: Maybe<Scalars['String']['output']>;
  country: CountryName;
  hubspotId?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  internalNetsuiteId?: Maybe<Scalars['String']['output']>;
  logo?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  users: Array<AgencyUser>;
}

export interface AgencyWithNSVerification {
  companyUrl?: Maybe<Scalars['String']['output']>;
  country: CountryName;
  hubspotId?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  internalNetsuiteId?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  users: Scalars['Int']['output'];
  verifiedStatus?: Maybe<NetSuiteVerifiedStatus>;
}

export interface AiChatModelName {
  modelName: AIModelName;
}

export interface AllAccountAnalyticsForAdminPayload {
  accounts: Array<AccountAnalyticsPayload>;
}

export interface AllAccountAnalyticsForAdvertiserPayload {
  accounts: Array<AccountAnalyticsForAdvertiserPayload>;
}

export interface AllAdvertisersForSearch {
  advertisers: Array<AdvertiserForSearch>;
  includedAdvertisers: Array<AdvertiserForSearch>;
}

/** An enumeration. */
export enum AllCampaignStatus {
  DEMO = 'DEMO',
  DRAFT = 'DRAFT',
  FINISHED = 'FINISHED',
  LOST = 'LOST',
  ONGOING = 'ONGOING',
  OUT_OF_COUPON = 'OUT_OF_COUPON',
  REVIEWING = 'REVIEWING',
  SUSPENDED = 'SUSPENDED',
  UPCOMING = 'UPCOMING',
}

export interface AllCampaignsReportSummary {
  chartData: Array<CampaignChartReport>;
  currency: Scalars['String']['output'];
  summary?: Maybe<CampaignReportSummary>;
  total: Scalars['Int']['output'];
}

export enum AllCampaignsSearchJobsOrderBy {
  CREATED_DATE = 'CREATED_DATE',
  HIGH_REWARD = 'HIGH_REWARD',
  ORDER_NUMBER = 'ORDER_NUMBER',
  POPULAR = 'POPULAR',
}

export interface AllInfluencerV4 {
  influencers: Array<InfluencerV4>;
  totalCount: Scalars['Int']['output'];
}

export interface AllStaffDetail {
  countryId: Scalars['String']['output'];
  email: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
}

export interface AllTiktokAdReportSummary {
  adCampaignId?: Maybe<Scalars['String']['output']>;
  adCampaignName?: Maybe<Scalars['String']['output']>;
  adGroupId?: Maybe<Scalars['String']['output']>;
  adGroupName?: Maybe<Scalars['String']['output']>;
  chartData: Array<TikTokAdChartReport>;
  currency: Scalars['String']['output'];
  summary?: Maybe<TikTokAdReportSummary>;
  total: Scalars['Int']['output'];
}

export interface AllUserDetail {
  advertisersCount: Scalars['Int']['output'];
  country: CountryName;
  email: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  role: UserRoles;
}

export interface AllowEngagementInfluencerProposalList {
  ok: Scalars['Boolean']['output'];
}

export interface AllowEngagementInfluencerProposalListInput {
  campaignId: Scalars['Int']['input'];
  influencerIds: Array<Scalars['Int']['input']>;
}

export interface AllowInfluencersUserProposal {
  ok: Scalars['Boolean']['output'];
}

export interface AllowInfluencersUserProposalInput {
  influencerIds: Array<Scalars['Int']['input']>;
}

export interface AnalyticSubscriptionAvailableFeature {
  compareEnabled: Scalars['Boolean']['output'];
  dashboardEnabled: Scalars['Boolean']['output'];
  hashtagsEnabled: Scalars['Boolean']['output'];
  interactionEnabled: Scalars['Boolean']['output'];
  trendEnabled: Scalars['Boolean']['output'];
}

export interface AnalyticSubscriptionAvailableFeatures {
  compareEnabled: Scalars['Boolean']['output'];
  dashboardEnabled: Scalars['Boolean']['output'];
  hashtagsEnabled: Scalars['Boolean']['output'];
  interactionEnabled: Scalars['Boolean']['output'];
}

export interface AnalyticSubscriptionCapabilities {
  maxCompareAccounts: Scalars['Int']['output'];
  maxHashtags: Scalars['Int']['output'];
}

export interface AnalyticSubscriptionCapability {
  maxCompareAccounts: Scalars['Int']['output'];
  maxHashtags: Scalars['Int']['output'];
}

/** An enumeration. */
export enum AnalyticsAdvertiserPlanFilter {
  BASIC = 'BASIC',
  ENTERPRISE = 'ENTERPRISE',
  FREE = 'FREE',
  TRIAL = 'TRIAL',
}

export enum AnalyticsAuthSocialAccountType {
  FACEBOOK_PAGE = 'FACEBOOK_PAGE',
  INSTAGRAM = 'INSTAGRAM',
  TWITTER = 'TWITTER',
  YOUTUBE = 'YOUTUBE',
}

export enum AnalyticsRedirectType {
  ANALYTICS_CONNECT = 'ANALYTICS_CONNECT',
  ANALYTICS_LIST_CONNECT = 'ANALYTICS_LIST_CONNECT',
  ANALYTICS_SIGNIN = 'ANALYTICS_SIGNIN',
}

export interface AnalyticsSelectSubscriptionPlan {
  ok: Scalars['Boolean']['output'];
}

export interface AnalyticsSelectSubscriptionPlanInput {
  /** advertiser's system id */
  advertiserId: Scalars['Int']['input'];
  /** the selected plan */
  plan: AnalyticsSubscriptionPlanType;
}

/**  Stores a redirect URL that FE uses to communicate with social network APIs */
export interface AnalyticsSocialAuthRedirectUrlPayload {
  redirectUri: Scalars['String']['output'];
}

export interface AnalyticsSubscriptionPlan {
  availableFeatures: AnalyticSubscriptionAvailableFeatures;
  capabilities: AnalyticSubscriptionCapabilities;
  /** subscription plan ID */
  id: Scalars['Int']['output'];
  type: AnalyticsSubscriptionPlanType;
}

export interface AnalyticsSubscriptionPlanDetails {
  availableFeatures: AnalyticSubscriptionAvailableFeature;
  capabilities: AnalyticSubscriptionCapability;
  id: Scalars['Int']['output'];
  type: AnalyticsSubscriptionPlanType;
}

export interface AnalyticsSubscriptionPlanName {
  name?: Maybe<Scalars['String']['output']>;
  type: AnalyticsSubscriptionPlanType;
}

export enum AnalyticsSubscriptionPlanType {
  BASIC = 'BASIC',
  BASIC_PLUS = 'BASIC_PLUS',
  BASIC_PLUS_PLUS = 'BASIC_PLUS_PLUS',
  BASIC_V2 = 'BASIC_V2',
  ENTERPRISE = 'ENTERPRISE',
  ENTERPRISE_PLUS = 'ENTERPRISE_PLUS',
  ENTERPRISE_PLUS_PLUS = 'ENTERPRISE_PLUS_PLUS',
  ENTERPRISE_V2 = 'ENTERPRISE_V2',
  FREE = 'FREE',
  TRIAL = 'TRIAL',
}

export interface AnyCreatorCategory {
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
}

export interface AnyXAccountInfoPayload {
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
}

export enum AnyXAuthSocialAccountType {
  APPLE = 'APPLE',
  FACEBOOK = 'FACEBOOK',
  INSTAGRAM = 'INSTAGRAM',
  TIKTOK = 'TIKTOK',
  TWITTER = 'TWITTER',
  YOUTUBE = 'YOUTUBE',
}

export interface AnyXCheckConnectedSocialAccountsForLinkBioPayload {
  facebooks: Array<AnyXConnectedFacebookAccountAndPageForLinkBio>;
  instagramAccounts: Array<AnyXConnectedSocialAccountForLinkBio>;
  tiktokAccounts: Array<AnyXConnectedSocialAccountForLinkBio>;
  twitterAccounts: Array<AnyXConnectedSocialAccountForLinkBio>;
  youtubeAccounts: Array<AnyXConnectedSocialAccountForLinkBio>;
}

export interface AnyXCheckConnectedSocialAccountsPayload {
  facebookPages: Array<AnyXConnectedFacebookPage>;
  facebooks: Array<AnyXConnectedFacebookAccount>;
  instagramAccounts: Array<AnyXConnectedInstagramAccount>;
  tiktokAccounts: Array<AnyXConnectedTikTokAccount>;
  twitterAccounts: Array<AnyXConnectedSocialAccount>;
  youtubeAccounts: Array<AnyXConnectedYoutubeAccount>;
}

/**  ANY-X */
export interface AnyXCheckEmailInput {
  email: Scalars['String']['input'];
}

export interface AnyXCheckEmailPayload {
  ok: Scalars['Boolean']['output'];
}

export interface AnyXConnectedChildYoutubeAccount {
  avatar?: Maybe<Scalars['String']['output']>;
  channelId: Scalars['String']['output'];
  name: Scalars['String']['output'];
}

export interface AnyXConnectedFacebookAccount {
  avatar?: Maybe<Scalars['String']['output']>;
  followersCount?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  idForDisconnect: Scalars['String']['output'];
  name: Scalars['String']['output'];
  needReconnect: Scalars['Boolean']['output'];
  url?: Maybe<Scalars['String']['output']>;
}

export interface AnyXConnectedFacebookAccountAndPageForLinkBio {
  avatar: Scalars['String']['output'];
  followersCount?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  needReconnect: Scalars['Boolean']['output'];
  post?: Maybe<FacebookPostForLinkBio>;
  socialAccountType: SocialAccountType;
  url: Scalars['String']['output'];
}

export interface AnyXConnectedFacebookPage {
  avatar?: Maybe<Scalars['String']['output']>;
  followersCount?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  idForDisconnect: Scalars['String']['output'];
  name: Scalars['String']['output'];
  needReconnect: Scalars['Boolean']['output'];
  url?: Maybe<Scalars['String']['output']>;
}

export interface AnyXConnectedInstagramAccount {
  avatar?: Maybe<Scalars['String']['output']>;
  followersCount?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  idForDisconnect: Scalars['String']['output'];
  isBusiness?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  needReconnect: Scalars['Boolean']['output'];
  url?: Maybe<Scalars['String']['output']>;
}

export interface AnyXConnectedSocialAccount {
  avatar?: Maybe<Scalars['String']['output']>;
  followersCount?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  idForDisconnect: Scalars['String']['output'];
  name: Scalars['String']['output'];
  url?: Maybe<Scalars['String']['output']>;
}

export interface AnyXConnectedSocialAccountForLinkBio {
  avatar: Scalars['String']['output'];
  followersCount?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  needReconnect: Scalars['Boolean']['output'];
  url: Scalars['String']['output'];
}

export interface AnyXConnectedTikTokAccount {
  avatar?: Maybe<Scalars['String']['output']>;
  followersCount?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  idForDisconnect: Scalars['String']['output'];
  name: Scalars['String']['output'];
  needReconnect: Scalars['Boolean']['output'];
  url?: Maybe<Scalars['String']['output']>;
}

export interface AnyXConnectedYoutubeAccount {
  avatar?: Maybe<Scalars['String']['output']>;
  childAccounts: Array<AnyXConnectedChildYoutubeAccount>;
  followersCount?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  idForDisconnect: Scalars['String']['output'];
  name: Scalars['String']['output'];
  url?: Maybe<Scalars['String']['output']>;
}

/** An enumeration. */
export enum AnyXEngagementCampaignStatus {
  DRAFTING = 'DRAFTING',
  EFFECTIVE = 'EFFECTIVE',
  INEFFECTIVE = 'INEFFECTIVE',
  POSTING = 'POSTING',
  REVIEWING = 'REVIEWING',
  WARNING_EFFECTIVE = 'WARNING_EFFECTIVE',
  WARNING_REVIEWING = 'WARNING_REVIEWING',
}

/** An enumeration. */
export enum AnyXMarketplaceCampaignStatus {
  APPROVED = 'APPROVED',
  ASSESSING = 'ASSESSING',
  EFFECTIVE = 'EFFECTIVE',
  INEFFECTIVE = 'INEFFECTIVE',
  OUT_OF_COUPON = 'OUT_OF_COUPON',
  POSTING = 'POSTING',
  REVIEWING = 'REVIEWING',
  SHIPPED = 'SHIPPED',
  WAITING_FOR_SHIPMENT = 'WAITING_FOR_SHIPMENT',
  WARNING_EFFECTIVE = 'WARNING_EFFECTIVE',
}

export interface AnyXSignIn {
  token: Scalars['String']['output'];
}

export interface AnyXSignUp {
  email?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  uuid: Scalars['ID']['output'];
}

export interface AnyXSocialAuthConnectForLinkBioInput {
  callbackUrl?: InputMaybe<Scalars['String']['input']>;
  provider: AnyXAuthSocialAccountType;
  response: Scalars['String']['input'];
}

export interface AnyXSocialAuthConnectForLinkBioPayload {
  connectedAccounts: Array<ConnectedAccountForLinkBio>;
}

export interface AnyXSocialAuthConnectInput {
  callbackUrl?: InputMaybe<Scalars['String']['input']>;
  provider: AnyXAuthSocialAccountType;
  response: Scalars['String']['input'];
}

export interface AnyXSocialAuthConnectPayload {
  ok: Scalars['Boolean']['output'];
}

export interface AnyXSocialAuthDisconnectInput {
  socialAccountId: Scalars['ID']['input'];
  socialAccountType: SocialAccountType;
}

export interface AnyXSocialAuthDisconnectPayload {
  ok: Scalars['Boolean']['output'];
}

export interface AnyXSocialAuthReconnectForLinkBioInput {
  callbackUrl?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  response: Scalars['String']['input'];
  socialAccountType: SocialAccountType;
}

export interface AnyXSocialAuthReconnectForLinkBioPayload {
  accounts: Array<ReconnectedAccountForLinkBio>;
}

export interface AnyXSocialAuthReconnectTTCMInput {
  id: Scalars['ID']['input'];
  response: Scalars['String']['input'];
}

export interface AnyXSocialAuthReconnectTTCMPayload {
  ok: Scalars['Boolean']['output'];
}

export interface AnyXSocialAuthReconnectTikTokInput {
  callbackUrl?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  response: Scalars['String']['input'];
}

export interface AnyXSocialAuthReconnectTikTokPayload {
  ok: Scalars['Boolean']['output'];
}

export interface AnyXSocialAuthSignInInput {
  callbackUrl?: InputMaybe<Scalars['String']['input']>;
  provider: AnyXAuthSocialAccountType;
  response: Scalars['String']['input'];
}

export interface AnyXSocialAuthSignInInstagramLineInput {
  fbOauth?: InputMaybe<FacebookOauthInput>;
  lineIDToken: Scalars['String']['input'];
}

export interface AnyXSocialAuthSignInInstagramLinePayload {
  ok: Scalars['Boolean']['output'];
}

export interface AnyXSocialAuthSignInOrSignUpInput {
  callbackUrl?: InputMaybe<Scalars['String']['input']>;
  provider: AnyXAuthSocialAccountType;
  response: Scalars['String']['input'];
}

export interface AnyXSocialAuthSignInOrSignUpPayload {
  hasIgAccount: Scalars['Boolean']['output'];
  signIn?: Maybe<AnyXSignIn>;
  signUp?: Maybe<AnyXSignUp>;
}

export interface AnyXSocialAuthSignInPayload {
  token: Scalars['String']['output'];
}

export interface AnyXSocialAuthSignUpInput {
  categoryIds: Array<Scalars['Int']['input']>;
  countryId: Scalars['String']['input'];
  dateOfBirth: Scalars['String']['input'];
  email: Scalars['String']['input'];
  gender: Genders;
  name: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  provider: AnyXAuthSocialAccountType;
  regionId?: InputMaybe<Scalars['Int']['input']>;
  uuid: Scalars['ID']['input'];
}

export interface AnyXSocialAuthSignUpPartnerInput {
  categoryIds: Array<Scalars['Int']['input']>;
  countryId: Scalars['String']['input'];
  dateOfBirth: Scalars['String']['input'];
  email: Scalars['String']['input'];
  gender: Genders;
  name: Scalars['String']['input'];
  partnerUserOwnerHash: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  provider: AnyXAuthSocialAccountType;
  regionId: Scalars['Int']['input'];
  uuid: Scalars['ID']['input'];
}

export interface AnyXSocialAuthSignUpPartnerPayload {
  token: Scalars['String']['output'];
}

export interface AnyXSocialAuthSignUpPayload {
  token: Scalars['String']['output'];
}

export interface AnyXSocialAuthSignUpTalentInput {
  categoryIds: Array<Scalars['Int']['input']>;
  countryId: Scalars['String']['input'];
  dateOfBirth: Scalars['String']['input'];
  email: Scalars['String']['input'];
  gender: Genders;
  name: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  provider: AnyXAuthSocialAccountType;
  regionId: Scalars['Int']['input'];
  talentAgencyOwnerHash: Scalars['String']['input'];
  uuid: Scalars['ID']['input'];
}

export interface AnyXSocialAuthSignUpTalentPayload {
  token: Scalars['String']['output'];
}

/** An enumeration. */
export enum AppName {
  ANYCREATOR = 'ANYCREATOR',
  ANYTAG = 'ANYTAG',
}

export enum AppType {
  ENGAGEMENT_CAMPAIGN = 'ENGAGEMENT_CAMPAIGN',
  MARKETPLACE_CAMPAIGN = 'MARKETPLACE_CAMPAIGN',
}

export interface ApplicantInfluencer {
  age?: Maybe<Scalars['Int']['output']>;
  appliedStatus: MarketplaceCampaignAppliedStatus;
  avatar?: Maybe<Scalars['String']['output']>;
  categories: Array<CategoryName>;
  country: CountryNameForInfluencer;
  email?: Maybe<Scalars['Boolean']['output']>;
  engagement: InfluencerEngagement;
  facebook?: Maybe<SocialAccountV2>;
  followersCount?: Maybe<Scalars['Int']['output']>;
  gender: Genders;
  id: Scalars['Int']['output'];
  instagram?: Maybe<SocialAccountV2>;
  marketplaceJoinedCount?: Maybe<Scalars['Int']['output']>;
  marketplacePostedCount?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  status: SocialAccountStatus;
  tiktok?: Maybe<SocialAccountV2>;
  twitter?: Maybe<SocialAccountV2>;
  youtube?: Maybe<SocialAccountV2>;
}

export interface ApproveApplicantInfluencers {
  ok: Scalars['Boolean']['output'];
}

export interface ApproveApplicantInfluencersInput {
  influencerIds: Array<Scalars['Int']['input']>;
  marketplaceCampaignId: Scalars['Int']['input'];
}

export interface ApproveAutoManagedPayout {
  ok: Scalars['Boolean']['output'];
}

export interface ApproveAutoManagedPayoutInput {
  coveragePeriods: Array<AutoManagedPayoutPeriodInput>;
  id: Scalars['Int']['input'];
}

export interface ApproveCmsInvoice {
  ok: Scalars['Boolean']['output'];
}

export interface ApproveCmsInvoiceInput {
  month: Scalars['Date']['input'];
}

export interface ApproveMarketplace {
  ok: Scalars['Boolean']['output'];
}

export interface ApproveMarketplaceAffiliatePayout {
  ok: Scalars['Boolean']['output'];
}

export interface ApproveMarketplaceAffiliatePayoutInput {
  coveragePeriods: Array<MarketplaceAffiliatePayoutPeriodInput>;
  marketplaceId: Scalars['Int']['input'];
}

export interface ApproveMarketplaceDraftPost {
  ok: Scalars['Boolean']['output'];
}

export interface ApproveMarketplaceDraftPostInput {
  draftId: Scalars['Int']['input'];
}

export interface ApproveMarketplaceInput {
  id: Scalars['Int']['input'];
}

export interface ApproveTikTokSpecialCampaign {
  ok: Scalars['Boolean']['output'];
}

export interface ApproveTikTokSpecialCampaignInput {
  campaignId: Scalars['Int']['input'];
}

export interface AssignAdvertiserForStaffs {
  ok: Scalars['Boolean']['output'];
}

export interface AssignAdvertiserForStaffsInput {
  advertiserId: Scalars['Int']['input'];
  staffUserIds: Array<Scalars['Int']['input']>;
}

export interface AssignChatPICInput {
  chatId: Scalars['String']['input'];
}

export interface AssignChatPICPayload {
  ok: Scalars['Boolean']['output'];
}

export interface AssociateCompany {
  companyType: AssociateCompanyType;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  usersCount: Scalars['Int']['output'];
}

/** An enumeration. */
export enum AssociateCompanyType {
  ADVERTISER = 'ADVERTISER',
  AGENCY = 'AGENCY',
}

export interface AudienceBreakdownAgeGenderRates {
  ageGroups: Array<AudienceBreakdownAgeGroup>;
  femaleRates: Array<Scalars['Float']['output']>;
  maleRates: Array<Scalars['Float']['output']>;
}

export interface AudienceBreakdownAgeGroup {
  end: Scalars['Int']['output'];
  start: Scalars['Int']['output'];
}

export interface AudienceBreakdownAgeRates {
  ageGroups: Array<AudienceBreakdownAgeGroup>;
  rates: Array<Scalars['Float']['output']>;
}

export interface AudienceBreakdownAreaRate {
  countryId: Scalars['String']['output'];
  rate: Scalars['Float']['output'];
}

export interface AudienceBreakdownGenderRates {
  femaleRate: Scalars['Float']['output'];
  maleRate: Scalars['Float']['output'];
}

export interface Auth0ToLocalJWT {
  email: Scalars['String']['output'];
  hash?: Maybe<Scalars['String']['output']>;
  role: UserRoles;
  token: Scalars['String']['output'];
  userId: Scalars['Int']['output'];
}

export interface Auth0ToLocalJWTInput {
  idToken: Scalars['String']['input'];
}

export enum AuthSocialAccountType {
  FACEBOOK = 'FACEBOOK',
  TIKTOK = 'TIKTOK',
  TTCM = 'TTCM',
  TWITTER = 'TWITTER',
  YOUTUBE = 'YOUTUBE',
}

export interface AuthTiktokBusinessAccount {
  ok: Scalars['Boolean']['output'];
}

export interface AuthTiktokBusinessAccountInput {
  authCode: Scalars['String']['input'];
}

export enum AutoManagedAffiliateCampaignStatus {
  ACTIVE = 'ACTIVE',
  FINISHED = 'FINISHED',
  INACTIVE = 'INACTIVE',
}

export interface AutoManagedAffiliateCommissionRateInput {
  commissionRate: Scalars['Float']['input'];
  influencerId: Scalars['Int']['input'];
}

export interface AutoManagedCampaignCountry {
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
}

export interface AutoManagedCampaignForInfluencerSearchJobPayload {
  campaignUrl?: Maybe<Scalars['String']['output']>;
  commissionRate?: Maybe<Scalars['Float']['output']>;
  createdDate: Scalars['String']['output'];
  currencyId?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isJoined: Scalars['Boolean']['output'];
  minimumPaymentAmount: Scalars['Float']['output'];
  requirement?: Maybe<Scalars['String']['output']>;
  serviceInfo?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['String']['output']>;
  status: AutoManagedAffiliateCampaignStatus;
  thumbNail?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  type: AutoManagedCampaignType;
}

export interface AutoManagedCampaignForInfluencerYourJobPayload {
  campaignUrl?: Maybe<Scalars['String']['output']>;
  commissionRate?: Maybe<Scalars['Float']['output']>;
  createdDate: Scalars['String']['output'];
  currencyId?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isJoined: Scalars['Boolean']['output'];
  minimumPaymentAmount: Scalars['Float']['output'];
  requirement?: Maybe<Scalars['String']['output']>;
  serviceInfo?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['String']['output']>;
  status: AutoManagedAffiliateCampaignStatus;
  thumbNail?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  trackingUrl: Scalars['String']['output'];
  type: AutoManagedCampaignType;
}

export interface AutoManagedCampaignInfoPayload {
  autoManagedCampaignType: AutoManagedCampaignType;
  category: Category;
  commissionRate: Scalars['Float']['output'];
  country: AutoManagedCampaignCountry;
  endDate?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  landingPageUrl?: Maybe<Scalars['String']['output']>;
  numberOfJoinedCreators: Scalars['Int']['output'];
  orders: Scalars['Int']['output'];
  price: AutoManagedCampaignMoney;
  revenue: AutoManagedCampaignMoney;
  sales: AutoManagedCampaignMoney;
  sellerName: Scalars['String']['output'];
  startDate?: Maybe<Scalars['String']['output']>;
  status: AutoManagedCampaignStatus;
  thumbnailUrl?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
}

export interface AutoManagedCampaignMoney {
  amount: Scalars['Float']['output'];
  currencyId: Scalars['String']['output'];
}

export interface AutoManagedCampaignPayload {
  autoManagedCampaignType: AutoManagedCampaignType;
  campaignThumbnailUrl?: Maybe<Scalars['String']['output']>;
  campaignUrl?: Maybe<Scalars['String']['output']>;
  category: Category;
  commissionRate?: Maybe<Scalars['Float']['output']>;
  country: Country;
  createdDate: Scalars['String']['output'];
  currency: Scalars['String']['output'];
  endDate?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  influencerCommissionRate?: Maybe<Scalars['Float']['output']>;
  landingPageUrl?: Maybe<Scalars['String']['output']>;
  marginRate?: Maybe<Scalars['Float']['output']>;
  requirement?: Maybe<Scalars['String']['output']>;
  sellerName?: Maybe<Scalars['String']['output']>;
  serviceInfo?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['String']['output']>;
  status: AutoManagedCampaignStatus;
  title: Scalars['String']['output'];
}

export enum AutoManagedCampaignStatus {
  ACTIVE = 'ACTIVE',
  FINISHED = 'FINISHED',
  INACTIVE = 'INACTIVE',
}

export enum AutoManagedCampaignType {
  LAZADA = 'LAZADA',
  SHOPEE = 'SHOPEE',
}

export interface AutoManagedCommissionInfluencer {
  avatar: Scalars['String']['output'];
  currency: Scalars['String']['output'];
  endCoverageDate: Scalars['Date']['output'];
  fixedDate: Scalars['Date']['output'];
  influencerId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  postId: Scalars['Int']['output'];
  revenue: Scalars['Float']['output'];
  startCoverageDate: Scalars['Date']['output'];
  status: AutoManagedCommissionStatus;
}

/** An enumeration. */
export enum AutoManagedCommissionStatus {
  APPROVED = 'APPROVED',
  UNAPPROVED = 'UNAPPROVED',
}

export interface AutoManagedInfluencerInfo {
  avatar: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
}

export interface AutoManagedJoinedInfluencer {
  influencer: AutoManagedInfluencerInfo;
  joinedDate: Scalars['Date']['output'];
  promotionMethods: Array<CampaignPromotionMethod>;
}

export interface AutoManagedJoinedMethodsInput {
  campaignId: Scalars['ID']['input'];
}

export interface AutoManagedJoinedMethodsPayload {
  emailNewsLetters: Array<JoinedPromotionMethodEmailNewsLetterPayload>;
  facebookAccount?: Maybe<JoinedPromotionMethodSocialAccountPayload>;
  facebookPages: Array<JoinedPromotionMethodSocialAccountPayload>;
  instagramAccounts: Array<JoinedPromotionMethodSocialAccountPayload>;
  mobileApps: Array<JoinedPromotionMethodMobileAppPayload>;
  offlines: Array<JoinedPromotionMethodOfflinePayload>;
  podCasts: Array<JoinedPromotionMethodPodCastPayload>;
  tiktokAccounts: Array<JoinedPromotionMethodSocialAccountPayload>;
  twitterAccounts: Array<JoinedPromotionMethodSocialAccountPayload>;
  websites: Array<JoinedPromotionMethodWebsitePayload>;
  youtubeAccounts: Array<JoinedPromotionMethodSocialAccountPayload>;
}

export interface AutoManagedJoinedMethodsPayloadV2 {
  methods: Array<CampaignPromotionMethod>;
}

export interface AutoManagedPayoutPeriodInput {
  fixedDate: Scalars['Date']['input'];
  postId: Scalars['Int']['input'];
}

export interface AutoManagedReportByDate {
  clicks: Scalars['Int']['output'];
  conversions: Scalars['Int']['output'];
  cost: Scalars['Float']['output'];
  cvr?: Maybe<Scalars['Float']['output']>;
  date: Scalars['Date']['output'];
  grossProfit: Scalars['Float']['output'];
  influencerCost: Scalars['Float']['output'];
  sales: Scalars['Float']['output'];
}

export interface AutoManagedReportByInfluencer {
  clicks: Scalars['Int']['output'];
  commissionRate: Scalars['Float']['output'];
  conversions: Scalars['Int']['output'];
  cost: Scalars['Float']['output'];
  cvr?: Maybe<Scalars['Float']['output']>;
  influencerCost: Scalars['Float']['output'];
  influencerInfo: CampaignInfluencerInfo;
  promotionMethods: Array<CampaignPromotionMethod>;
  sales: Scalars['Float']['output'];
}

export interface AutoManagedReportForInfluencer {
  clicks: Scalars['Int']['output'];
  commission: Scalars['Float']['output'];
  conversions: Scalars['Int']['output'];
  date: Scalars['Date']['output'];
}

export interface AutoManagedReportSummary {
  clicks: Scalars['Int']['output'];
  conversions: Scalars['Int']['output'];
  cost: Scalars['Float']['output'];
  cvr?: Maybe<Scalars['Float']['output']>;
  grossProfit: Scalars['Float']['output'];
  influencerCost: Scalars['Float']['output'];
  joinedCount: Scalars['Int']['output'];
  sales: Scalars['Float']['output'];
}

export interface AutoManagedReportSummaryForInfluencer {
  clicks: Scalars['Int']['output'];
  commission: Scalars['Float']['output'];
  conversions: Scalars['Int']['output'];
  currency: Scalars['String']['output'];
}

export interface AvailableInfluencer {
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  selected: Scalars['Boolean']['output'];
}

export interface AvailableInfluencersForStaffPayload {
  influencers: Array<AvailableInfluencer>;
}

export interface Bank {
  hasBranches: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
}

export interface BankBranch {
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
}

export interface BankBranchForInfluencer {
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
}

export interface BankForInfluencer {
  hasBranches: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
}

export interface BasicAccountAnalyticsPayload {
  brandAccountId: Scalars['Int']['output'];
  brandAccountName: Scalars['String']['output'];
  country: Scalars['String']['output'];
  socialMediaAccounts: AccountAnalyticsSocialMediaAccount;
  subscriptionPlan: AnalyticsSubscriptionPlanDetails;
}

/** An enumeration. */
export enum BcaRequestStatus {
  APPROVED = 'APPROVED',
  REQUESTED = 'REQUESTED',
  REVOKED = 'REVOKED',
}

export interface BillingInformation {
  cardBrand?: Maybe<Scalars['String']['output']>;
  cardCountry?: Maybe<Scalars['String']['output']>;
  cardExpMonth?: Maybe<Scalars['Int']['output']>;
  cardExpYear?: Maybe<Scalars['Int']['output']>;
  cardLast4?: Maybe<Scalars['String']['output']>;
}

export interface BioAnalyticsCity {
  city: Scalars['String']['output'];
  count: Scalars['Float']['output'];
}

export interface BioAnalyticsCountry {
  count: Scalars['Float']['output'];
  country: Scalars['String']['output'];
}

export interface BioAnalyticsOtherLinkClicks {
  clicks: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  linkName: Scalars['String']['output'];
}

export interface BioAnalyticsReferral {
  count: Scalars['Int']['output'];
  url: Scalars['String']['output'];
}

export interface BioAnalyticsSocialMediaClicks {
  clicks: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  linkName: Scalars['String']['output'];
  socialMediaType: BioSocialMediaType;
}

export interface BioAnalyticsTotalClicks {
  clicks: Scalars['Int']['output'];
  sectionType: BioSectionType;
}

export enum BioButtonStyle {
  BLACK_FULL_ROUNDED_EDGE_BUTTON = 'BLACK_FULL_ROUNDED_EDGE_BUTTON',
  BLACK_ROUNDED_EDGE_BUTTON = 'BLACK_ROUNDED_EDGE_BUTTON',
  BLACK_SHARP_EDGE_BUTTON = 'BLACK_SHARP_EDGE_BUTTON',
  WHITE_FULL_ROUNDED_EDGE_BUTTON = 'WHITE_FULL_ROUNDED_EDGE_BUTTON',
  WHITE_ROUNDED_EDGE_BUTTON = 'WHITE_ROUNDED_EDGE_BUTTON',
  WHITE_SHARP_EDGE_BUTTON = 'WHITE_SHARP_EDGE_BUTTON',
}

export enum BioFontStyle {
  KAISEI_DECOL = 'KAISEI_DECOL',
  KOSUGI = 'KOSUGI',
  M_PLUS_1 = 'M_PLUS_1',
  NOTO_SANS_JP = 'NOTO_SANS_JP',
  NOTO_SERIF_JP = 'NOTO_SERIF_JP',
  POTTA_ONE = 'POTTA_ONE',
  SAWARABI_MINCHO = 'SAWARABI_MINCHO',
  SHIPPORI_MINCHO = 'SHIPPORI_MINCHO',
  TRAIN_ONE = 'TRAIN_ONE',
}

export interface BioImage {
  image: Scalars['String']['output'];
  linkUrl?: Maybe<Scalars['String']['output']>;
}

export interface BioImageInput {
  image: Scalars['String']['input'];
  linkUrl?: InputMaybe<Scalars['String']['input']>;
}

export interface BioInfluencerProfile {
  avatar?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
}

export enum BioLinkIcon {
  BOOK = 'BOOK',
  CALENDAR = 'CALENDAR',
  CART = 'CART',
  CHAT = 'CHAT',
  CLOUD = 'CLOUD',
  COFFEE = 'COFFEE',
  DOCUMENT = 'DOCUMENT',
  ENVELOPE = 'ENVELOPE',
  HEART = 'HEART',
  HOUSE = 'HOUSE',
  KEY = 'KEY',
  LOCATION = 'LOCATION',
  MOON = 'MOON',
  MUSIC_NOTE = 'MUSIC_NOTE',
  PHONE = 'PHONE',
  SMILE_EMOJI = 'SMILE_EMOJI',
  STAR = 'STAR',
  SUN = 'SUN',
  USER = 'USER',
  VIDEO_CAMERA = 'VIDEO_CAMERA',
}

export interface BioLinkInput {
  linkIcon?: InputMaybe<BioLinkIcon>;
  linkName: Scalars['String']['input'];
  linkUrl: Scalars['String']['input'];
}

export interface BioProfileInput {
  avatar?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
}

export interface BioSection {
  id: Scalars['Int']['output'];
  image?: Maybe<BioImage>;
  isPublic: Scalars['Boolean']['output'];
  link?: Maybe<BioUrlLink>;
  order: Scalars['Int']['output'];
  profile?: Maybe<BioInfluencerProfile>;
  sectionType: BioSectionType;
  socialMedia?: Maybe<BioSocialMedia>;
  text?: Maybe<BioText>;
}

export enum BioSectionType {
  IMAGE = 'IMAGE',
  LINK = 'LINK',
  PROFILE = 'PROFILE',
  SOCIAL_MEDIA = 'SOCIAL_MEDIA',
  TEXT = 'TEXT',
}

export interface BioSectionV2 {
  id: Scalars['Int']['output'];
  image?: Maybe<BioImage>;
  link?: Maybe<BioUrlLink>;
  order: Scalars['Int']['output'];
  sectionType: BioSectionType;
  socialMedia?: Maybe<BioSocialMedia>;
  text?: Maybe<BioText>;
}

export interface BioSectionsInput {
  id?: InputMaybe<Scalars['Int']['input']>;
  image?: InputMaybe<BioImageInput>;
  isPublic: Scalars['Boolean']['input'];
  link?: InputMaybe<BioLinkInput>;
  order: Scalars['Int']['input'];
  profile?: InputMaybe<BioProfileInput>;
  sectionType: BioSectionType;
  socialMedia?: InputMaybe<BioSocialMediaInput>;
  text?: InputMaybe<BioTextInput>;
}

export interface BioSectionsInputV2 {
  id?: InputMaybe<Scalars['Int']['input']>;
  image?: InputMaybe<BioImageInput>;
  link?: InputMaybe<BioLinkInput>;
  order: Scalars['Int']['input'];
  sectionType: BioSectionType;
  socialMedia?: InputMaybe<BioSocialMediaInput>;
  text?: InputMaybe<BioTextInput>;
}

export interface BioSocialMedia {
  linkName: Scalars['String']['output'];
  linkUrl: Scalars['String']['output'];
  socialAccountId?: Maybe<Scalars['String']['output']>;
  socialAccountType?: Maybe<SocialAccountType>;
  socialMedia: BioSocialMediaType;
}

export interface BioSocialMediaInput {
  linkName: Scalars['String']['input'];
  linkUrl: Scalars['String']['input'];
  socialAccountId?: InputMaybe<Scalars['String']['input']>;
  socialAccountType?: InputMaybe<SocialAccountType>;
  socialMedia: BioSocialMediaType;
}

export enum BioSocialMediaType {
  FACEBOOK = 'FACEBOOK',
  INSTAGRAM = 'INSTAGRAM',
  LINE = 'LINE',
  TIKTOK = 'TIKTOK',
  TWITTER = 'TWITTER',
  YOUTUBE = 'YOUTUBE',
}

export interface BioText {
  text: Scalars['String']['output'];
}

export interface BioTextInput {
  text: Scalars['String']['input'];
}

export enum BioTheme {
  THEME_1 = 'THEME_1',
  THEME_2 = 'THEME_2',
  THEME_3 = 'THEME_3',
  THEME_4 = 'THEME_4',
  THEME_5 = 'THEME_5',
  THEME_6 = 'THEME_6',
  THEME_7 = 'THEME_7',
  THEME_8 = 'THEME_8',
  THEME_9 = 'THEME_9',
  THEME_10 = 'THEME_10',
  THEME_11 = 'THEME_11',
}

export enum BioThemeV2 {
  THEME2_1 = 'THEME2_1',
  THEME2_2 = 'THEME2_2',
  THEME2_3 = 'THEME2_3',
  THEME2_4 = 'THEME2_4',
  THEME2_5 = 'THEME2_5',
  THEME2_6 = 'THEME2_6',
  THEME2_7 = 'THEME2_7',
  THEME2_8 = 'THEME2_8',
  THEME2_9 = 'THEME2_9',
  THEME2_10 = 'THEME2_10',
  THEME2_11 = 'THEME2_11',
  THEME2_12 = 'THEME2_12',
  THEME2_13 = 'THEME2_13',
  THEME2_14 = 'THEME2_14',
  THEME2_15 = 'THEME2_15',
  THEME2_16 = 'THEME2_16',
  THEME2_17 = 'THEME2_17',
}

export interface BioUrlLink {
  linkIcon?: Maybe<BioLinkIcon>;
  linkName: Scalars['String']['output'];
  linkUrl: Scalars['String']['output'];
}

export interface BoostTikTokAdPostInput {
  adAccountSystemId: Scalars['Int']['input'];
  adGroupId: Scalars['String']['input'];
  adName: Scalars['String']['input'];
  authCode: Scalars['String']['input'];
  callToAction: Scalars['Boolean']['input'];
  landingPageUrl?: InputMaybe<Scalars['String']['input']>;
  postSystemId: Scalars['String']['input'];
  tkSpecialCampaignId: Scalars['Int']['input'];
}

export interface BoostTiktokAdPost {
  ok: Scalars['Boolean']['output'];
}

export type BotTextMessageEvent = ChatEvent & {
  authorId: Scalars['Int']['output'];
  authorName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  text: Scalars['String']['output'];
  timestamp: Scalars['String']['output'];
};

export interface BrandAccount {
  avatar: Scalars['String']['output'];
  averageEngagementRate: Scalars['Float']['output'];
  /** mentioned brand account id */
  id: Scalars['Int']['output'];
  mentionPostsCount: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  postIds: Array<Scalars['Int']['output']>;
}

export interface BulkEngagementProposalSocialAccount {
  influencerId: Scalars['Int']['input'];
  socialAccountId: Scalars['Int']['input'];
  socialAccountMedia: EngagementProposalSocialMediaType;
}

export interface BulkPackageSocialAccount {
  influencerId: Scalars['Int']['input'];
  socialAccountId: Scalars['Int']['input'];
  socialAccountType: SocialAccountType;
}

export interface BulkUpdateEngagementInfluencersBudget {
  ok: Scalars['Boolean']['output'];
}

export interface BulkUpdateEngagementInfluencersBudgetInput {
  campaignId: Scalars['Int']['input'];
  influencerCost: Scalars['Float']['input'];
  influencerIds: Array<Scalars['Int']['input']>;
  netBudget: Scalars['Float']['input'];
  profit: Scalars['Float']['input'];
}

export interface BulkUpdateEngagementSocialAccountsBudget {
  ok: Scalars['Boolean']['output'];
}

export interface BulkUpdateEngagementSocialAccountsBudgetInput {
  campaignId: Scalars['Int']['input'];
  influencerCost: Scalars['Float']['input'];
  netBudget: Scalars['Float']['input'];
  profit: Scalars['Float']['input'];
  socialAccounts: Array<BulkEngagementProposalSocialAccount>;
}

export interface BulkUpdatePackageProposalAccountsBudget {
  ok: Scalars['Boolean']['output'];
}

export interface BulkUpdatePackageProposalAccountsBudgetInput {
  budget: Scalars['Float']['input'];
  influencerCost: Scalars['Float']['input'];
  packageId: Scalars['Int']['input'];
  profit: Scalars['Float']['input'];
  socialAccounts: Array<BulkPackageSocialAccount>;
}

export interface BulkUpdatePackageProposalInfluencersBudget {
  ok: Scalars['Boolean']['output'];
}

export interface BulkUpdatePackageProposalInfluencersBudgetInput {
  budget: Scalars['Float']['input'];
  influencerCost: Scalars['Float']['input'];
  influencerIds: Array<Scalars['Int']['input']>;
  packageId: Scalars['Int']['input'];
  profit: Scalars['Float']['input'];
}

export interface BulkUpdateUserProposalAccountsBudget {
  ok: Scalars['Boolean']['output'];
}

export interface BulkUpdateUserProposalAccountsBudgetInput {
  budget: Scalars['Float']['input'];
  influencerCost: Scalars['Float']['input'];
  profit: Scalars['Float']['input'];
  socialAccounts: Array<BulkUserProposalSocialAccount>;
}

export interface BulkUpdateUserProposalInfluencersBudget {
  ok: Scalars['Boolean']['output'];
}

export interface BulkUpdateUserProposalInfluencersBudgetInput {
  budget: Scalars['Float']['input'];
  influencerCost: Scalars['Float']['input'];
  influencerIds: Array<Scalars['Int']['input']>;
  profit: Scalars['Float']['input'];
}

export interface BulkUserProposalSocialAccount {
  influencerId: Scalars['Int']['input'];
  socialAccountId: Scalars['Int']['input'];
  socialAccountMedia: UserProposalSocialMediaType;
}

export interface ButtonMessageInput {
  cards: Array<CardInput>;
}

export interface ButtonMessagePayload {
  cards: Array<CardPayload>;
}

export enum CRMChatTypeFilter {
  ACTIVATED = 'ACTIVATED',
  ASSIGNED_TO_ME = 'ASSIGNED_TO_ME',
}

export interface CampaignCategoryName {
  categoryName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
}

export interface CampaignChartReport {
  averageViewDurationSeconds?: Maybe<Scalars['Int']['output']>;
  comment?: Maybe<Scalars['Int']['output']>;
  cost: Scalars['Float']['output'];
  date: Scalars['Date']['output'];
  engagement: Scalars['Float']['output'];
  like?: Maybe<Scalars['Int']['output']>;
  share?: Maybe<Scalars['Int']['output']>;
  view?: Maybe<Scalars['Int']['output']>;
}

export interface CampaignChartReportForAllTime {
  cost: Scalars['Float']['output'];
  engagement: Scalars['Float']['output'];
  midDate: Scalars['Date']['output'];
}

export interface CampaignCreator {
  id: Scalars['Int']['output'];
  role: UserRoles;
}

export interface CampaignCreatorWithName {
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  role: UserRoles;
}

export interface CampaignDailyStats {
  click?: Maybe<Scalars['Int']['output']>;
  comment: Scalars['Int']['output'];
  conversion?: Maybe<Scalars['Int']['output']>;
  cost: Scalars['Float']['output'];
  costsPerAction?: Maybe<Scalars['Float']['output']>;
  costsPerClick?: Maybe<Scalars['Float']['output']>;
  /** For Vietnam campaigns */
  costsPerComment?: Maybe<Scalars['Float']['output']>;
  costsPerEngagement?: Maybe<Scalars['Float']['output']>;
  costsPerFollower?: Maybe<Scalars['Float']['output']>;
  costsPerImpressions?: Maybe<Scalars['Float']['output']>;
  costsPerLike?: Maybe<Scalars['Float']['output']>;
  costsPerReach?: Maybe<Scalars['Float']['output']>;
  costsPerSaved?: Maybe<Scalars['Float']['output']>;
  costsPerShare?: Maybe<Scalars['Float']['output']>;
  costsPerView?: Maybe<Scalars['Float']['output']>;
  date: Scalars['Date']['output'];
  engagement: Scalars['Float']['output'];
  engagementRate?: Maybe<Scalars['Float']['output']>;
  followers: Scalars['Int']['output'];
  impressions: Scalars['Int']['output'];
  like: Scalars['Int']['output'];
  /** For admin or staff user, and EngagementCampaign */
  net?: Maybe<Scalars['Float']['output']>;
  /** For admin or staff user, and EngagementCampaign */
  profit?: Maybe<Scalars['Float']['output']>;
  /** For admin or staff user, and EngagementCampaign */
  profitRate?: Maybe<Scalars['Float']['output']>;
  reach: Scalars['Float']['output'];
  saved?: Maybe<Scalars['Int']['output']>;
  share: Scalars['Int']['output'];
  view: Scalars['Int']['output'];
}

export interface CampaignDetailReport {
  age35UpPercentage?: Maybe<Scalars['Float']['output']>;
  age1824Percentage?: Maybe<Scalars['Float']['output']>;
  age2534Percentage?: Maybe<Scalars['Float']['output']>;
  averageViewDurationMinutes?: Maybe<Scalars['Int']['output']>;
  averageViewDurationPercentage?: Maybe<Scalars['Float']['output']>;
  averageViewDurationSeconds?: Maybe<Scalars['Int']['output']>;
  back?: Maybe<Scalars['Int']['output']>;
  campaign: CampaignReportCampaignInfo;
  click?: Maybe<Scalars['Int']['output']>;
  comment: Scalars['Int']['output'];
  conversion?: Maybe<Scalars['Int']['output']>;
  cost: Scalars['Float']['output'];
  costsPerAction?: Maybe<Scalars['Float']['output']>;
  costsPerClick?: Maybe<Scalars['Float']['output']>;
  /** For Vietnam campaigns */
  costsPerComment?: Maybe<Scalars['Float']['output']>;
  costsPerEngagement?: Maybe<Scalars['Float']['output']>;
  costsPerFollower?: Maybe<Scalars['Float']['output']>;
  costsPerImpressions?: Maybe<Scalars['Float']['output']>;
  costsPerLike?: Maybe<Scalars['Float']['output']>;
  costsPerOrder?: Maybe<Scalars['Float']['output']>;
  costsPerReach?: Maybe<Scalars['Float']['output']>;
  costsPerSale?: Maybe<Scalars['Float']['output']>;
  costsPerSaleReferral?: Maybe<Scalars['Float']['output']>;
  costsPerSaleTune?: Maybe<Scalars['Float']['output']>;
  costsPerSaved?: Maybe<Scalars['Float']['output']>;
  costsPerShare?: Maybe<Scalars['Float']['output']>;
  costsPerView?: Maybe<Scalars['Float']['output']>;
  daily: Array<CampaignDailyStats>;
  dislikes?: Maybe<Scalars['Int']['output']>;
  engagement: Scalars['Float']['output'];
  /** Engagement Rate by Followers for IG, FB, FB Page, X, Threads */
  engagementRate?: Maybe<Scalars['Float']['output']>;
  /** Engagement Rate by Views for TikTok, YT, Douyin */
  engagementViewsRate?: Maybe<Scalars['Float']['output']>;
  exited?: Maybe<Scalars['Int']['output']>;
  femalePercentage?: Maybe<Scalars['Float']['output']>;
  followers: Scalars['Float']['output'];
  forward?: Maybe<Scalars['Int']['output']>;
  impressionRate?: Maybe<Scalars['Float']['output']>;
  impressions?: Maybe<Scalars['Int']['output']>;
  impressionsFromDiscovery?: Maybe<Scalars['Int']['output']>;
  impressionsFromHashtag?: Maybe<Scalars['Int']['output']>;
  impressionsFromHome?: Maybe<Scalars['Int']['output']>;
  impressionsFromOther?: Maybe<Scalars['Int']['output']>;
  impressionsFromProfile?: Maybe<Scalars['Int']['output']>;
  interaction?: Maybe<Scalars['Int']['output']>;
  like?: Maybe<Scalars['Int']['output']>;
  linkClicks?: Maybe<Scalars['Int']['output']>;
  malePercentage?: Maybe<Scalars['Float']['output']>;
  navigation?: Maybe<Scalars['Int']['output']>;
  /** For admin or staff user, and EngagementCampaign */
  net?: Maybe<Scalars['Float']['output']>;
  nextStory?: Maybe<Scalars['Int']['output']>;
  nonFollowerReach?: Maybe<Scalars['Int']['output']>;
  nonFollowerReachRate?: Maybe<Scalars['Float']['output']>;
  orders?: Maybe<Scalars['Int']['output']>;
  profileActivity?: Maybe<Scalars['Int']['output']>;
  /** For admin or staff user, and EngagementCampaign */
  profit?: Maybe<Scalars['Float']['output']>;
  /** For admin or staff user, and EngagementCampaign */
  profitRate?: Maybe<Scalars['Float']['output']>;
  reach?: Maybe<Scalars['Float']['output']>;
  reachRate?: Maybe<Scalars['Float']['output']>;
  /** @deprecated please use sales_tune or sales_referral */
  sales?: Maybe<Scalars['Float']['output']>;
  salesReferral?: Maybe<Scalars['Float']['output']>;
  salesTune?: Maybe<Scalars['Float']['output']>;
  saved?: Maybe<Scalars['Int']['output']>;
  share?: Maybe<Scalars['Int']['output']>;
  stickerTaps?: Maybe<Scalars['Int']['output']>;
  view?: Maybe<Scalars['Float']['output']>;
}

export interface CampaignFilter {
  id: Scalars['Int']['input'];
  type: CampaignType;
}

export interface CampaignForInfluencerForYourJob {
  autoManaged?: Maybe<NewAutoManagedCampaignForInfluencerForYourJob>;
  createdDate: Scalars['Date']['output'];
  engagement?: Maybe<NewEngagementCampaignForInfluencerForYourJob>;
  marketplace?: Maybe<NewMarketplaceCampaignForInfluencerForYourJob>;
  tiktokSpecial?: Maybe<NewTiktokSpecialCampaignForInfluencerForYourJob>;
  type: CampaignType;
}

export interface CampaignForInfluencerForYourJobCounts {
  finishedCampaignCount: Scalars['Int']['output'];
  progressCampaignCount: Scalars['Int']['output'];
}

export interface CampaignForPostFilter {
  campaigns: Array<CampaignForSearch>;
  includedCampaigns?: Maybe<Array<CampaignForSearch>>;
}

export interface CampaignForSearch {
  endDate?: Maybe<Scalars['Date']['output']>;
  id: Scalars['Int']['output'];
  startDate?: Maybe<Scalars['Date']['output']>;
  status: CampaignStatusForReport;
  title: Scalars['String']['output'];
  type: CampaignType;
}

export interface CampaignGridReport {
  /** For all campaign SNSs */
  engagementRate: PostsMetricsGridReport;
  reachRate?: Maybe<PostsMetricsGridReport>;
  sales?: Maybe<PostsMetricsGridReport>;
  saveRate?: Maybe<PostsMetricsGridReport>;
  shareRate?: Maybe<PostsMetricsGridReport>;
  viewRate?: Maybe<PostsMetricsGridReport>;
}

export interface CampaignIdentifierInput {
  campaignId?: InputMaybe<Scalars['Int']['input']>;
  campaignType?: InputMaybe<CampaignIdentifierType>;
}

export enum CampaignIdentifierType {
  ENGAGEMENT = 'ENGAGEMENT',
  MARKETPLACE = 'MARKETPLACE',
}

export interface CampaignInfluencerInfo {
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  thumbNail: Scalars['String']['output'];
}

export interface CampaignInfluencerReport {
  InternalInfo?: Maybe<Array<Scalars['String']['output']>>;
  influencerInfo: CampaignInfluencerInfo;
  orderedSummaryDaily?: Maybe<Array<CampaignSummaryDaily>>;
  postCount?: Maybe<Scalars['Int']['output']>;
  postReports?: Maybe<Array<NewCampaignPostReport>>;
  summary: NewCampaignStatsItemRow;
  /** @deprecated use ordered_summary_daily */
  summaryDaily?: Maybe<Array<CampaignSummaryDaily>>;
}

export interface CampaignPayment {
  campaignType: CampaignType;
  /** Marketplace OR AutoManaged campaign */
  id: Scalars['Int']['output'];
  revenue: Scalars['Float']['output'];
  title: Scalars['String']['output'];
}

export interface CampaignPost {
  actions?: Maybe<Scalars['Int']['output']>;
  affiliatePaidStatus?: Maybe<CommissionStatus>;
  age18to24Percentage?: Maybe<Scalars['Float']['output']>;
  age25to34Percentage?: Maybe<Scalars['Float']['output']>;
  age35upPercentage?: Maybe<Scalars['Float']['output']>;
  audienceRetention?: Maybe<Scalars['Float']['output']>;
  avatar: Scalars['String']['output'];
  averageViewDurationPercentage?: Maybe<Scalars['Float']['output']>;
  averageViewDurationSeconds?: Maybe<Scalars['Int']['output']>;
  averageViewTime?: Maybe<Scalars['Float']['output']>;
  back?: Maybe<Scalars['Int']['output']>;
  campaignIdentifier: ReadCampaignIdentifier;
  campaignName: Scalars['String']['output'];
  clicks: Scalars['Int']['output'];
  comments: Scalars['Int']['output'];
  content: Scalars['String']['output'];
  conversions?: Maybe<Scalars['Int']['output']>;
  cost?: Maybe<Scalars['Float']['output']>;
  currencyId: Scalars['String']['output'];
  dislikes?: Maybe<Scalars['Int']['output']>;
  engagement: Scalars['Int']['output'];
  engagementFollowersRate?: Maybe<Scalars['Float']['output']>;
  engagementViewsRate?: Maybe<Scalars['Float']['output']>;
  exited?: Maybe<Scalars['Int']['output']>;
  femalePercentage?: Maybe<Scalars['Float']['output']>;
  followers: Scalars['Int']['output'];
  forward?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  impressions: Scalars['Int']['output'];
  impressionsClickThroughRate?: Maybe<Scalars['Float']['output']>;
  impressionsFromDiscovery?: Maybe<Scalars['Int']['output']>;
  impressionsFromHashtag?: Maybe<Scalars['Int']['output']>;
  impressionsFromHome?: Maybe<Scalars['Int']['output']>;
  impressionsFromOther?: Maybe<Scalars['Int']['output']>;
  impressionsFromProfile?: Maybe<Scalars['Int']['output']>;
  impressionsRate: Scalars['Float']['output'];
  influencerId: Scalars['Int']['output'];
  influencerName: Scalars['String']['output'];
  interaction?: Maybe<Scalars['Int']['output']>;
  likes: Scalars['Int']['output'];
  linkClicks?: Maybe<Scalars['Int']['output']>;
  malePercentage?: Maybe<Scalars['Float']['output']>;
  navigation?: Maybe<Scalars['Int']['output']>;
  nextStory?: Maybe<Scalars['Int']['output']>;
  nonFollowerReach?: Maybe<Scalars['Int']['output']>;
  nonFollowerReachRate?: Maybe<Scalars['Float']['output']>;
  orders?: Maybe<Scalars['Int']['output']>;
  postDate: Scalars['Date']['output'];
  postUrl: Scalars['String']['output'];
  profileActivity?: Maybe<Scalars['Int']['output']>;
  reach: Scalars['Int']['output'];
  reachRate: Scalars['Float']['output'];
  revenue: Scalars['Float']['output'];
  sales: Scalars['Float']['output'];
  salesCost?: Maybe<Scalars['Float']['output']>;
  salesReferral?: Maybe<Scalars['Float']['output']>;
  saved: Scalars['Int']['output'];
  shares: Scalars['Int']['output'];
  socialPostType: CampaignPostSocialType;
  stickerTaps?: Maybe<Scalars['Int']['output']>;
  thumbnail?: Maybe<Scalars['String']['output']>;
  totalPlayTime?: Maybe<Scalars['Int']['output']>;
  videoCompletionRate?: Maybe<Scalars['Float']['output']>;
  views: Scalars['Int']['output'];
}

export interface CampaignPostGridInfo {
  content: Scalars['String']['output'];
  hasTrafficSource?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['Int']['output'];
  influencerId: Scalars['Int']['output'];
  insightDataAcquisition?: Maybe<Scalars['Date']['output']>;
  postUrl: Scalars['String']['output'];
  socialAccountAvatar?: Maybe<Scalars['String']['output']>;
  socialAccountId: Scalars['Int']['output'];
  socialMedia: CampaignSocialMediaType;
  socialUsername: Scalars['String']['output'];
  thumbNail?: Maybe<Scalars['String']['output']>;
}

export interface CampaignPostInfo {
  content: Scalars['String']['output'];
  hasTrafficSource?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['Int']['output'];
  influencerId: Scalars['Int']['output'];
  insightDataAcquisition?: Maybe<Scalars['Date']['output']>;
  postUrl: Scalars['String']['output'];
  socialAccountId: Scalars['Int']['output'];
  socialMedia: CampaignSocialMediaType;
  thumbNail?: Maybe<Scalars['String']['output']>;
}

export interface CampaignPostMetrics {
  actions?: Maybe<Scalars['Float']['output']>;
  age18to24Percentage?: Maybe<Scalars['Float']['output']>;
  age25to34Percentage?: Maybe<Scalars['Float']['output']>;
  age35upPercentage?: Maybe<Scalars['Float']['output']>;
  audienceRetention?: Maybe<Scalars['Float']['output']>;
  averageViewDurationPercentage?: Maybe<Scalars['Float']['output']>;
  averageViewDurationSeconds?: Maybe<Scalars['Float']['output']>;
  averageViewTime?: Maybe<Scalars['Float']['output']>;
  back?: Maybe<Scalars['Float']['output']>;
  clicks: Scalars['Float']['output'];
  comments: Scalars['Float']['output'];
  conversions?: Maybe<Scalars['Float']['output']>;
  cost?: Maybe<Scalars['Float']['output']>;
  dislikes?: Maybe<Scalars['Float']['output']>;
  engagement: Scalars['Float']['output'];
  engagementFollowersRate: Scalars['Float']['output'];
  engagementViewsRate: Scalars['Float']['output'];
  exited?: Maybe<Scalars['Float']['output']>;
  femalePercentage?: Maybe<Scalars['Float']['output']>;
  followers: Scalars['Float']['output'];
  forward?: Maybe<Scalars['Float']['output']>;
  impressions: Scalars['Float']['output'];
  impressionsClickThroughRate?: Maybe<Scalars['Float']['output']>;
  impressionsFromDiscovery?: Maybe<Scalars['Float']['output']>;
  impressionsFromHashtag?: Maybe<Scalars['Float']['output']>;
  impressionsFromHome?: Maybe<Scalars['Float']['output']>;
  impressionsFromOther?: Maybe<Scalars['Float']['output']>;
  impressionsFromProfile?: Maybe<Scalars['Float']['output']>;
  impressionsRate: Scalars['Float']['output'];
  interaction?: Maybe<Scalars['Float']['output']>;
  likes: Scalars['Float']['output'];
  linkClicks?: Maybe<Scalars['Float']['output']>;
  malePercentage?: Maybe<Scalars['Float']['output']>;
  navigation?: Maybe<Scalars['Float']['output']>;
  nextStory?: Maybe<Scalars['Float']['output']>;
  nonFollowerReach?: Maybe<Scalars['Float']['output']>;
  nonFollowerReachRate?: Maybe<Scalars['Float']['output']>;
  orders?: Maybe<Scalars['Float']['output']>;
  profileActivity?: Maybe<Scalars['Float']['output']>;
  reach: Scalars['Float']['output'];
  reachRate: Scalars['Float']['output'];
  revenue: Scalars['Float']['output'];
  sales: Scalars['Float']['output'];
  salesCost?: Maybe<Scalars['Float']['output']>;
  salesReferral?: Maybe<Scalars['Float']['output']>;
  saved: Scalars['Float']['output'];
  shares: Scalars['Float']['output'];
  stickerTaps?: Maybe<Scalars['Float']['output']>;
  totalPlayTime?: Maybe<Scalars['Float']['output']>;
  videoCompletionRate?: Maybe<Scalars['Float']['output']>;
  views: Scalars['Float']['output'];
}

export interface CampaignPostOrderBy {
  field?: InputMaybe<CampaignPostSortField>;
  order?: InputMaybe<Order>;
}

export interface CampaignPostReportGrid {
  /** For all SNSs */
  comment: Scalars['Int']['output'];
  conversion?: Maybe<Scalars['Int']['output']>;
  /** For all SNSs */
  engagementRate: Scalars['Float']['output'];
  engagementViewsRate?: Maybe<Scalars['Float']['output']>;
  impressions?: Maybe<Scalars['Int']['output']>;
  like?: Maybe<Scalars['Int']['output']>;
  orders?: Maybe<Scalars['Int']['output']>;
  postInfo: CampaignPostGridInfo;
  /** Rate of current Comparable metrics */
  rate?: Maybe<Scalars['Float']['output']>;
  reach?: Maybe<Scalars['Int']['output']>;
  /** @deprecated please use sales_tune or sales_referral */
  sales?: Maybe<Scalars['Float']['output']>;
  salesReferral?: Maybe<Scalars['Float']['output']>;
  salesTune?: Maybe<Scalars['Float']['output']>;
  saved?: Maybe<Scalars['Int']['output']>;
  share?: Maybe<Scalars['Int']['output']>;
  view?: Maybe<Scalars['Int']['output']>;
}

export enum CampaignPostSocialType {
  DOUYIN = 'DOUYIN',
  FACEBOOK = 'FACEBOOK',
  FACEBOOK_PAGE = 'FACEBOOK_PAGE',
  INSTAGRAM = 'INSTAGRAM',
  THREADS = 'THREADS',
  TIKTOK = 'TIKTOK',
  TWITTER = 'TWITTER',
  XHS = 'XHS',
  YOUTUBE = 'YOUTUBE',
}

export enum CampaignPostSortField {
  ACTIONS = 'ACTIONS',
  AFFILIATE_PAID_STATUS = 'AFFILIATE_PAID_STATUS',
  AGE_18_TO_24_PERCENTAGE = 'AGE_18_TO_24_PERCENTAGE',
  AGE_25_TO_34_PERCENTAGE = 'AGE_25_TO_34_PERCENTAGE',
  AGE_35_UP_PERCENTAGE = 'AGE_35_UP_PERCENTAGE',
  AUDIENCE_RETENTION = 'AUDIENCE_RETENTION',
  AVERAGE_VIEW_DURATION_PERCENTAGE = 'AVERAGE_VIEW_DURATION_PERCENTAGE',
  AVERAGE_VIEW_DURATION_SECONDS = 'AVERAGE_VIEW_DURATION_SECONDS',
  AVERAGE_VIEW_TIME = 'AVERAGE_VIEW_TIME',
  BACK = 'BACK',
  CLICKS = 'CLICKS',
  COMMENTS = 'COMMENTS',
  CONVERSIONS = 'CONVERSIONS',
  COST = 'COST',
  DATE = 'DATE',
  DISLIKES = 'DISLIKES',
  ENGAGEMENT = 'ENGAGEMENT',
  ENGAGEMENT_FOLLOWERS_RATE = 'ENGAGEMENT_FOLLOWERS_RATE',
  ENGAGEMENT_VIEWS_RATE = 'ENGAGEMENT_VIEWS_RATE',
  EXITED = 'EXITED',
  FEMALE_PERCENTAGE = 'FEMALE_PERCENTAGE',
  FOLLOWERS = 'FOLLOWERS',
  FORWARD = 'FORWARD',
  IMPRESSIONS = 'IMPRESSIONS',
  IMPRESSIONS_CLICK_THROUGH_RATE = 'IMPRESSIONS_CLICK_THROUGH_RATE',
  IMPRESSIONS_FROM_DISCOVERY = 'IMPRESSIONS_FROM_DISCOVERY',
  IMPRESSIONS_FROM_HASHTAG = 'IMPRESSIONS_FROM_HASHTAG',
  IMPRESSIONS_FROM_HOME = 'IMPRESSIONS_FROM_HOME',
  IMPRESSIONS_FROM_OTHER = 'IMPRESSIONS_FROM_OTHER',
  IMPRESSIONS_FROM_PROFILE = 'IMPRESSIONS_FROM_PROFILE',
  IMPRESSIONS_RATE = 'IMPRESSIONS_RATE',
  INTERACTION = 'INTERACTION',
  LIKES = 'LIKES',
  LINK_CLICKS = 'LINK_CLICKS',
  MALE_PERCENTAGE = 'MALE_PERCENTAGE',
  NAVIGATION = 'NAVIGATION',
  NEXT_STORY = 'NEXT_STORY',
  NON_FOLLOWER_REACH = 'NON_FOLLOWER_REACH',
  NON_FOLLOWER_REACH_RATE = 'NON_FOLLOWER_REACH_RATE',
  ORDERS = 'ORDERS',
  PROFILE_ACTIVITY = 'PROFILE_ACTIVITY',
  REACH = 'REACH',
  REACH_RATE = 'REACH_RATE',
  REVENUE = 'REVENUE',
  SALES = 'SALES',
  SALES_COST = 'SALES_COST',
  SALES_REFERRAL = 'SALES_REFERRAL',
  SAVED = 'SAVED',
  SHARES = 'SHARES',
  STICKER_TAPS = 'STICKER_TAPS',
  TOTAL_PLAY_TIME = 'TOTAL_PLAY_TIME',
  VIDEO_COMPLETION_RATE = 'VIDEO_COMPLETION_RATE',
  VIEWS = 'VIEWS',
}

/** An enumeration. */
export enum CampaignPostStatusForInfluencer {
  EFFECTIVE = 'EFFECTIVE',
  INEFFECTIVE = 'INEFFECTIVE',
  REVIEWING = 'REVIEWING',
  WARNING = 'WARNING',
}

export interface CampaignPosts {
  posts: Array<CampaignPost>;
  summary: CampaignPostsSummary;
  totalCount?: Maybe<Scalars['Int']['output']>;
}

export interface CampaignPostsSummary {
  average: CampaignPostMetrics;
  currencyId: Scalars['String']['output'];
  sum: CampaignPostMetrics;
}

export enum CampaignPromotionMethod {
  EMAIL_NEWSLETTER = 'EMAIL_NEWSLETTER',
  FACEBOOK = 'FACEBOOK',
  FACEBOOK_PAGE = 'FACEBOOK_PAGE',
  INSTAGRAM = 'INSTAGRAM',
  INSTAGRAM_STORY = 'INSTAGRAM_STORY',
  MOBILE_APP = 'MOBILE_APP',
  OFFLINE = 'OFFLINE',
  OTHER_SOCIAL_MEDIA = 'OTHER_SOCIAL_MEDIA',
  PODCAST = 'PODCAST',
  TIKTOK = 'TIKTOK',
  TWITTER = 'TWITTER',
  WEBSITE = 'WEBSITE',
  YOUTUBE = 'YOUTUBE',
}

export interface CampaignReportCampaignInfo {
  endDate?: Maybe<Scalars['Date']['output']>;
  hasPostTracking?: Maybe<Scalars['Boolean']['output']>;
  hasTrafficSource?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['Int']['output'];
  /** Campaign detail type for MKP */
  mkpDetailType?: Maybe<MarketplaceCampaignDetailType>;
  socialMedias: Array<CampaignSocialMediaType>;
  startDate?: Maybe<Scalars['Date']['output']>;
  status: CampaignStatusForReport;
  thumbNail: Scalars['String']['output'];
  title: Scalars['String']['output'];
  type: CampaignType;
}

export interface CampaignReportCampaignInfoForInfluencer {
  endDate: Scalars['Date']['output'];
  id: Scalars['Int']['output'];
  socialMedias: Array<Maybe<CampaignSocialMediaType>>;
  startDate: Scalars['Date']['output'];
  status: CampaignStatusForInfluencer;
  suspendedDate?: Maybe<Scalars['Date']['output']>;
  thumbNail: Scalars['String']['output'];
  title: Scalars['String']['output'];
  type: CampaignType;
}

export interface CampaignReportOrderBy {
  field?: InputMaybe<CampaignReportOrderField>;
  order?: InputMaybe<ORDER>;
}

/** An enumeration. */
export enum CampaignReportOrderField {
  AGE_18_24_PERCENTAGE = 'AGE_18_24_PERCENTAGE',
  AGE_25_34_PERCENTAGE = 'AGE_25_34_PERCENTAGE',
  AGE_35_UP_PERCENTAGE = 'AGE_35_UP_PERCENTAGE',
  AVERAGE_VIEW_DURATION_MINUTES = 'AVERAGE_VIEW_DURATION_MINUTES',
  AVERAGE_VIEW_DURATION_PERCENTAGE = 'AVERAGE_VIEW_DURATION_PERCENTAGE',
  AVERAGE_VIEW_DURATION_SECONDS = 'AVERAGE_VIEW_DURATION_SECONDS',
  AVERAGE_VIEW_TIME = 'AVERAGE_VIEW_TIME',
  BACK = 'BACK',
  CLICK = 'CLICK',
  COMMENT = 'COMMENT',
  CONVERSION = 'CONVERSION',
  COST = 'COST',
  COST_PER_ACTION = 'COST_PER_ACTION',
  COST_PER_CLICK = 'COST_PER_CLICK',
  COST_PER_COMMENT = 'COST_PER_COMMENT',
  COST_PER_ENGAGEMENT = 'COST_PER_ENGAGEMENT',
  COST_PER_FOLLOWER = 'COST_PER_FOLLOWER',
  COST_PER_IMPRESSIONS = 'COST_PER_IMPRESSIONS',
  COST_PER_LIKE = 'COST_PER_LIKE',
  COST_PER_ORDER = 'COST_PER_ORDER',
  COST_PER_REACH = 'COST_PER_REACH',
  COST_PER_SALE = 'COST_PER_SALE',
  COST_PER_SALE_REFERRAL = 'COST_PER_SALE_REFERRAL',
  COST_PER_SALE_TUNE = 'COST_PER_SALE_TUNE',
  COST_PER_SAVED = 'COST_PER_SAVED',
  COST_PER_SHARE = 'COST_PER_SHARE',
  COST_PER_VIEW = 'COST_PER_VIEW',
  DATE = 'DATE',
  DISLIKES = 'DISLIKES',
  ENGAGEMENT = 'ENGAGEMENT',
  ENGAGEMENT_RATE = 'ENGAGEMENT_RATE',
  ENGAGEMENT_VIEWS_RATE = 'ENGAGEMENT_VIEWS_RATE',
  EXITED = 'EXITED',
  FEMALE_PERCENTAGE = 'FEMALE_PERCENTAGE',
  FOLLOWERS = 'FOLLOWERS',
  FOLLOWER_REACH = 'FOLLOWER_REACH',
  FOLLOWER_REACH_RATE = 'FOLLOWER_REACH_RATE',
  FORWARD = 'FORWARD',
  IMPRESSIONS = 'IMPRESSIONS',
  IMPRESSIONS_FROM_DISCOVERY = 'IMPRESSIONS_FROM_DISCOVERY',
  IMPRESSIONS_FROM_HASHTAG = 'IMPRESSIONS_FROM_HASHTAG',
  IMPRESSIONS_FROM_HOME = 'IMPRESSIONS_FROM_HOME',
  IMPRESSIONS_FROM_OTHER = 'IMPRESSIONS_FROM_OTHER',
  IMPRESSIONS_FROM_PROFILE = 'IMPRESSIONS_FROM_PROFILE',
  IMPRESSION_RATE = 'IMPRESSION_RATE',
  INTERACTION = 'INTERACTION',
  LIKE = 'LIKE',
  LINK_CLICKS = 'LINK_CLICKS',
  MALE_PERCENTAGE = 'MALE_PERCENTAGE',
  NAVIGATION = 'NAVIGATION',
  NET = 'NET',
  NEW_FOLLOWERS = 'NEW_FOLLOWERS',
  NEXT_STORY = 'NEXT_STORY',
  NON_FOLLOWER_REACH = 'NON_FOLLOWER_REACH',
  NON_FOLLOWER_REACH_RATE = 'NON_FOLLOWER_REACH_RATE',
  ORDER = 'ORDER',
  PROFILE_ACTIVITY = 'PROFILE_ACTIVITY',
  PROFIT = 'PROFIT',
  PROFIT_RATE = 'PROFIT_RATE',
  REACH = 'REACH',
  REACH_RATE = 'REACH_RATE',
  SALES = 'SALES',
  SALES_REFERRAL = 'SALES_REFERRAL',
  SALES_TUNE = 'SALES_TUNE',
  SAVED = 'SAVED',
  SHARE = 'SHARE',
  STICKER_TAPS = 'STICKER_TAPS',
  TOTAL_PLAY_TIME = 'TOTAL_PLAY_TIME',
  VIDEO_COMPLETION_RATE = 'VIDEO_COMPLETION_RATE',
  VIEW = 'VIEW',
  VIEWS_FROM_FOLLOWING = 'VIEWS_FROM_FOLLOWING',
  VIEWS_FROM_HOME = 'VIEWS_FROM_HOME',
  VIEWS_FROM_OTHER = 'VIEWS_FROM_OTHER',
  VIEWS_FROM_PROFILE = 'VIEWS_FROM_PROFILE',
  VIEWS_FROM_SEARCH = 'VIEWS_FROM_SEARCH',
}

export interface CampaignReportSummary {
  age35UpPercentage?: Maybe<Scalars['Float']['output']>;
  age1824Percentage?: Maybe<Scalars['Float']['output']>;
  age2534Percentage?: Maybe<Scalars['Float']['output']>;
  averageViewDurationMinutes?: Maybe<Scalars['Int']['output']>;
  averageViewDurationPercentage?: Maybe<Scalars['Float']['output']>;
  averageViewDurationSeconds?: Maybe<Scalars['Int']['output']>;
  back?: Maybe<Scalars['Int']['output']>;
  click?: Maybe<Scalars['Int']['output']>;
  comment: Scalars['Int']['output'];
  conversion?: Maybe<Scalars['Int']['output']>;
  cost: Scalars['Float']['output'];
  costsPerAction?: Maybe<Scalars['Float']['output']>;
  costsPerClick?: Maybe<Scalars['Float']['output']>;
  /** For Vietnam campaigns */
  costsPerComment?: Maybe<Scalars['Float']['output']>;
  costsPerEngagement?: Maybe<Scalars['Float']['output']>;
  costsPerFollower?: Maybe<Scalars['Float']['output']>;
  costsPerImpressions?: Maybe<Scalars['Float']['output']>;
  costsPerLike?: Maybe<Scalars['Float']['output']>;
  costsPerOrder?: Maybe<Scalars['Float']['output']>;
  costsPerReach?: Maybe<Scalars['Float']['output']>;
  costsPerSale?: Maybe<Scalars['Float']['output']>;
  costsPerSaleReferral?: Maybe<Scalars['Float']['output']>;
  costsPerSaleTune?: Maybe<Scalars['Float']['output']>;
  costsPerSaved?: Maybe<Scalars['Float']['output']>;
  costsPerShare?: Maybe<Scalars['Float']['output']>;
  costsPerView?: Maybe<Scalars['Float']['output']>;
  dislikes?: Maybe<Scalars['Int']['output']>;
  engagement: Scalars['Float']['output'];
  /** Engagement Rate by Followers for IG, FB, FB Page, X, Threads */
  engagementRate?: Maybe<Scalars['Float']['output']>;
  /** Engagement Rate by Views for TikTok, YT, Douyin */
  engagementViewsRate?: Maybe<Scalars['Float']['output']>;
  exited?: Maybe<Scalars['Int']['output']>;
  femalePercentage?: Maybe<Scalars['Float']['output']>;
  followers: Scalars['Float']['output'];
  forward?: Maybe<Scalars['Int']['output']>;
  impressionRate?: Maybe<Scalars['Float']['output']>;
  impressions?: Maybe<Scalars['Int']['output']>;
  impressionsFromDiscovery?: Maybe<Scalars['Int']['output']>;
  impressionsFromHashtag?: Maybe<Scalars['Int']['output']>;
  impressionsFromHome?: Maybe<Scalars['Int']['output']>;
  impressionsFromOther?: Maybe<Scalars['Int']['output']>;
  impressionsFromProfile?: Maybe<Scalars['Int']['output']>;
  interaction?: Maybe<Scalars['Int']['output']>;
  like?: Maybe<Scalars['Int']['output']>;
  linkClicks?: Maybe<Scalars['Int']['output']>;
  malePercentage?: Maybe<Scalars['Float']['output']>;
  navigation?: Maybe<Scalars['Int']['output']>;
  /** For admin or staff user, and EngagementCampaign */
  net?: Maybe<Scalars['Float']['output']>;
  nextStory?: Maybe<Scalars['Int']['output']>;
  nonFollowerReach?: Maybe<Scalars['Int']['output']>;
  nonFollowerReachRate?: Maybe<Scalars['Float']['output']>;
  orders?: Maybe<Scalars['Int']['output']>;
  profileActivity?: Maybe<Scalars['Int']['output']>;
  /** For admin or staff user, and EngagementCampaign */
  profit?: Maybe<Scalars['Float']['output']>;
  /** For admin or staff user, and EngagementCampaign */
  profitRate?: Maybe<Scalars['Float']['output']>;
  reach?: Maybe<Scalars['Float']['output']>;
  reachRate?: Maybe<Scalars['Float']['output']>;
  /** @deprecated please use sales_tune or sales_referral */
  sales?: Maybe<Scalars['Float']['output']>;
  salesReferral?: Maybe<Scalars['Float']['output']>;
  salesTune?: Maybe<Scalars['Float']['output']>;
  saved?: Maybe<Scalars['Int']['output']>;
  share?: Maybe<Scalars['Int']['output']>;
  stickerTaps?: Maybe<Scalars['Int']['output']>;
  view?: Maybe<Scalars['Float']['output']>;
}

export enum CampaignSocialMediaType {
  DOUYIN = 'DOUYIN',
  FACEBOOK = 'FACEBOOK',
  INSTAGRAM = 'INSTAGRAM',
  INSTAGRAM_STORY = 'INSTAGRAM_STORY',
  OTHER = 'OTHER',
  THREADS = 'THREADS',
  TIKTOK = 'TIKTOK',
  TWITTER = 'TWITTER',
  UNSELECT = 'UNSELECT',
  XHS = 'XHS',
  YOUTUBE = 'YOUTUBE',
}

/** An enumeration. */
export enum CampaignStatusForInfluencer {
  FINISHED = 'FINISHED',
  ONGOING = 'ONGOING',
  OUT_OF_COUPON = 'OUT_OF_COUPON',
  SUSPENDED = 'SUSPENDED',
  UPCOMING = 'UPCOMING',
}

/** An enumeration. */
export enum CampaignStatusForReport {
  FINISHED = 'FINISHED',
  ONGOING = 'ONGOING',
  OUT_OF_COUPON = 'OUT_OF_COUPON',
  OVER_BUDGET = 'OVER_BUDGET',
}

export interface CampaignSummaryDaily {
  date: Scalars['Date']['output'];
  stats: NewCampaignStatsItemRow;
}

export enum CampaignTrackingOption {
  POST = 'POST',
  REFERRAL_CODE = 'REFERRAL_CODE',
  TUNE = 'TUNE',
}

export enum CampaignType {
  AFFILIATE = 'AFFILIATE',
  AUTO_MANAGED = 'AUTO_MANAGED',
  ENGAGEMENT = 'ENGAGEMENT',
  MARKETPLACE = 'MARKETPLACE',
  TIKTOK_SPECIAL = 'TIKTOK_SPECIAL',
}

export interface CampaignsForSearchJobPayload {
  commissionRate?: Maybe<Scalars['Float']['output']>;
  createdDate: Scalars['String']['output'];
  currency: Scalars['String']['output'];
  endDate?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  isAutoInfluencerApproval: Scalars['Boolean']['output'];
  isGiftingCampaign: Scalars['Boolean']['output'];
  isSelectionCampaign: Scalars['Boolean']['output'];
  marketplaceCampaignType?: Maybe<MarketplaceCampaignDetailType>;
  minFollowers?: Maybe<Scalars['Int']['output']>;
  preLaunchDate?: Maybe<Scalars['String']['output']>;
  revenuePerAction?: Maybe<Scalars['Float']['output']>;
  revenuePerClick?: Maybe<Scalars['Float']['output']>;
  revenuePerComment?: Maybe<Scalars['Float']['output']>;
  revenuePerFollower?: Maybe<Scalars['Float']['output']>;
  revenuePerLike?: Maybe<Scalars['Float']['output']>;
  revenuePerOrder?: Maybe<Scalars['Float']['output']>;
  revenuePerPost?: Maybe<Scalars['Float']['output']>;
  revenuePerShare?: Maybe<Scalars['Float']['output']>;
  revenuePerView?: Maybe<Scalars['Float']['output']>;
  /**  null for auto-managed */
  socialMedias: Array<CampaignSocialMediaType>;
  startDate?: Maybe<Scalars['String']['output']>;
  status: MarketplaceCampaignStatus;
  thumbnail: Scalars['String']['output'];
  title: Scalars['String']['output'];
  trackingOptions: Array<CampaignTrackingOption>;
  type: CampaignType;
}

export interface CancelSubscriptionPlan {
  ok: Scalars['Boolean']['output'];
}

export interface CardInput {
  actionLabels: Array<ActionLabelInput>;
  description?: InputMaybe<TextComponentInput>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<TextComponentInput>;
}

export interface CardPayload {
  actionLabels: Array<ActionLabelPayload>;
  description?: Maybe<TextComponentPayload>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  title?: Maybe<TextComponentPayload>;
}

export interface Category {
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
}

export interface CategoryName {
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
}

export interface ChangeInstagramUGCManagementPostStatusInput {
  analyticsAccountId: Scalars['Int']['input'];
  postSnsId: Scalars['String']['input'];
  status: UGCPostStatus;
}

export interface ChangeInstagramUGCManagementPostStatusOutput {
  ok: Scalars['Boolean']['output'];
}

export enum ChangeType {
  ADDRESS1 = 'ADDRESS1',
  ADDRESS2 = 'ADDRESS2',
  BIRTHDAY = 'BIRTHDAY',
  CITY = 'CITY',
  COMMENT = 'COMMENT',
  COUNTRY_ID = 'COUNTRY_ID',
  EMAIL = 'EMAIL',
  FIRST_NAME = 'FIRST_NAME',
  GENDER = 'GENDER',
  LAST_NAME = 'LAST_NAME',
  ORDER_ID = 'ORDER_ID',
  PHONE = 'PHONE',
  PROVINCE = 'PROVINCE',
  TAGS = 'TAGS',
  ZIP = 'ZIP',
}

export interface ChatEvent {
  id: Scalars['String']['output'];
  timestamp: Scalars['String']['output'];
}

export interface CheckBioLinkNameAvailabilityInput {
  linkName: Scalars['String']['input'];
}

export interface CheckBioLinkNameAvailabilityPayload {
  isAvailable: Scalars['Boolean']['output'];
}

export interface City {
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
}

export interface CityForInfluencer {
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
}

export interface ClearCmsChannelAssetOwner {
  ok: Scalars['Boolean']['output'];
}

export interface ClearCmsChannelAssetOwnerInput {
  channelId: Scalars['String']['input'];
  /** Asset System Id */
  id: Scalars['Int']['input'];
}

/**  `ClickNotificationByEventIdInput` input defines the uuid string `eventId` to be clicked. */
export interface ClickNotificationByEventIdInput {
  eventId: Scalars['String']['input'];
}

/**  `ClickNotificationByEventIdPayload` payload houses an `ok` boolean to determine the success of the operation. */
export interface ClickNotificationByEventIdPayload {
  ok: Scalars['Boolean']['output'];
}

/**  `ClickNotificationInput` input defines the `notificationId` to be clicked. */
export interface ClickNotificationInput {
  id: Scalars['Long']['input'];
}

/**  `ClickNotificationPayload` payload houses an `ok` boolean to determine the success of the operation. */
export interface ClickNotificationPayload {
  ok: Scalars['Boolean']['output'];
}

export interface CloneMarketplace {
  id: Scalars['Int']['output'];
  ok: Scalars['Boolean']['output'];
}

export interface CloneMarketplaceInput {
  duplicateId: Scalars['Int']['input'];
  title: Scalars['String']['input'];
}

export interface CmsAssetShareRateInput {
  /** Asset System Id */
  id: Scalars['Int']['input'];
  shareRate: Scalars['Float']['input'];
}

export interface CmsChannelAssetInput {
  channelAvatar?: InputMaybe<Scalars['String']['input']>;
  channelId: Scalars['String']['input'];
  channelName?: InputMaybe<Scalars['String']['input']>;
  /** Asset System Id */
  id: Scalars['Int']['input'];
}

export interface CmsChannelShareRateInput {
  /** Channel Id */
  channelId: Scalars['String']['input'];
  shareRate: Scalars['Float']['input'];
}

export interface CommentContent {
  content?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  username?: Maybe<Scalars['String']['output']>;
}

export enum CommissionStatus {
  APPROVED = 'APPROVED',
  UNAPPROVED = 'UNAPPROVED',
}

export interface CompanyAndRolePair {
  /** id of company */
  companyId: Scalars['Int']['input'];
  /** company role */
  role: UserRoles;
}

export interface ConnectFacebookPagesAndInstagramAccountsInput {
  fbPageIds: Array<Scalars['String']['input']>;
  igUserIds: Array<Scalars['String']['input']>;
}

export interface ConnectFacebookPagesAndInstagramAccountsPayload {
  ok: Scalars['Boolean']['output'];
}

export interface ConnectNewShopifyAccountPayload {
  ok: Scalars['Boolean']['output'];
}

export interface ConnectPackageToEngagementProposal {
  ok: Scalars['Boolean']['output'];
}

export interface ConnectPackageToEngagementProposalInput {
  engagementCampaignId: Scalars['Int']['input'];
  influencerIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  packageIds: Array<Scalars['Int']['input']>;
}

export interface ConnectPackageWithInfluencerAccounts {
  ok: Scalars['Boolean']['output'];
}

export interface ConnectPackageWithInfluencerAccountsInput {
  influencerIds: Array<Scalars['Int']['input']>;
  packageId: Scalars['Int']['input'];
}

export interface ConnectPackageWithSocialAccounts {
  ok: Scalars['Boolean']['output'];
}

export interface ConnectPackageWithSocialAccountsInput {
  packageId: Scalars['Int']['input'];
  socialPairs?: InputMaybe<Array<SocialAccountPairInput>>;
}

export interface ConnectPackageWithUserProposal {
  ok: Scalars['Boolean']['output'];
}

export interface ConnectPackageWithUserProposalInput {
  packageId: Scalars['Int']['input'];
}

export interface ConnectPromotionMethodsInput {
  methods: Array<PromotionMethodInput>;
}

export interface ConnectPromotionMethodsPayload {
  ok: Scalars['Boolean']['output'];
}

export interface ConnectableFacebookPage {
  avatar?: Maybe<Scalars['String']['output']>;
  followersCount: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  isAlreadyConnected: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
}

export interface ConnectableFacebookPageAccount {
  avatar: Scalars['String']['output'];
  followers: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  isAlreadyUsed: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
}

export interface ConnectableFacebookPageAndInstagramAccount {
  igAccount?: Maybe<ConnectableInstagramAccount>;
  page: ConnectableFacebookPage;
}

export interface ConnectableFacebookPageMainAccount {
  avatar: Scalars['String']['output'];
  friendCount: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
}

export interface ConnectableFacebookPagesAndInstagramAccountsPayload {
  accounts: Array<ConnectableFacebookPageAndInstagramAccount>;
}

export interface ConnectableFacebookPagesPayload {
  pages: Array<ConnectableFacebookPage>;
}

export interface ConnectableInstagramAccount {
  avatar?: Maybe<Scalars['String']['output']>;
  followersCount: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  isAlreadyConnected: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
}

export interface ConnectableInstagramAccountWithNoConnectedFlag {
  avatar?: Maybe<Scalars['String']['output']>;
  followersCount: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
}

export interface ConnectableInstagramAccountsPayload {
  igAccounts: Array<ConnectableInstagramAccountWithNoConnectedFlag>;
}

/**
 *  `id`: social network account id
 *  `avatar`: account's avatar URL from a remote social network server
 *  `name`: account's name
 *  `followersCount`: number of followers
 */
export interface ConnectableOauthAccount {
  avatar?: Maybe<Scalars['String']['output']>;
  followersCount: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
}

export interface ConnectableOauthAccountForTalentSignIn {
  avatar?: Maybe<Scalars['String']['output']>;
  followersCount: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  status: SocialAccountStatus;
}

export interface ConnectableOauthFacebookAccountForTalentSignIn {
  fbName: Scalars['String']['output'];
  fbUserId: Scalars['String']['output'];
  status: SocialAccountStatus;
}

export interface ConnectableOauthInstagramAccountForTalentSignIn {
  avatar?: Maybe<Scalars['String']['output']>;
  followersCount: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  status: SocialAccountStatus;
  username: Scalars['String']['output'];
}

/**
 *  `page`: Instagram account's Facebook page
 *  `igAccount`: Instagram account data
 *  `isAlreadyUsed`: a flag that tells if an account has been used/connected/created in analytics by someone else.
 */
export interface ConnectableOauthPageAndIgAccount {
  igAccount: ConnectableOauthAccount;
  isAlreadyUsed: Scalars['Boolean']['output'];
  page: ConnectableOauthAccount;
}

export interface ConnectableOauthPageAndIgAccountForSignInFacebook {
  igAccount?: Maybe<ConnectableOauthAccountForTalentSignIn>;
  isPageAlreadyConnected: Scalars['Boolean']['output'];
  page: ConnectableOauthAccountForTalentSignIn;
}

export interface ConnectableOauthPageAndIgAccountForTalentSignIn {
  igAccount: ConnectableOauthInstagramAccountForTalentSignIn;
  isAlreadyUsed: Scalars['Boolean']['output'];
  page: ConnectableOauthAccountForTalentSignIn;
}

export interface ConnectableOauthYouTubeAccountForTalentSignIn {
  avatar: Scalars['String']['output'];
  channelId: Scalars['String']['output'];
  channelName: Scalars['String']['output'];
  isUsed: Scalars['Boolean']['output'];
  status: SocialAccountStatus;
  subscribersCount: Scalars['Int']['output'];
}

export interface ConnectedAccountForLinkBio {
  avatar: Scalars['String']['output'];
  followersCount?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  socialAccountType: SocialAccountType;
  url: Scalars['String']['output'];
}

export interface ConnectedFacebook {
  fbName: Scalars['String']['output'];
  fbUserId?: Maybe<Scalars['String']['output']>;
  status: SocialAccountStatus;
}

export interface ConnectedFacebookPage {
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
}

export interface ConnectedPage {
  pageId?: Maybe<Scalars['String']['output']>;
  pageName: Scalars['String']['output'];
  status: SocialAccountStatus;
}

export interface ConnectedPageAndIgAccount {
  accountId: Scalars['Int']['output'];
  igName: Scalars['String']['output'];
  igUserId?: Maybe<Scalars['String']['output']>;
  pageId?: Maybe<Scalars['String']['output']>;
  pageName?: Maybe<Scalars['String']['output']>;
  status: SocialAccountStatus;
}

export interface ConnectedTwitter {
  status: SocialAccountStatus;
  twName: Scalars['String']['output'];
  twUserId?: Maybe<Scalars['String']['output']>;
}

export interface ConnectedYouTube {
  channelId: Scalars['String']['output'];
  channelName: Scalars['String']['output'];
  status: SocialAccountStatus;
}

/** An enumeration. */
export enum ConstantHasEstimateMessage {
  YT_HAVE_DATA_PLEASE_WAIT_1_2_DAYS = 'YT_HAVE_DATA_PLEASE_WAIT_1_2_DAYS',
  YT_HAVE_NO_DATA_FOLLOWERS_LESS_THAN_10K = 'YT_HAVE_NO_DATA_FOLLOWERS_LESS_THAN_10K',
  YT_HAVE_NO_DATA_LATEST_POST_OVER_1_YEAR = 'YT_HAVE_NO_DATA_LATEST_POST_OVER_1_YEAR',
  YT_HAVE_NO_DATA_NOT_SUPPORTED_COUNTRY = 'YT_HAVE_NO_DATA_NOT_SUPPORTED_COUNTRY',
}

/**  `CountUnreadNotificationsByAppInput` input accepts `appType`: a enum type which represents the notification scope of application */
export interface CountUnreadNotificationsByAppInput {
  appType: AppType;
}

export interface CountriesPayload {
  countries: Array<Country>;
}

export interface Country {
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
}

export interface CountryAndCurrency {
  currency: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isAvailableCountry: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
}

export interface CountryName {
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
}

export interface CountryNameForInfluencer {
  id: Scalars['String']['output'];
  isMena: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
}

export interface CreateAdmin {
  ok: Scalars['Boolean']['output'];
}

export interface CreateAdminInput {
  countryId: Scalars['String']['input'];
  email: Scalars['String']['input'];
  hasInvitationEmail: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<CustomPermissionsInput>;
}

export interface CreateAdvertiser {
  ok: Scalars['Boolean']['output'];
}

export interface CreateAdvertiserInput {
  companyUrl?: InputMaybe<Scalars['String']['input']>;
  countryId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
}

export interface CreateAdvertiserInputV2 {
  advertiserName: Scalars['String']['input'];
  anyxMasterId?: InputMaybe<Scalars['Int']['input']>;
  companyUrl?: InputMaybe<Scalars['String']['input']>;
  countryId: Scalars['String']['input'];
  hubspotId?: InputMaybe<Scalars['String']['input']>;
  internalNetsuiteId?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
}

export interface CreateAdvertiserUser {
  ok: Scalars['Boolean']['output'];
}

export interface CreateAdvertiserUserInput {
  advertiserId: Scalars['Int']['input'];
  countryId: Scalars['String']['input'];
  email: Scalars['String']['input'];
  hasInvitationEmail: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  notificationSetting: Scalars['Boolean']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<CustomPermissionsInput>;
}

export interface CreateAdvertiserWithUser {
  ok: Scalars['Boolean']['output'];
}

export interface CreateAdvertiserWithUserInput {
  advertiserName: Scalars['String']['input'];
  anyxMasterId?: InputMaybe<Scalars['Int']['input']>;
  companyUrl?: InputMaybe<Scalars['String']['input']>;
  countryId: Scalars['String']['input'];
  hubspotId?: InputMaybe<Scalars['String']['input']>;
  internalNetsuiteId?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<AdvertiserUserInput>;
}

export interface CreateAdvertiserWithUserInputV2 {
  /** Needed when want to create new Advertiser */
  newAdvertiserInput?: InputMaybe<CreateAdvertiserInputV2>;
  /** None means we will create new Advertiser */
  selectedAdvertiserId?: InputMaybe<Scalars['Int']['input']>;
  user?: InputMaybe<AdvertiserUserInput>;
}

export interface CreateAdvertiserWithUserV2 {
  ok: Scalars['Boolean']['output'];
}

export interface CreateAgency {
  ok: Scalars['Boolean']['output'];
}

export interface CreateAgencyInput {
  agencyId: Scalars['Int']['input'];
  countryId: Scalars['String']['input'];
  email: Scalars['String']['input'];
  hasInvitationEmail: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<CustomPermissionsInput>;
}

export interface CreateAgencyWithUser {
  ok: Scalars['Boolean']['output'];
}

export interface CreateAgencyWithUserInput {
  advertiserIds: Array<Scalars['Int']['input']>;
  agencyName: Scalars['String']['input'];
  companyUrl?: InputMaybe<Scalars['String']['input']>;
  countryId: Scalars['String']['input'];
  email: Scalars['String']['input'];
  hasInvitationEmail: Scalars['Boolean']['input'];
  hubspotId?: InputMaybe<Scalars['String']['input']>;
  internalNetsuiteId?: InputMaybe<Scalars['String']['input']>;
  logo?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
}

export interface CreateCreatorStaffInput {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
}

export interface CreateCreatorStaffPayload {
  ok: Scalars['Boolean']['output'];
}

export interface CreateDraftBoostPost {
  ok: Scalars['Boolean']['output'];
}

export interface CreateDraftBoostPostInput {
  adAccountSystemId?: InputMaybe<Scalars['Int']['input']>;
  adCampaignId?: InputMaybe<Scalars['String']['input']>;
  adGroupId?: InputMaybe<Scalars['String']['input']>;
  adName?: InputMaybe<Scalars['String']['input']>;
  authPostCode?: InputMaybe<Scalars['String']['input']>;
  callToAction: Scalars['Boolean']['input'];
  landingUrl?: InputMaybe<Scalars['String']['input']>;
  postSystemId: Scalars['String']['input'];
  tkSpecialCampaignId: Scalars['Int']['input'];
}

export interface CreateEngagement {
  id: Scalars['Int']['output'];
  ok: Scalars['Boolean']['output'];
}

export interface CreateEngagementInput {
  adAgencyMarginRate?: InputMaybe<Scalars['Float']['input']>;
  advertiserId: Scalars['Int']['input'];
  budget: Scalars['Float']['input'];
  campaignCategoryId: Scalars['Int']['input'];
  categoryIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  countryIds: Array<Scalars['String']['input']>;
  couponList?: InputMaybe<Scalars['String']['input']>;
  couponUploadFiles?: InputMaybe<Array<Scalars['String']['input']>>;
  currency?: InputMaybe<Scalars['String']['input']>;
  defaultPostRequirement?: InputMaybe<Scalars['String']['input']>;
  endDate: Scalars['Date']['input'];
  hashtags?: InputMaybe<Array<Scalars['String']['input']>>;
  hubspotDealIds?: InputMaybe<Array<Scalars['String']['input']>>;
  influencerManagementPicIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  isTtcmCampaign?: InputMaybe<Scalars['Boolean']['input']>;
  landingPageUrl?: InputMaybe<Scalars['String']['input']>;
  marginRate?: InputMaybe<Scalars['Float']['input']>;
  materialUrl?: InputMaybe<Scalars['String']['input']>;
  materials?: InputMaybe<Array<Scalars['String']['input']>>;
  numberOfInfluencers?: InputMaybe<Scalars['Int']['input']>;
  objective: Scalars['String']['input'];
  salesPicIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  serviceInformation?: InputMaybe<Scalars['String']['input']>;
  serviceUrl?: InputMaybe<Scalars['String']['input']>;
  socialMedias: Array<CampaignSocialMediaType>;
  startDate: Scalars['Date']['input'];
  thumbNail: Scalars['String']['input'];
  title: Scalars['String']['input'];
  trackingOptions?: InputMaybe<Array<CampaignTrackingOption>>;
}

export interface CreateEngagementPostForInfluencerV2 {
  ok: Scalars['Boolean']['output'];
}

export interface CreateEngagementPostInputForInfluencerV2 {
  campaignId: Scalars['Int']['input'];
  content: Scalars['String']['input'];
  materialsUrl?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  planedPostDate: Scalars['Date']['input'];
  /** Represents social account system id */
  socialAccountId: Scalars['Int']['input'];
  /** Represents social account media type */
  socialAccountMedia: SocialAccountType;
  socialMedia: CampaignSocialMediaType;
}

export interface CreateEngagementPostInputV2 {
  campaignId: Scalars['Int']['input'];
  content?: InputMaybe<Scalars['String']['input']>;
  influencerId: Scalars['Int']['input'];
  insightDataAcquisition?: InputMaybe<Scalars['Date']['input']>;
  insightUrls?: InputMaybe<Array<Scalars['String']['input']>>;
  materialsUrl?: InputMaybe<Array<Scalars['String']['input']>>;
  planedPostDate?: InputMaybe<Scalars['Date']['input']>;
  postUrl?: InputMaybe<Scalars['String']['input']>;
  /** Represents social account system id */
  socialAccountId: Scalars['Int']['input'];
  /** Represents social account media type */
  socialAccountMedia: SocialAccountType;
}

export interface CreateEngagementPostV2 {
  ok: Scalars['Boolean']['output'];
}

export interface CreateGeminiThread {
  threadId: Scalars['Int']['output'];
}

export interface CreateInfluencerBioInput {
  linkName: Scalars['String']['input'];
}

export interface CreateInfluencerBioPayload {
  ok: Scalars['Boolean']['output'];
}

export interface CreateInfluencerInputV2 {
  addressInformation?: InputMaybe<AddressInput>;
  campaignDisplay: Scalars['Boolean']['input'];
  categoryIds: Array<Scalars['Int']['input']>;
  countryId: Scalars['String']['input'];
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  defaultEngagementSelectionReason?: InputMaybe<Scalars['String']['input']>;
  douyinAccounts?: InputMaybe<Array<InfluencerSocialNetworkAccountInput>>;
  email?: InputMaybe<Scalars['String']['input']>;
  facebookAccount?: InputMaybe<InfluencerSocialNetworkAccountInput>;
  facebookPages?: InputMaybe<Array<InfluencerSocialNetworkAccountInput>>;
  gender: Genders;
  instagramAccounts?: InputMaybe<Array<InfluencerSocialNetworkAccountInput>>;
  internalMemo?: InputMaybe<Scalars['String']['input']>;
  introduce?: InputMaybe<Scalars['String']['input']>;
  isBrandAccount?: InputMaybe<Scalars['Boolean']['input']>;
  managerIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  name: Scalars['String']['input'];
  notificationSetting: Scalars['Boolean']['input'];
  partnerIds: Array<Scalars['Int']['input']>;
  paymentInformation?: InputMaybe<PaymentInformationInput>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  rateCards?: InputMaybe<InfluencerRateCardsInput>;
  regionId?: InputMaybe<Scalars['Int']['input']>;
  tags: Array<Scalars['String']['input']>;
  talentAgencyIds: Array<Scalars['Int']['input']>;
  threadsAccounts?: InputMaybe<Array<InfluencerSocialNetworkAccountInput>>;
  tiktokAccounts?: InputMaybe<Array<InfluencerSocialNetworkAccountInput>>;
  twitterAccounts?: InputMaybe<Array<InfluencerSocialNetworkAccountInput>>;
  xhsAccounts?: InputMaybe<Array<InfluencerSocialNetworkAccountInput>>;
  youtubeAccounts?: InputMaybe<Array<InfluencerSocialNetworkAccountInput>>;
}

export interface CreateInfluencerStaffInput {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
}

export interface CreateInfluencerStaffPayload {
  ok: Scalars['Boolean']['output'];
}

export interface CreateInfluencerV2 {
  ok: Scalars['Boolean']['output'];
}

export interface CreateInstagramUGCManagementHashtagInput {
  analyticsAccountId: Scalars['Int']['input'];
  hashtag: Scalars['String']['input'];
}

export interface CreateInstagramUGCManagementHashtagOutput {
  ok: Scalars['Boolean']['output'];
}

export interface CreateInstagramUserHashtagInput {
  hashtag: Scalars['String']['input'];
}

export interface CreateInstagramUserHashtagOutput {
  ok: Scalars['Boolean']['output'];
}

export interface CreateLineBroadcastMessageInput {
  deliveryTiming: DeliveryTimingItemInput;
  lineContent: Array<LineContentItemInput>;
  recipient: RecipientItemInput;
  title: Scalars['String']['input'];
}

export interface CreateLineBroadcastMessagePayload {
  messageId: Scalars['String']['output'];
}

export interface CreateLineChannelInput {
  liffId: Scalars['String']['input'];
  lineChannelId: Scalars['String']['input'];
  lineChannelSecret: Scalars['String']['input'];
  lineKid: Scalars['String']['input'];
  lineLoginChannelId: Scalars['String']['input'];
  publicKeyId: Scalars['Long']['input'];
}

export interface CreateLineChannelPayload {
  ok: Scalars['Boolean']['output'];
}

export interface CreateMarketplace {
  ok: Scalars['Boolean']['output'];
}

export interface CreateMarketplaceDraftPost {
  ok: Scalars['Boolean']['output'];
}

export interface CreateMarketplaceDraftPostInput {
  campaignId: Scalars['Int']['input'];
  caption: Scalars['String']['input'];
  materialsUrl: Array<Scalars['String']['input']>;
}

export interface CreateMarketplaceInput {
  additionalRequirement?: InputMaybe<Scalars['String']['input']>;
  advertiserId: Scalars['Int']['input'];
  agencyMarginRate?: InputMaybe<Scalars['Float']['input']>;
  allowNewInfluencer: Scalars['Boolean']['input'];
  brandName?: InputMaybe<Scalars['String']['input']>;
  budget: Scalars['Float']['input'];
  campaignCategoryId: Scalars['Int']['input'];
  campaignType: MarketplaceCampaignDetailType;
  campaignUrl?: InputMaybe<Scalars['String']['input']>;
  categoryIds: Array<Scalars['Int']['input']>;
  clickUrl?: InputMaybe<Scalars['String']['input']>;
  /** for TUNE Tracking */
  costPerAction?: InputMaybe<Scalars['Float']['input']>;
  /** for TUNE Tracking */
  costPerClick?: InputMaybe<Scalars['Float']['input']>;
  costPerComment?: InputMaybe<Scalars['Float']['input']>;
  costPerFollower?: InputMaybe<Scalars['Float']['input']>;
  costPerLike?: InputMaybe<Scalars['Float']['input']>;
  /** for referral code Tracking */
  costPerOrder?: InputMaybe<Scalars['Float']['input']>;
  costPerPost?: InputMaybe<Scalars['Float']['input']>;
  /** for referral code Tracking */
  costPerSaleReferral?: InputMaybe<Scalars['Float']['input']>;
  /** for TUNE Tracking */
  costPerSaleTune?: InputMaybe<Scalars['Float']['input']>;
  costPerShare?: InputMaybe<Scalars['Float']['input']>;
  costPerView?: InputMaybe<Scalars['Float']['input']>;
  countryId: Scalars['String']['input'];
  couponUploadFiles?: InputMaybe<Array<Scalars['String']['input']>>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  genders: Array<Genders>;
  hashtags: Array<Scalars['String']['input']>;
  hubspotDealIds?: InputMaybe<Array<Scalars['String']['input']>>;
  influencerManagementPicIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  isAllowMultiplePosts?: InputMaybe<Scalars['Boolean']['input']>;
  isAutoInfluencerApproval?: InputMaybe<Scalars['Boolean']['input']>;
  isGiftingCampaign?: InputMaybe<Scalars['Boolean']['input']>;
  landingPageUrl?: InputMaybe<Scalars['String']['input']>;
  marginRate: Scalars['Float']['input'];
  materialUrl?: InputMaybe<Scalars['String']['input']>;
  materials?: InputMaybe<Array<Scalars['String']['input']>>;
  maxAge: Scalars['Int']['input'];
  maxFollowers: Scalars['Int']['input'];
  maximumRevenuePerInfluencer?: InputMaybe<Scalars['Float']['input']>;
  minAge: Scalars['Int']['input'];
  minFollowers: Scalars['Int']['input'];
  mkpServiceCampaignId?: InputMaybe<Scalars['Int']['input']>;
  preLaunchDate?: InputMaybe<Scalars['Date']['input']>;
  productThumbNail?: InputMaybe<Scalars['String']['input']>;
  regionIds: Array<Scalars['Int']['input']>;
  requireDraftPost?: InputMaybe<Scalars['Boolean']['input']>;
  requirement: Scalars['String']['input'];
  salesPicIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  sampleUrl?: InputMaybe<Scalars['String']['input']>;
  serviceInformation: Scalars['String']['input'];
  socialMedia: CampaignSocialMediaType;
  startDate: Scalars['Date']['input'];
  thumbnails: Array<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  trackingOptions?: InputMaybe<Array<CampaignTrackingOption>>;
}

export interface CreateMobileAppVersion {
  ok: Scalars['Boolean']['output'];
}

export interface CreateMobileAppVersionInput {
  /** for ios */
  buildNumber: Scalars['String']['input'];
  /** semantic versioning */
  version: Scalars['String']['input'];
  /** for Android */
  versionCode: Scalars['String']['input'];
}

export interface CreateOpenAIThread {
  threadId: Scalars['Int']['output'];
}

export interface CreatePackage {
  ok: Scalars['Boolean']['output'];
}

export interface CreatePackageInput {
  companyAndRolePairs: Array<CompanyAndRolePair>;
  countryId: Scalars['String']['input'];
  name: Scalars['String']['input'];
}

export interface CreatePackageWithInfluencerAccounts {
  ok: Scalars['Boolean']['output'];
}

export interface CreatePackageWithInfluencerAccountsInput {
  companyAndRolePairs: Array<CompanyAndRolePair>;
  countryId: Scalars['String']['input'];
  influencerIds: Array<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
}

export interface CreatePackageWithSocialAccounts {
  ok: Scalars['Boolean']['output'];
}

export interface CreatePackageWithSocialAccountsInput {
  companyAndRolePairs: Array<CompanyAndRolePair>;
  countryId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  socialPairs?: InputMaybe<Array<SocialAccountPairInput>>;
}

export interface CreatePackageWithUserProposal {
  ok: Scalars['Boolean']['output'];
}

export interface CreatePackageWithUserProposalInput {
  companyAndRolePairs: Array<CompanyAndRolePair>;
  countryId: Scalars['String']['input'];
  name: Scalars['String']['input'];
}

export interface CreatePartnerUser {
  ok: Scalars['Boolean']['output'];
}

export interface CreatePartnerUserInput {
  countryId: Scalars['String']['input'];
  email: Scalars['String']['input'];
  hasInvitationEmail: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  /** Partner Agency Id */
  partnerId: Scalars['Int']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<CustomPermissionsInput>;
}

export interface CreateSelfRegisteredAdvertiserUser {
  ok: Scalars['Boolean']['output'];
}

export interface CreateSelfRegisteredAdvertiserUserInput {
  advertiserCompanyName: Scalars['String']['input'];
  advertiserCompanyUrl: Scalars['String']['input'];
  advertiserPhoneNumber: Scalars['String']['input'];
  advertiserType?: InputMaybe<AdvertiserType>;
  /** Represents country of Advertiser and its User */
  countryId: Scalars['String']['input'];
  userEmail: Scalars['String']['input'];
  userName: Scalars['String']['input'];
}

export interface CreateSocialAccountAnalyticsAdminInputV2 {
  accountManagersPICs?: InputMaybe<Array<Scalars['Int']['input']>>;
  accountName: Scalars['String']['input'];
  advertiserId: Scalars['Int']['input'];
  facebookPageId?: InputMaybe<Scalars['String']['input']>;
  instagramUserId?: InputMaybe<Scalars['String']['input']>;
  twitterUserId?: InputMaybe<Scalars['String']['input']>;
  youtubeChannelId?: InputMaybe<Scalars['String']['input']>;
}

export interface CreateSocialAccountAnalyticsAdminOutputV2 {
  ok: Scalars['Boolean']['output'];
}

export interface CreateSocialAccountAnalyticsAdvertiserInputV2 {
  accountName: Scalars['String']['input'];
  facebookPageId?: InputMaybe<Scalars['String']['input']>;
  instagramUserId?: InputMaybe<Scalars['String']['input']>;
  twitterUserId?: InputMaybe<Scalars['String']['input']>;
  youtubeChannelId?: InputMaybe<Scalars['String']['input']>;
}

export interface CreateSocialAccountAnalyticsAdvertiserOutputV2 {
  ok: Scalars['Boolean']['output'];
}

export interface CreateStaff {
  ok: Scalars['Boolean']['output'];
}

export interface CreateStaffInput {
  advertiserIds: Array<Scalars['Int']['input']>;
  autoLinkToAdvertiser: Scalars['Boolean']['input'];
  countryId: Scalars['String']['input'];
  email: Scalars['String']['input'];
  hasInvitationEmail: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<CustomPermissionsInput>;
}

export interface CreateTalentAgencyUser {
  ok: Scalars['Boolean']['output'];
}

export interface CreateTalentAgencyUserInput {
  countryId: Scalars['String']['input'];
  email: Scalars['String']['input'];
  hasInvitationEmail: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<CustomPermissionsInput>;
  /** Talent Agency Id */
  talentAgencyId: Scalars['Int']['input'];
}

export interface CreateTalentAgencyWithUser {
  ok: Scalars['Boolean']['output'];
}

export interface CreateTalentAgencyWithUserInput {
  advertiserIds: Array<Scalars['Int']['input']>;
  agencyIds: Array<Scalars['Int']['input']>;
  cmsMarginRate?: InputMaybe<Scalars['Float']['input']>;
  companyUrl?: InputMaybe<Scalars['String']['input']>;
  countryId: Scalars['String']['input'];
  email: Scalars['String']['input'];
  hasInvitationEmail: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  netsuiteId?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  talentAgencyName: Scalars['String']['input'];
}

export interface CreateTalentInfluencerInput {
  categoryIds: Array<Scalars['Int']['input']>;
  countryId: Scalars['String']['input'];
  dateOfBirth: Scalars['String']['input'];
  defaultEngagementSelectionReason?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  gender: Genders;
  internalMemo?: InputMaybe<Scalars['String']['input']>;
  introduce?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  rateCards?: InputMaybe<RateCardsInput>;
  regionId: Scalars['Int']['input'];
  selectedSocialAccounts: SelectedSocialAccounts;
  tags: Array<Scalars['String']['input']>;
}

export interface CreateTalentInfluencerPayload {
  ok: Scalars['Boolean']['output'];
}

export interface CreateTikTokSpecialCampaign {
  id: Scalars['Int']['output'];
}

export interface CreateTikTokSpecialCampaignInput {
  campaignCategoryId?: InputMaybe<Scalars['Int']['input']>;
  /** nullable for draft */
  endDate?: InputMaybe<Scalars['Date']['input']>;
  hashtags: Array<Scalars['String']['input']>;
  isDraft: Scalars['Boolean']['input'];
  materialUrl?: InputMaybe<Scalars['String']['input']>;
  materials: Array<Scalars['String']['input']>;
  preLaunchDate?: InputMaybe<Scalars['Date']['input']>;
  productUrl?: InputMaybe<Scalars['String']['input']>;
  requirement: Scalars['String']['input'];
  sampleUrl?: InputMaybe<Scalars['String']['input']>;
  serviceInformation: Scalars['String']['input'];
  /** nullable for draft */
  startDate?: InputMaybe<Scalars['Date']['input']>;
  thumbnails: Array<Scalars['String']['input']>;
  title: Scalars['String']['input'];
}

export interface CreateTiktokAdAccount {
  ok: Scalars['Boolean']['output'];
}

export interface CreateTiktokAdAccountInput {
  bcId: Scalars['String']['input'];
  company: Scalars['String']['input'];
  industryId: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  promotionLink: Scalars['String']['input'];
  registeredArea: Scalars['String']['input'];
  timezone: Scalars['String']['input'];
}

export interface CreateTiktokBusinessCenter {
  ok: Scalars['Boolean']['output'];
}

export interface CreateTiktokBusinessCenterInput {
  name: Scalars['String']['input'];
  timezone: Scalars['String']['input'];
}

export interface CreateTiktokUserHashtagInput {
  hashtag: Scalars['String']['input'];
}

export interface CreateTiktokUserHashtagOutput {
  ok: Scalars['Boolean']['output'];
}

export interface CreateTiktokUserKeywordInput {
  keyword: Scalars['String']['input'];
}

export interface CreateTiktokUserKeywordOutput {
  ok: Scalars['Boolean']['output'];
}

export interface CreatorAddressPayload {
  address: Scalars['String']['output'];
  address2: Scalars['String']['output'];
  city: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  postalCode: Scalars['String']['output'];
  province: Scalars['String']['output'];
}

export interface CreatorStaff {
  email: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
}

export interface CreatorStaffsCountPayload {
  total: Scalars['Int']['output'];
}

export interface CreatorStaffsInput {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
}

export interface CreatorStaffsPayload {
  avatar: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  staffs: Array<CreatorStaff>;
}

export interface CurrentUserCurrency {
  currency: Scalars['String']['output'];
}

export interface CustomPermissionLevel {
  manage?: Maybe<Scalars['Boolean']['output']>;
  manageAndDelete?: Maybe<Scalars['Boolean']['output']>;
  noView?: Maybe<Scalars['Boolean']['output']>;
  view?: Maybe<Scalars['Boolean']['output']>;
}

export interface CustomPermissionLevelInput {
  manage?: InputMaybe<Scalars['Boolean']['input']>;
  manageAndDelete?: InputMaybe<Scalars['Boolean']['input']>;
  noView?: InputMaybe<Scalars['Boolean']['input']>;
  view?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface CustomPermissions {
  advertiser?: Maybe<CustomPermissionLevel>;
  agency?: Maybe<CustomPermissionLevel>;
  analytics?: Maybe<CustomPermissionLevel>;
  customizeUserPermissions?: Maybe<CustomPermissionLevel>;
  engagement?: Maybe<CustomPermissionLevel>;
  influencer?: Maybe<CustomPermissionLevel>;
  influencerAddressAndPaymentInformation?: Maybe<CustomPermissionLevel>;
  influencerPackage?: Maybe<CustomPermissionLevel>;
  marketplace?: Maybe<CustomPermissionLevel>;
  partnerAgency?: Maybe<CustomPermissionLevel>;
  payment?: Maybe<CustomPermissionLevel>;
  report?: Maybe<CustomPermissionLevel>;
  talentAgency?: Maybe<CustomPermissionLevel>;
  user?: Maybe<CustomPermissionLevel>;
}

export interface CustomPermissionsInput {
  advertiser?: InputMaybe<CustomPermissionLevelInput>;
  agency?: InputMaybe<CustomPermissionLevelInput>;
  analytics?: InputMaybe<CustomPermissionLevelInput>;
  customizeUserPermissions?: InputMaybe<CustomPermissionLevelInput>;
  engagement?: InputMaybe<CustomPermissionLevelInput>;
  influencer?: InputMaybe<CustomPermissionLevelInput>;
  influencerAddressAndPaymentInformation?: InputMaybe<CustomPermissionLevelInput>;
  influencerPackage?: InputMaybe<CustomPermissionLevelInput>;
  marketplace?: InputMaybe<CustomPermissionLevelInput>;
  partnerAgency?: InputMaybe<CustomPermissionLevelInput>;
  payment?: InputMaybe<CustomPermissionLevelInput>;
  report?: InputMaybe<CustomPermissionLevelInput>;
  talentAgency?: InputMaybe<CustomPermissionLevelInput>;
  user?: InputMaybe<CustomPermissionLevelInput>;
}

export enum CustomerTagOrder {
  BY_ALPHABETICAL = 'BY_ALPHABETICAL',
  BY_FREQUENTLY_USED = 'BY_FREQUENTLY_USED',
}

export interface CustomerTagPayload {
  name: Scalars['String']['output'];
}

export interface DeleteAdvertiser {
  ok: Scalars['Boolean']['output'];
}

export interface DeleteAgency {
  ok: Scalars['Boolean']['output'];
}

export interface DeleteBrandAccountAnalyticsInput {
  brandAccountId: Scalars['Int']['input'];
}

export interface DeleteBrandAccountAnalyticsOutput {
  ok: Scalars['Boolean']['output'];
}

export interface DeleteCommentInput {
  id: Scalars['Long']['input'];
}

export interface DeleteCommentPayload {
  ok: Scalars['Boolean']['output'];
}

export interface DeleteCompareInstagramAccountAnalytics {
  ok: Scalars['Boolean']['output'];
}

export interface DeleteEngagement {
  ok: Scalars['Boolean']['output'];
}

export interface DeleteEngagementInfluencerProposal {
  ok: Scalars['Boolean']['output'];
}

export interface DeleteEngagementInfluencerProposalInput {
  campaignId: Scalars['Int']['input'];
  influencerIds: Array<Scalars['Int']['input']>;
}

export interface DeleteEngagementPostForInfluencer {
  ok: Scalars['Boolean']['output'];
}

export interface DeleteEngagementProposalJoinedAccount {
  ok: Scalars['Boolean']['output'];
}

export interface DeleteEngagementProposalJoinedAccountInput {
  campaignId: Scalars['Int']['input'];
  influencerId: Scalars['Int']['input'];
  socialAccountId: Scalars['Int']['input'];
  socialAccountMedia: EngagementProposalSocialMediaType;
}

export interface DeleteEngagementSocialAccountProposal {
  ok: Scalars['Boolean']['output'];
}

export interface DeleteEngagementSocialAccountProposalInput {
  campaignId: Scalars['Int']['input'];
  influencerIds: Array<Scalars['Int']['input']>;
  socialAccountIds: Array<Scalars['Int']['input']>;
  socialAccountMedia: SocialAccountType;
}

export interface DeleteFanTagsInput {
  fanIds: Array<Scalars['Long']['input']>;
  tagIds: Array<Scalars['Long']['input']>;
}

export interface DeleteFanTagsPayload {
  ok: Scalars['Boolean']['output'];
}

export interface DeleteGeminiThread {
  ok: Scalars['Boolean']['output'];
}

export interface DeleteGeminiThreadInput {
  /** thread system id */
  id: Scalars['Int']['input'];
}

export interface DeleteInfluencer {
  ok: Scalars['Boolean']['output'];
}

export interface DeleteInfluencerPackage {
  ok: Scalars['Boolean']['output'];
}

export interface DeleteInstagramCompareAccountAnalyticsInput {
  /** system id of a compare account that we want to delete */
  compareAccountId: Scalars['Int']['input'];
  /** an analytics account system id the selected compare account was connected to */
  mainAnalyticsAccountId: Scalars['Int']['input'];
}

export interface DeleteInstagramHashtagAnalytics {
  ok: Scalars['Boolean']['output'];
}

export interface DeleteInstagramHashtagAnalyticsInput {
  /** an analytics account system id the selected hashtag was connected to */
  analyticsAccountId: Scalars['Int']['input'];
  /** a hashtag's system id that we want to delete */
  hashtagId: Scalars['Int']['input'];
}

export interface DeleteInstagramUGCManagementHashtagInput {
  analyticsAccountId: Scalars['Int']['input'];
  hashtagSystemId: Scalars['Int']['input'];
}

export interface DeleteInstagramUGCManagementHashtagOutput {
  ok: Scalars['Boolean']['output'];
}

export interface DeleteInstagramUserHashtagInput {
  hashtagId: Scalars['Long']['input'];
}

export interface DeleteInstagramUserHashtagOutput {
  ok: Scalars['Boolean']['output'];
}

export interface DeleteMarketplace {
  ok: Scalars['Boolean']['output'];
}

export interface DeleteOpenAIThread {
  ok: Scalars['Boolean']['output'];
}

export interface DeleteOpenAIThreadInput {
  /** thread system id */
  id: Scalars['Int']['input'];
}

export interface DeletePackage {
  ok: Scalars['Boolean']['output'];
}

export interface DeleteSocialAccountPackage {
  ok: Scalars['Boolean']['output'];
}

export interface DeleteTalentAgency {
  ok: Scalars['Boolean']['output'];
}

export interface DeleteTikTokSpecialCampaign {
  ok: Scalars['Boolean']['output'];
}

export interface DeleteTikTokSpecialCampaignInput {
  campaignId: Scalars['Int']['input'];
}

export interface DeleteTiktokUserHashtagInput {
  hashtagId: Scalars['Long']['input'];
}

export interface DeleteTiktokUserHashtagOutput {
  ok: Scalars['Boolean']['output'];
}

export interface DeleteTiktokUserKeywordInput {
  keywordId: Scalars['Long']['input'];
}

export interface DeleteTiktokUserKeywordOutput {
  ok: Scalars['Boolean']['output'];
}

export interface DeleteUser {
  ok: Scalars['Boolean']['output'];
}

export interface DeleteUserProposalInfluencer {
  ok: Scalars['Boolean']['output'];
}

export interface DeleteUserProposalInfluencerInput {
  influencerIds: Array<Scalars['Int']['input']>;
}

export interface DeleteUserProposalSocialAccount {
  ok: Scalars['Boolean']['output'];
}

export interface DeleteUserProposalSocialAccountIndividually {
  ok: Scalars['Boolean']['output'];
}

export interface DeleteUserProposalSocialAccountIndividuallyInput {
  influencerIds: Array<Scalars['Int']['input']>;
  socialAccountIds: Array<Scalars['Int']['input']>;
  socialAccountMedia: UserProposalSocialMediaType;
}

export interface DeleteUserProposalSocialAccountInput {
  influencerIds: Array<Scalars['Int']['input']>;
  socialAccountIds: Array<Scalars['Int']['input']>;
  socialAccountMedia: SocialAccountType;
}

export interface DeleteYoutubeCompareAccountAnalyticsInput {
  compareAccountId: Scalars['Int']['input'];
  mainAccountId: Scalars['Int']['input'];
}

export interface DeleteYoutubeCompareAccountAnalyticsOutput {
  ok: Scalars['Boolean']['output'];
}

export interface DeliveryTimingItemInput {
  scheduleDate?: InputMaybe<Scalars['String']['input']>;
  type: DeliveryTimingType;
}

export interface DeliveryTimingItemPayload {
  scheduleDate?: Maybe<Scalars['String']['output']>;
  type: DeliveryTimingType;
}

export enum DeliveryTimingType {
  IMMEDIATELY_SEND = 'IMMEDIATELY_SEND',
  SCHEDULE_SEND = 'SCHEDULE_SEND',
}

export interface DenyEngagementInfluencerProposalList {
  ok: Scalars['Boolean']['output'];
}

export interface DenyEngagementInfluencerProposalListInput {
  campaignId: Scalars['Int']['input'];
  deniedReason: Scalars['String']['input'];
  influencerIds: Array<Scalars['Int']['input']>;
}

export interface DenyInfluencersUserProposal {
  ok: Scalars['Boolean']['output'];
}

export interface DenyInfluencersUserProposalInput {
  influencerIds: Array<Scalars['Int']['input']>;
}

export interface DisconnectPackageFromEngagementProposal {
  ok: Scalars['Boolean']['output'];
}

export interface DisconnectPackageFromEngagementProposalInput {
  engagementCampaignId: Scalars['Int']['input'];
  packageIds: Array<Scalars['Int']['input']>;
}

export interface DisconnectPromotionMethodsInput {
  promotionMethodId: Scalars['ID']['input'];
}

export interface DisconnectPromotionMethodsPayload {
  ok: Scalars['Boolean']['output'];
}

export interface DisconnectTiktokAdAccount {
  ok: Scalars['Boolean']['output'];
}

export interface DisconnectTiktokAdAccountInput {
  adAccountId: Scalars['String']['input'];
}

export enum DisplayLanguage {
  ARABIC = 'ARABIC',
  BAHASA = 'BAHASA',
  CAMBODIA = 'CAMBODIA',
  ENGLISH = 'ENGLISH',
  JAPANESE = 'JAPANESE',
  KOREAN = 'KOREAN',
  MYANMAR = 'MYANMAR',
  SIMPLIFIED_CHINESE = 'SIMPLIFIED_CHINESE',
  THAI = 'THAI',
  TRADITIONAL_CHINESE = 'TRADITIONAL_CHINESE',
  VIETNAMESE = 'VIETNAMESE',
}

export interface DouyinAccountGeneralCategory {
  count: Scalars['Int']['output'];
  detailCategory?: Maybe<Array<DouyinAccountInterestRate>>;
  name: Scalars['String']['output'];
}

export interface DouyinAccountInterest {
  generalCategory?: Maybe<Array<DouyinAccountGeneralCategory>>;
  videoType?: Maybe<Array<DouyinAccountInterestRate>>;
}

export interface DouyinAccountInterestRate {
  name: Scalars['String']['output'];
  rate: Scalars['Float']['output'];
}

export interface DouyinAudienceSection {
  ageRates: TiktokFollowersAgeGroup;
  femaleRate?: Maybe<Scalars['Float']['output']>;
  maleRate?: Maybe<Scalars['Float']['output']>;
  regionRates?: Maybe<Array<DouyinFollowerRegionRate>>;
}

/** An enumeration. */
export enum DouyinDistributionType {
  ANYONE = 'ANYONE',
  FOLLOWER = 'FOLLOWER',
}

export interface DouyinFollowerRegionRate {
  name: Scalars['String']['output'];
  rate: Scalars['Float']['output'];
}

export interface DouyinInfluencer {
  age?: Maybe<Scalars['Int']['output']>;
  avatar?: Maybe<Scalars['String']['output']>;
  averageLikes?: Maybe<Scalars['Float']['output']>;
  averageViews?: Maybe<Scalars['Float']['output']>;
  bio?: Maybe<Scalars['String']['output']>;
  country: CountryName;
  gender: Genders;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  popularPosts: Array<SocialAccountPopularPost>;
  socialAccountEngagement?: Maybe<Scalars['Int']['output']>;
  socialAccountEngagementFollowersRate?: Maybe<Scalars['Float']['output']>;
  socialAccountEngagementViewsRate?: Maybe<Scalars['Float']['output']>;
  socialAccountFollowers?: Maybe<Scalars['Int']['output']>;
  socialAccountId: Scalars['ID']['output'];
  socialAccountStatus: SocialAccountStatus;
}

export interface DouyinInfluencerSearchResult {
  douyinAccounts: Array<DouyinInfluencer>;
  totalNumber: Scalars['Int']['output'];
}

export interface EditSocialAccountAnalyticsAdminInputV2 {
  accountManagersPICs?: InputMaybe<Array<Scalars['Int']['input']>>;
  accountName: Scalars['String']['input'];
  brandAccountId: Scalars['Int']['input'];
  facebookPageId?: InputMaybe<Scalars['String']['input']>;
  igFacebookPageId?: InputMaybe<Scalars['String']['input']>;
  twitterUserId?: InputMaybe<Scalars['String']['input']>;
  youtubeChannelId?: InputMaybe<Scalars['String']['input']>;
}

export interface EditSocialAccountAnalyticsAdminOutputV2 {
  ok: Scalars['Boolean']['output'];
}

export interface EditSocialAccountAnalyticsAdvertiserInputV2 {
  accountName: Scalars['String']['input'];
  brandAccountId: Scalars['Int']['input'];
  facebookPageId?: InputMaybe<Scalars['String']['input']>;
  igFacebookPageId?: InputMaybe<Scalars['String']['input']>;
  twitterUserId?: InputMaybe<Scalars['String']['input']>;
  youtubeChannelId?: InputMaybe<Scalars['String']['input']>;
}

export interface EditSocialAccountAnalyticsAdvertiserOutputV2 {
  ok: Scalars['Boolean']['output'];
}

export enum EmailEventStatus {
  BLOCKED = 'BLOCKED',
  BOUNCE = 'BOUNCE',
  CLICK = 'CLICK',
  DEFERRED = 'DEFERRED',
  DELIVERED = 'DELIVERED',
  DROPPED = 'DROPPED',
  GROUP_RESUBSCRIBE = 'GROUP_RESUBSCRIBE',
  GROUP_UNSUBSCRIBE = 'GROUP_UNSUBSCRIBE',
  OPEN = 'OPEN',
  PROCESSED = 'PROCESSED',
  SPAMREPORT = 'SPAMREPORT',
  UNSUBSCRIBE = 'UNSUBSCRIBE',
}

export enum EmailEventStatusForSearch {
  BOUNCE = 'BOUNCE',
  CLICK = 'CLICK',
  OPEN = 'OPEN',
}

export interface EmailInfo {
  fromEmail: Scalars['String']['output'];
  message: Scalars['String']['output'];
  sentAt: Scalars['String']['output'];
  subject: Scalars['String']['output'];
  tags?: Maybe<Array<Scalars['String']['output']>>;
  toEmails?: Maybe<Array<Scalars['String']['output']>>;
}

export interface EmailInfoForList {
  clickRate?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Long']['output'];
  openRate?: Maybe<Scalars['Float']['output']>;
  scheduleDate?: Maybe<Scalars['String']['output']>;
  sentAt?: Maybe<Scalars['String']['output']>;
  status: EmailTemplateStatus;
  subject: Scalars['String']['output'];
}

export interface EmailListOrderBy {
  field: EmailListSortableField;
  order: Order;
}

export enum EmailListSortableField {
  CLICK_RATE = 'CLICK_RATE',
  CREATED = 'CREATED',
  OPEN_RATE = 'OPEN_RATE',
}

export interface EmailStatistics {
  bounceCount: Scalars['Long']['output'];
  clickCount: Scalars['Long']['output'];
  openCount: Scalars['Long']['output'];
  recipientsCount: Scalars['Long']['output'];
  unsubscribeCount: Scalars['Long']['output'];
}

export enum EmailTemplateStatus {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  SCHEDULED = 'SCHEDULED',
}

export interface Engagement {
  advertiser: AdvertiserName;
  budget: Scalars['Float']['output'];
  countries: Array<CountryName>;
  currency?: Maybe<Scalars['String']['output']>;
  defaultPostRequirement?: Maybe<Scalars['String']['output']>;
  endDate: Scalars['Date']['output'];
  id: Scalars['Int']['output'];
  influencerManagementPics?: Maybe<Array<PicName>>;
  salesPics?: Maybe<Array<PicName>>;
  socialMedias: Array<CampaignSocialMediaType>;
  startDate: Scalars['Date']['output'];
  status: EngagementCampaignStatus;
  title: Scalars['String']['output'];
}

export interface EngagementAddInfluencersToProposalFromAnotherCampaign {
  ok: Scalars['Boolean']['output'];
}

export interface EngagementAddInfluencersToProposalFromAnotherCampaignInput {
  /** Get influencers from campaign */
  fromCampaignId: Scalars['Int']['input'];
  /** Put influencers to campaign */
  toCampaignId: Scalars['Int']['input'];
}

export interface EngagementCampaignCouponTrackingInput {
  campaignId: Scalars['Int']['input'];
  couponCode: Scalars['String']['input'];
  saleAmount: Scalars['Float']['input'];
  transactionId: Scalars['String']['input'];
}

export interface EngagementCampaignCouponTrackingPayload {
  ok: Scalars['Boolean']['output'];
}

export interface EngagementCampaignPostReportAudienceBreakdown {
  tiktok?: Maybe<EngagementCampaignPostReportAudienceBreakdownForTikTok>;
  youtube?: Maybe<EngagementCampaignPostReportAudienceBreakdownForYouTube>;
}

export interface EngagementCampaignPostReportAudienceBreakdownForTikTok {
  ageRates: AudienceBreakdownAgeRates;
  areaRates: Array<AudienceBreakdownAreaRate>;
  genderRates: AudienceBreakdownGenderRates;
}

export interface EngagementCampaignPostReportAudienceBreakdownForYouTube {
  ageGenderRates: AudienceBreakdownAgeGenderRates;
  areaRates: Array<AudienceBreakdownAreaRate>;
  genderRates: AudienceBreakdownGenderRates;
}

export interface EngagementCampaignPostReportForInfluencer {
  currency: Scalars['String']['output'];
  /** campaign ID */
  id: Scalars['Int']['output'];
  postReport: Array<InfluencerPostReportStatsEngagement>;
  /** campaign title */
  title: Scalars['String']['output'];
}

/** An enumeration. */
export enum EngagementCampaignPostStatus {
  APPROVED = 'APPROVED',
  EFFECTIVE = 'EFFECTIVE',
  INEFFECTIVE = 'INEFFECTIVE',
  READY_TO_POST = 'READY_TO_POST',
  REJECT = 'REJECT',
  REVIEWING = 'REVIEWING',
  TTCM_REVIEWING = 'TTCM_REVIEWING',
  WARNING = 'WARNING',
  WARNING_SOLVED = 'WARNING_SOLVED',
}

/** An enumeration. */
export enum EngagementCampaignPostStatusForInsightCheck {
  CONFIRMED = 'CONFIRMED',
  UNCONFIRMED = 'UNCONFIRMED',
}

/** An enumeration. */
export enum EngagementCampaignPostStatusForSearch {
  AD_FRAUD = 'AD_FRAUD',
  API_ERROR = 'API_ERROR',
  APPROVED = 'APPROVED',
  DELETED_POST = 'DELETED_POST',
  EFFECTIVE = 'EFFECTIVE',
  EXPIRED_TOKEN = 'EXPIRED_TOKEN',
  INEFFECTIVE = 'INEFFECTIVE',
  INVALID_CONTENT = 'INVALID_CONTENT',
  JOINED = 'JOINED',
  READY_TO_POST = 'READY_TO_POST',
  REJECT = 'REJECT',
  REVIEWING = 'REVIEWING',
  SCRAPING_ERROR = 'SCRAPING_ERROR',
  WARNING = 'WARNING',
  WARNING_SOLVED = 'WARNING_SOLVED',
}

export interface EngagementCampaignPostsForInsightCheck {
  campaignTitle: Scalars['String']['output'];
  posts: Array<EngagementPostForInsightCheck>;
}

export interface EngagementCampaignReport {
  InternalInfo?: Maybe<Array<Scalars['String']['output']>>;
  average?: Maybe<NewCampaignStatsItemRow>;
  campaignInfo: CampaignReportCampaignInfo;
  chartData: Array<CampaignChartReport>;
  currency: Scalars['String']['output'];
  influencerReports: Array<CampaignInfluencerReport>;
  orderedSummaryDaily?: Maybe<Array<CampaignSummaryDaily>>;
  postCount: Scalars['Int']['output'];
  summary: NewCampaignStatsItemRow;
  summaryDaily?: Maybe<Array<CampaignSummaryDaily>>;
}

export interface EngagementCampaignSearchSelectInfluencers {
  /** Campaign's category */
  category: Scalars['String']['output'];
  /** Campaign's countries */
  countries: Array<Scalars['String']['output']>;
  /** Campaign's engagement rate */
  engagementRate?: Maybe<Scalars['Float']['output']>;
  /** Campaign's id */
  id: Scalars['Int']['output'];
  /** Campaign's name */
  name: Scalars['String']['output'];
  /** Number of selected influencers in the campaign */
  numberOfInfluencers: Scalars['Int']['output'];
}

/** An enumeration. */
export enum EngagementCampaignSocialAccountPostStatus {
  APPROVED = 'APPROVED',
  EFFECTIVE = 'EFFECTIVE',
  INEFFECTIVE = 'INEFFECTIVE',
  JOINED = 'JOINED',
  JOINED_WITH_PENDING_INVITATION = 'JOINED_WITH_PENDING_INVITATION',
  JOINED_WITH_PENDING_ORDER = 'JOINED_WITH_PENDING_ORDER',
  READY_TO_POST = 'READY_TO_POST',
  REJECT = 'REJECT',
  REVIEWING = 'REVIEWING',
  TTCM_REVIEWING = 'TTCM_REVIEWING',
  WARNING = 'WARNING',
  WARNING_SOLVED = 'WARNING_SOLVED',
}

/** An enumeration. */
export enum EngagementCampaignStatus {
  DEMO = 'DEMO',
  DRAFT = 'DRAFT',
  FINISHED = 'FINISHED',
  LOST = 'LOST',
  ONGOING = 'ONGOING',
  REVIEWING = 'REVIEWING',
  UPCOMING = 'UPCOMING',
}

export interface EngagementDetail {
  adAgencyMarginRate?: Maybe<Scalars['Float']['output']>;
  advertiser: AdvertiserName;
  budget: Scalars['Float']['output'];
  campaignCategory?: Maybe<CampaignCategoryName>;
  campaignCreator?: Maybe<CampaignCreator>;
  categories: Array<CategoryName>;
  countries: Array<CountryName>;
  couponList?: Maybe<Scalars['String']['output']>;
  couponUploadFiles?: Maybe<Array<Scalars['String']['output']>>;
  currency?: Maybe<Scalars['String']['output']>;
  defaultPostRequirement?: Maybe<Scalars['String']['output']>;
  endDate: Scalars['Date']['output'];
  genders: Array<Genders>;
  hashtags?: Maybe<Array<Scalars['String']['output']>>;
  hubspotDealIds?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['Int']['output'];
  influencerManagementPics?: Maybe<Array<PicName>>;
  isTtcmCampaign: Scalars['Boolean']['output'];
  landingPageUrl?: Maybe<Scalars['String']['output']>;
  marginRate?: Maybe<Scalars['Float']['output']>;
  materialUrl?: Maybe<Scalars['String']['output']>;
  materials?: Maybe<Array<Scalars['String']['output']>>;
  maxAge: Scalars['Int']['output'];
  maxFollowers: Scalars['Int']['output'];
  minAge: Scalars['Int']['output'];
  minFollowers: Scalars['Int']['output'];
  numberOfInfluencers?: Maybe<Scalars['Int']['output']>;
  objective: Scalars['String']['output'];
  reportStartDate?: Maybe<Scalars['Date']['output']>;
  salesPics?: Maybe<Array<PicName>>;
  serviceInformation: Scalars['String']['output'];
  serviceUrl?: Maybe<Scalars['String']['output']>;
  socialMedias: Array<CampaignSocialMediaType>;
  startDate: Scalars['Date']['output'];
  status: EngagementCampaignStatus;
  thumbNail?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  trackingOptions?: Maybe<Array<CampaignTrackingOption>>;
}

export interface EngagementDetailForInfluencer {
  /** campaign status using for AnyX */
  anyXStatus: AnyXEngagementCampaignStatus;
  couponCode?: Maybe<Scalars['String']['output']>;
  createdDate: Scalars['Date']['output'];
  currency: Scalars['String']['output'];
  endDate?: Maybe<Scalars['Date']['output']>;
  hasDraftPosts: Scalars['Boolean']['output'];
  /** Whether she uploaded her post on social media or not. */
  hasReport: Scalars['Boolean']['output'];
  hasWarningPosts: Scalars['Boolean']['output'];
  hashtags: Array<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  isCouponCampaign?: Maybe<Scalars['Boolean']['output']>;
  isJoined: Scalars['Boolean']['output'];
  isTtcmCampaign: Scalars['Boolean']['output'];
  isTtcmReady?: Maybe<Scalars['Boolean']['output']>;
  materialUrl?: Maybe<Scalars['String']['output']>;
  materials?: Maybe<Array<MaterialName>>;
  objective?: Maybe<Scalars['String']['output']>;
  /** @deprecated Deprecated since new Influencer app */
  postStatus?: Maybe<CampaignPostStatusForInfluencer>;
  reason?: Maybe<Scalars['String']['output']>;
  requirements?: Maybe<Scalars['String']['output']>;
  /** influencer revenue */
  rewardAmount: Scalars['Float']['output'];
  serviceInformation: Scalars['String']['output'];
  serviceUrl?: Maybe<Scalars['String']['output']>;
  socialMedias: Array<CampaignSocialMediaType>;
  startDate: Scalars['Date']['output'];
  status: CampaignStatusForInfluencer;
  thumbNail: Scalars['String']['output'];
  title: Scalars['String']['output'];
  trackingUrl?: Maybe<Scalars['String']['output']>;
  ttcmCampaignCode?: Maybe<Scalars['String']['output']>;
  ttcmStatus?: Maybe<TTCMStatus>;
  type: CampaignType;
}

export interface EngagementDraftPostHistory {
  /** Influencer info */
  engagementPostDetailInfluencer: EngagementPostDetailInfluencer;
  /** List of rejected post history */
  historyRecords?: Maybe<Array<EngagementDraftPostHistoryRecord>>;
}

export interface EngagementDraftPostHistoryForInfluencer {
  /** List of rejected post history */
  historyRecords?: Maybe<Array<EngagementDraftPostHistoryRecordForInfluencer>>;
}

export interface EngagementDraftPostHistoryRecord {
  /** History date */
  createdDate: Scalars['Date']['output'];
  /** Reason detail */
  detailedReason?: Maybe<Scalars['String']['output']>;
  /** History record id */
  id?: Maybe<Scalars['Int']['output']>;
  images?: Maybe<Array<Scalars['String']['output']>>;
  /** Draft post content */
  postContent: Scalars['String']['output'];
  /** History post status */
  postStatus?: Maybe<EngagementCampaignPostStatus>;
  /** Reason for report */
  reason?: Maybe<Scalars['String']['output']>;
}

export interface EngagementDraftPostHistoryRecordForInfluencer {
  /** History date */
  createdDate: Scalars['Date']['output'];
  /** Reason detail */
  detailedReason?: Maybe<Scalars['String']['output']>;
  /** History record id */
  id?: Maybe<Scalars['Int']['output']>;
  /** History post status */
  postStatus?: Maybe<EngagementCampaignPostStatus>;
  /** Reason for report */
  reason?: Maybe<Scalars['String']['output']>;
}

export interface EngagementForInsightCheck {
  countries: Array<CountryName>;
  endDate: Scalars['Date']['output'];
  id: Scalars['Int']['output'];
  startDate: Scalars['Date']['output'];
  status: EngagementCampaignPostStatusForInsightCheck;
  title: Scalars['String']['output'];
  totalConfirmations: Scalars['Int']['output'];
  totalSubmissions: Scalars['Int']['output'];
}

export interface EngagementInfluencerBudgetInput {
  influencerCost: Scalars['Float']['input'];
  influencerId: Scalars['Int']['input'];
  netBudget: Scalars['Float']['input'];
  profit: Scalars['Float']['input'];
}

export interface EngagementPost {
  content?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  influencer: EngagementPostInfluencer;
  planedPostDate?: Maybe<Scalars['Date']['output']>;
  postUrl?: Maybe<Scalars['String']['output']>;
  postedDate?: Maybe<Scalars['Date']['output']>;
  socialAccountId?: Maybe<Scalars['Int']['output']>;
  /** support Tiktok for now */
  socialAccountStatus?: Maybe<SocialAccountStatus>;
  socialMedia: CampaignSocialMediaType;
  status?: Maybe<EngagementCampaignSocialAccountPostStatus>;
  thumbNail?: Maybe<Scalars['String']['output']>;
  warningReason?: Maybe<WarningReason>;
}

export interface EngagementPostComment {
  commentedDate?: Maybe<Scalars['DateTime']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  username?: Maybe<Scalars['String']['output']>;
}

export interface EngagementPostDetail {
  caption: Scalars['String']['output'];
  comments?: Maybe<Scalars['Int']['output']>;
  detailedReason?: Maybe<Scalars['String']['output']>;
  engagementPostDetailInfluencer: EngagementPostDetailInfluencer;
  id: Scalars['Int']['output'];
  images?: Maybe<Array<Scalars['String']['output']>>;
  likes?: Maybe<Scalars['Int']['output']>;
  postUrl?: Maybe<Scalars['String']['output']>;
  postedDate?: Maybe<Scalars['Date']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  saves?: Maybe<Scalars['Int']['output']>;
  shares?: Maybe<Scalars['Int']['output']>;
  socialMedia: CampaignSocialMediaType;
  status?: Maybe<EngagementCampaignPostStatus>;
  thumbNail?: Maybe<Scalars['String']['output']>;
  views?: Maybe<Scalars['Int']['output']>;
  warningReason?: Maybe<WarningReason>;
}

export interface EngagementPostDetailForInfluencer {
  /** Represents draft post or posted post content */
  caption: Scalars['String']['output'];
  engagementPostDetailInfluencer: EngagementPostDetailInfluencer;
  id: Scalars['Int']['output'];
  images?: Maybe<Array<Scalars['String']['output']>>;
  postedDate?: Maybe<Scalars['Date']['output']>;
  socialMedia: CampaignSocialMediaType;
}

export interface EngagementPostDetailForInfluencerV2 {
  content: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  /** represents warning/reject detail reason */
  inappropriateDetailedReason?: Maybe<Scalars['String']['output']>;
  /** represents warning/reject reason */
  inappropriateReason?: Maybe<Scalars['String']['output']>;
  materials?: Maybe<Array<MaterialName>>;
  planedPostDate: Scalars['Date']['output'];
  postStatus: EngagementCampaignPostStatus;
  /** represents selected social account system id */
  socialAccountId?: Maybe<Scalars['Int']['output']>;
  socialAccountMedia?: Maybe<SocialAccountType>;
  socialAccountName?: Maybe<Scalars['String']['output']>;
  /** represents campaign social media type */
  socialMedia: CampaignSocialMediaType;
}

export interface EngagementPostDetailInfluencer {
  avatar: Scalars['String']['output'];
  followersCount: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
}

export interface EngagementPostEditV2 {
  content?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  influencer: EngagementPostInfluencerWithSocialAccount;
  insightDataAcquisition?: Maybe<Scalars['Date']['output']>;
  insightUrls?: Maybe<Array<Scalars['String']['output']>>;
  materials?: Maybe<Array<MaterialName>>;
  planedPostDate?: Maybe<Scalars['Date']['output']>;
  postUrl?: Maybe<Scalars['String']['output']>;
  postedDate?: Maybe<Scalars['Date']['output']>;
  /** represents campaign social media type */
  socialMedia: CampaignSocialMediaType;
  status?: Maybe<EngagementCampaignPostStatus>;
}

export interface EngagementPostForInfluencer {
  /** campaign status using for AnyX */
  anyXStatus: AnyXEngagementCampaignStatus;
  content: Scalars['String']['output'];
  /** Post Id */
  id: Scalars['Int']['output'];
  /** represents reason details */
  inappropriateDetailedReason?: Maybe<Scalars['String']['output']>;
  /** represents reason title */
  inappropriateReason?: Maybe<Scalars['String']['output']>;
  /** currently for mobile */
  isPreviouslyModified: Scalars['Boolean']['output'];
  planedPostDate: Scalars['Date']['output'];
  postedDate?: Maybe<Scalars['Date']['output']>;
  socialMedia: CampaignSocialMediaType;
  status: EngagementCampaignPostStatus;
  thumbNail?: Maybe<Scalars['String']['output']>;
  ttcmInviteLink?: Maybe<Scalars['String']['output']>;
}

export interface EngagementPostForInsightCheck {
  id: Scalars['Int']['output'];
  /** Influencer ID */
  influencerId: Scalars['Int']['output'];
  /** Influencer Name */
  influencerName: Scalars['String']['output'];
  /** Draft OR Posted content */
  postContent: Scalars['String']['output'];
  socialMedia: CampaignSocialMediaType;
  status: EngagementCampaignPostStatusForInsightCheck;
}

export interface EngagementPostInfluencer {
  avatar: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
}

export interface EngagementPostInfluencerWithSocialAccount {
  /** represents influencer id */
  id: Scalars['Int']['output'];
  /** represents influencer name */
  name: Scalars['String']['output'];
  /** represents selected social account system id */
  socialAccountId?: Maybe<Scalars['Int']['output']>;
  socialAccountMedia?: Maybe<SocialAccountType>;
  socialAccountName?: Maybe<Scalars['String']['output']>;
}

export interface EngagementPostInfluencerWithSocialAccounts {
  /** Influencer Id */
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  socialAccounts: Array<EngagementPostSocialAccount>;
}

export interface EngagementPostMetricsDataForInsightCheck {
  audienceRetention?: Maybe<Scalars['Float']['output']>;
  averageViewDurationHours?: Maybe<Scalars['Float']['output']>;
  averageViewDurationMinutes?: Maybe<Scalars['Float']['output']>;
  back?: Maybe<Scalars['Int']['output']>;
  comments?: Maybe<Scalars['Int']['output']>;
  exited?: Maybe<Scalars['Int']['output']>;
  followerReach?: Maybe<Scalars['Int']['output']>;
  /** for Douyin post */
  followersCount?: Maybe<Scalars['Int']['output']>;
  forward?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  impressions?: Maybe<Scalars['Int']['output']>;
  impressionsClickThroughRate?: Maybe<Scalars['Float']['output']>;
  impressionsFromDiscovery?: Maybe<Scalars['Int']['output']>;
  impressionsFromHashtag?: Maybe<Scalars['Int']['output']>;
  impressionsFromHome?: Maybe<Scalars['Int']['output']>;
  impressionsFromOther?: Maybe<Scalars['Int']['output']>;
  impressionsFromProfile?: Maybe<Scalars['Int']['output']>;
  insightUrls?: Maybe<Array<Scalars['String']['output']>>;
  interaction?: Maybe<Scalars['Int']['output']>;
  likes?: Maybe<Scalars['Int']['output']>;
  linkClicks?: Maybe<Scalars['Int']['output']>;
  navigation?: Maybe<Scalars['Int']['output']>;
  newFollowers?: Maybe<Scalars['Int']['output']>;
  nextStory?: Maybe<Scalars['Int']['output']>;
  nonFollowerReach?: Maybe<Scalars['Int']['output']>;
  /** Draft OR Posted content */
  postContent: Scalars['String']['output'];
  profileActivity?: Maybe<Scalars['Int']['output']>;
  reaches?: Maybe<Scalars['Int']['output']>;
  saves?: Maybe<Scalars['Int']['output']>;
  shares?: Maybe<Scalars['Int']['output']>;
  socialMedia: CampaignSocialMediaType;
  status: EngagementCampaignPostStatusForInsightCheck;
  stickerTaps?: Maybe<Scalars['Int']['output']>;
  views?: Maybe<Scalars['Int']['output']>;
}

export interface EngagementPostSocialAccount {
  /** Represents social account system id */
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  socialMedia: SocialAccountType;
  status: SocialAccountStatus;
  ttcmCampaignInviteLink?: Maybe<Scalars['String']['output']>;
  ttcmStatus?: Maybe<TTCMStatus>;
  username?: Maybe<Scalars['String']['output']>;
}

export interface EngagementPostTrafficSource {
  estimatedMinutesWatched?: Maybe<Scalars['Float']['output']>;
  sourceType: YoutubeTrafficSource;
  views: Scalars['Int']['output'];
}

export interface EngagementProposal {
  activeTtcmCreatorsCount?: Maybe<Scalars['Int']['output']>;
  couponCodeCount?: Maybe<Scalars['Int']['output']>;
  /** Engagement Id */
  id: Scalars['Int']['output'];
  influencers: Array<ProposalInfluencer>;
  jdMode: ProposalJobDescriptionBreakDownMode;
  summary: ProposalJobSummary;
}

/** An enumeration. */
export enum EngagementProposalSocialMediaType {
  DOUYIN = 'DOUYIN',
  FACEBOOK = 'FACEBOOK',
  FACEBOOK_PAGE = 'FACEBOOK_PAGE',
  INSTAGRAM = 'INSTAGRAM',
  INSTAGRAM_STORY = 'INSTAGRAM_STORY',
  THREADS = 'THREADS',
  TIKTOK = 'TIKTOK',
  TWITTER = 'TWITTER',
  XHS = 'XHS',
  YOUTUBE = 'YOUTUBE',
}

export interface EngagementSocialAcccountBudgetInput {
  influencerCost: Scalars['Float']['input'];
  influencerId: Scalars['Int']['input'];
  netBudget: Scalars['Float']['input'];
  profit: Scalars['Float']['input'];
  socialAccountId: Scalars['Int']['input'];
  socialAccountMedia: EngagementProposalSocialMediaType;
}

export interface EngagementTracking {
  /** only for Pixel tracking campaign */
  pixelCode?: Maybe<Scalars['String']['output']>;
  /** only for postback URL campaign */
  postbackUrl?: Maybe<Scalars['String']['output']>;
}

export interface EngagementXhsPostMetricsDataForInsight {
  age45UpPercentage?: Maybe<Scalars['Float']['output']>;
  age1824Percentage?: Maybe<Scalars['Float']['output']>;
  age2534Percentage?: Maybe<Scalars['Float']['output']>;
  age3544Percentage?: Maybe<Scalars['Float']['output']>;
  ageUp17Percentage?: Maybe<Scalars['Float']['output']>;
  averageViewDurationSeconds?: Maybe<Scalars['Int']['output']>;
  comments?: Maybe<Scalars['Int']['output']>;
  femalePercentage?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Int']['output'];
  insightUrls?: Maybe<Array<Scalars['String']['output']>>;
  likes?: Maybe<Scalars['Int']['output']>;
  malePercentage?: Maybe<Scalars['Float']['output']>;
  newFollowers?: Maybe<Scalars['Int']['output']>;
  /** Draft OR Posted content */
  postContent: Scalars['String']['output'];
  saves?: Maybe<Scalars['Int']['output']>;
  status: EngagementCampaignPostStatusForInsightCheck;
  views?: Maybe<Scalars['Int']['output']>;
  viewsFromFollowing?: Maybe<Scalars['Int']['output']>;
  viewsFromHome?: Maybe<Scalars['Int']['output']>;
  viewsFromOther?: Maybe<Scalars['Int']['output']>;
  viewsFromProfile?: Maybe<Scalars['Int']['output']>;
  viewsFromSearch?: Maybe<Scalars['Int']['output']>;
}

export interface EngagementsForInsightCheck {
  engagementCampaigns: Array<EngagementForInsightCheck>;
}

export interface EngagementsForInsightCheckCount {
  totalConfirmed: Scalars['Int']['output'];
  totalUnconfirmed: Scalars['Int']['output'];
}

export enum ErrorDetail {
  /**
   * The deadline expired before the operation could complete.
   *
   * For operations that change the state of the system, this error
   * may be returned even if the operation has completed successfully.
   * For example, a successful response from a server could have been
   * delayed long enough for the deadline to expire.
   *
   * HTTP Mapping: 504 Gateway Timeout
   * Error Type: UNAVAILABLE
   */
  DEADLINE_EXCEEDED = 'DEADLINE_EXCEEDED',
  /**
   * The server detected that the client is exhibiting a behavior that
   * might be generating excessive load.
   *
   * HTTP Mapping: 429 Too Many Requests or 420 Enhance Your Calm
   * Error Type: UNAVAILABLE
   */
  ENHANCE_YOUR_CALM = 'ENHANCE_YOUR_CALM',
  /**
   * The requested field is not found in the schema.
   *
   * This differs from `NOT_FOUND` in that `NOT_FOUND` should be used when a
   * query is valid, but is unable to return a result (if, for example, a
   * specific video id doesn't exist). `FIELD_NOT_FOUND` is intended to be
   * returned by the server to signify that the requested field is not known to exist.
   * This may be returned in lieu of failing the entire query.
   * See also `PERMISSION_DENIED` for cases where the
   * requested field is invalid only for the given user or class of users.
   *
   * HTTP Mapping: 404 Not Found
   * Error Type: BAD_REQUEST
   */
  FIELD_NOT_FOUND = 'FIELD_NOT_FOUND',
  /**
   * The client specified an invalid argument.
   *
   * Note that this differs from `FAILED_PRECONDITION`.
   * `INVALID_ARGUMENT` indicates arguments that are problematic
   * regardless of the state of the system (e.g., a malformed file name).
   *
   * HTTP Mapping: 400 Bad Request
   * Error Type: BAD_REQUEST
   */
  INVALID_ARGUMENT = 'INVALID_ARGUMENT',
  /**
   * The provided cursor is not valid.
   *
   * The most common usage for this error is when a client is paginating
   * through a list that uses stateful cursors. In that case, the provided
   * cursor may be expired.
   *
   * HTTP Mapping: 404 Not Found
   * Error Type: NOT_FOUND
   */
  INVALID_CURSOR = 'INVALID_CURSOR',
  /**
   * Unable to perform operation because a required resource is missing.
   *
   * Example: Client is attempting to refresh a list, but the specified
   * list is expired. This requires an action by the client to get a new list.
   *
   * If the user is simply trying GET a resource that is not found,
   * use the NOT_FOUND error type. FAILED_PRECONDITION.MISSING_RESOURCE
   * is to be used particularly when the user is performing an operation
   * that requires a particular resource to exist.
   *
   * HTTP Mapping: 400 Bad Request or 500 Internal Server Error
   * Error Type: FAILED_PRECONDITION
   */
  MISSING_RESOURCE = 'MISSING_RESOURCE',
  /**
   * Service Error.
   *
   * There is a problem with an upstream service.
   *
   * This may be returned if a gateway receives an unknown error from a service
   * or if a service is unreachable.
   * If a request times out which waiting on a response from a service,
   * `DEADLINE_EXCEEDED` may be returned instead.
   * If a service returns a more specific error Type, the specific error Type may
   * be returned instead.
   *
   * HTTP Mapping: 502 Bad Gateway
   * Error Type: UNAVAILABLE
   */
  SERVICE_ERROR = 'SERVICE_ERROR',
  /**
   * Request failed due to network errors.
   *
   * HTTP Mapping: 503 Unavailable
   * Error Type: UNAVAILABLE
   */
  TCP_FAILURE = 'TCP_FAILURE',
  /**
   * Request throttled based on server concurrency limits.
   *
   * HTTP Mapping: 503 Unavailable
   * Error Type: UNAVAILABLE
   */
  THROTTLED_CONCURRENCY = 'THROTTLED_CONCURRENCY',
  /**
   * Request throttled based on server CPU limits
   *
   * HTTP Mapping: 503 Unavailable.
   * Error Type: UNAVAILABLE
   */
  THROTTLED_CPU = 'THROTTLED_CPU',
  /**
   * The operation is not implemented or is not currently supported/enabled.
   *
   * HTTP Mapping: 501 Not Implemented
   * Error Type: BAD_REQUEST
   */
  UNIMPLEMENTED = 'UNIMPLEMENTED',
  /**
   * Unknown error.
   *
   * This error should only be returned when no other error detail applies.
   * If a client sees an unknown errorDetail, it will be interpreted as UNKNOWN.
   *
   * HTTP Mapping: 500 Internal Server Error
   */
  UNKNOWN = 'UNKNOWN',
}

export enum ErrorType {
  /**
   * Bad Request.
   *
   * There is a problem with the request.
   * Retrying the same request is not likely to succeed.
   * An example would be a query or argument that cannot be deserialized.
   *
   * HTTP Mapping: 400 Bad Request
   */
  BAD_REQUEST = 'BAD_REQUEST',
  /**
   * The operation was rejected because the system is not in a state
   * required for the operation's execution.  For example, the directory
   * to be deleted is non-empty, an rmdir operation is applied to
   * a non-directory, etc.
   *
   * Service implementers can use the following guidelines to decide
   * between `FAILED_PRECONDITION` and `UNAVAILABLE`:
   *
   * - Use `UNAVAILABLE` if the client can retry just the failing call.
   * - Use `FAILED_PRECONDITION` if the client should not retry until
   * the system state has been explicitly fixed.  E.g., if an "rmdir"
   *      fails because the directory is non-empty, `FAILED_PRECONDITION`
   * should be returned since the client should not retry unless
   * the files are deleted from the directory.
   *
   * HTTP Mapping: 400 Bad Request or 500 Internal Server Error
   */
  FAILED_PRECONDITION = 'FAILED_PRECONDITION',
  /**
   * Internal error.
   *
   * An unexpected internal error was encountered. This means that some
   * invariants expected by the underlying system have been broken.
   * This error code is reserved for serious errors.
   *
   * HTTP Mapping: 500 Internal Server Error
   */
  INTERNAL = 'INTERNAL',
  /**
   * The requested entity was not found.
   *
   * This could apply to a resource that has never existed (e.g. bad resource id),
   * or a resource that no longer exists (e.g. cache expired.)
   *
   * Note to server developers: if a request is denied for an entire class
   * of users, such as gradual feature rollout or undocumented allowlist,
   * `NOT_FOUND` may be used. If a request is denied for some users within
   * a class of users, such as user-based access control, `PERMISSION_DENIED`
   * must be used.
   *
   * HTTP Mapping: 404 Not Found
   */
  NOT_FOUND = 'NOT_FOUND',
  /**
   * The caller does not have permission to execute the specified
   * operation.
   *
   * `PERMISSION_DENIED` must not be used for rejections
   * caused by exhausting some resource or quota.
   * `PERMISSION_DENIED` must not be used if the caller
   * cannot be identified (use `UNAUTHENTICATED`
   * instead for those errors).
   *
   * This error Type does not imply the
   * request is valid or the requested entity exists or satisfies
   * other pre-conditions.
   *
   * HTTP Mapping: 403 Forbidden
   */
  PERMISSION_DENIED = 'PERMISSION_DENIED',
  /**
   * The request does not have valid authentication credentials.
   *
   * This is intended to be returned only for routes that require
   * authentication.
   *
   * HTTP Mapping: 401 Unauthorized
   */
  UNAUTHENTICATED = 'UNAUTHENTICATED',
  /**
   * Currently Unavailable.
   *
   * The service is currently unavailable.  This is most likely a
   * transient condition, which can be corrected by retrying with
   * a backoff.
   *
   * HTTP Mapping: 503 Unavailable
   */
  UNAVAILABLE = 'UNAVAILABLE',
  /**
   * Unknown error.
   *
   * For example, this error may be returned when
   * an error code received from another address space belongs to
   * an error space that is not known in this address space.  Also
   * errors raised by APIs that do not return enough error information
   * may be converted to this error.
   *
   * If a client sees an unknown errorType, it will be interpreted as UNKNOWN.
   * Unknown errors MUST NOT trigger any special behavior. These MAY be treated
   * by an implementation as being equivalent to INTERNAL.
   *
   * When possible, a more specific error should be provided.
   *
   * HTTP Mapping: 520 Unknown Error
   */
  UNKNOWN = 'UNKNOWN',
}

export interface ExistingProfileTags {
  tags: Array<Scalars['String']['output']>;
}

export interface ExportCmsInvoiceToSpreadsheet {
  ok: Scalars['Boolean']['output'];
}

export interface ExportCmsInvoiceToSpreadsheetInput {
  month: Scalars['Date']['input'];
}

export interface ExportDashboardInstagramAccountAnalytics {
  ok: Scalars['Boolean']['output'];
}

/** An enumeration. */
export enum ExportIGPresentationType {
  GOOGLE_SLIDE = 'GOOGLE_SLIDE',
  PPTX = 'PPTX',
}

export interface ExportInstagramDashboardAccountAnalyticsInput {
  endDate: Scalars['Date']['input'];
  /** Analytics Instagram account id */
  instagramAccountId: Scalars['Int']['input'];
  startDate: Scalars['Date']['input'];
  /** type of a presentation to send on your email address */
  type: ExportIGPresentationType;
}

export interface ExportInstagramDashboardAccountInfluencerInput {
  endDate: Scalars['Date']['input'];
  /** Social account id of an influencer's IG account */
  socialAccountId: Scalars['Int']['input'];
  startDate: Scalars['Date']['input'];
  /** type of a presentation to send on your email address */
  type: ExportIGPresentationType;
}

export interface ExportInstagramHashtagPostsToGoogleSpreadsheetInput {
  endDate: Scalars['Date']['input'];
  hashtagId: Scalars['Long']['input'];
  startDate: Scalars['Date']['input'];
}

export interface ExportInstagramHashtagPostsToGoogleSpreadsheetOutput {
  status: Scalars['String']['output'];
}

export interface ExportTiktokHashtagPostsToGoogleSpreadsheetInput {
  endDate: Scalars['Date']['input'];
  hashtagId: Scalars['Long']['input'];
  startDate: Scalars['Date']['input'];
}

export interface ExportTiktokHashtagPostsToGoogleSpreadsheetOutput {
  status: Scalars['String']['output'];
}

export interface ExportTiktokKeywordPostsToGoogleSpreadsheetInput {
  endDate: Scalars['Date']['input'];
  keywordId: Scalars['Long']['input'];
  startDate: Scalars['Date']['input'];
}

export interface ExportTiktokKeywordPostsToGoogleSpreadsheetOutput {
  status: Scalars['String']['output'];
}

export enum ExportYoutubeAccountAnalyticsType {
  GOOGLE_SLIDE = 'GOOGLE_SLIDE',
  PPTX = 'PPTX',
}

export interface ExportYoutubeDashboardAccountAnalyticsInput {
  endDate: Scalars['String']['input'];
  startDate: Scalars['String']['input'];
  type?: InputMaybe<ExportYoutubeAccountAnalyticsType>;
  youtubeAccountId: Scalars['Int']['input'];
}

export interface ExportYoutubeDashboardAccountAnalyticsOutput {
  ok: Scalars['Boolean']['output'];
}

export enum ExternalAnalyticsAuthSocialAccountType {
  FACEBOOK_PAGE = 'FACEBOOK_PAGE',
  INSTAGRAM = 'INSTAGRAM',
  YOUTUBE = 'YOUTUBE',
}

export interface ExternalAnalyticsFacebookGetAccountDetailsOutput {
  fbPageAvatar: Scalars['String']['output'];
  fbPageName: Scalars['String']['output'];
}

export interface ExternalAnalyticsFacebookGetTokenWarningsOutput {
  analyticsAccountsWithInvalidTokens: Array<Scalars['Int']['output']>;
}

export interface ExternalAnalyticsInstagramGetAccountDetailsOutput {
  fbPageAvatar: Scalars['String']['output'];
  fbPageName: Scalars['String']['output'];
  igAvatar: Scalars['String']['output'];
  igUsername: Scalars['String']['output'];
}

export interface ExternalAnalyticsInstagramGetTokenWarningsOutput {
  analyticsAccountsWithInvalidTokens: Array<Scalars['Int']['output']>;
}

/**  Stores a redirect URL that FE uses to communicate with social network APIs */
export interface ExternalAnalyticsSocialAuthRedirectUrlPayload {
  redirectUrl: Scalars['String']['output'];
}

export interface ExternalConnectableFacebookPageAccount {
  avatar: Scalars['String']['output'];
  followers: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
}

export interface ExternalConnectableFacebookPageAnalyticsInput {
  callbackUrl: Scalars['String']['input'];
  response: Scalars['String']['input'];
}

export interface ExternalConnectableFacebookPageAnalyticsPayload {
  account?: Maybe<ExternalConnectableFacebookPageMainAccount>;
  pages: Array<ExternalConnectableFacebookPageAccount>;
}

export interface ExternalConnectableFacebookPageMainAccount {
  avatar: Scalars['String']['output'];
  friendCount: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
}

/**
 *  `id`: social network account id
 *  `avatar`: account's avatar URL from a remote social network server
 *  `name`: account's name
 *  `followersCount`: number of followers
 */
export interface ExternalConnectableOauthAccount {
  avatar?: Maybe<Scalars['String']['output']>;
  followersCount: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
}

/**
 *  `page`: Instagram account's Facebook page
 *  `igAccount`: Instagram account data
 */
export interface ExternalConnectableOauthPageAndIgAccount {
  igAccount: ExternalConnectableOauthAccount;
  page: ExternalConnectableOauthAccount;
}

export interface ExternalCreateFacebookPageAccountAnalyticsInput {
  facebookPageId: Scalars['String']['input'];
}

export interface ExternalCreateFacebookPageAccountAnalyticsOutput {
  analyticsAccountId: Scalars['Int']['output'];
}

export interface ExternalCreateInstagramAccountAnalyticsInput {
  instagramUserId: Scalars['String']['input'];
}

export interface ExternalCreateInstagramAccountAnalyticsOutput {
  analyticsAccountId: Scalars['Int']['output'];
}

export interface ExternalCreateYoutubeAccountAnalyticsInput {
  youtubeChannelId: Scalars['String']['input'];
}

export interface ExternalCreateYoutubeAccountAnalyticsOutput {
  analyticsAccountId: Scalars['Int']['output'];
}

export interface ExternalInstagramAnalyticsPostDetails {
  analysis?: Maybe<InstagramAnalyticsPostAnalysisDetails>;
  avatarUrl: Scalars['String']['output'];
  commentList?: Maybe<Array<InstagramAnalyticsPostCommentDetails>>;
  content: Scalars['String']['output'];
  followers?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  instagramAccountName: Scalars['String']['output'];
  mediaUrls?: Maybe<Array<Scalars['String']['output']>>;
  postDate: Scalars['Date']['output'];
  postUrl: Scalars['String']['output'];
  sentiment?: Maybe<InstagramAnalyticsPostSentimentDetails>;
}

export interface ExternalInstagramAnalyticsPostsInDate {
  feedPosts?: Maybe<Array<ExternalInstagramAnalyticsPostDetails>>;
  reelPosts?: Maybe<Array<ExternalInstagramAnalyticsReelDetails>>;
  storyPosts?: Maybe<Array<ExternalInstagramAnalyticsStoryDetails>>;
}

export interface ExternalInstagramAnalyticsReelDetails {
  analysis?: Maybe<InstagramAnalyticsReelAnalysisDetails>;
  avatarUrl: Scalars['String']['output'];
  commentList?: Maybe<Array<InstagramAnalyticsReelCommentDetails>>;
  content: Scalars['String']['output'];
  followers?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  instagramAccountName: Scalars['String']['output'];
  postDate: Scalars['Date']['output'];
  postUrl: Scalars['String']['output'];
  thumbnail?: Maybe<Scalars['String']['output']>;
}

export interface ExternalInstagramAnalyticsStoryDetails {
  avatarUrl: Scalars['String']['output'];
  comments: Scalars['Int']['output'];
  content: Scalars['String']['output'];
  exits: Scalars['Int']['output'];
  followers?: Maybe<Scalars['Int']['output']>;
  /** Instagram analytics story id */
  id: Scalars['Int']['output'];
  impressions: Scalars['Int']['output'];
  instagramAccountName: Scalars['String']['output'];
  postDate: Scalars['Date']['output'];
  postImageUrl?: Maybe<Scalars['String']['output']>;
  postUrl: Scalars['String']['output'];
  reach: Scalars['Int']['output'];
  storyVideoUrl?: Maybe<Scalars['String']['output']>;
  tapsBack: Scalars['Int']['output'];
  tapsForward: Scalars['Int']['output'];
}

export interface ExternalInstagramDashboardFollowerAnalytics {
  followerAgeRate: InstagramFollowersAgeRate;
  followerGenderRate: InstagramFollowerGenderRate;
  followerRegionRate?: Maybe<Array<InstagramFollowersRegionRate>>;
}

export interface ExternalInstagramDashboardOverview {
  comments: InstagramAnalyticsOverviewData;
  engagement: InstagramAnalyticsOverviewData;
  engagementRate: InstagramAnalyticsOverviewEngagementRateData;
  followers: InstagramAnalyticsOverviewData;
  /** impressions of posts */
  impressions: InstagramAnalyticsOverviewData;
  likes: InstagramAnalyticsOverviewData;
  posts: InstagramAnalyticsOverviewData;
  profileViews: InstagramAnalyticsOverviewData;
  reach: InstagramAnalyticsOverviewData;
  saved: InstagramAnalyticsOverviewData;
  websiteClicks: InstagramAnalyticsOverviewData;
}

export interface ExternalInstagramDashboardPostAnalytics {
  averageEngagement?: Maybe<InstagramPostAverageEngagement>;
  commentAnalytics?: Maybe<InstagramCommentAnalytics>;
  feedPosts?: Maybe<Array<ExternalInstagramFeedPost>>;
  postsHabit?: Maybe<Array<InstagramPostsHabit>>;
  storyPosts?: Maybe<Array<ExternalInstagramStoryPost>>;
}

export interface ExternalInstagramFeedPost {
  comments?: Maybe<Scalars['Int']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  engagement?: Maybe<Scalars['Int']['output']>;
  engagementRate?: Maybe<Scalars['Float']['output']>;
  follow?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  impressionFromDiscovery?: Maybe<Scalars['Int']['output']>;
  impressionFromHashtag?: Maybe<Scalars['Int']['output']>;
  impressions?: Maybe<Scalars['Int']['output']>;
  interaction?: Maybe<Scalars['Int']['output']>;
  likes?: Maybe<Scalars['Int']['output']>;
  negativeRate?: Maybe<Scalars['Float']['output']>;
  nonFollowerReach?: Maybe<Scalars['Int']['output']>;
  positiveRate?: Maybe<Scalars['Float']['output']>;
  postDate?: Maybe<Scalars['DateTime']['output']>;
  postUrl: Scalars['String']['output'];
  productButtonClick?: Maybe<Scalars['Int']['output']>;
  productPageView?: Maybe<Scalars['Int']['output']>;
  reach?: Maybe<Scalars['Int']['output']>;
  saved?: Maybe<Scalars['Int']['output']>;
  share?: Maybe<Scalars['Int']['output']>;
  thumbNail?: Maybe<Scalars['String']['output']>;
  views?: Maybe<Scalars['Int']['output']>;
}

export interface ExternalInstagramReelPost {
  comments?: Maybe<Scalars['Int']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  likes?: Maybe<Scalars['Int']['output']>;
  postDate?: Maybe<Scalars['DateTime']['output']>;
  postUrl: Scalars['String']['output'];
  reach?: Maybe<Scalars['Int']['output']>;
  saved?: Maybe<Scalars['Int']['output']>;
  shares?: Maybe<Scalars['Int']['output']>;
  thumbnail?: Maybe<Scalars['String']['output']>;
  views?: Maybe<Scalars['Int']['output']>;
}

export interface ExternalInstagramStoryPost {
  comments?: Maybe<Scalars['Int']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  exits?: Maybe<Scalars['Int']['output']>;
  follow?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  impressions?: Maybe<Scalars['Int']['output']>;
  interaction?: Maybe<Scalars['Int']['output']>;
  linkClicks?: Maybe<Scalars['Int']['output']>;
  postDate?: Maybe<Scalars['DateTime']['output']>;
  postUrl: Scalars['String']['output'];
  productButtonClick?: Maybe<Scalars['Int']['output']>;
  productPageView?: Maybe<Scalars['Int']['output']>;
  reach?: Maybe<Scalars['Int']['output']>;
  stickerTaps?: Maybe<Scalars['Int']['output']>;
  tapsBack?: Maybe<Scalars['Int']['output']>;
  tapsForward?: Maybe<Scalars['Int']['output']>;
  thumbNail?: Maybe<Scalars['String']['output']>;
}

export interface ExternalSocialAuthConnectableInstagramAccountsAnalyticsInput {
  callbackUrl: Scalars['String']['input'];
  response: Scalars['String']['input'];
}

/**  Stores Instagram accounts that can be connected based on a Facebook account */
export interface ExternalSocialAuthConnectableInstagramAccountsAnalyticsPayload {
  accounts: Array<ExternalConnectableOauthPageAndIgAccount>;
}

export interface ExternalSocialAuthReconnectFacebookPageAccountAnalyticsInput {
  accountId: Scalars['Int']['input'];
  callbackUrl: Scalars['String']['input'];
  fbPageId: Scalars['String']['input'];
  response: Scalars['String']['input'];
}

export interface ExternalSocialAuthReconnectFacebookPageAccountAnalyticsPayload {
  ok: Scalars['Boolean']['output'];
}

export interface ExternalUserSocialAuthReconnectInstagramAccountAnalyticsInput {
  accountId: Scalars['Int']['input'];
  callbackUrl: Scalars['String']['input'];
  response: Scalars['String']['input'];
}

export interface ExternalUserSocialAuthReconnectInstagramAccountAnalyticsPayload {
  ok: Scalars['Boolean']['output'];
}

export interface ExternalYoutubeAnalyticsAudienceAgeBreakdownAgeGroupPayload {
  end: Scalars['Int']['output'];
  start: Scalars['Int']['output'];
}

export interface ExternalYoutubeAnalyticsAudienceAgeBreakdownPayload {
  ageGroup: Array<ExternalYoutubeAnalyticsAudienceAgeBreakdownAgeGroupPayload>;
  femaleRate: Array<Scalars['Float']['output']>;
  maleRate: Array<Scalars['Float']['output']>;
  otherRate: Array<Scalars['Float']['output']>;
}

export interface ExternalYoutubeAnalyticsAudienceGenderBreakdownPayload {
  femaleRate: Scalars['Float']['output'];
  maleRate: Scalars['Float']['output'];
  otherRate: Scalars['Float']['output'];
}

export interface ExternalYoutubeAnalyticsAudiencePayload {
  ageRate: ExternalYoutubeAnalyticsAudienceAgeBreakdownPayload;
  genderRate: ExternalYoutubeAnalyticsAudienceGenderBreakdownPayload;
  regionRate: Array<ExternalYoutubeAnalyticsAudienceRegionPayload>;
}

export interface ExternalYoutubeAnalyticsAudienceRegionPayload {
  name: Scalars['String']['output'];
  rate: Scalars['Float']['output'];
}

export interface ExternalYoutubeAnalyticsOverviewHistoryItem {
  count: Scalars['Int']['output'];
  date: Scalars['Date']['output'];
}

export interface ExternalYoutubeAnalyticsOverviewItem {
  growth: Scalars['Int']['output'];
  history: Array<ExternalYoutubeAnalyticsOverviewHistoryItem>;
  percentage: Scalars['Float']['output'];
  total: Scalars['Int']['output'];
}

export interface ExternalYoutubeAnalyticsOverviewPayload {
  comment: ExternalYoutubeAnalyticsOverviewItem;
  dislike: ExternalYoutubeAnalyticsOverviewItem;
  like: ExternalYoutubeAnalyticsOverviewItem;
  post: ExternalYoutubeAnalyticsOverviewItem;
  subscriber: ExternalYoutubeAnalyticsOverviewItem;
  view: ExternalYoutubeAnalyticsOverviewItem;
}

export interface ExternalYoutubeAnalyticsPostComment {
  commentId: Scalars['String']['output'];
  content: Scalars['String']['output'];
  posterName: Scalars['String']['output'];
}

export interface ExternalYoutubeAnalyticsPostListByDatePayload {
  posts: Array<ExternalYoutubeAnalyticsPostListItemByDatePayload>;
}

export interface ExternalYoutubeAnalyticsPostListItemByDatePayload {
  averageViewDuration?: Maybe<Scalars['Int']['output']>;
  averageViewPercentage?: Maybe<Scalars['Float']['output']>;
  comments: Scalars['Int']['output'];
  dislikes: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  likes: Scalars['Int']['output'];
  postDate: Scalars['Date']['output'];
  subscribers: Scalars['Int']['output'];
  videoCaption: Scalars['String']['output'];
  videoComments: Array<YoutubeAnalyticsPostComment>;
  videoDuration: Scalars['Int']['output'];
  videoEmbed: Scalars['String']['output'];
  videoId: Scalars['String']['output'];
  videoTags: Array<Scalars['String']['output']>;
  videoThumbnail?: Maybe<Scalars['String']['output']>;
  videoTitle: Scalars['String']['output'];
  views: Scalars['Int']['output'];
}

export interface ExternalYoutubeAnalyticsPostListItemPayload {
  averageViewDuration?: Maybe<Scalars['Int']['output']>;
  averageViewPercentage?: Maybe<Scalars['Float']['output']>;
  comments: Scalars['Int']['output'];
  dislikes: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  likes: Scalars['Int']['output'];
  postDate: Scalars['Date']['output'];
  shares: Scalars['Int']['output'];
  videoDuration: Scalars['Int']['output'];
  videoId: Scalars['String']['output'];
  videoThumbnail?: Maybe<Scalars['String']['output']>;
  videoTitle: Scalars['String']['output'];
  views: Scalars['Int']['output'];
}

export interface ExternalYoutubeAnalyticsPostListPayload {
  posts: Array<ExternalYoutubeAnalyticsPostListItemPayload>;
}

export interface ExternalYoutubeAnalyticsPostsAverageEngagement {
  averageComments: ExternalYoutubeStatisticsData;
  averageDislikes: ExternalYoutubeStatisticsData;
  averageLikes: ExternalYoutubeStatisticsData;
  averagePostsPerWeek?: Maybe<ExternalYoutubeStatisticsData>;
  averageViews: ExternalYoutubeStatisticsData;
}

export interface ExternalYoutubeAnalyticsPostsPostingHabit {
  averageComments: Scalars['Float']['output'];
  averageLikes: Scalars['Float']['output'];
  engagementRate: Scalars['Float']['output'];
  hour: Scalars['Int']['output'];
  weekday: Scalars['Int']['output'];
}

export interface ExternalYoutubeAnalyticsPostsSortInput {
  field?: InputMaybe<ExternalYoutubeAnalyticsPostsSortOrder>;
  order?: InputMaybe<Order>;
}

export enum ExternalYoutubeAnalyticsPostsSortOrder {
  AVERAGE_VIEW_DURATION = 'AVERAGE_VIEW_DURATION',
  AVERAGE_VIEW_PERCENTAGE = 'AVERAGE_VIEW_PERCENTAGE',
  COMMENT = 'COMMENT',
  DISLIKE = 'DISLIKE',
  DURATION = 'DURATION',
  ENGAGEMENT = 'ENGAGEMENT',
  LIKE = 'LIKE',
  NEGATIVE_RATE = 'NEGATIVE_RATE',
  POSITIVE_RATE = 'POSITIVE_RATE',
  POST_DATE = 'POST_DATE',
  SHARE = 'SHARE',
  VIEWS = 'VIEWS',
}

export interface ExternalYoutubeAnalyticsPostsStatisticsPayload {
  averageEngagement: ExternalYoutubeAnalyticsPostsAverageEngagement;
  postHabit: Array<ExternalYoutubeAnalyticsPostsPostingHabit>;
}

export interface ExternalYoutubeAnalyticsRelatedPostPayload {
  comments: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  postDate: Scalars['Date']['output'];
  videoCaption: Scalars['String']['output'];
  videoDuration: Scalars['Int']['output'];
  videoEmbed: Scalars['String']['output'];
  videoId: Scalars['String']['output'];
  videoThumbnail?: Maybe<Scalars['String']['output']>;
  videoTitle: Scalars['String']['output'];
  views: Scalars['Int']['output'];
}

export interface ExternalYoutubeAnalyticsRelatedPostsPayload {
  posts: Array<ExternalYoutubeAnalyticsRelatedPostPayload>;
}

export interface ExternalYoutubeAnalyticsTagRankingPayload {
  averageComment: Scalars['Float']['output'];
  averageDislike: Scalars['Float']['output'];
  averageEngagement: Scalars['Float']['output'];
  averageLike: Scalars['Float']['output'];
  averageShare: Scalars['Float']['output'];
  engagementRate: Scalars['Float']['output'];
  posts: Scalars['Int']['output'];
  tag: Scalars['String']['output'];
  views: Scalars['Int']['output'];
}

export interface ExternalYoutubeAnalyticsTagRankingPayloads {
  tags: Array<ExternalYoutubeAnalyticsTagRankingPayload>;
}

export interface ExternalYoutubeAnalyticsTagRankingSortInput {
  field?: InputMaybe<ExternalYoutubeAnalyticsTagRankingSortOrder>;
  order?: InputMaybe<Order>;
}

export enum ExternalYoutubeAnalyticsTagRankingSortOrder {
  AVERAGE_COMMENT = 'AVERAGE_COMMENT',
  AVERAGE_DISLIKE = 'AVERAGE_DISLIKE',
  AVERAGE_ENGAGEMENT = 'AVERAGE_ENGAGEMENT',
  AVERAGE_LIKE = 'AVERAGE_LIKE',
  AVERAGE_SHARE = 'AVERAGE_SHARE',
  ENGAGEMENT_RATE = 'ENGAGEMENT_RATE',
  POST = 'POST',
  VIEWS = 'VIEWS',
}

export interface ExternalYoutubePostPayload {
  averageViewDuration?: Maybe<Scalars['Int']['output']>;
  averageViewPercentage?: Maybe<Scalars['Float']['output']>;
  comments: Scalars['Int']['output'];
  dislikes: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  likes: Scalars['Int']['output'];
  postDate: Scalars['Date']['output'];
  subscribers: Scalars['Int']['output'];
  videoCaption: Scalars['String']['output'];
  videoComments: Array<ExternalYoutubeAnalyticsPostComment>;
  videoDuration: Scalars['Int']['output'];
  videoEmbed: Scalars['String']['output'];
  videoId: Scalars['String']['output'];
  videoTags: Array<Scalars['String']['output']>;
  videoThumbnail?: Maybe<Scalars['String']['output']>;
  videoTitle: Scalars['String']['output'];
  views: Scalars['Int']['output'];
}

export interface ExternalYoutubeStatisticsData {
  growth: Scalars['Float']['output'];
  value: Scalars['Float']['output'];
}

export interface FacebookAccountInfluencerSearchResultV2 {
  facebookAccounts: Array<FacebookAccountInfluencerV2>;
  totalNumber: Scalars['Int']['output'];
}

export interface FacebookAccountInfluencerV2 {
  age?: Maybe<Scalars['Int']['output']>;
  avatar?: Maybe<Scalars['String']['output']>;
  averageLikes?: Maybe<Scalars['Float']['output']>;
  averageViews?: Maybe<Scalars['Float']['output']>;
  country: CountryName;
  engagementPostedCount?: Maybe<Scalars['Int']['output']>;
  engagementProposedCount?: Maybe<Scalars['Int']['output']>;
  gender: Genders;
  id: Scalars['ID']['output'];
  influencerEngagement?: Maybe<Scalars['Int']['output']>;
  influencerEngagementRate?: Maybe<Scalars['Float']['output']>;
  isBrandAccount: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  popularPosts?: Maybe<Array<SocialAccountPopularPost>>;
  shares?: Maybe<Scalars['Int']['output']>;
  socialAccountEngagement?: Maybe<Scalars['Int']['output']>;
  socialAccountEngagementRate?: Maybe<Scalars['Float']['output']>;
  socialAccountFollowers?: Maybe<Scalars['Int']['output']>;
  socialAccountId: Scalars['ID']['output'];
  socialAccountStatus: SocialAccountStatus;
}

export interface FacebookInfluencer {
  age?: Maybe<Scalars['Int']['output']>;
  avatar?: Maybe<Scalars['String']['output']>;
  averageLikes?: Maybe<Scalars['Float']['output']>;
  averageViews?: Maybe<Scalars['Float']['output']>;
  categories: Array<CategoryName>;
  comments?: Maybe<Scalars['Int']['output']>;
  country: CountryName;
  engagementPostedCount?: Maybe<Scalars['Int']['output']>;
  engagementProposedCount?: Maybe<Scalars['Int']['output']>;
  gender: Genders;
  /** Influencer Id */
  id: Scalars['Int']['output'];
  influencerEngagement?: Maybe<Scalars['Int']['output']>;
  influencerEngagementRate?: Maybe<Scalars['Float']['output']>;
  influencerFollowers?: Maybe<Scalars['Int']['output']>;
  isBrandAccount: Scalars['Boolean']['output'];
  likes?: Maybe<Scalars['Int']['output']>;
  marketplaceJoinedCount?: Maybe<Scalars['Int']['output']>;
  marketplacePostedCount?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  popularPosts?: Maybe<Array<SocialAccountPopularPost>>;
  shares?: Maybe<Scalars['Int']['output']>;
  socialAccountEngagement?: Maybe<Scalars['Int']['output']>;
  socialAccountEngagementFollowersRate?: Maybe<Scalars['Float']['output']>;
  socialAccountFollowers?: Maybe<Scalars['Int']['output']>;
  /** Social Account System Id */
  socialAccountId: Scalars['Int']['output'];
  /** Social Account Status */
  socialAccountStatus: SocialAccountStatus;
  views?: Maybe<Scalars['Int']['output']>;
}

export interface FacebookOauthInput {
  callbackUrl: Scalars['String']['input'];
  response: Scalars['String']['input'];
}

export interface FacebookPageAnalyticsAudienceAgeBreakdownAgeGroupPayload {
  end: Scalars['Int']['output'];
  start: Scalars['Int']['output'];
}

export interface FacebookPageAnalyticsAudienceAgeBreakdownPayload {
  ageGroup: Array<FacebookPageAnalyticsAudienceAgeBreakdownAgeGroupPayload>;
  femaleRate: Array<Scalars['Float']['output']>;
  maleRate: Array<Scalars['Float']['output']>;
  otherRate: Array<Scalars['Float']['output']>;
}

export interface FacebookPageAnalyticsAudienceGenderBreakdownPayload {
  femaleRate: Scalars['Float']['output'];
  maleRate: Scalars['Float']['output'];
  otherRate: Scalars['Float']['output'];
}

export interface FacebookPageAnalyticsAudiencePayload {
  ageRate: FacebookPageAnalyticsAudienceAgeBreakdownPayload;
  genderRate: FacebookPageAnalyticsAudienceGenderBreakdownPayload;
  regionRate: Array<FacebookPageAnalyticsAudienceRegionPayload>;
}

export interface FacebookPageAnalyticsAudienceRegionPayload {
  name: Scalars['String']['output'];
  rate: Scalars['Float']['output'];
}

export interface FacebookPageAnalyticsOverviewHistoryItem {
  count: Scalars['Int']['output'];
  date: Scalars['Date']['output'];
}

export interface FacebookPageAnalyticsOverviewHistoryRateItem {
  count: Scalars['Float']['output'];
  date: Scalars['Date']['output'];
}

export interface FacebookPageAnalyticsOverviewItem {
  growth: Scalars['Long']['output'];
  history: Array<FacebookPageAnalyticsOverviewHistoryItem>;
  percentage: Scalars['Float']['output'];
  total: Scalars['Long']['output'];
}

export interface FacebookPageAnalyticsOverviewPayload {
  comment: FacebookPageAnalyticsOverviewItem;
  engagement: FacebookPageAnalyticsOverviewItem;
  engagementRate: FacebookPageAnalyticsOverviewRateItem;
  follower: FacebookPageAnalyticsOverviewItem;
  impression: FacebookPageAnalyticsOverviewItem;
  lastUpdated: Scalars['DateTime']['output'];
  pageCta: FacebookPageAnalyticsOverviewItem;
  pageImpression: FacebookPageAnalyticsOverviewItem;
  pageLikes: FacebookPageAnalyticsOverviewItem;
  post: FacebookPageAnalyticsOverviewItem;
  reaction: FacebookPageAnalyticsOverviewItem;
  share: FacebookPageAnalyticsOverviewItem;
  view: FacebookPageAnalyticsOverviewItem;
}

export interface FacebookPageAnalyticsOverviewRateItem {
  growth: Scalars['Float']['output'];
  history: Array<FacebookPageAnalyticsOverviewHistoryRateItem>;
  percentage: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
}

export interface FacebookPageAnalyticsPostComment {
  commentId: Scalars['String']['output'];
  content: Scalars['String']['output'];
  posterName: Scalars['String']['output'];
}

export interface FacebookPageAnalyticsPostPayload {
  adImpressions: Scalars['Long']['output'];
  comments: Scalars['Int']['output'];
  content: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  image?: Maybe<Scalars['String']['output']>;
  postComments: Array<FacebookPageAnalyticsPostComment>;
  postDate: Scalars['Date']['output'];
  postUrl: Scalars['String']['output'];
  reactions: Scalars['Int']['output'];
  shares: Scalars['Int']['output'];
  subscribers: Scalars['Int']['output'];
  views: Scalars['Int']['output'];
}

export interface FacebookPageAnalyticsPostWithPageDetailsPayload {
  adImpressions: Scalars['Long']['output'];
  avatar: Scalars['String']['output'];
  comments: Scalars['Int']['output'];
  content: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  image?: Maybe<Scalars['String']['output']>;
  pageName: Scalars['String']['output'];
  postComments: Array<FacebookPageAnalyticsPostComment>;
  postDate: Scalars['Date']['output'];
  postUrl: Scalars['String']['output'];
  reactions: Scalars['Int']['output'];
  shares: Scalars['Int']['output'];
  subscribers: Scalars['Int']['output'];
  views: Scalars['Int']['output'];
}

export interface FacebookPageAnalyticsPostsAnalyticsPayload {
  averageEngagement?: Maybe<FacebookPageAnalyticsPostsAverageEngagement>;
  fanActivity: Array<FacebookPagePostsAnalyticsFanActivity>;
}

export interface FacebookPageAnalyticsPostsAverageEngagement {
  averageComments?: Maybe<FacebookPageStatisticsData>;
  averageImpressions?: Maybe<FacebookPageStatisticsData>;
  averagePostsPerWeek?: Maybe<FacebookPageStatisticsData>;
  averageReactions?: Maybe<FacebookPageStatisticsData>;
  averageShares?: Maybe<FacebookPageStatisticsData>;
  averageViews?: Maybe<FacebookPageStatisticsData>;
}

export interface FacebookPageAnalyticsPostsByDatePayload {
  avatar: Scalars['String']['output'];
  followers: Scalars['Int']['output'];
  pageName: Scalars['String']['output'];
  posts: Array<FacebookPageAnalyticsPostPayload>;
}

export interface FacebookPageAnalyticsPostsPayload {
  adImpressions: Scalars['Long']['output'];
  comments: Scalars['Int']['output'];
  content: Scalars['String']['output'];
  engagement: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  image?: Maybe<Scalars['String']['output']>;
  postDate: Scalars['Date']['output'];
  postUrl: Scalars['String']['output'];
  reactions: Scalars['Int']['output'];
  shares: Scalars['Int']['output'];
  views: Scalars['Int']['output'];
}

export interface FacebookPageAnalyticsPostsPayloads {
  posts: Array<FacebookPageAnalyticsPostsPayload>;
}

export interface FacebookPageAnalyticsPostsSortInput {
  field?: InputMaybe<FacebookPageAnalyticsPostsSortOrder>;
  order?: InputMaybe<Order>;
}

export enum FacebookPageAnalyticsPostsSortOrder {
  AD_IMPRESSIONS = 'AD_IMPRESSIONS',
  COMMENT = 'COMMENT',
  ENGAGEMENT = 'ENGAGEMENT',
  NEGATIVE_RATE = 'NEGATIVE_RATE',
  POSITIVE_RATE = 'POSITIVE_RATE',
  POST_DATE = 'POST_DATE',
  REACTIONS = 'REACTIONS',
  SHARE = 'SHARE',
  VIEWS = 'VIEWS',
}

export interface FacebookPageForProfileV2 {
  analyticsEnabled: Scalars['Boolean']['output'];
  avatar?: Maybe<Scalars['String']['output']>;
  followersCount?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  profile?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
}

export interface FacebookPageInfluencer {
  age?: Maybe<Scalars['Int']['output']>;
  analyticsWarning: Scalars['Boolean']['output'];
  avatar?: Maybe<Scalars['String']['output']>;
  averageLikes?: Maybe<Scalars['Float']['output']>;
  averageViews?: Maybe<Scalars['Float']['output']>;
  categories: Array<CategoryName>;
  comments?: Maybe<Scalars['Int']['output']>;
  country: CountryName;
  engagementPostedCount?: Maybe<Scalars['Int']['output']>;
  engagementProposedCount?: Maybe<Scalars['Int']['output']>;
  gender: Genders;
  /** Influencer Id */
  id: Scalars['Int']['output'];
  influencerEngagement?: Maybe<Scalars['Int']['output']>;
  influencerEngagementRate?: Maybe<Scalars['Float']['output']>;
  influencerFollowers?: Maybe<Scalars['Int']['output']>;
  isBrandAccount: Scalars['Boolean']['output'];
  likes?: Maybe<Scalars['Int']['output']>;
  marketplaceJoinedCount?: Maybe<Scalars['Int']['output']>;
  marketplacePostedCount?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  popularPosts?: Maybe<Array<SocialAccountPopularPost>>;
  shares?: Maybe<Scalars['Int']['output']>;
  socialAccountEngagement?: Maybe<Scalars['Int']['output']>;
  socialAccountEngagementFollowersRate?: Maybe<Scalars['Float']['output']>;
  socialAccountFollowers?: Maybe<Scalars['Int']['output']>;
  /** Social Account System Id */
  socialAccountId: Scalars['Int']['output'];
  /** Social Account Status */
  socialAccountStatus: SocialAccountStatus;
  views?: Maybe<Scalars['Int']['output']>;
}

export interface FacebookPageInfluencerSearchResultV2 {
  facebookPages: Array<FacebookPageInfluencerV2>;
  totalNumber: Scalars['Int']['output'];
}

export interface FacebookPageInfluencerV2 {
  age?: Maybe<Scalars['Int']['output']>;
  analyticsWarning?: Maybe<Scalars['Boolean']['output']>;
  avatar?: Maybe<Scalars['String']['output']>;
  averageLikes?: Maybe<Scalars['Float']['output']>;
  averageViews?: Maybe<Scalars['Float']['output']>;
  bio?: Maybe<Scalars['String']['output']>;
  country: CountryName;
  engagementPostedCount?: Maybe<Scalars['Int']['output']>;
  engagementProposedCount?: Maybe<Scalars['Int']['output']>;
  gender: Genders;
  id: Scalars['ID']['output'];
  influencerEngagement?: Maybe<Scalars['Int']['output']>;
  influencerEngagementRate?: Maybe<Scalars['Float']['output']>;
  isBrandAccount: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  popularPosts?: Maybe<Array<SocialAccountPopularPost>>;
  shares?: Maybe<Scalars['Int']['output']>;
  socialAccountEngagement?: Maybe<Scalars['Int']['output']>;
  socialAccountEngagementRate?: Maybe<Scalars['Float']['output']>;
  socialAccountFollowers?: Maybe<Scalars['Int']['output']>;
  socialAccountId: Scalars['ID']['output'];
  socialAccountStatus: SocialAccountStatus;
}

export interface FacebookPagePostsAnalyticsFanActivity {
  hour: Scalars['Int']['output'];
  onlineCount: Scalars['Int']['output'];
  weekday: Scalars['Int']['output'];
}

export interface FacebookPageStatisticsData {
  growth: Scalars['Float']['output'];
  value: Scalars['Float']['output'];
}

export interface FacebookPostForLinkBio {
  id: Scalars['ID']['output'];
  thumbnail: Scalars['String']['output'];
  totalPostsCount: Scalars['Int']['output'];
}

export interface FanActivity {
  activityType: FanActivityType;
  changes: Array<ActivityChange>;
  commenterId?: Maybe<Scalars['Long']['output']>;
  created: Scalars['String']['output'];
  id: Scalars['Long']['output'];
}

export enum FanActivityType {
  COMMENT_CREATE = 'COMMENT_CREATE',
  FAN_CREATE = 'FAN_CREATE',
  FAN_UPDATE = 'FAN_UPDATE',
  ORDER_CANCEL = 'ORDER_CANCEL',
  ORDER_CREATE = 'ORDER_CREATE',
  ORDER_UPDATE = 'ORDER_UPDATE',
}

export interface FanDetails {
  contactNumber?: Maybe<Scalars['String']['output']>;
  countryId?: Maybe<Scalars['String']['output']>;
  created: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['Long']['output'];
  lineAccount?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  shopifyAccount?: Maybe<Scalars['Long']['output']>;
  tags: Array<FanTag>;
}

export interface FanFormAnswer {
  answerOptionTitles: Array<Scalars['String']['output']>;
  answerText?: Maybe<Scalars['String']['output']>;
  order: Scalars['Int']['output'];
  questionId: Scalars['Long']['output'];
  questionTitle: Scalars['String']['output'];
  questionType: QuestionType;
}

export enum FanGender {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
  OTHER = 'OTHER',
}

export interface FanListOrderBy {
  field: FanListSortableField;
  order: Order;
}

export enum FanListSortableField {
  CREATED = 'CREATED',
}

export interface FanOrderDetails {
  currency: Scalars['String']['output'];
  items: Array<FanOrderItem>;
  orderId: Scalars['String']['output'];
  orderTime: Scalars['String']['output'];
  paymentStatus: ShopifyOrderPaymentStatus;
  shippingStatus?: Maybe<ShopifyOrderShippingStatus>;
  total: Scalars['Float']['output'];
}

export interface FanOrderItem {
  image?: Maybe<Scalars['String']['output']>;
  price: Scalars['Float']['output'];
  productName: Scalars['String']['output'];
  quantity: Scalars['Int']['output'];
  variant?: Maybe<Scalars['String']['output']>;
}

export interface FanTag {
  id: Scalars['Long']['output'];
  tag: Scalars['String']['output'];
}

export interface FileUploadUrls {
  fileUploadUrls: Array<SignedUrlData>;
}

export enum FilterByDateRange {
  LAST_7_DAYS = 'LAST_7_DAYS',
  LAST_12_MONTHS = 'LAST_12_MONTHS',
  LAST_30_DAYS = 'LAST_30_DAYS',
  LAST_90_DAYS = 'LAST_90_DAYS',
}

export enum FilterByNumber {
  EQUAL = 'EQUAL',
  GREATER_THAN = 'GREATER_THAN',
  LESS_THAN = 'LESS_THAN',
}

export interface FollowersAgeGenderGroup {
  ageGroup?: Maybe<Array<FollowersAgeGroup>>;
  femaleRate?: Maybe<Array<Scalars['Float']['output']>>;
  maleRate?: Maybe<Array<Scalars['Float']['output']>>;
}

export interface FollowersAgeGroup {
  end: Scalars['Int']['output'];
  start: Scalars['Int']['output'];
}

export interface FollowersAgeGroupInput {
  end: Scalars['Int']['input'];
  start: Scalars['Int']['input'];
}

export interface FollowersByTime {
  followersCount?: Maybe<Scalars['Int']['output']>;
  time: Scalars['DateTime']['output'];
}

export interface FollowersCountry {
  /** Country ID */
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  rate: Scalars['Float']['output'];
}

export enum FollowersGrowthPeriodEnum {
  ONE_MONTH = 'ONE_MONTH',
  ONE_YEAR = 'ONE_YEAR',
  SIX_MONTHS = 'SIX_MONTHS',
  THREE_MONTHS = 'THREE_MONTHS',
}

export enum FontSize {
  LG = 'LG',
  MD = 'MD',
  SM = 'SM',
  XL = 'XL',
  XXL = 'XXL',
}

export interface FormItem {
  id: Scalars['Long']['output'];
  lastUpdated: Scalars['String']['output'];
  status: FormStatus;
  submissionCount: Scalars['Long']['output'];
  title: Scalars['String']['output'];
}

export interface FormListOrderBy {
  field?: InputMaybe<FormListSortableField>;
  order?: InputMaybe<Order>;
}

export enum FormListSortableField {
  CREATED = 'CREATED',
  TITLE = 'TITLE',
  UPDATED = 'UPDATED',
}

export interface FormQuestionAnswer {
  answerOptionIds: Array<Scalars['Long']['output']>;
  answerText?: Maybe<Scalars['String']['output']>;
  questionId: Scalars['Long']['output'];
  questionTitle: Scalars['String']['output'];
  questionType: QuestionType;
}

export interface FormReportAnswerOption {
  count: Scalars['Int']['output'];
  optionTitle: Scalars['String']['output'];
}

export interface FormReportQuestionAnswer {
  answerOptions: Array<FormReportAnswerOption>;
  answerTexts: Array<Scalars['String']['output']>;
  numberResponses: Scalars['Int']['output'];
  questionId: Scalars['Long']['output'];
  questionTitle: Scalars['String']['output'];
  questionType: QuestionType;
}

export interface FormReportSummaryInput {
  id: Scalars['Long']['input'];
}

export interface FormReportSummaryPayload {
  answers: Array<FormReportQuestionAnswer>;
}

export interface FormRespondentItem {
  email?: Maybe<Scalars['String']['output']>;
  fanId?: Maybe<Scalars['Long']['output']>;
  firstName: Scalars['String']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  page?: Maybe<Scalars['Long']['output']>;
  submissionId: Scalars['Long']['output'];
}

export interface FormRespondentsCountInput {
  id: Scalars['Long']['input'];
}

export interface FormRespondentsCountPayload {
  total: Scalars['Long']['output'];
}

export interface FormRespondentsListInput {
  id: Scalars['Long']['input'];
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
}

export interface FormRespondentsListPayload {
  respondents: Array<FormRespondentItem>;
}

export enum FormStatus {
  DRAFT = 'DRAFT',
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC',
}

export interface GeminiMessage {
  /** value will return when status is READY_TO_SEARCH */
  influencerIds: Array<Scalars['Int']['output']>;
  /** for debugging */
  jsonResponses?: Maybe<Array<Scalars['String']['output']>>;
  nextAction: GeminiNextAction;
  text?: Maybe<Scalars['String']['output']>;
}

/** An enumeration. */
export enum GeminiNextAction {
  COLLECTING = 'COLLECTING',
  ERROR = 'ERROR',
  READY_TO_SEARCH = 'READY_TO_SEARCH',
}

/** An enumeration. */
export enum GeminiSourceFrom {
  ALL_INFLUENCERS_TAB = 'ALL_INFLUENCERS_TAB',
  INSTAGRAM_TAB = 'INSTAGRAM_TAB',
  TIKTOK_TAB = 'TIKTOK_TAB',
  YOUTUBE_TAB = 'YOUTUBE_TAB',
}

export enum Genders {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
  NOT_APPLICABLE = 'NOT_APPLICABLE',
  UNKNOWN = 'UNKNOWN',
  UPDATE_LATER = 'UPDATE_LATER',
}

export interface GenerateImportFansUploadURLPayload {
  fileName: Scalars['String']['output'];
  signedUrl: Scalars['String']['output'];
}

export interface GenerateMarketplaceCampaignDetailInput {
  campaignType: MarketplaceCampaignDetailType;
  companyUrl?: InputMaybe<Scalars['String']['input']>;
  countryId: Scalars['String']['input'];
  productUrl?: InputMaybe<Scalars['String']['input']>;
  prompt?: InputMaybe<Scalars['String']['input']>;
}

export interface GenerateMarketplaceCampaignDetailPayload {
  requestId: Scalars['ID']['output'];
}

export enum GenerateMarketplaceCampaignDetailStatus {
  ERROR = 'ERROR',
  NOT_FOUND = 'NOT_FOUND',
  SUCCEEDED = 'SUCCEEDED',
}

export interface GenerateMarketplacePostCaptionInput {
  marketplaceCampaignId: Scalars['ID']['input'];
  userInputCaption?: InputMaybe<Scalars['String']['input']>;
}

export interface GeneratePartnerInfluencerSignUpUrl {
  url: Scalars['String']['output'];
}

export interface GenerateTalentInfluencerSignUpUrl {
  url: Scalars['String']['output'];
}

export interface GenerateTikTokSpecialPostCaptionInput {
  tikTokSpecialCampaignId: Scalars['ID']['input'];
  userInputCaption?: InputMaybe<Scalars['String']['input']>;
}

export interface GetAccountInformationPayload {
  brandName?: Maybe<Scalars['String']['output']>;
  categoryIds: Array<Scalars['Int']['output']>;
  countryId: Scalars['String']['output'];
  dateOfBirth?: Maybe<Scalars['String']['output']>;
  gender: Genders;
  introduce: Scalars['String']['output'];
  name: Scalars['String']['output'];
  regionId?: Maybe<Scalars['Int']['output']>;
}

export interface GetAllCampaignsCountForSearchJobInput {
  /**  new filters */
  anyCreatorCategoryId?: InputMaybe<Scalars['Int']['input']>;
  categoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  maxPrice?: InputMaybe<Scalars['Float']['input']>;
  maxReward?: InputMaybe<Scalars['Float']['input']>;
  minPrice?: InputMaybe<Scalars['Float']['input']>;
  minReward?: InputMaybe<Scalars['Float']['input']>;
  needOnlyAvailableJobs?: InputMaybe<Scalars['Boolean']['input']>;
  participationTypes?: InputMaybe<Array<SearchJobFilterParticipationType>>;
  /**  deprecated */
  promotionMethods: Array<CampaignSocialMediaType>;
  revenueConditions?: InputMaybe<Array<SearchJobFilterRevenueCondition>>;
  revenueTypes?: InputMaybe<Array<SearchJobFilterRevenueType>>;
}

export interface GetAllCampaignsCountForSearchJobPayload {
  count?: Maybe<Scalars['Int']['output']>;
}

export interface GetAllCampaignsForSearchJobInput {
  /**  new filters */
  anyCreatorCategoryId?: InputMaybe<Scalars['Int']['input']>;
  categoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  maxPrice?: InputMaybe<Scalars['Float']['input']>;
  maxReward?: InputMaybe<Scalars['Float']['input']>;
  minPrice?: InputMaybe<Scalars['Float']['input']>;
  minReward?: InputMaybe<Scalars['Float']['input']>;
  needOnlyAvailableJobs?: InputMaybe<Scalars['Boolean']['input']>;
  offset: Scalars['Int']['input'];
  orderBy: AllCampaignsSearchJobsOrderBy;
  participationTypes?: InputMaybe<Array<SearchJobFilterParticipationType>>;
  /**  deprecated */
  promotionMethods: Array<CampaignSocialMediaType>;
  revenueConditions?: InputMaybe<Array<SearchJobFilterRevenueCondition>>;
  revenueTypes?: InputMaybe<Array<SearchJobFilterRevenueType>>;
}

export interface GetAllCampaignsForSearchJobPayload {
  campaigns: Array<CampaignsForSearchJobPayload>;
}

export interface GetAnyXAccountsByKeywordInput {
  countryId: Scalars['String']['input'];
  keyword: Scalars['String']['input'];
}

export interface GetAnyXAccountsByKeywordPayload {
  accounts: Array<AnyXAccountInfoPayload>;
}

export interface GetAutoManagedCampaignByIdInput {
  id: Scalars['Int']['input'];
}

export interface GetAutoManagedCampaignForInfluencerSearchJobInput {
  id: Scalars['Int']['input'];
}

export interface GetAutoManagedCampaignForInfluencerYourJobInput {
  id: Scalars['Int']['input'];
}

export interface GetAutoManagedCampaignsCountInput {
  categoryIds: Array<Scalars['ID']['input']>;
  countryId?: InputMaybe<Scalars['String']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  marketplaceType?: InputMaybe<AutoManagedCampaignType>;
  maxCommissionRate?: InputMaybe<Scalars['Float']['input']>;
  maxPrice?: InputMaybe<Scalars['Float']['input']>;
  minCommissionRate?: InputMaybe<Scalars['Float']['input']>;
  minPrice?: InputMaybe<Scalars['Float']['input']>;
  status?: InputMaybe<AutoManagedCampaignStatus>;
}

export interface GetAutoManagedCampaignsCountPayload {
  count: Scalars['Int']['output'];
}

export interface GetAutoManagedCampaignsInput {
  categoryIds: Array<Scalars['ID']['input']>;
  countryId?: InputMaybe<Scalars['String']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  marketplaceType?: InputMaybe<AutoManagedCampaignType>;
  maxCommissionRate?: InputMaybe<Scalars['Float']['input']>;
  maxPrice?: InputMaybe<Scalars['Float']['input']>;
  minCommissionRate?: InputMaybe<Scalars['Float']['input']>;
  minPrice?: InputMaybe<Scalars['Float']['input']>;
  offset: Scalars['Int']['input'];
  orderBy: GetAutoManagedCampaignsOrderBy;
  status?: InputMaybe<AutoManagedCampaignStatus>;
}

export interface GetAutoManagedCampaignsOrderBy {
  field?: InputMaybe<GetAutoManagedCampaignsSortField>;
  order?: InputMaybe<Order>;
}

export interface GetAutoManagedCampaignsPayload {
  campaigns: Array<AutoManagedCampaignInfoPayload>;
}

export enum GetAutoManagedCampaignsSortField {
  COMMISSION_RATE = 'COMMISSION_RATE',
  JOINED_CREATORS = 'JOINED_CREATORS',
  ORDERS = 'ORDERS',
  SALES = 'SALES',
}

export interface GetChatEventsInput {
  chatId: Scalars['String']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  newerThan?: InputMaybe<Scalars['String']['input']>;
  olderThan?: InputMaybe<Scalars['String']['input']>;
}

export interface GetChatEventsPayload {
  chatEvents: Array<ChatEvent>;
  lastCheckedAt?: Maybe<Scalars['String']['output']>;
  userName: Scalars['String']['output'];
}

export interface GetCreatorTagsByIdInput {
  tagIds: Array<Scalars['Long']['input']>;
}

export interface GetCreatorTagsByIdPayload {
  tags: Array<FanTag>;
}

export interface GetCreatorTagsInput {
  keyword: Scalars['String']['input'];
}

export interface GetCreatorTagsPayload {
  tags: Array<FanTag>;
}

export interface GetCustomerTagsInput {
  order: CustomerTagOrder;
}

export interface GetCustomerTagsPayload {
  tags: Array<CustomerTagPayload>;
}

export interface GetEmailListCountInput {
  keyword: Scalars['String']['input'];
  status?: InputMaybe<EmailTemplateStatus>;
}

export interface GetEmailListCountPayload {
  total: Scalars['Long']['output'];
}

export interface GetEmailListInput {
  keyword: Scalars['String']['input'];
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy: EmailListOrderBy;
  status?: InputMaybe<EmailTemplateStatus>;
}

export interface GetEmailListPayload {
  emails: Array<EmailInfoForList>;
}

export interface GetEmailOverviewInput {
  emailId: Scalars['Long']['input'];
}

export interface GetEmailOverviewPayload {
  info: EmailInfo;
  stats: EmailStatistics;
}

export interface GetEmailRecipientFan {
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['Long']['output'];
  name: Scalars['String']['output'];
}

export interface GetEmailRecipientTag {
  count: Scalars['Long']['output'];
  id: Scalars['Long']['output'];
  tag: Scalars['String']['output'];
}

export interface GetEmailRecipientsCountInput {
  emailId: Scalars['Long']['input'];
  keyword: Scalars['String']['input'];
  status?: InputMaybe<EmailEventStatusForSearch>;
}

export interface GetEmailRecipientsCountPayload {
  total: Scalars['Long']['output'];
}

export interface GetEmailRecipientsInput {
  emailId: Scalars['Long']['input'];
  keyword: Scalars['String']['input'];
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  status?: InputMaybe<EmailEventStatusForSearch>;
}

export interface GetEmailRecipientsPayload {
  recipients: Array<RecipientDetail>;
}

export interface GetEmailTemplateInput {
  id: Scalars['Long']['input'];
}

export interface GetEmailTemplatePayload {
  fans: Array<Scalars['Long']['output']>;
  id: Scalars['Long']['output'];
  message: Scalars['String']['output'];
  scheduleDate?: Maybe<Scalars['String']['output']>;
  sendNow?: Maybe<Scalars['Boolean']['output']>;
  sender: Scalars['String']['output'];
  status: EmailTemplateStatus;
  subject: Scalars['String']['output'];
  tags: Array<Scalars['Long']['output']>;
}

export interface GetFanActivitiesInput {
  fanId: Scalars['Long']['input'];
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
}

export interface GetFanActivitiesPayload {
  activities: Array<FanActivity>;
  total: Scalars['Int']['output'];
}

export interface GetFanFormResponseInput {
  fanId: Scalars['Long']['input'];
  submissionId: Scalars['Long']['input'];
}

export interface GetFanFormResponsePayload {
  answers: Array<FanFormAnswer>;
  formId: Scalars['Long']['output'];
  formTitle: Scalars['String']['output'];
}

export interface GetFanHeaderPayload {
  avatar: Scalars['String']['output'];
  created: Scalars['String']['output'];
  currencyId?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  id: Scalars['Long']['output'];
  lastName: Scalars['String']['output'];
  lastOrder?: Maybe<Scalars['String']['output']>;
  lineAccount?: Maybe<Scalars['String']['output']>;
  shopifyAccount?: Maybe<Scalars['Long']['output']>;
  totalOrders?: Maybe<Scalars['Int']['output']>;
  totalSpent?: Maybe<Scalars['Float']['output']>;
}

export interface GetFanInput {
  fanId: Scalars['Long']['input'];
}

export interface GetFanOrdersInput {
  fanId: Scalars['Long']['input'];
}

export interface GetFanOrdersPayload {
  orders: Array<FanOrderDetails>;
}

export interface GetFanParticipationFormListInput {
  fanId: Scalars['Long']['input'];
}

export interface GetFanParticipationFormListPayload {
  forms: Array<ParticipationForm>;
}

export interface GetFanPayload {
  address1?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  birthday?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  contactNumber?: Maybe<Scalars['String']['output']>;
  countryId?: Maybe<Scalars['String']['output']>;
  editable: Scalars['Boolean']['output'];
  email?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  gender?: Maybe<FanGender>;
  id: Scalars['Long']['output'];
  lastName: Scalars['String']['output'];
  postalCode?: Maybe<Scalars['String']['output']>;
  province?: Maybe<Scalars['String']['output']>;
  tags: Array<Scalars['String']['output']>;
}

export interface GetFanProvincesInput {
  countryId: Scalars['String']['input'];
}

export interface GetFansCountInput {
  createdDate?: InputMaybe<FilterByDateRange>;
  exactAmountSpent?: InputMaybe<Scalars['Float']['input']>;
  exactNumberOfOrders?: InputMaybe<Scalars['Int']['input']>;
  keyword: Scalars['String']['input'];
  lastOrderDate?: InputMaybe<FilterByDateRange>;
  maxAmountSpent?: InputMaybe<Scalars['Float']['input']>;
  maxNumberOfOrders?: InputMaybe<Scalars['Int']['input']>;
  minAmountSpent?: InputMaybe<Scalars['Float']['input']>;
  minNumberOfOrders?: InputMaybe<Scalars['Int']['input']>;
  tagIds: Array<Scalars['Long']['input']>;
}

export interface GetFansCountPayload {
  total: Scalars['Int']['output'];
}

export interface GetFansInput {
  createdDate?: InputMaybe<FilterByDateRange>;
  exactAmountSpent?: InputMaybe<Scalars['Float']['input']>;
  exactNumberOfOrders?: InputMaybe<Scalars['Int']['input']>;
  keyword: Scalars['String']['input'];
  lastOrderDate?: InputMaybe<FilterByDateRange>;
  limit: Scalars['Int']['input'];
  maxAmountSpent?: InputMaybe<Scalars['Float']['input']>;
  maxNumberOfOrders?: InputMaybe<Scalars['Int']['input']>;
  minAmountSpent?: InputMaybe<Scalars['Float']['input']>;
  minNumberOfOrders?: InputMaybe<Scalars['Int']['input']>;
  offset: Scalars['Int']['input'];
  orderBy: FanListOrderBy;
  tagIds: Array<Scalars['Long']['input']>;
}

export interface GetFansPayload {
  fans: Array<FanDetails>;
}

export interface GetFormInput {
  id: Scalars['Long']['input'];
}

export interface GetFormInputPayload {
  description: Scalars['String']['output'];
  hash: Scalars['String']['output'];
  id: Scalars['Long']['output'];
  questions: Array<GetFormQuestion>;
  status: FormStatus;
  thankDescription: Scalars['String']['output'];
  thankTitle: Scalars['String']['output'];
  title: Scalars['String']['output'];
}

export interface GetFormQuestion {
  id: Scalars['Long']['output'];
  image?: Maybe<Scalars['String']['output']>;
  isRequired: Scalars['Boolean']['output'];
  options: Array<GetFormQuestionOption>;
  order: Scalars['Int']['output'];
  questionType: QuestionType;
  title: Scalars['String']['output'];
}

export interface GetFormQuestionOption {
  id: Scalars['Long']['output'];
  optionTitle: Scalars['String']['output'];
  order: Scalars['Int']['output'];
}

export interface GetFormResponseByPageInput {
  formId: Scalars['Long']['input'];
  page: Scalars['Long']['input'];
}

export interface GetFormResponseInput {
  submissionId: Scalars['Long']['input'];
}

export interface GetFormResponsePayload {
  answers: Array<FormQuestionAnswer>;
  created: Scalars['String']['output'];
  formTitle: Scalars['String']['output'];
  submissionId: Scalars['Long']['output'];
}

export interface GetFormTemplateInput {
  hash: Scalars['String']['input'];
}

export interface GetFormTemplateOption {
  id: Scalars['Long']['output'];
  optionTitle: Scalars['String']['output'];
  order: Scalars['Int']['output'];
}

export interface GetFormTemplatePayload {
  description: Scalars['String']['output'];
  id: Scalars['Long']['output'];
  questions: Array<GetFormTemplateQuestion>;
  thankDescription: Scalars['String']['output'];
  thankTitle: Scalars['String']['output'];
  title: Scalars['String']['output'];
}

export interface GetFormTemplateQuestion {
  id: Scalars['Long']['output'];
  image?: Maybe<Scalars['String']['output']>;
  isRequired: Scalars['Boolean']['output'];
  options: Array<GetFormTemplateOption>;
  order: Scalars['Int']['output'];
  questionType: QuestionType;
  title: Scalars['String']['output'];
}

export interface GetFormsCountInput {
  keyword: Scalars['String']['input'];
  status?: InputMaybe<FormStatus>;
}

export interface GetFormsCountPayload {
  total: Scalars['Long']['output'];
}

export interface GetFormsListInput {
  keyword: Scalars['String']['input'];
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy: FormListOrderBy;
  status?: InputMaybe<FormStatus>;
}

export interface GetFormsListPayload {
  forms: Array<FormItem>;
}

export interface GetGeneratedMarketplaceCampaignDetailInput {
  requestId: Scalars['ID']['input'];
}

export interface GetGeneratedMarketplaceCampaignDetailPayload {
  generatedDetail?: Maybe<GetGeneratedMarketplaceCampaignDetailPayloadGeneratedDetail>;
  status: GenerateMarketplaceCampaignDetailStatus;
}

export interface GetGeneratedMarketplaceCampaignDetailPayloadGeneratedDetail {
  requirement: Scalars['String']['output'];
  serviceInformation: Scalars['String']['output'];
  title: Scalars['String']['output'];
}

export interface GetGeneratedMarketplacePostCaptionInput {
  marketplaceCampaignId: Scalars['ID']['input'];
}

export interface GetGeneratedMarketplacePostCaptionPayload {
  attemptsRemaining: Scalars['Int']['output'];
  generatedCaption?: Maybe<Scalars['String']['output']>;
  recentRequestStatus: GetGeneratedPostCaptionRecentRequestStatus;
}

export enum GetGeneratedPostCaptionRecentRequestStatus {
  ERROR = 'ERROR',
  IN_PROGRESS = 'IN_PROGRESS',
  NOT_REQUESTED = 'NOT_REQUESTED',
  SUCCEEDED = 'SUCCEEDED',
}

export interface GetGeneratedTikTokSpecialPostCaptionInput {
  tikTokSpecialCampaignId: Scalars['ID']['input'];
}

export interface GetGeneratedTikTokSpecialPostCaptionPayload {
  attemptsRemaining: Scalars['Int']['output'];
  generatedCaption?: Maybe<Scalars['String']['output']>;
  recentRequestStatus: GetGeneratedPostCaptionRecentRequestStatus;
}

export interface GetInfluencerBioAnalyticsInput {
  endDate: Scalars['String']['input'];
  startDate: Scalars['String']['input'];
}

export interface GetInfluencerBioAnalyticsPayload {
  audience?: Maybe<InfluencerBioAnalyticsAudience>;
  clicks?: Maybe<InfluencerBioAnalyticsOverview>;
  sessions?: Maybe<InfluencerBioAnalyticsOverview>;
  views?: Maybe<InfluencerBioAnalyticsOverview>;
}

export interface GetInfluencerBioPayload {
  id: Scalars['Int']['output'];
  isPublic: Scalars['Boolean']['output'];
  linkName: Scalars['String']['output'];
  sections: Array<BioSection>;
}

export interface GetInfluencerBioPayloadV2 {
  id: Scalars['Int']['output'];
  isFinish: Scalars['Boolean']['output'];
  isPublic: Scalars['Boolean']['output'];
  linkName?: Maybe<Scalars['String']['output']>;
  profile: BioInfluencerProfile;
  sections: Array<BioSectionV2>;
  theme?: Maybe<BioTheme>;
  themeV2?: Maybe<BioThemeV2>;
}

export interface GetInfluencerChatServicesPayload {
  line?: Maybe<GetLineServicePayload>;
}

export interface GetLineBroadcastEstimateRecipientsInput {
  recipientType: RecipientType;
  tagNames: Array<Scalars['String']['input']>;
}

export interface GetLineBroadcastEstimateRecipientsPayload {
  total: Scalars['Int']['output'];
}

export interface GetLineBroadcastMessageInput {
  lineBroadcastMessageId: Scalars['String']['input'];
}

export interface GetLineBroadcastMessagePayload {
  content: Array<LineContentItemPayload>;
  createdDateTime: Scalars['String']['output'];
  deliveredDateTime?: Maybe<Scalars['String']['output']>;
  deliveryTiming: DeliveryTimingItemPayload;
  id: Scalars['String']['output'];
  imageUrl: Scalars['String']['output'];
  recipient: RecipientItemPayload;
  status?: Maybe<LineBroadcastStatus>;
  title: Scalars['String']['output'];
}

export interface GetLineBroadcastSummaryInput {
  from?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['String']['input']>;
}

export interface GetLineBroadcastSummaryPayload {
  clickRate: LineBroadcastFloatValue;
  delivered: LineBroadcastIntValue;
  openRate: LineBroadcastFloatValue;
}

export interface GetLineBroadcastsCountInput {
  from?: InputMaybe<Scalars['String']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<LineBroadcastStatus>;
  tag?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['String']['input']>;
}

export interface GetLineBroadcastsCountPayload {
  total: Scalars['Int']['output'];
}

export interface GetLineBroadcastsInput {
  from?: InputMaybe<Scalars['String']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  status?: InputMaybe<LineBroadcastStatus>;
  tag?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['String']['input']>;
}

export interface GetLineBroadcastsPayload {
  broadcasts: Array<LineBroadcast>;
}

export interface GetLineChannelPayload {
  id: Scalars['Long']['output'];
  lineChannelId: Scalars['String']['output'];
  lineChannelSecret: Scalars['String']['output'];
  lineKid: Scalars['String']['output'];
  publicKey: Scalars['String']['output'];
  publicKeyId: Scalars['Long']['output'];
}

export interface GetLineChatCountInput {
  keyword: Scalars['String']['input'];
}

export interface GetLineChatCountPayload {
  activatedCount: Scalars['Int']['output'];
  assignedToMe: Scalars['Int']['output'];
}

export interface GetLineChatInput {
  chatId: Scalars['String']['input'];
}

export interface GetLineChatPayload {
  avatar?: Maybe<Scalars['String']['output']>;
  chatId: Scalars['String']['output'];
  name: Scalars['String']['output'];
}

export interface GetLineChatsInput {
  keyword: Scalars['String']['input'];
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  tagName?: InputMaybe<Scalars['String']['input']>;
  typeFilter: CRMChatTypeFilter;
}

export interface GetLineChatsPayload {
  chats: Array<LineChat>;
}

export interface GetLineLoginDetailPayload {
  liffId?: Maybe<Scalars['String']['output']>;
  lineLoginChannelId?: Maybe<Scalars['String']['output']>;
}

export interface GetLinePublicKeyPayload {
  id: Scalars['Long']['output'];
  publicKey: Scalars['String']['output'];
}

export interface GetLineServicePayload {
  liffUrl: Scalars['String']['output'];
}

export interface GetLinkBioFacebookAccountPostsInput {
  id: Scalars['ID']['input'];
}

export interface GetLinkBioFacebookAccountPostsPayload {
  posts: Array<GetLinkBioFacebookPostPayload>;
}

export interface GetLinkBioFacebookPagePostsInput {
  id: Scalars['ID']['input'];
}

export interface GetLinkBioFacebookPagePostsPayload {
  posts: Array<GetLinkBioFacebookPostPayload>;
}

export interface GetLinkBioFacebookPostPayload {
  postSystemId: Scalars['ID']['output'];
  thumbnail: Scalars['String']['output'];
}

export interface GetLinkBioThemeColorPayload {
  bg: LinkBioBackground;
  button?: Maybe<BioButtonStyle>;
  color: LinkBioPageColor;
  font?: Maybe<BioFontStyle>;
  theme?: Maybe<BioTheme>;
}

export interface GetMessageTemplatesPayload {
  templates: Array<MessageTemplateItem>;
}

export interface GetPublicInfluencerBioInput {
  linkName: Scalars['String']['input'];
}

export interface GetPublicInfluencerBioPayload {
  id: Scalars['Int']['output'];
  isPublic: Scalars['Boolean']['output'];
  profile: BioInfluencerProfile;
  sections: Array<PublicBioSection>;
  themeV2?: Maybe<BioThemeV2>;
  themes?: Maybe<PublicBioTheme>;
}

export interface GetSelectableYoutubeAnalyticsChannelsInput {
  callbackUrl: Scalars['String']['input'];
  response: Scalars['String']['input'];
}

export interface GetShopifyAccountPayload {
  id: Scalars['Long']['output'];
  shopName: Scalars['String']['output'];
}

export interface GetTagsAndFansRecipientsInput {
  keyword: Scalars['String']['input'];
}

export interface GetTagsAndFansRecipientsPayload {
  fans: Array<GetEmailRecipientFan>;
  tags: Array<GetEmailRecipientTag>;
}

export interface GoogleSlideFile {
  date: Scalars['Date']['output'];
  slideUrl: Scalars['String']['output'];
}

export interface Hashtag {
  blocked: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
}

export interface HashtagType {
  count?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['String']['output']>;
}

export interface HubspotCompanyData {
  id: Scalars['String']['output'];
  internalNetsuiteId?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
}

export interface HubspotDealData {
  companyId: Scalars['String']['output'];
  country?: Maybe<Scalars['String']['output']>;
  dealName: Scalars['String']['output'];
  endDate?: Maybe<Scalars['Date']['output']>;
  id: Scalars['String']['output'];
  ownerName?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['Date']['output']>;
}

export interface HubspotDealDataForSearch {
  dealName: Scalars['String']['output'];
  id: Scalars['String']['output'];
}

export interface ImageInput {
  fileName: Scalars['String']['input'];
  mimeType: Scalars['String']['input'];
  previewUrl: Scalars['String']['input'];
  url: Scalars['String']['input'];
}

export interface ImageItemInput {
  imageUrl: Scalars['String']['input'];
  label?: InputMaybe<Scalars['String']['input']>;
  url: Scalars['String']['input'];
}

export interface ImageItemPayload {
  imageUrl: Scalars['String']['output'];
  label?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
}

export interface ImageMessageInput {
  images: Array<ImageItemInput>;
}

export interface ImageMessagePayload {
  images: Array<ImageItemPayload>;
}

export interface ImagePayload {
  fileName: Scalars['String']['output'];
  mimeType: Scalars['String']['output'];
  previewUrl: Scalars['String']['output'];
  url: Scalars['String']['output'];
}

export interface ImageVideoMessageInput {
  media: MediaItemInput;
}

export interface ImageVideoMessagePayload {
  media: MediaItemPayload;
}

export interface ImportFansInput {
  uploadFileUrl: Scalars['String']['input'];
}

export interface ImportFansPayload {
  ok: Scalars['Boolean']['output'];
}

/**  TODO old API. don't forget to remove */
export interface Influencer {
  age?: Maybe<Scalars['Int']['output']>;
  avatar: Scalars['String']['output'];
  categories: Array<CategoryName>;
  country: CountryName;
  engagement: InfluencerEngagement;
  engagementPostedCount?: Maybe<Scalars['Int']['output']>;
  engagementProposedCount?: Maybe<Scalars['Int']['output']>;
  facebook?: Maybe<SocialAccount>;
  followersCount?: Maybe<Scalars['Int']['output']>;
  gender: Genders;
  id: Scalars['Int']['output'];
  instagram?: Maybe<SocialAccount>;
  marketplaceJoinedCount?: Maybe<Scalars['Int']['output']>;
  marketplacePostedCount?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  status: SocialAccountStatus;
  twitter?: Maybe<SocialAccount>;
  youtube?: Maybe<SocialAccount>;
}

export interface InfluencerAddYoutubeCompareAccountAnalyticsInput {
  socialAccountId: Scalars['Int']['input'];
  url: Scalars['String']['input'];
}

export interface InfluencerAnalyticsMultipleInstagramAccessTokenWarningPayload {
  reconnectStatuses: Array<InfluencerMultipleInstagramAnalyticsAccessTokenWarningItemPayload>;
}

export interface InfluencerAnalyticsSponsoredPostCountPayload {
  total: Scalars['Long']['output'];
}

export interface InfluencerAnalyticsSponsoredPostPayload {
  averageEngagement: Scalars['Float']['output'];
  averageEngagementRate: Scalars['Float']['output'];
  posts: Array<InfluencerYoutubeSponsoredPost>;
}

export interface InfluencerAnalyticsTwitterRefreshAvailabilityPayload {
  isRefreshAvailable: Scalars['Boolean']['output'];
}

export interface InfluencerAnalyticsTwitterRefreshInput {
  socialAccountId: Scalars['Int']['input'];
}

export interface InfluencerAnalyticsTwitterRefreshOutput {
  ok: Scalars['Boolean']['output'];
}

export interface InfluencerAudienceSection {
  ageGenderRates: FollowersAgeGenderGroup;
  femaleRate?: Maybe<Scalars['Float']['output']>;
  maleRate?: Maybe<Scalars['Float']['output']>;
}

export interface InfluencerBioAnalyticsAudience {
  cities: Array<BioAnalyticsCity>;
  countries: Array<BioAnalyticsCountry>;
  otherLinkClicks: Array<BioAnalyticsOtherLinkClicks>;
  referral: Array<BioAnalyticsReferral>;
  socialMediaClicks: Array<BioAnalyticsSocialMediaClicks>;
  totalClicks: Array<BioAnalyticsTotalClicks>;
}

export interface InfluencerBioAnalyticsHistory {
  count: Scalars['Int']['output'];
  date: Scalars['String']['output'];
}

export interface InfluencerBioAnalyticsOverview {
  growth: Scalars['Int']['output'];
  history: Array<InfluencerBioAnalyticsHistory>;
  percentage: Scalars['Float']['output'];
  total: Scalars['Int']['output'];
}

export interface InfluencerBrandAccounts {
  averageEngagement?: Maybe<Scalars['Float']['output']>;
  averageEngagementRate?: Maybe<Scalars['Float']['output']>;
  brandAccounts?: Maybe<Array<BrandAccount>>;
}

export interface InfluencerCampaignDetail {
  /** Campaign info */
  campaignInfo: InfluencerCampaignDetailInfo;
  /** User currency */
  currency: Scalars['String']['output'];
  joinedDate: Scalars['Date']['output'];
  /** List of posted posts in the campaign */
  posts: Array<InfluencerCampaignPostData>;
  /** Summary stats for campaign */
  summary: InfluencerCampaignStat;
}

export interface InfluencerCampaignDetailByPromotion {
  /** Campaign info */
  campaignInfo: InfluencerCampaignDetailInfoByPromotion;
  /** User currency */
  currency: Scalars['String']['output'];
  /** Summary stats for campaign */
  summary: InfluencerCampaignStatByPromotion;
}

export interface InfluencerCampaignDetailInfo {
  hasPostTracking?: Maybe<Scalars['Boolean']['output']>;
  /** Campaign ID */
  id?: Maybe<Scalars['Int']['output']>;
  /** Campaign detail type for MKP */
  mkpDetailType?: Maybe<MarketplaceCampaignDetailType>;
  /** Campaign status */
  status: AllCampaignStatus;
  /** Campaign thumbnail */
  thumbNail?: Maybe<Scalars['String']['output']>;
  /** Campaign title */
  title: Scalars['String']['output'];
  /** Campaign type */
  type: CampaignType;
}

export interface InfluencerCampaignDetailInfoByPromotion {
  hasPostTracking?: Maybe<Scalars['Boolean']['output']>;
  /** Campaign ID */
  id?: Maybe<Scalars['Int']['output']>;
  /** Campaign detail type for MKP */
  mkpDetailType?: Maybe<MarketplaceCampaignDetailType>;
  promotionMethod: CampaignPromotionMethod;
  /** Campaign thumbnail */
  thumbNail?: Maybe<Scalars['String']['output']>;
  /** Campaign title */
  title: Scalars['String']['output'];
  /** Campaign type */
  type: CampaignType;
}

/** An enumeration. */
export enum InfluencerCampaignListSortField {
  CLICK = 'CLICK',
  COMMENT = 'COMMENT',
  CONVERSION = 'CONVERSION',
  COST = 'COST',
  CVR = 'CVR',
  ENGAGEMENT = 'ENGAGEMENT',
  ENGAGEMENT_RATE = 'ENGAGEMENT_RATE',
  JOINED_DATE = 'JOINED_DATE',
  LIKE = 'LIKE',
  REACH = 'REACH',
  SHARE = 'SHARE',
}

export interface InfluencerCampaignOrderBy {
  field?: InputMaybe<InfluencerCampaignListSortField>;
  order?: InputMaybe<ORDER>;
}

export interface InfluencerCampaignPostData {
  /** Post info */
  postInfo: InfluencerCampaignPostInfo;
  /** Summary stats for post */
  summary: InfluencerCampaignStat;
}

export interface InfluencerCampaignPostInfo {
  content?: Maybe<Scalars['String']['output']>;
  /** Post Id */
  id: Scalars['Int']['output'];
  mkpCampaignType?: Maybe<MarketplaceCampaignType>;
  postUrl?: Maybe<Scalars['String']['output']>;
  promotionMethods: Array<CampaignPromotionMethod>;
  socialMedia: CampaignSocialMediaType;
  thumbNail?: Maybe<Scalars['String']['output']>;
}

export interface InfluencerCampaignStat {
  click?: Maybe<Scalars['Int']['output']>;
  comment: Scalars['Int']['output'];
  conversion?: Maybe<Scalars['Int']['output']>;
  /** Influencer cost */
  cost?: Maybe<Scalars['Float']['output']>;
  cvr?: Maybe<Scalars['Float']['output']>;
  engagement: Scalars['Int']['output'];
  engagementRate?: Maybe<Scalars['Float']['output']>;
  like?: Maybe<Scalars['Int']['output']>;
  reach?: Maybe<Scalars['Int']['output']>;
  share?: Maybe<Scalars['Int']['output']>;
}

export interface InfluencerCampaignStatByPromotion {
  actions?: Maybe<Scalars['Int']['output']>;
  clicks?: Maybe<Scalars['Int']['output']>;
  orders?: Maybe<Scalars['Int']['output']>;
  sales?: Maybe<Scalars['Int']['output']>;
}

export interface InfluencerCampaignSummary {
  /** average cost for all posts in auto_managed campaigns */
  autoManagedAvgCost?: Maybe<Scalars['Float']['output']>;
  /** number of joined auto_managed campaigns */
  autoManagedJoinedCount?: Maybe<Scalars['Int']['output']>;
  /** average comments for all posts */
  avgComments?: Maybe<Scalars['Float']['output']>;
  /** average engagement for all posts */
  avgEngagement?: Maybe<Scalars['Float']['output']>;
  /** average likes for all posts */
  avgLikes?: Maybe<Scalars['Float']['output']>;
  /** average reach for all posts */
  avgReach?: Maybe<Scalars['Float']['output']>;
  /** User currency */
  currency: Scalars['String']['output'];
  /** average cost for all posts in engagement campaigns */
  engagementAvgCost?: Maybe<Scalars['Float']['output']>;
  /** number of joined engagement campaigns */
  engagementJoinedCount?: Maybe<Scalars['Int']['output']>;
  /** number of posted engagement posts */
  engagementPostedCount?: Maybe<Scalars['Int']['output']>;
  /** average cost for all posts in marketplace campaigns */
  marketplaceAvgCost?: Maybe<Scalars['Float']['output']>;
  /** number of joined marketplace campaigns */
  marketplaceJoinedCount?: Maybe<Scalars['Int']['output']>;
  /** number of posted marketplace posts */
  marketplacePostedCount?: Maybe<Scalars['Int']['output']>;
}

export interface InfluencerDeleteYoutubeCompareAccountAnalyticsInput {
  compareAccountId: Scalars['Int']['input'];
  socialAccountId: Scalars['Int']['input'];
}

export interface InfluencerEngagement {
  averageEngagement?: Maybe<Scalars['Float']['output']>;
  engagementFollowersRate?: Maybe<Scalars['Float']['output']>;
  engagementRate?: Maybe<Scalars['Float']['output']>;
  engagementViewsRate?: Maybe<Scalars['Float']['output']>;
}

export interface InfluencerExportDashboardInstagramAccount {
  ok: Scalars['Boolean']['output'];
}

export interface InfluencerExportYoutubeDashboardAccountAnalyticsInput {
  endDate: Scalars['String']['input'];
  socialAccountId: Scalars['Int']['input'];
  startDate: Scalars['String']['input'];
  type?: InputMaybe<ExportYoutubeAccountAnalyticsType>;
}

export interface InfluencerFacebookPageAnalyticsAccessTokenWarningPayload {
  needReconnect: Scalars['Boolean']['output'];
}

export interface InfluencerFeedPost {
  caption?: Maybe<Scalars['String']['output']>;
  comments?: Maybe<Scalars['Int']['output']>;
  /** SocialPost ID */
  id: Scalars['Int']['output'];
  isVideo?: Maybe<Scalars['Boolean']['output']>;
  likes?: Maybe<Scalars['Int']['output']>;
  reactions?: Maybe<Scalars['Int']['output']>;
  shares?: Maybe<Scalars['Int']['output']>;
  socialMedia: SocialAccountType;
  thumbnail?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  views?: Maybe<Scalars['Int']['output']>;
}

export interface InfluencerFollowerGrowthSection {
  activeFollowersCount?: Maybe<Scalars['Int']['output']>;
  engageFansRate?: Maybe<Scalars['Float']['output']>;
  followersCount: Scalars['Int']['output'];
  followersGrowth: Array<FollowersByTime>;
  hasPost?: Maybe<Scalars['Boolean']['output']>;
  /** last updated datetime in UTC */
  lastUpdatedDatetime: Scalars['DateTime']['output'];
  postsCount: Scalars['Int']['output'];
  postsGrowth: Array<PostsByTime>;
  readFansRate?: Maybe<Scalars['Float']['output']>;
}

export interface InfluencerHasEstimateApi {
  accountInterest: Scalars['Boolean']['output'];
  audienceDemographics: Scalars['Boolean']['output'];
  message?: Maybe<ConstantHasEstimateMessage>;
}

export interface InfluencerInstagramAnalyticsAccessTokenWarningPayload {
  dataForReconnect: InfluencerInstagramDataForReconnectPayload;
  needReconnect: Scalars['Boolean']['output'];
}

export interface InfluencerInstagramDataForReconnectPayload {
  analyticsAccountId: Scalars['Int']['output'];
}

export interface InfluencerInterest {
  category: Scalars['String']['output'];
  rate: Scalars['Float']['output'];
}

/** An enumeration. */
export enum InfluencerListSortField {
  DATE = 'DATE',
  ENGAGEMENT = 'ENGAGEMENT',
  ENGAGEMENT_POSTED_COUNT = 'ENGAGEMENT_POSTED_COUNT',
  ENGAGEMENT_PROPOSED_COUNT = 'ENGAGEMENT_PROPOSED_COUNT',
  ENGAGEMENT_RATE = 'ENGAGEMENT_RATE',
  FOLLOWERS = 'FOLLOWERS',
  MARKETPLACE_JOINED_COUNT = 'MARKETPLACE_JOINED_COUNT',
  MARKETPLACE_POSTED_COUNT = 'MARKETPLACE_POSTED_COUNT',
}

export interface InfluencerManagerOutput {
  email: Scalars['String']['output'];
  name: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  userId: Scalars['Int']['output'];
}

export interface InfluencerMultipleInstagramAnalyticsAccessTokenWarningItemPayload {
  dataForReconnect: InfluencerMultipleInstagramDataForReconnectPayload;
  needReconnect: Scalars['Boolean']['output'];
  socialAccountId: Scalars['Int']['output'];
}

export interface InfluencerMultipleInstagramDataForReconnectPayload {
  analyticsAccountId: Scalars['Int']['output'];
}

export interface InfluencerNewProfileDetail {
  followers: InfluencerNewProfileFollowers;
  /** last updated datetime in UTC */
  lastUpdatedDatetime: Scalars['DateTime']['output'];
  popularPosts: Array<InfluencerPopularPost>;
  postsHabit: Array<InfluencerNewProfilePostsHabit>;
  postsStats: InfluencerNewProfilePostsStats;
  sentimentAnalysis?: Maybe<InfluencerNewProfileSentimentAnalysis>;
}

export interface InfluencerNewProfileEngagement {
  averageEngagement?: Maybe<Scalars['Float']['output']>;
  engagementFollowersRate?: Maybe<Scalars['Float']['output']>;
  engagementViewsRate?: Maybe<Scalars['Float']['output']>;
}

export interface InfluencerNewProfileFollowers {
  ageGenderRates?: Maybe<FollowersAgeGenderGroup>;
  countryRates?: Maybe<Array<FollowersCountry>>;
  femaleRate?: Maybe<Scalars['Float']['output']>;
  followersGrowth?: Maybe<Array<FollowersByTime>>;
  maleRate?: Maybe<Scalars['Float']['output']>;
}

export interface InfluencerNewProfileHeaderV2 {
  age?: Maybe<Scalars['Int']['output']>;
  avatar?: Maybe<Scalars['String']['output']>;
  categories: Array<CategoryName>;
  country: CountryNameForInfluencer;
  cover?: Maybe<Scalars['String']['output']>;
  defaultSelectionReason?: Maybe<Scalars['String']['output']>;
  douyinAccounts?: Maybe<Array<SocialAccountForProfileV2>>;
  emailNewsLetters?: Maybe<Array<PromotionMethodEmailNewsLetterForProfile>>;
  engagement: InfluencerNewProfileEngagement;
  facebookAccount?: Maybe<SocialAccountForProfileV2>;
  facebookPages?: Maybe<Array<FacebookPageForProfileV2>>;
  followersCount?: Maybe<Scalars['Int']['output']>;
  gender: Genders;
  hideSensitiveMetrics: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  instagramAccounts?: Maybe<Array<InstagramAccountForProfileV2>>;
  internalMemo?: Maybe<Scalars['String']['output']>;
  introduce?: Maybe<Scalars['String']['output']>;
  isBrandAccount: Scalars['Boolean']['output'];
  isTaggedToCompany?: Maybe<Scalars['Boolean']['output']>;
  mobileApps?: Maybe<Array<PromotionMethodMobileAppForProfile>>;
  name: Scalars['String']['output'];
  offlines?: Maybe<Array<PromotionMethodOfflineForProfile>>;
  partnerIds?: Maybe<Array<Scalars['Int']['output']>>;
  partnerTalentAgencies: Array<Scalars['String']['output']>;
  podCasts?: Maybe<Array<PromotionMethodPodCastForProfile>>;
  region?: Maybe<RegionName>;
  status: SocialAccountStatus;
  tags: Array<Scalars['String']['output']>;
  talentAgencyIds?: Maybe<Array<Scalars['Int']['output']>>;
  threadsAccounts?: Maybe<Array<SocialAccountForProfileV2>>;
  tiktokAccounts?: Maybe<Array<TiktokAccountForProfileV2>>;
  twitterAccounts?: Maybe<Array<TwitterForProfileV2>>;
  websites?: Maybe<Array<PromotionMethodWebsiteForProfile>>;
  xhsAccounts?: Maybe<Array<SocialAccountForProfileV2>>;
  youtubeAccounts?: Maybe<Array<YoutubeAccountForProfileV2>>;
}

export interface InfluencerNewProfilePostsHabit {
  averageComments: Scalars['Float']['output'];
  averageLikes: Scalars['Float']['output'];
  averageShares: Scalars['Float']['output'];
  averageViews: Scalars['Float']['output'];
  engagementRate: Scalars['Float']['output'];
  hour: Scalars['Int']['output'];
  weekday: Scalars['Int']['output'];
}

export interface InfluencerNewProfilePostsStats {
  averageComments?: Maybe<Scalars['Float']['output']>;
  averageEngagement?: Maybe<Scalars['Float']['output']>;
  averageLikes?: Maybe<Scalars['Float']['output']>;
  averageShares?: Maybe<Scalars['Float']['output']>;
  averageViews?: Maybe<Scalars['Float']['output']>;
  engagementFollowersRate?: Maybe<Scalars['Float']['output']>;
  engagementViewsRate?: Maybe<Scalars['Float']['output']>;
}

export interface InfluencerNewProfileSentimentAnalysis {
  negativeRatio?: Maybe<Scalars['Float']['output']>;
  neutralRatio?: Maybe<Scalars['Float']['output']>;
  positiveRatio?: Maybe<Scalars['Float']['output']>;
}

export interface InfluencerOrderBy {
  field?: InputMaybe<InfluencerListSortField>;
  order?: InputMaybe<ORDER>;
}

export interface InfluencerPaymentInformation {
  accountName?: Maybe<Scalars['String']['output']>;
  accountNumber?: Maybe<Scalars['String']['output']>;
  address?: Maybe<Scalars['String']['output']>;
  bankId?: Maybe<Scalars['Int']['output']>;
  branchId?: Maybe<Scalars['Int']['output']>;
  /** Bank Branch Name */
  branchName?: Maybe<Scalars['String']['output']>;
  cityId?: Maybe<Scalars['Int']['output']>;
  /** necessary for JP influencer */
  fullName?: Maybe<Scalars['String']['output']>;
  /** IBAN */
  iban?: Maybe<Scalars['String']['output']>;
  paymentEmail?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  /** SWIFT Code */
  swiftCode?: Maybe<Scalars['String']['output']>;
}

export interface InfluencerPaymentRequest {
  campaigns?: Maybe<Array<CampaignPayment>>;
  /** For JP influencer only */
  consumptionTax?: Maybe<Scalars['Float']['output']>;
  currency: Scalars['String']['output'];
  dueDate: Scalars['Date']['output'];
  fee: Scalars['Float']['output'];
  grossRevenue: Scalars['Float']['output'];
  id: Scalars['Int']['output'];
  netRevenue: Scalars['Float']['output'];
  paidDate?: Maybe<Scalars['Date']['output']>;
  requestedDate: Scalars['Date']['output'];
  status: PaymentRequestStatusForInfluencer;
  /** For JP influencer only */
  withholdingFee?: Maybe<Scalars['Float']['output']>;
}

export interface InfluencerPaymentRequests {
  currency: Scalars['String']['output'];
  paymentRequests: Array<InfluencerPaymentRequest>;
  totalNetRevenue: Scalars['Float']['output'];
}

export interface InfluencerPopularPost {
  comments: Scalars['Int']['output'];
  content?: Maybe<Scalars['String']['output']>;
  /** SocialPost ID */
  id: Scalars['Int']['output'];
  likes: Scalars['Int']['output'];
  shares?: Maybe<Scalars['Int']['output']>;
  socialMedia: SocialAccountType;
  thumbNail?: Maybe<Scalars['String']['output']>;
  views?: Maybe<Scalars['Int']['output']>;
}

export interface InfluencerPostDetail {
  avatar?: Maybe<Scalars['String']['output']>;
  caption: Scalars['String']['output'];
  comments?: Maybe<Scalars['Int']['output']>;
  commentsContent?: Maybe<Array<CommentContent>>;
  engagement?: Maybe<Scalars['Int']['output']>;
  followersCount?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  influencerId: Scalars['Int']['output'];
  influencerName: Scalars['String']['output'];
  likes?: Maybe<Scalars['Int']['output']>;
  postDate?: Maybe<Scalars['DateTime']['output']>;
  postUrl?: Maybe<Scalars['String']['output']>;
  reactions?: Maybe<Scalars['Int']['output']>;
  saves?: Maybe<Scalars['Int']['output']>;
  sentimentNegativeRatio?: Maybe<Scalars['Float']['output']>;
  sentimentPositiveRatio?: Maybe<Scalars['Float']['output']>;
  shares?: Maybe<Scalars['Int']['output']>;
  similarPosts?: Maybe<Array<SimilarPost>>;
  socialMedia: SocialPostType;
  thumbNail?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  views?: Maybe<Scalars['Int']['output']>;
}

export interface InfluencerPostReportStatsEngagement {
  /** campaign status using for AnyX */
  anyXStatus: AnyXEngagementCampaignStatus;
  click?: Maybe<Scalars['Int']['output']>;
  comment: Scalars['Int']['output'];
  conversion?: Maybe<Scalars['Int']['output']>;
  /** post ID */
  id: Scalars['Int']['output'];
  like: Scalars['Int']['output'];
  postCaption: Scalars['String']['output'];
  postSocialMedia: CampaignSocialMediaType;
  postUrl: Scalars['String']['output'];
  share: Scalars['Int']['output'];
  status: EngagementCampaignPostStatus;
  thumbNail?: Maybe<Scalars['String']['output']>;
  view: Scalars['Int']['output'];
}

export interface InfluencerPostReportStatsMarketplace {
  /** campaign status using for AnyX */
  anyXStatus?: Maybe<AnyXMarketplaceCampaignStatus>;
  click?: Maybe<Scalars['Int']['output']>;
  comment: Scalars['Int']['output'];
  conversion?: Maybe<Scalars['Int']['output']>;
  /** post ID */
  id: Scalars['Int']['output'];
  /** represents reason details */
  inappropriateDetailedReason?: Maybe<Scalars['String']['output']>;
  /** represents reason title */
  inappropriateReason?: Maybe<Scalars['String']['output']>;
  like: Scalars['Int']['output'];
  missingCoupon?: Maybe<Scalars['String']['output']>;
  postCaption: Scalars['String']['output'];
  postSocialMedia: CampaignSocialMediaType;
  postUrl: Scalars['String']['output'];
  share: Scalars['Int']['output'];
  status: MarketplaceCampaignPostStatus;
  thumbNail?: Maybe<Scalars['String']['output']>;
  view: Scalars['Int']['output'];
}

export interface InfluencerPostReportStatsTikTokSpecial {
  click?: Maybe<Scalars['Int']['output']>;
  comment: Scalars['Int']['output'];
  conversion?: Maybe<Scalars['Int']['output']>;
  /** post ID */
  id: Scalars['Int']['output'];
  like: Scalars['Int']['output'];
  postCaption: Scalars['String']['output'];
  postSocialMedia: CampaignSocialMediaType;
  postUrl: Scalars['String']['output'];
  share: Scalars['Int']['output'];
  status: TikTokSpecialCampaignPostStatus;
  thumbNail?: Maybe<Scalars['String']['output']>;
  view: Scalars['Int']['output'];
}

export interface InfluencerPostSection {
  accountInterests: Array<InfluencerInterest>;
  averageComments: Scalars['Float']['output'];
  averageLikes?: Maybe<Scalars['Float']['output']>;
  averagePostsPerWeek: Scalars['Float']['output'];
  averageReactions?: Maybe<Scalars['Float']['output']>;
  averageSaved?: Maybe<Scalars['Float']['output']>;
  averageShares?: Maybe<Scalars['Float']['output']>;
  averageViews?: Maybe<Scalars['Float']['output']>;
  engagementFollowersRate: Scalars['Float']['output'];
  engagementRate: Scalars['Float']['output'];
  engagementViewsRate?: Maybe<Scalars['Float']['output']>;
  estimateReach?: Maybe<Scalars['Float']['output']>;
  feedPosts: Array<InfluencerFeedPost>;
  postsHabit: Array<InfluencerNewProfilePostsHabit>;
  /** available for Instagram */
  reelPosts?: Maybe<Array<InfluencerReelPost>>;
  sentimentNegativeRatio?: Maybe<Scalars['Float']['output']>;
  sentimentNeutralRatio?: Maybe<Scalars['Float']['output']>;
  sentimentPositiveRatio?: Maybe<Scalars['Float']['output']>;
}

export interface InfluencerPostV3 {
  age?: Maybe<Scalars['Int']['output']>;
  avatar?: Maybe<Scalars['String']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  engagementRate?: Maybe<Scalars['Float']['output']>;
  followers?: Maybe<Scalars['Int']['output']>;
  gender: Genders;
  influencerId: Scalars['Int']['output'];
  isBrandAccount: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  postDate: Scalars['Date']['output'];
  postId?: Maybe<Scalars['Int']['output']>;
  postUrl: Scalars['String']['output'];
  socialAccountId?: Maybe<Scalars['Int']['output']>;
  socialAccountStatus?: Maybe<SocialAccountStatus>;
  socialMedia: SocialAccountType;
  thumbNail?: Maybe<Scalars['String']['output']>;
  views?: Maybe<Scalars['Int']['output']>;
}

export interface InfluencerPostsV3 {
  posts: Array<InfluencerPostV3>;
  totalCount?: Maybe<Scalars['Int']['output']>;
}

export interface InfluencerProfileDetailForEngagementProposal {
  followers: InfluencerNewProfileFollowers;
  /** last updated datetime in UTC */
  lastUpdatedDatetime: Scalars['DateTime']['output'];
  popularPosts: Array<InfluencerPopularPost>;
  postsHabit: Array<InfluencerNewProfilePostsHabit>;
  postsStats: InfluencerNewProfilePostsStats;
  sentimentAnalysis?: Maybe<InfluencerNewProfileSentimentAnalysis>;
}

export interface InfluencerProfileForEngagementProposal {
  age?: Maybe<Scalars['Int']['output']>;
  avatar?: Maybe<Scalars['String']['output']>;
  categories: Array<CategoryName>;
  country: CountryNameForInfluencer;
  cover?: Maybe<Scalars['String']['output']>;
  defaultSelectionReason?: Maybe<Scalars['String']['output']>;
  douyinAccounts?: Maybe<Array<SocialAccountForProfileV2>>;
  emailNewsLetters?: Maybe<Array<PromotionMethodEmailNewsLetterForProfile>>;
  engagement: InfluencerNewProfileEngagement;
  facebookAccount?: Maybe<SocialAccountForProfileV2>;
  facebookPages?: Maybe<Array<FacebookPageForProfileV2>>;
  followersCount?: Maybe<Scalars['Int']['output']>;
  gender: Genders;
  hideSensitiveMetrics: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  instagramAccounts?: Maybe<Array<InstagramAccountForProfileV2>>;
  internalMemo?: Maybe<Scalars['String']['output']>;
  introduce?: Maybe<Scalars['String']['output']>;
  isBrandAccount: Scalars['Boolean']['output'];
  isTaggedToCompany?: Maybe<Scalars['Boolean']['output']>;
  mobileApps?: Maybe<Array<PromotionMethodMobileAppForProfile>>;
  name: Scalars['String']['output'];
  offlines?: Maybe<Array<PromotionMethodOfflineForProfile>>;
  partnerIds?: Maybe<Array<Scalars['Int']['output']>>;
  partnerTalentAgencies: Array<Scalars['String']['output']>;
  podCasts?: Maybe<Array<PromotionMethodPodCastForProfile>>;
  region?: Maybe<RegionName>;
  status: SocialAccountStatus;
  tags: Array<Scalars['String']['output']>;
  talentAgencyIds?: Maybe<Array<Scalars['Int']['output']>>;
  threadsAccounts?: Maybe<Array<SocialAccountForProfileV2>>;
  tiktokAccounts?: Maybe<Array<TiktokAccountForProfileV2>>;
  twitterAccounts?: Maybe<Array<TwitterForProfileV2>>;
  websites?: Maybe<Array<PromotionMethodWebsiteForProfile>>;
  xhsAccounts?: Maybe<Array<SocialAccountForProfileV2>>;
  youtubeAccounts?: Maybe<Array<YoutubeAccountForProfileV2>>;
}

export interface InfluencerPromotionMethodsPayload {
  emailNewsLetters: Array<PromotionMethodEmailNewsLetterPayload>;
  mobileApps: Array<PromotionMethodMobileAppPayload>;
  offlines: Array<PromotionMethodOfflinePayload>;
  podCasts: Array<PromotionMethodPodCastPayload>;
  websites: Array<PromotionMethodWebsitePayload>;
}

export interface InfluencerRateCardData {
  price: Scalars['Float']['output'];
}

export interface InfluencerRateCardDetailData {
  description: Scalars['String']['output'];
  price: Scalars['Float']['output'];
}

export interface InfluencerRateCardInput {
  influencerId: Scalars['Int']['input'];
}

export interface InfluencerRateCardPayload {
  currencyId: Scalars['String']['output'];
  douyin: Array<InfluencerRateCardDetailData>;
  facebook?: Maybe<InfluencerRateCardData>;
  instagram?: Maybe<InfluencerRateCardData>;
  instagramStory?: Maybe<InfluencerRateCardData>;
  threads?: Maybe<InfluencerRateCardData>;
  tiktok?: Maybe<InfluencerRateCardData>;
  twitter?: Maybe<InfluencerRateCardData>;
  xhs: Array<InfluencerRateCardDetailData>;
  youtube?: Maybe<InfluencerRateCardData>;
}

export interface InfluencerRateCardPriceInput {
  price: Scalars['Float']['input'];
}

export interface InfluencerRateCardsInput {
  facebook?: InputMaybe<InfluencerRateCardPriceInput>;
  instagram?: InputMaybe<InfluencerRateCardPriceInput>;
  instagramStory?: InputMaybe<InfluencerRateCardPriceInput>;
  threads?: InputMaybe<InfluencerRateCardPriceInput>;
  tiktok?: InputMaybe<InfluencerRateCardPriceInput>;
  twitter?: InputMaybe<InfluencerRateCardPriceInput>;
  youtube?: InputMaybe<InfluencerRateCardPriceInput>;
}

export interface InfluencerReelPost {
  caption?: Maybe<Scalars['String']['output']>;
  comments: Scalars['Int']['output'];
  /** SocialPost ID */
  id: Scalars['Int']['output'];
  likes: Scalars['Int']['output'];
  socialMedia: SocialPostType;
  thumbnail?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
}

export enum InfluencerSearchKeywordField {
  BIO = 'BIO',
  EMAIL = 'EMAIL',
  NAME = 'NAME',
  PROFILE = 'PROFILE',
  SOCIAL_USERNAME = 'SOCIAL_USERNAME',
  TAG = 'TAG',
}

export interface InfluencerSearchOrderBy {
  field?: InputMaybe<InfluencerSearchSortField>;
  order?: InputMaybe<Order>;
}

export interface InfluencerSearchSortBy {
  field?: InputMaybe<InfluencerSearchSortField>;
  order?: InputMaybe<ORDER>;
}

export enum InfluencerSearchSortField {
  CAMPAIGNS_JOINED = 'CAMPAIGNS_JOINED',
  DATE = 'DATE',
  ENGAGEMENT = 'ENGAGEMENT',
  ENGAGEMENT_FOLLOWERS_RATE = 'ENGAGEMENT_FOLLOWERS_RATE',
  ENGAGEMENT_RATE = 'ENGAGEMENT_RATE',
  ENGAGEMENT_VIEWS_RATE = 'ENGAGEMENT_VIEWS_RATE',
  FOLLOWERS = 'FOLLOWERS',
}

export interface InfluencerSignUpInput {
  categoryIds: Array<Scalars['Int']['input']>;
  countryId: Scalars['String']['input'];
  dateOfBirth: Scalars['String']['input'];
  email: Scalars['String']['input'];
  gender: Genders;
  name: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  regionId?: InputMaybe<Scalars['Int']['input']>;
}

export interface InfluencerSignUpInputPayload {
  ok: Scalars['Boolean']['output'];
}

export interface InfluencerSocialAuthReconnectInstagramAccountAnalyticsInput {
  analyticsAccountId: Scalars['Int']['input'];
  callbackUrl: Scalars['String']['input'];
  response: Scalars['String']['input'];
}

export interface InfluencerSocialAuthReconnectInstagramAccountAnalyticsPayload {
  ok: Scalars['Boolean']['output'];
}

export interface InfluencerSocialNetworkAccountInput {
  username: Scalars['String']['input'];
}

export interface InfluencerSocialNetworkAccountOutput {
  socialAccountId: Scalars['Int']['output'];
  /** Social Account Status */
  status: SocialAccountStatus;
  username: Scalars['String']['output'];
}

export interface InfluencerSocialPost {
  caption?: Maybe<Scalars['String']['output']>;
  comments?: Maybe<Scalars['Int']['output']>;
  date: Scalars['Date']['output'];
  /** SocialPost ID */
  id: Scalars['Int']['output'];
  impressions?: Maybe<Scalars['Int']['output']>;
  isSponsoredPost?: Maybe<Scalars['Boolean']['output']>;
  likes?: Maybe<Scalars['Int']['output']>;
  postUrl?: Maybe<Scalars['String']['output']>;
  reach?: Maybe<Scalars['Int']['output']>;
  saved?: Maybe<Scalars['Int']['output']>;
  shares?: Maybe<Scalars['Int']['output']>;
  thumbnail?: Maybe<Scalars['String']['output']>;
  views?: Maybe<Scalars['Int']['output']>;
}

export interface InfluencerSponsoredPost {
  campaignType?: Maybe<CampaignType>;
  comments: Scalars['Int']['output'];
  content?: Maybe<Scalars['String']['output']>;
  /** Post ID */
  id: Scalars['Int']['output'];
  likes: Scalars['Int']['output'];
  postUrl: Scalars['String']['output'];
  postedDate: Scalars['Date']['output'];
  reaches?: Maybe<Scalars['Int']['output']>;
  saved?: Maybe<Scalars['Int']['output']>;
  shares?: Maybe<Scalars['Int']['output']>;
  socialMedia: SocialPostType;
  thumbNail?: Maybe<Scalars['String']['output']>;
  views?: Maybe<Scalars['Int']['output']>;
}

export interface InfluencerSponsoredPostCountResponse {
  total: Scalars['Int']['output'];
}

export interface InfluencerSponsoredPostResponse {
  averageEngagement?: Maybe<Scalars['Float']['output']>;
  averageEngagementRate?: Maybe<Scalars['Float']['output']>;
  averageReach?: Maybe<Scalars['Float']['output']>;
  averageSaved?: Maybe<Scalars['Float']['output']>;
  posts: Array<InfluencerSponsoredPost>;
}

export enum InfluencerStatusForSearch {
  SCRAPING = 'SCRAPING',
  SIGNED_UP = 'SIGNED_UP',
  SIGNED_UP_EMAIL = 'SIGNED_UP_EMAIL',
}

export interface InfluencerTwitterAnalyticsAccessTokenWarningPayload {
  needReconnect: Scalars['Boolean']['output'];
}

/** An enumeration. */
export enum InfluencerType {
  NORMAL = 'NORMAL',
  PRO = 'PRO',
}

export interface InfluencerUpdateDetailV2 {
  campaignDisplay: Scalars['Boolean']['output'];
  categories: Array<CategoryName>;
  country: CountryNameForInfluencer;
  dateOfBirth?: Maybe<Scalars['Date']['output']>;
  defaultEngagementSelectionReason?: Maybe<Scalars['String']['output']>;
  douyinAccounts?: Maybe<Array<InfluencerSocialNetworkAccountOutput>>;
  email?: Maybe<Scalars['String']['output']>;
  facebookAccount?: Maybe<InfluencerSocialNetworkAccountOutput>;
  facebookPages?: Maybe<Array<InfluencerSocialNetworkAccountOutput>>;
  gender: Genders;
  id: Scalars['Int']['output'];
  instagramAccounts?: Maybe<Array<InfluencerSocialNetworkAccountOutput>>;
  internalMemo?: Maybe<Scalars['String']['output']>;
  introduce?: Maybe<Scalars['String']['output']>;
  isBrandAccount: Scalars['Boolean']['output'];
  isProAccount: Scalars['Boolean']['output'];
  managerIds?: Maybe<Array<Scalars['Int']['output']>>;
  name: Scalars['String']['output'];
  notificationSetting: Scalars['Boolean']['output'];
  partners?: Maybe<Array<Partner>>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  region?: Maybe<RegionName>;
  status: SocialAccountStatus;
  tags: Array<Scalars['String']['output']>;
  talentAgencies?: Maybe<Array<TalentAgency>>;
  threadsAccounts?: Maybe<Array<InfluencerSocialNetworkAccountOutput>>;
  tiktokAccounts?: Maybe<Array<InfluencerSocialNetworkAccountOutput>>;
  twitterAccounts?: Maybe<Array<InfluencerSocialNetworkAccountOutput>>;
  xhsAccounts?: Maybe<Array<InfluencerSocialNetworkAccountOutput>>;
  youtubeAccounts?: Maybe<Array<InfluencerSocialNetworkAccountOutput>>;
}

export interface InfluencerV4 {
  age?: Maybe<Scalars['Int']['output']>;
  avatar: Scalars['String']['output'];
  country: CountryName;
  douyin?: Maybe<SocialAccountV2>;
  email?: Maybe<Scalars['Boolean']['output']>;
  engagement: InfluencerEngagement;
  facebook?: Maybe<SocialAccountV2>;
  followersCount?: Maybe<Scalars['Int']['output']>;
  gender: Genders;
  id: Scalars['Int']['output'];
  instagram?: Maybe<SocialAccountV2>;
  isBrandAccount: Scalars['Boolean']['output'];
  isTaggedToCompany?: Maybe<Scalars['Boolean']['output']>;
  line?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  socialAccounts: Array<SocialAccountDescription>;
  status: SocialAccountStatus;
  tags: Array<Scalars['String']['output']>;
  threads?: Maybe<SocialAccountV2>;
  tiktok?: Maybe<SocialAccountV2>;
  twitter?: Maybe<SocialAccountV2>;
  xhs?: Maybe<SocialAccountV2>;
  youtube?: Maybe<SocialAccountV2>;
}

export interface InfluencerWhitelist {
  /**
   *
   *         If `is_whitelist` is true, this is a whitelist,
   *         which means a list of influencers who can join the marketplace campaign.
   *         If `is_whitelist` is false, this is a blacklist.
   *
   */
  influencerIds: Array<Scalars['Int']['output']>;
  isWhitelist: Scalars['Boolean']['output'];
}

export interface InfluencerWordCloud {
  count: Scalars['Int']['output'];
  hashtag: Scalars['String']['output'];
}

export interface InfluencerYoutubeAnalyticsAccountCard {
  avatarUrl: Scalars['String']['output'];
  channelName: Scalars['String']['output'];
  elementId: Scalars['Int']['output'];
  profileUrl: Scalars['String']['output'];
  socialAccountId: Scalars['Int']['output'];
}

export interface InfluencerYoutubeAnalyticsAccountPosts {
  postHabit: Array<YoutubeAnalyticsPostsPostingHabit>;
  posts: Array<YoutubeCompareAccountPost>;
  socialAccountId: Scalars['Int']['output'];
}

export interface InfluencerYoutubeAnalyticsAccountPostsInDate {
  posts: Array<YoutubeAnalyticsPostPayload>;
  socialAccountId: Scalars['Int']['output'];
}

export interface InfluencerYoutubeAnalyticsAccountTagRanking {
  socialAccountId: Scalars['Int']['output'];
  tagsRanking: Array<YoutubeTagRanking>;
}

export interface InfluencerYoutubeAnalyticsChannelDetailsPayload {
  channelAvatar: Scalars['String']['output'];
  channelId: Scalars['String']['output'];
  channelName: Scalars['String']['output'];
}

export interface InfluencerYoutubeAnalyticsCompareAccountCard {
  accountId: Scalars['Int']['output'];
  avatarUrl: Scalars['String']['output'];
  channelName: Scalars['String']['output'];
  elementId: Scalars['Int']['output'];
  profileUrl: Scalars['String']['output'];
}

export interface InfluencerYoutubeAnalyticsCompareAccountCards {
  compareAccounts: Array<InfluencerYoutubeAnalyticsCompareAccountCard>;
  mainAccount?: Maybe<InfluencerYoutubeAnalyticsAccountCard>;
}

export interface InfluencerYoutubeCompareAccountOverview {
  graph: YoutubeCompareOverviewHistory;
  socialAccountId: Scalars['Int']['output'];
  summary: YoutubeCompareOverviewSummary;
}

export interface InfluencerYoutubeCompareAllPostsInDate {
  compareAccounts: Array<YoutubeCompareAccountPostsInDate>;
  mainAccount: InfluencerYoutubeAnalyticsAccountPostsInDate;
}

export interface InfluencerYoutubeCompareOverview {
  compareAccounts: Array<YoutubeCompareAccountOverview>;
  mainAccount: InfluencerYoutubeCompareAccountOverview;
}

export interface InfluencerYoutubeComparePosts {
  compareAccounts: Array<YoutubeCompareAccountPosts>;
  mainAccount: InfluencerYoutubeAnalyticsAccountPosts;
}

export interface InfluencerYoutubeCompareTagRanking {
  compareAccounts: Array<YoutubeCompareAccountTagRanking>;
  mainAccount: InfluencerYoutubeAnalyticsAccountTagRanking;
}

export interface InfluencerYoutubeSponsoredPost {
  comments: Scalars['Long']['output'];
  content: Scalars['String']['output'];
  duration: Scalars['Long']['output'];
  id: Scalars['Long']['output'];
  likes: Scalars['Long']['output'];
  postUrl: Scalars['String']['output'];
  postedDate: Scalars['DateTime']['output'];
  shares: Scalars['Long']['output'];
  thumbnail?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  views: Scalars['Long']['output'];
}

export interface InfluencersPptxSocialAccountInput {
  influencerId: Scalars['Int']['input'];
  socialAccountId: Scalars['Int']['input'];
  socialType: SocialAccountType;
}

/** An enumeration. */
export enum InfluencersProfileFormat {
  GOOGLE_SLIDE = 'GOOGLE_SLIDE',
  PPTX = 'PPTX',
}

export type InquiryEvent = ChatEvent & {
  id: Scalars['String']['output'];
  inquiry: Scalars['String']['output'];
  timestamp: Scalars['String']['output'];
};

export type InquiryFinishEvent = ChatEvent & {
  id: Scalars['String']['output'];
  timestamp: Scalars['String']['output'];
  username?: Maybe<Scalars['String']['output']>;
};

export interface InstagramAccountForProfileV2 {
  analyticsEnabled: Scalars['Boolean']['output'];
  avatar?: Maybe<Scalars['String']['output']>;
  followersCount?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  isBusiness: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  profile?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
}

export interface InstagramAnalyticsAccountInfoHeader {
  accountName: Scalars['String']['output'];
  avatar: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  socialAccountUsername: Scalars['String']['output'];
}

export interface InstagramAnalyticsComments {
  commentId?: Maybe<Scalars['String']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['Date']['output']>;
  postUrl?: Maybe<Scalars['String']['output']>;
  username?: Maybe<Scalars['String']['output']>;
}

export interface InstagramAnalyticsCompareAccount {
  avatarUrl: Scalars['String']['output'];
  blocked: Scalars['Boolean']['output'];
  elementId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  profileUrl: Scalars['String']['output'];
  username: Scalars['String']['output'];
}

export interface InstagramAnalyticsCompareAccounts {
  accounts?: Maybe<Array<InstagramAnalyticsCompareAccount>>;
}

export interface InstagramAnalyticsComparePostsInDate {
  accountId: Scalars['Int']['output'];
  feedPosts?: Maybe<Array<InstagramComparePostDetails>>;
}

export interface InstagramAnalyticsGetCommentItemsPayload {
  commentId: Scalars['String']['output'];
  content: Scalars['String']['output'];
  created: Scalars['String']['output'];
  postUrl: Scalars['String']['output'];
  username: Scalars['String']['output'];
}

export interface InstagramAnalyticsGetCommentsPayload {
  comments: Array<InstagramAnalyticsGetCommentItemsPayload>;
}

export interface InstagramAnalyticsHashtag {
  blocked: Scalars['Boolean']['output'];
  hashTag: Scalars['String']['output'];
  hashTagUrl: Scalars['String']['output'];
  id: Scalars['Int']['output'];
}

export interface InstagramAnalyticsHashtags {
  hashTags?: Maybe<Array<InstagramAnalyticsHashtag>>;
}

export interface InstagramAnalyticsHistoryDataPoint {
  count?: Maybe<Scalars['Int']['output']>;
  date?: Maybe<Scalars['Date']['output']>;
}

export interface InstagramAnalyticsHistoryEngagementRateDataPoint {
  count?: Maybe<Scalars['Float']['output']>;
  date?: Maybe<Scalars['Date']['output']>;
}

export interface InstagramAnalyticsOverviewData {
  growth?: Maybe<Scalars['Int']['output']>;
  history?: Maybe<Array<InstagramAnalyticsHistoryDataPoint>>;
  percentage?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
}

export interface InstagramAnalyticsOverviewEngagementRateData {
  growth?: Maybe<Scalars['Float']['output']>;
  history?: Maybe<Array<InstagramAnalyticsHistoryEngagementRateDataPoint>>;
  percentage?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
}

export interface InstagramAnalyticsPostAnalysisDetails {
  comments: Scalars['Int']['output'];
  impressions: Scalars['Int']['output'];
  likes: Scalars['Int']['output'];
  reach: Scalars['Int']['output'];
  saves: Scalars['Int']['output'];
  views: Scalars['Int']['output'];
}

export interface InstagramAnalyticsPostCommentDetails {
  content: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  username?: Maybe<Scalars['String']['output']>;
}

export interface InstagramAnalyticsPostDetails {
  analysis?: Maybe<InstagramAnalyticsPostAnalysisDetails>;
  avatarUrl: Scalars['String']['output'];
  commentList?: Maybe<Array<InstagramAnalyticsPostCommentDetails>>;
  content: Scalars['String']['output'];
  followers?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  instagramAccountName: Scalars['String']['output'];
  mediaUrls?: Maybe<Array<Scalars['String']['output']>>;
  postDate: Scalars['Date']['output'];
  postUrl: Scalars['String']['output'];
}

export interface InstagramAnalyticsPostSentimentDetails {
  negativeRate: Scalars['Float']['output'];
  positiveRate: Scalars['Float']['output'];
}

export interface InstagramAnalyticsPostsInDate {
  feedPosts?: Maybe<Array<InstagramAnalyticsPostDetails>>;
  reelPosts?: Maybe<Array<InstagramAnalyticsReelDetails>>;
  storyPosts?: Maybe<Array<InstagramAnalyticsStoryDetails>>;
}

export interface InstagramAnalyticsReelAnalysisDetails {
  comments: Scalars['Int']['output'];
  likes: Scalars['Int']['output'];
  reach: Scalars['Int']['output'];
  saves: Scalars['Int']['output'];
  shares: Scalars['Int']['output'];
  views: Scalars['Int']['output'];
}

export interface InstagramAnalyticsReelCommentDetails {
  content: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  username?: Maybe<Scalars['String']['output']>;
}

export interface InstagramAnalyticsReelDetails {
  analysis?: Maybe<InstagramAnalyticsReelAnalysisDetails>;
  avatarUrl: Scalars['String']['output'];
  commentList?: Maybe<Array<InstagramAnalyticsReelCommentDetails>>;
  content: Scalars['String']['output'];
  followers?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  instagramAccountName: Scalars['String']['output'];
  postDate: Scalars['Date']['output'];
  postUrl: Scalars['String']['output'];
  thumbnail?: Maybe<Scalars['String']['output']>;
}

export interface InstagramAnalyticsStoryDetails {
  avatarUrl: Scalars['String']['output'];
  comments: Scalars['Int']['output'];
  content: Scalars['String']['output'];
  exits: Scalars['Int']['output'];
  followers?: Maybe<Scalars['Int']['output']>;
  /** Instagram analytics story id */
  id: Scalars['Int']['output'];
  impressions: Scalars['Int']['output'];
  instagramAccountName: Scalars['String']['output'];
  postDate: Scalars['Date']['output'];
  postImageUrl?: Maybe<Scalars['String']['output']>;
  postUrl: Scalars['String']['output'];
  reach: Scalars['Int']['output'];
  storyVideoUrl?: Maybe<Scalars['String']['output']>;
  swipeForward: Scalars['Int']['output'];
  tapsBack: Scalars['Int']['output'];
  tapsForward: Scalars['Int']['output'];
}

export interface InstagramAnalyticsSummaryPostsInDate {
  compareAccounts: Array<InstagramAnalyticsComparePostsInDate>;
  mainAccount: InstagramAnalyticsPostsInDate;
}

export interface InstagramBrandAmbassadorPost {
  comments: Scalars['Int']['output'];
  likes: Scalars['Int']['output'];
  postId: Scalars['String']['output'];
  postUrl: Scalars['String']['output'];
  thumbNail?: Maybe<Scalars['String']['output']>;
}

export interface InstagramBrandAmbassadorPostDetails {
  comments: Scalars['Int']['output'];
  content: Scalars['String']['output'];
  likes: Scalars['Int']['output'];
  postId: Scalars['String']['output'];
  postUrl: Scalars['String']['output'];
  thumbNail?: Maybe<Scalars['String']['output']>;
  username: Scalars['String']['output'];
}

export interface InstagramBrandAmbassadorPosts {
  ambassadorPosts: Array<InstagramBrandAmbassadorPostsByUsername>;
}

export interface InstagramBrandAmbassadorPostsByUsername {
  brandMentionedPosts: Scalars['Int']['output'];
  igUsername: Scalars['String']['output'];
  posts: Array<InstagramBrandAmbassadorPost>;
}

export interface InstagramCommentAnalytics {
  negativeRatio?: Maybe<Scalars['Float']['output']>;
  neutralRatio?: Maybe<Scalars['Float']['output']>;
  positiveRatio?: Maybe<Scalars['Float']['output']>;
}

export interface InstagramCompareAccount {
  avatar: Scalars['String']['output'];
  blocked: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  profileUrl: Scalars['String']['output'];
  username: Scalars['String']['output'];
}

export interface InstagramCompareAccountPost {
  comments?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  likes?: Maybe<Scalars['Int']['output']>;
  thumbNail?: Maybe<Scalars['String']['output']>;
}

export interface InstagramCompareAccountsPayload {
  compareAccounts: Array<InstagramCompareAccount>;
  mainAccount: InstagramCompareAccount;
}

export interface InstagramCompareFeedPost {
  comments?: Maybe<Scalars['Int']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  engagement?: Maybe<Scalars['Int']['output']>;
  engagementRate?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Int']['output'];
  likes?: Maybe<Scalars['Int']['output']>;
  postDate?: Maybe<Scalars['DateTime']['output']>;
  postUrl: Scalars['String']['output'];
  thumbNail?: Maybe<Scalars['String']['output']>;
}

export interface InstagramCompareFollowers {
  compareAccounts: Array<InstagramCompareFollowersSecondaryAccount>;
  /** primary compare account */
  mainAccount: InstagramCompareFollowersPrimaryAccount;
}

export interface InstagramCompareFollowersPrimaryAccount {
  followerAgeRate: InstagramFollowersAgeRate;
  followerGenderRate: InstagramFollowerGenderRate;
  /** represents Instagram account system id */
  id: Scalars['Int']['output'];
}

export interface InstagramCompareFollowersSecondaryAccount {
  followerAgeRate: InstagramFollowersAgeRate;
  followerGenderRate: InstagramFollowerGenderRate;
  /** represents Instagram compare account system id */
  id: Scalars['Int']['output'];
}

export interface InstagramCompareOverview {
  /** list of comparable accounts */
  compareAccounts: Array<InstagramCompareSecondaryAccount>;
  /** primary compare account */
  mainAccount: InstagramComparePrimaryAccount;
}

export interface InstagramCompareOverviewHistory {
  comments: Array<InstagramAnalyticsHistoryDataPoint>;
  engagement: Array<InstagramAnalyticsHistoryDataPoint>;
  followers: Array<InstagramAnalyticsHistoryDataPoint>;
  likes: Array<InstagramAnalyticsHistoryDataPoint>;
  posts: Array<InstagramAnalyticsHistoryDataPoint>;
}

export interface InstagramCompareOverviewSummary {
  averageComments: InstagramCompareOverviewSummaryAverageData;
  averageLikes: InstagramCompareOverviewSummaryAverageData;
  maximumLikes: InstagramCompareOverviewSummaryData;
  totalFollowers: InstagramCompareOverviewSummaryData;
  totalPosts: InstagramCompareOverviewSummaryData;
}

export interface InstagramCompareOverviewSummaryAverageData {
  growth?: Maybe<Scalars['Float']['output']>;
  percentage?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
}

export interface InstagramCompareOverviewSummaryData {
  growth?: Maybe<Scalars['Int']['output']>;
  percentage?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
}

export interface InstagramComparePostAnalysisDetails {
  comments: Scalars['Int']['output'];
  likes: Scalars['Int']['output'];
}

export interface InstagramComparePostDetails {
  analysis?: Maybe<InstagramComparePostAnalysisDetails>;
  avatarUrl?: Maybe<Scalars['String']['output']>;
  commentList?: Maybe<Array<InstagramAnalyticsPostCommentDetails>>;
  content: Scalars['String']['output'];
  followers?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  instagramAccountName?: Maybe<Scalars['String']['output']>;
  postDate: Scalars['Date']['output'];
  postImageUrl?: Maybe<Scalars['String']['output']>;
  postUrl: Scalars['String']['output'];
}

export interface InstagramComparePosts {
  compareAccounts: Array<InstagramComparePostsData>;
  mainAccount: InstagramComparePostsData;
}

export interface InstagramComparePostsData {
  feedPosts?: Maybe<Array<InstagramCompareAccountPost>>;
  id: Scalars['Int']['output'];
  popularHashtags?: Maybe<Array<InstagramPopularHashtags>>;
  postsHabit?: Maybe<Array<InstagramPostsHabit>>;
  sentiment?: Maybe<InstagramCommentAnalytics>;
}

export interface InstagramComparePrimaryAccount {
  graph: InstagramCompareOverviewHistory;
  /** represents Instagram account system id */
  id: Scalars['Int']['output'];
  summary: InstagramCompareOverviewSummary;
}

export interface InstagramCompareSecondaryAccount {
  graph: InstagramCompareOverviewHistory;
  /** represents Instagram compare account system id */
  id: Scalars['Int']['output'];
  summary: InstagramCompareOverviewSummary;
}

export interface InstagramDashboardFollowerAnalytics {
  followerAgeRate: InstagramFollowersAgeRate;
  followerCountryRate?: Maybe<Array<InstagramFollowersCountryRate>>;
  followerGenderRate: InstagramFollowerGenderRate;
  followerRegionRate?: Maybe<Array<InstagramFollowersRegionRate>>;
}

export interface InstagramDashboardOverview {
  comments: InstagramAnalyticsOverviewData;
  engagement: InstagramAnalyticsOverviewData;
  engagementRate: InstagramAnalyticsOverviewEngagementRateData;
  followers: InstagramAnalyticsOverviewData;
  /** impressions of posts */
  impressions: InstagramAnalyticsOverviewData;
  lastUpdated?: Maybe<Scalars['DateTime']['output']>;
  likes: InstagramAnalyticsOverviewData;
  posts: InstagramAnalyticsOverviewData;
  profileViews: InstagramAnalyticsOverviewData;
  reach: InstagramAnalyticsOverviewData;
  saved: InstagramAnalyticsOverviewData;
  websiteClicks: InstagramAnalyticsOverviewData;
}

export interface InstagramDashboardPostAnalytics {
  averageEngagement?: Maybe<InstagramPostAverageEngagement>;
  commentAnalytics?: Maybe<InstagramCommentAnalytics>;
  comments?: Maybe<Array<InstagramAnalyticsComments>>;
  feedPosts?: Maybe<Array<InstagramFeedPost>>;
  postsHabit?: Maybe<Array<InstagramPostsHabit>>;
  reelsPosts?: Maybe<Array<InstagramReelPost>>;
  storyPosts?: Maybe<Array<InstagramStoryPost>>;
}

export interface InstagramDataForReconnectPayload {
  accountId: Scalars['Int']['output'];
  businessAccountId: Scalars['String']['output'];
  fbPageId: Scalars['String']['output'];
  username: Scalars['String']['output'];
}

export interface InstagramFeedPost {
  accountsEngaged?: Maybe<Scalars['Int']['output']>;
  comments?: Maybe<Scalars['Int']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  engagement?: Maybe<Scalars['Int']['output']>;
  engagementRate?: Maybe<Scalars['Float']['output']>;
  externalLinkTaps?: Maybe<Scalars['Int']['output']>;
  follow?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  impressionFromDiscovery?: Maybe<Scalars['Int']['output']>;
  impressionFromHashtag?: Maybe<Scalars['Int']['output']>;
  impressions?: Maybe<Scalars['Int']['output']>;
  interaction?: Maybe<Scalars['Int']['output']>;
  likes?: Maybe<Scalars['Int']['output']>;
  nonFollowerReach?: Maybe<Scalars['Int']['output']>;
  postDate?: Maybe<Scalars['DateTime']['output']>;
  postUrl: Scalars['String']['output'];
  productButtonClick?: Maybe<Scalars['Int']['output']>;
  productPageView?: Maybe<Scalars['Int']['output']>;
  profileActivity?: Maybe<Scalars['Int']['output']>;
  profileVisits?: Maybe<Scalars['Int']['output']>;
  reach?: Maybe<Scalars['Int']['output']>;
  saved?: Maybe<Scalars['Int']['output']>;
  share?: Maybe<Scalars['Int']['output']>;
  thumbNail?: Maybe<Scalars['String']['output']>;
  thumbnail?: Maybe<Scalars['String']['output']>;
  views?: Maybe<Scalars['Int']['output']>;
  viewsByFollowers?: Maybe<Scalars['Int']['output']>;
  viewsByNonFollowers?: Maybe<Scalars['Int']['output']>;
  viewsFromDiscovery?: Maybe<Scalars['Int']['output']>;
  viewsFromHome?: Maybe<Scalars['Int']['output']>;
  viewsFromOthers?: Maybe<Scalars['Int']['output']>;
  viewsFromProfile?: Maybe<Scalars['Int']['output']>;
}

export interface InstagramFeedPostOrderBy {
  field?: InputMaybe<InstagramFeedPostSortField>;
  order?: InputMaybe<ORDER>;
}

/** An enumeration. */
export enum InstagramFeedPostSortField {
  ACCOUNTS_ENGAGED = 'ACCOUNTS_ENGAGED',
  COMMENT = 'COMMENT',
  ENGAGEMENT = 'ENGAGEMENT',
  ENGAGEMENT_RATE = 'ENGAGEMENT_RATE',
  EXTERNAL_LINK_TAPS = 'EXTERNAL_LINK_TAPS',
  FOLLOW = 'FOLLOW',
  IMPRESSIONS = 'IMPRESSIONS',
  IMPRESSION_FROM_DISCOVERY = 'IMPRESSION_FROM_DISCOVERY',
  IMPRESSION_FROM_HASHTAG = 'IMPRESSION_FROM_HASHTAG',
  INTERACTION = 'INTERACTION',
  LIKE = 'LIKE',
  NON_FOLLOWER_REACH = 'NON_FOLLOWER_REACH',
  POST_DATE = 'POST_DATE',
  PRODUCT_BUTTON_CLICK = 'PRODUCT_BUTTON_CLICK',
  PRODUCT_PAGE_VIEW = 'PRODUCT_PAGE_VIEW',
  PROFILE_ACTIVITY = 'PROFILE_ACTIVITY',
  PROFILE_VISITS = 'PROFILE_VISITS',
  REACH = 'REACH',
  SAVED = 'SAVED',
  SHARE = 'SHARE',
  VIEW = 'VIEW',
  VIEWS_BY_FOLLOWERS = 'VIEWS_BY_FOLLOWERS',
  VIEWS_BY_NON_FOLLOWERS = 'VIEWS_BY_NON_FOLLOWERS',
  VIEWS_FROM_DISCOVERY = 'VIEWS_FROM_DISCOVERY',
  VIEWS_FROM_HOME = 'VIEWS_FROM_HOME',
  VIEWS_FROM_OTHERS = 'VIEWS_FROM_OTHERS',
  VIEWS_FROM_PROFILE = 'VIEWS_FROM_PROFILE',
}

export interface InstagramFollowerGenderRate {
  femaleRate?: Maybe<Scalars['Float']['output']>;
  maleRate?: Maybe<Scalars['Float']['output']>;
  otherRate?: Maybe<Scalars['Float']['output']>;
}

export interface InstagramFollowersAgeGroup {
  end: Scalars['Int']['output'];
  start: Scalars['Int']['output'];
}

export interface InstagramFollowersAgeRate {
  ageGroup?: Maybe<Array<InstagramFollowersAgeGroup>>;
  femaleRate?: Maybe<Array<Scalars['Float']['output']>>;
  maleRate?: Maybe<Array<Scalars['Float']['output']>>;
  otherRate?: Maybe<Array<Scalars['Float']['output']>>;
}

export interface InstagramFollowersCountryRate {
  /** Country ID */
  id: Scalars['String']['output'];
  rate: Scalars['Float']['output'];
}

export interface InstagramFollowersRegionRate {
  name: Scalars['String']['output'];
  rate: Scalars['Float']['output'];
}

export interface InstagramHashtagFeedPost {
  comments?: Maybe<Scalars['Int']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  engagement?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  likes?: Maybe<Scalars['Int']['output']>;
  postDate?: Maybe<Scalars['DateTime']['output']>;
  postUrl: Scalars['String']['output'];
  thumbNail?: Maybe<Scalars['String']['output']>;
}

export interface InstagramHashtagFeedPostV2 {
  accountId?: Maybe<Scalars['Int']['output']>;
  avatar: Scalars['String']['output'];
  comments: Scalars['Int']['output'];
  content?: Maybe<Scalars['String']['output']>;
  engagement: Scalars['Int']['output'];
  followersCount?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  images: Array<Scalars['String']['output']>;
  influencerId?: Maybe<Scalars['Int']['output']>;
  likes: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  postDate: Scalars['Date']['output'];
  postUrl: Scalars['String']['output'];
  rank: Scalars['Int']['output'];
}

export interface InstagramHashtagFeedPostsPayload {
  count: Scalars['Int']['output'];
  posts: Array<InstagramHashtagFeedPostV2>;
}

export interface InstagramHashtagPostAnalysisDetails {
  comments: Scalars['Int']['output'];
  likes: Scalars['Int']['output'];
}

export interface InstagramHashtagPostDetails {
  analysis?: Maybe<InstagramHashtagPostAnalysisDetails>;
  avatarUrl?: Maybe<Scalars['String']['output']>;
  commentList?: Maybe<Array<InstagramAnalyticsPostCommentDetails>>;
  content: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  images?: Maybe<Array<Scalars['String']['output']>>;
  instagramAccountName?: Maybe<Scalars['String']['output']>;
  postDate: Scalars['Date']['output'];
  postImageUrl?: Maybe<Scalars['String']['output']>;
  postUrl: Scalars['String']['output'];
}

export interface InstagramHashtagPosts {
  hashtags: Array<InstagramHashtagPostsData>;
}

export interface InstagramHashtagPostsData {
  feedPosts?: Maybe<Array<InstagramHashtagFeedPost>>;
  id: Scalars['Int']['output'];
}

export interface InstagramHashtagPostsPayload {
  hashtags: Array<Hashtag>;
  postsByHashtag: Array<PostByHashtag>;
}

export interface InstagramHashtagsMetricsData {
  comments: Array<InstagramAnalyticsHistoryDataPoint>;
  /** Hashtag system id */
  id: Scalars['Int']['output'];
  likes: Array<InstagramAnalyticsHistoryDataPoint>;
  posts: Array<InstagramAnalyticsHistoryDataPoint>;
}

export interface InstagramHashtagsOverview {
  graph: InstagramHashtagsOverviewHistory;
  summaries: Array<InstagramHashtagsOverviewSummary>;
}

export interface InstagramHashtagsOverviewHistory {
  /** Historical followers data of Analytics account */
  followers: Array<InstagramAnalyticsHistoryDataPoint>;
  hashtags: Array<InstagramHashtagsMetricsData>;
}

export interface InstagramHashtagsOverviewSummary {
  comments: InstagramHashtagsOverviewSummaryData;
  hashtag: InstagramAnalyticsHashtag;
  likes: InstagramHashtagsOverviewSummaryData;
  posts: InstagramHashtagsOverviewSummaryData;
}

export interface InstagramHashtagsOverviewSummaryData {
  growth?: Maybe<Scalars['Int']['output']>;
  percentage?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
}

export interface InstagramInfluencerSearchResultV3 {
  instagramAccounts: Array<InstagramInfluencerV3>;
  totalNumber: Scalars['Int']['output'];
}

export interface InstagramInfluencerV3 {
  age?: Maybe<Scalars['Int']['output']>;
  analyticsWarning?: Maybe<Scalars['Boolean']['output']>;
  avatar?: Maybe<Scalars['String']['output']>;
  averageLikes?: Maybe<Scalars['Float']['output']>;
  averageReach?: Maybe<Scalars['Float']['output']>;
  averageViews?: Maybe<Scalars['Float']['output']>;
  country: CountryName;
  gender: Genders;
  id: Scalars['ID']['output'];
  isBrandAccount: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  popularPosts?: Maybe<Array<SocialAccountPopularPost>>;
  socialAccountEngagement?: Maybe<Scalars['Int']['output']>;
  socialAccountEngagementRate?: Maybe<Scalars['Float']['output']>;
  socialAccountFollowers?: Maybe<Scalars['Int']['output']>;
  socialAccountId: Scalars['ID']['output'];
  socialAccountStatus: SocialAccountStatus;
}

export interface InstagramInteractionCommonPostData {
  comments?: Maybe<Scalars['Int']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  likes?: Maybe<Scalars['Int']['output']>;
  mediaUrl?: Maybe<Scalars['String']['output']>;
  postDate: Scalars['Date']['output'];
  postUrl: Scalars['String']['output'];
}

export interface InstagramInteractionCommonTaggedPostData {
  comments?: Maybe<Scalars['Int']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  likes?: Maybe<Scalars['Int']['output']>;
  postDate: Scalars['Date']['output'];
  postUrl: Scalars['String']['output'];
  thumbNail?: Maybe<Scalars['String']['output']>;
}

export interface InstagramInteractionOverview {
  graph: InstagramInteractionOverviewHistory;
  summaries: InstagramInteractionOverviewSummary;
}

export interface InstagramInteractionOverviewHistory {
  /** Historical followers data of Analytics account */
  followers: Array<InstagramAnalyticsHistoryDataPoint>;
  mentionedComments: Array<InstagramAnalyticsHistoryDataPoint>;
  mentionedLikes: Array<InstagramAnalyticsHistoryDataPoint>;
  mentionedPosts: Array<InstagramAnalyticsHistoryDataPoint>;
  taggedComments: Array<InstagramAnalyticsHistoryDataPoint>;
  taggedLikes: Array<InstagramAnalyticsHistoryDataPoint>;
  taggedPosts: Array<InstagramAnalyticsHistoryDataPoint>;
}

export interface InstagramInteractionOverviewSummary {
  mentionComments: InstagramInteractionOverviewSummaryData;
  mentionLikes: InstagramInteractionOverviewSummaryData;
  mentionPosts: InstagramInteractionOverviewSummaryData;
  taggedComments: InstagramInteractionOverviewSummaryData;
  taggedLikes: InstagramInteractionOverviewSummaryData;
  taggedPosts: InstagramInteractionOverviewSummaryData;
}

export interface InstagramInteractionOverviewSummaryData {
  growth?: Maybe<Scalars['Int']['output']>;
  percentage?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
}

export interface InstagramInteractionPost {
  comments?: Maybe<Scalars['Int']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  likes?: Maybe<Scalars['Int']['output']>;
  mediaUrl?: Maybe<Scalars['String']['output']>;
  postDate?: Maybe<Scalars['DateTime']['output']>;
  postUrl: Scalars['String']['output'];
}

export interface InstagramInteractionPostDetails {
  avatarUrl?: Maybe<Scalars['String']['output']>;
  commentList?: Maybe<Array<InstagramAnalyticsPostCommentDetails>>;
  comments: Scalars['Int']['output'];
  content: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  instagramAccountName?: Maybe<Scalars['String']['output']>;
  likes: Scalars['Int']['output'];
  postDate: Scalars['Date']['output'];
  postImageUrl?: Maybe<Scalars['String']['output']>;
  postUrl: Scalars['String']['output'];
}

export interface InstagramInteractionPosts {
  mentionedPosts: Array<InstagramInteractionCommonPostData>;
  taggedPosts: Array<InstagramInteractionCommonTaggedPostData>;
  userAnalytics: Array<InstagramInteractionUserAnalytics>;
}

export interface InstagramInteractionTaggedPost {
  comments?: Maybe<Scalars['Int']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  likes?: Maybe<Scalars['Int']['output']>;
  postDate?: Maybe<Scalars['DateTime']['output']>;
  postUrl: Scalars['String']['output'];
  thumbNail?: Maybe<Scalars['String']['output']>;
}

export interface InstagramInteractionUserAnalytics {
  comments?: Maybe<Scalars['Int']['output']>;
  likes?: Maybe<Scalars['Int']['output']>;
  postsCount?: Maybe<Scalars['Int']['output']>;
  username: Scalars['String']['output'];
}

export interface InstagramInteractionUserAnalyticsDetails {
  /** average total comments for Mentioned only */
  avgMentionedComments: Scalars['Float']['output'];
  /** average total likes for Mentioned only */
  avgMentionedLikes: Scalars['Float']['output'];
  /** average total comments for Tagged only */
  avgTaggedComments: Scalars['Float']['output'];
  /** average total likes for Tagged only */
  avgTaggedLikes: Scalars['Float']['output'];
  /** average total comments for Tagged OR Mentioned */
  avgTotalComments: Scalars['Float']['output'];
  /** average total likes for Tagged OR Mentioned */
  avgTotalLikes: Scalars['Float']['output'];
  /** total posts count for Mentioned only */
  mentionedPosts: Scalars['Int']['output'];
  /** total posts count for Tagged only */
  taggedPosts: Scalars['Int']['output'];
  /** total posts count for Tagged OR Mentioned */
  totalPostsCount: Scalars['Int']['output'];
  username: Scalars['String']['output'];
}

export interface InstagramInteractionUserAnalyticsDetailsAndCount {
  totalNumber: Scalars['Int']['output'];
  usernames?: Maybe<Array<InstagramInteractionUserAnalyticsDetails>>;
}

export interface InstagramInteractionUserOrderBy {
  field?: InputMaybe<InstagramInteractionUserSortField>;
  order?: InputMaybe<ORDER>;
}

/** An enumeration. */
export enum InstagramInteractionUserSortField {
  COMMENT = 'COMMENT',
  LIKE = 'LIKE',
  MENTIONED_COMMENT = 'MENTIONED_COMMENT',
  MENTIONED_LIKE = 'MENTIONED_LIKE',
  MENTIONED_POST = 'MENTIONED_POST',
  POST = 'POST',
  TAGGED_COMMENT = 'TAGGED_COMMENT',
  TAGGED_LIKE = 'TAGGED_LIKE',
  TAGGED_POST = 'TAGGED_POST',
}

export interface InstagramPopularHashtags {
  averageComments?: Maybe<Scalars['Int']['output']>;
  averageEngagement?: Maybe<Scalars['Int']['output']>;
  averageLikes?: Maybe<Scalars['Int']['output']>;
  hashtag?: Maybe<Scalars['String']['output']>;
  usedPosts?: Maybe<Scalars['Int']['output']>;
}

export interface InstagramPostAverageEngagement {
  averageComments?: Maybe<Scalars['Float']['output']>;
  averageCommentsGrowth?: Maybe<Scalars['Float']['output']>;
  averageLikes?: Maybe<Scalars['Float']['output']>;
  averageLikesGrowth?: Maybe<Scalars['Float']['output']>;
  averagePostsGrowth?: Maybe<Scalars['Float']['output']>;
  averagePostsPerWeek?: Maybe<Scalars['Float']['output']>;
  averageSaved?: Maybe<Scalars['Float']['output']>;
  averageSavedGrowth?: Maybe<Scalars['Float']['output']>;
}

export interface InstagramPostSortInput {
  field?: InputMaybe<InstagramPostSortOrder>;
  order?: InputMaybe<Order>;
}

export enum InstagramPostSortOrder {
  COMMENTS = 'COMMENTS',
  ENGAGEMENT = 'ENGAGEMENT',
  LIKES = 'LIKES',
  POST_DATE = 'POST_DATE',
  RANK = 'RANK',
}

export interface InstagramPostsHabit {
  averageComments: Scalars['Float']['output'];
  averageLikes: Scalars['Float']['output'];
  engagementRate: Scalars['Float']['output'];
  hour: Scalars['Int']['output'];
  weekday: Scalars['Int']['output'];
}

export interface InstagramReelPost {
  comments?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  likes?: Maybe<Scalars['Int']['output']>;
  postUrl: Scalars['String']['output'];
  thumbNail?: Maybe<Scalars['String']['output']>;
}

export interface InstagramReelPostItem {
  comments?: Maybe<Scalars['Int']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  likes?: Maybe<Scalars['Int']['output']>;
  postDate?: Maybe<Scalars['DateTime']['output']>;
  postUrl: Scalars['String']['output'];
  reach?: Maybe<Scalars['Int']['output']>;
  saved?: Maybe<Scalars['Int']['output']>;
  shares?: Maybe<Scalars['Int']['output']>;
  thumbNail?: Maybe<Scalars['String']['output']>;
  views?: Maybe<Scalars['Int']['output']>;
}

export interface InstagramReelPostOrderBy {
  field?: InputMaybe<InstagramReelPostSortField>;
  order?: InputMaybe<ORDER>;
}

/** An enumeration. */
export enum InstagramReelPostSortField {
  COMMENT = 'COMMENT',
  LIKES = 'LIKES',
  POST_DATE = 'POST_DATE',
  REACH = 'REACH',
  SAVED = 'SAVED',
  SHARES = 'SHARES',
  VIEWS = 'VIEWS',
}

export interface InstagramStoryPost {
  comments?: Maybe<Scalars['Int']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  exits?: Maybe<Scalars['Int']['output']>;
  follow?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  impressions?: Maybe<Scalars['Int']['output']>;
  interaction?: Maybe<Scalars['Int']['output']>;
  linkClicks?: Maybe<Scalars['Int']['output']>;
  postDate?: Maybe<Scalars['DateTime']['output']>;
  postUrl: Scalars['String']['output'];
  productButtonClick?: Maybe<Scalars['Int']['output']>;
  productPageView?: Maybe<Scalars['Int']['output']>;
  reach?: Maybe<Scalars['Int']['output']>;
  stickerTaps?: Maybe<Scalars['Int']['output']>;
  swipeForward?: Maybe<Scalars['Int']['output']>;
  tapsBack?: Maybe<Scalars['Int']['output']>;
  tapsForward?: Maybe<Scalars['Int']['output']>;
  thumbNail?: Maybe<Scalars['String']['output']>;
}

export interface InstagramStoryPostOrderBy {
  field?: InputMaybe<InstagramStoryPostSortField>;
  order?: InputMaybe<ORDER>;
}

/** An enumeration. */
export enum InstagramStoryPostSortField {
  COMMENT = 'COMMENT',
  EXITS = 'EXITS',
  FOLLOW = 'FOLLOW',
  IMPRESSIONS = 'IMPRESSIONS',
  INTERACTION = 'INTERACTION',
  LINK_CLICKS = 'LINK_CLICKS',
  POST_DATE = 'POST_DATE',
  PRODUCT_BUTTON_CLICK = 'PRODUCT_BUTTON_CLICK',
  PRODUCT_PAGE_VIEW = 'PRODUCT_PAGE_VIEW',
  REACH = 'REACH',
  STICKER_TAPS = 'STICKER_TAPS',
  SWIPE_FORWARD = 'SWIPE_FORWARD',
  TAPS_BACK = 'TAPS_BACK',
  TAPS_FORWARD = 'TAPS_FORWARD',
}

export interface InstagramUGCAddedHashtagPayload {
  hashtag: Scalars['String']['output'];
  hashtagSystemId: Scalars['Int']['output'];
}

export interface InstagramUGCManagementBasicInfoPayload {
  addedHashtags: Array<InstagramUGCAddedHashtagPayload>;
  brandInstagramUserName: Scalars['String']['output'];
}

export interface InstagramUGCManagementListPostsCountPayload {
  totalNumber: Scalars['Int']['output'];
}

export interface InstagramUGCManagementListPostsItemPayload {
  comments: Scalars['Int']['output'];
  likes: Scalars['Int']['output'];
  postSnsId: Scalars['String']['output'];
  status: UGCPostStatus;
  thumbnail?: Maybe<Scalars['String']['output']>;
}

export interface InstagramUGCManagementListPostsPayload {
  posts: Array<InstagramUGCManagementListPostsItemPayload>;
}

export interface InstagramUGCManagementPostDetailsPayload {
  commentingAllowed: Scalars['Boolean']['output'];
  comments: Scalars['Int']['output'];
  content: Scalars['String']['output'];
  likes: Scalars['Int']['output'];
  mediaURLs: Array<Scalars['String']['output']>;
  postDate: Scalars['Date']['output'];
  postSNSId: Scalars['String']['output'];
  postUrl: Scalars['String']['output'];
  showStaffRequest: Scalars['Boolean']['output'];
  staffComment?: Maybe<StaffComment>;
  status: UGCPostStatus;
}

export interface InstagramUGCPostsSortInput {
  field: InstagramUGCPostsSortOrder;
  order: Order;
}

export enum InstagramUGCPostsSortOrder {
  ENGAGEMENT = 'ENGAGEMENT',
  POST_DATE = 'POST_DATE',
}

export interface InstagramUserHashtag {
  hashtag: Scalars['String']['output'];
  hashtagId: Scalars['Int']['output'];
}

export interface InstagramUserHashtagListPostsItemPayload {
  accountId?: Maybe<Scalars['Int']['output']>;
  avatar: Scalars['String']['output'];
  comments: Scalars['Int']['output'];
  content: Scalars['String']['output'];
  engagement: Scalars['Int']['output'];
  followersCount?: Maybe<Scalars['Int']['output']>;
  influencerId?: Maybe<Scalars['Int']['output']>;
  likes: Scalars['Int']['output'];
  media: Array<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  postDate: Scalars['Date']['output'];
  postId: Scalars['Int']['output'];
  rank: Scalars['Int']['output'];
}

export interface InstagramUserHashtagListPostsPayload {
  count: Scalars['Int']['output'];
  posts: Array<InstagramUserHashtagListPostsItemPayload>;
}

export interface InstagramUserHashtagPayload {
  hashtags: Array<InstagramUserHashtag>;
}

export interface InstagramUserHashtagPostsSortInput {
  field?: InputMaybe<InstagramUserHashtagPostsSortOrder>;
  order?: InputMaybe<Order>;
}

export enum InstagramUserHashtagPostsSortOrder {
  COMMENTS = 'COMMENTS',
  ENGAGEMENT = 'ENGAGEMENT',
  LIKES = 'LIKES',
  POST_DATE = 'POST_DATE',
  RANK = 'RANK',
}

export interface IsInfluencerUUUM {
  isUuum: Scalars['Boolean']['output'];
}

export interface JoinAutoManagedCampaignInput {
  campaignId: Scalars['ID']['input'];
  methodIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  methods?: InputMaybe<Array<CampaignPromotionMethod>>;
}

export interface JoinAutoManagedCampaignPayload {
  id: Scalars['ID']['output'];
  ok: Scalars['Boolean']['output'];
}

export interface JoinMarketplaceByStaff {
  ok: Scalars['Boolean']['output'];
}

export interface JoinMarketplaceByStaffInput {
  campaignId: Scalars['Int']['input'];
  influencerIds: Array<Scalars['Int']['input']>;
  /** for mkp affiliate campaign without post-tracking */
  methods?: InputMaybe<Array<CampaignPromotionMethod>>;
}

export interface JoinMarketplaceV2 {
  ok: Scalars['Boolean']['output'];
}

export interface JoinMarketplaceV2Input {
  campaignId: Scalars['Int']['input'];
  isMobile?: InputMaybe<Scalars['Boolean']['input']>;
  methodIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  methods?: InputMaybe<Array<CampaignPromotionMethod>>;
  socialAccountId?: InputMaybe<Scalars['Int']['input']>;
  socialMedia?: InputMaybe<SocialAccountType>;
}

export interface JoinTiktokSpecialCampaign {
  ok: Scalars['Boolean']['output'];
}

export interface JoinTiktokSpecialCampaignInput {
  campaignId: Scalars['Int']['input'];
  socialAccountId: Scalars['Int']['input'];
}

export interface JoinableAccount {
  activeToken: Scalars['Boolean']['output'];
  avatar?: Maybe<Scalars['String']['output']>;
  disabled: Scalars['Boolean']['output'];
  followersCount: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  username: Scalars['String']['output'];
}

export interface JoinableFbAccount {
  activeToken: Scalars['Boolean']['output'];
  avatar?: Maybe<Scalars['String']['output']>;
  disabled: Scalars['Boolean']['output'];
  followersCount: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  userId?: Maybe<Scalars['String']['output']>;
  username: Scalars['String']['output'];
}

export interface JoinableInstagramAccount {
  activeToken: Scalars['Boolean']['output'];
  avatar?: Maybe<Scalars['String']['output']>;
  disabled: Scalars['Boolean']['output'];
  followersCount: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  isBusinessAccount: Scalars['Boolean']['output'];
  username: Scalars['String']['output'];
}

export interface JoinablePromotionMethodEmailNewsLetterPayload {
  description: Scalars['String']['output'];
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
}

export interface JoinablePromotionMethodMobileAppPayload {
  id: Scalars['ID']['output'];
  mobileAppType: PromotionMobileAppType;
  url: Scalars['String']['output'];
}

export interface JoinablePromotionMethodOfflinePayload {
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
}

export interface JoinablePromotionMethodPodCastPayload {
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  url: Scalars['String']['output'];
}

export interface JoinablePromotionMethodSocialAccountPayload {
  avatar: Scalars['String']['output'];
  followersCount: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
}

export interface JoinablePromotionMethodWebsitePayload {
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  url: Scalars['String']['output'];
}

export interface JoinedCampaign {
  id: Scalars['Int']['output'];
  socialAccountId?: Maybe<Scalars['Int']['output']>;
  socialAccountType?: Maybe<SocialAccountType>;
  title: Scalars['String']['output'];
  type: CampaignType;
}

export interface JoinedPromotionMethodEmailNewsLetterPayload {
  description?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
}

export interface JoinedPromotionMethodMobileAppPayload {
  id: Scalars['ID']['output'];
  mobileAppType?: Maybe<PromotionMobileAppType>;
  url?: Maybe<Scalars['String']['output']>;
}

export interface JoinedPromotionMethodOfflinePayload {
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
}

export interface JoinedPromotionMethodPodCastPayload {
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  url?: Maybe<Scalars['String']['output']>;
}

export interface JoinedPromotionMethodSocialAccountPayload {
  avatar: Scalars['String']['output'];
  followersCount: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
}

export interface JoinedPromotionMethodWebsitePayload {
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  url?: Maybe<Scalars['String']['output']>;
}

export interface LineBroadcast {
  click?: Maybe<Scalars['Int']['output']>;
  clickRate?: Maybe<Scalars['Float']['output']>;
  createdDateTime: Scalars['String']['output'];
  delivered?: Maybe<Scalars['Int']['output']>;
  deliveredDateTime?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  imageUrl: Scalars['String']['output'];
  open?: Maybe<Scalars['Int']['output']>;
  openRate?: Maybe<Scalars['Float']['output']>;
  status: LineBroadcastStatus;
  tags: Array<Scalars['String']['output']>;
  title: Scalars['String']['output'];
}

export interface LineBroadcastFloatValue {
  count: Scalars['Float']['output'];
  growth?: Maybe<Scalars['Float']['output']>;
  percentage?: Maybe<Scalars['Float']['output']>;
}

export interface LineBroadcastIntValue {
  count: Scalars['Int']['output'];
  growth?: Maybe<Scalars['Int']['output']>;
  percentage?: Maybe<Scalars['Float']['output']>;
}

export enum LineBroadcastStatus {
  DELIVERED = 'DELIVERED',
  DRAFT = 'DRAFT',
  ERROR = 'ERROR',
  SCHEDULED = 'SCHEDULED',
  SENDING = 'SENDING',
}

export interface LineChat {
  chatId: Scalars['String']['output'];
  checked: Scalars['Boolean']['output'];
  checkedAt?: Maybe<Scalars['Long']['output']>;
  fanId?: Maybe<Scalars['Long']['output']>;
  lastMessage: Scalars['String']['output'];
  lastMessageAt: Scalars['Long']['output'];
  pic?: Maybe<LineChatPIC>;
  resolved: Scalars['Boolean']['output'];
  userAvatar?: Maybe<Scalars['String']['output']>;
  userEmail?: Maybe<Scalars['String']['output']>;
  userId: Scalars['String']['output'];
  userName: Scalars['String']['output'];
}

export interface LineChatPIC {
  id: Scalars['Long']['output'];
  name: Scalars['String']['output'];
}

export interface LineContentItemInput {
  buttonMessage?: InputMaybe<ButtonMessageInput>;
  imageMessage?: InputMaybe<ImageMessageInput>;
  mediaMessage?: InputMaybe<ImageVideoMessageInput>;
  plainTextMessage?: InputMaybe<PlainTextMessageInput>;
  richMessage?: InputMaybe<RichMessageInput>;
  type: LineMessageType;
}

export interface LineContentItemPayload {
  buttonMessage?: Maybe<ButtonMessagePayload>;
  imageMessage?: Maybe<ImageMessagePayload>;
  mediaMessage?: Maybe<ImageVideoMessagePayload>;
  plainTextMessage?: Maybe<PlainTextMessagePayload>;
  richMessage?: Maybe<RichMessagePayload>;
  type: LineMessageType;
}

export enum LineMessageType {
  BUTTON = 'BUTTON',
  IMAGES = 'IMAGES',
  IMAGE_VIDEO = 'IMAGE_VIDEO',
  PLAIN_TEXT = 'PLAIN_TEXT',
  RICH = 'RICH',
}

export interface LinkBioBackground {
  bgColor?: Maybe<Scalars['String']['output']>;
  media?: Maybe<Scalars['String']['output']>;
}

export interface LinkBioPageColor {
  buttonColor?: Maybe<Scalars['String']['output']>;
  buttonFontColor?: Maybe<Scalars['String']['output']>;
  fontColor?: Maybe<Scalars['String']['output']>;
}

export interface LinkCmsChannelAssets {
  ok: Scalars['Boolean']['output'];
}

export interface LinkCmsChannelAssetsInput {
  records: Array<CmsChannelAssetInput>;
}

export interface Logo {
  logoUrl?: Maybe<Scalars['String']['output']>;
}

export interface MadeMarketplaceCampaignPost {
  ok: Scalars['Boolean']['output'];
}

export interface MadeMarketplaceCampaignPostInput {
  campaignId: Scalars['Int']['input'];
}

export interface MajorCategory {
  id: Scalars['Int']['output'];
  minorCategories: Array<MinorCategory>;
  name: Scalars['String']['output'];
}

/**  `MarkReadNotificationByAppInput` input accepts `appType`: a enum type which represents the notification scope of application */
export interface MarkReadNotificationByAppInput {
  appType: AppType;
}

/**  `MarkReadNotificationPayload` payload houses an `ok` boolean to determine the success of the operation. */
export interface MarkReadNotificationPayload {
  ok: Scalars['Boolean']['output'];
}

export interface Marketplace {
  advertiser: AdvertiserName;
  agencyMarginRate?: Maybe<Scalars['Float']['output']>;
  budget: Scalars['Float']['output'];
  campaignType: MarketplaceCampaignDetailType;
  commissionRate?: Maybe<Scalars['Float']['output']>;
  /** for TUNE Tracking */
  costPerAction?: Maybe<Scalars['Float']['output']>;
  /** for TUNE Tracking */
  costPerClick?: Maybe<Scalars['Float']['output']>;
  costPerComment?: Maybe<Scalars['Float']['output']>;
  costPerFollower?: Maybe<Scalars['Float']['output']>;
  costPerLike?: Maybe<Scalars['Float']['output']>;
  /** for referral code Tracking */
  costPerOrder?: Maybe<Scalars['Float']['output']>;
  costPerPost?: Maybe<Scalars['Float']['output']>;
  /** for referral code Tracking */
  costPerSaleReferral?: Maybe<Scalars['Float']['output']>;
  /** for TUNE Tracking */
  costPerSaleTune?: Maybe<Scalars['Float']['output']>;
  costPerShare?: Maybe<Scalars['Float']['output']>;
  costPerView?: Maybe<Scalars['Float']['output']>;
  country: CountryName;
  currency: Scalars['String']['output'];
  currentCost?: Maybe<Scalars['Int']['output']>;
  endDate?: Maybe<Scalars['Date']['output']>;
  id: Scalars['Int']['output'];
  influencerManagementPics?: Maybe<Array<PicName>>;
  isPostTracking: Scalars['Boolean']['output'];
  isSelectionCampaign: Scalars['Boolean']['output'];
  marginRate: Scalars['Float']['output'];
  salesPics?: Maybe<Array<PicName>>;
  socialMedia: CampaignSocialMediaType;
  startDate: Scalars['Date']['output'];
  status: MarketplaceCampaignStatus;
  title: Scalars['String']['output'];
}

export interface MarketplaceAffiliateCommissionFeeInput {
  commissionFee: Scalars['Float']['input'];
  influencerId: Scalars['Int']['input'];
}

export interface MarketplaceAffiliateCommissionInfluencer {
  currency: Scalars['String']['output'];
  endCoverageDate: Scalars['Date']['output'];
  fixedDate: Scalars['Date']['output'];
  influencer: MarketplacePostInfluencer;
  postId: Scalars['Int']['output'];
  revenue: Scalars['Float']['output'];
  startCoverageDate: Scalars['Date']['output'];
  status: MarketplaceAffiliateCommissionStatus;
}

export interface MarketplaceAffiliateCommissionRateInput {
  commissionRate: Scalars['Float']['input'];
  influencerId: Scalars['Int']['input'];
}

/** An enumeration. */
export enum MarketplaceAffiliateCommissionStatus {
  APPROVED = 'APPROVED',
  UNAPPROVED = 'UNAPPROVED',
}

export interface MarketplaceAffiliateJoinedInfluencer {
  costEditable?: Maybe<Scalars['Boolean']['output']>;
  couponCode?: Maybe<Scalars['String']['output']>;
  influencer: MarketplacePostInfluencer;
  joinedDate: Scalars['Date']['output'];
  promotionMethods: Array<CampaignPromotionMethod>;
  revenueItems: Array<MarketplaceRevenueItem>;
}

export interface MarketplaceAffiliatePayoutPeriodInput {
  fixedDate: Scalars['Date']['input'];
  postId: Scalars['Int']['input'];
}

export interface MarketplaceAffiliateReportByDate {
  clicks: Scalars['Int']['output'];
  conversions: Scalars['Int']['output'];
  cost: Scalars['Float']['output'];
  cvr?: Maybe<Scalars['Float']['output']>;
  date: Scalars['Date']['output'];
  grossProfit: Scalars['Float']['output'];
  influencerCost: Scalars['Float']['output'];
  sales: Scalars['Float']['output'];
}

export interface MarketplaceAffiliateReportByInfluencer {
  clicks: Scalars['Int']['output'];
  commissionRate: Scalars['Float']['output'];
  conversions: Scalars['Int']['output'];
  cost: Scalars['Float']['output'];
  cvr?: Maybe<Scalars['Float']['output']>;
  influencerCost: Scalars['Float']['output'];
  influencerInfo: CampaignInfluencerInfo;
  promotionMethods: Array<CampaignPromotionMethod>;
  sales: Scalars['Float']['output'];
}

/** An enumeration. */
export enum MarketplaceAffiliateReportByInfluencerField {
  CLICK = 'CLICK',
  COST = 'COST',
  CVR = 'CVR',
  INFLUENCER_COMMISSION_RATE = 'INFLUENCER_COMMISSION_RATE',
  INFLUENCER_COST = 'INFLUENCER_COST',
  ORDER = 'ORDER',
  SALE = 'SALE',
}

export interface MarketplaceAffiliateReportByInfluencerOrderBy {
  field?: InputMaybe<MarketplaceAffiliateReportByInfluencerField>;
  order?: InputMaybe<ORDER>;
}

export interface MarketplaceAffiliateReportForInfluencer {
  clicks: Scalars['Int']['output'];
  commission: Scalars['Float']['output'];
  conversions: Scalars['Int']['output'];
  date: Scalars['Date']['output'];
}

export interface MarketplaceAffiliateReportSummary {
  average: MarketplaceAffiliateStatsItem;
  chartData: Array<AffiliateCampaignChartReport>;
  joinedCount: Scalars['Int']['output'];
  summary: MarketplaceAffiliateStatsItem;
}

export interface MarketplaceAffiliateReportSummaryForInfluencer {
  clicks: Scalars['Int']['output'];
  commission: Scalars['Float']['output'];
  conversions: Scalars['Int']['output'];
  currency: Scalars['String']['output'];
}

export interface MarketplaceAffiliateStatsItem {
  clicks: Scalars['Int']['output'];
  conversions: Scalars['Int']['output'];
  cost: Scalars['Float']['output'];
  cvr?: Maybe<Scalars['Float']['output']>;
  grossProfit: Scalars['Float']['output'];
  influencerCost: Scalars['Float']['output'];
  sales: Scalars['Float']['output'];
}

/** An enumeration. */
export enum MarketplaceCampaignAppliedStatus {
  APPLYING = 'APPLYING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export interface MarketplaceCampaignCouponTrackingInput {
  campaignId: Scalars['Int']['input'];
  couponCode: Scalars['String']['input'];
  saleAmount: Scalars['Float']['input'];
  transactionId: Scalars['String']['input'];
}

export interface MarketplaceCampaignCouponTrackingPayload {
  ok: Scalars['Boolean']['output'];
}

export enum MarketplaceCampaignDetailType {
  E_COMMERCE = 'E_COMMERCE',
  NORMAL = 'NORMAL',
  REFERRAL_CODE = 'REFERRAL_CODE',
  URL_TRACKING = 'URL_TRACKING',
}

/** An enumeration. */
export enum MarketplaceCampaignDraftPostStatus {
  APPROVED = 'APPROVED',
  DRAFT = 'DRAFT',
  REJECTED = 'REJECTED',
}

/**
 *
 *     status of influencer and MKP participation for Staff to force influencer joins campaign
 *
 */
export enum MarketplaceCampaignParticipateStatus {
  APPLIED = 'APPLIED',
  AVAILABLE = 'AVAILABLE',
  JOINED = 'JOINED',
  REJECTED = 'REJECTED',
  UNAVAILABLE = 'UNAVAILABLE',
  WAITING = 'WAITING',
}

/** An enumeration. */
export enum MarketplaceCampaignPostHistoryStatus {
  APPROVED = 'APPROVED',
  CREATED = 'CREATED',
  DRAFT = 'DRAFT',
  DRAFT_REJECTED = 'DRAFT_REJECTED',
  JOINED = 'JOINED',
  READY_TO_POST = 'READY_TO_POST',
  REJECTED = 'REJECTED',
}

export interface MarketplaceCampaignPostReportForInfluencer {
  currency: Scalars['String']['output'];
  /** campaign ID */
  id: Scalars['Int']['output'];
  postReport: InfluencerPostReportStatsMarketplace;
  postReports: Array<InfluencerPostReportStatsMarketplace>;
  /** campaign revenue */
  revenue: Scalars['Float']['output'];
  /** campaign title */
  title: Scalars['String']['output'];
}

/** An enumeration. */
export enum MarketplaceCampaignPostStatus {
  AFFILIATE = 'AFFILIATE',
  APPROVED = 'APPROVED',
  EFFECTIVE = 'EFFECTIVE',
  INEFFECTIVE = 'INEFFECTIVE',
  SHIPPED = 'SHIPPED',
  TRACKING = 'TRACKING',
  WARNING = 'WARNING',
  WARNING_SOLVED = 'WARNING_SOLVED',
}

/** An enumeration. */
export enum MarketplaceCampaignPostStatusForAdvertiser {
  APPROVED = 'APPROVED',
  DRAFT = 'DRAFT',
  DRAFT_REJECTED = 'DRAFT_REJECTED',
  EFFECTIVE = 'EFFECTIVE',
  INEFFECTIVE = 'INEFFECTIVE',
  JOINED = 'JOINED',
  READY_TO_POST = 'READY_TO_POST',
  SHIPPED = 'SHIPPED',
  TRACKING = 'TRACKING',
  WARNING = 'WARNING',
}

/** An enumeration. */
export enum MarketplaceCampaignPostStatusForSearch {
  AD_FRAUD = 'AD_FRAUD',
  API_ERROR = 'API_ERROR',
  APPROVED = 'APPROVED',
  DELETED_POST = 'DELETED_POST',
  DRAFT = 'DRAFT',
  EFFECTIVE = 'EFFECTIVE',
  INEFFECTIVE = 'INEFFECTIVE',
  INVALID_CONTENT = 'INVALID_CONTENT',
  INVALID_TOKEN = 'INVALID_TOKEN',
  JOINED = 'JOINED',
  READY_TO_POST = 'READY_TO_POST',
  SCRAPING_ERROR = 'SCRAPING_ERROR',
  TRACKING = 'TRACKING',
  WARNING = 'WARNING',
}

/** An enumeration. */
export enum MarketplaceCampaignRejectedStatus {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  REVIEWING = 'REVIEWING',
}

export interface MarketplaceCampaignReport {
  InternalInfo?: Maybe<Array<Scalars['String']['output']>>;
  average?: Maybe<NewCampaignStatsItemRow>;
  campaignInfo: CampaignReportCampaignInfo;
  chartData: Array<CampaignChartReport>;
  currency: Scalars['String']['output'];
  influencerReports: Array<CampaignInfluencerReport>;
  orderedSummaryDaily?: Maybe<Array<CampaignSummaryDaily>>;
  postCount: Scalars['Int']['output'];
  summary: NewCampaignStatsItemRow;
  summaryDaily?: Maybe<Array<CampaignSummaryDaily>>;
}

/** An enumeration. */
export enum MarketplaceCampaignRevenueType {
  INFLUENCER_COMMISSION_RATE = 'INFLUENCER_COMMISSION_RATE',
  REVENUE_PER_ACTION = 'REVENUE_PER_ACTION',
  REVENUE_PER_CLICK = 'REVENUE_PER_CLICK',
  REVENUE_PER_COMMENT = 'REVENUE_PER_COMMENT',
  REVENUE_PER_LIKE = 'REVENUE_PER_LIKE',
  REVENUE_PER_POST = 'REVENUE_PER_POST',
  REVENUE_PER_SHARE = 'REVENUE_PER_SHARE',
  REVENUE_PER_VIEW = 'REVENUE_PER_VIEW',
}

export enum MarketplaceCampaignStatus {
  FINISHED = 'FINISHED',
  ONGOING = 'ONGOING',
  OUT_OF_COUPON = 'OUT_OF_COUPON',
  REJECTED = 'REJECTED',
  REVIEWING = 'REVIEWING',
  SUSPENDED = 'SUSPENDED',
  UPCOMING = 'UPCOMING',
}

/** An enumeration. */
export enum MarketplaceCampaignType {
  AFFILIATE = 'AFFILIATE',
  AFFILIATE_SPECIAL_DEAL = 'AFFILIATE_SPECIAL_DEAL',
  NORMAL = 'NORMAL',
  SELECTION = 'SELECTION',
}

export interface MarketplaceDetail {
  additionalRequirement?: Maybe<Scalars['String']['output']>;
  advertiser: AdvertiserName;
  agencyMarginRate?: Maybe<Scalars['Float']['output']>;
  allowNewInfluencer?: Maybe<Scalars['Boolean']['output']>;
  brandName?: Maybe<Scalars['String']['output']>;
  budget: Scalars['Float']['output'];
  campaignCategory?: Maybe<CampaignCategoryName>;
  campaignCreator?: Maybe<CampaignCreatorWithName>;
  campaignType: MarketplaceCampaignDetailType;
  campaignUrl?: Maybe<Scalars['String']['output']>;
  categories: Array<CategoryName>;
  clickUrl?: Maybe<Scalars['String']['output']>;
  commissionRate?: Maybe<Scalars['Float']['output']>;
  /** for TUNE Tracking */
  costPerAction?: Maybe<Scalars['Float']['output']>;
  /** for TUNE Tracking */
  costPerClick?: Maybe<Scalars['Float']['output']>;
  costPerComment?: Maybe<Scalars['Float']['output']>;
  costPerFollower?: Maybe<Scalars['Float']['output']>;
  costPerLike?: Maybe<Scalars['Float']['output']>;
  /** for referral code Tracking */
  costPerOrder?: Maybe<Scalars['Float']['output']>;
  costPerPost?: Maybe<Scalars['Float']['output']>;
  /** for referral code Tracking */
  costPerSaleReferral?: Maybe<Scalars['Float']['output']>;
  /** for TUNE Tracking */
  costPerSaleTune?: Maybe<Scalars['Float']['output']>;
  costPerShare?: Maybe<Scalars['Float']['output']>;
  costPerView?: Maybe<Scalars['Float']['output']>;
  country: CountryName;
  couponUploadFiles?: Maybe<Array<Scalars['String']['output']>>;
  currency: Scalars['String']['output'];
  currentCost?: Maybe<Scalars['Int']['output']>;
  endDate?: Maybe<Scalars['Date']['output']>;
  genders: Array<Genders>;
  hashtags: Array<Scalars['String']['output']>;
  hubspotDealIds?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['Int']['output'];
  influencerManagementPics?: Maybe<Array<PicName>>;
  isAllowMultiplePosts?: Maybe<Scalars['Boolean']['output']>;
  isAutoInfluencerApproval?: Maybe<Scalars['Boolean']['output']>;
  isGiftingCampaign: Scalars['Boolean']['output'];
  isPostTracking: Scalars['Boolean']['output'];
  isSelectionCampaign: Scalars['Boolean']['output'];
  landingPageUrl?: Maybe<Scalars['String']['output']>;
  marginRate: Scalars['Float']['output'];
  materialUrl?: Maybe<Scalars['String']['output']>;
  materials?: Maybe<Array<Scalars['String']['output']>>;
  maxAge: Scalars['Int']['output'];
  maxFollowers: Scalars['Int']['output'];
  maximumRevenuePerInfluencer?: Maybe<Scalars['Float']['output']>;
  minAge: Scalars['Int']['output'];
  minFollowers: Scalars['Int']['output'];
  mkpServiceCampaignId?: Maybe<Scalars['Int']['output']>;
  needCouponsCount?: Maybe<Scalars['Int']['output']>;
  preLaunchDate?: Maybe<Scalars['Date']['output']>;
  productThumbNail?: Maybe<Scalars['String']['output']>;
  regions?: Maybe<Array<RegionName>>;
  rejectedInfo?: Maybe<MarketplaceRejectedAdvertiserInfo>;
  requireDraftPost: Scalars['Boolean']['output'];
  requirement: Scalars['String']['output'];
  salesPics?: Maybe<Array<PicName>>;
  sampleUrl?: Maybe<Scalars['String']['output']>;
  serviceInformation: Scalars['String']['output'];
  socialMedia: CampaignSocialMediaType;
  startDate: Scalars['Date']['output'];
  status: MarketplaceCampaignStatus;
  thumbnails: Array<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  trackingOptions?: Maybe<Array<CampaignTrackingOption>>;
}

export interface MarketplaceDetailForInfluencer {
  ableToJoin?: Maybe<Scalars['Boolean']['output']>;
  additionalRequirement?: Maybe<Scalars['String']['output']>;
  /** campaign status using for AnyX */
  anyXStatus?: Maybe<AnyXMarketplaceCampaignStatus>;
  /** exists when campaign type is SELECTION */
  appliedStatus?: Maybe<MarketplaceCampaignAppliedStatus>;
  campaignDetailType: MarketplaceCampaignDetailType;
  /** @deprecated not useful anymore */
  campaignType: MarketplaceCampaignType;
  clickUrl?: Maybe<Scalars['String']['output']>;
  commissionRate?: Maybe<Scalars['Float']['output']>;
  confirmMadePostAvailability?: Maybe<Scalars['Boolean']['output']>;
  couponCode?: Maybe<Scalars['String']['output']>;
  createdDate: Scalars['Date']['output'];
  currency: Scalars['String']['output'];
  endDate?: Maybe<Scalars['Date']['output']>;
  /** Whether she uploaded her post on social media or not. */
  hasReport: Scalars['Boolean']['output'];
  hashtags: Array<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  isApplied: Scalars['Boolean']['output'];
  isAutoInfluencerApproval?: Maybe<Scalars['Boolean']['output']>;
  isCouponCampaign?: Maybe<Scalars['Boolean']['output']>;
  isGiftingCampaign?: Maybe<Scalars['Boolean']['output']>;
  isJoined: Scalars['Boolean']['output'];
  isPostTracking?: Maybe<Scalars['Boolean']['output']>;
  isSelectionCampaign?: Maybe<Scalars['Boolean']['output']>;
  isWaiting: Scalars['Boolean']['output'];
  materialUrl?: Maybe<Scalars['String']['output']>;
  materials?: Maybe<Array<MaterialName>>;
  maxFollowers: Scalars['Int']['output'];
  maximumRevenuePerInfluencer?: Maybe<Scalars['Float']['output']>;
  minFollowers: Scalars['Int']['output'];
  minimumPaymentAmount: Scalars['Float']['output'];
  postStatus?: Maybe<CampaignPostStatusForInfluencer>;
  postTrackingUrl?: Maybe<Scalars['String']['output']>;
  postUrl?: Maybe<Scalars['String']['output']>;
  preLaunchDate?: Maybe<Scalars['Date']['output']>;
  remainingAmount: Scalars['Float']['output'];
  requireDraftPost: Scalars['Boolean']['output'];
  requirement: Scalars['String']['output'];
  revenuePerAction?: Maybe<Scalars['Float']['output']>;
  revenuePerClick?: Maybe<Scalars['Float']['output']>;
  revenuePerComment?: Maybe<Scalars['Float']['output']>;
  revenuePerFollower?: Maybe<Scalars['Float']['output']>;
  revenuePerLike?: Maybe<Scalars['Float']['output']>;
  revenuePerOrder?: Maybe<Scalars['Float']['output']>;
  revenuePerPost?: Maybe<Scalars['Float']['output']>;
  revenuePerShare?: Maybe<Scalars['Float']['output']>;
  revenuePerView?: Maybe<Scalars['Float']['output']>;
  sampleUrl?: Maybe<Scalars['String']['output']>;
  serviceInformation: Scalars['String']['output'];
  socialMedias: Array<CampaignSocialMediaType>;
  startDate: Scalars['Date']['output'];
  status: CampaignStatusForInfluencer;
  thumbNail: Scalars['String']['output'];
  thumbnails: Array<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  totalAmount: Scalars['Float']['output'];
  trackingOptions?: Maybe<Array<CampaignTrackingOption>>;
  trackingUrl?: Maybe<Scalars['String']['output']>;
  type: CampaignType;
  warningReason?: Maybe<WarningReason>;
}

export interface MarketplaceDraftPostDetail {
  caption: Scalars['String']['output'];
  createdDate?: Maybe<Scalars['Date']['output']>;
  id: Scalars['Int']['output'];
  images?: Maybe<Array<Scalars['String']['output']>>;
  influencer: MarketplacePostDetailInfluencer;
  rejectedDate?: Maybe<Scalars['Date']['output']>;
  rejectedMessage?: Maybe<Scalars['String']['output']>;
  status: MarketplaceCampaignDraftPostStatus;
}

export interface MarketplaceDraftPostForInfluencer {
  content: Scalars['String']['output'];
  materials: Array<Scalars['String']['output']>;
  rejectedMessage?: Maybe<Scalars['String']['output']>;
  status: MarketplaceCampaignDraftPostStatus;
}

export interface MarketplaceForReorder {
  id: Scalars['Int']['output'];
  status: MarketplaceCampaignStatus;
  thumbnail: Scalars['String']['output'];
  title: Scalars['String']['output'];
}

export interface MarketplaceInfluencer {
  age?: Maybe<Scalars['Int']['output']>;
  avatar?: Maybe<Scalars['String']['output']>;
  categories: Array<CategoryName>;
  country: CountryNameForInfluencer;
  email?: Maybe<Scalars['Boolean']['output']>;
  engagement: InfluencerEngagement;
  facebook?: Maybe<SocialAccountV2>;
  followersCount?: Maybe<Scalars['Int']['output']>;
  gender: Genders;
  id: Scalars['Int']['output'];
  instagram?: Maybe<SocialAccountV2>;
  marketplaceJoinedCount?: Maybe<Scalars['Int']['output']>;
  marketplacePostedCount?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  participateStatus: MarketplaceCampaignParticipateStatus;
  status: SocialAccountStatus;
  tiktok?: Maybe<SocialAccountV2>;
  twitter?: Maybe<SocialAccountV2>;
  youtube?: Maybe<SocialAccountV2>;
}

export interface MarketplaceInfluencerRevenueInput {
  influencerCommissionRate?: InputMaybe<Scalars['Float']['input']>;
  influencerId: Scalars['Int']['input'];
  revenuePerAction?: InputMaybe<Scalars['Float']['input']>;
  revenuePerClick?: InputMaybe<Scalars['Float']['input']>;
  revenuePerComment?: InputMaybe<Scalars['Float']['input']>;
  revenuePerLike?: InputMaybe<Scalars['Float']['input']>;
  revenuePerPost?: InputMaybe<Scalars['Float']['input']>;
  revenuePerShare?: InputMaybe<Scalars['Float']['input']>;
  revenuePerView?: InputMaybe<Scalars['Float']['input']>;
}

export interface MarketplaceJoinableAccountsPayload {
  facebookAccount?: Maybe<JoinableFbAccount>;
  facebookPages: Array<JoinableAccount>;
  instagramAccounts: Array<JoinableInstagramAccount>;
  tiktokAccounts: Array<JoinableAccount>;
  twitterAccounts: Array<JoinableAccount>;
  youtubeAccounts: Array<JoinableAccount>;
}

export interface MarketplaceJoinablePromotionMethodsPayload {
  emailNewsLetters: Array<JoinablePromotionMethodEmailNewsLetterPayload>;
  facebookAccount?: Maybe<JoinablePromotionMethodSocialAccountPayload>;
  facebookPages: Array<JoinablePromotionMethodSocialAccountPayload>;
  instagramAccounts: Array<JoinablePromotionMethodSocialAccountPayload>;
  mobileApps: Array<JoinablePromotionMethodMobileAppPayload>;
  offlines: Array<JoinablePromotionMethodOfflinePayload>;
  podCasts: Array<JoinablePromotionMethodPodCastPayload>;
  tiktokAccounts: Array<JoinablePromotionMethodSocialAccountPayload>;
  twitterAccounts: Array<JoinablePromotionMethodSocialAccountPayload>;
  websites: Array<JoinablePromotionMethodWebsitePayload>;
  youtubeAccounts: Array<JoinablePromotionMethodSocialAccountPayload>;
}

export interface MarketplaceJoinedAccountPayload {
  avatar?: Maybe<Scalars['String']['output']>;
  followersCount: Scalars['Int']['output'];
  profileUrl: Scalars['String']['output'];
  socialAccountType: SocialAccountType;
  username: Scalars['String']['output'];
}

export interface MarketplaceJoinedMethodsInput {
  campaignId: Scalars['ID']['input'];
}

export interface MarketplaceJoinedMethodsPayload {
  emailNewsLetters: Array<JoinedPromotionMethodEmailNewsLetterPayload>;
  facebookAccount?: Maybe<JoinedPromotionMethodSocialAccountPayload>;
  facebookPages: Array<JoinedPromotionMethodSocialAccountPayload>;
  instagramAccounts: Array<JoinedPromotionMethodSocialAccountPayload>;
  mobileApps: Array<JoinedPromotionMethodMobileAppPayload>;
  offlines: Array<JoinedPromotionMethodOfflinePayload>;
  podCasts: Array<JoinedPromotionMethodPodCastPayload>;
  tiktokAccounts: Array<JoinedPromotionMethodSocialAccountPayload>;
  twitterAccounts: Array<JoinedPromotionMethodSocialAccountPayload>;
  websites: Array<JoinedPromotionMethodWebsitePayload>;
  youtubeAccounts: Array<JoinedPromotionMethodSocialAccountPayload>;
}

export interface MarketplaceJoinedMethodsPayloadV2 {
  methods: Array<CampaignPromotionMethod>;
}

export interface MarketplacePost {
  content?: Maybe<Scalars['String']['output']>;
  costEditable?: Maybe<Scalars['Boolean']['output']>;
  couponCode?: Maybe<Scalars['String']['output']>;
  draftId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  influencer: MarketplacePostInfluencer;
  joinedDate: Scalars['Date']['output'];
  needReview: Scalars['Boolean']['output'];
  postUrl?: Maybe<Scalars['String']['output']>;
  postedDate?: Maybe<Scalars['Date']['output']>;
  revenueItems: Array<MarketplaceRevenueItem>;
  socialAccountId?: Maybe<Scalars['Int']['output']>;
  socialMedia: CampaignSocialMediaType;
  status?: Maybe<MarketplaceCampaignPostStatusForAdvertiser>;
  thumbNail?: Maybe<Scalars['String']['output']>;
  warningReason?: Maybe<WarningReason>;
}

export interface MarketplacePostComment {
  commentedDate?: Maybe<Scalars['DateTime']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  username?: Maybe<Scalars['String']['output']>;
}

export interface MarketplacePostDetail {
  caption: Scalars['String']['output'];
  comments?: Maybe<Scalars['Int']['output']>;
  detailedReason?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  images?: Maybe<Array<Scalars['String']['output']>>;
  likes?: Maybe<Scalars['Int']['output']>;
  postDetailInfluencer: MarketplacePostDetailInfluencer;
  postUrl?: Maybe<Scalars['String']['output']>;
  postedDate?: Maybe<Scalars['Date']['output']>;
  reach?: Maybe<Scalars['Int']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  shares?: Maybe<Scalars['Int']['output']>;
  socialMedia: CampaignSocialMediaType;
  status?: Maybe<MarketplaceCampaignPostStatus>;
  views?: Maybe<Scalars['Int']['output']>;
  warningReason?: Maybe<WarningReason>;
}

export interface MarketplacePostDetailInfluencer {
  avatar: Scalars['String']['output'];
  followersCount: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
}

export interface MarketplacePostHistory {
  /** exist only status is REJECTED */
  detailedReason?: Maybe<Scalars['String']['output']>;
  /** Draft post Id */
  draftId?: Maybe<Scalars['Int']['output']>;
  draftPostContent?: Maybe<Scalars['String']['output']>;
  draftPostMaterials?: Maybe<Array<Scalars['String']['output']>>;
  id?: Maybe<Scalars['Int']['output']>;
  operatedDate: Scalars['Date']['output'];
  /** exist only statuses are REJECTED and APPROVED */
  operatedUserName?: Maybe<Scalars['String']['output']>;
  /** exist only status is REJECTED */
  reason?: Maybe<Scalars['String']['output']>;
  status: MarketplaceCampaignPostHistoryStatus;
}

export interface MarketplacePostInfluencer {
  avatar: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
}

export interface MarketplacePostNoRevenue {
  content: Scalars['String']['output'];
  influencerAvatar: Scalars['String']['output'];
  influencerId: Scalars['Int']['output'];
  influencerName: Scalars['String']['output'];
  postId: Scalars['Int']['output'];
  postedDate: Scalars['Date']['output'];
  thumbnails: Array<Scalars['String']['output']>;
}

export interface MarketplacePostsNoRevenue {
  count: Scalars['Int']['output'];
  posts: Array<MarketplacePostNoRevenue>;
}

export interface MarketplaceRejectedAdvertiserInfo {
  operatedDate: Scalars['Date']['output'];
  operatedUser: OperatedUserInfo;
  reason?: Maybe<Scalars['String']['output']>;
  status: MarketplaceCampaignRejectedStatus;
}

export interface MarketplaceRevenueItem {
  revenueType: MarketplaceCampaignRevenueType;
  updatedDate?: Maybe<Scalars['Date']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
}

export interface MarketplaceTracking {
  /** only for Pixel tracking campaign */
  pixelCode?: Maybe<Scalars['String']['output']>;
  /** only for postback URL campaign */
  postbackUrl?: Maybe<Scalars['String']['output']>;
}

export interface MarketplaceTrackingPostUrlForInfluencer {
  /** Available only if influencer joined on-going campaign and post is not fetched yet */
  postUrl?: Maybe<Scalars['String']['output']>;
}

export interface MaterialName {
  id: Scalars['Int']['output'];
  url: Scalars['String']['output'];
}

export interface MediaItemInput {
  image?: InputMaybe<ImageInput>;
  type: MediaType;
  video?: InputMaybe<VideoInput>;
}

export interface MediaItemPayload {
  image?: Maybe<ImagePayload>;
  type: MediaType;
  video?: Maybe<VideoPayload>;
}

export enum MediaType {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
}

export type MessageTemplateEvent = ChatEvent & {
  id: Scalars['String']['output'];
  templateId: Scalars['String']['output'];
  templateName: Scalars['String']['output'];
  timestamp: Scalars['String']['output'];
};

export interface MessageTemplateItem {
  name: Scalars['String']['output'];
  templateId: Scalars['String']['output'];
  text: Scalars['String']['output'];
}

export interface MinorCategory {
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
}

export interface MobileVersion {
  /** for ios */
  buildNumber: Scalars['String']['output'];
  /** semantic versioning */
  version: Scalars['String']['output'];
  /** for Android */
  versionCode: Scalars['String']['output'];
}

export interface Mutation {
  addAllSelectedInfluencers?: Maybe<AddAllSelectedInfluencers>;
  addComment?: Maybe<AddCommentPayload>;
  addEmailTemplate?: Maybe<AddEmailTemplatePayload>;
  addFan?: Maybe<AddFanPayload>;
  addFanTags?: Maybe<AddFanTagsPayload>;
  addForm?: Maybe<AddFormPayload>;
  /** Add influencer to user proposal list, on Select Influencers Tab */
  addInfluencerToUserProposal?: Maybe<AddInfluencerToUserProposal>;
  /**
   * Adds an Instagram account to a 'compare' list.
   *         May be used by admin, staff, agency and advertiser users.
   */
  addInstagramCompareAccountAnalytics?: Maybe<AddCompareInstagramAccountAnalytics>;
  /**
   * Adds an Instagram hashtag to a 'hashtag' list.
   *         May be used by admin, staff, agency and advertiser users.
   */
  addInstagramHashtagAnalytics?: Maybe<AddInstagramHashtagAnalytics>;
  /**
   *
   *         Add tracking post url for marketplace campaign on Advertiser platform
   *
   */
  addMarketplacePostUrl?: Maybe<AddMarketplacePostUrl>;
  /**
   *
   *         Add tracking post url for marketplace campaign on Influencer platform
   *
   */
  addMarketplacePostUrlForInfluencer?: Maybe<AddMarketplacePostUrlForInfluencer>;
  addReadTutorial?: Maybe<AddReadTutorial>;
  addSelectedInfluencers?: Maybe<AddSelectedInfluencers>;
  /** Add SNS account of influencer to user proposal list, on Select Influencers Tab */
  addSocialAccountToUserProposal?: Maybe<AddSocialAccountToUserProposal>;
  /** Add Staff's Advertisers To Another Staff */
  addStaffAdvertisersToAnotherStaff?: Maybe<AddStaffAdvertisersToAnotherStaff>;
  /**
   *  Adds a Youtube account to a 'compare' list.
   *  May be used by admin, staff, agency and advertiser users.
   */
  addYoutubeCompareAccountAnalytics: AddYoutubeCompareAccountAnalyticsOutput;
  allowEngagementInfluencerProposalList?: Maybe<AllowEngagementInfluencerProposalList>;
  allowInfluencersUserProposal?: Maybe<AllowInfluencersUserProposal>;
  /**
   * Sets a subscription plan for a specific advertiser.
   *         May be used by admin and staff users only.
   */
  analyticsSelectSubscriptionPlan?: Maybe<AnalyticsSelectSubscriptionPlan>;
  /**  validate the email availability */
  anyXCheckEmail?: Maybe<AnyXCheckEmailPayload>;
  /**  Allow creator user to Connect Social account on AnyX Platform */
  anyXSocialAuthConnect?: Maybe<AnyXSocialAuthConnectPayload>;
  /**  Allow creator user to Connect Social account for LinkBio on AnyX Platform */
  anyXSocialAuthConnectForLinkBio?: Maybe<AnyXSocialAuthConnectForLinkBioPayload>;
  /**  Allow creator user to Disconnect Social account on AnyX Platform */
  anyXSocialAuthDisconnect?: Maybe<AnyXSocialAuthDisconnectPayload>;
  /**  Allow creator user to Reconnect Social accounts on LinkBio feature */
  anyXSocialAuthReconnectForLinkBio?: Maybe<AnyXSocialAuthReconnectForLinkBioPayload>;
  /**  Allow creator user to Reconnect TikTok account on AnyX Platform */
  anyXSocialAuthReconnectTikTok?: Maybe<AnyXSocialAuthReconnectTikTokPayload>;
  anyXSocialAuthReconnectTtcm?: Maybe<AnyXSocialAuthReconnectTTCMPayload>;
  /**  Allow creator user to SignIn with Social account on AnyX Platform */
  anyXSocialAuthSignIn?: Maybe<AnyXSocialAuthSignInPayload>;
  /**  Allow creator user to SignIn with Instagram and LineIDToken by LIFF URL */
  anyXSocialAuthSignInInstagramLine?: Maybe<AnyXSocialAuthSignInInstagramLinePayload>;
  /**  To check SignIn or SignUp is enable of logged-in account from social media platform */
  anyXSocialAuthSignInOrSignUp?: Maybe<AnyXSocialAuthSignInOrSignUpPayload>;
  /**  Allow creator user to SignUp with Social account on AnyX Platform */
  anyXSocialAuthSignUp?: Maybe<AnyXSocialAuthSignUpPayload>;
  /**  Allow creator user to SignUp with Social account on AnyX Platform as Partner Influencer account. */
  anyXSocialAuthSignUpPartner?: Maybe<AnyXSocialAuthSignUpPartnerPayload>;
  /**  Allow creator user to SignUp with Social account on AnyX Platform as Talent Influencer account. */
  anyXSocialAuthSignUpTalent?: Maybe<AnyXSocialAuthSignUpTalentPayload>;
  /**
   *
   *         Approve Applicant influencers to Selection Marketplace campaign
   *
   */
  approveApplicantInfluencers?: Maybe<ApproveApplicantInfluencers>;
  approveAutoManagedPayout?: Maybe<ApproveAutoManagedPayout>;
  approveCmsInvoice?: Maybe<ApproveCmsInvoice>;
  approveMarketplace?: Maybe<ApproveMarketplace>;
  approveMarketplaceAffiliatePayout?: Maybe<ApproveMarketplaceAffiliatePayout>;
  /** approve a draft post */
  approveMarketplaceDraftPost?: Maybe<ApproveMarketplaceDraftPost>;
  approveTiktokSpecialCampaign?: Maybe<ApproveTikTokSpecialCampaign>;
  assignAdvertiserToStaffs?: Maybe<AssignAdvertiserForStaffs>;
  assignChatPic?: Maybe<AssignChatPICPayload>;
  /** Generates a local JWT token from a given Auth0 ID token */
  auth0ToLocalJwt?: Maybe<Auth0ToLocalJWT>;
  authTiktokBusinessAccount?: Maybe<AuthTiktokBusinessAccount>;
  boostTiktokAdPost?: Maybe<BoostTiktokAdPost>;
  /** Bulk update influencers budget */
  bulkUpdateEngagementInfluencersBudget?: Maybe<BulkUpdateEngagementInfluencersBudget>;
  /** Bulk update social accounts budget */
  bulkUpdateEngagementSocialAccountsBudget?: Maybe<BulkUpdateEngagementSocialAccountsBudget>;
  bulkUpdatePackageProposalAccountsBudget?: Maybe<BulkUpdatePackageProposalAccountsBudget>;
  bulkUpdatePackageProposalInfluencersBudget?: Maybe<BulkUpdatePackageProposalInfluencersBudget>;
  bulkUpdateUserProposalAccountsBudget?: Maybe<BulkUpdateUserProposalAccountsBudget>;
  bulkUpdateUserProposalInfluencersBudget?: Maybe<BulkUpdateUserProposalInfluencersBudget>;
  cancelSubscriptionPlan?: Maybe<CancelSubscriptionPlan>;
  /**  Changes a UGC post's status */
  changeInstagramUGCManagementPostStatus: ChangeInstagramUGCManagementPostStatusOutput;
  clearCmsChannelAssetOwner?: Maybe<ClearCmsChannelAssetOwner>;
  /**
   *  `clickNotification` mutation sets a notification as clicked.
   *  The user is known through the `Authorization` header.
   *  Click is defined as a user click.
   */
  clickNotification?: Maybe<ClickNotificationPayload>;
  /**
   *  `clickNotificationByEventId` mutation sets a notification as clicked by event_id.
   *  The user is known through the `Authorization` header.
   *  Click is defined as a user click.
   */
  clickNotificationByEventId?: Maybe<ClickNotificationByEventIdPayload>;
  cloneMarketplace?: Maybe<CloneMarketplace>;
  connectFacebookPagesAndInstagramAccounts?: Maybe<ConnectFacebookPagesAndInstagramAccountsPayload>;
  connectNewShopifyAccount?: Maybe<ConnectNewShopifyAccountPayload>;
  connectPackageToEngagementProposal?: Maybe<ConnectPackageToEngagementProposal>;
  connectPackageWithInfluencerAccounts?: Maybe<ConnectPackageWithInfluencerAccounts>;
  connectPackageWithSocialAccounts?: Maybe<ConnectPackageWithSocialAccounts>;
  connectPackageWithUserProposal?: Maybe<ConnectPackageWithUserProposal>;
  /**  Allow influencer to connect promotion methods */
  connectPromotionMethods?: Maybe<ConnectPromotionMethodsPayload>;
  createAdminUser?: Maybe<CreateAdmin>;
  createAdvertiser?: Maybe<CreateAdvertiser>;
  /** Create Advertiser User */
  createAdvertiserUser?: Maybe<CreateAdvertiserUser>;
  createAdvertiserWithUser?: Maybe<CreateAdvertiserWithUser>;
  /** create AdvertiserId if selected id not exist, and create advertiser user */
  createAdvertiserWithUserV2?: Maybe<CreateAdvertiserWithUserV2>;
  /** Create Ad Agency User */
  createAgencyUser?: Maybe<CreateAgency>;
  createAgencyWithUser?: Maybe<CreateAgencyWithUser>;
  createCreatorStaff?: Maybe<CreateCreatorStaffPayload>;
  createDraftBoostPost?: Maybe<CreateDraftBoostPost>;
  createEngagement?: Maybe<CreateEngagement>;
  createEngagementPostForInfluencerV2?: Maybe<CreateEngagementPostForInfluencerV2>;
  createEngagementPostV2?: Maybe<CreateEngagementPostV2>;
  createGeminiThread?: Maybe<CreateGeminiThread>;
  /**  Influencer bio */
  createInfluencerBio?: Maybe<CreateInfluencerBioPayload>;
  createInfluencerV2?: Maybe<CreateInfluencerV2>;
  /**  Creates hashtag for UGC management */
  createInstagramUGCManagementHashtag: CreateInstagramUGCManagementHashtagOutput;
  createInstagramUserHashtag: CreateInstagramUserHashtagOutput;
  createLineBroadcastMessage?: Maybe<CreateLineBroadcastMessagePayload>;
  createLineChannel?: Maybe<CreateLineChannelPayload>;
  createMarketplace?: Maybe<CreateMarketplace>;
  /** Influencer submit a draft post */
  createMarketplaceDraftPost?: Maybe<CreateMarketplaceDraftPost>;
  createMobileAppVersion?: Maybe<CreateMobileAppVersion>;
  createOpenAiThread?: Maybe<CreateOpenAIThread>;
  createPackage?: Maybe<CreatePackage>;
  createPackageWithInfluencerAccounts?: Maybe<CreatePackageWithInfluencerAccounts>;
  createPackageWithSocialAccounts?: Maybe<CreatePackageWithSocialAccounts>;
  createPackageWithUserProposal?: Maybe<CreatePackageWithUserProposal>;
  /** Create Partner User */
  createPartnerUser?: Maybe<CreatePartnerUser>;
  /**
   *
   *         Creates a self registered advertiser and an advertiser user attached to it with no authentication.
   *
   */
  createSelfRegisteredAdvertiserUser?: Maybe<CreateSelfRegisteredAdvertiserUser>;
  /**
   *  Creates an Analytics account with a connected Youtube or Instagram account.
   *  May be used by admin, staff and agency users.
   */
  createSocialAccountAnalyticsAdminV2: CreateSocialAccountAnalyticsAdminOutputV2;
  /**
   *  Creates an Analytics account with a connected Youtube or Instagram account.
   *  May be used by advertiser users only.
   */
  createSocialAccountAnalyticsAdvertiserV2: CreateSocialAccountAnalyticsAdvertiserOutputV2;
  createStaffUser?: Maybe<CreateStaff>;
  /** Create Talent Agency User */
  createTalentAgencyUser?: Maybe<CreateTalentAgencyUser>;
  createTalentAgencyWithUser?: Maybe<CreateTalentAgencyWithUser>;
  /**  Create Talent Influencer by Talent agency user with multiple social accounts (Facebook, Instagram, Youtube, and Twitter) */
  createTalentInfluencer?: Maybe<CreateTalentInfluencerPayload>;
  createTiktokAdAccount?: Maybe<CreateTiktokAdAccount>;
  createTiktokBusinessCenter?: Maybe<CreateTiktokBusinessCenter>;
  createTiktokSpecialCampaign?: Maybe<CreateTikTokSpecialCampaign>;
  createTiktokUserHashtag: CreateTiktokUserHashtagOutput;
  createTiktokUserKeyword: CreateTiktokUserKeywordOutput;
  deleteAdvertiser?: Maybe<DeleteAdvertiser>;
  deleteAgency?: Maybe<DeleteAgency>;
  /**
   *  Delete brand account and relations to facebook, instagram, youtube accounts and managers
   *  May be used by admin, staff
   */
  deleteBrandAccountAnalytics: DeleteBrandAccountAnalyticsOutput;
  deleteComment?: Maybe<DeleteCommentPayload>;
  deleteEngagement?: Maybe<DeleteEngagement>;
  deleteEngagementInfluencerProposal?: Maybe<DeleteEngagementInfluencerProposal>;
  deleteEngagementPostForInfluencer?: Maybe<DeleteEngagementPostForInfluencer>;
  deleteEngagementProposalJoinedAccount?: Maybe<DeleteEngagementProposalJoinedAccount>;
  deleteEngagementSocialAccountProposal?: Maybe<DeleteEngagementSocialAccountProposal>;
  deleteFanTags?: Maybe<DeleteFanTagsPayload>;
  deleteGeminiThread?: Maybe<DeleteGeminiThread>;
  deleteInfluencer?: Maybe<DeleteInfluencer>;
  deleteInfluencerPackage?: Maybe<DeleteInfluencerPackage>;
  /**
   * Removes a specified 'compare' account from the 'compare' page.
   *         May be used by admin, staff, agency and advertiser users.
   */
  deleteInstagramCompareAccountAnalytics?: Maybe<DeleteCompareInstagramAccountAnalytics>;
  /**
   * Removes an Instagram hashtag.
   *         May be used by admin, staff, agency and advertiser users.
   */
  deleteInstagramHashtagAnalytics?: Maybe<DeleteInstagramHashtagAnalytics>;
  /**  Deletes hashtag for UGC management */
  deleteInstagramUGCManagementHashtag: DeleteInstagramUGCManagementHashtagOutput;
  deleteInstagramUserHashtag: DeleteInstagramUserHashtagOutput;
  deleteMarketplace?: Maybe<DeleteMarketplace>;
  deleteOpenAiThread?: Maybe<DeleteOpenAIThread>;
  deletePackage?: Maybe<DeletePackage>;
  deleteSocialAccountPackage?: Maybe<DeleteSocialAccountPackage>;
  deleteTalentAgency?: Maybe<DeleteTalentAgency>;
  deleteTiktokSpecialCampaign?: Maybe<DeleteTikTokSpecialCampaign>;
  deleteTiktokUserHashtag: DeleteTiktokUserHashtagOutput;
  deleteTiktokUserKeyword: DeleteTiktokUserKeywordOutput;
  deleteUser?: Maybe<DeleteUser>;
  /** Delete influencer on user proposal list, on Select Influencers Tab */
  deleteUserProposalInfluencer?: Maybe<DeleteUserProposalInfluencer>;
  /** Delete SNS account of influencer on user proposal list, on Select Influencers Tab */
  deleteUserProposalSocialAccount?: Maybe<DeleteUserProposalSocialAccount>;
  /** Delete individual SNS account of influencer on user proposal list, on Propose Influencers Tab */
  deleteUserProposalSocialAccountIndividually?: Maybe<DeleteUserProposalSocialAccountIndividually>;
  /**
   *  Deletes a Youtube account from a 'compare' list
   *  May be used by admin, staff, agency and advertiser users.
   */
  deleteYoutubeCompareAccountAnalytics: DeleteYoutubeCompareAccountAnalyticsOutput;
  denyEngagementInfluencerProposalList?: Maybe<DenyEngagementInfluencerProposalList>;
  denyInfluencersUserProposal?: Maybe<DenyInfluencersUserProposal>;
  disconnectPackageFromEngagementProposal?: Maybe<DisconnectPackageFromEngagementProposal>;
  /**  Allow influencer to disconnect promotion methods */
  disconnectPromotionMethods?: Maybe<DisconnectPromotionMethodsPayload>;
  disconnectTiktokAdAccount?: Maybe<DisconnectTiktokAdAccount>;
  /**
   *  Edit an Analytics account with a connected Youtube or Instagram account.
   *  May be used by admin, staff and agency users.
   */
  editSocialAccountAnalyticsAdminV2: EditSocialAccountAnalyticsAdminOutputV2;
  /**
   *  Edit an Analytics account with a connected Youtube or Instagram account.
   *  May be used by advertiser users only.
   */
  editSocialAccountAnalyticsAdvertiserV2: EditSocialAccountAnalyticsAdvertiserOutputV2;
  /** Adds all influencers from one campaign to another */
  engagementAddInfluencersToProposalFromAnotherCampaign?: Maybe<EngagementAddInfluencersToProposalFromAnotherCampaign>;
  /**  Public API to save Engagement Coupon Tracking */
  engagementCampaignCouponTracking?: Maybe<EngagementCampaignCouponTrackingPayload>;
  exportCmsInvoiceToSpreadsheet?: Maybe<ExportCmsInvoiceToSpreadsheet>;
  /** Creates a presentation with the dashboard data and sends it to the user's email address as a file */
  exportInstagramAccountDashboardAnalytics?: Maybe<ExportDashboardInstagramAccountAnalytics>;
  exportInstagramHashtagPostsToGoogleSpreadsheet: ExportInstagramHashtagPostsToGoogleSpreadsheetOutput;
  exportTiktokHashtagPostsToGoogleSpreadsheet: ExportTiktokHashtagPostsToGoogleSpreadsheetOutput;
  exportTiktokKeywordPostsToGoogleSpreadsheet: ExportTiktokKeywordPostsToGoogleSpreadsheetOutput;
  /**
   *  Get the exported google slide/pptx and send to email.
   *  May be used by admin, staff, advertiser, agency, talent agency and partner users
   */
  exportYoutubeDashboardAccountAnalytics: ExportYoutubeDashboardAccountAnalyticsOutput;
  /**
   *  Get the exported google slide/pptx and send to email for an influencer.
   *  May be used by admin, staff, partner, and talent agency users
   */
  exportYoutubeDashboardAccountAnalyticsForInfluencer: ExportYoutubeDashboardAccountAnalyticsOutput;
  /**
   *  Creates a facebook page analutics account.
   *  May be used by external users only.
   */
  externalCreateFacebookPageAccountAnalytics: ExternalCreateFacebookPageAccountAnalyticsOutput;
  /**
   *  Creates an instagram analytics account.
   *  The service is idempotent. You may create the same account multiple times. The returned id is the same in this situation.
   *  May be used by external users only.
   */
  externalCreateInstagramAccountAnalytics: ExternalCreateInstagramAccountAnalyticsOutput;
  /**
   *  Creates an youtube analytics account.
   *  The service is idempotent. You may create the same account multiple times. The returned id is the same in this situation.
   *  May be used by external users only.
   */
  externalCreateYoutubeAccountAnalytics: ExternalCreateYoutubeAccountAnalyticsOutput;
  /**
   *  Returns a list of Facebook page accounts that can be connected based on your Facebook account.
   *  May be used by external users only.
   *  The mutation looks like a query but actually it also saves access tokens in the DB.
   */
  externalSocialAuthConnectableFacebookPageAnalytics?: Maybe<ExternalConnectableFacebookPageAnalyticsPayload>;
  /**
   *  Returns a list of Instagram accounts that can be connected based on your Facebook account.
   *  May be used by external users only.
   *  The mutation looks like a query but actually it also saves access tokens in the DB.
   */
  externalSocialAuthConnectableInstagramAccountsAnalytics?: Maybe<ExternalSocialAuthConnectableInstagramAccountsAnalyticsPayload>;
  /**
   *  Reconnects a Facebook Page analytics account.
   *  May be used by external users only.
   */
  externalSocialAuthReconnectFacebookPageAccountAnalytics?: Maybe<ExternalSocialAuthReconnectFacebookPageAccountAnalyticsPayload>;
  /**
   *  Reconnects an Instagram analytics account.
   *  May be used by external users only.
   */
  externalUserSocialAuthReconnectInstagramAccountAnalytics?: Maybe<ExternalUserSocialAuthReconnectInstagramAccountAnalyticsPayload>;
  fetchAutoManagedCustomShopeeCampaigns?: Maybe<ShopeeCustomCampaignPayload>;
  generateMarketplaceCampaignDetail?: Maybe<GenerateMarketplaceCampaignDetailPayload>;
  generateMarketplacePostCaption?: Maybe<AcceptedPayload>;
  /** Generates Partner Influencer sign-up url */
  generatePartnerInfluencerSignUpUrl?: Maybe<GeneratePartnerInfluencerSignUpUrl>;
  /** Generates Talent Influencer sign-up url */
  generateTalentInfluencerSignUpUrl?: Maybe<GenerateTalentInfluencerSignUpUrl>;
  generateTikTokSpecialPostCaption?: Maybe<AcceptedPayload>;
  importFans?: Maybe<ImportFansPayload>;
  /**  Adds a Youtube account to a 'compare' list. For influencers only. */
  influencerAddYoutubeCompareAccountAnalytics: AddYoutubeCompareAccountAnalyticsOutput;
  /**
   *  Updates twitter account data
   *  May be used by admin, staff, agency, advertisers, and influencers
   */
  influencerAnalyticsTwitterRefresh: InfluencerAnalyticsTwitterRefreshOutput;
  /**  Deletes a Youtube account from a 'compare' list. For influencers only. */
  influencerDeleteYoutubeCompareAccountAnalytics: DeleteYoutubeCompareAccountAnalyticsOutput;
  /**
   * Creates a presentation with the dashboard data and
   *             sends it to the user's email address as a file. For influencers only.
   */
  influencerExportInstagramAccountDashboard?: Maybe<InfluencerExportDashboardInstagramAccount>;
  /**  Allow influencer to sign-up by email */
  influencerSignUp?: Maybe<InfluencerSignUpInputPayload>;
  /**
   *  Reconnects an Instagram analytics account.
   *  May be used for influencers only.
   */
  influencerSocialAuthReconnectInstagramAccountAnalytics?: Maybe<InfluencerSocialAuthReconnectInstagramAccountAnalyticsPayload>;
  joinAutoManagedCampaign?: Maybe<JoinAutoManagedCampaignPayload>;
  joinMarketplaceByStaff?: Maybe<JoinMarketplaceByStaff>;
  joinMarketplaceV2?: Maybe<JoinMarketplaceV2>;
  joinTiktokSpecialCampaign?: Maybe<JoinTiktokSpecialCampaign>;
  linkCmsChannelAssets?: Maybe<LinkCmsChannelAssets>;
  /** Influencer made a post */
  madeMarketplaceCampaignPost?: Maybe<MadeMarketplaceCampaignPost>;
  /**
   *  `markReadNotification` mutation marks all notification of a influencer user as read.
   *  The user is known through the `Authorization` header.
   *  Read is defined as the occurence of the notification being rendered by front-end.
   */
  markReadNotification: MarkReadNotificationPayload;
  /**
   *  `markReadNotificationByApp` mutation marks all specific app notification of a influencer user as read.
   *  The user is known through the `Authorization` header.
   *  Read is defined as the occurence of the notification being rendered by front-end.
   */
  markReadNotificationByApp: MarkReadNotificationPayload;
  /**  Public API to save Marketplace Coupon Tracking */
  marketplaceCampaignCouponTracking?: Maybe<MarketplaceCampaignCouponTrackingPayload>;
  publishInfluencerBio?: Maybe<PublishInfluencerBioPayload>;
  reSendBcaRequest?: Maybe<ReSendBcaRequestPayload>;
  rechargeAdvertiserAccount?: Maybe<RechargeAdvertiserAccount>;
  reconnectTiktokAdAccount?: Maybe<ReconnectTiktokAdAccount>;
  refreshPassword?: Maybe<RefreshPasswordPayload>;
  refreshProfileAccount?: Maybe<RefreshProfileAccountPayload>;
  refreshTwitterAccount?: Maybe<RefreshTwitterAccountPayload>;
  registerPushToken?: Maybe<RegisterPushToken>;
  /** Reject engagement draft post (it can be used by admin, staff, agency and advertiser) */
  rejectEngagementDraftPost?: Maybe<RejectEngagementDraftPost>;
  /** Reject Marketplace campaign that have been created by Advertiser */
  rejectMarketplaceCampaign?: Maybe<RejectMarketplaceCampaign>;
  /** reject a draft post */
  rejectMarketplaceDraftPost?: Maybe<RejectMarketplaceDraftPost>;
  /** Reject TikTokSpecial campaign that have been created by Advertiser */
  rejectTiktokSpecialCampaign?: Maybe<RejectTikTokSpecialCampaign>;
  removeAllFormResponses?: Maybe<RemoveAllFormResponsesPayload>;
  removeAllSelectedInfluencers?: Maybe<RemoveAllSelectedInfluencers>;
  removeCreatorStaff?: Maybe<RemoveCreatorStaffPayload>;
  removeEmails?: Maybe<RemoveEmailsPayload>;
  removeFans?: Maybe<RemoveFansPayload>;
  removeFormResponse?: Maybe<RemoveFormResponsePayload>;
  removeForms?: Maybe<RemoveFormsPayload>;
  removeSelectedInfluencers?: Maybe<RemoveSelectedInfluencers>;
  removeShopifyAccount?: Maybe<RemoveShopifyAccountPayload>;
  reorderMarketplaceCampaigns?: Maybe<ReorderMarketplaceCampaigns>;
  reportEngagementPostStatus?: Maybe<ReportEngagementPostStatus>;
  reportMarketplacePostStatus?: Maybe<ReportMarketplacePostStatus>;
  reportTiktokSpecialPostStatus?: Maybe<ReportTiktokSpecialPostStatus>;
  requestDeleteAccount?: Maybe<RequestDeleteAccount>;
  requestGoogleSlideCustomisedReport?: Maybe<RequestGoogleSlideCustomisedReport>;
  requestGoogleSlideReport?: Maybe<RequestGoogleSlideReport>;
  requestInfluencerProfilePptx?: Maybe<RequestInfluencerProfilePptx>;
  requestInfluencersProfilePptx?: Maybe<RequestInfluencersProfilePptx>;
  requestInfluencersSocialAccountPptx?: Maybe<RequestInfluencersSocialAccountPptx>;
  /**  Request a UGC post's via comment */
  requestInstagramUGCManagementPost: RequestInstagramUGCManagementPostOutput;
  requestPackageProposalExcel?: Maybe<RequestPackageProposalExcel>;
  requestPackageProposalPptx?: Maybe<RequestPackageProposalPptx>;
  requestPackageSpreadsheet?: Maybe<RequestPackageSpreadsheet>;
  requestPowerpointCustomisedReport?: Maybe<RequestPowerpointCustomisedReport>;
  requestPowerpointReport?: Maybe<RequestPowerpointReport>;
  requestProposalExcel?: Maybe<RequestProposalExcel>;
  requestProposalPptx?: Maybe<RequestProposalPptx>;
  requestProposalSpreadsheet?: Maybe<RequestProposalSpreadsheet>;
  requestUserProposalExcel?: Maybe<RequestUserProposalExcel>;
  requestUserProposalPptx?: Maybe<RequestUserProposalPptx>;
  /** Re-send an email for self-registered Advertiser */
  resendEmailForAdvertiser?: Maybe<ResendEmailForAdvertiser>;
  /**  Resend forgot password's email to influencer which contains generated hash url to let influencer to set new password */
  resendInfluencerForgotPasswordEmail?: Maybe<ResendInfluencerForgotPasswordEmailPayload>;
  /**  Resend sign-up's email to influencer which contains generated hash url to let influencer to set new password */
  resendInfluencerSignUpEmail?: Maybe<ResendInfluencerSignUpEmailPayload>;
  /** temporary internal api */
  resendPartnerEmails?: Maybe<ResendPartnerEmails>;
  resetPassword?: Maybe<ResetPasswordPayload>;
  resetPasswordAndLogin?: Maybe<ResetPasswordAndLoginPayload>;
  resolveChat?: Maybe<ResolveChatPayload>;
  sendBcaRequest?: Maybe<SendBcaRequestPayload>;
  sendContact?: Maybe<SendContact>;
  sendGeminiMessage?: Maybe<SendGeminiMessage>;
  sendLineBroadcastMessage?: Maybe<SendLineBroadcastMessagePayload>;
  sendMessage?: Maybe<SendMessagePayload>;
  sendOpenAiMessage?: Maybe<SendOpenAIMessage>;
  shopifyValidateAuth?: Maybe<ShopifyValidateAuthPayload>;
  /**
   *  Returns a list of Facebook page accounts that can be connected based on your Facebook account.
   *  May be used by admin, staff, agency and advertiser users.
   *  The mutation looks like a query but actually it also saves access tokens in the DB.
   */
  socialAuthConnectableFacebookPageAnalytics?: Maybe<SocialAuthConnectableFacebookPageAnalyticsPayload>;
  /**
   *  Returns a list of Instagram accounts that can be connected based on your Facebook account.
   *  May be used by admin, staff, agency and advertiser users.
   *  The mutation looks like a query but actually it also saves access tokens in the DB.
   */
  socialAuthConnectableInstagramAccountsAnalytics?: Maybe<SocialAuthConnectableInstagramAccountsAnalyticsPayload>;
  /**
   *  Returns a twitter account that can be connected to analytics account.
   *  May be used by admin, staff, agency and advertiser users.
   *  The mutation looks like a query but actually it also saves access tokens in the DB.
   */
  socialAuthConnectableTwitterAccountAnalyticsV2: SocialAuthConnectableTwitterAccountAnalyticsOutput;
  /**
   *  Get selectable youtube analytics account channels and save tokens in DB.
   *  May be used by admin, staff, agency and advertiser users.
   *  The mutation looks like a query but actually it also saves access tokens in the DB.
   */
  socialAuthConnectableYouTubeChannelsAnalyticsV2: Array<SelectableYoutubeAnalyticsChannel>;
  socialAuthForJoinCampaign?: Maybe<SocialAuthForJoinCampaignPayload>;
  socialAuthReConnectInstagram?: Maybe<SocialAuthReConnectInstagramPayload>;
  /**
   *  Reconnects a Facebook Page analytics account.
   *  May be used by admin, staff, agency and advertiser users. We need the mutation to reconnect an account if its token invalid.
   */
  socialAuthReconnectFacebookPageAccountAnalytics?: Maybe<SocialAuthReconnectFacebookPageAccountAnalyticsPayload>;
  /**
   *  Reconnects an Instagram analytics account.
   *  May be used by admin, staff, agency and advertiser users. We need the mutation to reconnect an account if its token is not valid no more.
   */
  socialAuthReconnectInstagramAccountAnalytics?: Maybe<SocialAuthReconnectInstagramAccountAnalyticsPayload>;
  /**
   *  Reconnects a Twitter analytics account.
   *  May be used by admin, staff, agency and advertiser users. We need the mutation to reconnect an account if its token invalid.
   */
  socialAuthReconnectTwitterAccountAnalyticsV2?: Maybe<SocialAuthReconnectTwitterAccountAnalyticsPayload>;
  /**
   *  Reconnects a YouTube analytics account.
   *  May be used by admin, staff, agency and advertiser users. We need the mutation to reconnect an account if its token invalid.
   */
  socialAuthReconnectYouTubeAccountAnalyticsV2?: Maybe<SocialAuthReconnectYouTubeAccountAnalyticsPayload>;
  /**
   *  Make Facebook OAuth when signing-in and returns Facebook UserId and account name
   *  Used by only Talent Agency user
   */
  socialAuthTalentSignInFacebook?: Maybe<SocialAuthTalentSignInFacebookPayload>;
  /**
   *  Make Facebook OAuth when signing-in and returns a list of Page & Instagram account pairs and your Facebook account.
   *  Used by only Talent Agency user
   */
  socialAuthTalentSignInInstagram?: Maybe<SocialAuthTalentSignInInstagramPayload>;
  /**
   *  Make Twitter OAuth when signing-in and returns Twitter user id  and account name
   *  Used by only Talent Agency user
   */
  socialAuthTalentSignInTwitter?: Maybe<SocialAuthTalentSignInTwitterPayload>;
  /**
   *  Make YouTube OAuth when signing-in and returns a list of YouTube Channels.
   *  Used by only Talent Agency user
   */
  socialAuthTalentSignInYouTube?: Maybe<SocialAuthTalentSignInYouTubePayload>;
  submitForm?: Maybe<SubmitFormPayload>;
  submitFormInternal?: Maybe<SubmitFormInternalPayload>;
  subscribeAnalyticsPlan?: Maybe<SubscribeAnalyticsPlan>;
  subscribeAnalyticsTrialPlan?: Maybe<SubscribeAnalyticsTrialPlan>;
  sudoLimitedLogin?: Maybe<SudoLimitedLogin>;
  /** Allow creator staff to change account */
  switchInfluencerForStaff?: Maybe<SwitchInfluencerForStaffPayload>;
  switchRoleToAdmin?: Maybe<SwitchRoleToAdmin>;
  switchRoleToAdvertiser?: Maybe<SwitchRoleToAdvertiser>;
  switchRoleToAgency?: Maybe<SwitchRoleToAgency>;
  switchRoleToPartner?: Maybe<SwitchRoleToPartner>;
  switchRoleToStaff?: Maybe<SwitchRoleToStaff>;
  switchRoleToTalentAgency?: Maybe<SwitchRoleToTalentAgency>;
  tokenAuth?: Maybe<ObtainJSONWebTokenPayload>;
  /** for internal use */
  tokenAuthLongLive?: Maybe<TokenAuthLongLive>;
  trackYoutubeMusicDownload?: Maybe<TrackYoutubeMusicDownload>;
  transferMoneyToAdAccount?: Maybe<TransferMoneyToAdAccount>;
  /**
   *
   *             Unapprove Applicant influencers to Selection Marketplace campaign
   *
   */
  unApproveApplicantInfluencers?: Maybe<UnApproveApplicantInfluencers>;
  unassignChatPic?: Maybe<UnassignChatPICPayload>;
  unblockInfluencerMarketplace?: Maybe<UnblockInfluencerMarketplace>;
  unresolveChat?: Maybe<UnresolveChatPayload>;
  /**  Update crm account information */
  updateAccountInformation?: Maybe<UpdateAccountInformationPayload>;
  updateAddress?: Maybe<UpdateAddress>;
  /**  Update crm address information */
  updateAddressInformation?: Maybe<UpdateAddressInformationPayload>;
  updateAdminUser?: Maybe<UpdateAdmin>;
  updateAdvertiser?: Maybe<UpdateAdvertiser>;
  updateAdvertiserByAdvertiser?: Maybe<UpdateAdvertiser>;
  /** Update Advertiser User */
  updateAdvertiserUser?: Maybe<UpdateAdvertiserUser>;
  updateAdvertiserUserSettings?: Maybe<UpdateAdvertiserUserSettings>;
  updateAgency?: Maybe<UpdatePlainAgency>;
  /** Update Ad Agency User */
  updateAgencyUser?: Maybe<UpdateAgency>;
  updateAutoManagedAffiliateCommissionRates?: Maybe<UpdateAutoManagedAffiliateCommissionRates>;
  updateAutoManagedCampaign?: Maybe<UpdateAutoManagedCampaignPayload>;
  /**  Update auto-managed campaign */
  updateAutoManagedCampaignsStatus?: Maybe<UpdateAutoManagedCampaignsStatusPayload>;
  updateBioSections?: Maybe<UpdateBioSectionsPayload>;
  updateCmsAssetShareRate?: Maybe<UpdateCmsAssetShareRate>;
  updateCmsChannelShareRate?: Maybe<UpdateCmsChannelShareRate>;
  updateComment?: Maybe<UpdateCommentPayload>;
  updateEmailTemplate?: Maybe<UpdateEmailTemplatePayload>;
  updateEngagement?: Maybe<UpdateEngagement>;
  /** Change budget mode for engagement campaign */
  updateEngagementBreakdownMode?: Maybe<UpdateEngagementBreakDownMode>;
  updateEngagementCouponList?: Maybe<UpdateEngagementCouponList>;
  /**
   *
   *         Join Influencers and their social accounts to Engagement Proposal
   *
   */
  updateEngagementInfluencerProposalList?: Maybe<UpdateEngagementInfluencerProposalList>;
  updateEngagementInfluencersBudget?: Maybe<UpdateEngagementInfluencersBudget>;
  updateEngagementPostForInfluencerV2?: Maybe<UpdateEngagementPostForInfluencerV2>;
  updateEngagementPostStatus?: Maybe<UpdateEngagementPostStatus>;
  updateEngagementPostV2?: Maybe<UpdateEngagementPostV2>;
  /**
   *
   *         Join a specific social account and its own influencer to Engagement Proposal
   *
   */
  updateEngagementSocialAccountProposalList?: Maybe<UpdateEngagementSocialAccountProposalList>;
  updateEngagementSocialAccountsBudget?: Maybe<UpdateEngagementSocialAccountsBudget>;
  updateEngagementStatus?: Maybe<UpdateEngagementStatus>;
  updateFan?: Maybe<UpdateFanPayload>;
  updateForm?: Maybe<UpdateFormPayload>;
  updateFormStatus?: Maybe<UpdateFormStatusPayload>;
  updateInfluencerBio?: Maybe<UpdateInfluencerBioPayload>;
  updateInfluencerPhoneNumber?: Maybe<UpdateInfluencerPhoneNumberPayload>;
  updateInfluencerProfile?: Maybe<UpdateInfluencerProfile>;
  /**  Update the influencer rate card for specific social account type */
  updateInfluencerRateCard?: Maybe<UpdateInfluencerRateCardPayload>;
  /**  Update the influencer rate cards for specific social account type */
  updateInfluencerRateCards?: Maybe<UpdateInfluencerRateCardsPayload>;
  updateInfluencerRateCardsByStaff?: Maybe<UpdateInfluencerRateCardsByStaff>;
  updateInfluencerTagsByStaff?: Maybe<UpdateInfluencerTagsByStaff>;
  updateInfluencerV2?: Maybe<UpdateInfluencerV2>;
  updateInternalMemo?: Maybe<UpdateInternalMemo>;
  updateLineBroadcastMessage?: Maybe<UpdateLineBroadcastMessagePayload>;
  /**  update LinkBio Theme and Colors */
  updateLinkBioThemeColor?: Maybe<UpdateLinkBioThemeColorPayload>;
  updateMarketplace?: Maybe<UpdateMarketplace>;
  updateMarketplaceAffiliateCommissionFees?: Maybe<UpdateMarketplaceAffiliateCommissionFees>;
  updateMarketplaceAffiliateCommissionRates?: Maybe<UpdateMarketplaceAffiliateCommissionRates>;
  updateMarketplaceCouponList?: Maybe<UpdateMarketplaceCouponList>;
  updateMarketplaceInfluencerCost?: Maybe<UpdateMarketplaceInfluencerCost>;
  /**
   *
   *         Update Marketplace post status v2
   *
   */
  updateMarketplacePostStatusV2?: Maybe<UpdateMarketplacePostStatusV2>;
  /** Influencer update post tracking url */
  updateMarketplacePostTrackingUrl?: Maybe<UpdateMarketplacePostTrackingUrl>;
  updatePackage?: Maybe<UpdatePackage>;
  updatePackageProposalInfluencersBudget?: Maybe<UpdatePackageProposalInfluencersBudget>;
  updatePackageProposalMode?: Maybe<UpdatePackageProposalMode>;
  updatePackageProposalSocialAccountsBudget?: Maybe<UpdatePackageProposalSocialAccountsBudget>;
  updatePartnerAgency?: Maybe<UpdatePartnerAgency>;
  /** Update Partner User */
  updatePartnerUser?: Maybe<UpdatePartnerUser>;
  updatePasswordByAdvertiserUser?: Maybe<UpdatePasswordByAdvertiserUser>;
  updatePaymentInformation?: Maybe<UpdatePaymentInformation>;
  updatePaymentStatus?: Maybe<UpdatePaymentStatus>;
  /**  Update crm personal user detail */
  updatePersonalUserDetail?: Maybe<UpdatePersoanlUserDetailPayload>;
  /**  Update all signed-up/ scraping social accounts */
  updateSocialAccounts?: Maybe<UpdateSocialAccountsPayload>;
  updateSocialUsername?: Maybe<UpdateSocialUsername>;
  updateStaffUser?: Maybe<UpdateStaff>;
  updateSubscriptionPlan?: Maybe<UpdateSubscriptionPlan>;
  updateTalentAgency?: Maybe<UpdateTalentAgency>;
  /** Update Talent Agency User */
  updateTalentAgencyUser?: Maybe<UpdateTalentAgencyUser>;
  /**  Update Talent Influencer by Talent agency user with multiple social accounts (Facebook, Instagram, Youtube, and Twitter) */
  updateTalentInfluencer?: Maybe<UpdateTalentInfluencerPayload>;
  updateTiktokAdvertiserInfo?: Maybe<UpdateTiktokAdvertiserInfo>;
  updateTiktokSpecialCampaign?: Maybe<UpdateTikTokSpecialCampaign>;
  updateTiktokSpecialPostStatus?: Maybe<UpdateTiktokSpecialPostStatus>;
  updateTtcmPost?: Maybe<UpdateTtcmPost>;
  /**  Update crm user detail */
  updateUserDetail?: Maybe<UpdateUserDetailPayload>;
  /** Change budget mode for engagement campaign */
  updateUserProposalBreakdownMode?: Maybe<UpdateUserProposalBreakdownMode>;
  /** update budget of individual influencer account, on Propose Influencers Tab */
  updateUserProposalInfluencersBudget?: Maybe<UpdateUserProposalInfluencersBudget>;
  /** update budget of individual SNS account, on Propose Influencers Tab */
  updateUserProposalSocialAccountsBudget?: Maybe<UpdateUserProposalSocialAccountsBudget>;
  /**  Update the credentials(tokens, owner id) for Youtube MCN influencers */
  updateYoutubeMCNInfluencerCredentials?: Maybe<UpdateYoutubeMCNCredentialsPayload>;
  /**
   *
   *             Upload csv report file for engagement campaign
   *
   */
  uploadEngagementReportFile?: Maybe<UploadEngagementReportFile>;
  /**
   *
   *                Upload report of engagement campaign from Insight screenshot check
   *
   */
  uploadEngagementReportFromInsightCheck?: Maybe<UploadEngagementReportFromInsightCheck>;
  uploadEngagementXhsInsightCheck?: Maybe<UploadEngagementXhsInsightCheck>;
  uploadOpenAiImages?: Maybe<UploadOpenAIImages>;
  /**  Signs-up UUUM influencers using youtube */
  uuumSocialAuthSignUp?: Maybe<UUUMSocialAuthSignUpPayload>;
  verifyLineIDToken?: Maybe<VerifyLineIDTokenPayload>;
  verifyToken?: Maybe<VerifyPayload>;
  withdrawMoneyFromAdAccount?: Maybe<WithdrawMoneyFromAdAccount>;
}

export interface MutationaddAllSelectedInfluencersArgs {
  input: AddAllSelectedInfluencersInput;
}

export interface MutationaddCommentArgs {
  input: AddCommentInput;
}

export interface MutationaddEmailTemplateArgs {
  input: AddEmailTemplateInput;
}

export interface MutationaddFanArgs {
  input: AddFanInput;
}

export interface MutationaddFanTagsArgs {
  input: AddFanTagsInput;
}

export interface MutationaddFormArgs {
  input: AddFormInput;
}

export interface MutationaddInfluencerToUserProposalArgs {
  input: AddInfluencerToUserProposalInput;
}

export interface MutationaddInstagramCompareAccountAnalyticsArgs {
  input: AddInstagramCompareAccountAnalyticsInput;
}

export interface MutationaddInstagramHashtagAnalyticsArgs {
  input: AddInstagramHashtagAnalyticsInput;
}

export interface MutationaddMarketplacePostUrlArgs {
  input: AddMarketplacePostUrlInput;
}

export interface MutationaddMarketplacePostUrlForInfluencerArgs {
  input: AddMarketplacePostUrlInputForInfluencer;
}

export interface MutationaddReadTutorialArgs {
  input: AddReadTutorialInput;
}

export interface MutationaddSelectedInfluencersArgs {
  input: AddSelectedInfluencersInput;
}

export interface MutationaddSocialAccountToUserProposalArgs {
  input: AddSocialAccountToUserProposalInput;
}

export interface MutationaddStaffAdvertisersToAnotherStaffArgs {
  input: AddStaffAdvertisersToAnotherStaffInput;
}

export interface MutationaddYoutubeCompareAccountAnalyticsArgs {
  input: AddYoutubeCompareAccountAnalyticsInput;
}

export interface MutationallowEngagementInfluencerProposalListArgs {
  input: AllowEngagementInfluencerProposalListInput;
}

export interface MutationallowInfluencersUserProposalArgs {
  input: AllowInfluencersUserProposalInput;
}

export interface MutationanalyticsSelectSubscriptionPlanArgs {
  input: AnalyticsSelectSubscriptionPlanInput;
}

export interface MutationanyXCheckEmailArgs {
  input: AnyXCheckEmailInput;
}

export interface MutationanyXSocialAuthConnectArgs {
  input: AnyXSocialAuthConnectInput;
}

export interface MutationanyXSocialAuthConnectForLinkBioArgs {
  input: AnyXSocialAuthConnectForLinkBioInput;
}

export interface MutationanyXSocialAuthDisconnectArgs {
  input: AnyXSocialAuthDisconnectInput;
}

export interface MutationanyXSocialAuthReconnectForLinkBioArgs {
  input: AnyXSocialAuthReconnectForLinkBioInput;
}

export interface MutationanyXSocialAuthReconnectTikTokArgs {
  input: AnyXSocialAuthReconnectTikTokInput;
}

export interface MutationanyXSocialAuthReconnectTtcmArgs {
  input: AnyXSocialAuthReconnectTTCMInput;
}

export interface MutationanyXSocialAuthSignInArgs {
  input: AnyXSocialAuthSignInInput;
}

export interface MutationanyXSocialAuthSignInInstagramLineArgs {
  input: AnyXSocialAuthSignInInstagramLineInput;
}

export interface MutationanyXSocialAuthSignInOrSignUpArgs {
  input: AnyXSocialAuthSignInOrSignUpInput;
}

export interface MutationanyXSocialAuthSignUpArgs {
  input: AnyXSocialAuthSignUpInput;
}

export interface MutationanyXSocialAuthSignUpPartnerArgs {
  input: AnyXSocialAuthSignUpPartnerInput;
}

export interface MutationanyXSocialAuthSignUpTalentArgs {
  input: AnyXSocialAuthSignUpTalentInput;
}

export interface MutationapproveApplicantInfluencersArgs {
  input: ApproveApplicantInfluencersInput;
}

export interface MutationapproveAutoManagedPayoutArgs {
  input: ApproveAutoManagedPayoutInput;
}

export interface MutationapproveCmsInvoiceArgs {
  input: ApproveCmsInvoiceInput;
}

export interface MutationapproveMarketplaceArgs {
  input: ApproveMarketplaceInput;
}

export interface MutationapproveMarketplaceAffiliatePayoutArgs {
  input: ApproveMarketplaceAffiliatePayoutInput;
}

export interface MutationapproveMarketplaceDraftPostArgs {
  input: ApproveMarketplaceDraftPostInput;
}

export interface MutationapproveTiktokSpecialCampaignArgs {
  input: ApproveTikTokSpecialCampaignInput;
}

export interface MutationassignAdvertiserToStaffsArgs {
  input: AssignAdvertiserForStaffsInput;
}

export interface MutationassignChatPicArgs {
  input: AssignChatPICInput;
}

export interface Mutationauth0ToLocalJwtArgs {
  input: Auth0ToLocalJWTInput;
}

export interface MutationauthTiktokBusinessAccountArgs {
  input: AuthTiktokBusinessAccountInput;
}

export interface MutationboostTiktokAdPostArgs {
  input: BoostTikTokAdPostInput;
}

export interface MutationbulkUpdateEngagementInfluencersBudgetArgs {
  input: BulkUpdateEngagementInfluencersBudgetInput;
}

export interface MutationbulkUpdateEngagementSocialAccountsBudgetArgs {
  input: BulkUpdateEngagementSocialAccountsBudgetInput;
}

export interface MutationbulkUpdatePackageProposalAccountsBudgetArgs {
  input: BulkUpdatePackageProposalAccountsBudgetInput;
}

export interface MutationbulkUpdatePackageProposalInfluencersBudgetArgs {
  input: BulkUpdatePackageProposalInfluencersBudgetInput;
}

export interface MutationbulkUpdateUserProposalAccountsBudgetArgs {
  input: BulkUpdateUserProposalAccountsBudgetInput;
}

export interface MutationbulkUpdateUserProposalInfluencersBudgetArgs {
  input: BulkUpdateUserProposalInfluencersBudgetInput;
}

export interface MutationchangeInstagramUGCManagementPostStatusArgs {
  input: ChangeInstagramUGCManagementPostStatusInput;
}

export interface MutationclearCmsChannelAssetOwnerArgs {
  input: ClearCmsChannelAssetOwnerInput;
}

export interface MutationclickNotificationArgs {
  input?: InputMaybe<ClickNotificationInput>;
}

export interface MutationclickNotificationByEventIdArgs {
  input?: InputMaybe<ClickNotificationByEventIdInput>;
}

export interface MutationcloneMarketplaceArgs {
  input: CloneMarketplaceInput;
}

export interface MutationconnectFacebookPagesAndInstagramAccountsArgs {
  input?: InputMaybe<ConnectFacebookPagesAndInstagramAccountsInput>;
}

export interface MutationconnectNewShopifyAccountArgs {
  input: ShopifyValidateAuthInput;
}

export interface MutationconnectPackageToEngagementProposalArgs {
  input: ConnectPackageToEngagementProposalInput;
}

export interface MutationconnectPackageWithInfluencerAccountsArgs {
  input: ConnectPackageWithInfluencerAccountsInput;
}

export interface MutationconnectPackageWithSocialAccountsArgs {
  input: ConnectPackageWithSocialAccountsInput;
}

export interface MutationconnectPackageWithUserProposalArgs {
  input: ConnectPackageWithUserProposalInput;
}

export interface MutationconnectPromotionMethodsArgs {
  input: ConnectPromotionMethodsInput;
}

export interface MutationcreateAdminUserArgs {
  input: CreateAdminInput;
}

export interface MutationcreateAdvertiserArgs {
  input: CreateAdvertiserInput;
}

export interface MutationcreateAdvertiserUserArgs {
  input: CreateAdvertiserUserInput;
}

export interface MutationcreateAdvertiserWithUserArgs {
  input: CreateAdvertiserWithUserInput;
}

export interface MutationcreateAdvertiserWithUserV2Args {
  input: CreateAdvertiserWithUserInputV2;
}

export interface MutationcreateAgencyUserArgs {
  input: CreateAgencyInput;
}

export interface MutationcreateAgencyWithUserArgs {
  input: CreateAgencyWithUserInput;
}

export interface MutationcreateCreatorStaffArgs {
  input: CreateCreatorStaffInput;
}

export interface MutationcreateDraftBoostPostArgs {
  input: CreateDraftBoostPostInput;
}

export interface MutationcreateEngagementArgs {
  input: CreateEngagementInput;
}

export interface MutationcreateEngagementPostForInfluencerV2Args {
  input: CreateEngagementPostInputForInfluencerV2;
}

export interface MutationcreateEngagementPostV2Args {
  input: CreateEngagementPostInputV2;
}

export interface MutationcreateInfluencerBioArgs {
  input: CreateInfluencerBioInput;
}

export interface MutationcreateInfluencerV2Args {
  input: CreateInfluencerInputV2;
}

export interface MutationcreateInstagramUGCManagementHashtagArgs {
  input: CreateInstagramUGCManagementHashtagInput;
}

export interface MutationcreateInstagramUserHashtagArgs {
  input: CreateInstagramUserHashtagInput;
}

export interface MutationcreateLineBroadcastMessageArgs {
  input: CreateLineBroadcastMessageInput;
}

export interface MutationcreateLineChannelArgs {
  input: CreateLineChannelInput;
}

export interface MutationcreateMarketplaceArgs {
  input: CreateMarketplaceInput;
}

export interface MutationcreateMarketplaceDraftPostArgs {
  input: CreateMarketplaceDraftPostInput;
}

export interface MutationcreateMobileAppVersionArgs {
  input: CreateMobileAppVersionInput;
}

export interface MutationcreatePackageArgs {
  input: CreatePackageInput;
}

export interface MutationcreatePackageWithInfluencerAccountsArgs {
  input: CreatePackageWithInfluencerAccountsInput;
}

export interface MutationcreatePackageWithSocialAccountsArgs {
  input: CreatePackageWithSocialAccountsInput;
}

export interface MutationcreatePackageWithUserProposalArgs {
  input: CreatePackageWithUserProposalInput;
}

export interface MutationcreatePartnerUserArgs {
  input: CreatePartnerUserInput;
}

export interface MutationcreateSelfRegisteredAdvertiserUserArgs {
  input: CreateSelfRegisteredAdvertiserUserInput;
}

export interface MutationcreateSocialAccountAnalyticsAdminV2Args {
  input: CreateSocialAccountAnalyticsAdminInputV2;
}

export interface MutationcreateSocialAccountAnalyticsAdvertiserV2Args {
  input: CreateSocialAccountAnalyticsAdvertiserInputV2;
}

export interface MutationcreateStaffUserArgs {
  input: CreateStaffInput;
}

export interface MutationcreateTalentAgencyUserArgs {
  input: CreateTalentAgencyUserInput;
}

export interface MutationcreateTalentAgencyWithUserArgs {
  input: CreateTalentAgencyWithUserInput;
}

export interface MutationcreateTalentInfluencerArgs {
  input: CreateTalentInfluencerInput;
}

export interface MutationcreateTiktokAdAccountArgs {
  input: CreateTiktokAdAccountInput;
}

export interface MutationcreateTiktokBusinessCenterArgs {
  input: CreateTiktokBusinessCenterInput;
}

export interface MutationcreateTiktokSpecialCampaignArgs {
  input: CreateTikTokSpecialCampaignInput;
}

export interface MutationcreateTiktokUserHashtagArgs {
  input: CreateTiktokUserHashtagInput;
}

export interface MutationcreateTiktokUserKeywordArgs {
  input: CreateTiktokUserKeywordInput;
}

export interface MutationdeleteAdvertiserArgs {
  id: Scalars['Int']['input'];
}

export interface MutationdeleteAgencyArgs {
  id: Scalars['Int']['input'];
}

export interface MutationdeleteBrandAccountAnalyticsArgs {
  input: DeleteBrandAccountAnalyticsInput;
}

export interface MutationdeleteCommentArgs {
  input: DeleteCommentInput;
}

export interface MutationdeleteEngagementArgs {
  id: Scalars['Int']['input'];
}

export interface MutationdeleteEngagementInfluencerProposalArgs {
  input: DeleteEngagementInfluencerProposalInput;
}

export interface MutationdeleteEngagementPostForInfluencerArgs {
  id: Scalars['Int']['input'];
}

export interface MutationdeleteEngagementProposalJoinedAccountArgs {
  input: DeleteEngagementProposalJoinedAccountInput;
}

export interface MutationdeleteEngagementSocialAccountProposalArgs {
  input: DeleteEngagementSocialAccountProposalInput;
}

export interface MutationdeleteFanTagsArgs {
  input: DeleteFanTagsInput;
}

export interface MutationdeleteGeminiThreadArgs {
  input: DeleteGeminiThreadInput;
}

export interface MutationdeleteInfluencerArgs {
  id: Scalars['Int']['input'];
}

export interface MutationdeleteInfluencerPackageArgs {
  influencerIds: Array<Scalars['Int']['input']>;
  packageId: Scalars['Int']['input'];
}

export interface MutationdeleteInstagramCompareAccountAnalyticsArgs {
  input: DeleteInstagramCompareAccountAnalyticsInput;
}

export interface MutationdeleteInstagramHashtagAnalyticsArgs {
  input: DeleteInstagramHashtagAnalyticsInput;
}

export interface MutationdeleteInstagramUGCManagementHashtagArgs {
  input: DeleteInstagramUGCManagementHashtagInput;
}

export interface MutationdeleteInstagramUserHashtagArgs {
  input: DeleteInstagramUserHashtagInput;
}

export interface MutationdeleteMarketplaceArgs {
  id: Scalars['Int']['input'];
}

export interface MutationdeleteOpenAiThreadArgs {
  input: DeleteOpenAIThreadInput;
}

export interface MutationdeletePackageArgs {
  id: Scalars['Int']['input'];
}

export interface MutationdeleteSocialAccountPackageArgs {
  packageId: Scalars['Int']['input'];
  socialAccountIds: Array<Scalars['Int']['input']>;
  socialMedia: SocialAccountType;
}

export interface MutationdeleteTalentAgencyArgs {
  id: Scalars['Int']['input'];
}

export interface MutationdeleteTiktokSpecialCampaignArgs {
  input: DeleteTikTokSpecialCampaignInput;
}

export interface MutationdeleteTiktokUserHashtagArgs {
  input: DeleteTiktokUserHashtagInput;
}

export interface MutationdeleteTiktokUserKeywordArgs {
  input: DeleteTiktokUserKeywordInput;
}

export interface MutationdeleteUserArgs {
  id: Scalars['Int']['input'];
}

export interface MutationdeleteUserProposalInfluencerArgs {
  input: DeleteUserProposalInfluencerInput;
}

export interface MutationdeleteUserProposalSocialAccountArgs {
  input: DeleteUserProposalSocialAccountInput;
}

export interface MutationdeleteUserProposalSocialAccountIndividuallyArgs {
  input: DeleteUserProposalSocialAccountIndividuallyInput;
}

export interface MutationdeleteYoutubeCompareAccountAnalyticsArgs {
  input: DeleteYoutubeCompareAccountAnalyticsInput;
}

export interface MutationdenyEngagementInfluencerProposalListArgs {
  input: DenyEngagementInfluencerProposalListInput;
}

export interface MutationdenyInfluencersUserProposalArgs {
  input: DenyInfluencersUserProposalInput;
}

export interface MutationdisconnectPackageFromEngagementProposalArgs {
  input: DisconnectPackageFromEngagementProposalInput;
}

export interface MutationdisconnectPromotionMethodsArgs {
  input: DisconnectPromotionMethodsInput;
}

export interface MutationdisconnectTiktokAdAccountArgs {
  input: DisconnectTiktokAdAccountInput;
}

export interface MutationeditSocialAccountAnalyticsAdminV2Args {
  input: EditSocialAccountAnalyticsAdminInputV2;
}

export interface MutationeditSocialAccountAnalyticsAdvertiserV2Args {
  input: EditSocialAccountAnalyticsAdvertiserInputV2;
}

export interface MutationengagementAddInfluencersToProposalFromAnotherCampaignArgs {
  input: EngagementAddInfluencersToProposalFromAnotherCampaignInput;
}

export interface MutationengagementCampaignCouponTrackingArgs {
  input: EngagementCampaignCouponTrackingInput;
}

export interface MutationexportCmsInvoiceToSpreadsheetArgs {
  input: ExportCmsInvoiceToSpreadsheetInput;
}

export interface MutationexportInstagramAccountDashboardAnalyticsArgs {
  input: ExportInstagramDashboardAccountAnalyticsInput;
}

export interface MutationexportInstagramHashtagPostsToGoogleSpreadsheetArgs {
  input: ExportInstagramHashtagPostsToGoogleSpreadsheetInput;
}

export interface MutationexportTiktokHashtagPostsToGoogleSpreadsheetArgs {
  input: ExportTiktokHashtagPostsToGoogleSpreadsheetInput;
}

export interface MutationexportTiktokKeywordPostsToGoogleSpreadsheetArgs {
  input: ExportTiktokKeywordPostsToGoogleSpreadsheetInput;
}

export interface MutationexportYoutubeDashboardAccountAnalyticsArgs {
  input: ExportYoutubeDashboardAccountAnalyticsInput;
}

export interface MutationexportYoutubeDashboardAccountAnalyticsForInfluencerArgs {
  input: InfluencerExportYoutubeDashboardAccountAnalyticsInput;
}

export interface MutationexternalCreateFacebookPageAccountAnalyticsArgs {
  input: ExternalCreateFacebookPageAccountAnalyticsInput;
}

export interface MutationexternalCreateInstagramAccountAnalyticsArgs {
  input: ExternalCreateInstagramAccountAnalyticsInput;
}

export interface MutationexternalCreateYoutubeAccountAnalyticsArgs {
  input: ExternalCreateYoutubeAccountAnalyticsInput;
}

export interface MutationexternalSocialAuthConnectableFacebookPageAnalyticsArgs {
  input: ExternalConnectableFacebookPageAnalyticsInput;
}

export interface MutationexternalSocialAuthConnectableInstagramAccountsAnalyticsArgs {
  input: ExternalSocialAuthConnectableInstagramAccountsAnalyticsInput;
}

export interface MutationexternalSocialAuthReconnectFacebookPageAccountAnalyticsArgs {
  input: ExternalSocialAuthReconnectFacebookPageAccountAnalyticsInput;
}

export interface MutationexternalUserSocialAuthReconnectInstagramAccountAnalyticsArgs {
  input: ExternalUserSocialAuthReconnectInstagramAccountAnalyticsInput;
}

export interface MutationfetchAutoManagedCustomShopeeCampaignsArgs {
  input: ShopeeCustomCampaignInput;
}

export interface MutationgenerateMarketplaceCampaignDetailArgs {
  input: GenerateMarketplaceCampaignDetailInput;
}

export interface MutationgenerateMarketplacePostCaptionArgs {
  input: GenerateMarketplacePostCaptionInput;
}

export interface MutationgenerateTikTokSpecialPostCaptionArgs {
  input: GenerateTikTokSpecialPostCaptionInput;
}

export interface MutationimportFansArgs {
  input: ImportFansInput;
}

export interface MutationinfluencerAddYoutubeCompareAccountAnalyticsArgs {
  input: InfluencerAddYoutubeCompareAccountAnalyticsInput;
}

export interface MutationinfluencerAnalyticsTwitterRefreshArgs {
  input: InfluencerAnalyticsTwitterRefreshInput;
}

export interface MutationinfluencerDeleteYoutubeCompareAccountAnalyticsArgs {
  input: InfluencerDeleteYoutubeCompareAccountAnalyticsInput;
}

export interface MutationinfluencerExportInstagramAccountDashboardArgs {
  input: ExportInstagramDashboardAccountInfluencerInput;
}

export interface MutationinfluencerSignUpArgs {
  input: InfluencerSignUpInput;
}

export interface MutationinfluencerSocialAuthReconnectInstagramAccountAnalyticsArgs {
  input: InfluencerSocialAuthReconnectInstagramAccountAnalyticsInput;
}

export interface MutationjoinAutoManagedCampaignArgs {
  input: JoinAutoManagedCampaignInput;
}

export interface MutationjoinMarketplaceByStaffArgs {
  input: JoinMarketplaceByStaffInput;
}

export interface MutationjoinMarketplaceV2Args {
  input: JoinMarketplaceV2Input;
}

export interface MutationjoinTiktokSpecialCampaignArgs {
  input: JoinTiktokSpecialCampaignInput;
}

export interface MutationlinkCmsChannelAssetsArgs {
  input: LinkCmsChannelAssetsInput;
}

export interface MutationmadeMarketplaceCampaignPostArgs {
  input: MadeMarketplaceCampaignPostInput;
}

export interface MutationmarkReadNotificationByAppArgs {
  input?: InputMaybe<MarkReadNotificationByAppInput>;
}

export interface MutationmarketplaceCampaignCouponTrackingArgs {
  input: MarketplaceCampaignCouponTrackingInput;
}

export interface MutationpublishInfluencerBioArgs {
  input: PublishInfluencerBioInput;
}

export interface MutationreSendBcaRequestArgs {
  input: ReSendBcaRequestInput;
}

export interface MutationrechargeAdvertiserAccountArgs {
  input: RechargeAdvertiserAccountInput;
}

export interface MutationreconnectTiktokAdAccountArgs {
  input: ReconnectTiktokAdAccountInput;
}

export interface MutationrefreshPasswordArgs {
  input: RefreshPasswordInput;
}

export interface MutationrefreshProfileAccountArgs {
  input: RefreshProfileAccountInput;
}

export interface MutationrefreshTwitterAccountArgs {
  input: RefreshTwitterAccountInput;
}

export interface MutationregisterPushTokenArgs {
  input: RegisterPushTokenInput;
}

export interface MutationrejectEngagementDraftPostArgs {
  input: RejectEngagementDraftPostInput;
}

export interface MutationrejectMarketplaceCampaignArgs {
  input: RejectMarketplaceCampaignInput;
}

export interface MutationrejectMarketplaceDraftPostArgs {
  input: RejectMarketplaceDraftPostInput;
}

export interface MutationrejectTiktokSpecialCampaignArgs {
  input: RejectTikTokSpecialCampaignInput;
}

export interface MutationremoveAllFormResponsesArgs {
  input: RemoveAllFormResponsesInput;
}

export interface MutationremoveAllSelectedInfluencersArgs {
  input: RemoveAllSelectedInfluencersInput;
}

export interface MutationremoveCreatorStaffArgs {
  input: RemoveCreatorStaffInput;
}

export interface MutationremoveEmailsArgs {
  input: RemoveEmailsInput;
}

export interface MutationremoveFansArgs {
  input: RemoveFansInput;
}

export interface MutationremoveFormResponseArgs {
  input: RemoveFormResponseInput;
}

export interface MutationremoveFormsArgs {
  input: RemoveFormsInput;
}

export interface MutationremoveSelectedInfluencersArgs {
  input: RemoveSelectedInfluencersInput;
}

export interface MutationremoveShopifyAccountArgs {
  input: RemoveShopifyAccountInput;
}

export interface MutationreorderMarketplaceCampaignsArgs {
  campaignIds: Array<InputMaybe<Scalars['Int']['input']>>;
}

export interface MutationreportEngagementPostStatusArgs {
  input: ReportEngagementPostStatusInput;
}

export interface MutationreportMarketplacePostStatusArgs {
  input: ReportMarketplacePostStatusInput;
}

export interface MutationreportTiktokSpecialPostStatusArgs {
  input: ReportTiktokSpecialPostStatusInput;
}

export interface MutationrequestDeleteAccountArgs {
  input: RequestDeleteAccountInput;
}

export interface MutationrequestGoogleSlideCustomisedReportArgs {
  campaignId: Scalars['Int']['input'];
  campaignType: CampaignType;
  selectedMetrics: Array<Scalars['String']['input']>;
}

export interface MutationrequestGoogleSlideReportArgs {
  campaignId: Scalars['Int']['input'];
  campaignType: CampaignType;
}

export interface MutationrequestInfluencerProfilePptxArgs {
  format: Scalars['String']['input'];
  influencerId: Scalars['Int']['input'];
  socialAccountId: Scalars['Int']['input'];
  socialMedia: Scalars['String']['input'];
}

export interface MutationrequestInfluencersProfilePptxArgs {
  format: Scalars['String']['input'];
  influencerIds: Array<InputMaybe<Scalars['Int']['input']>>;
  socialMedias: Array<Scalars['String']['input']>;
}

export interface MutationrequestInfluencersSocialAccountPptxArgs {
  format: Scalars['String']['input'];
  influencerSocialAccountIds: Array<InfluencersPptxSocialAccountInput>;
}

export interface MutationrequestInstagramUGCManagementPostArgs {
  input: RequestInstagramUGCManagementPostInput;
}

export interface MutationrequestPackageProposalExcelArgs {
  format: ProposalDownloadFormat;
  influencerIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  packageId: Scalars['Int']['input'];
}

export interface MutationrequestPackageProposalPptxArgs {
  format: InfluencersProfileFormat;
  influencerIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  packageId: Scalars['Int']['input'];
}

export interface MutationrequestPackageSpreadsheetArgs {
  keyword?: InputMaybe<Scalars['String']['input']>;
  packageId: Scalars['Int']['input'];
}

export interface MutationrequestPowerpointCustomisedReportArgs {
  campaignId: Scalars['Int']['input'];
  campaignType: CampaignType;
  selectedMetrics: Array<Scalars['String']['input']>;
}

export interface MutationrequestPowerpointReportArgs {
  campaignId: Scalars['Int']['input'];
  campaignType: CampaignType;
}

export interface MutationrequestProposalExcelArgs {
  engagementId: Scalars['Int']['input'];
  influencerIds?: InputMaybe<Array<Scalars['Int']['input']>>;
}

export interface MutationrequestProposalPptxArgs {
  engagementId: Scalars['Int']['input'];
  format: Scalars['String']['input'];
  influencerIds?: InputMaybe<Array<Scalars['Int']['input']>>;
}

export interface MutationrequestProposalSpreadsheetArgs {
  engagementId: Scalars['Int']['input'];
  influencerIds?: InputMaybe<Array<Scalars['Int']['input']>>;
}

export interface MutationrequestUserProposalExcelArgs {
  format: ProposalDownloadFormat;
  influencerIds?: InputMaybe<Array<Scalars['Int']['input']>>;
}

export interface MutationrequestUserProposalPptxArgs {
  format: InfluencersProfileFormat;
  influencerIds?: InputMaybe<Array<Scalars['Int']['input']>>;
}

export interface MutationresendEmailForAdvertiserArgs {
  input: ResendEmailForAdvertiserInput;
}

export interface MutationresendInfluencerForgotPasswordEmailArgs {
  input: ResendInfluencerForgotPasswordEmailInput;
}

export interface MutationresendInfluencerSignUpEmailArgs {
  input: ResendInfluencerSignUpEmailInput;
}

export interface MutationresendPartnerEmailsArgs {
  input: ResendPartnerEmailsInput;
}

export interface MutationresetPasswordArgs {
  input: ResetPasswordInput;
}

export interface MutationresetPasswordAndLoginArgs {
  input: ResetPasswordInput;
}

export interface MutationresolveChatArgs {
  input: ResolveChatInput;
}

export interface MutationsendBcaRequestArgs {
  input: SendBcaRequestInput;
}

export interface MutationsendContactArgs {
  input: SendContactInput;
}

export interface MutationsendGeminiMessageArgs {
  input: SendGeminiMessageInput;
}

export interface MutationsendLineBroadcastMessageArgs {
  input: SendLineBroadcastMessageInput;
}

export interface MutationsendMessageArgs {
  input: SendMessageInput;
}

export interface MutationsendOpenAiMessageArgs {
  input: SendOpenAIMessageInput;
}

export interface MutationshopifyValidateAuthArgs {
  input: ShopifyValidateAuthInput;
}

export interface MutationsocialAuthConnectableFacebookPageAnalyticsArgs {
  input: SocialAuthConnectableFacebookPageAnalyticsInput;
}

export interface MutationsocialAuthConnectableInstagramAccountsAnalyticsArgs {
  input: SocialAuthConnectableInstagramAccountsAnalyticsInput;
}

export interface MutationsocialAuthConnectableTwitterAccountAnalyticsV2Args {
  input?: InputMaybe<SocialAuthConnectableTwitterAccountAnalyticsInput>;
}

export interface MutationsocialAuthConnectableYouTubeChannelsAnalyticsV2Args {
  input?: InputMaybe<GetSelectableYoutubeAnalyticsChannelsInput>;
}

export interface MutationsocialAuthForJoinCampaignArgs {
  input: SocialAuthForJoinCampaignInput;
}

export interface MutationsocialAuthReConnectInstagramArgs {
  input: SocialAuthReConnectInstagramInput;
}

export interface MutationsocialAuthReconnectFacebookPageAccountAnalyticsArgs {
  input: SocialAuthReconnectFacebookPageAccountAnalyticsInput;
}

export interface MutationsocialAuthReconnectInstagramAccountAnalyticsArgs {
  input: SocialAuthReconnectInstagramAccountAnalyticsInput;
}

export interface MutationsocialAuthReconnectTwitterAccountAnalyticsV2Args {
  input: SocialAuthReconnectTwitterAccountAnalyticsInput;
}

export interface MutationsocialAuthReconnectYouTubeAccountAnalyticsV2Args {
  input: SocialAuthReconnectYouTubeAccountAnalyticsInput;
}

export interface MutationsocialAuthTalentSignInFacebookArgs {
  input: SocialAuthTalentSignInFacebookInput;
}

export interface MutationsocialAuthTalentSignInInstagramArgs {
  input: SocialAuthTalentSignInInstagramInput;
}

export interface MutationsocialAuthTalentSignInTwitterArgs {
  input: SocialAuthTalentSignInTwitterInput;
}

export interface MutationsocialAuthTalentSignInYouTubeArgs {
  input: SocialAuthTalentSignInYouTubeInput;
}

export interface MutationsubmitFormArgs {
  input: SubmitFormInput;
}

export interface MutationsubmitFormInternalArgs {
  input: SubmitFormInternalInput;
}

export interface MutationsubscribeAnalyticsPlanArgs {
  input: SubscribeAnalyticsPlanInput;
}

export interface MutationsudoLimitedLoginArgs {
  input: SudoLimitedLoginInput;
}

export interface MutationswitchInfluencerForStaffArgs {
  input: SwitchInfluencerForStaffInput;
}

export interface MutationswitchRoleToAdminArgs {
  input: SwitchRoleToAdminInput;
}

export interface MutationswitchRoleToAdvertiserArgs {
  input: SwitchRoleToAdvertiserInput;
}

export interface MutationswitchRoleToAgencyArgs {
  input: SwitchRoleToAgencyInput;
}

export interface MutationswitchRoleToPartnerArgs {
  input: SwitchRoleToPartnerInput;
}

export interface MutationswitchRoleToStaffArgs {
  input: SwitchRoleToStaffInput;
}

export interface MutationswitchRoleToTalentAgencyArgs {
  input: SwitchRoleToTalentAgencyInput;
}

export interface MutationtokenAuthArgs {
  input: ObtainJSONWebTokenInput;
}

export interface MutationtokenAuthLongLiveArgs {
  input: TokenAuthLongLiveInput;
}

export interface MutationtrackYoutubeMusicDownloadArgs {
  input: TrackYoutubeMusicDownloadInput;
}

export interface MutationtransferMoneyToAdAccountArgs {
  input: TransferMoneyToAdAccountInput;
}

export interface MutationunApproveApplicantInfluencersArgs {
  input: UnApproveApplicantInfluencersInput;
}

export interface MutationunassignChatPicArgs {
  input: UnassignChatPICInput;
}

export interface MutationunblockInfluencerMarketplaceArgs {
  input: UnblockInfluencerMarketplaceInput;
}

export interface MutationunresolveChatArgs {
  input: UnresolveChatInput;
}

export interface MutationupdateAccountInformationArgs {
  input: UpdateAccountInformationInput;
}

export interface MutationupdateAddressArgs {
  input: UpdateAddressInput;
}

export interface MutationupdateAddressInformationArgs {
  input: UpdateAddressInformationInput;
}

export interface MutationupdateAdminUserArgs {
  input: UpdateAdminInput;
}

export interface MutationupdateAdvertiserArgs {
  input: UpdateAdvertiserInput;
}

export interface MutationupdateAdvertiserByAdvertiserArgs {
  input: UpdateAdvertiserInput;
}

export interface MutationupdateAdvertiserUserArgs {
  input: UpdateAdvertiserUserInput;
}

export interface MutationupdateAdvertiserUserSettingsArgs {
  input: UpdateAdvertiserUserSettingsInput;
}

export interface MutationupdateAgencyArgs {
  input: UpdatePlainAgencyInput;
}

export interface MutationupdateAgencyUserArgs {
  input: UpdateAgencyInput;
}

export interface MutationupdateAutoManagedAffiliateCommissionRatesArgs {
  input: UpdateAutoManagedAffiliateCommissionRatesInput;
}

export interface MutationupdateAutoManagedCampaignArgs {
  input: UpdateAutoManagedCampaignInput;
}

export interface MutationupdateAutoManagedCampaignsStatusArgs {
  input: UpdateAutoManagedCampaignsStatusInput;
}

export interface MutationupdateBioSectionsArgs {
  input: UpdateBioSectionsInput;
}

export interface MutationupdateCmsAssetShareRateArgs {
  input: UpdateCmsAssetShareRateInput;
}

export interface MutationupdateCmsChannelShareRateArgs {
  input: UpdateCmsChannelShareRateInput;
}

export interface MutationupdateCommentArgs {
  input: UpdateCommentInput;
}

export interface MutationupdateEmailTemplateArgs {
  input: UpdateEmailTemplateInput;
}

export interface MutationupdateEngagementArgs {
  input: UpdateEngagementInput;
}

export interface MutationupdateEngagementBreakdownModeArgs {
  input: UpdateEngagementBreakDownModeInput;
}

export interface MutationupdateEngagementCouponListArgs {
  input: UpdateEngagementCouponListInput;
}

export interface MutationupdateEngagementInfluencerProposalListArgs {
  input: UpdateEngagementInfluencerProposalListInput;
}

export interface MutationupdateEngagementInfluencersBudgetArgs {
  input: UpdateEngagementInfluencersBudgetInput;
}

export interface MutationupdateEngagementPostForInfluencerV2Args {
  input: UpdateEngagementPostInputForInfluencerV2;
}

export interface MutationupdateEngagementPostStatusArgs {
  input: UpdateEngagementPostStatusInput;
}

export interface MutationupdateEngagementPostV2Args {
  input: UpdateEngagementPostInputV2;
}

export interface MutationupdateEngagementSocialAccountProposalListArgs {
  input: UpdateEngagementSocialAccountProposalListInput;
}

export interface MutationupdateEngagementSocialAccountsBudgetArgs {
  input: UpdateEngagementSocialAccountsBudgetInput;
}

export interface MutationupdateEngagementStatusArgs {
  input: UpdateEngagementStatusInput;
}

export interface MutationupdateFanArgs {
  input: UpdateFanInput;
}

export interface MutationupdateFormArgs {
  input: UpdateFormInput;
}

export interface MutationupdateFormStatusArgs {
  input: UpdateFormStatusInput;
}

export interface MutationupdateInfluencerBioArgs {
  input: UpdateInfluencerBioInput;
}

export interface MutationupdateInfluencerPhoneNumberArgs {
  input: UpdateInfluencerPhoneNumberInput;
}

export interface MutationupdateInfluencerProfileArgs {
  input: UpdateInfluencerProfileInput;
}

export interface MutationupdateInfluencerRateCardArgs {
  input: UpdateInfluencerRateCardInput;
}

export interface MutationupdateInfluencerRateCardsArgs {
  input: UpdateInfluencerRateCardsInput;
}

export interface MutationupdateInfluencerRateCardsByStaffArgs {
  input: UpdateInfluencerRateCardsByStaffInput;
}

export interface MutationupdateInfluencerTagsByStaffArgs {
  input: UpdateInfluencerTagsByStaffInput;
}

export interface MutationupdateInfluencerV2Args {
  input: UpdateInfluencerInputV2;
}

export interface MutationupdateInternalMemoArgs {
  input: UpdateInternalMemoInput;
}

export interface MutationupdateLineBroadcastMessageArgs {
  input: UpdateLineBroadcastMessageInput;
}

export interface MutationupdateLinkBioThemeColorArgs {
  input: UpdateLinkBioThemeColorInput;
}

export interface MutationupdateMarketplaceArgs {
  input: UpdateMarketplaceInput;
}

export interface MutationupdateMarketplaceAffiliateCommissionFeesArgs {
  input: UpdateMarketplaceAffiliateCommissionFeesInput;
}

export interface MutationupdateMarketplaceAffiliateCommissionRatesArgs {
  input: UpdateMarketplaceAffiliateCommissionRatesInput;
}

export interface MutationupdateMarketplaceCouponListArgs {
  input: UpdateMarketplaceCouponListInput;
}

export interface MutationupdateMarketplaceInfluencerCostArgs {
  input: UpdateMarketplaceInfluencerCostInput;
}

export interface MutationupdateMarketplacePostStatusV2Args {
  input: UpdateMarketplacePostStatusInputV2;
}

export interface MutationupdateMarketplacePostTrackingUrlArgs {
  input: UpdateMarketplacePostTrackingUrlInput;
}

export interface MutationupdatePackageArgs {
  input: UpdatePackageInput;
}

export interface MutationupdatePackageProposalInfluencersBudgetArgs {
  input: UpdatePackageProposalInfluencersBudgetInput;
}

export interface MutationupdatePackageProposalModeArgs {
  input: UpdatePackageProposalModeInput;
}

export interface MutationupdatePackageProposalSocialAccountsBudgetArgs {
  input: UpdatePackageProposalSocialAccountsBudgetInput;
}

export interface MutationupdatePartnerAgencyArgs {
  input: UpdatePartnerAgencyInput;
}

export interface MutationupdatePartnerUserArgs {
  input: UpdatePartnerUserInput;
}

export interface MutationupdatePasswordByAdvertiserUserArgs {
  input: UpdatePasswordByAdvertiserUserInput;
}

export interface MutationupdatePaymentInformationArgs {
  input: UpdatePaymentInformationInput;
}

export interface MutationupdatePaymentStatusArgs {
  input: UpdatePaymentStatusInput;
}

export interface MutationupdatePersonalUserDetailArgs {
  input: UpdatePersonalUserDetailInput;
}

export interface MutationupdateSocialAccountsArgs {
  input?: InputMaybe<UpdateSocialAccountsInput>;
}

export interface MutationupdateSocialUsernameArgs {
  input: UpdateSocialUsernameInput;
}

export interface MutationupdateStaffUserArgs {
  input: UpdateStaffInput;
}

export interface MutationupdateSubscriptionPlanArgs {
  input: UpdateSubscriptionPlanInput;
}

export interface MutationupdateTalentAgencyArgs {
  input: UpdateTalentAgencyInput;
}

export interface MutationupdateTalentAgencyUserArgs {
  input: UpdateTalentAgencyUserInput;
}

export interface MutationupdateTalentInfluencerArgs {
  input: UpdateTalentInfluencerInput;
}

export interface MutationupdateTiktokAdvertiserInfoArgs {
  input: UpdateTiktokAdvertiserInfoInput;
}

export interface MutationupdateTiktokSpecialCampaignArgs {
  input: UpdateTikTokSpecialCampaignInput;
}

export interface MutationupdateTiktokSpecialPostStatusArgs {
  input: UpdateTiktokSpecialPostStatusInput;
}

export interface MutationupdateTtcmPostArgs {
  input: UpdateTtcmPostInput;
}

export interface MutationupdateUserDetailArgs {
  input: UpdateUserDetailInput;
}

export interface MutationupdateUserProposalBreakdownModeArgs {
  input: UpdateUserProposalBreakdownModeInput;
}

export interface MutationupdateUserProposalInfluencersBudgetArgs {
  input: UpdateUserProposalInfluencersBudgetInput;
}

export interface MutationupdateUserProposalSocialAccountsBudgetArgs {
  input: UpdateUserProposalSocialAccountsBudgetInput;
}

export interface MutationupdateYoutubeMCNInfluencerCredentialsArgs {
  input: UpdateYoutubeMCNCredentialsInput;
}

export interface MutationuploadEngagementReportFileArgs {
  input: UploadEngagementReportFileInput;
}

export interface MutationuploadEngagementReportFromInsightCheckArgs {
  input: UploadEngagementReportFromInsightCheckInput;
}

export interface MutationuploadEngagementXhsInsightCheckArgs {
  input: UploadEngagementXhsInsightCheckInput;
}

export interface MutationuploadOpenAiImagesArgs {
  input: UploadOpenAIImagesInput;
}

export interface MutationuuumSocialAuthSignUpArgs {
  input: UUUMSocialAuthSignUpInput;
}

export interface MutationverifyLineIDTokenArgs {
  input: VerifyLineIDTokenInput;
}

export interface MutationverifyTokenArgs {
  input: VerifyInput;
}

export interface MutationwithdrawMoneyFromAdAccountArgs {
  input: TransferMoneyToAdAccountInput;
}

export interface NeedReconnectForFacebookPayload {
  need: Scalars['Boolean']['output'];
}

export interface NeedReviewMarketplacePost {
  id: Scalars['Int']['output'];
  influencer: MarketplacePostInfluencer;
}

export interface NetSuiteCustomerData {
  companyUrl?: Maybe<Scalars['String']['output']>;
  countryId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
}

export interface NetSuiteCustomerDataForSearch {
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
}

export interface NetSuiteVendorData {
  companyUrl?: Maybe<Scalars['String']['output']>;
  countryId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
}

export interface NetSuiteVendorDataForSearch {
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
}

/** An enumeration. */
export enum NetSuiteVerifiedStatus {
  NOT_VERIFIED = 'NOT_VERIFIED',
  NO_STATUS = 'NO_STATUS',
  VERIFIED = 'VERIFIED',
}

export interface NewAutoManagedCampaignForInfluencerForYourJob {
  brandName?: Maybe<Scalars['String']['output']>;
  commissionRate?: Maybe<Scalars['Float']['output']>;
  currency: Scalars['String']['output'];
  endDate?: Maybe<Scalars['Date']['output']>;
  /** Whether she uploaded her post on social media or not. */
  hasReport: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  mkpServerId: Scalars['Int']['output'];
  startDate?: Maybe<Scalars['Date']['output']>;
  status: AutoManagedAffiliateCampaignStatus;
  thumbNail: Scalars['String']['output'];
  title: Scalars['String']['output'];
  type: CampaignType;
}

export interface NewCampaignPostReport {
  InternalInfo?: Maybe<Array<Scalars['String']['output']>>;
  orderedSummaryDaily?: Maybe<Array<CampaignSummaryDaily>>;
  postInfo: CampaignPostInfo;
  summary: NewCampaignStatsItemRow;
  summaryDaily?: Maybe<Array<CampaignSummaryDaily>>;
}

export interface NewCampaignReportSummary {
  campaignSocialMedias: Array<CampaignSocialMediaType>;
  comment: Scalars['Int']['output'];
  conversions?: Maybe<Scalars['Int']['output']>;
  cost: Scalars['Float']['output'];
  currency: Scalars['String']['output'];
  engagement: Scalars['Float']['output'];
  engagementRate: Scalars['Float']['output'];
  like?: Maybe<Scalars['Int']['output']>;
  orders?: Maybe<Scalars['Int']['output']>;
  postCount: Scalars['Int']['output'];
  /** @deprecated please use sales_tune or sales_referral */
  sales?: Maybe<Scalars['Float']['output']>;
  salesReferral?: Maybe<Scalars['Float']['output']>;
  salesTune?: Maybe<Scalars['Float']['output']>;
}

export interface NewCampaignStatsItemRow {
  age35UpPercentage?: Maybe<Scalars['Float']['output']>;
  age1824Percentage?: Maybe<Scalars['Float']['output']>;
  age2534Percentage?: Maybe<Scalars['Float']['output']>;
  averageViewDurationMinutes?: Maybe<Scalars['Float']['output']>;
  averageViewDurationPercentage?: Maybe<Scalars['Float']['output']>;
  averageViewDurationSeconds?: Maybe<Scalars['Int']['output']>;
  averageViewTime?: Maybe<Scalars['Float']['output']>;
  back?: Maybe<Scalars['Int']['output']>;
  click?: Maybe<Scalars['Int']['output']>;
  comment: Scalars['Int']['output'];
  conversion?: Maybe<Scalars['Int']['output']>;
  cost: Scalars['Float']['output'];
  costsPerAction?: Maybe<Scalars['Float']['output']>;
  costsPerClick?: Maybe<Scalars['Float']['output']>;
  /** For Vietnam campaigns */
  costsPerComment?: Maybe<Scalars['Float']['output']>;
  costsPerEngagement?: Maybe<Scalars['Float']['output']>;
  costsPerFollower?: Maybe<Scalars['Float']['output']>;
  costsPerImpressions?: Maybe<Scalars['Float']['output']>;
  costsPerLike?: Maybe<Scalars['Float']['output']>;
  costsPerOrder?: Maybe<Scalars['Float']['output']>;
  costsPerReach?: Maybe<Scalars['Float']['output']>;
  costsPerSale?: Maybe<Scalars['Float']['output']>;
  costsPerSaleReferral?: Maybe<Scalars['Float']['output']>;
  costsPerSaleTune?: Maybe<Scalars['Float']['output']>;
  costsPerSaved?: Maybe<Scalars['Float']['output']>;
  costsPerShare?: Maybe<Scalars['Float']['output']>;
  costsPerView?: Maybe<Scalars['Float']['output']>;
  dislikes?: Maybe<Scalars['Int']['output']>;
  engagement: Scalars['Float']['output'];
  /** Engagement Rate by Followers for IG, FB, FB Page, X, Threads */
  engagementRate?: Maybe<Scalars['Float']['output']>;
  /** Engagement Rate by Views for TikTok, YT, Douyin */
  engagementViewsRate?: Maybe<Scalars['Float']['output']>;
  exited?: Maybe<Scalars['Int']['output']>;
  femalePercentage?: Maybe<Scalars['Float']['output']>;
  followerReach?: Maybe<Scalars['Int']['output']>;
  followerReachRate?: Maybe<Scalars['Float']['output']>;
  followers: Scalars['Int']['output'];
  forward?: Maybe<Scalars['Int']['output']>;
  impressionRate?: Maybe<Scalars['Float']['output']>;
  impressions?: Maybe<Scalars['Int']['output']>;
  impressionsFromDiscovery?: Maybe<Scalars['Int']['output']>;
  impressionsFromHashtag?: Maybe<Scalars['Int']['output']>;
  impressionsFromHome?: Maybe<Scalars['Int']['output']>;
  impressionsFromOther?: Maybe<Scalars['Int']['output']>;
  impressionsFromProfile?: Maybe<Scalars['Int']['output']>;
  interaction?: Maybe<Scalars['Int']['output']>;
  like?: Maybe<Scalars['Int']['output']>;
  linkClicks?: Maybe<Scalars['Int']['output']>;
  malePercentage?: Maybe<Scalars['Float']['output']>;
  navigation?: Maybe<Scalars['Int']['output']>;
  /** For admin or staff user, and EngagementCampaign */
  net?: Maybe<Scalars['Float']['output']>;
  newFollowers?: Maybe<Scalars['Int']['output']>;
  nextStory?: Maybe<Scalars['Int']['output']>;
  nonFollowerReach?: Maybe<Scalars['Int']['output']>;
  nonFollowerReachRate?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  profileActivity?: Maybe<Scalars['Int']['output']>;
  /** For admin or staff user, and EngagementCampaign */
  profit?: Maybe<Scalars['Float']['output']>;
  /** For admin or staff user, and EngagementCampaign */
  profitRate?: Maybe<Scalars['Float']['output']>;
  reach?: Maybe<Scalars['Int']['output']>;
  reachRate?: Maybe<Scalars['Float']['output']>;
  /** @deprecated please use sales_tune or sales_referral */
  sales?: Maybe<Scalars['Float']['output']>;
  salesReferral?: Maybe<Scalars['Float']['output']>;
  salesTune?: Maybe<Scalars['Float']['output']>;
  saved?: Maybe<Scalars['Int']['output']>;
  share?: Maybe<Scalars['Int']['output']>;
  stickerTaps?: Maybe<Scalars['Int']['output']>;
  totalPlayTime?: Maybe<Scalars['Int']['output']>;
  videoCompletionRate?: Maybe<Scalars['Float']['output']>;
  view?: Maybe<Scalars['Int']['output']>;
  /** XHS metric */
  viewsFromFollowing?: Maybe<Scalars['Int']['output']>;
  /** XHS metric */
  viewsFromHome?: Maybe<Scalars['Int']['output']>;
  /** XHS metric */
  viewsFromOther?: Maybe<Scalars['Int']['output']>;
  /** XHS metric */
  viewsFromProfile?: Maybe<Scalars['Int']['output']>;
  /** XHS metric */
  viewsFromSearch?: Maybe<Scalars['Int']['output']>;
}

export interface NewEngagementCampaignForInfluencerForYourJob {
  currency: Scalars['String']['output'];
  endDate: Scalars['Date']['output'];
  hasDraftPosts: Scalars['Boolean']['output'];
  /** Whether she uploaded her post on social media or not. */
  hasReport: Scalars['Boolean']['output'];
  hasWarningPosts: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  isTtcmCampaign: Scalars['Boolean']['output'];
  /** influencer revenue */
  rewardAmount?: Maybe<Scalars['Float']['output']>;
  socialMedias: Array<CampaignSocialMediaType>;
  startDate: Scalars['Date']['output'];
  status: AnyXEngagementCampaignStatus;
  thumbNail: Scalars['String']['output'];
  title: Scalars['String']['output'];
  ttcmStatus?: Maybe<TTCMStatus>;
  type: CampaignType;
}

export interface NewMarketplaceCampaignForInfluencerForYourJob {
  /** exists when campaign type is SELECTION */
  appliedStatus?: Maybe<MarketplaceCampaignAppliedStatus>;
  campaignDetailType: MarketplaceCampaignDetailType;
  campaignStatus?: Maybe<CampaignStatusForInfluencer>;
  commissionRate?: Maybe<Scalars['Float']['output']>;
  currency: Scalars['String']['output'];
  endDate?: Maybe<Scalars['Date']['output']>;
  hasPostsCouponMissing?: Maybe<Scalars['Boolean']['output']>;
  /** Whether she uploaded her post on social media or not. */
  hasReport: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  /** For Selection Marketplace Campaign */
  isApplying?: Maybe<Scalars['Boolean']['output']>;
  isAutoInfluencerApproval?: Maybe<Scalars['Boolean']['output']>;
  isGiftingCampaign?: Maybe<Scalars['Boolean']['output']>;
  isPostTracking?: Maybe<Scalars['Boolean']['output']>;
  isSelectionCampaign?: Maybe<Scalars['Boolean']['output']>;
  isWaiting: Scalars['Boolean']['output'];
  mkpServerId?: Maybe<Scalars['Int']['output']>;
  preLaunchDate?: Maybe<Scalars['Date']['output']>;
  remainingAmount?: Maybe<Scalars['Float']['output']>;
  revenuePerAction?: Maybe<Scalars['Float']['output']>;
  revenuePerClick?: Maybe<Scalars['Float']['output']>;
  revenuePerComment?: Maybe<Scalars['Float']['output']>;
  revenuePerFollower?: Maybe<Scalars['Float']['output']>;
  revenuePerLike?: Maybe<Scalars['Float']['output']>;
  revenuePerOrder?: Maybe<Scalars['Float']['output']>;
  revenuePerPost?: Maybe<Scalars['Float']['output']>;
  revenuePerShare?: Maybe<Scalars['Float']['output']>;
  revenuePerView?: Maybe<Scalars['Float']['output']>;
  socialMedias: Array<CampaignSocialMediaType>;
  startDate: Scalars['Date']['output'];
  status?: Maybe<AnyXMarketplaceCampaignStatus>;
  thumbNail: Scalars['String']['output'];
  title: Scalars['String']['output'];
  totalAmount?: Maybe<Scalars['Float']['output']>;
  type: CampaignType;
}

export interface NewTiktokSpecialCampaignForInfluencerForYourJob {
  campaignStatus: CampaignStatusForInfluencer;
  currency: Scalars['String']['output'];
  endDate: Scalars['Date']['output'];
  /** Whether she uploaded her post on social media or not. */
  hasReport: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  mkpServerId: Scalars['Int']['output'];
  preLaunchDate?: Maybe<Scalars['Date']['output']>;
  revenuePerPost: Scalars['Float']['output'];
  socialMedia: CampaignSocialMediaType;
  startDate: Scalars['Date']['output'];
  thumbNail: Scalars['String']['output'];
  title: Scalars['String']['output'];
  type: CampaignType;
}

export enum NotificationId {
  AD_HOC_PAYMENT_FLOW_CHANGED = 'AD_HOC_PAYMENT_FLOW_CHANGED',
  BANK_ACCOUNT_CHECK = 'BANK_ACCOUNT_CHECK',
  ENGAGEMENT_DRAFT_POST_APPROVED = 'ENGAGEMENT_DRAFT_POST_APPROVED',
  ENGAGEMENT_DRAFT_POST_REJECTED = 'ENGAGEMENT_DRAFT_POST_REJECTED',
  ENGAGEMENT_FETCHED = 'ENGAGEMENT_FETCHED',
  ENGAGEMENT_FINISH = 'ENGAGEMENT_FINISH',
  MARKETPLACE_CAMPAIGN_FINISHED = 'MARKETPLACE_CAMPAIGN_FINISHED',
  MARKETPLACE_CAMPAIGN_JOINED = 'MARKETPLACE_CAMPAIGN_JOINED',
  MARKETPLACE_CAMPAIGN_REACH_BUDGET = 'MARKETPLACE_CAMPAIGN_REACH_BUDGET',
  MARKETPLACE_CAMPAIGN_REACH_BUDGET_NP = 'MARKETPLACE_CAMPAIGN_REACH_BUDGET_NP',
  MARKETPLACE_CAMPAIGN_STARTED = 'MARKETPLACE_CAMPAIGN_STARTED',
  MARKETPLACE_CAMPAIGN_UPCOMING = 'MARKETPLACE_CAMPAIGN_UPCOMING',
  MARKETPLACE_CAMPAIGN_UPDATE = 'MARKETPLACE_CAMPAIGN_UPDATE',
  MARKETPLACE_DRAFT_POST_APPROVED = 'MARKETPLACE_DRAFT_POST_APPROVED',
  MARKETPLACE_DRAFT_POST_REJECTED = 'MARKETPLACE_DRAFT_POST_REJECTED',
  MARKETPLACE_SHIPPED = 'MARKETPLACE_SHIPPED',
  MP_CAMPAIGNS_APPLICANT_INFLS_APPROVED = 'MP_CAMPAIGNS_APPLICANT_INFLS_APPROVED',
  MP_CAMPAIGNS_APPLICANT_INFLS_INAPPROPRIATE = 'MP_CAMPAIGNS_APPLICANT_INFLS_INAPPROPRIATE',
  MP_CAMPAIGNS_APPLICANT_INFLS_REJECTED = 'MP_CAMPAIGNS_APPLICANT_INFLS_REJECTED',
  PAYMENT_COMPLETED = 'PAYMENT_COMPLETED',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  PAYMENT_REQUEST_DONE = 'PAYMENT_REQUEST_DONE',
}

/**  `NotificationPayload` payload consists of the data of a single notification. */
export interface NotificationPayload {
  content: Scalars['JSON']['output'];
  created: Scalars['DateTime']['output'];
  id: Scalars['Long']['output'];
  isClicked: Scalars['Boolean']['output'];
  notificationId: NotificationId;
}

/**
 *  `checkpoint`: a checkpoint to get notifications beyond the checkpoint. A value of `null` can be provided to start from the latest notification.
 *  `appType`: a enum type which represents the notification scope of application
 */
export interface NotificationsByAppInput {
  appType: AppType;
  checkpoint?: InputMaybe<Scalars['Long']['input']>;
}

/**
 *  `checkpoint`: an offset used for the next query for the API to know where we left off. (Lookup keyset pagination for more info)
 *  `hasMore`: a boolean informing whether is there any more notifications available to grab
 */
export interface NotificationsPayload {
  checkpoint: Scalars['Long']['output'];
  hasMore: Scalars['Boolean']['output'];
  notifications: Array<NotificationPayload>;
}

/** An enumeration. */
export enum ORDER {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface ObtainJSONWebTokenInput {
  appName?: InputMaybe<AppName>;
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
}

export interface ObtainJSONWebTokenPayload {
  company?: Maybe<Scalars['String']['output']>;
  /** Users's country's id */
  countryId?: Maybe<Scalars['String']['output']>;
  /** Users's country's currency id  */
  currencyId: Scalars['String']['output'];
  hash?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  refreshToken?: Maybe<Scalars['String']['output']>;
  role: UserRoles;
  subscriptionPaymentFailed?: Maybe<Scalars['Boolean']['output']>;
  token: Scalars['String']['output'];
  userId: Scalars['Int']['output'];
}

export interface OpenAIMessage {
  /** value will return when status is READY_TO_SEARCH */
  influencerIds: Array<Scalars['Int']['output']>;
  /** for debugging */
  jsonResponses?: Maybe<Array<Scalars['String']['output']>>;
  nextAction: OpenAINextAction;
  runId: Scalars['String']['output'];
  text?: Maybe<Scalars['String']['output']>;
  threadId: Scalars['Int']['output'];
}

/** An enumeration. */
export enum OpenAINextAction {
  COLLECTING = 'COLLECTING',
  ERROR = 'ERROR',
  READY_TO_SEARCH = 'READY_TO_SEARCH',
  WAITING_FOR_RESPONSE = 'WAITING_FOR_RESPONSE',
}

/** An enumeration. */
export enum OpenAIRunSourceFrom {
  ALL_INFLUENCERS_TAB = 'ALL_INFLUENCERS_TAB',
  INSTAGRAM_TAB = 'INSTAGRAM_TAB',
  TIKTOK_TAB = 'TIKTOK_TAB',
  YOUTUBE_TAB = 'YOUTUBE_TAB',
}

export interface OperatedUserInfo {
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
}

export interface OptionInput {
  optionTitle: Scalars['String']['input'];
  order: Scalars['Int']['input'];
}

export enum Order {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface PackageCompany {
  /** id of company */
  companyId: Scalars['Int']['output'];
  /** unique result index used for FE purpose */
  id?: Maybe<Scalars['Int']['output']>;
  /** name of company */
  name: Scalars['String']['output'];
  /** company role */
  role: UserRoles;
}

export interface PackageInfluencer {
  accountList: Array<PackageSocialAccount>;
  addedDate?: Maybe<Scalars['Date']['output']>;
  avatar?: Maybe<Scalars['String']['output']>;
  /** Influencer id */
  id: Scalars['Int']['output'];
  /** Influencer name */
  name: Scalars['String']['output'];
  status: SocialAccountStatus;
  summary: PackageSummary;
}

export interface PackageProposal {
  influencers: Array<PackageInfluencer>;
  totalCount: Scalars['Int']['output'];
}

export interface PackageProposalInfluencerBudgetInput {
  budget: Scalars['Float']['input'];
  influencerCost: Scalars['Float']['input'];
  influencerId: Scalars['Int']['input'];
  profit: Scalars['Float']['input'];
}

export interface PackageProposalSocialAcccountBudgetInput {
  budget: Scalars['Float']['input'];
  influencerCost: Scalars['Float']['input'];
  influencerId: Scalars['Int']['input'];
  profit: Scalars['Float']['input'];
  socialAccountId: Scalars['Int']['input'];
  socialAccountType: SocialAccountType;
}

export interface PackageProposalSummary {
  currency: Scalars['String']['output'];
  /** Package Id */
  id: Scalars['Int']['output'];
  jdMode: ProposalJobDescriptionBreakDownMode;
  name: Scalars['String']['output'];
  summary: PackageSummaryV2;
}

export interface PackageSocialAccount {
  addedDate?: Maybe<Scalars['Date']['output']>;
  /** Social account id */
  id: Scalars['Int']['output'];
  /** Social account name */
  name?: Maybe<Scalars['String']['output']>;
  /** Social account type */
  socialMedia: SocialAccountType;
  summary: PackageSummary;
}

export interface PackageSummary {
  averageEngagement?: Maybe<Scalars['Float']['output']>;
  budget?: Maybe<Scalars['Float']['output']>;
  engagementFollowerRate?: Maybe<Scalars['Float']['output']>;
  engagementPostedCount?: Maybe<Scalars['Int']['output']>;
  engagementProposedCount?: Maybe<Scalars['Int']['output']>;
  engagementViewRate?: Maybe<Scalars['Float']['output']>;
  followersCount?: Maybe<Scalars['Int']['output']>;
  influencerCost?: Maybe<Scalars['Float']['output']>;
  profit?: Maybe<Scalars['Float']['output']>;
}

export interface PackageSummaryV2 {
  avgEngagementPosted?: Maybe<Scalars['Float']['output']>;
  avgEngagementProposed?: Maybe<Scalars['Float']['output']>;
  budget?: Maybe<Scalars['Float']['output']>;
  engagementFollowerRate?: Maybe<Scalars['Float']['output']>;
  engagementViewRate?: Maybe<Scalars['Float']['output']>;
  followersCount?: Maybe<Scalars['BigInt']['output']>;
  influencerCost?: Maybe<Scalars['Float']['output']>;
  profit?: Maybe<Scalars['Float']['output']>;
}

export interface ParticipationForm {
  formName: Scalars['String']['output'];
  submissionId: Scalars['Long']['output'];
  submittedAt: Scalars['String']['output'];
}

export interface Partner {
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
}

export interface PartnerAgencyUserWithRole {
  companyName?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  role: UserRoles;
}

export interface PartnerAgencyWithCompanies {
  advertiserIds: Array<Scalars['Int']['output']>;
  agencyIds: Array<Scalars['Int']['output']>;
  companyUrl?: Maybe<Scalars['String']['output']>;
  country?: Maybe<CountryName>;
  id: Scalars['Int']['output'];
  influencerCostPermission: Scalars['Boolean']['output'];
  logo?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  netsuiteId?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  privateInfluencers: Array<PrivatePartnerInfluencer>;
}

export interface PartnerAgencyWithNSVerification {
  companyUrl?: Maybe<Scalars['String']['output']>;
  country?: Maybe<CountryName>;
  id: Scalars['Int']['output'];
  logo?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  netsuiteId?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  users: Scalars['Int']['output'];
  verifiedStatus?: Maybe<NetSuiteVerifiedStatus>;
}

export interface PartnerAgencyWithPermission {
  id: Scalars['Int']['output'];
  influencerCostPermission: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
}

/**  Stores a redirect URL that FE uses to reconnect partner influencer accounts */
export interface PartnerReconnectAnalyticsSocialAuthRedirectUrlPayload {
  redirectUri: Scalars['String']['output'];
}

export interface PayableCampaign {
  campaignType: CampaignType;
  currency: Scalars['String']['output'];
  /** Marketplace OR AutoManaged campaign */
  id: Scalars['Int']['output'];
  revenue: Scalars['Float']['output'];
  socialMedia?: Maybe<SocialAccountType>;
  thumbNail: Scalars['String']['output'];
  title: Scalars['String']['output'];
}

export interface Payment {
  currency: Scalars['String']['output'];
  dueDate: Scalars['Date']['output'];
  fee: Scalars['Float']['output'];
  grossRevenue: Scalars['Float']['output'];
  id: Scalars['Int']['output'];
  influencer: PaymentInfluencerAccount;
  netRevenue: Scalars['Float']['output'];
  paidDate?: Maybe<Scalars['Date']['output']>;
  requestedDate: Scalars['Date']['output'];
  status: PaymentRequestStatus;
}

export interface PaymentInfluencerAccount {
  email: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
}

export interface PaymentInformation {
  info?: Maybe<PaymentInformationDetails>;
  isEditable: Scalars['Boolean']['output'];
}

export interface PaymentInformationDetails {
  accountName: Scalars['String']['output'];
  accountNumber: Scalars['String']['output'];
  bankName: Scalars['String']['output'];
  branchName: Scalars['String']['output'];
}

export interface PaymentInformationInput {
  accountName: Scalars['String']['input'];
  accountNumber: Scalars['String']['input'];
  bankName: Scalars['String']['input'];
  branchName: Scalars['String']['input'];
}

/** An enumeration. */
export enum PaymentPackageStatus {
  FAILED = 'FAILED',
  INVALID = 'INVALID',
  IN_PROGRESS = 'IN_PROGRESS',
  PAID = 'PAID',
}

export interface PaymentPackageStatusResponse {
  failureCode?: Maybe<Scalars['String']['output']>;
  failureMessage?: Maybe<Scalars['String']['output']>;
  status: PaymentPackageStatus;
}

/** An enumeration. */
export enum PaymentPlanName {
  ADVANCED_CAMPAIGN = 'ADVANCED_CAMPAIGN',
  BASIC_CAMPAIGN = 'BASIC_CAMPAIGN',
  BEGINNER = 'BEGINNER',
  ENTERPRISE = 'ENTERPRISE',
  PRO_CAMPAIGN = 'PRO_CAMPAIGN',
  STANDARD = 'STANDARD',
}

/** An enumeration. */
export enum PaymentPlanType {
  ANALYTICS = 'ANALYTICS',
  MARKETPLACE = 'MARKETPLACE',
  TIKTOK_ADS = 'TIKTOK_ADS',
}

/** An enumeration. */
export enum PaymentPriceType {
  MONTHLY = 'MONTHLY',
  ONE_TIME = 'ONE_TIME',
  SIX_MONTHS = 'SIX_MONTHS',
  TWELVE_MONTHS = 'TWELVE_MONTHS',
}

export interface PaymentRequest {
  currency: Scalars['String']['output'];
  fee: Scalars['Float']['output'];
  grossRevenue: Scalars['Float']['output'];
  minimumPaymentAmount: Scalars['Float']['output'];
}

/** An enumeration. */
export enum PaymentRequestStatus {
  FAILED = 'FAILED',
  PAID = 'PAID',
  REJECTED = 'REJECTED',
  REVIEWING = 'REVIEWING',
}

/** An enumeration. */
export enum PaymentRequestStatusForInfluencer {
  FAILED = 'FAILED',
  REJECTED = 'REJECTED',
  REVIEWING = 'REVIEWING',
  TRANSFERRED = 'TRANSFERRED',
}

export interface PersonalUserDetailPayload {
  displayLanguage?: Maybe<DisplayLanguage>;
  email: Scalars['String']['output'];
  isAvailableCountry: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  notificationSetting?: Maybe<Scalars['Boolean']['output']>;
}

export interface PicName {
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
}

export interface PicUser {
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
}

export interface PlainTextMessageInput {
  text: Scalars['String']['input'];
}

export interface PlainTextMessagePayload {
  text: Scalars['String']['output'];
}

export interface PostByHashtag {
  hashtagId: Scalars['Int']['output'];
  posts: Array<PostsForHashtagDashboard>;
}

/** An enumeration. */
export enum PostListSortField {
  AGE = 'AGE',
  COMMENT = 'COMMENT',
  COUNTRY = 'COUNTRY',
  ENGAGEMENT = 'ENGAGEMENT',
  ENGAGEMENT_RATE = 'ENGAGEMENT_RATE',
  FOLLOWERS = 'FOLLOWERS',
  GENDER = 'GENDER',
  IMPRESSION = 'IMPRESSION',
  LIKE = 'LIKE',
  POST_DATE = 'POST_DATE',
  REACH = 'REACH',
  SAVED = 'SAVED',
  SHARE = 'SHARE',
  VIEW = 'VIEW',
}

export enum PostListSortFieldV3 {
  ENGAGEMENT_RATE = 'ENGAGEMENT_RATE',
  FOLLOWERS = 'FOLLOWERS',
  POST_DATE = 'POST_DATE',
  RELEVANT = 'RELEVANT',
}

export interface PostOrderBy {
  field?: InputMaybe<PostListSortField>;
  order?: InputMaybe<ORDER>;
}

export interface PostOrderByV3 {
  field?: InputMaybe<PostListSortFieldV3>;
  order?: InputMaybe<Order>;
}

export interface PostsByTime {
  date: Scalars['Date']['output'];
  postIds: Array<Scalars['Int']['output']>;
  postsCount: Scalars['Int']['output'];
}

export interface PostsForHashtagDashboard {
  id: Scalars['Int']['output'];
  likes: Scalars['Int']['output'];
  thumbnail: Scalars['String']['output'];
}

export interface PostsMetricsGridReport {
  averageMetricRate?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  posts: Array<CampaignPostReportGrid>;
}

export interface PricingTable {
  plans: Array<PricingTableItem>;
}

export interface PricingTableItem {
  currency: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  /** price id */
  id: Scalars['Int']['output'];
  isCurrentPlan: Scalars['Boolean']['output'];
  mkpCampaignEnabled: Scalars['Boolean']['output'];
  name: PaymentPlanName;
  numberOfInfluencers?: Maybe<Scalars['Int']['output']>;
  packageId?: Maybe<Scalars['Int']['output']>;
  price: Scalars['Float']['output'];
  priceType: PaymentPriceType;
  trialDays?: Maybe<Scalars['Int']['output']>;
}

export interface PrivatePartnerInfluencer {
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
}

export interface PrivateTalentInfluencer {
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
}

export interface PromotionMethodEmailNewsLetterForProfile {
  description?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
}

export interface PromotionMethodEmailNewsLetterPayload {
  description?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
}

export interface PromotionMethodInput {
  description?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  method: CampaignPromotionMethod;
  mobileAppType?: InputMaybe<PromotionMobileAppType>;
  url?: InputMaybe<Scalars['String']['input']>;
}

export interface PromotionMethodMobileAppForProfile {
  id: Scalars['Int']['output'];
  mobileAppType?: Maybe<PromotionMobileAppType>;
  url?: Maybe<Scalars['String']['output']>;
}

export interface PromotionMethodMobileAppPayload {
  id: Scalars['ID']['output'];
  mobileAppType?: Maybe<PromotionMobileAppType>;
  url?: Maybe<Scalars['String']['output']>;
}

export interface PromotionMethodOfflineForProfile {
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
}

export interface PromotionMethodOfflinePayload {
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
}

export interface PromotionMethodPodCastForProfile {
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  url?: Maybe<Scalars['String']['output']>;
}

export interface PromotionMethodPodCastPayload {
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  url?: Maybe<Scalars['String']['output']>;
}

export interface PromotionMethodWebsiteForProfile {
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  url?: Maybe<Scalars['String']['output']>;
}

export interface PromotionMethodWebsitePayload {
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  url?: Maybe<Scalars['String']['output']>;
}

export enum PromotionMobileAppType {
  ANDROID = 'ANDROID',
  APPLE = 'APPLE',
}

/** An enumeration. */
export enum ProposalDownloadFormat {
  EXCEL = 'EXCEL',
  SPREADSHEET = 'SPREADSHEET',
}

export interface ProposalInfluencer {
  accountList: Array<ProposalSocialAccount>;
  addedDate?: Maybe<Scalars['Date']['output']>;
  avatar?: Maybe<Scalars['String']['output']>;
  couponCode?: Maybe<Scalars['String']['output']>;
  deniedReason?: Maybe<Scalars['String']['output']>;
  /** Influencer id */
  id: Scalars['Int']['output'];
  isDenied?: Maybe<Scalars['Boolean']['output']>;
  jdMode: ProposalJobDescriptionBreakDownMode;
  /** Influencer name */
  name: Scalars['String']['output'];
  reasons?: Maybe<Scalars['String']['output']>;
  requirements?: Maybe<Scalars['String']['output']>;
  status: SocialAccountStatus;
  summary: ProposalJobSummary;
  ttcmCampaignCode?: Maybe<Scalars['String']['output']>;
  ttcmCampaignInviteLink?: Maybe<Scalars['String']['output']>;
  ttcmStatus?: Maybe<TTCMStatus>;
}

/** An enumeration. */
export enum ProposalJobDescriptionBreakDownMode {
  INFLUENCER_BREAKDOWN = 'INFLUENCER_BREAKDOWN',
  SOCIAL_BREAKDOWN = 'SOCIAL_BREAKDOWN',
}

export interface ProposalJobSummary {
  adAgencyMarginRate?: Maybe<Scalars['Float']['output']>;
  adAgencyMarginValue?: Maybe<Scalars['Int']['output']>;
  averageEngagement?: Maybe<Scalars['Float']['output']>;
  budget?: Maybe<Scalars['Float']['output']>;
  engagementFollowerRate?: Maybe<Scalars['Float']['output']>;
  engagementPostedCount?: Maybe<Scalars['Int']['output']>;
  engagementProposedCount?: Maybe<Scalars['Int']['output']>;
  engagementViewRate?: Maybe<Scalars['Float']['output']>;
  followersCount?: Maybe<Scalars['Int']['output']>;
  influencerCost?: Maybe<Scalars['Float']['output']>;
  marginRate?: Maybe<Scalars['Float']['output']>;
  netBudget?: Maybe<Scalars['Float']['output']>;
  posts?: Maybe<Scalars['Int']['output']>;
  profit?: Maybe<Scalars['Float']['output']>;
}

export interface ProposalSocialAccount {
  addedDate?: Maybe<Scalars['Date']['output']>;
  /** Social account id */
  id: Scalars['Int']['output'];
  /** Social account name */
  name?: Maybe<Scalars['String']['output']>;
  /** Social account type */
  socialMedia: EngagementProposalSocialMediaType;
  summary: ProposalJobSummary;
  ttcmCampaignInviteLink?: Maybe<Scalars['String']['output']>;
  ttcmStatus?: Maybe<TTCMStatus>;
}

export interface Province {
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
}

export interface ProvincesPayload {
  provinces: Array<Province>;
}

export interface PublicBioSection {
  id: Scalars['Int']['output'];
  image?: Maybe<BioImage>;
  link?: Maybe<BioUrlLink>;
  sectionType: BioSectionType;
  socialMedia?: Maybe<BioSocialMedia>;
  text?: Maybe<BioText>;
}

export interface PublicBioTheme {
  bg: PublicLinkBioBackground;
  button?: Maybe<BioButtonStyle>;
  color: PublicLinkBioPageColor;
  font?: Maybe<BioFontStyle>;
  theme?: Maybe<BioTheme>;
}

export interface PublicLinkBioBackground {
  bgColor?: Maybe<Scalars['String']['output']>;
  media?: Maybe<Scalars['String']['output']>;
}

export interface PublicLinkBioPageColor {
  buttonColor?: Maybe<Scalars['String']['output']>;
  buttonFontColor?: Maybe<Scalars['String']['output']>;
  fontColor?: Maybe<Scalars['String']['output']>;
}

export interface PublishInfluencerBioInput {
  isPublic: Scalars['Boolean']['input'];
}

export interface PublishInfluencerBioPayload {
  ok: Scalars['Boolean']['output'];
}

export interface Query {
  _service: _Service;
  /**
   *  Gets a specific account analytics based on the input id
   *  May be used by admin, staff, agency, and advertisers
   *  Multiple social accounts should be expected
   */
  accountAnalyticDetailsV2: AccountAnalyticsDetail;
  /**
   *  Returns basic information of an analytics account
   *  May be used by admin, staff, agency, and advertisers
   */
  accountAnalyticsBasicDetailsV2: BasicAccountAnalyticsPayload;
  address?: Maybe<Address>;
  addressForInfluencer?: Maybe<AddressForInfluencer>;
  advertiser?: Maybe<Advertiser>;
  advertiserForAdvertiserUser?: Maybe<AdvertiserForAdvertiserUser>;
  advertiserUserSettingsDetails?: Maybe<AdvertiserUserSettingsDetails>;
  agency?: Maybe<AgencyWithAdvertisers>;
  agencyBusinessAssociateCompanies?: Maybe<Array<AssociateCompany>>;
  agencyBusinessAssociateCompaniesCount?: Maybe<TotalNumber>;
  agencyLogo?: Maybe<AgencyLogo>;
  aiChatModel: AiChatModelName;
  /**
   *  Returns the total number of analytic accounts.
   *  May be used by admin, staff, and agency
   */
  allAccountAnalyticsCountForAdminV2: TotalNumber;
  /**
   *  Returns a paginated list of analytic accounts.
   *  May be used by admin, staff, and agency
   *  Multiple social accounts should be expected
   */
  allAccountAnalyticsForAdminV2: AllAccountAnalyticsForAdminPayload;
  /**
   *  Returns a paginated list of analytic accounts.
   *  May be used by advertisers
   *  Multiple social accounts should be expected
   */
  allAccountAnalyticsForAdvertiserV2: AllAccountAnalyticsForAdvertiserPayload;
  allAdvertiserPics?: Maybe<Array<AdvertiserUserWithRole>>;
  allAdvertisers?: Maybe<Array<AdvertiserForList>>;
  allAdvertisersCount?: Maybe<TotalNumber>;
  allAdvertisersForSearch: AllAdvertisersForSearch;
  allAgencies?: Maybe<Array<AgencyWithNSVerification>>;
  allAgenciesCount?: Maybe<TotalNumber>;
  allAgenciesForSearch?: Maybe<Array<AgenciesForSearch>>;
  allAgencyUsers?: Maybe<Array<AgencyUserWithRole>>;
  allAnalyticsSubscriptionPlans?: Maybe<Array<AnalyticsSubscriptionPlanName>>;
  allAnycreatorCategories?: Maybe<Array<AnyCreatorCategory>>;
  allApplicantInfluencersForMarketplace?: Maybe<Array<ApplicantInfluencer>>;
  allApplicantInfluencersForMarketplaceCount?: Maybe<TotalNumber>;
  allAvailableCountries: Array<CountryAndCurrency>;
  allBankBranches?: Maybe<Array<Maybe<BankBranch>>>;
  allBankBranchesForInfluencer?: Maybe<Array<Maybe<BankBranchForInfluencer>>>;
  allBanks?: Maybe<Array<Maybe<Bank>>>;
  allBanksForInfluencer?: Maybe<Array<Maybe<BankForInfluencer>>>;
  allCampaignCategories?: Maybe<Array<CampaignCategoryName>>;
  allCampaignPosts: CampaignPosts;
  allCampaignsForFilter: CampaignForPostFilter;
  allCampaignsForSearch?: Maybe<Array<CampaignForSearch>>;
  allCampaignsForYourJob?: Maybe<Array<CampaignForInfluencerForYourJob>>;
  allCampaignsForYourJobCounts?: Maybe<CampaignForInfluencerForYourJobCounts>;
  allCampaignsReport?: Maybe<Array<CampaignDetailReport>>;
  allCampaignsSummary?: Maybe<AllCampaignsReportSummary>;
  allCategories?: Maybe<Array<MajorCategory>>;
  allCities?: Maybe<Array<Maybe<City>>>;
  allCitiesForInfluencer?: Maybe<Array<Maybe<CityForInfluencer>>>;
  allConnectedFacebookPages?: Maybe<Array<ConnectedFacebookPage>>;
  allCountries: Array<CountryAndCurrency>;
  /**  Searches douyin accounts */
  allDouyinInfluencers: DouyinInfluencerSearchResult;
  allEngagementPosts?: Maybe<Array<EngagementPost>>;
  allEngagementPostsCount?: Maybe<TotalNumber>;
  allEngagementPostsForInfluencer?: Maybe<Array<EngagementPostForInfluencer>>;
  allEngagementPostsForInsightCheck?: Maybe<EngagementCampaignPostsForInsightCheck>;
  allEngagements?: Maybe<Array<Engagement>>;
  allEngagementsCount?: Maybe<TotalNumber>;
  allEngagementsForInsightCheck?: Maybe<EngagementsForInsightCheck>;
  allEngagementsForInsightCheckCount?: Maybe<EngagementsForInsightCheckCount>;
  allExistingPackages?: Maybe<Array<ReadPackage>>;
  allFacebookAccountInfluencers?: Maybe<Array<FacebookInfluencer>>;
  allFacebookAccountInfluencersCount?: Maybe<TotalNumber>;
  /**  Searches facebook accounts */
  allFacebookAccountInfluencersV2: FacebookAccountInfluencerSearchResultV2;
  allFacebookPageInfluencers?: Maybe<Array<FacebookPageInfluencer>>;
  allFacebookPageInfluencersCount?: Maybe<TotalNumber>;
  /**  Searches facebook pages */
  allFacebookPageInfluencersV2: FacebookPageInfluencerSearchResultV2;
  allInfluencerAccountPosts?: Maybe<Array<InfluencerSocialPost>>;
  allInfluencerAccountPostsCount?: Maybe<TotalNumber>;
  allInfluencerCategories?: Maybe<Array<CategoryName>>;
  allInfluencerManagers?: Maybe<Array<InfluencerManagerOutput>>;
  allInfluencerPostsV3: InfluencerPostsV3;
  allInfluencersCountForMarketplace?: Maybe<TotalNumber>;
  allInfluencersForMarketplaceV2?: Maybe<Array<MarketplaceInfluencer>>;
  /**  Searches for influencers */
  allInfluencersV4: AllInfluencerV4;
  allInstagramAmbassadorPosts?: Maybe<InstagramBrandAmbassadorPosts>;
  allInstagramAmbassadorPostsCount?: Maybe<TotalNumber>;
  /** Returns a list of feed posts for a specified compare account */
  allInstagramCompareFeedPosts?: Maybe<Array<InstagramCompareFeedPost>>;
  allInstagramCompareFeedPostsCount?: Maybe<TotalNumber>;
  /**
   * Returns a list of posts in a specific date(not a date range!)
   *         for the main account and compare accounts
   */
  allInstagramComparePostsInDate?: Maybe<InstagramAnalyticsSummaryPostsInDate>;
  /**
   * Returns Instagram feed posts for a specific analytics account
   *         that were posted in a specified period of time.
   *
   */
  allInstagramFeedPosts?: Maybe<Array<InstagramFeedPost>>;
  allInstagramFeedPostsCount?: Maybe<TotalNumber>;
  allInstagramHashtagFeedPosts?: Maybe<Array<InstagramHashtagFeedPost>>;
  allInstagramHashtagFeedPostsCount?: Maybe<TotalNumber>;
  /**  Searches instagram accounts */
  allInstagramInfluencersV3: InstagramInfluencerSearchResultV3;
  allInstagramInteractionUsers: InstagramInteractionUserAnalyticsDetailsAndCount;
  allInstagramMentionedPosts?: Maybe<Array<InstagramInteractionPost>>;
  allInstagramMentionedPostsCount?: Maybe<TotalNumber>;
  /**
   * Returns a list of Instagram story, reel and feed posts of a specific analytics account
   *         that were posted on a specific date .
   */
  allInstagramPostsInDate?: Maybe<InstagramAnalyticsPostsInDate>;
  /**
   * Returns Instagram reel posts for a specific analytics account
   *         that were posted in a specified period of time.
   *
   */
  allInstagramReelPosts?: Maybe<Array<InstagramReelPostItem>>;
  allInstagramReelPostsCount?: Maybe<TotalNumber>;
  /**  Searches similar instagram accounts */
  allInstagramSimilarInfluencersV3: Array<InstagramInfluencerV3>;
  /**
   * Returns Instagram story posts that were posted in a specified period of time
   *         for a specific analytics account.
   */
  allInstagramStoryPosts?: Maybe<Array<InstagramStoryPost>>;
  allInstagramStoryPostsCount?: Maybe<TotalNumber>;
  allInstagramTaggedPosts?: Maybe<Array<InstagramInteractionTaggedPost>>;
  allInstagramTaggedPostsCount?: Maybe<TotalNumber>;
  allMarketplacePosts?: Maybe<Array<MarketplacePost>>;
  allMarketplacePostsCount?: Maybe<TotalNumber>;
  allMarketplaces?: Maybe<Array<Marketplace>>;
  allMarketplacesCount?: Maybe<TotalNumber>;
  allMarketplacesForReorder?: Maybe<Array<MarketplaceForReorder>>;
  allNeedReviewMarketplacePosts?: Maybe<Array<NeedReviewMarketplacePost>>;
  allPackageCompanies?: Maybe<Array<PackageCompany>>;
  allPackages?: Maybe<Array<ReadPackageAccount>>;
  allPackagesCount?: Maybe<TotalNumber>;
  allPartnerAgenciesCount?: Maybe<TotalNumber>;
  allPartnerAgencyCompany?: Maybe<Array<PartnerAgencyWithNSVerification>>;
  allPartnerAgencyUsers?: Maybe<Array<PartnerAgencyUserWithRole>>;
  allPartners?: Maybe<Array<Partner>>;
  allPayments?: Maybe<Array<Payment>>;
  allPaymentsCount?: Maybe<TotalNumber>;
  allPicUsers?: Maybe<Array<PicUser>>;
  allRegions?: Maybe<Array<Maybe<Region>>>;
  allStaffs?: Maybe<Array<AllStaffDetail>>;
  allStaffsCount?: Maybe<TotalNumber>;
  allTalentAgencies?: Maybe<Array<TalentAgency>>;
  allTalentAgenciesCount?: Maybe<TotalNumber>;
  allTalentAgenciesPartners?: Maybe<Array<TalentAgencyOrPartner>>;
  allTalentAgencyCompany?: Maybe<Array<TalentAgencyWithNSVerification>>;
  allTalentAgencyUsers?: Maybe<Array<TalentAgencyUserWithRole>>;
  allThreadsInfluencers?: Maybe<Array<ThreadsInfluencer>>;
  allThreadsInfluencersCount?: Maybe<TotalNumber>;
  allTiktokAdReportSummary?: Maybe<AllTiktokAdReportSummary>;
  allTiktokAdsReport?: Maybe<Array<TiktokAdDetailReport>>;
  allTiktokInfluencers?: Maybe<Array<TiktokInfluencer>>;
  allTiktokInfluencersCount?: Maybe<TotalNumber>;
  /**  Searches tiktok accounts */
  allTiktokInfluencersV2: TiktokInfluencerSearchResultV2;
  allTiktokProductCategory: Array<TikTokProductCategory>;
  allTiktokSimilarInfluencers?: Maybe<Array<TiktokInfluencer>>;
  allTiktokSpecialCampaigns?: Maybe<Array<TikTokSpecialCampaign>>;
  allTiktokSpecialCampaignsCount?: Maybe<TotalNumber>;
  allTwitterInfluencers?: Maybe<Array<TwitterInfluencer>>;
  allTwitterInfluencersCount?: Maybe<TotalNumber>;
  allTwitterSimilarInfluencers?: Maybe<Array<TwitterInfluencer>>;
  allUsers?: Maybe<Array<AllUserDetail>>;
  allUsersCount?: Maybe<TotalNumber>;
  allXHSInfluencers: XhsInfluencerSearchResult;
  allYoutubeCmsConfirmedAssets?: Maybe<Array<YoutubeCmsConfirmedAsset>>;
  allYoutubeCmsConfirmedAssetsCount?: Maybe<TotalNumber>;
  allYoutubeCmsConfirmedChannels?: Maybe<Array<YoutubeCmsConfirmedChannel>>;
  allYoutubeCmsConfirmedChannelsCount?: Maybe<TotalNumber>;
  allYoutubeCmsEstimateAssets?: Maybe<Array<YoutubeCmsEstimateAsset>>;
  allYoutubeCmsEstimateAssetsCount?: Maybe<TotalNumber>;
  allYoutubeCmsEstimateChannels?: Maybe<Array<YoutubeCmsEstimateChannel>>;
  allYoutubeCmsEstimateChannelsCount?: Maybe<TotalNumber>;
  /**  Searches youtube accounts */
  allYoutubeInfluencersV3: YoutubeInfluencerSearchResultV3;
  allYoutubeSimilarInfluencers?: Maybe<Array<YouTubeInfluencer>>;
  /**
   *  Returns a URL that FE uses to communicate with social network APIs.
   *  May be used by anyone with no authentication.
   */
  analyticsSocialAuthRedirectUrl?: Maybe<AnalyticsSocialAuthRedirectUrlPayload>;
  analyticsSubscriptionPlan?: Maybe<AnalyticsSubscriptionPlan>;
  analyticsSubscriptionPlanByAdvId?: Maybe<AnalyticsSubscriptionPlan>;
  anyXCheckConnectedSocialAccounts?: Maybe<AnyXCheckConnectedSocialAccountsPayload>;
  anyXCheckConnectedSocialAccountsForLinkBio?: Maybe<AnyXCheckConnectedSocialAccountsForLinkBioPayload>;
  autoManagedCommissionFixedDates?: Maybe<Array<Scalars['Date']['output']>>;
  autoManagedCommissionInfluencers?: Maybe<Array<AutoManagedCommissionInfluencer>>;
  autoManagedCommissionInfluencersCounts?: Maybe<TotalNumber>;
  autoManagedJoinedInfluencers?: Maybe<Array<AutoManagedJoinedInfluencer>>;
  autoManagedJoinedInfluencersCount?: Maybe<TotalNumber>;
  autoManagedJoinedMethods?: Maybe<AutoManagedJoinedMethodsPayload>;
  autoManagedJoinedMethodsV2?: Maybe<AutoManagedJoinedMethodsPayloadV2>;
  autoManagedReportByDate?: Maybe<Array<AutoManagedReportByDate>>;
  autoManagedReportByDateCount?: Maybe<TotalNumber>;
  autoManagedReportByInfluencer?: Maybe<Array<AutoManagedReportByInfluencer>>;
  autoManagedReportByInfluencerCount?: Maybe<TotalNumber>;
  autoManagedReportCountForInfluencer?: Maybe<TotalNumber>;
  autoManagedReportForInfluencer?: Maybe<Array<AutoManagedReportForInfluencer>>;
  autoManagedReportSummary?: Maybe<AutoManagedReportSummary>;
  autoManagedReportSummaryForInfluencer?: Maybe<AutoManagedReportSummaryForInfluencer>;
  billingInformation?: Maybe<BillingInformation>;
  brandAccounts?: Maybe<InfluencerBrandAccounts>;
  campaignCheckoutSessionLink?: Maybe<StripeDeepLink>;
  campaignHashtagCloud?: Maybe<Array<HashtagType>>;
  campaignsChartForAllTime: Array<CampaignChartReportForAllTime>;
  checkBioLinkNameAvailability?: Maybe<CheckBioLinkNameAvailabilityPayload>;
  checkoutSessionLink?: Maybe<StripeDeepLink>;
  cmsInvoiceDownloadUrl?: Maybe<YoutubeCmsInvoiceDownloadUrl>;
  connectableFacebookPages?: Maybe<ConnectableFacebookPagesPayload>;
  connectableFacebookPagesAndInstagramAccounts?: Maybe<ConnectableFacebookPagesAndInstagramAccountsPayload>;
  connectableInstagramAccounts?: Maybe<ConnectableInstagramAccountsPayload>;
  /**
   *  `countUnreadNotifications` query gets the count of notifications that are not read.
   *  The user is known thorugh the `Authorization` header.
   *  Read is defined as the occurence of the notification being rendered by front-end.
   */
  countUnreadNotifications?: Maybe<UnreadNotificationCountPayload>;
  /**
   *  `countUnreadNotificationsByApp` query gets the count of notifications that are not read of specific application.
   *  The user is known thorugh the `Authorization` header.
   *  Read is defined as the occurence of the notification being rendered by front-end.
   */
  countUnreadNotificationsByApp?: Maybe<UnreadNotificationCountByAppPayload>;
  creatorAddressInformation?: Maybe<CreatorAddressPayload>;
  customerPortalLink?: Maybe<StripeDeepLink>;
  douyinAccountInterest?: Maybe<DouyinAccountInterest>;
  douyinProfileAudience?: Maybe<DouyinAudienceSection>;
  engagement?: Maybe<EngagementDetail>;
  engagementCampaignPostReportAudienceBreakdown?: Maybe<EngagementCampaignPostReportAudienceBreakdown>;
  engagementCampaignPostReportForInfluencer?: Maybe<EngagementCampaignPostReportForInfluencer>;
  engagementCampaignReport?: Maybe<EngagementCampaignReport>;
  engagementCampaignReportGrid?: Maybe<CampaignGridReport>;
  engagementCampaignReportSummary?: Maybe<NewCampaignReportSummary>;
  engagementCampaignTrafficSource?: Maybe<Array<EngagementPostTrafficSource>>;
  /** Returns history list for a specific engagement draft post */
  engagementDraftPostHistory?: Maybe<EngagementDraftPostHistory>;
  /** Returns history list for a specific engagement draft post for influencer */
  engagementDraftPostHistoryForInfluencer?: Maybe<EngagementDraftPostHistoryForInfluencer>;
  engagementForInfluencer?: Maybe<EngagementDetailForInfluencer>;
  engagementHashtagCloud?: Maybe<Array<HashtagType>>;
  engagementPostComments?: Maybe<Array<EngagementPostComment>>;
  engagementPostDetail?: Maybe<EngagementPostDetail>;
  /**
   *
   *                                                            To fetch Engagement post details to show on Influencer platform
   *
   */
  engagementPostDetailForInfluencer?: Maybe<EngagementPostDetailForInfluencer>;
  engagementPostForInfluencerV2?: Maybe<EngagementPostDetailForInfluencerV2>;
  engagementPostInfluencers?: Maybe<Array<EngagementPostInfluencerWithSocialAccounts>>;
  engagementPostMetricsForInsightCheck?: Maybe<EngagementPostMetricsDataForInsightCheck>;
  engagementPostSocialAccountsForInfluencer?: Maybe<Array<EngagementPostSocialAccount>>;
  engagementPostTrafficSource?: Maybe<Array<EngagementPostTrafficSource>>;
  engagementPostV2?: Maybe<EngagementPostEditV2>;
  engagementProposal?: Maybe<EngagementProposal>;
  engagementTracking?: Maybe<EngagementTracking>;
  engagementXhsPostMetricsForInsight?: Maybe<EngagementXhsPostMetricsDataForInsight>;
  existingProfileTags?: Maybe<ExistingProfileTags>;
  /**
   * Returns Instagram feed posts for a specified external analytics account
   *             that were posted in a specified period of time.
   *
   */
  externalAllInstagramFeedPosts?: Maybe<Array<ExternalInstagramFeedPost>>;
  externalAllInstagramFeedPostsCount?: Maybe<TotalNumber>;
  /**
   * Returns a list of Instagram story, reel and feed posts that were posted on a specified date
   *             made by a given external analytics account.
   */
  externalAllInstagramPostsInDate?: Maybe<ExternalInstagramAnalyticsPostsInDate>;
  /**
   * Returns Instagram reel posts for a specified external analytics account
   *             that were posted in a specified period of time.
   *
   */
  externalAllInstagramReelPosts?: Maybe<Array<ExternalInstagramReelPost>>;
  externalAllInstagramReelPostsCount?: Maybe<TotalNumber>;
  /**
   * Returns Instagram story posts that were posted in a specified period of time
   *             for a specified external analytics account.
   */
  externalAllInstagramStoryPosts?: Maybe<Array<ExternalInstagramStoryPost>>;
  externalAllInstagramStoryPostsCount?: Maybe<TotalNumber>;
  /**
   *  Returns basic FB account details
   *  May be used by external users only
   */
  externalAnalyticsFacebookGetAccountDetails: ExternalAnalyticsFacebookGetAccountDetailsOutput;
  /**
   *  Checks if reconnection is needed
   *  May be used by external users only
   */
  externalAnalyticsFacebookGetTokenWarnings: ExternalAnalyticsFacebookGetTokenWarningsOutput;
  /**
   *  Returns basic IG account details
   *  May be used by external users only
   */
  externalAnalyticsInstagramGetAccountDetails: ExternalAnalyticsInstagramGetAccountDetailsOutput;
  /**
   *  Checks if reconnection is needed
   *  May be used by external users only
   */
  externalAnalyticsInstagramGetTokenWarnings: ExternalAnalyticsInstagramGetTokenWarningsOutput;
  /**
   *  Returns a URL that FE uses to communicate with social network APIs.
   *  May be used by external users only.
   */
  externalAnalyticsSocialAuthRedirectUrl?: Maybe<ExternalAnalyticsSocialAuthRedirectUrlPayload>;
  /**
   *  Returns the audience of the facebook page account by `facebookPageAnalyticsAccountId` within the specified period
   *  May be used by external users
   */
  externalFacebookPageAnalyticsAudience: FacebookPageAnalyticsAudiencePayload;
  /**  Returns the overview data of the facebook page account by `facebookPageAnalyticsAccountId` within the specified period */
  externalFacebookPageAnalyticsOverview: FacebookPageAnalyticsOverviewPayload;
  /**
   *  Returns a post specified by `facebookPageAnalyticsAccountId`
   *  May be used by external users only
   */
  externalFacebookPageAnalyticsPost: FacebookPageAnalyticsPostPayload;
  /**
   *  Returns a list of the posts made by the account `facebookPageAnalyticsAccountId`
   *  May be used by external users only
   */
  externalFacebookPageAnalyticsPosts: FacebookPageAnalyticsPostsPayloads;
  /**
   *  Returns the statistics of the posts by `facebookPageAnalyticsAccountId` within the specified period
   *  May be used by external users only
   */
  externalFacebookPageAnalyticsPostsAnalytics: FacebookPageAnalyticsPostsAnalyticsPayload;
  /**  Returns a list of the posts made by the account `facebookPageAnalyticsAccountId` by date */
  externalFacebookPageAnalyticsPostsByDate: FacebookPageAnalyticsPostsByDatePayload;
  /**
   *  Returns the total count of posts made by the account `facebookPageAnalyticsAccountId`
   *  May be used by external users
   */
  externalFacebookPageAnalyticsPostsCount: TotalNumber;
  /**
   * Returns details for a specified Instagram feed post. For example, the content,
   *             comments, and etc.
   *
   */
  externalInstagramAnalyticsPostDetails?: Maybe<ExternalInstagramAnalyticsPostDetails>;
  /** Returns details for a specific Instagram reel post. For external users only. */
  externalInstagramAnalyticsReelDetails?: Maybe<ExternalInstagramAnalyticsReelDetails>;
  /** Returns details of a specified Instagram story post made by an external analytics account. */
  externalInstagramAnalyticsStoryDetails?: Maybe<ExternalInstagramAnalyticsStoryDetails>;
  /**
   * Returns follower demographics analytics information for a specified external analytics account
   *             in a specified period of time. For example, age-gender distribution, followers countries, and etc.
   */
  externalInstagramDashboardFollowerAnalytics?: Maybe<ExternalInstagramDashboardFollowerAnalytics>;
  /**
   * Returns the main analytics information for a specified external analytics account
   *             in a specified period of time. For example, the number of followers, posting frequency/activity,
   *             comments growth, and etc.
   */
  externalInstagramDashboardOverview?: Maybe<ExternalInstagramDashboardOverview>;
  /**
   * Returns Instagram posts analytics data for a specified external analytics account
   *             in a specified period of time. For example, posting habits, last feed and story posts, and etc.
   */
  externalInstagramDashboardPostAnalytics?: Maybe<ExternalInstagramDashboardPostAnalytics>;
  /**
   *  Returns the audience of the youtube account by `youtubeAnalyticsAccountId` within the specified period
   *  May be used by external
   */
  externalYoutubeAnalyticsAudience: ExternalYoutubeAnalyticsAudiencePayload;
  /**
   *  Returns the overview data of the youtube account by `youtubeAnalyticsAccountId` within the specified period
   *  May be used by external
   */
  externalYoutubeAnalyticsOverview: ExternalYoutubeAnalyticsOverviewPayload;
  /**
   *  Returns a post specified by `youtubeAnalyticsPostId`
   *  May be used by external
   *  Views can be larger than 32-bit, please keep note
   */
  externalYoutubeAnalyticsPost: ExternalYoutubePostPayload;
  /**
   *  Returns a list of the posts made by the account `youtubeAnalyticsAccountId`
   *  May be used by external
   *  Views can be larger than 32-bit, please keep note
   */
  externalYoutubeAnalyticsPosts: ExternalYoutubeAnalyticsPostListPayload;
  /**  Returns posts for external user for account `socialAccountId` */
  externalYoutubeAnalyticsPostsByDate: ExternalYoutubeAnalyticsPostListByDatePayload;
  /**
   *  Returns the total count of posts made by the account `youtubeAnalyticsAccountId`
   *  May be used by external
   */
  externalYoutubeAnalyticsPostsCount: TotalNumber;
  /**
   *  Returns the statistics of the posts by `youtubeAnalyticsAccountId` within the specified period
   *  May be used by external
   */
  externalYoutubeAnalyticsPostsStatistics: ExternalYoutubeAnalyticsPostsStatisticsPayload;
  /**
   *  Returns the related posts with the same tag of the same analytics account
   *  May be used by external
   *  `youtubeAnalyticsPostId` is the id of the current shown post. This will be excluded from the result
   */
  externalYoutubeAnalyticsRelatedPosts: ExternalYoutubeAnalyticsRelatedPostsPayload;
  /**
   *  Returns a paginated list of the tags sorted by the specified field.
   *  May be used by external
   *  views can be larger than 32-bit, please keep note.
   */
  externalYoutubeAnalyticsTagsTopRanking: ExternalYoutubeAnalyticsTagRankingPayloads;
  /**
   *  Returns the total count of the tags specified by the inputs
   *  May be used by external
   *  views can be larger than 32-bit, please keep note.
   */
  externalYoutubeAnalyticsTagsTopRankingCount: TotalNumber;
  /**
   *  Returns the audience of the facebook page account by `facebookPageAnalyticsAccountId` within the specified period
   *  May be used by admin, staff, agency, and advertisers
   */
  facebookPageAnalyticsAudience: FacebookPageAnalyticsAudiencePayload;
  /**  Returns the overview data of the facebook page account by `facebookPageAnalyticsAccountId` within the specified period */
  facebookPageAnalyticsOverview: FacebookPageAnalyticsOverviewPayload;
  /**
   *  Returns a post specified by `facebookPageAnalyticsAccountId`
   *  May be used by admin, staff, agency, and advertisers
   */
  facebookPageAnalyticsPost: FacebookPageAnalyticsPostWithPageDetailsPayload;
  /**
   *  Returns a list of the posts made by the account `facebookPageAnalyticsAccountId`
   *  May be used by admin, staff, agency, and advertisers
   */
  facebookPageAnalyticsPosts: FacebookPageAnalyticsPostsPayloads;
  /**
   *  Returns the statistics of the posts by `facebookPageAnalyticsAccountId` within the specified period
   *  May be used by admin, staff, agency, and advertisers
   */
  facebookPageAnalyticsPostsAnalytics: FacebookPageAnalyticsPostsAnalyticsPayload;
  /**  Returns a list of the posts made by the account `facebookPageAnalyticsAccountId` by date */
  facebookPageAnalyticsPostsByDate: FacebookPageAnalyticsPostsByDatePayload;
  /**
   *  Returns the total count of posts made by the account `facebookPageAnalyticsAccountId`
   *  May be used by admin, staff, agency, and advertisers
   */
  facebookPageAnalyticsPostsCount: TotalNumber;
  findJoinedCampaigns?: Maybe<Array<JoinedCampaign>>;
  generateAgencyLogoSignedUrl: FileUploadUrls;
  generateImportFansUploadURL?: Maybe<GenerateImportFansUploadURLPayload>;
  generateMaterialSignedUrl: FileUploadUrls;
  generatePostFileSignedUrl?: Maybe<SignedUrlData>;
  generatePostFileSignedUrls: FileUploadUrls;
  generateThumbnailSignedUrl: FileUploadUrls;
  generateUploadReportSignedUrl: FileUploadUrls;
  getAccountInformation?: Maybe<GetAccountInformationPayload>;
  getAllCampaignsCountForSearchJob?: Maybe<GetAllCampaignsCountForSearchJobPayload>;
  getAllCampaignsForSearchJob?: Maybe<GetAllCampaignsForSearchJobPayload>;
  getAnyXAccountsByKeyword?: Maybe<GetAnyXAccountsByKeywordPayload>;
  getAutoManagedCampaignById?: Maybe<AutoManagedCampaignPayload>;
  /**  query to get AM campaign detail from search jobs. Id input is remote campaign id */
  getAutoManagedCampaignForInfluencerSearchJob?: Maybe<AutoManagedCampaignForInfluencerSearchJobPayload>;
  /**  query to get AM campaign detail from your jobs. Id input is local campaign id */
  getAutoManagedCampaignForInfluencerYourJob?: Maybe<AutoManagedCampaignForInfluencerYourJobPayload>;
  getAutoManagedCampaigns?: Maybe<GetAutoManagedCampaignsPayload>;
  getAutoManagedCampaignsCount?: Maybe<GetAutoManagedCampaignsCountPayload>;
  getAvailableInfluencersForStaff?: Maybe<AvailableInfluencersForStaffPayload>;
  getChatEvents?: Maybe<GetChatEventsPayload>;
  getCouponsList?: Maybe<Array<Scalars['String']['output']>>;
  getCreatorStaffs?: Maybe<CreatorStaffsPayload>;
  getCreatorStaffsCount?: Maybe<CreatorStaffsCountPayload>;
  getCreatorTags?: Maybe<GetCreatorTagsPayload>;
  getCreatorTagsById?: Maybe<GetCreatorTagsByIdPayload>;
  getCurrentUserCurrency: CurrentUserCurrency;
  getCustomerTags?: Maybe<GetCustomerTagsPayload>;
  getEmailList?: Maybe<GetEmailListPayload>;
  getEmailListCount?: Maybe<GetEmailListCountPayload>;
  getEmailOverview?: Maybe<GetEmailOverviewPayload>;
  getEmailRecipients?: Maybe<GetEmailRecipientsPayload>;
  getEmailRecipientsCount?: Maybe<GetEmailRecipientsCountPayload>;
  getEmailTemplate?: Maybe<GetEmailTemplatePayload>;
  getFan?: Maybe<GetFanPayload>;
  getFanActivities?: Maybe<GetFanActivitiesPayload>;
  getFanCountries?: Maybe<CountriesPayload>;
  getFanFormResponse?: Maybe<GetFanFormResponsePayload>;
  getFanHeader?: Maybe<GetFanHeaderPayload>;
  getFanOrders?: Maybe<GetFanOrdersPayload>;
  getFanParticipationFormList?: Maybe<GetFanParticipationFormListPayload>;
  getFanProvinces?: Maybe<ProvincesPayload>;
  getFans?: Maybe<GetFansPayload>;
  getFansCount?: Maybe<GetFansCountPayload>;
  getForm?: Maybe<GetFormInputPayload>;
  getFormReportSummary?: Maybe<FormReportSummaryPayload>;
  getFormRespondentsCount?: Maybe<FormRespondentsCountPayload>;
  getFormRespondentsList?: Maybe<FormRespondentsListPayload>;
  getFormResponse?: Maybe<GetFormResponsePayload>;
  getFormResponseByPage?: Maybe<GetFormResponsePayload>;
  getFormTemplate?: Maybe<GetFormTemplatePayload>;
  getFormsCount?: Maybe<GetFormsCountPayload>;
  getFormsList?: Maybe<GetFormsListPayload>;
  getGeneratedMarketplaceCampaignDetail?: Maybe<GetGeneratedMarketplaceCampaignDetailPayload>;
  getGeneratedMarketplacePostCaption?: Maybe<GetGeneratedMarketplacePostCaptionPayload>;
  getGeneratedTikTokSpecialPostCaption?: Maybe<GetGeneratedTikTokSpecialPostCaptionPayload>;
  getInfluencerBio?: Maybe<GetInfluencerBioPayload>;
  getInfluencerBioAnalytics?: Maybe<GetInfluencerBioAnalyticsPayload>;
  getInfluencerBioV2?: Maybe<GetInfluencerBioPayloadV2>;
  getInfluencerChatServices?: Maybe<GetInfluencerChatServicesPayload>;
  getInfluencerRateCard?: Maybe<InfluencerRateCardPayload>;
  getInfluencerRateCardForInfluencer?: Maybe<InfluencerRateCardPayload>;
  getLineBroadcastEstimateRecipients?: Maybe<GetLineBroadcastEstimateRecipientsPayload>;
  getLineBroadcastMessage?: Maybe<GetLineBroadcastMessagePayload>;
  getLineBroadcastSummary?: Maybe<GetLineBroadcastSummaryPayload>;
  getLineBroadcasts?: Maybe<GetLineBroadcastsPayload>;
  getLineBroadcastsCount?: Maybe<GetLineBroadcastsCountPayload>;
  getLineChannels?: Maybe<GetLineChannelPayload>;
  getLineChat?: Maybe<GetLineChatPayload>;
  getLineChatCount?: Maybe<GetLineChatCountPayload>;
  getLineChats?: Maybe<GetLineChatsPayload>;
  getLineLoginDetail?: Maybe<GetLineLoginDetailPayload>;
  getLinePublicKey?: Maybe<GetLinePublicKeyPayload>;
  getLinkBioFacebookAccountPosts?: Maybe<GetLinkBioFacebookAccountPostsPayload>;
  getLinkBioFacebookPagePosts?: Maybe<GetLinkBioFacebookPagePostsPayload>;
  getLinkBioThemeColor?: Maybe<GetLinkBioThemeColorPayload>;
  getMarketplaceCampaignForInfluencer?: Maybe<MarketplaceDetailForInfluencer>;
  getMessageTemplates?: Maybe<GetMessageTemplatesPayload>;
  /**
   *  `getNotifications` query gets the notificataions of an  influencer user.
   *  The user is known through the `Authorization` header.
   */
  getNotifications?: Maybe<NotificationsPayload>;
  /**
   *  `getNotificationsByApp` query gets the notificataions a specific application type of an  influencer user.
   *  The user is known through the `Authorization` header.
   */
  getNotificationsByApp?: Maybe<NotificationsPayload>;
  getPersonalUserDetail?: Maybe<PersonalUserDetailPayload>;
  getPublicInfluencerBio?: Maybe<GetPublicInfluencerBioPayload>;
  getReadTutorials?: Maybe<ReadTutorials>;
  getShopifyAccount?: Maybe<GetShopifyAccountPayload>;
  /**  Returns required permissions for each social network */
  getSocialAccountPermissions: Array<Scalars['String']['output']>;
  getTagsAndFansRecipients?: Maybe<GetTagsAndFansRecipientsPayload>;
  getUserDefaultPermissions?: Maybe<UserDefaultPermissions>;
  getUserDetail?: Maybe<UserDetailPayload>;
  getUserPermissions?: Maybe<UserDefaultPermissions>;
  getYoutubeMCNRedirectUrlForAdmin?: Maybe<YoutubeMCNAdminRedirectUrlPayload>;
  hubspotCompanyList?: Maybe<Array<HubspotCompanyData>>;
  hubspotCompanyName?: Maybe<HubspotCompanyData>;
  hubspotDealName?: Maybe<Array<HubspotDealDataForSearch>>;
  hubspotDealsList?: Maybe<Array<HubspotDealData>>;
  /**
   * Returns Instagram feed posts for a specified influencer analytics account
   *             that were posted in a specified period of time.
   *
   */
  influencerAllInstagramFeedPosts?: Maybe<Array<InstagramFeedPost>>;
  influencerAllInstagramFeedPostsCount?: Maybe<TotalNumber>;
  influencerAllInstagramInteractionUsers: InstagramInteractionUserAnalyticsDetailsAndCount;
  influencerAllInstagramMentionedPosts?: Maybe<Array<InstagramInteractionPost>>;
  influencerAllInstagramMentionedPostsCount?: Maybe<TotalNumber>;
  /**
   * Returns a list of Instagram story, reel and feed posts that were posted on a specified date
   *             made by a given influencer analytics account.
   */
  influencerAllInstagramPostsInDate?: Maybe<InstagramAnalyticsPostsInDate>;
  /**
   * Returns Instagram reel posts for a specified influencer analytics account
   *             that were posted in a specified period of time.
   *
   */
  influencerAllInstagramReelPosts?: Maybe<Array<InstagramReelPostItem>>;
  influencerAllInstagramReelPostsCount?: Maybe<TotalNumber>;
  /**
   * Returns Instagram story posts that were posted in a specified period of time
   *             for a specified influencer analytics account.
   */
  influencerAllInstagramStoryPosts?: Maybe<Array<InstagramStoryPost>>;
  influencerAllInstagramStoryPostsCount?: Maybe<TotalNumber>;
  influencerAllInstagramTaggedPosts?: Maybe<Array<InstagramInteractionTaggedPost>>;
  influencerAllInstagramTaggedPostsCount?: Maybe<TotalNumber>;
  /**  Checks if reconnection is needed + provides data for successfull reconnection for influencers */
  influencerAnalyticsFacebookPageAccessTokenWarning: InfluencerFacebookPageAnalyticsAccessTokenWarningPayload;
  /**  Checks if reconnection is needed + provides data for successfull reconnection for influencers */
  influencerAnalyticsInstagramAccessTokenWarning: InfluencerInstagramAnalyticsAccessTokenWarningPayload;
  /**  Checks if reconnection is needed + provides data for successfull reconnection for multiple influencer IG accounts */
  influencerAnalyticsMultipleInstagramAccessTokenWarning: InfluencerAnalyticsMultipleInstagramAccessTokenWarningPayload;
  /**  Checks if reconnection is needed for twitter accounts */
  influencerAnalyticsTwitterAccessTokenWarning: InfluencerTwitterAnalyticsAccessTokenWarningPayload;
  /**
   *  Returns true if twitter account available to update
   *  May be used by admin, staff, agency, advertisers, and influencers
   */
  influencerAnalyticsTwitterRefreshAvailability: InfluencerAnalyticsTwitterRefreshAvailabilityPayload;
  /** List of joined campaign for influencers */
  influencerCampaignList?: Maybe<Array<InfluencerCampaignDetail>>;
  /** List of joined MKP and Auto-Managed campaign for influencers independently by promotion method id */
  influencerCampaignListByPromotionMethod?: Maybe<Array<InfluencerCampaignDetailByPromotion>>;
  /** campaigns summary for influencer */
  influencerCampaignSummary: InfluencerCampaignSummary;
  influencerFacebookPageAnalyticsAudience: FacebookPageAnalyticsAudiencePayload;
  /**
   *  Returns the overview data of the influencer facebook page account by `socialAccountId` and `influencerId`
   *  within the specified period
   */
  influencerFacebookPageAnalyticsOverview: FacebookPageAnalyticsOverviewPayload;
  /**  Returns a post specified by `socialAccountId`, `influencerId` and `postId` */
  influencerFacebookPageAnalyticsPost: FacebookPageAnalyticsPostWithPageDetailsPayload;
  /**  Returns a list of the posts made by the account specified by `socialAccountId`, `influencerId` and `postId` */
  influencerFacebookPageAnalyticsPosts: FacebookPageAnalyticsPostsPayloads;
  /**
   *  Returns the statistics of the posts by `influencerFacebookPageAnalyticsPostsAnalytics` within the specified period
   *  May be used by admin, staff, agency, and advertisers
   */
  influencerFacebookPageAnalyticsPostsAnalytics: FacebookPageAnalyticsPostsAnalyticsPayload;
  /**  Returns a list of the posts made by the account specified by `socialAccountId`, `influencerId` and by date */
  influencerFacebookPageAnalyticsPostsByDate: FacebookPageAnalyticsPostsByDatePayload;
  /**  Returns the total count of posts made by the account specified by `socialAccountId`, `influencerId` and `postId` */
  influencerFacebookPageAnalyticsPostsCount: TotalNumber;
  /** checking social account has estimate api */
  influencerHasEstimateApi: InfluencerHasEstimateApi;
  /**
   * Returns details for a specified Instagram feed post. For example, the content,
   *             comments, and etc.
   */
  influencerInstagramAnalyticsPostDetails?: Maybe<InstagramAnalyticsPostDetails>;
  /** Returns details for a specific Instagram reel post. For influencers only. */
  influencerInstagramAnalyticsReelDetails?: Maybe<InstagramAnalyticsReelDetails>;
  /** Returns details of a specified Instagram story post made by an influencer analytics account. */
  influencerInstagramAnalyticsStoryDetails?: Maybe<InstagramAnalyticsStoryDetails>;
  /**
   * Returns follower demographics analytics information for a specified influencer analytics account
   *             in a specified period of time. For example, age-gender distribution, followers countries, and etc.
   */
  influencerInstagramDashboardFollowerAnalytics?: Maybe<InstagramDashboardFollowerAnalytics>;
  /**
   * Returns the main analytics information for a specified influencer analytics account
   *             in a specified period of time. For example, the number of followers, posting frequency/activity,
   *             comments growth, and etc.
   */
  influencerInstagramDashboardOverview?: Maybe<InstagramDashboardOverview>;
  /**
   * Returns Instagram posts analytics data for a specified influencer analytics account
   *             in a specified period of time. For example, posting habits, last feed and story posts, and etc.
   */
  influencerInstagramDashboardPostAnalytics?: Maybe<InstagramDashboardPostAnalytics>;
  influencerInstagramInteractionMentionedPostDetails?: Maybe<InstagramInteractionPostDetails>;
  influencerInstagramInteractionOverview?: Maybe<InstagramInteractionOverview>;
  influencerInstagramInteractionPosts?: Maybe<InstagramInteractionPosts>;
  influencerInstagramInteractionTaggedPostDetails?: Maybe<InstagramInteractionPostDetails>;
  influencerInterests?: Maybe<Array<InfluencerInterest>>;
  influencerMultipleSocialAccountsCount?: Maybe<TotalNumber>;
  influencerPaymentInformation?: Maybe<InfluencerPaymentInformation>;
  influencerPaymentRequests: InfluencerPaymentRequests;
  influencerPostDetail?: Maybe<InfluencerPostDetail>;
  influencerPostDetailForTalentAgency?: Maybe<InfluencerPostDetail>;
  influencerProfileAudience?: Maybe<InfluencerAudienceSection>;
  influencerProfileDetailForEngagementProposal?: Maybe<InfluencerProfileDetailForEngagementProposal>;
  influencerProfileDetailV2?: Maybe<InfluencerNewProfileDetail>;
  influencerProfileFollowerGrowth?: Maybe<InfluencerFollowerGrowthSection>;
  influencerProfileForEngagementProposal?: Maybe<InfluencerProfileForEngagementProposal>;
  influencerProfilePosts?: Maybe<InfluencerPostSection>;
  influencerProfileV2?: Maybe<InfluencerNewProfileHeaderV2>;
  /**  query to return list of influencer connected promotion methods */
  influencerPromotionMethods?: Maybe<InfluencerPromotionMethodsPayload>;
  /**
   *  Returns the overview data of the twitter account with specified `socialAccountId` and specified period
   *  May be used by admin, staff, agency, advertisers, influencers
   */
  influencerTwitterAnalyticsOverview: TwitterAnalyticsOverviewPayload;
  /**
   *  Returns a post specified by `socialAccountId` and `twitterAnalyticsPostId`
   *  May be used by admin, staff, agency, and advertisers, influencers
   */
  influencerTwitterAnalyticsPost: TwitterAnalyticsPostByIdPayload;
  /**
   *  Returns a list of the posts made by the account with specified `socialAccountId`
   *  May be used by admin, staff, agency, advertisers, influencers
   */
  influencerTwitterAnalyticsPosts: TwitterAnalyticsPostsPayload;
  /**  Returns the posts that are made on the date */
  influencerTwitterAnalyticsPostsByDate: TwitterAnalyticsPostsByDatePayload;
  /**  Returns the total count of posts made for influencer account `socialAccountId` */
  influencerTwitterAnalyticsPostsCount: TotalNumber;
  /**  Posts section for twitter dashboard for influencer account `socialAccountId` */
  influencerTwitterAnalyticsPostsStatistic?: Maybe<TwitterAnalyticsPostsStatisticPayload>;
  influencerUpdateDetailV2?: Maybe<InfluencerUpdateDetailV2>;
  influencerWhitelist?: Maybe<InfluencerWhitelist>;
  /**  Returns the audience of the youtube account by `socialAccountId` within the specified period */
  influencerYoutubeAnalyticsAudience: YoutubeAnalyticsAudiencePayload;
  /**
   *  Returns basic details of a youtube channel by a given 'socialAccountId'.
   *  May be used for influencers only
   */
  influencerYoutubeAnalyticsChannelDetails: InfluencerYoutubeAnalyticsChannelDetailsPayload;
  /**  Returns a list of Youtube accounts compared with account `socialAccountId`. For influencers only. */
  influencerYoutubeAnalyticsCompareAccountCards: InfluencerYoutubeAnalyticsCompareAccountCards;
  /**
   *  Returns the related posts with the same tag of the same compare account. For influencers only
   *  `postId` is the id of the current shown post. This will be excluded from the result
   */
  influencerYoutubeAnalyticsCompareRelatedPosts: YoutubeAnalyticsCompareRelatedPosts;
  /**  Returns the overview data of the youtube account by `socialAccountId` within the specified period for influencers */
  influencerYoutubeAnalyticsOverview: YoutubeAnalyticsOverviewPayload;
  /**
   *  Returns a post specified by `socialAccountId` for influencers
   *  Views can be larger than 32-bit, please keep note
   */
  influencerYoutubeAnalyticsPost: YoutubeAnalyticsPostWithChannelDetailsPayload;
  /**  Returns a list of the posts made by the account `socialAccountId` for influencers */
  influencerYoutubeAnalyticsPosts: YoutubeAnalyticsPostsPayloads;
  /**  Returns the posts that are made on the date for influencers */
  influencerYoutubeAnalyticsPostsByDate: YoutubeAnalyticsPostPayloads;
  /**  Returns the total count of posts made by the account `socialAccountId` for influencers */
  influencerYoutubeAnalyticsPostsCount: TotalNumber;
  /**  Returns the statistics of the posts by `socialAccountId` within the specified period for influencers */
  influencerYoutubeAnalyticsPostsStatistics: YoutubeAnalyticsPostsStatisticsPayload;
  /**
   *  Returns the related posts with the same tag of the same analytics account
   *  `youtubeAnalyticsPostId` is the id of the current shown post. This will be excluded from the result. For influencers.
   */
  influencerYoutubeAnalyticsRelatedPosts: YoutubeAnalyticsRelatedPostsPayload;
  /**  Returns a list of the posts made by the account `socialAccountId` for influencers */
  influencerYoutubeAnalyticsShorts: YoutubeAnalyticsShortsPayloads;
  /**  Returns the total count of shorts made by the account `socialAccountId` for influencers */
  influencerYoutubeAnalyticsShortsCount: TotalNumber;
  influencerYoutubeAnalyticsSponsoredPosts: InfluencerAnalyticsSponsoredPostPayload;
  influencerYoutubeAnalyticsSponsoredPostsCount: InfluencerAnalyticsSponsoredPostCountPayload;
  /**
   *  Returns a paginated list of the tags sorted by the specified field.
   *  For influencers.
   *  views can be larger than 32-bit, please keep note.
   */
  influencerYoutubeAnalyticsTagsTopRanking: YoutubeAnalyticsTagRankingPayloads;
  /**
   *  Returns the total count of the tags specified by the inputs
   *  For influencers.
   *  views can be larger than 32-bit, please keep note.
   */
  influencerYoutubeAnalyticsTagsTopRankingCount: TotalNumber;
  /**  Returns all Youtube compare posts in date. For influencers only. */
  influencerYoutubeCompareAllPostsInDate?: Maybe<InfluencerYoutubeCompareAllPostsInDate>;
  /**  Returns the Youtube overview data for the main analytics account and compare accounts. For influencers only. */
  influencerYoutubeCompareOverview: InfluencerYoutubeCompareOverview;
  /**  Returns a compare youtube post by id. For influencers only. */
  influencerYoutubeComparePostById?: Maybe<YoutubeComparePost>;
  /**  Returns Youtube compare posts with pagination. For influencers only. */
  influencerYoutubeComparePostList: YoutubeComparePostList;
  /**  Returns comparable posts for Youtube. For influencers only. */
  influencerYoutubeComparePosts: InfluencerYoutubeComparePosts;
  /**  Returns the total count of compare posts. For influencers only. */
  influencerYoutubeComparePostsCount: TotalNumber;
  /**  Returns the Youtube compare accounts tag ranking data. For influencers only. */
  influencerYoutubeCompareTagRanking: InfluencerYoutubeCompareTagRanking;
  instagramAccountInfo?: Maybe<InstagramAnalyticsAccountInfoHeader>;
  instagramAmbassadorPostDetails?: Maybe<InstagramBrandAmbassadorPostDetails>;
  instagramAnalyticsHashtags?: Maybe<InstagramAnalyticsHashtags>;
  /**
   *  Returns a list of comments of `instagramAnalyticsAccountId` account, comments of posts where it mentioned,
   *  comments where it mentioned, and regular comments
   *  May be used by admin, staff, agency, and advertisers
   */
  instagramAnalyticsListComments: InstagramAnalyticsGetCommentsPayload;
  /**
   *  Returns the total count of comments of `instagramAnalyticsAccountId` account, comments of posts where it mentioned
   *  comments where it mentioned, and regular comments
   *  May be used by admin, staff, agency, and advertisers
   */
  instagramAnalyticsListCommentsCount: TotalNumber;
  /**
   * Returns details for a specific Instagram feed post. For example, the content,
   *         comments, and etc.
   *
   */
  instagramAnalyticsPostDetails?: Maybe<InstagramAnalyticsPostDetails>;
  /** Returns details for a specific Instagram reel post. */
  instagramAnalyticsReelDetails?: Maybe<InstagramAnalyticsReelDetails>;
  /** Returns details for a specific Instagram story post. */
  instagramAnalyticsStoryDetails?: Maybe<InstagramAnalyticsStoryDetails>;
  instagramCompareAccounts: InstagramCompareAccountsPayload;
  /** Returns followers age-gender breakdown data for the main account and compare accounts */
  instagramCompareFollowers?: Maybe<InstagramCompareFollowers>;
  /** Returns a list of accounts to compare */
  instagramCompareGetAccounts?: Maybe<InstagramAnalyticsCompareAccounts>;
  /** Returns the overview data for the main analytics account and compare accounts */
  instagramCompareOverview?: Maybe<InstagramCompareOverview>;
  /** Returns details of a specific post of a compare account */
  instagramComparePostDetails?: Maybe<InstagramComparePostDetails>;
  /**
   * Returns posts analytics data(posting habits, recent posts, popular hashtags, and etc)
   *         for the main account and compare accounts
   */
  instagramComparePosts?: Maybe<InstagramComparePosts>;
  /**
   * Returns follower demographics analytics information for a specific analytics account
   *         in a specified period of time. For example, age-gender distribution, followers countries, and etc.
   */
  instagramDashboardFollowerAnalytics?: Maybe<InstagramDashboardFollowerAnalytics>;
  /**
   * Returns the main analytics information for a specific analytics account
   *         in a specified period of time. For example, the number of followers, posting frequency/activity,
   *         comments growth, and etc.
   */
  instagramDashboardOverview?: Maybe<InstagramDashboardOverview>;
  /**
   * Returns Instagram posts analytics data for a specific analytics account
   *         in a specified period of time. For example, posting habits, last feed and story posts, and etc.
   */
  instagramDashboardPostAnalytics?: Maybe<InstagramDashboardPostAnalytics>;
  instagramHashtagFeedPostsV2: InstagramHashtagFeedPostsPayload;
  instagramHashtagPostDetails?: Maybe<InstagramHashtagPostDetails>;
  instagramHashtagPosts?: Maybe<InstagramHashtagPosts>;
  instagramHashtagPostsV2: InstagramHashtagPostsPayload;
  instagramHashtagsOverview?: Maybe<InstagramHashtagsOverview>;
  instagramInteractionMentionedPostDetails?: Maybe<InstagramInteractionPostDetails>;
  instagramInteractionOverview?: Maybe<InstagramInteractionOverview>;
  instagramInteractionPosts?: Maybe<InstagramInteractionPosts>;
  instagramInteractionTaggedPostDetails?: Maybe<InstagramInteractionPostDetails>;
  /**  Returns basic info for UGC management: ig username, added hashtags */
  instagramUGCManagementBasicInfo: InstagramUGCManagementBasicInfoPayload;
  /**  Returns UGC posts */
  instagramUGCManagementListPosts: InstagramUGCManagementListPostsPayload;
  /**  Returns UGC posts' count. Used for pagination */
  instagramUGCManagementListPostsCount: InstagramUGCManagementListPostsCountPayload;
  /**  Returns UGC post's details */
  instagramUGCManagementPostDetails: InstagramUGCManagementPostDetailsPayload;
  instagramUserHashtagPostList?: Maybe<InstagramUserHashtagListPostsPayload>;
  instagramUserHashtags: InstagramUserHashtagPayload;
  /** Tells if an influencer belongs to UUUM */
  isInfluencerUuum?: Maybe<IsInfluencerUUUM>;
  logo?: Maybe<Logo>;
  marketplace?: Maybe<MarketplaceDetail>;
  marketplaceAffiliateCommissionFixedDates?: Maybe<Array<Scalars['Date']['output']>>;
  marketplaceAffiliateCommissionInfluencers?: Maybe<Array<MarketplaceAffiliateCommissionInfluencer>>;
  marketplaceAffiliateCommissionInfluencersCount?: Maybe<TotalNumber>;
  marketplaceAffiliateJoinedInfluencers?: Maybe<Array<MarketplaceAffiliateJoinedInfluencer>>;
  marketplaceAffiliateJoinedInfluencersCount?: Maybe<TotalNumber>;
  marketplaceAffiliateReportByDate?: Maybe<Array<MarketplaceAffiliateReportByDate>>;
  marketplaceAffiliateReportByDateCount?: Maybe<TotalNumber>;
  marketplaceAffiliateReportByInfluencer?: Maybe<Array<MarketplaceAffiliateReportByInfluencer>>;
  marketplaceAffiliateReportByInfluencerCount?: Maybe<TotalNumber>;
  marketplaceAffiliateReportCountForInfluencer?: Maybe<TotalNumber>;
  marketplaceAffiliateReportForInfluencer?: Maybe<Array<MarketplaceAffiliateReportForInfluencer>>;
  marketplaceAffiliateReportSummary?: Maybe<MarketplaceAffiliateReportSummary>;
  marketplaceAffiliateReportSummaryForInfluencer?: Maybe<MarketplaceAffiliateReportSummaryForInfluencer>;
  marketplaceCampaignPostReportForInfluencer?: Maybe<MarketplaceCampaignPostReportForInfluencer>;
  marketplaceCampaignReport?: Maybe<MarketplaceCampaignReport>;
  marketplaceCampaignReportGrid?: Maybe<CampaignGridReport>;
  marketplaceCampaignReportSummary?: Maybe<NewCampaignReportSummary>;
  marketplaceDraftPost?: Maybe<MarketplaceDraftPostDetail>;
  marketplaceDraftPostsForInfluencer?: Maybe<Array<MarketplaceDraftPostForInfluencer>>;
  marketplaceForInfluencer?: Maybe<MarketplaceDetailForInfluencer>;
  marketplaceGoogleSlidesHistory?: Maybe<Array<GoogleSlideFile>>;
  marketplaceHashtagCloud?: Maybe<Array<HashtagType>>;
  marketplaceJoinableAccounts?: Maybe<MarketplaceJoinableAccountsPayload>;
  marketplaceJoinablePromotionMethods?: Maybe<MarketplaceJoinablePromotionMethodsPayload>;
  marketplaceJoinedAccount?: Maybe<MarketplaceJoinedAccountPayload>;
  marketplaceJoinedMethods?: Maybe<MarketplaceJoinedMethodsPayload>;
  marketplaceJoinedMethodsV2?: Maybe<MarketplaceJoinedMethodsPayloadV2>;
  marketplacePost?: Maybe<MarketplacePostDetail>;
  marketplacePostComments?: Maybe<Array<MarketplacePostComment>>;
  marketplacePostHistory?: Maybe<Array<MarketplacePostHistory>>;
  marketplacePostsNoRevenue?: Maybe<MarketplacePostsNoRevenue>;
  marketplaceTracking?: Maybe<MarketplaceTracking>;
  /**
   *
   *         Returns Marketplace tracking post url of its influencer if campaign is on-going and post is not fetched
   *
   */
  marketplaceTrackingPostUrlForInfluencer?: Maybe<MarketplaceTrackingPostUrlForInfluencer>;
  mobileAppVersion: MobileVersion;
  needReconnectForFacebook?: Maybe<NeedReconnectForFacebookPayload>;
  netsuiteCustomerList?: Maybe<Array<NetSuiteCustomerData>>;
  netsuiteCustomerName?: Maybe<NetSuiteCustomerDataForSearch>;
  netsuiteVendorList?: Maybe<Array<NetSuiteVendorData>>;
  netsuiteVendorName?: Maybe<NetSuiteVendorDataForSearch>;
  openAiMessage?: Maybe<OpenAIMessage>;
  package?: Maybe<ReadPackage>;
  packageInfluencers?: Maybe<Array<ReadPackageInfluencers>>;
  packageProposal?: Maybe<PackageProposal>;
  packageProposalSummary?: Maybe<PackageProposalSummary>;
  partnerAgency?: Maybe<PartnerAgencyWithCompanies>;
  partnerAgencyBusinessAssociates?: Maybe<Array<AssociateCompany>>;
  partnerAgencyBusinessAssociatesCount?: Maybe<TotalNumber>;
  partnerAgencyPermission?: Maybe<PartnerAgencyWithPermission>;
  /**
   *  Returns a URL that FE uses to reconnect partner influencer accounts
   *  May be used by anyone with no authentication.
   */
  partnerReconnectAnalyticsSocialAuthRedirectUrl?: Maybe<PartnerReconnectAnalyticsSocialAuthRedirectUrlPayload>;
  /**
   *  Returns a post specified by `partnerInfluencerAccountId` for partner influencers
   *  Views can be larger than 32-bit, please keep note
   */
  partnerYoutubeAnalyticsPost: YoutubeAnalyticsPostPayload;
  payableCampaign?: Maybe<Array<PayableCampaign>>;
  paymentInformation?: Maybe<PaymentInformation>;
  paymentPackageStatus?: Maybe<PaymentPackageStatusResponse>;
  paymentRequest?: Maybe<PaymentRequest>;
  pricingTable?: Maybe<PricingTable>;
  refreshProfileAccountAvailability?: Maybe<RefreshProfileAccountAvailabilityPayload>;
  refreshTwitterAccountAvailability?: Maybe<RefreshTwitterAccountAvailabilityPayload>;
  reportForInfluencer?: Maybe<Array<ReportForInfluencer>>;
  requestedBcaBrands?: Maybe<Array<RequestedBCABrand>>;
  searchEngCampaignsSelectInfluencers?: Maybe<Array<EngagementCampaignSearchSelectInfluencers>>;
  searchEngCampaignsSelectInfluencersCount?: Maybe<TotalNumber>;
  /**
   * Searches for private partner influencers by a keyword and partner id.
   *         Already added influencers are excluded
   */
  searchPrivatePartnerInfluencers?: Maybe<Array<PrivatePartnerInfluencer>>;
  /**
   * Searches for private talent influencers by a keyword and talent agency id.
   *         Already added influencers are excluded
   */
  searchPrivateTalentInfluencers?: Maybe<Array<PrivateTalentInfluencer>>;
  selectableBcaBrands?: Maybe<SelectableBCABrands>;
  shopifyAuthRedirectUrl?: Maybe<ShopifyAuthRedirectUrlPayload>;
  socialAuthCheckFacebookToken?: Maybe<SocialAuthCheckFacebookTokenPayload>;
  /**  Returns a simplified redirect URL that FE uses to communicate with social network APIs for AnyTag/AnyCreator. */
  socialAuthRedirectUrlForInfluencer?: Maybe<SocialAuthRedirectUrlForInfluencerPayload>;
  sponsoredPosts?: Maybe<InfluencerSponsoredPostResponse>;
  sponsoredPostsCount?: Maybe<InfluencerSponsoredPostCountResponse>;
  subscriptionPlan?: Maybe<SubscriptionPlan>;
  talentAgency?: Maybe<TalentAgencyWithCompanies>;
  talentAgencyBusinessAssociateCompanies?: Maybe<Array<AssociateCompany>>;
  talentAgencyBusinessAssociateCompaniesCount?: Maybe<TotalNumber>;
  /**
   *  Returns a URL that FE uses to reconnect talent influencer accounts
   *  May be used by anyone with no authentication.
   */
  talentAgencyReconnectAnalyticsSocialAuthRedirectUrl?: Maybe<TalentAgencyReconnectAnalyticsSocialAuthRedirectUrlPayload>;
  /**  Returns Talent Influencer details for Edit page including connected social accounts */
  talentInfluencerDetailsForEdit?: Maybe<TalentInfluencerDetailsForEditPayload>;
  tiktokAdAccounts?: Maybe<Array<TiktokAdAccountInfo>>;
  tiktokAdAuthRedirectUrl?: Maybe<TiktokAuthRedirectUrl>;
  tiktokAdCampaigns?: Maybe<Array<TiktokAdCampaign>>;
  tiktokAdGroups?: Maybe<Array<TiktokAdGroup>>;
  tiktokAdvertiserAccountInfo?: Maybe<TiktokAdvertiserAccount>;
  tiktokAdvertiserPlanData?: Maybe<TiktokAdvertiserPlanData>;
  tiktokAdvertiserSummaryData?: Maybe<TiktokAdvertiserSummaryData>;
  tiktokBudgetAllocation?: Maybe<TiktokBudgetAllocation>;
  tiktokBusinessAccountInfo?: Maybe<TiktokBusinessAccountInfo>;
  tiktokBusinessAdAccounts?: Maybe<Array<TiktokAdAccountInfo>>;
  tiktokProfileAudience?: Maybe<TiktokAudienceSection>;
  tiktokSpecialCampaign?: Maybe<TikTokSpecialCampaignDetail>;
  tiktokSpecialCampaignForInfluencerSearchJob?: Maybe<TikTokSpecialCampaignDetailForInfluencer>;
  tiktokSpecialCampaignForInfluencerYourJob?: Maybe<TikTokSpecialCampaignDetailForInfluencer>;
  tiktokSpecialCampaignPostReportForInfluencer?: Maybe<TikTokSpecialCampaignPostReportForInfluencer>;
  tiktokSpecialCampaignPosts?: Maybe<Array<TikTokSpecialCampaignPost>>;
  tiktokSpecialCampaignPostsCount?: Maybe<TotalNumber>;
  tiktokSpecialCampaignReport?: Maybe<TiktokSpecialCampaignReport>;
  tiktokSpecialCampaignReportGrid?: Maybe<CampaignGridReport>;
  tiktokSpecialCampaignReportSummary?: Maybe<TiktokSpecialCampaignReportSummary>;
  tiktokSpecialJoinableAccounts?: Maybe<TiktokSpecialJoinableAccountsPayload>;
  tiktokSpecialJoinedAccount?: Maybe<TikTokSpecialJoinedAccountPayload>;
  tiktokSpecialPackageInfluencers: Array<TikTokSpecialPackageInfluencer>;
  tiktokSpecialPackageInfluencersCount?: Maybe<TotalNumber>;
  tiktokSpecialPost?: Maybe<TikTokSpecialPostDetail>;
  tiktokSpecialPostHistory?: Maybe<Array<TikTokSpecialPostHistory>>;
  tiktokTagPostDetails: TiktokUserTagPostDetailsPayload;
  tiktokUserHashtagPostList?: Maybe<TiktokUserHashtagListPostsPayload>;
  tiktokUserHashtags: TiktokUserHashtagPayload;
  tiktokUserKeywordPostList?: Maybe<TiktokUserHashtagListPostsPayload>;
  tiktokUserKeywords: TiktokUserKeywordPayload;
  totalInfluencerReport?: Maybe<TotalInfluencerReport>;
  /**  Returns trending facebook influencers */
  trendingFacebookInfluencers: TrendingFacebookInfluencerSearchResult;
  /**  Returns trending instagram influencers */
  trendingInstagramInfluencersV2: TrendingInstagramInfluencerSearchResultV2;
  /**  Returns trending youtube influencers */
  trendingYoutubeInfluencersV2: TrendingYoutubeInfluencerSearchResultV2;
  /**
   *  Returns the overview data of the twitter account by `twitterAnalyticsAccountId` within the specified period
   *  May be used by admin, staff, agency, and advertisers
   */
  twitterAnalyticsOverview: TwitterAnalyticsOverviewPayload;
  /**
   *  Returns a post specified by `twitterAnalyticsAccountId`
   *  May be used by admin, staff, agency, and advertisers
   */
  twitterAnalyticsPost: TwitterAnalyticsPostByIdPayload;
  /**
   *  Returns a list of the posts made by the account `twitterAnalyticsAccountId`
   *  May be used by admin, staff, agency, and advertisers
   */
  twitterAnalyticsPosts: TwitterAnalyticsPostsPayload;
  /**
   *  Returns the posts that are made on the date
   *  May be used by admin, staff, agency, and advertisers
   */
  twitterAnalyticsPostsByDate: TwitterAnalyticsPostsByDatePayload;
  /**
   *  Returns the total count of posts made by the account `twitterAnalyticsAccountId`
   *  May be used by admin, staff, agency, and advertisers
   */
  twitterAnalyticsPostsCount: TotalNumber;
  /**
   *  Posts section for twitter dashboard for account `twitterAnalyticsAccountId`
   *  May be used by admin, staff, agency, and advertisers
   */
  twitterAnalyticsPostsStatistic?: Maybe<TwitterAnalyticsPostsStatisticPayload>;
  updatePaymentMethodLink?: Maybe<StripeDeepLink>;
  user?: Maybe<UserDetail>;
  userProposal?: Maybe<UserProposal>;
  /** List of influencer word cloud */
  wordCloudList?: Maybe<Array<InfluencerWordCloud>>;
  xhsAccountInterest?: Maybe<XhsAccountInterest>;
  xhsProfileAudience?: Maybe<XhsAudienceSection>;
  xhsProfileEngagementInsights?: Maybe<XhsEngagementInsightSection>;
  xhsProfilePosts?: Maybe<XhsPostSection>;
  /**
   *  Returns the audience of the youtube account by `youtubeAnalyticsAccountId` within the specified period
   *  May be used by admin, staff, agency, and advertisers
   */
  youtubeAnalyticsAudience: YoutubeAnalyticsAudiencePayload;
  /**
   *  Returns a list of the comments made at videos of the account `youtubeAnalyticsAccountId`
   *  May be used by admin, staff, agency, and advertisers
   */
  youtubeAnalyticsComments: YoutubeAnalyticsCommentsPayload;
  /**
   *  Returns the total count of comments made at videos of the account `youtubeAnalyticsAccountId`
   *  May be used by admin, staff, agency, and advertisers
   */
  youtubeAnalyticsCommentsCount: TotalNumber;
  /**
   *  Returns a list of Youtube accounts compared with account `mainAccountId`
   *  May be used by admin, agency, staff, advertiser, talent_agency, partner
   */
  youtubeAnalyticsCompareAccountCards: YoutubeAnalyticsCompareAccountCards;
  /**
   *  Returns the related posts with the same tag of the same compare account
   *  May be used by administrator, agency, staff, advertiser, talent_agency, partner.
   *  `postId` is the id of the current shown post. This will be excluded from the result
   */
  youtubeAnalyticsCompareRelatedPosts: YoutubeAnalyticsCompareRelatedPosts;
  youtubeAnalyticsCountriesCategories: YoutubeAnalyticsCountriesCategoriesPayload;
  /**
   *  Returns the overview data of the youtube account by `youtubeAnalyticsAccountId` within the specified period
   *  May be used by admin, staff, agency, and advertisers
   */
  youtubeAnalyticsOverview: YoutubeAnalyticsOverviewPayload;
  /**
   *  Returns a post specified by `youtubeAnalyticsPostId`
   *  May be used by admin, staff, agency, and advertisers
   *  Views can be larger than 32-bit, please keep note
   */
  youtubeAnalyticsPost: YoutubeAnalyticsPostWithChannelDetailsPayload;
  /**
   *  Returns a list of the posts made by the account `youtubeAnalyticsAccountId`
   *  May be used by admin, staff, agency, and advertisers
   *  Views can be larger than 32-bit, please keep note
   */
  youtubeAnalyticsPosts: YoutubeAnalyticsPostsPayloads;
  /**
   *  Returns the posts that are made on the date
   *  May be used by admin, staff, agency, and advertisers
   */
  youtubeAnalyticsPostsByDate: YoutubeAnalyticsPostPayloads;
  /**
   *  Returns the total count of posts made by the account `youtubeAnalyticsAccountId`
   *  May be used by admin, staff, agency, and advertisers
   */
  youtubeAnalyticsPostsCount: TotalNumber;
  /**
   *  Returns the statistics of the posts by `youtubeAnalyticsAccountId` within the specified period
   *  May be used by admin, staff, agency, and advertisers
   */
  youtubeAnalyticsPostsStatistics: YoutubeAnalyticsPostsStatisticsPayload;
  /**
   *  Returns the related posts with the same tag of the same analytics account
   *  May be used by admin, staff, agency, and advertisers
   *  `youtubeAnalyticsPostId` is the id of the current shown post. This will be excluded from the result
   */
  youtubeAnalyticsRelatedPosts: YoutubeAnalyticsRelatedPostsPayload;
  youtubeAnalyticsShorts: YoutubeAnalyticsShortsPayloads;
  youtubeAnalyticsShortsCount: TotalNumber;
  /**
   *  Returns a paginated list of the tags sorted by the specified field.
   *  May be used by admin, staff, agency, and advertisers
   *  views can be larger than 32-bit, please keep note.
   */
  youtubeAnalyticsTagsTopRanking: YoutubeAnalyticsTagRankingPayloads;
  /**
   *  Returns the total count of the tags specified by the inputs
   *  May be used by admin, staff, agency, and advertisers
   *  views can be larger than 32-bit, please keep note.
   */
  youtubeAnalyticsTagsTopRankingCount: TotalNumber;
  /**
   *  Returns a trending post by id
   *  May be used by any logged in user
   */
  youtubeAnalyticsTrendPostById: YoutubeAnalyticsTrendPostDetailsPayload;
  /**
   *  Returns a list of the trends for country `country` and category `category` in date `date`
   *  sorted by position in chart from 1 to 100
   *  May be used by any logged in user
   */
  youtubeAnalyticsTrends: YoutubeAnalyticsTrendsPayloads;
  /**
   *  Returns count of the trends for country `country` and category `category` in date `date`
   *  May be used by any logged in user
   */
  youtubeAnalyticsTrendsCount: TotalNumber;
  youtubeBrandAccounts?: Maybe<YouTubeBrandAccounts>;
  youtubeCmsChannelDetail?: Maybe<YoutubeCmsChannelDetail>;
  youtubeCmsChannelInfo?: Maybe<Array<YoutubeCmsChannelInfo>>;
  youtubeCmsChannelsForInfluencer: Array<YoutubeCmsChannelForInfluencer>;
  youtubeCmsConfirmedAssetsSummary?: Maybe<YoutubeCmsConfirmedChannelSummary>;
  youtubeCmsConfirmedChannelsForInfluencer: Array<YoutubeCmsChannelForInfluencer>;
  youtubeCmsConfirmedChannelsSummary?: Maybe<YoutubeCmsConfirmedChannelSummary>;
  youtubeCmsConfirmedMusicsForInfluencer: Array<YoutubeCmsChannelForInfluencer>;
  youtubeCmsConfirmedRevenueForInfluencer?: Maybe<YouTubeCmsRevenueForInfluencer>;
  youtubeCmsConfirmedRevenueLatestMonth?: Maybe<YoutubeCmsConfirmedRevenueLatestMonth>;
  youtubeCmsConfirmedVideosForInfluencer: Array<YoutubeCmsChannelForInfluencer>;
  youtubeCmsEstimateAssetsSummary?: Maybe<YoutubeCmsEstimateChannelSummary>;
  youtubeCmsEstimateChannelsSummary?: Maybe<YoutubeCmsEstimateChannelSummary>;
  youtubeCmsInvoiceCount?: Maybe<TotalNumber>;
  youtubeCmsInvoiceList?: Maybe<Array<YoutubeCmsInvoice>>;
  youtubeCmsInvoiceListForInfluencer?: Maybe<Array<Maybe<YoutubeCmsInvoiceForInfluencer>>>;
  youtubeCmsMusicsForInfluencer: Array<YoutubeCmsMusicForInfluencer>;
  youtubeCmsRevenueForInfluencer?: Maybe<YouTubeCmsRevenueForInfluencer>;
  youtubeCmsStartMonthForInfluencer?: Maybe<YoutubeCmsStartMonth>;
  youtubeCmsVideoAudience?: Maybe<YouTubeCmsVideoAudience>;
  youtubeCmsVideoStats?: Maybe<YouTubeCmsVideoStats>;
  youtubeCmsVideosForInfluencer: Array<YoutubeCmsVideo>;
  /**
   *  Returns all Youtube compare posts in date
   *  May be used by administrator, agency, staff, advertiser, talent_agency, partner.
   */
  youtubeCompareAllPostsInDate?: Maybe<YoutubeCompareAllPostsInDate>;
  /**
   *  Returns the Youtube overview data for the main analytics account and compare accounts
   *  May be used by administrator, agency, staff, advertiser, talent_agency, partner.
   */
  youtubeCompareOverview: YoutubeCompareOverview;
  /**  Returns a compare youtube post by id */
  youtubeComparePostById?: Maybe<YoutubeComparePost>;
  /**
   *  Returns the Youtube compare posts with pagination
   *  May be used by administrator, agency, staff, advertiser, talent_agency, partner.
   */
  youtubeComparePostList: YoutubeComparePostList;
  /**
   *  Returns comparable posts for Youtube
   *  May be used by administrator, agency, staff, advertiser, talent_agency, partner.
   */
  youtubeComparePosts: YoutubeComparePosts;
  /**  Returns the total count of compare posts made for the account `compareAccountId` */
  youtubeComparePostsCount: TotalNumber;
  /**
   *  Returns the Youtube compare accounts tag ranking data
   *  May be used by administrator, agency, staff, advertiser, talent_agency, partner.
   */
  youtubeCompareTagRanking: YoutubeCompareTagRanking;
  youtubeMusicCategories: Array<YoutubeMusicCategory>;
  youtubeMusicList: Array<YoutubeMusicItem>;
}

export interface QueryaccountAnalyticDetailsV2Args {
  id: Scalars['Int']['input'];
}

export interface QueryaccountAnalyticsBasicDetailsV2Args {
  brandAccountId: Scalars['Int']['input'];
}

export interface QueryaddressArgs {
  influencerId: Scalars['Int']['input'];
}

export interface QueryadvertiserArgs {
  pk: Scalars['Int']['input'];
}

export interface QueryagencyArgs {
  pk: Scalars['Int']['input'];
}

export interface QueryagencyBusinessAssociateCompaniesArgs {
  agencyId: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
}

export interface QueryagencyBusinessAssociateCompaniesCountArgs {
  agencyId: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
}

export interface QueryagencyLogoArgs {
  hash: Scalars['String']['input'];
}

export interface QueryallAccountAnalyticsCountForAdminV2Args {
  advertiserId?: InputMaybe<Scalars['Int']['input']>;
  countryId?: InputMaybe<Scalars['String']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  subscriptionPlan?: InputMaybe<AnalyticsSubscriptionPlanType>;
}

export interface QueryallAccountAnalyticsForAdminV2Args {
  advertiserId?: InputMaybe<Scalars['Int']['input']>;
  countryId?: InputMaybe<Scalars['String']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  subscriptionPlan?: InputMaybe<AnalyticsSubscriptionPlanType>;
}

export interface QueryallAccountAnalyticsForAdvertiserV2Args {
  keyword?: InputMaybe<Scalars['String']['input']>;
  subscriptionPlan?: InputMaybe<AnalyticsSubscriptionPlanType>;
}

export interface QueryallAdvertiserPicsArgs {
  advertiserId: Scalars['Int']['input'];
}

export interface QueryallAdvertisersArgs {
  analyticsPlan?: InputMaybe<AnalyticsAdvertiserPlanFilter>;
  countryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  tiktokPlan?: InputMaybe<TikTokAdvertiserPlanFilter>;
  verifiedStatus?: InputMaybe<NetSuiteVerifiedStatus>;
}

export interface QueryallAdvertisersCountArgs {
  analyticsPlan?: InputMaybe<AnalyticsAdvertiserPlanFilter>;
  countryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  tiktokPlan?: InputMaybe<TikTokAdvertiserPlanFilter>;
  verifiedStatus?: InputMaybe<NetSuiteVerifiedStatus>;
}

export interface QueryallAdvertisersForSearchArgs {
  countryId?: InputMaybe<Scalars['String']['input']>;
  includeIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  keyword?: InputMaybe<Scalars['String']['input']>;
}

export interface QueryallAgenciesArgs {
  countryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  verifiedStatus?: InputMaybe<NetSuiteVerifiedStatus>;
}

export interface QueryallAgenciesCountArgs {
  countryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  verifiedStatus?: InputMaybe<NetSuiteVerifiedStatus>;
}

export interface QueryallAgencyUsersArgs {
  agencyId: Scalars['Int']['input'];
}

export interface QueryallApplicantInfluencersForMarketplaceArgs {
  appliedStatus?: InputMaybe<MarketplaceCampaignAppliedStatus>;
  categoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  genders?: InputMaybe<Array<Genders>>;
  id: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  maxAge?: InputMaybe<Scalars['Int']['input']>;
  maxEngagementRate?: InputMaybe<Scalars['Float']['input']>;
  maxFollowers?: InputMaybe<Scalars['Int']['input']>;
  minAge?: InputMaybe<Scalars['Int']['input']>;
  minEngagementRate?: InputMaybe<Scalars['Float']['input']>;
  minFollowers?: InputMaybe<Scalars['Int']['input']>;
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<InfluencerOrderBy>;
}

export interface QueryallApplicantInfluencersForMarketplaceCountArgs {
  appliedStatus?: InputMaybe<MarketplaceCampaignAppliedStatus>;
  categoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  genders?: InputMaybe<Array<Genders>>;
  id: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  maxAge?: InputMaybe<Scalars['Int']['input']>;
  maxEngagementRate?: InputMaybe<Scalars['Float']['input']>;
  maxFollowers?: InputMaybe<Scalars['Int']['input']>;
  minAge?: InputMaybe<Scalars['Int']['input']>;
  minEngagementRate?: InputMaybe<Scalars['Float']['input']>;
  minFollowers?: InputMaybe<Scalars['Int']['input']>;
}

export interface QueryallBankBranchesArgs {
  bankId: Scalars['Int']['input'];
}

export interface QueryallBankBranchesForInfluencerArgs {
  bankId: Scalars['Int']['input'];
}

export interface QueryallBanksArgs {
  countryId: Scalars['String']['input'];
}

export interface QueryallCampaignPostsArgs {
  advertiserIds: Array<Scalars['Int']['input']>;
  campaignTypes: Array<CampaignIdentifierType>;
  campaigns: Array<CampaignIdentifierInput>;
  categoryIds: Array<Scalars['Int']['input']>;
  countryIds: Array<Scalars['String']['input']>;
  influencerIds: Array<Scalars['Int']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  maxPostDate?: InputMaybe<Scalars['Date']['input']>;
  minPostDate?: InputMaybe<Scalars['Date']['input']>;
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<CampaignPostOrderBy>;
  socialPostTypes: Array<CampaignPostSocialType>;
}

export interface QueryallCampaignsForFilterArgs {
  campaignTypes?: InputMaybe<Array<CampaignType>>;
  campaigns?: InputMaybe<Array<CampaignFilter>>;
}

export interface QueryallCampaignsForSearchArgs {
  campaignTypes?: InputMaybe<Array<CampaignType>>;
}

export interface QueryallCampaignsForYourJobArgs {
  isFinished: Scalars['Boolean']['input'];
}

export interface QueryallCampaignsReportArgs {
  campaignCategoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  campaignTypes?: InputMaybe<Array<CampaignType>>;
  campaigns?: InputMaybe<Array<CampaignFilter>>;
  countryId?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<CampaignReportOrderBy>;
  socialMedias?: InputMaybe<Array<CampaignSocialMediaType>>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryallCampaignsSummaryArgs {
  campaignCategoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  campaignTypes?: InputMaybe<Array<CampaignType>>;
  campaigns?: InputMaybe<Array<CampaignFilter>>;
  countryId?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  socialMedias?: InputMaybe<Array<CampaignSocialMediaType>>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryallCitiesArgs {
  countryId: Scalars['String']['input'];
}

export interface QueryallConnectedFacebookPagesArgs {
  pk: Scalars['Int']['input'];
}

export interface QueryallDouyinInfluencersArgs {
  categoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  countryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  demographicsAge?: InputMaybe<SocialAccountInfluencerDemographicsAge>;
  demographicsGender?: InputMaybe<SocialAccountInfluencerDemographicsGender>;
  genders?: InputMaybe<Array<Genders>>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  keywordField?: InputMaybe<InfluencerSearchKeywordField>;
  limit: Scalars['Int']['input'];
  maxAge?: InputMaybe<Scalars['Int']['input']>;
  maxEngagementFollowersRate?: InputMaybe<Scalars['Float']['input']>;
  maxEngagementViewsRate?: InputMaybe<Scalars['Float']['input']>;
  maxFollowers?: InputMaybe<Scalars['Int']['input']>;
  maxPostPrice?: InputMaybe<Scalars['Float']['input']>;
  minAge?: InputMaybe<Scalars['Int']['input']>;
  minEngagementFollowersRate?: InputMaybe<Scalars['Float']['input']>;
  minEngagementViewsRate?: InputMaybe<Scalars['Float']['input']>;
  minFollowers?: InputMaybe<Scalars['Int']['input']>;
  minPostPrice?: InputMaybe<Scalars['Float']['input']>;
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<SocialAccountInfluencerOrderByV2>;
  partnerIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  regionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  status?: InputMaybe<SocialAccountStatus>;
  talentAgencyIds?: InputMaybe<Array<Scalars['Int']['input']>>;
}

export interface QueryallEngagementPostsArgs {
  engagementId: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  status?: InputMaybe<EngagementCampaignPostStatusForSearch>;
}

export interface QueryallEngagementPostsCountArgs {
  engagementId: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EngagementCampaignPostStatusForSearch>;
}

export interface QueryallEngagementPostsForInfluencerArgs {
  engagementId: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
}

export interface QueryallEngagementPostsForInsightCheckArgs {
  campaignId: Scalars['Int']['input'];
  status?: InputMaybe<EngagementCampaignPostStatusForInsightCheck>;
}

export interface QueryallEngagementsArgs {
  campaignCategoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  countryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  hubspotDealIds?: InputMaybe<Array<Scalars['String']['input']>>;
  influencerPicIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  salesPicIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  status?: InputMaybe<EngagementCampaignStatus>;
  tiktokCampaignTypes?: InputMaybe<Array<TikTokCampaignType>>;
}

export interface QueryallEngagementsCountArgs {
  campaignCategoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  countryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  hubspotDealIds?: InputMaybe<Array<Scalars['String']['input']>>;
  influencerPicIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  salesPicIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  status?: InputMaybe<EngagementCampaignStatus>;
  tiktokCampaignTypes?: InputMaybe<Array<TikTokCampaignType>>;
}

export interface QueryallEngagementsForInsightCheckArgs {
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  status?: InputMaybe<EngagementCampaignPostStatusForInsightCheck>;
}

export interface QueryallEngagementsForInsightCheckCountArgs {
  keyword?: InputMaybe<Scalars['String']['input']>;
}

export interface QueryallFacebookAccountInfluencersArgs {
  audience?: InputMaybe<SocialAccountInfluencerAudience>;
  categoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  countryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  genders?: InputMaybe<Array<Genders>>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  keywordField?: InputMaybe<InfluencerSearchKeywordField>;
  limit: Scalars['Int']['input'];
  maxAge?: InputMaybe<Scalars['Int']['input']>;
  maxEngagementRate?: InputMaybe<Scalars['Float']['input']>;
  maxFollowers?: InputMaybe<Scalars['Int']['input']>;
  maxPostPrice?: InputMaybe<Scalars['Float']['input']>;
  minAge?: InputMaybe<Scalars['Int']['input']>;
  minEngagementRate?: InputMaybe<Scalars['Float']['input']>;
  minFollowers?: InputMaybe<Scalars['Int']['input']>;
  minPostPrice?: InputMaybe<Scalars['Float']['input']>;
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<SocialAccountInfluencerOrderBy>;
  partnerIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  regionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  status?: InputMaybe<SocialAccountStatus>;
  talentAgencyIds?: InputMaybe<Array<Scalars['Int']['input']>>;
}

export interface QueryallFacebookAccountInfluencersCountArgs {
  audience?: InputMaybe<SocialAccountInfluencerAudience>;
  categoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  countryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  genders?: InputMaybe<Array<Genders>>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  keywordField?: InputMaybe<InfluencerSearchKeywordField>;
  maxAge?: InputMaybe<Scalars['Int']['input']>;
  maxEngagementRate?: InputMaybe<Scalars['Float']['input']>;
  maxFollowers?: InputMaybe<Scalars['Int']['input']>;
  maxPostPrice?: InputMaybe<Scalars['Float']['input']>;
  minAge?: InputMaybe<Scalars['Int']['input']>;
  minEngagementRate?: InputMaybe<Scalars['Float']['input']>;
  minFollowers?: InputMaybe<Scalars['Int']['input']>;
  minPostPrice?: InputMaybe<Scalars['Float']['input']>;
  partnerIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  regionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  status?: InputMaybe<SocialAccountStatus>;
  talentAgencyIds?: InputMaybe<Array<Scalars['Int']['input']>>;
}

export interface QueryallFacebookAccountInfluencersV2Args {
  audience?: InputMaybe<SocialAccountInfluencerAudience>;
  categoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  countryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  genders?: InputMaybe<Array<Genders>>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  keywordField?: InputMaybe<InfluencerSearchKeywordField>;
  limit: Scalars['Int']['input'];
  maxAge?: InputMaybe<Scalars['Int']['input']>;
  maxEngagementRate?: InputMaybe<Scalars['Float']['input']>;
  maxFollowers?: InputMaybe<Scalars['Int']['input']>;
  maxPostPrice?: InputMaybe<Scalars['Float']['input']>;
  minAge?: InputMaybe<Scalars['Int']['input']>;
  minEngagementRate?: InputMaybe<Scalars['Float']['input']>;
  minFollowers?: InputMaybe<Scalars['Int']['input']>;
  minPostPrice?: InputMaybe<Scalars['Float']['input']>;
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<SocialAccountInfluencerOrderByV2>;
  partnerIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  regionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  status?: InputMaybe<SocialAccountStatus>;
  talentAgencyIds?: InputMaybe<Array<Scalars['Int']['input']>>;
}

export interface QueryallFacebookPageInfluencersArgs {
  audience?: InputMaybe<SocialAccountInfluencerAudience>;
  categoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  countryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  genders?: InputMaybe<Array<Genders>>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  keywordField?: InputMaybe<InfluencerSearchKeywordField>;
  limit: Scalars['Int']['input'];
  maxAge?: InputMaybe<Scalars['Int']['input']>;
  maxEngagementRate?: InputMaybe<Scalars['Float']['input']>;
  maxFollowers?: InputMaybe<Scalars['Int']['input']>;
  maxPostPrice?: InputMaybe<Scalars['Float']['input']>;
  minAge?: InputMaybe<Scalars['Int']['input']>;
  minEngagementRate?: InputMaybe<Scalars['Float']['input']>;
  minFollowers?: InputMaybe<Scalars['Int']['input']>;
  minPostPrice?: InputMaybe<Scalars['Float']['input']>;
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<SocialAccountInfluencerOrderBy>;
  partnerIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  regionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  status?: InputMaybe<SocialAccountStatus>;
  talentAgencyIds?: InputMaybe<Array<Scalars['Int']['input']>>;
}

export interface QueryallFacebookPageInfluencersCountArgs {
  audience?: InputMaybe<SocialAccountInfluencerAudience>;
  categoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  countryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  genders?: InputMaybe<Array<Genders>>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  keywordField?: InputMaybe<InfluencerSearchKeywordField>;
  maxAge?: InputMaybe<Scalars['Int']['input']>;
  maxEngagementRate?: InputMaybe<Scalars['Float']['input']>;
  maxFollowers?: InputMaybe<Scalars['Int']['input']>;
  maxPostPrice?: InputMaybe<Scalars['Float']['input']>;
  minAge?: InputMaybe<Scalars['Int']['input']>;
  minEngagementRate?: InputMaybe<Scalars['Float']['input']>;
  minFollowers?: InputMaybe<Scalars['Int']['input']>;
  minPostPrice?: InputMaybe<Scalars['Float']['input']>;
  partnerIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  regionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  status?: InputMaybe<SocialAccountStatus>;
  talentAgencyIds?: InputMaybe<Array<Scalars['Int']['input']>>;
}

export interface QueryallFacebookPageInfluencersV2Args {
  audience?: InputMaybe<SocialAccountInfluencerAudience>;
  categoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  countryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  genders?: InputMaybe<Array<Genders>>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  keywordField?: InputMaybe<InfluencerSearchKeywordField>;
  limit: Scalars['Int']['input'];
  maxAge?: InputMaybe<Scalars['Int']['input']>;
  maxEngagementRate?: InputMaybe<Scalars['Float']['input']>;
  maxFollowers?: InputMaybe<Scalars['Int']['input']>;
  maxPostPrice?: InputMaybe<Scalars['Float']['input']>;
  minAge?: InputMaybe<Scalars['Int']['input']>;
  minEngagementRate?: InputMaybe<Scalars['Float']['input']>;
  minFollowers?: InputMaybe<Scalars['Int']['input']>;
  minPostPrice?: InputMaybe<Scalars['Float']['input']>;
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<SocialAccountInfluencerOrderByV2>;
  partnerIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  regionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  status?: InputMaybe<SocialAccountStatus>;
  talentAgencyIds?: InputMaybe<Array<Scalars['Int']['input']>>;
}

export interface QueryallInfluencerAccountPostsArgs {
  isSponsoredPost?: InputMaybe<Scalars['Boolean']['input']>;
  isVideo?: InputMaybe<Scalars['Boolean']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<PostOrderBy>;
  socialAccountId: Scalars['Int']['input'];
  socialPostType: SocialPostType;
}

export interface QueryallInfluencerAccountPostsCountArgs {
  isSponsoredPost?: InputMaybe<Scalars['Boolean']['input']>;
  isVideo?: InputMaybe<Scalars['Boolean']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  socialAccountId: Scalars['Int']['input'];
  socialPostType: SocialPostType;
}

export interface QueryallInfluencerPostsV3Args {
  categoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  countryId?: InputMaybe<Scalars['String']['input']>;
  genders?: InputMaybe<Array<Genders>>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  maxEngagementRate?: InputMaybe<Scalars['Float']['input']>;
  maxFollowers?: InputMaybe<Scalars['Int']['input']>;
  maxPostDate?: InputMaybe<Scalars['Date']['input']>;
  maxViews?: InputMaybe<Scalars['Int']['input']>;
  minEngagementRate?: InputMaybe<Scalars['Float']['input']>;
  minFollowers?: InputMaybe<Scalars['Int']['input']>;
  minPostDate?: InputMaybe<Scalars['Date']['input']>;
  minViews?: InputMaybe<Scalars['Int']['input']>;
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<PostOrderByV3>;
  socialPostType?: InputMaybe<SocialPostType>;
}

export interface QueryallInfluencersCountForMarketplaceArgs {
  categoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  genders?: InputMaybe<Array<Genders>>;
  id: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  maxAge?: InputMaybe<Scalars['Int']['input']>;
  maxEngagementRate?: InputMaybe<Scalars['Float']['input']>;
  maxFollowers?: InputMaybe<Scalars['Int']['input']>;
  minAge?: InputMaybe<Scalars['Int']['input']>;
  minEngagementRate?: InputMaybe<Scalars['Float']['input']>;
  minFollowers?: InputMaybe<Scalars['Int']['input']>;
  participateStatuses?: InputMaybe<Array<MarketplaceCampaignParticipateStatus>>;
  partnerIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  selectedInfluencers?: InputMaybe<Array<SelectedInfluencers>>;
  talentAgencyIds?: InputMaybe<Array<Scalars['Int']['input']>>;
}

export interface QueryallInfluencersForMarketplaceV2Args {
  categoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  genders?: InputMaybe<Array<Genders>>;
  id: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  maxAge?: InputMaybe<Scalars['Int']['input']>;
  maxEngagementRate?: InputMaybe<Scalars['Float']['input']>;
  maxFollowers?: InputMaybe<Scalars['Int']['input']>;
  minAge?: InputMaybe<Scalars['Int']['input']>;
  minEngagementRate?: InputMaybe<Scalars['Float']['input']>;
  minFollowers?: InputMaybe<Scalars['Int']['input']>;
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<InfluencerOrderBy>;
  participateStatuses?: InputMaybe<Array<MarketplaceCampaignParticipateStatus>>;
  partnerIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  selectedInfluencers?: InputMaybe<Array<SelectedInfluencers>>;
  talentAgencyIds?: InputMaybe<Array<Scalars['Int']['input']>>;
}

export interface QueryallInfluencersV4Args {
  campaignDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  categoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  countryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  genders?: InputMaybe<Array<Genders>>;
  includeIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  keywordField?: InputMaybe<InfluencerSearchKeywordField>;
  limit: Scalars['Int']['input'];
  maxAge?: InputMaybe<Scalars['Int']['input']>;
  maxAvgPostPrice?: InputMaybe<Scalars['Float']['input']>;
  maxCampaignsJoined?: InputMaybe<Scalars['Int']['input']>;
  maxEngagementFollowersRate?: InputMaybe<Scalars['Float']['input']>;
  maxEngagementRate?: InputMaybe<Scalars['Float']['input']>;
  maxEngagementViewsRate?: InputMaybe<Scalars['Float']['input']>;
  maxFollowers?: InputMaybe<Scalars['Int']['input']>;
  minAge?: InputMaybe<Scalars['Int']['input']>;
  minAvgPostPrice?: InputMaybe<Scalars['Float']['input']>;
  minCampaignsJoined?: InputMaybe<Scalars['Int']['input']>;
  minEngagementFollowersRate?: InputMaybe<Scalars['Float']['input']>;
  minEngagementRate?: InputMaybe<Scalars['Float']['input']>;
  minEngagementViewsRate?: InputMaybe<Scalars['Float']['input']>;
  minFollowers?: InputMaybe<Scalars['Int']['input']>;
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<InfluencerSearchOrderBy>;
  partnerIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  regionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  socialMedias?: InputMaybe<Array<QuerySocialAccountType>>;
  statuses?: InputMaybe<Array<InfluencerStatusForSearch>>;
  talentAgencyIds?: InputMaybe<Array<Scalars['Int']['input']>>;
}

export interface QueryallInstagramAmbassadorPostsArgs {
  accountId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryallInstagramAmbassadorPostsCountArgs {
  accountId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryallInstagramCompareFeedPostsArgs {
  accountId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<InstagramFeedPostOrderBy>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryallInstagramCompareFeedPostsCountArgs {
  accountId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryallInstagramComparePostsInDateArgs {
  accountId: Scalars['Int']['input'];
  comparableAccountIds: Array<Scalars['Int']['input']>;
  postDate: Scalars['Date']['input'];
}

export interface QueryallInstagramFeedPostsArgs {
  accountId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<InstagramFeedPostOrderBy>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryallInstagramFeedPostsCountArgs {
  accountId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryallInstagramHashtagFeedPostsArgs {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  hashtagId: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<InstagramFeedPostOrderBy>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryallInstagramHashtagFeedPostsCountArgs {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  hashtagId: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryallInstagramInfluencersV3Args {
  audience?: InputMaybe<SocialAccountInfluencerAudience>;
  categoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  countryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  followerGrowthPercentage?: InputMaybe<Scalars['Float']['input']>;
  followerGrowthPeriod?: InputMaybe<FollowersGrowthPeriodEnum>;
  genders?: InputMaybe<Array<Genders>>;
  includeIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  interests?: InputMaybe<Array<Scalars['String']['input']>>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  keywordField?: InputMaybe<InfluencerSearchKeywordField>;
  limit: Scalars['Int']['input'];
  maxAge?: InputMaybe<Scalars['Int']['input']>;
  maxEngagementRate?: InputMaybe<Scalars['Float']['input']>;
  maxFollowers?: InputMaybe<Scalars['Int']['input']>;
  maxPostPrice?: InputMaybe<Scalars['Float']['input']>;
  maxReach?: InputMaybe<Scalars['Float']['input']>;
  maxSaveRate?: InputMaybe<Scalars['Float']['input']>;
  maxStoryPrice?: InputMaybe<Scalars['Float']['input']>;
  minAge?: InputMaybe<Scalars['Int']['input']>;
  minEngagementRate?: InputMaybe<Scalars['Float']['input']>;
  minFollowers?: InputMaybe<Scalars['Int']['input']>;
  minPostPrice?: InputMaybe<Scalars['Float']['input']>;
  minReach?: InputMaybe<Scalars['Float']['input']>;
  minSaveRate?: InputMaybe<Scalars['Float']['input']>;
  minStoryPrice?: InputMaybe<Scalars['Float']['input']>;
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<SocialAccountInfluencerOrderByV2>;
  partnerIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  regionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  status?: InputMaybe<SocialAccountStatus>;
  talentAgencyIds?: InputMaybe<Array<Scalars['Int']['input']>>;
}

export interface QueryallInstagramInteractionUsersArgs {
  accountId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<InstagramInteractionUserOrderBy>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryallInstagramMentionedPostsArgs {
  accountId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<InstagramFeedPostOrderBy>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryallInstagramMentionedPostsCountArgs {
  accountId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryallInstagramPostsInDateArgs {
  accountId: Scalars['Int']['input'];
  postDate: Scalars['Date']['input'];
}

export interface QueryallInstagramReelPostsArgs {
  accountId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<InstagramReelPostOrderBy>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryallInstagramReelPostsCountArgs {
  accountId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryallInstagramSimilarInfluencersV3Args {
  accountId?: InputMaybe<Scalars['Int']['input']>;
  genders?: InputMaybe<Array<Genders>>;
  limit: Scalars['Int']['input'];
  maxAge?: InputMaybe<Scalars['Int']['input']>;
  maxFollowers?: InputMaybe<Scalars['Int']['input']>;
  minAge?: InputMaybe<Scalars['Int']['input']>;
  minFollowers?: InputMaybe<Scalars['Int']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
}

export interface QueryallInstagramStoryPostsArgs {
  accountId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<InstagramStoryPostOrderBy>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryallInstagramStoryPostsCountArgs {
  accountId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryallInstagramTaggedPostsArgs {
  accountId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<InstagramFeedPostOrderBy>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryallInstagramTaggedPostsCountArgs {
  accountId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryallMarketplacePostsArgs {
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  marketplaceId: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  status?: InputMaybe<MarketplaceCampaignPostStatusForSearch>;
}

export interface QueryallMarketplacePostsCountArgs {
  keyword?: InputMaybe<Scalars['String']['input']>;
  marketplaceId: Scalars['Int']['input'];
  status?: InputMaybe<MarketplaceCampaignPostStatusForSearch>;
}

export interface QueryallMarketplacesArgs {
  advertiserIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  campaignDetailType?: InputMaybe<MarketplaceCampaignDetailType>;
  countryId?: InputMaybe<Scalars['String']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  status?: InputMaybe<MarketplaceCampaignStatus>;
}

export interface QueryallMarketplacesCountArgs {
  campaignDetailType?: InputMaybe<MarketplaceCampaignDetailType>;
  countryId?: InputMaybe<Scalars['String']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<MarketplaceCampaignStatus>;
}

export interface QueryallMarketplacesForReorderArgs {
  countryId?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
}

export interface QueryallNeedReviewMarketplacePostsArgs {
  marketplaceId: Scalars['Int']['input'];
}

export interface QueryallPackagesArgs {
  campaignId?: InputMaybe<Scalars['Int']['input']>;
  countryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
}

export interface QueryallPackagesCountArgs {
  countryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  keyword?: InputMaybe<Scalars['String']['input']>;
}

export interface QueryallPartnerAgenciesCountArgs {
  countryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  verifiedStatus?: InputMaybe<NetSuiteVerifiedStatus>;
}

export interface QueryallPartnerAgencyCompanyArgs {
  countryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  verifiedStatus?: InputMaybe<NetSuiteVerifiedStatus>;
}

export interface QueryallPartnerAgencyUsersArgs {
  partnerAgencyId: Scalars['Int']['input'];
}

export interface QueryallPaymentsArgs {
  countryId?: InputMaybe<Scalars['String']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  maxDueDate?: InputMaybe<Scalars['Date']['input']>;
  minDueDate?: InputMaybe<Scalars['Date']['input']>;
  offset: Scalars['Int']['input'];
  status?: InputMaybe<PaymentRequestStatus>;
}

export interface QueryallPaymentsCountArgs {
  countryId?: InputMaybe<Scalars['String']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  maxDueDate?: InputMaybe<Scalars['Date']['input']>;
  minDueDate?: InputMaybe<Scalars['Date']['input']>;
  status?: InputMaybe<PaymentRequestStatus>;
}

export interface QueryallRegionsArgs {
  countryId: Scalars['String']['input'];
}

export interface QueryallStaffsArgs {
  countryId?: InputMaybe<Scalars['String']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}

export interface QueryallStaffsCountArgs {
  countryId?: InputMaybe<Scalars['String']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
}

export interface QueryallTalentAgenciesCountArgs {
  countryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  verifiedStatus?: InputMaybe<NetSuiteVerifiedStatus>;
}

export interface QueryallTalentAgencyCompanyArgs {
  countryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  verifiedStatus?: InputMaybe<NetSuiteVerifiedStatus>;
}

export interface QueryallTalentAgencyUsersArgs {
  talentAgencyId: Scalars['Int']['input'];
}

export interface QueryallThreadsInfluencersArgs {
  categoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  countryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  genders?: InputMaybe<Array<Genders>>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  keywordField?: InputMaybe<InfluencerSearchKeywordField>;
  limit: Scalars['Int']['input'];
  maxAge?: InputMaybe<Scalars['Int']['input']>;
  maxEngagementRate?: InputMaybe<Scalars['Float']['input']>;
  maxFollowers?: InputMaybe<Scalars['Int']['input']>;
  maxPostPrice?: InputMaybe<Scalars['Float']['input']>;
  minAge?: InputMaybe<Scalars['Int']['input']>;
  minEngagementRate?: InputMaybe<Scalars['Float']['input']>;
  minFollowers?: InputMaybe<Scalars['Int']['input']>;
  minPostPrice?: InputMaybe<Scalars['Float']['input']>;
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<SocialAccountInfluencerOrderBy>;
  partnerIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  regionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  status?: InputMaybe<SocialAccountStatus>;
  talentAgencyIds?: InputMaybe<Array<Scalars['Int']['input']>>;
}

export interface QueryallThreadsInfluencersCountArgs {
  categoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  countryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  genders?: InputMaybe<Array<Genders>>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  keywordField?: InputMaybe<InfluencerSearchKeywordField>;
  maxAge?: InputMaybe<Scalars['Int']['input']>;
  maxEngagementRate?: InputMaybe<Scalars['Float']['input']>;
  maxFollowers?: InputMaybe<Scalars['Int']['input']>;
  maxPostPrice?: InputMaybe<Scalars['Float']['input']>;
  minAge?: InputMaybe<Scalars['Int']['input']>;
  minEngagementRate?: InputMaybe<Scalars['Float']['input']>;
  minFollowers?: InputMaybe<Scalars['Int']['input']>;
  minPostPrice?: InputMaybe<Scalars['Float']['input']>;
  partnerIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  regionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  status?: InputMaybe<SocialAccountStatus>;
  talentAgencyIds?: InputMaybe<Array<Scalars['Int']['input']>>;
}

export interface QueryallTiktokAdReportSummaryArgs {
  adCampaignId?: InputMaybe<Scalars['String']['input']>;
  adGroupId?: InputMaybe<Scalars['String']['input']>;
  adReportType: TikTokAdReportType;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  status?: InputMaybe<Array<TikTokAdStatus>>;
}

export interface QueryallTiktokAdsReportArgs {
  adCampaignId?: InputMaybe<Scalars['String']['input']>;
  adGroupId?: InputMaybe<Scalars['String']['input']>;
  adReportType: TikTokAdReportType;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<TiktokAdReportOrderBy>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  status?: InputMaybe<Array<TikTokAdStatus>>;
}

export interface QueryallTiktokInfluencersArgs {
  categoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  countryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  genders?: InputMaybe<Array<Genders>>;
  includeIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  isTtcm?: InputMaybe<Scalars['Boolean']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  keywordField?: InputMaybe<InfluencerSearchKeywordField>;
  limit: Scalars['Int']['input'];
  maxAge?: InputMaybe<Scalars['Int']['input']>;
  maxEngagementRate?: InputMaybe<Scalars['Float']['input']>;
  maxFollowers?: InputMaybe<Scalars['Int']['input']>;
  maxPostPrice?: InputMaybe<Scalars['Float']['input']>;
  minAge?: InputMaybe<Scalars['Int']['input']>;
  minEngagementRate?: InputMaybe<Scalars['Float']['input']>;
  minFollowers?: InputMaybe<Scalars['Int']['input']>;
  minPostPrice?: InputMaybe<Scalars['Float']['input']>;
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<SocialAccountInfluencerOrderBy>;
  partnerIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  regionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  status?: InputMaybe<SocialAccountStatus>;
  talentAgencyIds?: InputMaybe<Array<Scalars['Int']['input']>>;
}

export interface QueryallTiktokInfluencersCountArgs {
  categoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  countryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  genders?: InputMaybe<Array<Genders>>;
  includeIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  isTtcm?: InputMaybe<Scalars['Boolean']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  keywordField?: InputMaybe<InfluencerSearchKeywordField>;
  maxAge?: InputMaybe<Scalars['Int']['input']>;
  maxEngagementRate?: InputMaybe<Scalars['Float']['input']>;
  maxFollowers?: InputMaybe<Scalars['Int']['input']>;
  maxPostPrice?: InputMaybe<Scalars['Float']['input']>;
  minAge?: InputMaybe<Scalars['Int']['input']>;
  minEngagementRate?: InputMaybe<Scalars['Float']['input']>;
  minFollowers?: InputMaybe<Scalars['Int']['input']>;
  minPostPrice?: InputMaybe<Scalars['Float']['input']>;
  partnerIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  regionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  status?: InputMaybe<SocialAccountStatus>;
  talentAgencyIds?: InputMaybe<Array<Scalars['Int']['input']>>;
}

export interface QueryallTiktokInfluencersV2Args {
  categoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  countryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  demographicsAge?: InputMaybe<SocialAccountInfluencerDemographicsAge>;
  demographicsGender?: InputMaybe<SocialAccountInfluencerDemographicsGender>;
  genders?: InputMaybe<Array<Genders>>;
  includeIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  isTtcm?: InputMaybe<Scalars['Boolean']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  keywordField?: InputMaybe<InfluencerSearchKeywordField>;
  limit: Scalars['Int']['input'];
  maxAge?: InputMaybe<Scalars['Int']['input']>;
  maxEngagementFollowersRate?: InputMaybe<Scalars['Float']['input']>;
  maxEngagementViewsRate?: InputMaybe<Scalars['Float']['input']>;
  maxFollowers?: InputMaybe<Scalars['Int']['input']>;
  maxPostPrice?: InputMaybe<Scalars['Float']['input']>;
  minAge?: InputMaybe<Scalars['Int']['input']>;
  minEngagementFollowersRate?: InputMaybe<Scalars['Float']['input']>;
  minEngagementViewsRate?: InputMaybe<Scalars['Float']['input']>;
  minFollowers?: InputMaybe<Scalars['Int']['input']>;
  minPostPrice?: InputMaybe<Scalars['Float']['input']>;
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<SocialAccountInfluencerOrderByV2>;
  partnerIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  regionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  status?: InputMaybe<SocialAccountStatus>;
  talentAgencyIds?: InputMaybe<Array<Scalars['Int']['input']>>;
}

export interface QueryallTiktokSimilarInfluencersArgs {
  accountId: Scalars['Int']['input'];
  genders?: InputMaybe<Array<Genders>>;
  limit: Scalars['Int']['input'];
  maxAge?: InputMaybe<Scalars['Int']['input']>;
  maxFollowers?: InputMaybe<Scalars['Int']['input']>;
  minAge?: InputMaybe<Scalars['Int']['input']>;
  minFollowers?: InputMaybe<Scalars['Int']['input']>;
}

export interface QueryallTiktokSpecialCampaignsArgs {
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  status?: InputMaybe<TikTokSpecialCampaignStatus>;
}

export interface QueryallTiktokSpecialCampaignsCountArgs {
  keyword?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<TikTokSpecialCampaignStatus>;
}

export interface QueryallTwitterInfluencersArgs {
  categoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  countryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  genders?: InputMaybe<Array<Genders>>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  keywordField?: InputMaybe<InfluencerSearchKeywordField>;
  limit: Scalars['Int']['input'];
  maxAge?: InputMaybe<Scalars['Int']['input']>;
  maxEngagementRate?: InputMaybe<Scalars['Float']['input']>;
  maxFollowers?: InputMaybe<Scalars['Int']['input']>;
  maxPostPrice?: InputMaybe<Scalars['Float']['input']>;
  minAge?: InputMaybe<Scalars['Int']['input']>;
  minEngagementRate?: InputMaybe<Scalars['Float']['input']>;
  minFollowers?: InputMaybe<Scalars['Int']['input']>;
  minPostPrice?: InputMaybe<Scalars['Float']['input']>;
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<SocialAccountInfluencerOrderBy>;
  partnerIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  regionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  status?: InputMaybe<SocialAccountStatus>;
  talentAgencyIds?: InputMaybe<Array<Scalars['Int']['input']>>;
}

export interface QueryallTwitterInfluencersCountArgs {
  categoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  countryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  genders?: InputMaybe<Array<Genders>>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  keywordField?: InputMaybe<InfluencerSearchKeywordField>;
  maxAge?: InputMaybe<Scalars['Int']['input']>;
  maxEngagementRate?: InputMaybe<Scalars['Float']['input']>;
  maxFollowers?: InputMaybe<Scalars['Int']['input']>;
  maxPostPrice?: InputMaybe<Scalars['Float']['input']>;
  minAge?: InputMaybe<Scalars['Int']['input']>;
  minEngagementRate?: InputMaybe<Scalars['Float']['input']>;
  minFollowers?: InputMaybe<Scalars['Int']['input']>;
  minPostPrice?: InputMaybe<Scalars['Float']['input']>;
  partnerIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  regionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  status?: InputMaybe<SocialAccountStatus>;
  talentAgencyIds?: InputMaybe<Array<Scalars['Int']['input']>>;
}

export interface QueryallTwitterSimilarInfluencersArgs {
  accountId: Scalars['Int']['input'];
  genders?: InputMaybe<Array<Genders>>;
  limit: Scalars['Int']['input'];
  maxAge?: InputMaybe<Scalars['Int']['input']>;
  maxFollowers?: InputMaybe<Scalars['Int']['input']>;
  minAge?: InputMaybe<Scalars['Int']['input']>;
  minFollowers?: InputMaybe<Scalars['Int']['input']>;
}

export interface QueryallUsersArgs {
  advertiserIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  countryId?: InputMaybe<Scalars['String']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
}

export interface QueryallUsersCountArgs {
  advertiserIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  countryId?: InputMaybe<Scalars['String']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
}

export interface QueryallXHSInfluencersArgs {
  categoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  countryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  demographicsAge?: InputMaybe<SocialAccountInfluencerDemographicsAge>;
  demographicsGender?: InputMaybe<SocialAccountInfluencerDemographicsGender>;
  genders?: InputMaybe<Array<Genders>>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  keywordField?: InputMaybe<InfluencerSearchKeywordField>;
  limit: Scalars['Int']['input'];
  maxAge?: InputMaybe<Scalars['Int']['input']>;
  maxEngagementFollowersRate?: InputMaybe<Scalars['Float']['input']>;
  maxEngagementViewsRate?: InputMaybe<Scalars['Float']['input']>;
  maxFollowers?: InputMaybe<Scalars['Int']['input']>;
  maxPicturePrice?: InputMaybe<Scalars['Float']['input']>;
  maxVideoPrice?: InputMaybe<Scalars['Float']['input']>;
  minAge?: InputMaybe<Scalars['Int']['input']>;
  minEngagementFollowersRate?: InputMaybe<Scalars['Float']['input']>;
  minEngagementViewsRate?: InputMaybe<Scalars['Float']['input']>;
  minFollowers?: InputMaybe<Scalars['Int']['input']>;
  minPicturePrice?: InputMaybe<Scalars['Float']['input']>;
  minVideoPrice?: InputMaybe<Scalars['Float']['input']>;
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<SocialAccountInfluencerOrderByV2>;
  partnerIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  regionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  status?: InputMaybe<SocialAccountStatus>;
  talentAgencyIds?: InputMaybe<Array<Scalars['Int']['input']>>;
}

export interface QueryallYoutubeCmsConfirmedAssetsArgs {
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  month: Scalars['Date']['input'];
  offset: Scalars['Int']['input'];
}

export interface QueryallYoutubeCmsConfirmedAssetsCountArgs {
  keyword?: InputMaybe<Scalars['String']['input']>;
  month: Scalars['Date']['input'];
}

export interface QueryallYoutubeCmsConfirmedChannelsArgs {
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  month: Scalars['Date']['input'];
  offset: Scalars['Int']['input'];
}

export interface QueryallYoutubeCmsConfirmedChannelsCountArgs {
  keyword?: InputMaybe<Scalars['String']['input']>;
  month: Scalars['Date']['input'];
}

export interface QueryallYoutubeCmsEstimateAssetsArgs {
  cmsChannelId?: InputMaybe<Scalars['Int']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  month?: InputMaybe<Scalars['Date']['input']>;
  offset: Scalars['Int']['input'];
}

export interface QueryallYoutubeCmsEstimateAssetsCountArgs {
  cmsChannelId?: InputMaybe<Scalars['Int']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
}

export interface QueryallYoutubeCmsEstimateChannelsArgs {
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  month?: InputMaybe<Scalars['Date']['input']>;
  offset: Scalars['Int']['input'];
}

export interface QueryallYoutubeCmsEstimateChannelsCountArgs {
  keyword?: InputMaybe<Scalars['String']['input']>;
}

export interface QueryallYoutubeInfluencersV3Args {
  audience?: InputMaybe<SocialAccountInfluencerAudience>;
  categoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  countryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  followerGrowthPercentage?: InputMaybe<Scalars['Float']['input']>;
  followerGrowthPeriod?: InputMaybe<FollowersGrowthPeriodEnum>;
  genders?: InputMaybe<Array<Genders>>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  keywordField?: InputMaybe<InfluencerSearchKeywordField>;
  limit: Scalars['Int']['input'];
  maxAge?: InputMaybe<Scalars['Int']['input']>;
  maxEngagementFollowersRate?: InputMaybe<Scalars['Float']['input']>;
  maxEngagementViewsRate?: InputMaybe<Scalars['Float']['input']>;
  maxFollowers?: InputMaybe<Scalars['Int']['input']>;
  maxPostPrice?: InputMaybe<Scalars['Float']['input']>;
  maxViews?: InputMaybe<Scalars['Int']['input']>;
  minAge?: InputMaybe<Scalars['Int']['input']>;
  minEngagementFollowersRate?: InputMaybe<Scalars['Float']['input']>;
  minEngagementViewsRate?: InputMaybe<Scalars['Float']['input']>;
  minFollowers?: InputMaybe<Scalars['Int']['input']>;
  minPostPrice?: InputMaybe<Scalars['Float']['input']>;
  minViews?: InputMaybe<Scalars['Int']['input']>;
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<SocialAccountInfluencerOrderByV2>;
  partnerIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  regionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  status?: InputMaybe<SocialAccountStatus>;
  talentAgencyIds?: InputMaybe<Array<Scalars['Int']['input']>>;
}

export interface QueryallYoutubeSimilarInfluencersArgs {
  accountId: Scalars['Int']['input'];
  genders?: InputMaybe<Array<Genders>>;
  limit: Scalars['Int']['input'];
  maxAge?: InputMaybe<Scalars['Int']['input']>;
  maxFollowers?: InputMaybe<Scalars['Int']['input']>;
  minAge?: InputMaybe<Scalars['Int']['input']>;
  minFollowers?: InputMaybe<Scalars['Int']['input']>;
}

export interface QueryanalyticsSocialAuthRedirectUrlArgs {
  callbackUrl: Scalars['String']['input'];
  provider: AnalyticsAuthSocialAccountType;
}

export interface QueryanalyticsSubscriptionPlanArgs {
  accountId: Scalars['Int']['input'];
}

export interface QueryanalyticsSubscriptionPlanByAdvIdArgs {
  advId: Scalars['Int']['input'];
}

export interface QueryautoManagedCommissionFixedDatesArgs {
  id: Scalars['Int']['input'];
}

export interface QueryautoManagedCommissionInfluencersArgs {
  fixedDate?: InputMaybe<Scalars['Date']['input']>;
  id: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  status?: InputMaybe<AutoManagedCommissionStatus>;
}

export interface QueryautoManagedCommissionInfluencersCountsArgs {
  fixedDate?: InputMaybe<Scalars['Date']['input']>;
  id: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<AutoManagedCommissionStatus>;
}

export interface QueryautoManagedJoinedInfluencersArgs {
  id: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
}

export interface QueryautoManagedJoinedInfluencersCountArgs {
  id: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
}

export interface QueryautoManagedJoinedMethodsArgs {
  input: AutoManagedJoinedMethodsInput;
}

export interface QueryautoManagedJoinedMethodsV2Args {
  input: AutoManagedJoinedMethodsInput;
}

export interface QueryautoManagedReportByDateArgs {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  id: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryautoManagedReportByDateCountArgs {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  id: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryautoManagedReportByInfluencerArgs {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  id: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<MarketplaceAffiliateReportByInfluencerOrderBy>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryautoManagedReportByInfluencerCountArgs {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  id: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryautoManagedReportCountForInfluencerArgs {
  campaignId: Scalars['Int']['input'];
}

export interface QueryautoManagedReportForInfluencerArgs {
  campaignId: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
}

export interface QueryautoManagedReportSummaryArgs {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  id: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryautoManagedReportSummaryForInfluencerArgs {
  campaignId: Scalars['Int']['input'];
}

export interface QuerybrandAccountsArgs {
  influencerId: Scalars['Int']['input'];
  socialAccountId: Scalars['Int']['input'];
  socialMedia: SocialAccountType;
}

export interface QuerycampaignCheckoutSessionLinkArgs {
  packageId: Scalars['Int']['input'];
  priceId: Scalars['Int']['input'];
  redirectUrl: Scalars['String']['input'];
}

export interface QuerycampaignHashtagCloudArgs {
  campaignObjective?: InputMaybe<Scalars['String']['input']>;
  caption?: InputMaybe<Scalars['String']['input']>;
  socialMedia?: InputMaybe<CampaignSocialMediaType>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
}

export interface QuerycampaignsChartForAllTimeArgs {
  campaignCategoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  campaignTypes?: InputMaybe<Array<CampaignType>>;
  campaigns?: InputMaybe<Array<CampaignFilter>>;
  countryId?: InputMaybe<Scalars['String']['input']>;
  socialMedias?: InputMaybe<Array<CampaignSocialMediaType>>;
}

export interface QuerycheckBioLinkNameAvailabilityArgs {
  input?: InputMaybe<CheckBioLinkNameAvailabilityInput>;
}

export interface QuerycheckoutSessionLinkArgs {
  priceId: Scalars['Int']['input'];
  redirectUrl: Scalars['String']['input'];
}

export interface QuerycmsInvoiceDownloadUrlArgs {
  id: Scalars['Int']['input'];
}

export interface QueryconnectableInstagramAccountsArgs {
  fbPageIds: Array<InputMaybe<Scalars['String']['input']>>;
}

export interface QuerycountUnreadNotificationsByAppArgs {
  input?: InputMaybe<CountUnreadNotificationsByAppInput>;
}

export interface QuerycustomerPortalLinkArgs {
  redirectUrl: Scalars['String']['input'];
}

export interface QuerydouyinAccountInterestArgs {
  pk: Scalars['Int']['input'];
  socialAccountId: Scalars['Int']['input'];
}

export interface QuerydouyinProfileAudienceArgs {
  distributionType: DouyinDistributionType;
  pk: Scalars['Int']['input'];
  socialAccountId: Scalars['Int']['input'];
}

export interface QueryengagementArgs {
  pk: Scalars['Int']['input'];
}

export interface QueryengagementCampaignPostReportAudienceBreakdownArgs {
  campaignId: Scalars['Int']['input'];
}

export interface QueryengagementCampaignPostReportForInfluencerArgs {
  campaignId: Scalars['Int']['input'];
}

export interface QueryengagementCampaignReportArgs {
  campaignId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<CampaignReportOrderBy>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryengagementCampaignReportGridArgs {
  campaignId: Scalars['Int']['input'];
}

export interface QueryengagementCampaignReportSummaryArgs {
  campaignId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryengagementCampaignTrafficSourceArgs {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  pk: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryengagementDraftPostHistoryArgs {
  pk: Scalars['Int']['input'];
}

export interface QueryengagementDraftPostHistoryForInfluencerArgs {
  pk: Scalars['Int']['input'];
}

export interface QueryengagementForInfluencerArgs {
  pk: Scalars['Int']['input'];
}

export interface QueryengagementHashtagCloudArgs {
  advertiser?: InputMaybe<Scalars['String']['input']>;
  campaignCountry?: InputMaybe<Scalars['String']['input']>;
  campaignObjective?: InputMaybe<Scalars['String']['input']>;
  campaignTitle?: InputMaybe<Scalars['String']['input']>;
  influencerCategory?: InputMaybe<Array<Scalars['Int']['input']>>;
  productInfo?: InputMaybe<Scalars['String']['input']>;
  socialMedia?: InputMaybe<CampaignSocialMediaType>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
}

export interface QueryengagementPostCommentsArgs {
  limit: Scalars['Int']['input'];
  postId: Scalars['Int']['input'];
}

export interface QueryengagementPostDetailArgs {
  pk: Scalars['Int']['input'];
}

export interface QueryengagementPostDetailForInfluencerArgs {
  pk: Scalars['Int']['input'];
}

export interface QueryengagementPostForInfluencerV2Args {
  pk: Scalars['Int']['input'];
}

export interface QueryengagementPostInfluencersArgs {
  pk: Scalars['Int']['input'];
}

export interface QueryengagementPostMetricsForInsightCheckArgs {
  postId: Scalars['Int']['input'];
}

export interface QueryengagementPostSocialAccountsForInfluencerArgs {
  pk: Scalars['Int']['input'];
}

export interface QueryengagementPostTrafficSourceArgs {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  pk: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryengagementPostV2Args {
  pk: Scalars['Int']['input'];
}

export interface QueryengagementProposalArgs {
  pk: Scalars['Int']['input'];
}

export interface QueryengagementTrackingArgs {
  engagementId: Scalars['Int']['input'];
}

export interface QueryengagementXhsPostMetricsForInsightArgs {
  postId: Scalars['Int']['input'];
}

export interface QueryexistingProfileTagsArgs {
  keyword: Scalars['String']['input'];
}

export interface QueryexternalAllInstagramFeedPostsArgs {
  accountId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<InstagramFeedPostOrderBy>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryexternalAllInstagramFeedPostsCountArgs {
  accountId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryexternalAllInstagramPostsInDateArgs {
  accountId: Scalars['Int']['input'];
  postDate: Scalars['Date']['input'];
}

export interface QueryexternalAllInstagramReelPostsArgs {
  accountId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<InstagramReelPostOrderBy>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryexternalAllInstagramReelPostsCountArgs {
  accountId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryexternalAllInstagramStoryPostsArgs {
  accountId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<InstagramStoryPostOrderBy>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryexternalAllInstagramStoryPostsCountArgs {
  accountId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryexternalAnalyticsFacebookGetAccountDetailsArgs {
  analyticsAccountId: Scalars['Int']['input'];
}

export interface QueryexternalAnalyticsFacebookGetTokenWarningsArgs {
  analyticsAccountIdsToCheck: Array<Scalars['Int']['input']>;
}

export interface QueryexternalAnalyticsInstagramGetAccountDetailsArgs {
  analyticsAccountId: Scalars['Int']['input'];
}

export interface QueryexternalAnalyticsInstagramGetTokenWarningsArgs {
  analyticsAccountIdsToCheck: Array<Scalars['Int']['input']>;
}

export interface QueryexternalAnalyticsSocialAuthRedirectUrlArgs {
  callbackUrl: Scalars['String']['input'];
  provider: ExternalAnalyticsAuthSocialAccountType;
}

export interface QueryexternalFacebookPageAnalyticsAudienceArgs {
  endDate: Scalars['Date']['input'];
  facebookPageAnalyticsAccountId: Scalars['Int']['input'];
  startDate: Scalars['Date']['input'];
}

export interface QueryexternalFacebookPageAnalyticsOverviewArgs {
  endDate: Scalars['Date']['input'];
  facebookPageAnalyticsAccountId: Scalars['Int']['input'];
  startDate: Scalars['Date']['input'];
}

export interface QueryexternalFacebookPageAnalyticsPostArgs {
  facebookPageAnalyticsAccountId: Scalars['Int']['input'];
  facebookPageAnalyticsPostId: Scalars['Int']['input'];
}

export interface QueryexternalFacebookPageAnalyticsPostsArgs {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  facebookPageAnalyticsAccountId: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy: FacebookPageAnalyticsPostsSortInput;
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryexternalFacebookPageAnalyticsPostsAnalyticsArgs {
  endDate: Scalars['Date']['input'];
  facebookPageAnalyticsAccountId: Scalars['Int']['input'];
  startDate: Scalars['Date']['input'];
  timezoneOffset: Scalars['Int']['input'];
}

export interface QueryexternalFacebookPageAnalyticsPostsByDateArgs {
  date: Scalars['Date']['input'];
  facebookPageAnalyticsAccountId: Scalars['Int']['input'];
}

export interface QueryexternalFacebookPageAnalyticsPostsCountArgs {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  facebookPageAnalyticsAccountId: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryexternalInstagramAnalyticsPostDetailsArgs {
  accountId: Scalars['Int']['input'];
  postId: Scalars['Int']['input'];
}

export interface QueryexternalInstagramAnalyticsReelDetailsArgs {
  accountId: Scalars['Int']['input'];
  postId: Scalars['Int']['input'];
}

export interface QueryexternalInstagramAnalyticsStoryDetailsArgs {
  accountId: Scalars['Int']['input'];
  storyId: Scalars['Int']['input'];
}

export interface QueryexternalInstagramDashboardFollowerAnalyticsArgs {
  accountId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryexternalInstagramDashboardOverviewArgs {
  accountId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryexternalInstagramDashboardPostAnalyticsArgs {
  accountId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  timezoneOffset: Scalars['Int']['input'];
}

export interface QueryexternalYoutubeAnalyticsAudienceArgs {
  endDate: Scalars['Date']['input'];
  startDate: Scalars['Date']['input'];
  youtubeAnalyticsAccountId: Scalars['Int']['input'];
}

export interface QueryexternalYoutubeAnalyticsOverviewArgs {
  endDate: Scalars['Date']['input'];
  startDate: Scalars['Date']['input'];
  youtubeAnalyticsAccountId: Scalars['Int']['input'];
}

export interface QueryexternalYoutubeAnalyticsPostArgs {
  youtubeAnalyticsAccountId: Scalars['Int']['input'];
  youtubeAnalyticsPostId: Scalars['Int']['input'];
}

export interface QueryexternalYoutubeAnalyticsPostsArgs {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy: ExternalYoutubeAnalyticsPostsSortInput;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  youtubeAnalyticsAccountId: Scalars['Int']['input'];
}

export interface QueryexternalYoutubeAnalyticsPostsByDateArgs {
  date: Scalars['Date']['input'];
  youtubeAnalyticsAccountId: Scalars['Int']['input'];
}

export interface QueryexternalYoutubeAnalyticsPostsCountArgs {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  youtubeAnalyticsAccountId: Scalars['Int']['input'];
}

export interface QueryexternalYoutubeAnalyticsPostsStatisticsArgs {
  endDate: Scalars['Date']['input'];
  startDate: Scalars['Date']['input'];
  timeDifferenceHour: Scalars['Int']['input'];
  youtubeAnalyticsAccountId: Scalars['Int']['input'];
}

export interface QueryexternalYoutubeAnalyticsRelatedPostsArgs {
  tag: Scalars['String']['input'];
  youtubeAnalyticsAccountId: Scalars['Int']['input'];
  youtubeAnalyticsPostId: Scalars['Int']['input'];
}

export interface QueryexternalYoutubeAnalyticsTagsTopRankingArgs {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy: ExternalYoutubeAnalyticsTagRankingSortInput;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  youtubeAnalyticsAccountId: Scalars['Int']['input'];
}

export interface QueryexternalYoutubeAnalyticsTagsTopRankingCountArgs {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  youtubeAnalyticsAccountId: Scalars['Int']['input'];
}

export interface QueryfacebookPageAnalyticsAudienceArgs {
  endDate: Scalars['Date']['input'];
  facebookPageAnalyticsAccountId: Scalars['Int']['input'];
  startDate: Scalars['Date']['input'];
}

export interface QueryfacebookPageAnalyticsOverviewArgs {
  endDate: Scalars['Date']['input'];
  facebookPageAnalyticsAccountId: Scalars['Int']['input'];
  startDate: Scalars['Date']['input'];
}

export interface QueryfacebookPageAnalyticsPostArgs {
  facebookPageAnalyticsAccountId: Scalars['Int']['input'];
  facebookPageAnalyticsPostId: Scalars['Int']['input'];
}

export interface QueryfacebookPageAnalyticsPostsArgs {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  facebookPageAnalyticsAccountId: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy: FacebookPageAnalyticsPostsSortInput;
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryfacebookPageAnalyticsPostsAnalyticsArgs {
  endDate: Scalars['Date']['input'];
  facebookPageAnalyticsAccountId: Scalars['Int']['input'];
  startDate: Scalars['Date']['input'];
}

export interface QueryfacebookPageAnalyticsPostsByDateArgs {
  date: Scalars['Date']['input'];
  facebookPageAnalyticsAccountId: Scalars['Int']['input'];
}

export interface QueryfacebookPageAnalyticsPostsCountArgs {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  facebookPageAnalyticsAccountId: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryfindJoinedCampaignsArgs {
  influencerId: Scalars['Int']['input'];
}

export interface QuerygenerateAgencyLogoSignedUrlArgs {
  fileNames: Array<Scalars['String']['input']>;
}

export interface QuerygenerateMaterialSignedUrlArgs {
  fileNames: Array<Scalars['String']['input']>;
}

export interface QuerygeneratePostFileSignedUrlArgs {
  fileName: Scalars['String']['input'];
}

export interface QuerygeneratePostFileSignedUrlsArgs {
  fileNames: Array<Scalars['String']['input']>;
}

export interface QuerygenerateThumbnailSignedUrlArgs {
  fileNames: Array<Scalars['String']['input']>;
}

export interface QuerygenerateUploadReportSignedUrlArgs {
  fileNames: Array<Scalars['String']['input']>;
}

export interface QuerygetAllCampaignsCountForSearchJobArgs {
  input: GetAllCampaignsCountForSearchJobInput;
}

export interface QuerygetAllCampaignsForSearchJobArgs {
  input: GetAllCampaignsForSearchJobInput;
}

export interface QuerygetAnyXAccountsByKeywordArgs {
  input: GetAnyXAccountsByKeywordInput;
}

export interface QuerygetAutoManagedCampaignByIdArgs {
  input: GetAutoManagedCampaignByIdInput;
}

export interface QuerygetAutoManagedCampaignForInfluencerSearchJobArgs {
  input: GetAutoManagedCampaignForInfluencerSearchJobInput;
}

export interface QuerygetAutoManagedCampaignForInfluencerYourJobArgs {
  input: GetAutoManagedCampaignForInfluencerYourJobInput;
}

export interface QuerygetAutoManagedCampaignsArgs {
  input: GetAutoManagedCampaignsInput;
}

export interface QuerygetAutoManagedCampaignsCountArgs {
  input: GetAutoManagedCampaignsCountInput;
}

export interface QuerygetChatEventsArgs {
  input: GetChatEventsInput;
}

export interface QuerygetCouponsListArgs {
  fileUrl: Scalars['String']['input'];
}

export interface QuerygetCreatorStaffsArgs {
  input: CreatorStaffsInput;
}

export interface QuerygetCreatorTagsArgs {
  input: GetCreatorTagsInput;
}

export interface QuerygetCreatorTagsByIdArgs {
  input: GetCreatorTagsByIdInput;
}

export interface QuerygetCustomerTagsArgs {
  input: GetCustomerTagsInput;
}

export interface QuerygetEmailListArgs {
  input: GetEmailListInput;
}

export interface QuerygetEmailListCountArgs {
  input: GetEmailListCountInput;
}

export interface QuerygetEmailOverviewArgs {
  input: GetEmailOverviewInput;
}

export interface QuerygetEmailRecipientsArgs {
  input: GetEmailRecipientsInput;
}

export interface QuerygetEmailRecipientsCountArgs {
  input: GetEmailRecipientsCountInput;
}

export interface QuerygetEmailTemplateArgs {
  input: GetEmailTemplateInput;
}

export interface QuerygetFanArgs {
  input: GetFanInput;
}

export interface QuerygetFanActivitiesArgs {
  input: GetFanActivitiesInput;
}

export interface QuerygetFanFormResponseArgs {
  input: GetFanFormResponseInput;
}

export interface QuerygetFanHeaderArgs {
  input: GetFanInput;
}

export interface QuerygetFanOrdersArgs {
  input: GetFanOrdersInput;
}

export interface QuerygetFanParticipationFormListArgs {
  input: GetFanParticipationFormListInput;
}

export interface QuerygetFanProvincesArgs {
  input: GetFanProvincesInput;
}

export interface QuerygetFansArgs {
  input: GetFansInput;
}

export interface QuerygetFansCountArgs {
  input: GetFansCountInput;
}

export interface QuerygetFormArgs {
  input: GetFormInput;
}

export interface QuerygetFormReportSummaryArgs {
  input: FormReportSummaryInput;
}

export interface QuerygetFormRespondentsCountArgs {
  input: FormRespondentsCountInput;
}

export interface QuerygetFormRespondentsListArgs {
  input: FormRespondentsListInput;
}

export interface QuerygetFormResponseArgs {
  input: GetFormResponseInput;
}

export interface QuerygetFormResponseByPageArgs {
  input: GetFormResponseByPageInput;
}

export interface QuerygetFormTemplateArgs {
  input: GetFormTemplateInput;
}

export interface QuerygetFormsCountArgs {
  input: GetFormsCountInput;
}

export interface QuerygetFormsListArgs {
  input: GetFormsListInput;
}

export interface QuerygetGeneratedMarketplaceCampaignDetailArgs {
  input: GetGeneratedMarketplaceCampaignDetailInput;
}

export interface QuerygetGeneratedMarketplacePostCaptionArgs {
  input: GetGeneratedMarketplacePostCaptionInput;
}

export interface QuerygetGeneratedTikTokSpecialPostCaptionArgs {
  input: GetGeneratedTikTokSpecialPostCaptionInput;
}

export interface QuerygetInfluencerBioAnalyticsArgs {
  input: GetInfluencerBioAnalyticsInput;
}

export interface QuerygetInfluencerRateCardArgs {
  input: InfluencerRateCardInput;
}

export interface QuerygetLineBroadcastEstimateRecipientsArgs {
  input: GetLineBroadcastEstimateRecipientsInput;
}

export interface QuerygetLineBroadcastMessageArgs {
  input: GetLineBroadcastMessageInput;
}

export interface QuerygetLineBroadcastSummaryArgs {
  input: GetLineBroadcastSummaryInput;
}

export interface QuerygetLineBroadcastsArgs {
  input: GetLineBroadcastsInput;
}

export interface QuerygetLineBroadcastsCountArgs {
  input: GetLineBroadcastsCountInput;
}

export interface QuerygetLineChatArgs {
  input: GetLineChatInput;
}

export interface QuerygetLineChatCountArgs {
  input: GetLineChatCountInput;
}

export interface QuerygetLineChatsArgs {
  input: GetLineChatsInput;
}

export interface QuerygetLinkBioFacebookAccountPostsArgs {
  input: GetLinkBioFacebookAccountPostsInput;
}

export interface QuerygetLinkBioFacebookPagePostsArgs {
  input: GetLinkBioFacebookPagePostsInput;
}

export interface QuerygetMarketplaceCampaignForInfluencerArgs {
  pk: Scalars['Int']['input'];
}

export interface QuerygetNotificationsArgs {
  input?: InputMaybe<UnreadNotificationInput>;
}

export interface QuerygetNotificationsByAppArgs {
  input?: InputMaybe<NotificationsByAppInput>;
}

export interface QuerygetPublicInfluencerBioArgs {
  input: GetPublicInfluencerBioInput;
}

export interface QuerygetSocialAccountPermissionsArgs {
  input: SocialAccountPermissionsInput;
}

export interface QuerygetTagsAndFansRecipientsArgs {
  input: GetTagsAndFansRecipientsInput;
}

export interface QuerygetUserDefaultPermissionsArgs {
  role: UserRoles;
}

export interface QuerygetUserDetailArgs {
  input: UserDetailInput;
}

export interface QuerygetYoutubeMCNRedirectUrlForAdminArgs {
  input: YoutubeMCNAdminRedirectUrlInput;
}

export interface QueryhubspotCompanyListArgs {
  keyword?: InputMaybe<Scalars['String']['input']>;
}

export interface QueryhubspotCompanyNameArgs {
  hubspotId: Scalars['String']['input'];
}

export interface QueryhubspotDealNameArgs {
  hubspotDealIds?: InputMaybe<Array<Scalars['String']['input']>>;
}

export interface QueryhubspotDealsListArgs {
  countryId?: InputMaybe<Scalars['String']['input']>;
  keyword: Scalars['String']['input'];
}

export interface QueryinfluencerAllInstagramFeedPostsArgs {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<InstagramFeedPostOrderBy>;
  socialAccountId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryinfluencerAllInstagramFeedPostsCountArgs {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  socialAccountId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryinfluencerAllInstagramInteractionUsersArgs {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<InstagramInteractionUserOrderBy>;
  socialAccountId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryinfluencerAllInstagramMentionedPostsArgs {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<InstagramFeedPostOrderBy>;
  socialAccountId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryinfluencerAllInstagramMentionedPostsCountArgs {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  socialAccountId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryinfluencerAllInstagramPostsInDateArgs {
  postDate: Scalars['Date']['input'];
  socialAccountId: Scalars['Int']['input'];
}

export interface QueryinfluencerAllInstagramReelPostsArgs {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<InstagramReelPostOrderBy>;
  socialAccountId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryinfluencerAllInstagramReelPostsCountArgs {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  socialAccountId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryinfluencerAllInstagramStoryPostsArgs {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<InstagramStoryPostOrderBy>;
  socialAccountId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryinfluencerAllInstagramStoryPostsCountArgs {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  socialAccountId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryinfluencerAllInstagramTaggedPostsArgs {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<InstagramFeedPostOrderBy>;
  socialAccountId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryinfluencerAllInstagramTaggedPostsCountArgs {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  socialAccountId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryinfluencerAnalyticsFacebookPageAccessTokenWarningArgs {
  influencerId: Scalars['Int']['input'];
  socialAccountId: Scalars['Int']['input'];
}

export interface QueryinfluencerAnalyticsInstagramAccessTokenWarningArgs {
  socialAccountId: Scalars['Int']['input'];
}

export interface QueryinfluencerAnalyticsMultipleInstagramAccessTokenWarningArgs {
  socialAccountIds: Array<Scalars['Int']['input']>;
}

export interface QueryinfluencerAnalyticsTwitterAccessTokenWarningArgs {
  socialAccountId: Scalars['Int']['input'];
}

export interface QueryinfluencerAnalyticsTwitterRefreshAvailabilityArgs {
  socialAccountId: Scalars['Int']['input'];
}

export interface QueryinfluencerCampaignListArgs {
  campaignType?: InputMaybe<CampaignType>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<InfluencerCampaignOrderBy>;
  pk: Scalars['Int']['input'];
  socialMedias?: InputMaybe<Array<CampaignSocialMediaType>>;
}

export interface QueryinfluencerCampaignListByPromotionMethodArgs {
  influencerId: Scalars['Int']['input'];
  promotionMethodId: Scalars['Int']['input'];
}

export interface QueryinfluencerCampaignSummaryArgs {
  pk: Scalars['Int']['input'];
}

export interface QueryinfluencerFacebookPageAnalyticsAudienceArgs {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  influencerId: Scalars['Int']['input'];
  socialAccountId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryinfluencerFacebookPageAnalyticsOverviewArgs {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  influencerId: Scalars['Int']['input'];
  socialAccountId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryinfluencerFacebookPageAnalyticsPostArgs {
  influencerId: Scalars['Int']['input'];
  postId: Scalars['Int']['input'];
  socialAccountId: Scalars['Int']['input'];
}

export interface QueryinfluencerFacebookPageAnalyticsPostsArgs {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  influencerId: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy: FacebookPageAnalyticsPostsSortInput;
  socialAccountId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryinfluencerFacebookPageAnalyticsPostsAnalyticsArgs {
  endDate: Scalars['Date']['input'];
  influencerId: Scalars['Int']['input'];
  socialAccountId: Scalars['Int']['input'];
  startDate: Scalars['Date']['input'];
}

export interface QueryinfluencerFacebookPageAnalyticsPostsByDateArgs {
  date: Scalars['Date']['input'];
  influencerId: Scalars['Int']['input'];
  socialAccountId: Scalars['Int']['input'];
}

export interface QueryinfluencerFacebookPageAnalyticsPostsCountArgs {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  influencerId: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  socialAccountId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryinfluencerHasEstimateApiArgs {
  pk: Scalars['Int']['input'];
  socialAccountId: Scalars['Int']['input'];
  socialMedia: SocialAccountType;
}

export interface QueryinfluencerInstagramAnalyticsPostDetailsArgs {
  postId: Scalars['Int']['input'];
  socialAccountId: Scalars['Int']['input'];
}

export interface QueryinfluencerInstagramAnalyticsReelDetailsArgs {
  postId: Scalars['Int']['input'];
  socialAccountId: Scalars['Int']['input'];
}

export interface QueryinfluencerInstagramAnalyticsStoryDetailsArgs {
  socialAccountId: Scalars['Int']['input'];
  storyId: Scalars['Int']['input'];
}

export interface QueryinfluencerInstagramDashboardFollowerAnalyticsArgs {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  socialAccountId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryinfluencerInstagramDashboardOverviewArgs {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  socialAccountId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryinfluencerInstagramDashboardPostAnalyticsArgs {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  socialAccountId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryinfluencerInstagramInteractionMentionedPostDetailsArgs {
  postId: Scalars['Int']['input'];
  socialAccountId: Scalars['Int']['input'];
}

export interface QueryinfluencerInstagramInteractionOverviewArgs {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  socialAccountId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryinfluencerInstagramInteractionPostsArgs {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  socialAccountId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryinfluencerInstagramInteractionTaggedPostDetailsArgs {
  postId: Scalars['Int']['input'];
  socialAccountId: Scalars['Int']['input'];
}

export interface QueryinfluencerInterestsArgs {
  influencerId: Scalars['Int']['input'];
  socialAccountId: Scalars['Int']['input'];
  socialMedia: SocialAccountType;
}

export interface QueryinfluencerMultipleSocialAccountsCountArgs {
  influencerIds: Array<Scalars['Int']['input']>;
  socialMedia: CampaignSocialMediaType;
}

export interface QueryinfluencerPaymentRequestsArgs {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
}

export interface QueryinfluencerPostDetailArgs {
  needSimilarPosts?: InputMaybe<Scalars['Boolean']['input']>;
  pk: Scalars['Int']['input'];
  postId: Scalars['Int']['input'];
}

export interface QueryinfluencerPostDetailForTalentAgencyArgs {
  needSimilarPosts?: InputMaybe<Scalars['Boolean']['input']>;
  pk: Scalars['Int']['input'];
  postId: Scalars['Int']['input'];
}

export interface QueryinfluencerProfileAudienceArgs {
  pk: Scalars['Int']['input'];
  socialAccountId: Scalars['Int']['input'];
  socialMedia: SocialAccountType;
}

export interface QueryinfluencerProfileDetailForEngagementProposalArgs {
  engagementId: Scalars['Int']['input'];
  influencerId: Scalars['Int']['input'];
  socialAccountId: Scalars['Int']['input'];
  socialMedia: SocialAccountType;
}

export interface QueryinfluencerProfileDetailV2Args {
  pk: Scalars['Int']['input'];
  socialAccountId: Scalars['Int']['input'];
  socialMedia: SocialAccountType;
}

export interface QueryinfluencerProfileFollowerGrowthArgs {
  pk: Scalars['Int']['input'];
  socialAccountId: Scalars['Int']['input'];
  socialMedia: SocialAccountType;
}

export interface QueryinfluencerProfileForEngagementProposalArgs {
  engagementId: Scalars['Int']['input'];
  influencerId: Scalars['Int']['input'];
}

export interface QueryinfluencerProfilePostsArgs {
  pk: Scalars['Int']['input'];
  socialAccountId: Scalars['Int']['input'];
  socialMedia: SocialAccountType;
}

export interface QueryinfluencerProfileV2Args {
  pk: Scalars['Int']['input'];
}

export interface QueryinfluencerTwitterAnalyticsOverviewArgs {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  socialAccountId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryinfluencerTwitterAnalyticsPostArgs {
  socialAccountId: Scalars['Int']['input'];
  twitterAnalyticsPostId: Scalars['Int']['input'];
}

export interface QueryinfluencerTwitterAnalyticsPostsArgs {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy: TwitterAnalyticsPostsSortInput;
  socialAccountId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryinfluencerTwitterAnalyticsPostsByDateArgs {
  date: Scalars['Date']['input'];
  socialAccountId: Scalars['Int']['input'];
}

export interface QueryinfluencerTwitterAnalyticsPostsCountArgs {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  socialAccountId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryinfluencerTwitterAnalyticsPostsStatisticArgs {
  endDate: Scalars['Date']['input'];
  socialAccountId: Scalars['Int']['input'];
  startDate: Scalars['Date']['input'];
}

export interface QueryinfluencerUpdateDetailV2Args {
  pk: Scalars['Int']['input'];
}

export interface QueryinfluencerWhitelistArgs {
  marketplaceId: Scalars['Int']['input'];
}

export interface QueryinfluencerYoutubeAnalyticsAudienceArgs {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  socialAccountId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryinfluencerYoutubeAnalyticsChannelDetailsArgs {
  socialAccountId: Scalars['Int']['input'];
}

export interface QueryinfluencerYoutubeAnalyticsCompareAccountCardsArgs {
  socialAccountId: Scalars['Int']['input'];
}

export interface QueryinfluencerYoutubeAnalyticsCompareRelatedPostsArgs {
  compareAccountId: Scalars['Int']['input'];
  postId: Scalars['Int']['input'];
  socialAccountId: Scalars['Int']['input'];
  tag: Scalars['String']['input'];
}

export interface QueryinfluencerYoutubeAnalyticsOverviewArgs {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  socialAccountId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryinfluencerYoutubeAnalyticsPostArgs {
  socialAccountId: Scalars['Int']['input'];
  youtubeAnalyticsPostId: Scalars['Int']['input'];
}

export interface QueryinfluencerYoutubeAnalyticsPostsArgs {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy: YoutubeAnalyticsPostsSortInput;
  socialAccountId: Scalars['Int']['input'];
  sponsoredPostsOnly?: InputMaybe<Scalars['Boolean']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryinfluencerYoutubeAnalyticsPostsByDateArgs {
  date: Scalars['Date']['input'];
  socialAccountId: Scalars['Int']['input'];
}

export interface QueryinfluencerYoutubeAnalyticsPostsCountArgs {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  socialAccountId: Scalars['Int']['input'];
  sponsoredPostsOnly?: InputMaybe<Scalars['Boolean']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryinfluencerYoutubeAnalyticsPostsStatisticsArgs {
  endDate: Scalars['Date']['input'];
  socialAccountId: Scalars['Int']['input'];
  startDate: Scalars['Date']['input'];
}

export interface QueryinfluencerYoutubeAnalyticsRelatedPostsArgs {
  socialAccountId: Scalars['Int']['input'];
  tag: Scalars['String']['input'];
  youtubeAnalyticsPostId: Scalars['Int']['input'];
}

export interface QueryinfluencerYoutubeAnalyticsShortsArgs {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy: YoutubeAnalyticsPostsSortInput;
  socialAccountId: Scalars['Int']['input'];
  sponsoredPostsOnly?: InputMaybe<Scalars['Boolean']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryinfluencerYoutubeAnalyticsShortsCountArgs {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  socialAccountId: Scalars['Int']['input'];
  sponsoredPostsOnly?: InputMaybe<Scalars['Boolean']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryinfluencerYoutubeAnalyticsSponsoredPostsArgs {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<YoutubeAnalyticsSponsoredPostsSortInput>;
  socialAccountId: Scalars['Long']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryinfluencerYoutubeAnalyticsSponsoredPostsCountArgs {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  socialAccountId: Scalars['Long']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryinfluencerYoutubeAnalyticsTagsTopRankingArgs {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy: YoutubeAnalyticsTagRankingSortInput;
  socialAccountId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryinfluencerYoutubeAnalyticsTagsTopRankingCountArgs {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  socialAccountId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryinfluencerYoutubeCompareAllPostsInDateArgs {
  date: Scalars['Date']['input'];
  socialAccountId: Scalars['Int']['input'];
}

export interface QueryinfluencerYoutubeCompareOverviewArgs {
  endDate: Scalars['Date']['input'];
  socialAccountId: Scalars['Int']['input'];
  startDate: Scalars['Date']['input'];
}

export interface QueryinfluencerYoutubeComparePostByIdArgs {
  compareAccountId: Scalars['Int']['input'];
  postId: Scalars['Int']['input'];
  socialAccountId: Scalars['Int']['input'];
}

export interface QueryinfluencerYoutubeComparePostListArgs {
  compareAccountId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy: YoutubeAnalyticsComparePostsSort;
  socialAccountId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryinfluencerYoutubeComparePostsArgs {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  socialAccountId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryinfluencerYoutubeComparePostsCountArgs {
  compareAccountId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  socialAccountId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryinfluencerYoutubeCompareTagRankingArgs {
  endDate: Scalars['Date']['input'];
  socialAccountId: Scalars['Int']['input'];
  startDate: Scalars['Date']['input'];
}

export interface QueryinstagramAccountInfoArgs {
  accountId: Scalars['Int']['input'];
}

export interface QueryinstagramAmbassadorPostDetailsArgs {
  accountId: Scalars['Int']['input'];
  postId: Scalars['String']['input'];
}

export interface QueryinstagramAnalyticsHashtagsArgs {
  accountId: Scalars['Int']['input'];
}

export interface QueryinstagramAnalyticsListCommentsArgs {
  instagramAnalyticsAccountId: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
}

export interface QueryinstagramAnalyticsListCommentsCountArgs {
  instagramAnalyticsAccountId: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
}

export interface QueryinstagramAnalyticsPostDetailsArgs {
  postId: Scalars['Int']['input'];
}

export interface QueryinstagramAnalyticsReelDetailsArgs {
  accountId: Scalars['Int']['input'];
  postId: Scalars['Int']['input'];
}

export interface QueryinstagramAnalyticsStoryDetailsArgs {
  accountId: Scalars['Int']['input'];
  storyId: Scalars['Int']['input'];
}

export interface QueryinstagramCompareAccountsArgs {
  instagramAnalyticsAccountId: Scalars['Int']['input'];
}

export interface QueryinstagramCompareFollowersArgs {
  accountId: Scalars['Int']['input'];
  comparableAccountIds: Array<Scalars['Int']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryinstagramCompareGetAccountsArgs {
  accountToCompareWithId: Scalars['Int']['input'];
}

export interface QueryinstagramCompareOverviewArgs {
  comparableAccountIds: Array<Scalars['Int']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  mainAccountId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryinstagramComparePostDetailsArgs {
  postId: Scalars['Int']['input'];
}

export interface QueryinstagramComparePostsArgs {
  accountId: Scalars['Int']['input'];
  comparableAccountIds: Array<Scalars['Int']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryinstagramDashboardFollowerAnalyticsArgs {
  accountId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryinstagramDashboardOverviewArgs {
  accountId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryinstagramDashboardPostAnalyticsArgs {
  accountId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryinstagramHashtagFeedPostsV2Args {
  date: Scalars['Date']['input'];
  hashtagId: Scalars['Int']['input'];
  instagramAnalyticsAccountId: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<InstagramPostSortInput>;
}

export interface QueryinstagramHashtagPostDetailsArgs {
  postId: Scalars['Int']['input'];
}

export interface QueryinstagramHashtagPostsArgs {
  analyticsAccountId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryinstagramHashtagPostsV2Args {
  date: Scalars['Date']['input'];
  instagramAnalyticsAccountId: Scalars['Int']['input'];
}

export interface QueryinstagramHashtagsOverviewArgs {
  analyticsAccountId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryinstagramInteractionMentionedPostDetailsArgs {
  accountId: Scalars['Int']['input'];
  postId: Scalars['Int']['input'];
}

export interface QueryinstagramInteractionOverviewArgs {
  analyticsAccountId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryinstagramInteractionPostsArgs {
  analyticsAccountId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryinstagramInteractionTaggedPostDetailsArgs {
  accountId: Scalars['Int']['input'];
  postId: Scalars['Int']['input'];
}

export interface QueryinstagramUGCManagementBasicInfoArgs {
  instagramAnalyticsAccountId: Scalars['Int']['input'];
}

export interface QueryinstagramUGCManagementListPostsArgs {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  hashtagSystemId?: InputMaybe<Scalars['Int']['input']>;
  instagramAnalyticsAccountId: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy: InstagramUGCPostsSortInput;
  postType?: InputMaybe<UGCPostType>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  statuses?: InputMaybe<Array<UGCPostStatus>>;
}

export interface QueryinstagramUGCManagementListPostsCountArgs {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  hashtagSystemId?: InputMaybe<Scalars['Int']['input']>;
  instagramAnalyticsAccountId: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  postType?: InputMaybe<UGCPostType>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  statuses?: InputMaybe<Array<UGCPostStatus>>;
}

export interface QueryinstagramUGCManagementPostDetailsArgs {
  instagramAnalyticsAccountId: Scalars['Int']['input'];
  postSNSId: Scalars['String']['input'];
}

export interface QueryinstagramUserHashtagPostListArgs {
  date: Scalars['Date']['input'];
  hashtagId: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<InstagramUserHashtagPostsSortInput>;
}

export interface QuerymarketplaceArgs {
  pk: Scalars['Int']['input'];
}

export interface QuerymarketplaceAffiliateCommissionFixedDatesArgs {
  marketplaceId: Scalars['Int']['input'];
}

export interface QuerymarketplaceAffiliateCommissionInfluencersArgs {
  fixedDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  marketplaceId: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  status?: InputMaybe<MarketplaceAffiliateCommissionStatus>;
}

export interface QuerymarketplaceAffiliateCommissionInfluencersCountArgs {
  fixedDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  marketplaceId: Scalars['Int']['input'];
  status?: InputMaybe<MarketplaceAffiliateCommissionStatus>;
}

export interface QuerymarketplaceAffiliateJoinedInfluencersArgs {
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  marketplaceId: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
}

export interface QuerymarketplaceAffiliateJoinedInfluencersCountArgs {
  keyword?: InputMaybe<Scalars['String']['input']>;
  marketplaceId: Scalars['Int']['input'];
}

export interface QuerymarketplaceAffiliateReportByDateArgs {
  campaignId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QuerymarketplaceAffiliateReportByDateCountArgs {
  campaignId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QuerymarketplaceAffiliateReportByInfluencerArgs {
  campaignId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<MarketplaceAffiliateReportByInfluencerOrderBy>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QuerymarketplaceAffiliateReportByInfluencerCountArgs {
  campaignId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QuerymarketplaceAffiliateReportCountForInfluencerArgs {
  campaignId: Scalars['Int']['input'];
}

export interface QuerymarketplaceAffiliateReportForInfluencerArgs {
  campaignId: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
}

export interface QuerymarketplaceAffiliateReportSummaryArgs {
  campaignId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  showBy?: InputMaybe<AffiliateReportShowBy>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QuerymarketplaceAffiliateReportSummaryForInfluencerArgs {
  campaignId: Scalars['Int']['input'];
}

export interface QuerymarketplaceCampaignPostReportForInfluencerArgs {
  campaignId: Scalars['Int']['input'];
}

export interface QuerymarketplaceCampaignReportArgs {
  campaignId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<CampaignReportOrderBy>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QuerymarketplaceCampaignReportGridArgs {
  campaignId: Scalars['Int']['input'];
}

export interface QuerymarketplaceCampaignReportSummaryArgs {
  campaignId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QuerymarketplaceDraftPostArgs {
  draftId: Scalars['Int']['input'];
}

export interface QuerymarketplaceDraftPostsForInfluencerArgs {
  marketplaceId: Scalars['Int']['input'];
}

export interface QuerymarketplaceForInfluencerArgs {
  pk: Scalars['Int']['input'];
}

export interface QuerymarketplaceGoogleSlidesHistoryArgs {
  campaignId: Scalars['Int']['input'];
}

export interface QuerymarketplaceHashtagCloudArgs {
  advertiser?: InputMaybe<Scalars['String']['input']>;
  campaignCountry?: InputMaybe<Scalars['String']['input']>;
  campaignTitle?: InputMaybe<Scalars['String']['input']>;
  influencerCategory?: InputMaybe<Array<Scalars['Int']['input']>>;
  postRequirements?: InputMaybe<Scalars['String']['input']>;
  productInfo?: InputMaybe<Scalars['String']['input']>;
  socialMedia?: InputMaybe<CampaignSocialMediaType>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
}

export interface QuerymarketplaceJoinableAccountsArgs {
  campaignId: Scalars['Int']['input'];
}

export interface QuerymarketplaceJoinedAccountArgs {
  campaignId: Scalars['Int']['input'];
}

export interface QuerymarketplaceJoinedMethodsArgs {
  input: MarketplaceJoinedMethodsInput;
}

export interface QuerymarketplaceJoinedMethodsV2Args {
  input: MarketplaceJoinedMethodsInput;
}

export interface QuerymarketplacePostArgs {
  pk: Scalars['Int']['input'];
}

export interface QuerymarketplacePostCommentsArgs {
  limit: Scalars['Int']['input'];
  postId: Scalars['Int']['input'];
}

export interface QuerymarketplacePostHistoryArgs {
  draftId?: InputMaybe<Scalars['Int']['input']>;
  pk?: InputMaybe<Scalars['Int']['input']>;
}

export interface QuerymarketplacePostsNoRevenueArgs {
  marketplacePostIds: Array<Scalars['Int']['input']>;
}

export interface QuerymarketplaceTrackingArgs {
  marketplaceId: Scalars['Int']['input'];
}

export interface QuerymarketplaceTrackingPostUrlForInfluencerArgs {
  marketplaceId: Scalars['Int']['input'];
}

export interface QuerynetsuiteCustomerListArgs {
  keyword?: InputMaybe<Scalars['String']['input']>;
}

export interface QuerynetsuiteCustomerNameArgs {
  netsuiteId: Scalars['String']['input'];
}

export interface QuerynetsuiteVendorListArgs {
  keyword?: InputMaybe<Scalars['String']['input']>;
}

export interface QuerynetsuiteVendorNameArgs {
  netsuiteId: Scalars['String']['input'];
}

export interface QueryopenAiMessageArgs {
  runId: Scalars['String']['input'];
  sourceFrom?: InputMaybe<OpenAIRunSourceFrom>;
  threadSystemId: Scalars['Int']['input'];
}

export interface QuerypackageArgs {
  packageId: Scalars['Int']['input'];
}

export interface QuerypackageInfluencersArgs {
  keyword?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<InfluencerSearchSortBy>;
  packageId: Scalars['Int']['input'];
}

export interface QuerypackageProposalArgs {
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  packageId: Scalars['Int']['input'];
}

export interface QuerypackageProposalSummaryArgs {
  keyword?: InputMaybe<Scalars['String']['input']>;
  packageId: Scalars['Int']['input'];
}

export interface QuerypartnerAgencyArgs {
  pk: Scalars['Int']['input'];
}

export interface QuerypartnerAgencyBusinessAssociatesArgs {
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  partnerAgencyId: Scalars['Int']['input'];
}

export interface QuerypartnerAgencyBusinessAssociatesCountArgs {
  keyword?: InputMaybe<Scalars['String']['input']>;
  partnerAgencyId: Scalars['Int']['input'];
}

export interface QuerypartnerReconnectAnalyticsSocialAuthRedirectUrlArgs {
  callbackUrl: Scalars['String']['input'];
  provider: AnalyticsAuthSocialAccountType;
}

export interface QuerypartnerYoutubeAnalyticsPostArgs {
  partnerInfluencerAccountId: Scalars['Int']['input'];
  youtubeAnalyticsPostId: Scalars['Int']['input'];
}

export interface QuerypaymentInformationArgs {
  influencerId: Scalars['Int']['input'];
}

export interface QuerypaymentPackageStatusArgs {
  sessionId: Scalars['String']['input'];
}

export interface QuerypricingTableArgs {
  planType: PaymentPlanType;
}

export interface QueryrefreshProfileAccountAvailabilityArgs {
  input: RefreshProfileAccountAvailabilityInput;
}

export interface QueryrefreshTwitterAccountAvailabilityArgs {
  input: RefreshTwitterAccountAvailabilityInput;
}

export interface QueryreportForInfluencerArgs {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
}

export interface QueryrequestedBcaBrandsArgs {
  influencerId: Scalars['Int']['input'];
}

export interface QuerysearchEngCampaignsSelectInfluencersArgs {
  categoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  countryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  currentCampaignId: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
}

export interface QuerysearchEngCampaignsSelectInfluencersCountArgs {
  categoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  countryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  currentCampaignId: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
}

export interface QuerysearchPrivatePartnerInfluencersArgs {
  alreadySelectedInfluencers: Array<Scalars['Int']['input']>;
  keyword: Scalars['String']['input'];
  partnerId: Scalars['Int']['input'];
}

export interface QuerysearchPrivateTalentInfluencersArgs {
  alreadySelectedInfluencers: Array<Scalars['Int']['input']>;
  keyword: Scalars['String']['input'];
  talentAgencyId: Scalars['Int']['input'];
}

export interface QueryselectableBcaBrandsArgs {
  influencerId: Scalars['Int']['input'];
}

export interface QueryshopifyAuthRedirectUrlArgs {
  input: ShopifyAuthRedirectUrlInput;
}

export interface QuerysocialAuthRedirectUrlForInfluencerArgs {
  input: SocialAuthRedirectUrlForInfluencerInput;
}

export interface QuerysponsoredPostsArgs {
  influencerId: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SponsoredPostOrderBy>;
  socialAccountId: Scalars['Int']['input'];
  socialMedia: SocialAccountType;
}

export interface QuerysponsoredPostsCountArgs {
  influencerId: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  socialAccountId: Scalars['Int']['input'];
  socialMedia: SocialAccountType;
}

export interface QuerytalentAgencyArgs {
  pk: Scalars['Int']['input'];
}

export interface QuerytalentAgencyBusinessAssociateCompaniesArgs {
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  talentAgencyId: Scalars['Int']['input'];
}

export interface QuerytalentAgencyBusinessAssociateCompaniesCountArgs {
  keyword?: InputMaybe<Scalars['String']['input']>;
  talentAgencyId: Scalars['Int']['input'];
}

export interface QuerytalentAgencyReconnectAnalyticsSocialAuthRedirectUrlArgs {
  callbackUrl: Scalars['String']['input'];
  provider: AnalyticsAuthSocialAccountType;
}

export interface QuerytalentInfluencerDetailsForEditArgs {
  input: TalentInfluencerDetailsForEditInput;
}

export interface QuerytiktokAdAccountsArgs {
  tkSpecialCampaignId: Scalars['String']['input'];
}

export interface QuerytiktokAdAuthRedirectUrlArgs {
  callbackUrl: Scalars['String']['input'];
}

export interface QuerytiktokAdCampaignsArgs {
  adAccountSystemId: Scalars['String']['input'];
  tkSpecialCampaignId: Scalars['String']['input'];
}

export interface QuerytiktokAdGroupsArgs {
  adAccountSystemId: Scalars['String']['input'];
  adCampaignId: Scalars['String']['input'];
  tkSpecialCampaignId: Scalars['String']['input'];
}

export interface QuerytiktokAdvertiserPlanDataArgs {
  advertiserId: Scalars['Int']['input'];
}

export interface QuerytiktokBudgetAllocationArgs {
  advertiserId: Scalars['Int']['input'];
}

export interface QuerytiktokProfileAudienceArgs {
  pk: Scalars['Int']['input'];
  socialAccountId: Scalars['Int']['input'];
}

export interface QuerytiktokSpecialCampaignArgs {
  id: Scalars['Int']['input'];
}

export interface QuerytiktokSpecialCampaignForInfluencerSearchJobArgs {
  id: Scalars['Int']['input'];
}

export interface QuerytiktokSpecialCampaignForInfluencerYourJobArgs {
  id: Scalars['Int']['input'];
}

export interface QuerytiktokSpecialCampaignPostReportForInfluencerArgs {
  campaignId: Scalars['Int']['input'];
}

export interface QuerytiktokSpecialCampaignPostsArgs {
  campaignId: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  status?: InputMaybe<TikTokSpecialCampaignPostStatus>;
}

export interface QuerytiktokSpecialCampaignPostsCountArgs {
  campaignId: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<TikTokSpecialCampaignPostStatus>;
}

export interface QuerytiktokSpecialCampaignReportArgs {
  campaignId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<TiktokSpecialReportOrderBy>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QuerytiktokSpecialCampaignReportGridArgs {
  campaignId: Scalars['Int']['input'];
}

export interface QuerytiktokSpecialCampaignReportSummaryArgs {
  campaignId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QuerytiktokSpecialJoinableAccountsArgs {
  campaignId: Scalars['Int']['input'];
}

export interface QuerytiktokSpecialJoinedAccountArgs {
  campaignId: Scalars['Int']['input'];
}

export interface QuerytiktokSpecialPackageInfluencersArgs {
  categoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  countryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  genders?: InputMaybe<Array<Genders>>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<SocialAccountInfluencerOrderBy>;
  packageId: Scalars['Int']['input'];
}

export interface QuerytiktokSpecialPackageInfluencersCountArgs {
  categoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  countryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  genders?: InputMaybe<Array<Genders>>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  packageId: Scalars['Int']['input'];
}

export interface QuerytiktokSpecialPostArgs {
  pk: Scalars['Int']['input'];
}

export interface QuerytiktokSpecialPostHistoryArgs {
  pk: Scalars['Int']['input'];
}

export interface QuerytiktokTagPostDetailsArgs {
  postId: Scalars['Int']['input'];
}

export interface QuerytiktokUserHashtagPostListArgs {
  date: Scalars['Date']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<TiktokUserTagPostsSortInput>;
  tagId: Scalars['Int']['input'];
}

export interface QuerytiktokUserKeywordPostListArgs {
  date: Scalars['Date']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<TiktokUserTagPostsSortInput>;
  tagId: Scalars['Int']['input'];
}

export interface QuerytrendingFacebookInfluencersArgs {
  categoryIds: Array<Scalars['Int']['input']>;
  countryId?: InputMaybe<Scalars['String']['input']>;
  followerGrowthPeriod: FollowersGrowthPeriodEnum;
  limit: Scalars['Int']['input'];
  minFollowers?: InputMaybe<Scalars['Int']['input']>;
  offset: Scalars['Int']['input'];
}

export interface QuerytrendingInstagramInfluencersV2Args {
  categoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  countryId?: InputMaybe<Scalars['String']['input']>;
  followerGrowthPeriod: FollowersGrowthPeriodEnum;
  limit: Scalars['Int']['input'];
  minFollowers?: InputMaybe<Scalars['Int']['input']>;
  offset: Scalars['Int']['input'];
}

export interface QuerytrendingYoutubeInfluencersV2Args {
  categoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  countryId?: InputMaybe<Scalars['String']['input']>;
  followerGrowthPeriod: FollowersGrowthPeriodEnum;
  limit: Scalars['Int']['input'];
  minFollowers?: InputMaybe<Scalars['Int']['input']>;
  offset: Scalars['Int']['input'];
}

export interface QuerytwitterAnalyticsOverviewArgs {
  endDate: Scalars['Date']['input'];
  startDate: Scalars['Date']['input'];
  twitterAnalyticsAccountId: Scalars['Int']['input'];
}

export interface QuerytwitterAnalyticsPostArgs {
  twitterAnalyticsAccountId: Scalars['Int']['input'];
  twitterAnalyticsPostId: Scalars['Int']['input'];
}

export interface QuerytwitterAnalyticsPostsArgs {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy: TwitterAnalyticsPostsSortInput;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  twitterAnalyticsAccountId: Scalars['Int']['input'];
}

export interface QuerytwitterAnalyticsPostsByDateArgs {
  date: Scalars['Date']['input'];
  twitterAnalyticsAccountId: Scalars['Int']['input'];
}

export interface QuerytwitterAnalyticsPostsCountArgs {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  twitterAnalyticsAccountId: Scalars['Int']['input'];
}

export interface QuerytwitterAnalyticsPostsStatisticArgs {
  endDate: Scalars['Date']['input'];
  startDate: Scalars['Date']['input'];
  twitterAnalyticsAccountId: Scalars['Int']['input'];
}

export interface QueryupdatePaymentMethodLinkArgs {
  redirectUrl: Scalars['String']['input'];
}

export interface QueryuserArgs {
  pk: Scalars['Int']['input'];
}

export interface QuerywordCloudListArgs {
  influencerId: Scalars['Int']['input'];
  socialAccountId: Scalars['Int']['input'];
  socialMedia: SocialAccountType;
}

export interface QueryxhsAccountInterestArgs {
  pk: Scalars['Int']['input'];
  socialAccountId: Scalars['Int']['input'];
}

export interface QueryxhsProfileAudienceArgs {
  pk: Scalars['Int']['input'];
  socialAccountId: Scalars['Int']['input'];
}

export interface QueryxhsProfileEngagementInsightsArgs {
  pk: Scalars['Int']['input'];
  postType: XhsPostType;
  socialAccountId: Scalars['Int']['input'];
}

export interface QueryxhsProfilePostsArgs {
  pk: Scalars['Int']['input'];
  socialAccountId: Scalars['Int']['input'];
}

export interface QueryyoutubeAnalyticsAudienceArgs {
  endDate: Scalars['Date']['input'];
  startDate: Scalars['Date']['input'];
  youtubeAnalyticsAccountId: Scalars['Int']['input'];
}

export interface QueryyoutubeAnalyticsCommentsArgs {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
  youtubeAnalyticsAccountId: Scalars['Int']['input'];
}

export interface QueryyoutubeAnalyticsCommentsCountArgs {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  youtubeAnalyticsAccountId: Scalars['Int']['input'];
}

export interface QueryyoutubeAnalyticsCompareAccountCardsArgs {
  mainAccountId: Scalars['Int']['input'];
}

export interface QueryyoutubeAnalyticsCompareRelatedPostsArgs {
  compareAccountId: Scalars['Int']['input'];
  mainAccountId: Scalars['Int']['input'];
  postId: Scalars['Int']['input'];
  tag: Scalars['String']['input'];
}

export interface QueryyoutubeAnalyticsOverviewArgs {
  endDate: Scalars['Date']['input'];
  startDate: Scalars['Date']['input'];
  youtubeAnalyticsAccountId: Scalars['Int']['input'];
}

export interface QueryyoutubeAnalyticsPostArgs {
  youtubeAnalyticsAccountId: Scalars['Int']['input'];
  youtubeAnalyticsPostId: Scalars['Int']['input'];
}

export interface QueryyoutubeAnalyticsPostsArgs {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy: YoutubeAnalyticsPostsSortInput;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  youtubeAnalyticsAccountId: Scalars['Int']['input'];
}

export interface QueryyoutubeAnalyticsPostsByDateArgs {
  date: Scalars['Date']['input'];
  youtubeAnalyticsAccountId: Scalars['Int']['input'];
}

export interface QueryyoutubeAnalyticsPostsCountArgs {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  youtubeAnalyticsAccountId: Scalars['Int']['input'];
}

export interface QueryyoutubeAnalyticsPostsStatisticsArgs {
  endDate: Scalars['Date']['input'];
  startDate: Scalars['Date']['input'];
  youtubeAnalyticsAccountId: Scalars['Int']['input'];
}

export interface QueryyoutubeAnalyticsRelatedPostsArgs {
  tag: Scalars['String']['input'];
  youtubeAnalyticsAccountId: Scalars['Int']['input'];
  youtubeAnalyticsPostId: Scalars['Int']['input'];
}

export interface QueryyoutubeAnalyticsShortsArgs {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy: YoutubeAnalyticsPostsSortInput;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  youtubeAnalyticsAccountId: Scalars['Int']['input'];
}

export interface QueryyoutubeAnalyticsShortsCountArgs {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  youtubeAnalyticsAccountId: Scalars['Int']['input'];
}

export interface QueryyoutubeAnalyticsTagsTopRankingArgs {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy: YoutubeAnalyticsTagRankingSortInput;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  youtubeAnalyticsAccountId: Scalars['Int']['input'];
}

export interface QueryyoutubeAnalyticsTagsTopRankingCountArgs {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  youtubeAnalyticsAccountId: Scalars['Int']['input'];
}

export interface QueryyoutubeAnalyticsTrendPostByIdArgs {
  id: Scalars['Int']['input'];
}

export interface QueryyoutubeAnalyticsTrendsArgs {
  category: Scalars['Int']['input'];
  country: Scalars['String']['input'];
  date?: InputMaybe<Scalars['Date']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
}

export interface QueryyoutubeAnalyticsTrendsCountArgs {
  category: Scalars['Int']['input'];
  country: Scalars['String']['input'];
  date?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryyoutubeBrandAccountsArgs {
  influencerId: Scalars['Int']['input'];
  youtubeAccountSystemId: Scalars['Int']['input'];
}

export interface QueryyoutubeCmsChannelDetailArgs {
  id?: InputMaybe<Scalars['Int']['input']>;
  month?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryyoutubeCmsChannelInfoArgs {
  channelIds: Array<Scalars['String']['input']>;
}

export interface QueryyoutubeCmsChannelsForInfluencerArgs {
  maxDate?: InputMaybe<Scalars['Date']['input']>;
  minDate?: InputMaybe<Scalars['Date']['input']>;
  month?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryyoutubeCmsConfirmedAssetsSummaryArgs {
  keyword?: InputMaybe<Scalars['String']['input']>;
  month: Scalars['Date']['input'];
}

export interface QueryyoutubeCmsConfirmedChannelsForInfluencerArgs {
  month: Scalars['Date']['input'];
}

export interface QueryyoutubeCmsConfirmedChannelsSummaryArgs {
  keyword?: InputMaybe<Scalars['String']['input']>;
  month: Scalars['Date']['input'];
}

export interface QueryyoutubeCmsConfirmedMusicsForInfluencerArgs {
  month: Scalars['Date']['input'];
}

export interface QueryyoutubeCmsConfirmedRevenueForInfluencerArgs {
  month?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryyoutubeCmsConfirmedVideosForInfluencerArgs {
  month: Scalars['Date']['input'];
}

export interface QueryyoutubeCmsEstimateAssetsSummaryArgs {
  cmsChannelId?: InputMaybe<Scalars['Int']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  month?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryyoutubeCmsEstimateChannelsSummaryArgs {
  keyword?: InputMaybe<Scalars['String']['input']>;
  month?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryyoutubeCmsInvoiceCountArgs {
  keyword?: InputMaybe<Scalars['String']['input']>;
  month: Scalars['Date']['input'];
}

export interface QueryyoutubeCmsInvoiceListArgs {
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  month: Scalars['Date']['input'];
  offset: Scalars['Int']['input'];
}

export interface QueryyoutubeCmsInvoiceListForInfluencerArgs {
  year: Scalars['Int']['input'];
}

export interface QueryyoutubeCmsMusicsForInfluencerArgs {
  maxDate?: InputMaybe<Scalars['Date']['input']>;
  minDate?: InputMaybe<Scalars['Date']['input']>;
  month?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryyoutubeCmsRevenueForInfluencerArgs {
  maxDate?: InputMaybe<Scalars['Date']['input']>;
  minDate?: InputMaybe<Scalars['Date']['input']>;
  month?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryyoutubeCmsVideoAudienceArgs {
  month: Scalars['Date']['input'];
  videoId: Scalars['String']['input'];
}

export interface QueryyoutubeCmsVideoStatsArgs {
  month: Scalars['Date']['input'];
  videoId: Scalars['String']['input'];
}

export interface QueryyoutubeCmsVideosForInfluencerArgs {
  maxDate?: InputMaybe<Scalars['Date']['input']>;
  minDate?: InputMaybe<Scalars['Date']['input']>;
  month?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryyoutubeCompareAllPostsInDateArgs {
  date: Scalars['Date']['input'];
  mainAccountId: Scalars['Int']['input'];
}

export interface QueryyoutubeCompareOverviewArgs {
  endDate: Scalars['Date']['input'];
  mainAccountId: Scalars['Int']['input'];
  startDate: Scalars['Date']['input'];
}

export interface QueryyoutubeComparePostByIdArgs {
  compareAccountId: Scalars['Int']['input'];
  mainAccountId: Scalars['Int']['input'];
  postId: Scalars['Int']['input'];
}

export interface QueryyoutubeComparePostListArgs {
  compareAccountId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  mainAccountId: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy: YoutubeAnalyticsComparePostsSort;
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryyoutubeComparePostsArgs {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  mainAccountId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryyoutubeComparePostsCountArgs {
  compareAccountId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  mainAccountId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
}

export interface QueryyoutubeCompareTagRankingArgs {
  endDate: Scalars['Date']['input'];
  mainAccountId: Scalars['Int']['input'];
  startDate: Scalars['Date']['input'];
}

export interface QueryyoutubeMusicListArgs {
  categoryId?: InputMaybe<Scalars['Int']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
}

export enum QuerySocialAccountType {
  DOUYIN = 'DOUYIN',
  FACEBOOK = 'FACEBOOK',
  FACEBOOK_PAGE = 'FACEBOOK_PAGE',
  INSTAGRAM = 'INSTAGRAM',
  INSTAGRAM_BUSINESS = 'INSTAGRAM_BUSINESS',
  LINE = 'LINE',
  THREADS = 'THREADS',
  TIKTOK = 'TIKTOK',
  TWITTER = 'TWITTER',
  XHS = 'XHS',
  YOUTUBE = 'YOUTUBE',
}

export enum QuestionType {
  CHECKBOX = 'CHECKBOX',
  DROPDOWN = 'DROPDOWN',
  EMAIL = 'EMAIL',
  NAME = 'NAME',
  SHORT_ANSWER = 'SHORT_ANSWER',
}

export interface QuestionsInput {
  image?: InputMaybe<Scalars['String']['input']>;
  isRequired: Scalars['Boolean']['input'];
  options: Array<OptionInput>;
  order: Scalars['Int']['input'];
  questionType: QuestionType;
  title: Scalars['String']['input'];
}

export interface RateCardsInput {
  facebook?: InputMaybe<InfluencerRateCardPriceInput>;
  instagram?: InputMaybe<InfluencerRateCardPriceInput>;
  instagramStory?: InputMaybe<InfluencerRateCardPriceInput>;
  tiktok?: InputMaybe<InfluencerRateCardPriceInput>;
  twitter?: InputMaybe<InfluencerRateCardPriceInput>;
  youtube?: InputMaybe<InfluencerRateCardPriceInput>;
}

export interface ReSendBcaRequestInput {
  brandIgId: Scalars['Int']['input'];
  creatorIgId: Scalars['Int']['input'];
}

export interface ReSendBcaRequestPayload {
  ok: Scalars['Boolean']['output'];
}

export interface ReadCampaignIdentifier {
  campaignDetailType?: Maybe<MarketplaceCampaignDetailType>;
  campaignId: Scalars['Int']['output'];
  campaignType: CampaignIdentifierType;
  hasPostTracking: Scalars['Boolean']['output'];
}

export interface ReadPackage {
  companies?: Maybe<Array<Maybe<PackageCompany>>>;
  country: CountryName;
  /** Package Id */
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
}

export interface ReadPackageAccount {
  accounts?: Maybe<Scalars['Int']['output']>;
  campaignSelected?: Maybe<Scalars['Boolean']['output']>;
  companies?: Maybe<Array<PackageCompany>>;
  country: CountryName;
  /** Package Id */
  id: Scalars['Int']['output'];
  isDefault: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
}

export interface ReadPackageInfluencers {
  age: Scalars['Int']['output'];
  avatar: Scalars['String']['output'];
  averageEngagement: Scalars['Int']['output'];
  averageEngagementRate: Scalars['Float']['output'];
  averageEngagementViewsRate: Scalars['Float']['output'];
  categories: Array<Scalars['String']['output']>;
  country: CountryName;
  engagementPostedCount: Scalars['Int']['output'];
  engagementProposedCount: Scalars['Int']['output'];
  followersCount: Scalars['Int']['output'];
  gender: Genders;
  influencerId: Scalars['Int']['output'];
  /** none is empty, true is green-icon, false is without gree-icon */
  isAnySignedUpAccountForFacebook?: Maybe<Scalars['Boolean']['output']>;
  /** none is empty, true is green-icon, false is without gree-icon */
  isAnySignedUpAccountForInstagram?: Maybe<Scalars['Boolean']['output']>;
  /** none is empty, true is green-icon, false is without gree-icon */
  isAnySignedUpAccountForTiktok?: Maybe<Scalars['Boolean']['output']>;
  /** none is empty, true is green-icon, false is without gree-icon */
  isAnySignedUpAccountForTwitter?: Maybe<Scalars['Boolean']['output']>;
  /** none is empty, true is green-icon, false is without gree-icon */
  isAnySignedUpAccountForYoutube?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  socialAccounts: Array<ReadSocialAccountForPackage>;
  status: SocialAccountStatus;
  tags: Array<Scalars['String']['output']>;
}

export interface ReadSocialAccountForPackage {
  averageEngagement: Scalars['Int']['output'];
  averageEngagementRate: Scalars['Float']['output'];
  followersCount: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  socialAccountId: Scalars['Int']['output'];
  socialMedia: SocialAccountType;
}

export interface ReadTutorials {
  readTutorials: Array<TutorialName>;
}

export interface RechargeAdvertiserAccount {
  checkoutUrl?: Maybe<Scalars['String']['output']>;
  ok: Scalars['Boolean']['output'];
}

export interface RechargeAdvertiserAccountInput {
  amount: Scalars['Int']['input'];
  redirectUrl: Scalars['String']['input'];
}

export interface RecipientDetail {
  email: Scalars['String']['output'];
  fanId: Scalars['Long']['output'];
  name: Scalars['String']['output'];
  statuses: Array<EmailEventStatus>;
}

export interface RecipientItemInput {
  tagNames: Array<Scalars['String']['input']>;
  type: RecipientType;
}

export interface RecipientItemPayload {
  tagNames: Array<Scalars['String']['output']>;
  type: RecipientType;
}

export enum RecipientType {
  ALL = 'ALL',
  WITH_CUSTOMER_TAGS = 'WITH_CUSTOMER_TAGS',
}

export interface ReconnectTiktokAdAccount {
  ok: Scalars['Boolean']['output'];
}

export interface ReconnectTiktokAdAccountInput {
  adAccountId: Scalars['String']['input'];
}

export interface ReconnectedAccountForLinkBio {
  avatar: Scalars['String']['output'];
  followersCount?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  socialAccountType: SocialAccountType;
  url: Scalars['String']['output'];
}

export enum RedirectType {
  CONNECT = 'CONNECT',
  JOIN_CAMPAIGN = 'JOIN_CAMPAIGN',
  RECONNECT_IG = 'RECONNECT_IG',
  SIGNIN = 'SIGNIN',
  SIGNUP = 'SIGNUP',
  SIGNUP_CONNECT = 'SIGNUP_CONNECT',
  SIGNUP_ENABLED = 'SIGNUP_ENABLED',
  TALENT_SIGNIN = 'TALENT_SIGNIN',
  TALENT_SIGNUP = 'TALENT_SIGNUP',
}

export interface RefreshPasswordInput {
  email?: InputMaybe<Scalars['String']['input']>;
}

export interface RefreshPasswordPayload {
  ok?: Maybe<Scalars['Boolean']['output']>;
}

export interface RefreshProfileAccountAvailabilityInput {
  socialAccountId: Scalars['Int']['input'];
  socialMedia: SocialAccountType;
}

export interface RefreshProfileAccountAvailabilityPayload {
  status: RefreshProfileAvailabilityStatus;
}

export interface RefreshProfileAccountInput {
  socialAccountId: Scalars['Int']['input'];
  socialMedia: SocialAccountType;
}

export interface RefreshProfileAccountPayload {
  ok: Scalars['Boolean']['output'];
}

export enum RefreshProfileAvailabilityStatus {
  AVAILABLE = 'AVAILABLE',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  NO_DATA = 'NO_DATA',
}

export interface RefreshTwitterAccountAvailabilityInput {
  socialAccountId: Scalars['Int']['input'];
}

export interface RefreshTwitterAccountAvailabilityPayload {
  isRefreshAvailable: Scalars['Boolean']['output'];
}

export interface RefreshTwitterAccountInput {
  socialAccountId: Scalars['Int']['input'];
}

export interface RefreshTwitterAccountPayload {
  ok: Scalars['Boolean']['output'];
}

export interface Region {
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
}

export interface RegionName {
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
}

export interface RegisterPushToken {
  ok: Scalars['Boolean']['output'];
}

export interface RegisterPushTokenInput {
  influencerId: Scalars['Int']['input'];
  token: Scalars['String']['input'];
}

export interface RejectEngagementDraftPost {
  ok: Scalars['Boolean']['output'];
}

export interface RejectEngagementDraftPostInput {
  detailedReason?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  reason: Scalars['String']['input'];
}

export interface RejectMarketplaceCampaign {
  ok: Scalars['Boolean']['output'];
}

export interface RejectMarketplaceCampaignInput {
  marketplaceId: Scalars['Int']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
}

export interface RejectMarketplaceDraftPost {
  ok: Scalars['Boolean']['output'];
}

export interface RejectMarketplaceDraftPostInput {
  draftId: Scalars['Int']['input'];
  message: Scalars['String']['input'];
}

export interface RejectTikTokSpecialCampaign {
  ok: Scalars['Boolean']['output'];
}

export interface RejectTikTokSpecialCampaignInput {
  campaignId: Scalars['Int']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
}

export interface RemoveAllFormResponsesInput {
  formId: Scalars['Long']['input'];
}

export interface RemoveAllFormResponsesPayload {
  ok: Scalars['Boolean']['output'];
}

export interface RemoveAllSelectedInfluencers {
  ok: Scalars['Boolean']['output'];
}

export interface RemoveAllSelectedInfluencersInput {
  marketplaceId: Scalars['Int']['input'];
}

export interface RemoveCreatorStaffInput {
  creatorStaffId: Scalars['Int']['input'];
}

export interface RemoveCreatorStaffPayload {
  ok: Scalars['Boolean']['output'];
}

export interface RemoveEmailsInput {
  emailIds: Array<Scalars['Long']['input']>;
}

export interface RemoveEmailsPayload {
  ok: Scalars['Boolean']['output'];
}

export interface RemoveFansInput {
  fanIds: Array<Scalars['Long']['input']>;
}

export interface RemoveFansPayload {
  ok: Scalars['Boolean']['output'];
}

export interface RemoveFormResponseInput {
  submissionId: Scalars['Long']['input'];
}

export interface RemoveFormResponsePayload {
  ok: Scalars['Boolean']['output'];
}

export interface RemoveFormsInput {
  formIds: Array<Scalars['Long']['input']>;
}

export interface RemoveFormsPayload {
  ok: Scalars['Boolean']['output'];
}

export interface RemoveSelectedInfluencers {
  ok: Scalars['Boolean']['output'];
}

export interface RemoveSelectedInfluencersInput {
  influencerIds: Array<Scalars['Int']['input']>;
  marketplaceId: Scalars['Int']['input'];
}

export interface RemoveShopifyAccountInput {
  id: Scalars['Long']['input'];
}

export interface RemoveShopifyAccountPayload {
  ok: Scalars['Boolean']['output'];
}

export interface ReorderMarketplaceCampaigns {
  ok: Scalars['Boolean']['output'];
}

export interface ReportEngagementPostStatus {
  ok: Scalars['Boolean']['output'];
}

export interface ReportEngagementPostStatusInput {
  detailedReason?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  reason: Scalars['String']['input'];
}

export interface ReportForInfluencer {
  campaign: CampaignReportCampaignInfoForInfluencer;
  click?: Maybe<Scalars['Int']['output']>;
  comment?: Maybe<Scalars['Int']['output']>;
  conversion?: Maybe<Scalars['Int']['output']>;
  currency: Scalars['String']['output'];
  like?: Maybe<Scalars['Int']['output']>;
  postStatus?: Maybe<CampaignPostStatusForInfluencer>;
  revenue?: Maybe<Scalars['Float']['output']>;
  share?: Maybe<Scalars['Int']['output']>;
  view?: Maybe<Scalars['Int']['output']>;
}

export interface ReportMarketplacePostStatus {
  ok: Scalars['Boolean']['output'];
}

export interface ReportMarketplacePostStatusInput {
  detailedReason: Scalars['String']['input'];
  id: Scalars['Int']['input'];
  reason: Scalars['String']['input'];
}

export interface ReportTiktokSpecialPostStatus {
  ok: Scalars['Boolean']['output'];
}

export interface ReportTiktokSpecialPostStatusInput {
  detailedReason: Scalars['String']['input'];
  id: Scalars['Int']['input'];
  reason: Scalars['String']['input'];
}

export interface RequestDeleteAccount {
  ok: Scalars['Boolean']['output'];
}

export interface RequestDeleteAccountInput {
  comments?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  otherServices?: InputMaybe<Scalars['String']['input']>;
  reasons: Array<Scalars['String']['input']>;
}

export interface RequestGoogleSlideCustomisedReport {
  ok: Scalars['Boolean']['output'];
}

export interface RequestGoogleSlideReport {
  ok: Scalars['Boolean']['output'];
}

export interface RequestInfluencerProfilePptx {
  ok: Scalars['Boolean']['output'];
}

export interface RequestInfluencersProfilePptx {
  ok: Scalars['Boolean']['output'];
}

export interface RequestInfluencersSocialAccountPptx {
  ok: Scalars['Boolean']['output'];
}

export interface RequestInstagramUGCManagementPostInput {
  analyticsAccountId: Scalars['Int']['input'];
  content: Scalars['String']['input'];
  postSnsId: Scalars['String']['input'];
}

export interface RequestInstagramUGCManagementPostOutput {
  ok: Scalars['Boolean']['output'];
}

export interface RequestPackageProposalExcel {
  ok: Scalars['Boolean']['output'];
}

export interface RequestPackageProposalPptx {
  ok: Scalars['Boolean']['output'];
}

export interface RequestPackageSpreadsheet {
  ok: Scalars['Boolean']['output'];
}

export interface RequestPowerpointCustomisedReport {
  ok: Scalars['Boolean']['output'];
}

export interface RequestPowerpointReport {
  ok: Scalars['Boolean']['output'];
}

export interface RequestProposalExcel {
  ok: Scalars['Boolean']['output'];
}

export interface RequestProposalPptx {
  ok: Scalars['Boolean']['output'];
}

export interface RequestProposalSpreadsheet {
  ok: Scalars['Boolean']['output'];
}

export interface RequestUserProposalExcel {
  ok: Scalars['Boolean']['output'];
}

export interface RequestUserProposalPptx {
  ok: Scalars['Boolean']['output'];
}

export interface RequestedBCABrand {
  brand: RequestedBrandForBCA;
  instagramAccount: RequestedInstagramAccountForBCA;
  isResendable: Scalars['Boolean']['output'];
  status: BcaRequestStatus;
}

export interface RequestedBrandForBCA {
  /** Analytics Instagram Account System Id */
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
}

export interface RequestedInstagramAccountForBCA {
  /** Instagram Account System Id */
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
}

export interface ResendEmailForAdvertiser {
  ok?: Maybe<Scalars['Boolean']['output']>;
}

export interface ResendEmailForAdvertiserInput {
  email: Scalars['String']['input'];
}

export interface ResendInfluencerForgotPasswordEmailInput {
  email: Scalars['String']['input'];
}

export interface ResendInfluencerForgotPasswordEmailPayload {
  ok: Scalars['Boolean']['output'];
}

export interface ResendInfluencerSignUpEmailInput {
  email: Scalars['String']['input'];
}

export interface ResendInfluencerSignUpEmailPayload {
  ok: Scalars['Boolean']['output'];
}

export interface ResendPartnerEmails {
  ok: Scalars['Boolean']['output'];
}

export interface ResendPartnerEmailsInput {
  emails: Array<Scalars['String']['input']>;
}

export interface ResetPasswordAndLoginPayload {
  hash?: Maybe<Scalars['String']['output']>;
  refreshToken?: Maybe<Scalars['String']['output']>;
  role: UserRoles;
  token: Scalars['String']['output'];
  userId: Scalars['Int']['output'];
}

export interface ResetPasswordInput {
  password?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
}

export interface ResetPasswordPayload {
  ok?: Maybe<Scalars['Boolean']['output']>;
}

export interface ResolveChatInput {
  chatId: Scalars['String']['input'];
}

export interface ResolveChatPayload {
  ok: Scalars['Boolean']['output'];
}

export interface RichMessageInput {
  imageUrl: Scalars['String']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
}

export interface RichMessagePayload {
  imageUrl: Scalars['String']['output'];
  url?: Maybe<Scalars['String']['output']>;
}

export enum SearchJobFilterParticipationType {
  OPEN_CAMPAIGN = 'OPEN_CAMPAIGN',
  SELECTION_CAMPAIGN = 'SELECTION_CAMPAIGN',
}

export enum SearchJobFilterRevenueCondition {
  PER_CLICK = 'PER_CLICK',
  PER_COMMENT = 'PER_COMMENT',
  PER_COMMISSION_RATE = 'PER_COMMISSION_RATE',
  PER_CONVERSION = 'PER_CONVERSION',
  PER_LIKE = 'PER_LIKE',
  PER_POST = 'PER_POST',
  PER_SHARE = 'PER_SHARE',
  PER_VIEW = 'PER_VIEW',
}

export enum SearchJobFilterRevenueType {
  AFFILIATE_CAMPAIGN = 'AFFILIATE_CAMPAIGN',
  POST_CAMPAIGN = 'POST_CAMPAIGN',
}

export enum SearchMode {
  ITEM_SEARCH = 'ITEM_SEARCH',
  KEYWORD_SEARCH = 'KEYWORD_SEARCH',
}

export interface SelectableBCABrands {
  brands: Array<SelectableBrandForBCA>;
  instagramAccounts: Array<SelectableInstagramAccountForBCA>;
  isBcaAvailable: Scalars['Boolean']['output'];
}

export interface SelectableBrandForBCA {
  /** Analytics Instagram Account System Id */
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
}

export interface SelectableInstagramAccountForBCA {
  /** Instagram Account System Id */
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
}

export interface SelectableYoutubeAnalyticsChannel {
  avatar: Scalars['String']['output'];
  channelId: Scalars['String']['output'];
  channelName: Scalars['String']['output'];
  isUsed: Scalars['Boolean']['output'];
  subscribersCount: Scalars['Int']['output'];
}

/** An enumeration. */
export enum SelectedInfluencers {
  SELECTED = 'SELECTED',
  UNSELECTED = 'UNSELECTED',
}

export interface SelectedOauthPageAndIgAccount {
  igUserId: Scalars['String']['input'];
  pageId: Scalars['String']['input'];
}

export interface SelectedSocialAccounts {
  fbUserId?: InputMaybe<Scalars['String']['input']>;
  pageIds: Array<Scalars['String']['input']>;
  pagesAndIgAccounts: Array<SelectedOauthPageAndIgAccount>;
  twitterUserIds: Array<Scalars['String']['input']>;
  youTubeChannelIds: Array<Scalars['String']['input']>;
}

export interface SendBcaRequestInput {
  brandIgId: Scalars['Int']['input'];
  creatorIgId: Scalars['Int']['input'];
}

export interface SendBcaRequestPayload {
  ok: Scalars['Boolean']['output'];
}

export interface SendContact {
  ok: Scalars['Boolean']['output'];
}

export interface SendContactInput {
  enquiry: Scalars['String']['input'];
}

export interface SendGeminiMessage {
  message: GeminiMessage;
}

export interface SendGeminiMessageInput {
  /** thread system id */
  id: Scalars['Int']['input'];
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  sourceFrom?: InputMaybe<GeminiSourceFrom>;
}

export interface SendLineBroadcastMessageInput {
  isTest: Scalars['Boolean']['input'];
  lineBroadcastMessageId: Scalars['String']['input'];
}

export interface SendLineBroadcastMessagePayload {
  ok: Scalars['Boolean']['output'];
}

export interface SendMessageInput {
  chatId: Scalars['String']['input'];
  message: Scalars['String']['input'];
}

export interface SendMessagePayload {
  ok: Scalars['Boolean']['output'];
}

export interface SendOpenAIMessage {
  message: OpenAIMessage;
}

export interface SendOpenAIMessageInput {
  id: Scalars['Int']['input'];
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  sourceFrom?: InputMaybe<OpenAIRunSourceFrom>;
}

export interface ShopeeCustomCampaignInput {
  countryId: Scalars['String']['input'];
  itemId?: InputMaybe<Scalars['String']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  searchMode: SearchMode;
}

export interface ShopeeCustomCampaignPayload {
  ok: Scalars['Boolean']['output'];
}

export interface ShopifyAuthRedirectUrlInput {
  callbackUrl: Scalars['String']['input'];
  shopName: Scalars['String']['input'];
}

export interface ShopifyAuthRedirectUrlPayload {
  redirectUri: Scalars['String']['output'];
}

export enum ShopifyOrderPaymentStatus {
  AUTHORIZED = 'AUTHORIZED',
  PAID = 'PAID',
  PARTIALLY_PAID = 'PARTIALLY_PAID',
  PARTIALLY_REFUNDED = 'PARTIALLY_REFUNDED',
  PENDING = 'PENDING',
  REFUNDED = 'REFUNDED',
  VOIDED = 'VOIDED',
}

export enum ShopifyOrderShippingStatus {
  FULFILLED = 'FULFILLED',
  PARTIAL = 'PARTIAL',
  RESTOCKED = 'RESTOCKED',
}

export interface ShopifyValidateAuthInput {
  response: Scalars['String']['input'];
  shopName: Scalars['String']['input'];
}

export interface ShopifyValidateAuthPayload {
  ok: Scalars['Boolean']['output'];
}

export interface SignedUrlData {
  fileName: Scalars['String']['output'];
  signedUrl: Scalars['String']['output'];
}

export interface SimilarPost {
  image?: Maybe<Scalars['String']['output']>;
  influencerId: Scalars['Int']['output'];
  postId: Scalars['Int']['output'];
  postType: SocialPostType;
  socialAccountId: Scalars['Int']['output'];
  username: Scalars['String']['output'];
}

/**  TODO old API. don't forget to remove */
export interface SocialAccount {
  exclusive?: Maybe<Scalars['Boolean']['output']>;
  url?: Maybe<Scalars['String']['output']>;
}

export interface SocialAccountDescription {
  socialAccountName: Scalars['String']['output'];
  socialAccountType: SocialAccountType;
}

export interface SocialAccountForProfileV2 {
  avatar?: Maybe<Scalars['String']['output']>;
  followersCount?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  profile?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
}

export interface SocialAccountInfluencerAudience {
  age?: InputMaybe<FollowersAgeGroupInput>;
  gender: Genders;
  minPercentage?: InputMaybe<Scalars['Float']['input']>;
}

export interface SocialAccountInfluencerDemographicsAge {
  age: FollowersAgeGroupInput;
  minPercentage: Scalars['Float']['input'];
}

export interface SocialAccountInfluencerDemographicsGender {
  gender: Genders;
  minPercentage: Scalars['Float']['input'];
}

/** An enumeration. */
export enum SocialAccountInfluencerListSortField {
  AGE = 'AGE',
  AVG_LIKE = 'AVG_LIKE',
  AVG_REACH = 'AVG_REACH',
  AVG_VIEW = 'AVG_VIEW',
  CATEGORY = 'CATEGORY',
  COMMENT = 'COMMENT',
  COUNTRY = 'COUNTRY',
  DISLIKE = 'DISLIKE',
  ENGAGEMENT_POSTED_COUNT = 'ENGAGEMENT_POSTED_COUNT',
  ENGAGEMENT_PROPOSED_COUNT = 'ENGAGEMENT_PROPOSED_COUNT',
  GENDER = 'GENDER',
  ID = 'ID',
  INFLUENCER_ENGAGEMENT = 'INFLUENCER_ENGAGEMENT',
  INFLUENCER_ENGAGEMENT_RATE = 'INFLUENCER_ENGAGEMENT_RATE',
  INFLUENCER_FOLLOWERS = 'INFLUENCER_FOLLOWERS',
  LIKE = 'LIKE',
  MARKETPLACE_JOINED_COUNT = 'MARKETPLACE_JOINED_COUNT',
  MARKETPLACE_POSTED_COUNT = 'MARKETPLACE_POSTED_COUNT',
  ML_API = 'ML_API',
  REACH = 'REACH',
  REPLY = 'REPLY',
  RETWEET = 'RETWEET',
  SAVED = 'SAVED',
  SHARE = 'SHARE',
  SOCIAL_ACCOUNT_AVG_ENGAGEMENT = 'SOCIAL_ACCOUNT_AVG_ENGAGEMENT',
  SOCIAL_ACCOUNT_ENGAGEMENT = 'SOCIAL_ACCOUNT_ENGAGEMENT',
  SOCIAL_ACCOUNT_ENGAGEMENT_FOLLOWERS_RATE = 'SOCIAL_ACCOUNT_ENGAGEMENT_FOLLOWERS_RATE',
  SOCIAL_ACCOUNT_ENGAGEMENT_VIEWS_RATE = 'SOCIAL_ACCOUNT_ENGAGEMENT_VIEWS_RATE',
  SOCIAL_ACCOUNT_FOLLOWERS = 'SOCIAL_ACCOUNT_FOLLOWERS',
  STATUS = 'STATUS',
  VIEW = 'VIEW',
}

export enum SocialAccountInfluencerListSortFieldV2 {
  AVG_LIKE = 'AVG_LIKE',
  AVG_REACH = 'AVG_REACH',
  AVG_VIEW = 'AVG_VIEW',
  CREATED = 'CREATED',
  RELEVANT = 'RELEVANT',
  SOCIAL_ACCOUNT_ENGAGEMENT_FOLLOWERS_RATE = 'SOCIAL_ACCOUNT_ENGAGEMENT_FOLLOWERS_RATE',
  SOCIAL_ACCOUNT_ENGAGEMENT_VIEWS_RATE = 'SOCIAL_ACCOUNT_ENGAGEMENT_VIEWS_RATE',
  SOCIAL_ACCOUNT_FOLLOWERS = 'SOCIAL_ACCOUNT_FOLLOWERS',
}

export interface SocialAccountInfluencerOrderBy {
  field?: InputMaybe<SocialAccountInfluencerListSortField>;
  order?: InputMaybe<ORDER>;
}

export interface SocialAccountInfluencerOrderByV2 {
  field?: InputMaybe<SocialAccountInfluencerListSortFieldV2>;
  order?: InputMaybe<Order>;
}

export interface SocialAccountPairInput {
  influencerId: Scalars['Int']['input'];
  socialAccountId?: InputMaybe<Scalars['Int']['input']>;
  socialType?: InputMaybe<SocialAccountType>;
}

export interface SocialAccountPermissionsInput {
  type: SocialAccountType;
}

export interface SocialAccountPopularPost {
  caption: Scalars['String']['output'];
  postId: Scalars['ID']['output'];
  thumbNail?: Maybe<Scalars['String']['output']>;
  type: SocialPostType;
}

export enum SocialAccountStatus {
  SCRAPING = 'SCRAPING',
  SIGNED_UP = 'SIGNED_UP',
}

export enum SocialAccountType {
  DOUYIN = 'DOUYIN',
  FACEBOOK = 'FACEBOOK',
  FACEBOOK_PAGE = 'FACEBOOK_PAGE',
  INSTAGRAM = 'INSTAGRAM',
  LINE = 'LINE',
  THREADS = 'THREADS',
  TIKTOK = 'TIKTOK',
  TWITTER = 'TWITTER',
  XHS = 'XHS',
  YOUTUBE = 'YOUTUBE',
}

export interface SocialAccountV2 {
  isAnySignedUpAccount: Scalars['Boolean']['output'];
}

export interface SocialAuthCheckFacebookTokenPayload {
  needReconnect: Scalars['Boolean']['output'];
}

export interface SocialAuthConnectableFacebookPageAnalyticsInput {
  callbackUrl: Scalars['String']['input'];
  response: Scalars['String']['input'];
}

export interface SocialAuthConnectableFacebookPageAnalyticsPayload {
  account?: Maybe<ConnectableFacebookPageMainAccount>;
  pages: Array<ConnectableFacebookPageAccount>;
}

export interface SocialAuthConnectableInstagramAccountsAnalyticsInput {
  callbackUrl: Scalars['String']['input'];
  response: Scalars['String']['input'];
}

/**  Stores Instagram accounts that can be connected based on a Facebook account */
export interface SocialAuthConnectableInstagramAccountsAnalyticsPayload {
  accounts: Array<ConnectableOauthPageAndIgAccount>;
}

export interface SocialAuthConnectableTwitterAccountAnalyticsInput {
  callbackUrl: Scalars['String']['input'];
  response: Scalars['String']['input'];
}

export interface SocialAuthConnectableTwitterAccountAnalyticsOutput {
  avatar: Scalars['String']['output'];
  followersCount: Scalars['Int']['output'];
  isUsed: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  twitterUserId: Scalars['String']['output'];
  username: Scalars['String']['output'];
}

/**  socialAuthForJoinCampaign */
export interface SocialAuthForJoinCampaignInput {
  callbackUrl?: InputMaybe<Scalars['String']['input']>;
  provider: AuthSocialAccountType;
  response: Scalars['String']['input'];
  selectedSocialAccountId?: InputMaybe<Scalars['ID']['input']>;
}

export interface SocialAuthForJoinCampaignPayload {
  ok: Scalars['Boolean']['output'];
}

export interface SocialAuthReConnectInstagramInput {
  callbackUrl: Scalars['String']['input'];
  response: Scalars['String']['input'];
}

export interface SocialAuthReConnectInstagramPayload {
  token: Scalars['String']['output'];
}

/**
 *  `response`: response from Facebook API. Used to get the access token
 *  `callbackUrl`: callback URL from Facebook API. Used to get the access token
 *  `accountId`: analytics account id.
 *  `fbPageId`: id of the Facebook page the reconnected account is connected to.
 */
export interface SocialAuthReconnectFacebookPageAccountAnalyticsInput {
  accountId: Scalars['Int']['input'];
  callbackUrl: Scalars['String']['input'];
  fbPageId: Scalars['String']['input'];
  response: Scalars['String']['input'];
}

export interface SocialAuthReconnectFacebookPageAccountAnalyticsPayload {
  ok: Scalars['Boolean']['output'];
}

/**
 *  `businessAccountId`: Instagram business account id.
 *  `response`: response from Facebook API. Used to get the access token
 *  `callbackUrl`: callback URL from Facebook API. Used to get the access token
 *  `redirectType`: type of redirect. We have many pages to redirect to when Facebook is done with processing our requests.
 *   We can go back to "account list", "add accounts" page, and etc. Used to get the access token
 *  `accountId`: analytics account id.
 *  `username`: reconnected Instagram account username.
 *  `fbPageId`: id of the Facebook page the reconnected account is connected to.
 */
export interface SocialAuthReconnectInstagramAccountAnalyticsInput {
  accountId: Scalars['Int']['input'];
  businessAccountId: Scalars['String']['input'];
  callbackUrl: Scalars['String']['input'];
  fbPageId?: InputMaybe<Scalars['String']['input']>;
  redirectType: AnalyticsRedirectType;
  response: Scalars['String']['input'];
  username: Scalars['String']['input'];
}

export interface SocialAuthReconnectInstagramAccountAnalyticsPayload {
  ok: Scalars['Boolean']['output'];
}

export interface SocialAuthReconnectTwitterAccountAnalyticsInput {
  callbackUrl: Scalars['String']['input'];
  response: Scalars['String']['input'];
}

export interface SocialAuthReconnectTwitterAccountAnalyticsPayload {
  ok: Scalars['Boolean']['output'];
}

/**
 *  `channelId`: id of the YouTube channel the reconnected account is connected to.
 *  `callbackUrl`: callback URL from Google API. Used to get the access token
 *  `response`: response from Google API. Used to get the access token
 */
export interface SocialAuthReconnectYouTubeAccountAnalyticsInput {
  callbackUrl: Scalars['String']['input'];
  channelId: Scalars['String']['input'];
  response: Scalars['String']['input'];
}

export interface SocialAuthReconnectYouTubeAccountAnalyticsPayload {
  ok: Scalars['Boolean']['output'];
}

export interface SocialAuthRedirectUrlForInfluencerInput {
  callbackUrl: Scalars['String']['input'];
  provider: AuthSocialAccountType;
}

export interface SocialAuthRedirectUrlForInfluencerPayload {
  redirectUri: Scalars['String']['output'];
}

/**  Talent & Partner */
export interface SocialAuthTalentSignInFacebookInput {
  callbackUrl: Scalars['String']['input'];
  influencerId?: InputMaybe<Scalars['Int']['input']>;
  response: Scalars['String']['input'];
}

export interface SocialAuthTalentSignInFacebookPayload {
  fbAvatar: Scalars['String']['output'];
  fbFollowersCount: Scalars['Int']['output'];
  fbName: Scalars['String']['output'];
  fbUserId: Scalars['String']['output'];
  pages: Array<ConnectableOauthPageAndIgAccountForSignInFacebook>;
  status: SocialAccountStatus;
}

export interface SocialAuthTalentSignInInstagramInput {
  callbackUrl: Scalars['String']['input'];
  influencerId?: InputMaybe<Scalars['Int']['input']>;
  response: Scalars['String']['input'];
  username?: InputMaybe<Scalars['String']['input']>;
}

export interface SocialAuthTalentSignInInstagramPayload {
  facebook: ConnectableOauthFacebookAccountForTalentSignIn;
  pagesAndIgAccounts: Array<ConnectableOauthPageAndIgAccountForTalentSignIn>;
}

export interface SocialAuthTalentSignInTwitterInput {
  callbackUrl: Scalars['String']['input'];
  response: Scalars['String']['input'];
  screenName?: InputMaybe<Scalars['String']['input']>;
}

export interface SocialAuthTalentSignInTwitterPayload {
  status: SocialAccountStatus;
  twName: Scalars['String']['output'];
  twScreenName: Scalars['String']['output'];
  twUserId: Scalars['String']['output'];
}

export interface SocialAuthTalentSignInYouTubeInput {
  callbackUrl: Scalars['String']['input'];
  channelId?: InputMaybe<Scalars['String']['input']>;
  response: Scalars['String']['input'];
}

export interface SocialAuthTalentSignInYouTubePayload {
  channels: Array<ConnectableOauthYouTubeAccountForTalentSignIn>;
}

export enum SocialMediaTypeForRateCard {
  FACEBOOK = 'FACEBOOK',
  INSTAGRAM = 'INSTAGRAM',
  INSTAGRAM_STORY = 'INSTAGRAM_STORY',
  TIKTOK = 'TIKTOK',
  TWITTER = 'TWITTER',
  YOUTUBE = 'YOUTUBE',
}

export enum SocialPostType {
  DOUYIN = 'DOUYIN',
  FACEBOOK = 'FACEBOOK',
  FACEBOOK_PAGE = 'FACEBOOK_PAGE',
  INSTAGRAM = 'INSTAGRAM',
  INSTAGRAM_REEL = 'INSTAGRAM_REEL',
  INSTAGRAM_STORY = 'INSTAGRAM_STORY',
  THREADS = 'THREADS',
  TIKTOK = 'TIKTOK',
  TWITTER = 'TWITTER',
  XHS = 'XHS',
  YOUTUBE = 'YOUTUBE',
}

export interface SponsoredPostOrderBy {
  field?: InputMaybe<SponsoredPostSortField>;
  order?: InputMaybe<ORDER>;
}

/** An enumeration. */
export enum SponsoredPostSortField {
  COMMENT = 'COMMENT',
  ENGAGEMENT = 'ENGAGEMENT',
  LIKE = 'LIKE',
  POST_DATE = 'POST_DATE',
  REACH = 'REACH',
  SAVED = 'SAVED',
  SHARE = 'SHARE',
  VIEW = 'VIEW',
}

export interface StaffComment {
  content: Scalars['String']['output'];
  date: Scalars['String']['output'];
}

export type StateUpdateEvent = ChatEvent & {
  id: Scalars['String']['output'];
  timestamp: Scalars['String']['output'];
};

export interface StripeDeepLink {
  url: Scalars['String']['output'];
}

export interface SubmitFormInput {
  id: Scalars['Long']['input'];
  questions: Array<SubmitQuestion>;
  recaptchaResponse: Scalars['String']['input'];
}

export interface SubmitFormInternalInput {
  id: Scalars['Long']['input'];
  questions: Array<SubmitQuestionInternal>;
}

export interface SubmitFormInternalPayload {
  ok: Scalars['Boolean']['output'];
}

export interface SubmitFormPayload {
  ok: Scalars['Boolean']['output'];
}

export interface SubmitQuestion {
  answeredOptionIds: Array<Scalars['Long']['input']>;
  answeredTexts: Array<Scalars['String']['input']>;
  questionId: Scalars['Long']['input'];
}

export interface SubmitQuestionInternal {
  answeredOptionIds: Array<Scalars['Long']['input']>;
  answeredTexts: Array<Scalars['String']['input']>;
  questionId: Scalars['Long']['input'];
}

export interface SubscribeAnalyticsPlan {
  ok: Scalars['Boolean']['output'];
}

export interface SubscribeAnalyticsPlanInput {
  /** advertiser's system id */
  advertiserId: Scalars['Int']['input'];
  /** the selected plan */
  plan: AnalyticsSubscriptionPlanType;
}

export interface SubscribeAnalyticsTrialPlan {
  ok: Scalars['Boolean']['output'];
}

export interface SubscriptionAvailableFeatures {
  compareEnabled: Scalars['Boolean']['output'];
  dashboardEnabled: Scalars['Boolean']['output'];
  hashtagsEnabled: Scalars['Boolean']['output'];
  interactionEnabled: Scalars['Boolean']['output'];
}

export interface SubscriptionCapabilities {
  maxCompareAccounts: Scalars['Int']['output'];
  maxHashtags: Scalars['Int']['output'];
}

export interface SubscriptionPlan {
  analytics?: Maybe<SubscriptionPlanDetail>;
  marketplace?: Maybe<SubscriptionPlanDetail>;
}

export interface SubscriptionPlanDetail {
  currency: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  expiredAt?: Maybe<Scalars['DateTime']['output']>;
  /** price id */
  id?: Maybe<Scalars['Int']['output']>;
  isCanceled: Scalars['Boolean']['output'];
  isExpired: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Float']['output'];
}

export interface SubscriptionPlanForAdvertiserUserSettings {
  availableFeatures: SubscriptionAvailableFeatures;
  capabilities: SubscriptionCapabilities;
  id: Scalars['Int']['output'];
  type: AnalyticsSubscriptionPlanType;
}

export interface SudoLimitedLogin {
  token: Scalars['String']['output'];
}

export interface SudoLimitedLoginInput {
  userId: Scalars['Int']['input'];
}

export interface SwitchInfluencerForStaffInput {
  influencerId: Scalars['Int']['input'];
}

export interface SwitchInfluencerForStaffPayload {
  token: Scalars['String']['output'];
}

export interface SwitchRoleToAdmin {
  ok: Scalars['Boolean']['output'];
}

export interface SwitchRoleToAdminInput {
  countryId: Scalars['String']['input'];
  email: Scalars['String']['input'];
  id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<CustomPermissionsInput>;
}

export interface SwitchRoleToAdvertiser {
  ok: Scalars['Boolean']['output'];
}

export interface SwitchRoleToAdvertiserInput {
  advertiserId: Scalars['Int']['input'];
  countryId: Scalars['String']['input'];
  email: Scalars['String']['input'];
  id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  notificationSetting: Scalars['Boolean']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<CustomPermissionsInput>;
}

export interface SwitchRoleToAgency {
  ok: Scalars['Boolean']['output'];
}

export interface SwitchRoleToAgencyInput {
  agencyId: Scalars['Int']['input'];
  countryId: Scalars['String']['input'];
  email: Scalars['String']['input'];
  id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<CustomPermissionsInput>;
}

export interface SwitchRoleToPartner {
  ok: Scalars['Boolean']['output'];
}

export interface SwitchRoleToPartnerInput {
  countryId: Scalars['String']['input'];
  email: Scalars['String']['input'];
  id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  partnerId: Scalars['Int']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<CustomPermissionsInput>;
}

export interface SwitchRoleToStaff {
  ok: Scalars['Boolean']['output'];
}

export interface SwitchRoleToStaffInput {
  advertiserIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  countryId: Scalars['String']['input'];
  email: Scalars['String']['input'];
  id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<CustomPermissionsInput>;
}

export interface SwitchRoleToTalentAgency {
  ok: Scalars['Boolean']['output'];
}

export interface SwitchRoleToTalentAgencyInput {
  countryId: Scalars['String']['input'];
  email: Scalars['String']['input'];
  id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<CustomPermissionsInput>;
  talentAgencyId: Scalars['Int']['input'];
}

export enum TTCMStatus {
  APPROVED = 'APPROVED',
  INVITED = 'INVITED',
  NOT_INVITED = 'NOT_INVITED',
  REJECTED = 'REJECTED',
}

export interface TalentAgency {
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
}

export interface TalentAgencyOrPartner {
  /** unique result index used for FE purpose */
  id: Scalars['Int']['output'];
  /** id of Talent Agency or Partner */
  idOfTalentOrPartner: Scalars['Int']['output'];
  /** name of Talent Agency or Partner */
  name: Scalars['String']['output'];
  /** TALENT_AGENCY or PARTNER */
  role: UserRoles;
}

export enum TalentAgencyReconnectAnalyticsRedirectType {
  ANY_CREATOR_INFLUENCER_PROFILE_CONNECT = 'ANY_CREATOR_INFLUENCER_PROFILE_CONNECT',
  ANY_TAG_INFLUENCER_PROFILE_CONNECT = 'ANY_TAG_INFLUENCER_PROFILE_CONNECT',
}

/**  Stores a redirect URL that FE uses to reconnect talent influencer accounts */
export interface TalentAgencyReconnectAnalyticsSocialAuthRedirectUrlPayload {
  redirectUri: Scalars['String']['output'];
}

export interface TalentAgencyUserWithRole {
  companyName?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  role: UserRoles;
}

export interface TalentAgencyWithCompanies {
  advertiserIds: Array<Scalars['Int']['output']>;
  agencyIds: Array<Scalars['Int']['output']>;
  cmsMarginRate?: Maybe<Scalars['Float']['output']>;
  companyUrl?: Maybe<Scalars['String']['output']>;
  country?: Maybe<CountryName>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  netsuiteId?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  privateInfluencers: Array<PrivateTalentInfluencer>;
}

export interface TalentAgencyWithNSVerification {
  companyUrl?: Maybe<Scalars['String']['output']>;
  country?: Maybe<CountryName>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  netsuiteId?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  users: Scalars['Int']['output'];
  verifiedStatus?: Maybe<NetSuiteVerifiedStatus>;
}

export interface TalentInfluencerDetailsForEditInput {
  influencerId: Scalars['Int']['input'];
}

export interface TalentInfluencerDetailsForEditPayload {
  category: Array<Category>;
  connectedFacebook?: Maybe<ConnectedFacebook>;
  connectedFacebookPages: Array<ConnectedPage>;
  connectedInstagrams: Array<ConnectedPageAndIgAccount>;
  connectedTwitters: Array<ConnectedTwitter>;
  connectedYouTubes: Array<ConnectedYouTube>;
  country: Country;
  dateOfBirth?: Maybe<Scalars['String']['output']>;
  defaultEngagementSelectionReason: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
  gender: Genders;
  internalMemo: Scalars['String']['output'];
  introduce: Scalars['String']['output'];
  isProAccount: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Region>;
  tags: Array<Scalars['String']['output']>;
}

export interface TextComponentInput {
  fontSize: FontSize;
  text: Scalars['String']['input'];
  weighted: Scalars['Boolean']['input'];
}

export interface TextComponentPayload {
  fontSize: FontSize;
  text: Scalars['String']['output'];
  weighted: Scalars['Boolean']['output'];
}

export interface ThreadsInfluencer {
  age?: Maybe<Scalars['Int']['output']>;
  avatar?: Maybe<Scalars['String']['output']>;
  averageLikes?: Maybe<Scalars['Float']['output']>;
  categories: Array<CategoryName>;
  comments?: Maybe<Scalars['Int']['output']>;
  country: CountryName;
  engagementPostedCount?: Maybe<Scalars['Int']['output']>;
  engagementProposedCount?: Maybe<Scalars['Int']['output']>;
  gender: Genders;
  /** Influencer Id */
  id: Scalars['Int']['output'];
  influencerEngagement?: Maybe<Scalars['Int']['output']>;
  influencerEngagementRate?: Maybe<Scalars['Float']['output']>;
  influencerFollowers?: Maybe<Scalars['Int']['output']>;
  isBrandAccount: Scalars['Boolean']['output'];
  likes?: Maybe<Scalars['Int']['output']>;
  marketplaceJoinedCount?: Maybe<Scalars['Int']['output']>;
  marketplacePostedCount?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  popularPosts?: Maybe<Array<SocialAccountPopularPost>>;
  socialAccountAverageEngagement?: Maybe<Scalars['Int']['output']>;
  socialAccountEngagement?: Maybe<Scalars['Int']['output']>;
  socialAccountEngagementFollowersRate?: Maybe<Scalars['Float']['output']>;
  socialAccountFollowers?: Maybe<Scalars['Int']['output']>;
  /** Social Account System Id */
  socialAccountId: Scalars['Int']['output'];
  /** Social Account Status */
  socialAccountStatus: SocialAccountStatus;
}

export interface TikTokAdChartReport {
  adCost: Scalars['Float']['output'];
  adImpressions: Scalars['Int']['output'];
  date: Scalars['Date']['output'];
}

/** An enumeration. */
export enum TikTokAdReportOrderField {
  AD_BUDGET = 'AD_BUDGET',
  AD_CLICKS = 'AD_CLICKS',
  AD_COMMENTS = 'AD_COMMENTS',
  AD_COST = 'AD_COST',
  AD_COST_PER_1000_REACHED = 'AD_COST_PER_1000_REACHED',
  AD_COST_PER_RESULT = 'AD_COST_PER_RESULT',
  AD_CPC = 'AD_CPC',
  AD_CPM = 'AD_CPM',
  AD_CTR = 'AD_CTR',
  AD_ENGAGEMENTS = 'AD_ENGAGEMENTS',
  AD_ER = 'AD_ER',
  AD_FOLLOWS = 'AD_FOLLOWS',
  AD_FREQUENCY = 'AD_FREQUENCY',
  AD_IMPRESSIONS = 'AD_IMPRESSIONS',
  AD_LIKES = 'AD_LIKES',
  AD_REACH = 'AD_REACH',
  AD_RESULT = 'AD_RESULT',
  AD_RESULT_RATE = 'AD_RESULT_RATE',
  AD_SHARES = 'AD_SHARES',
  AD_STATUS = 'AD_STATUS',
  AVERAGE_VIDEO_PLAY = 'AVERAGE_VIDEO_PLAY',
  AVERAGE_VIDEO_PLAY_PER_USER = 'AVERAGE_VIDEO_PLAY_PER_USER',
  INTERACTIVE_ADD_ON_DESTINATION_CLICKS = 'INTERACTIVE_ADD_ON_DESTINATION_CLICKS',
  INTERACTIVE_ADD_ON_IMPRESSIONS = 'INTERACTIVE_ADD_ON_IMPRESSIONS',
  VIDEO_PLAY_ACTIONS = 'VIDEO_PLAY_ACTIONS',
  VIDEO_VIEWS_P25 = 'VIDEO_VIEWS_P25',
  VIDEO_VIEWS_P50 = 'VIDEO_VIEWS_P50',
  VIDEO_VIEWS_P75 = 'VIDEO_VIEWS_P75',
  VIDEO_VIEWS_P100 = 'VIDEO_VIEWS_P100',
  VIDEO_WATCHED_2S = 'VIDEO_WATCHED_2S',
  VIDEO_WATCHED_6S = 'VIDEO_WATCHED_6S',
}

export interface TikTokAdReportSummary {
  adBudget?: Maybe<Scalars['Float']['output']>;
  adClicks?: Maybe<Scalars['Int']['output']>;
  adComments?: Maybe<Scalars['Int']['output']>;
  adCost?: Maybe<Scalars['Float']['output']>;
  adCostPer1000Reached?: Maybe<Scalars['Float']['output']>;
  adCostPerResult?: Maybe<Scalars['Float']['output']>;
  adCpc?: Maybe<Scalars['Float']['output']>;
  adCpm?: Maybe<Scalars['Float']['output']>;
  adCtr?: Maybe<Scalars['Float']['output']>;
  adEngagements?: Maybe<Scalars['Int']['output']>;
  adEr?: Maybe<Scalars['Float']['output']>;
  adFollows?: Maybe<Scalars['Int']['output']>;
  adFrequency?: Maybe<Scalars['Int']['output']>;
  adImpressions?: Maybe<Scalars['Int']['output']>;
  adLikes?: Maybe<Scalars['Int']['output']>;
  adReach?: Maybe<Scalars['Int']['output']>;
  adResult?: Maybe<Scalars['Int']['output']>;
  adResultRate?: Maybe<Scalars['Float']['output']>;
  adShares?: Maybe<Scalars['Int']['output']>;
  averageVideoPlay?: Maybe<Scalars['Int']['output']>;
  averageVideoPlayPerUser?: Maybe<Scalars['Int']['output']>;
  interactiveAddOnDestinationClicks?: Maybe<Scalars['Int']['output']>;
  interactiveAddOnImpressions?: Maybe<Scalars['Int']['output']>;
  videoPlayActions?: Maybe<Scalars['Int']['output']>;
  videoViewsP25?: Maybe<Scalars['Int']['output']>;
  videoViewsP50?: Maybe<Scalars['Int']['output']>;
  videoViewsP75?: Maybe<Scalars['Int']['output']>;
  videoViewsP100?: Maybe<Scalars['Int']['output']>;
  videoWatched2s?: Maybe<Scalars['Int']['output']>;
  videoWatched6s?: Maybe<Scalars['Int']['output']>;
}

/** An enumeration. */
export enum TikTokAdReportType {
  AD = 'AD',
  AD_CAMPAIGN = 'AD_CAMPAIGN',
  AD_GROUP = 'AD_GROUP',
}

/** An enumeration. */
export enum TikTokAdStatus {
  DELETE = 'DELETE',
  DISABLE = 'DISABLE',
  ENABLE = 'ENABLE',
}

/** An enumeration. */
export enum TikTokAdvertiserPlanFilter {
  BEGINNER = 'BEGINNER',
  ENTERPRISE = 'ENTERPRISE',
  NO_PLAN = 'NO_PLAN',
  STANDARD = 'STANDARD',
}

/** An enumeration. */
export enum TikTokCampaignType {
  NORMAL = 'NORMAL',
  TTCM = 'TTCM',
}

export interface TikTokProductCategory {
  id: Scalars['Int']['output'];
  name: TikTokProductCategoryName;
  packageId: Scalars['Int']['output'];
}

/** An enumeration. */
export enum TikTokProductCategoryName {
  BEAUTY = 'BEAUTY',
  ELECTRONIC = 'ELECTRONIC',
  FASHION = 'FASHION',
  FMCG = 'FMCG',
  LIFESTYLE = 'LIFESTYLE',
  OTHERS = 'OTHERS',
}

export interface TikTokSpecialAdPostInfo {
  adAccountSystemId: Scalars['Int']['output'];
  adCampaignId: Scalars['String']['output'];
  adGroupId: Scalars['String']['output'];
  adName: Scalars['String']['output'];
  authPostCode: Scalars['String']['output'];
  callToAction: Scalars['Boolean']['output'];
  landingUrl?: Maybe<Scalars['String']['output']>;
}

export interface TikTokSpecialCampaign {
  advertiser: AdvertiserName;
  endDate?: Maybe<Scalars['Date']['output']>;
  id: Scalars['Int']['output'];
  joinedInfluencersCount: Scalars['Int']['output'];
  maxNumberInfluencers: Scalars['Int']['output'];
  plan: PaymentPlanName;
  preLaunchDate?: Maybe<Scalars['Date']['output']>;
  startDate?: Maybe<Scalars['Date']['output']>;
  status: TikTokSpecialCampaignStatus;
  title: Scalars['String']['output'];
}

export interface TikTokSpecialCampaignChartReport {
  cost: Scalars['Float']['output'];
  date: Scalars['Date']['output'];
  engagement: Scalars['Int']['output'];
}

export interface TikTokSpecialCampaignDetail {
  advertiser: AdvertiserName;
  campaignCategoryId?: Maybe<Scalars['Int']['output']>;
  country: CountryName;
  createdDate: Scalars['Date']['output'];
  endDate?: Maybe<Scalars['Date']['output']>;
  hashtags: Array<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  materialUrl?: Maybe<Scalars['String']['output']>;
  materials: Array<Scalars['String']['output']>;
  preLaunchDate?: Maybe<Scalars['Date']['output']>;
  priceItem: PricingTableItem;
  productUrl?: Maybe<Scalars['String']['output']>;
  rejectedDate?: Maybe<Scalars['Date']['output']>;
  rejectedReason?: Maybe<Scalars['String']['output']>;
  requirement?: Maybe<Scalars['String']['output']>;
  sampleUrl?: Maybe<Scalars['String']['output']>;
  serviceInformation?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['Date']['output']>;
  status: TikTokSpecialCampaignStatus;
  thumbnails: Array<Scalars['String']['output']>;
  title: Scalars['String']['output'];
}

export interface TikTokSpecialCampaignDetailForInfluencer {
  currency: Scalars['String']['output'];
  endDate: Scalars['Date']['output'];
  hasReport: Scalars['Boolean']['output'];
  hashtags: Array<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  isJoined: Scalars['Boolean']['output'];
  materialUrl?: Maybe<Scalars['String']['output']>;
  materials?: Maybe<Array<MaterialName>>;
  minimumPaymentAmount: Scalars['Float']['output'];
  preLaunchDate?: Maybe<Scalars['Date']['output']>;
  productUrl?: Maybe<Scalars['String']['output']>;
  requirement?: Maybe<Scalars['String']['output']>;
  revenuePerPost: Scalars['Float']['output'];
  sampleUrl?: Maybe<Scalars['String']['output']>;
  serviceInformation?: Maybe<Scalars['String']['output']>;
  socialMedias: Array<CampaignSocialMediaType>;
  startDate: Scalars['Date']['output'];
  status: CampaignStatusForInfluencer;
  thumbnails: Array<Scalars['String']['output']>;
  title: Scalars['String']['output'];
}

export interface TikTokSpecialCampaignPost {
  content?: Maybe<Scalars['String']['output']>;
  currency: Scalars['String']['output'];
  id?: Maybe<Scalars['Int']['output']>;
  influencer: TiktokPostInfluencer;
  joinedDate: Scalars['Date']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  postCode?: Maybe<Scalars['String']['output']>;
  postedDate?: Maybe<Scalars['Date']['output']>;
  revenuePerPost?: Maybe<Scalars['Float']['output']>;
  socialAccountId: Scalars['Int']['output'];
  status?: Maybe<TikTokSpecialCampaignPostStatus>;
  thumbNail?: Maybe<Scalars['String']['output']>;
}

export interface TikTokSpecialCampaignPostReportForInfluencer {
  currency: Scalars['String']['output'];
  /** campaign ID */
  id: Scalars['Int']['output'];
  postReports: Array<InfluencerPostReportStatsTikTokSpecial>;
  /** campaign revenue */
  revenue: Scalars['Float']['output'];
  /** campaign title */
  title: Scalars['String']['output'];
}

/** An enumeration. */
export enum TikTokSpecialCampaignPostStatus {
  APPROVED = 'APPROVED',
  EFFECTIVE = 'EFFECTIVE',
  INEFFECTIVE = 'INEFFECTIVE',
  JOINED = 'JOINED',
  WARNING = 'WARNING',
  WARNING_SOLVED = 'WARNING_SOLVED',
}

/** An enumeration. */
export enum TikTokSpecialCampaignStatus {
  DRAFT = 'DRAFT',
  FINISHED = 'FINISHED',
  ONGOING = 'ONGOING',
  REJECTED = 'REJECTED',
  REVIEWING = 'REVIEWING',
  SUSPENDED = 'SUSPENDED',
  UPCOMING = 'UPCOMING',
}

export interface TikTokSpecialDraftAdPostInfo {
  adAccountSystemId?: Maybe<Scalars['Int']['output']>;
  adCampaignId?: Maybe<Scalars['String']['output']>;
  adGroupId?: Maybe<Scalars['String']['output']>;
  adName?: Maybe<Scalars['String']['output']>;
  authPostCode?: Maybe<Scalars['String']['output']>;
  callToAction?: Maybe<Scalars['Boolean']['output']>;
  landingUrl?: Maybe<Scalars['String']['output']>;
}

export interface TikTokSpecialJoinedAccountPayload {
  avatar?: Maybe<Scalars['String']['output']>;
  followersCount: Scalars['Int']['output'];
  profileUrl: Scalars['String']['output'];
  socialAccountType: SocialAccountType;
  username: Scalars['String']['output'];
}

export interface TikTokSpecialPackageInfluencer {
  age?: Maybe<Scalars['Int']['output']>;
  avatar?: Maybe<Scalars['String']['output']>;
  averageViews?: Maybe<Scalars['Float']['output']>;
  country: CountryName;
  engagement?: Maybe<Scalars['Int']['output']>;
  engagementFollowersRate?: Maybe<Scalars['Float']['output']>;
  engagementViewsRate?: Maybe<Scalars['Float']['output']>;
  followers?: Maybe<Scalars['Int']['output']>;
  gender: Genders;
  /** Influencer Id */
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  popularPosts?: Maybe<Array<SocialAccountPopularPost>>;
  /** Social Account System Id */
  socialAccountId: Scalars['Int']['output'];
}

export interface TikTokSpecialPostDetail {
  boostedInfo?: Maybe<TikTokSpecialAdPostInfo>;
  caption: Scalars['String']['output'];
  comments?: Maybe<Scalars['Int']['output']>;
  detailedReason?: Maybe<Scalars['String']['output']>;
  draftBoostedInfo?: Maybe<TikTokSpecialDraftAdPostInfo>;
  id?: Maybe<Scalars['Int']['output']>;
  images?: Maybe<Array<Scalars['String']['output']>>;
  influencer: TiktokPostDetailInfluencer;
  likes?: Maybe<Scalars['Int']['output']>;
  postUrl?: Maybe<Scalars['String']['output']>;
  postedDate?: Maybe<Scalars['Date']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  shares?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<TikTokSpecialCampaignPostStatus>;
  views?: Maybe<Scalars['Int']['output']>;
  warningReason?: Maybe<WarningReason>;
}

export interface TikTokSpecialPostHistory {
  /** exist only status is REJECTED */
  detailedReason?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  operatedDate: Scalars['Date']['output'];
  operatedUserName?: Maybe<Scalars['String']['output']>;
  /** exist only status is REJECTED */
  reason?: Maybe<Scalars['String']['output']>;
  status: TikTokSpecialPostHistoryStatus;
}

/** An enumeration. */
export enum TikTokSpecialPostHistoryStatus {
  APPROVED = 'APPROVED',
  CREATED = 'CREATED',
  REJECTED = 'REJECTED',
}

/** An enumeration. */
export enum TikTokSpecialReportOrderField {
  AD_COMMENTS = 'AD_COMMENTS',
  AD_ENGAGEMENTS = 'AD_ENGAGEMENTS',
  AD_IMPRESSIONS = 'AD_IMPRESSIONS',
  AD_IMPRESSIONS_RATE = 'AD_IMPRESSIONS_RATE',
  AD_LIKES = 'AD_LIKES',
  AD_REACH = 'AD_REACH',
  AD_REACH_RATE = 'AD_REACH_RATE',
  AD_SHARES = 'AD_SHARES',
  COMMENTS = 'COMMENTS',
  COST = 'COST',
  COSTS_PER_COMMENT = 'COSTS_PER_COMMENT',
  COSTS_PER_ENGAGEMENT = 'COSTS_PER_ENGAGEMENT',
  COSTS_PER_FOLLOWER = 'COSTS_PER_FOLLOWER',
  COSTS_PER_LIKE = 'COSTS_PER_LIKE',
  COSTS_PER_SHARE = 'COSTS_PER_SHARE',
  COSTS_PER_VIEW = 'COSTS_PER_VIEW',
  ENGAGEMENT = 'ENGAGEMENT',
  ENGAGEMENT_RATE = 'ENGAGEMENT_RATE',
  ENGAGEMENT_VIEWS_RATE = 'ENGAGEMENT_VIEWS_RATE',
  FOLLOWERS = 'FOLLOWERS',
  LIKES = 'LIKES',
  SHARES = 'SHARES',
  VIDEO_PLAY_ACTIONS = 'VIDEO_PLAY_ACTIONS',
  VIEWS = 'VIEWS',
}

export interface TiktokAccountForProfileV2 {
  avatar?: Maybe<Scalars['String']['output']>;
  followersCount?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  profile?: Maybe<Scalars['String']['output']>;
  ttcmStatus?: Maybe<TTCMStatus>;
  url?: Maybe<Scalars['String']['output']>;
}

export interface TiktokAdAccountInfo {
  balanceAmount: Scalars['Float']['output'];
  bcId: Scalars['String']['output'];
  bcName: Scalars['String']['output'];
  currencyId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  status: TiktokAdAccountStatus;
  systemId: Scalars['String']['output'];
}

/** An enumeration. */
export enum TiktokAdAccountStatus {
  DISCONNECT = 'DISCONNECT',
  UNVERIFIED = 'UNVERIFIED',
  VERIFIED = 'VERIFIED',
}

export interface TiktokAdCampaign {
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
}

export interface TiktokAdDetailReport {
  adBudget?: Maybe<Scalars['Float']['output']>;
  adCampaignId?: Maybe<Scalars['String']['output']>;
  adCampaignName?: Maybe<Scalars['String']['output']>;
  adClicks?: Maybe<Scalars['Int']['output']>;
  adComments?: Maybe<Scalars['Int']['output']>;
  adCost?: Maybe<Scalars['Float']['output']>;
  adCostPer1000Reached?: Maybe<Scalars['Float']['output']>;
  adCostPerResult?: Maybe<Scalars['Float']['output']>;
  adCpc?: Maybe<Scalars['Float']['output']>;
  adCpm?: Maybe<Scalars['Float']['output']>;
  adCtr?: Maybe<Scalars['Float']['output']>;
  adEngagements?: Maybe<Scalars['Int']['output']>;
  adEr?: Maybe<Scalars['Float']['output']>;
  adFollows?: Maybe<Scalars['Int']['output']>;
  adFrequency?: Maybe<Scalars['Int']['output']>;
  adGroupId?: Maybe<Scalars['String']['output']>;
  adGroupName?: Maybe<Scalars['String']['output']>;
  adId?: Maybe<Scalars['String']['output']>;
  adImpressions?: Maybe<Scalars['Int']['output']>;
  adLikes?: Maybe<Scalars['Int']['output']>;
  adName?: Maybe<Scalars['String']['output']>;
  adReach?: Maybe<Scalars['Int']['output']>;
  adResult?: Maybe<Scalars['Int']['output']>;
  adResultRate?: Maybe<Scalars['Float']['output']>;
  adShares?: Maybe<Scalars['Int']['output']>;
  averageVideoPlay?: Maybe<Scalars['Int']['output']>;
  averageVideoPlayPerUser?: Maybe<Scalars['Int']['output']>;
  campaignId?: Maybe<Scalars['Int']['output']>;
  interactiveAddOnDestinationClicks?: Maybe<Scalars['Int']['output']>;
  interactiveAddOnImpressions?: Maybe<Scalars['Int']['output']>;
  postId?: Maybe<Scalars['Int']['output']>;
  status: TikTokAdStatus;
  videoPlayActions?: Maybe<Scalars['Int']['output']>;
  videoViewsP25?: Maybe<Scalars['Int']['output']>;
  videoViewsP50?: Maybe<Scalars['Int']['output']>;
  videoViewsP75?: Maybe<Scalars['Int']['output']>;
  videoViewsP100?: Maybe<Scalars['Int']['output']>;
  videoWatched2s?: Maybe<Scalars['Int']['output']>;
  videoWatched6s?: Maybe<Scalars['Int']['output']>;
}

export interface TiktokAdGroup {
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
}

export interface TiktokAdReportOrderBy {
  field?: InputMaybe<TikTokAdReportOrderField>;
  order?: InputMaybe<ORDER>;
}

export interface TiktokAdvertiserAccount {
  advertiserName: Scalars['String']['output'];
  advertiserUrl: Scalars['String']['output'];
  countryId: Scalars['String']['output'];
  phoneNumber: Scalars['String']['output'];
}

export interface TiktokAdvertiserPlanData {
  currencyId: Scalars['String']['output'];
  expirationDate?: Maybe<Scalars['Date']['output']>;
  paymentDate?: Maybe<Scalars['Date']['output']>;
  planPrice?: Maybe<Scalars['Float']['output']>;
  subscriptionPlan?: Maybe<TikTokAdvertiserPlanFilter>;
}

export interface TiktokAdvertiserSummaryData {
  cancelNextMonth: Scalars['Boolean']['output'];
  currencyId: Scalars['String']['output'];
  numberCreatedCampaigns: Scalars['Int']['output'];
  planPrice?: Maybe<Scalars['Float']['output']>;
  prepaidBalance?: Maybe<Scalars['Float']['output']>;
  subscriptionPlan?: Maybe<PaymentPlanName>;
  upcomingSubscriptionPlan?: Maybe<PaymentPlanName>;
  warningsList: Array<TiktokBusinessWarningStatus>;
}

export interface TiktokAudienceSection {
  ageRates: TiktokFollowersAgeGroup;
  countryRates?: Maybe<Array<TiktokFollowerCountryRate>>;
  femaleRate?: Maybe<Scalars['Float']['output']>;
  maleRate?: Maybe<Scalars['Float']['output']>;
}

export interface TiktokAuthRedirectUrl {
  url: Scalars['String']['output'];
}

export interface TiktokBudgetAllocation {
  adAccounts: Array<TiktokAdAccountInfo>;
  currencyId: Scalars['String']['output'];
  prepaidBalance: Scalars['Float']['output'];
}

export interface TiktokBusinessAccountInfo {
  bcList: Array<TiktokBusinessCenterInfo>;
  connected: Scalars['Boolean']['output'];
}

export interface TiktokBusinessCenterInfo {
  bcType: TiktokBusinessCenterType;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
}

/** An enumeration. */
export enum TiktokBusinessCenterType {
  AGENCY = 'AGENCY',
  DIRECT = 'DIRECT',
  NORMAL = 'NORMAL',
  SELF_SERVICE = 'SELF_SERVICE',
  SELF_SERVICE_AGENCY = 'SELF_SERVICE_AGENCY',
}

/** An enumeration. */
export enum TiktokBusinessWarningStatus {
  MKP_CAMPAIGN_DISABLE = 'MKP_CAMPAIGN_DISABLE',
  REACH_ADS_ACCOUNTS_LIMIT = 'REACH_ADS_ACCOUNTS_LIMIT',
  SUBSCRIPTION_PAYMENT_FAILED = 'SUBSCRIPTION_PAYMENT_FAILED',
}

export interface TiktokFollowerCountryRate {
  countryId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  rate: Scalars['Float']['output'];
}

export interface TiktokFollowersAgeGroup {
  ageGroup?: Maybe<Array<FollowersAgeGroup>>;
  rate?: Maybe<Array<Scalars['Float']['output']>>;
}

export interface TiktokInfluencer {
  age?: Maybe<Scalars['Int']['output']>;
  avatar?: Maybe<Scalars['String']['output']>;
  averageLikes?: Maybe<Scalars['Float']['output']>;
  averageViews?: Maybe<Scalars['Float']['output']>;
  categories: Array<CategoryName>;
  comments?: Maybe<Scalars['Int']['output']>;
  country: CountryName;
  engagementPostedCount?: Maybe<Scalars['Int']['output']>;
  engagementProposedCount?: Maybe<Scalars['Int']['output']>;
  gender: Genders;
  /** Influencer Id */
  id: Scalars['Int']['output'];
  influencerEngagement?: Maybe<Scalars['Int']['output']>;
  influencerEngagementRate?: Maybe<Scalars['Float']['output']>;
  influencerFollowers?: Maybe<Scalars['Int']['output']>;
  isBrandAccount: Scalars['Boolean']['output'];
  likes?: Maybe<Scalars['Int']['output']>;
  marketplaceJoinedCount?: Maybe<Scalars['Int']['output']>;
  marketplacePostedCount?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  popularPosts?: Maybe<Array<SocialAccountPopularPost>>;
  shares?: Maybe<Scalars['Int']['output']>;
  socialAccountEngagement?: Maybe<Scalars['Int']['output']>;
  socialAccountEngagementFollowersRate?: Maybe<Scalars['Float']['output']>;
  socialAccountEngagementViewsRate?: Maybe<Scalars['Float']['output']>;
  socialAccountFollowers?: Maybe<Scalars['Int']['output']>;
  /** Social Account System Id */
  socialAccountId: Scalars['Int']['output'];
  /** Social Account Status */
  socialAccountStatus: SocialAccountStatus;
  ttcmStatus?: Maybe<TTCMStatus>;
  views?: Maybe<Scalars['BigInt']['output']>;
}

export interface TiktokInfluencerSearchResultV2 {
  tiktokAccounts: Array<TiktokInfluencerV2>;
  totalNumber: Scalars['Int']['output'];
}

export interface TiktokInfluencerV2 {
  age?: Maybe<Scalars['Int']['output']>;
  avatar?: Maybe<Scalars['String']['output']>;
  averageLikes?: Maybe<Scalars['Float']['output']>;
  averageViews?: Maybe<Scalars['Float']['output']>;
  bio?: Maybe<Scalars['String']['output']>;
  country: CountryName;
  gender: Genders;
  id: Scalars['ID']['output'];
  isBrandAccount: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  popularPosts: Array<SocialAccountPopularPost>;
  socialAccountEngagement?: Maybe<Scalars['Int']['output']>;
  socialAccountEngagementFollowersRate?: Maybe<Scalars['Float']['output']>;
  socialAccountEngagementViewsRate?: Maybe<Scalars['Float']['output']>;
  socialAccountFollowers?: Maybe<Scalars['Int']['output']>;
  socialAccountId: Scalars['ID']['output'];
  socialAccountStatus: SocialAccountStatus;
  ttcmStatus?: Maybe<TTCMStatus>;
}

export interface TiktokPostDetailInfluencer {
  avatar: Scalars['String']['output'];
  followersCount: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
}

export interface TiktokPostInfluencer {
  avatar: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
}

export interface TiktokSpecialCampaignInfo {
  endDate?: Maybe<Scalars['Date']['output']>;
  id: Scalars['Int']['output'];
  startDate?: Maybe<Scalars['Date']['output']>;
  status: TikTokSpecialCampaignStatus;
  title: Scalars['String']['output'];
}

export interface TiktokSpecialCampaignReport {
  average?: Maybe<TiktokSpecialStatsItemRow>;
  campaignInfo: TiktokSpecialCampaignInfo;
  chartData: Array<TikTokSpecialCampaignChartReport>;
  currency: Scalars['String']['output'];
  influencerReports: Array<TiktokSpecialInfluencerReport>;
  postCount: Scalars['Int']['output'];
  summary: TiktokSpecialStatsItemRow;
  summaryDaily?: Maybe<Array<TiktokSpecialSummaryDaily>>;
}

export interface TiktokSpecialCampaignReportSummary {
  comments?: Maybe<Scalars['Int']['output']>;
  engagement?: Maybe<Scalars['Int']['output']>;
  engagementRate?: Maybe<Scalars['Float']['output']>;
  likes?: Maybe<Scalars['Int']['output']>;
  postCount: Scalars['Int']['output'];
  shares?: Maybe<Scalars['Int']['output']>;
}

export interface TiktokSpecialInfluencerReport {
  influencerInfo: CampaignInfluencerInfo;
  postInfo: TiktokSpecialPostInfo;
  summary: TiktokSpecialStatsItemRow;
  summaryDaily?: Maybe<Array<TiktokSpecialSummaryDaily>>;
}

export interface TiktokSpecialJoinableAccountsPayload {
  tiktokAccounts: Array<JoinableAccount>;
}

export interface TiktokSpecialPostInfo {
  content: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  postUrl: Scalars['String']['output'];
  socialAccountId: Scalars['Int']['output'];
  thumbNail?: Maybe<Scalars['String']['output']>;
}

export interface TiktokSpecialReportOrderBy {
  field?: InputMaybe<TikTokSpecialReportOrderField>;
  order?: InputMaybe<ORDER>;
}

export interface TiktokSpecialStatsItemRow {
  adComments?: Maybe<Scalars['Int']['output']>;
  adImpressions?: Maybe<Scalars['Int']['output']>;
  adImpressionsRate?: Maybe<Scalars['Float']['output']>;
  adLikes?: Maybe<Scalars['Int']['output']>;
  adReach?: Maybe<Scalars['Int']['output']>;
  adReachRate?: Maybe<Scalars['Float']['output']>;
  adShares?: Maybe<Scalars['Int']['output']>;
  comments?: Maybe<Scalars['Int']['output']>;
  cost?: Maybe<Scalars['Float']['output']>;
  costsPerComment?: Maybe<Scalars['Float']['output']>;
  costsPerEngagement?: Maybe<Scalars['Float']['output']>;
  costsPerFollower?: Maybe<Scalars['Float']['output']>;
  costsPerLike?: Maybe<Scalars['Float']['output']>;
  costsPerShare?: Maybe<Scalars['Float']['output']>;
  costsPerView?: Maybe<Scalars['Float']['output']>;
  engagement?: Maybe<Scalars['Int']['output']>;
  engagementRate?: Maybe<Scalars['Float']['output']>;
  engagementViewsRate?: Maybe<Scalars['Float']['output']>;
  followers?: Maybe<Scalars['Float']['output']>;
  likes?: Maybe<Scalars['Int']['output']>;
  shares?: Maybe<Scalars['Int']['output']>;
  videoPlayActions?: Maybe<Scalars['Int']['output']>;
  views?: Maybe<Scalars['Int']['output']>;
}

export interface TiktokSpecialSummaryDaily {
  date: Scalars['Date']['output'];
  stats: TiktokSpecialStatsItemRow;
}

export interface TiktokUserHashtag {
  hashtag: Scalars['String']['output'];
  id: Scalars['Int']['output'];
}

export interface TiktokUserHashtagListPostsPayload {
  count: Scalars['Int']['output'];
  posts: Array<TiktokUserTagListPostsItemPayload>;
}

export interface TiktokUserHashtagPayload {
  hashtags: Array<TiktokUserHashtag>;
}

export interface TiktokUserKeyword {
  id: Scalars['Int']['output'];
  keyword: Scalars['String']['output'];
}

export interface TiktokUserKeywordListPostsPayload {
  count: Scalars['Int']['output'];
  posts: Array<TiktokUserTagListPostsItemPayload>;
}

export interface TiktokUserKeywordPayload {
  keywords: Array<TiktokUserKeyword>;
}

export interface TiktokUserTagListPostsItemPayload {
  comments: Scalars['Int']['output'];
  content: Scalars['String']['output'];
  engagement: Scalars['Int']['output'];
  likes: Scalars['Int']['output'];
  postDate: Scalars['Date']['output'];
  postId: Scalars['Int']['output'];
  rank: Scalars['Int']['output'];
  saves: Scalars['Int']['output'];
  shares: Scalars['Int']['output'];
  thumbnail: Scalars['String']['output'];
  views: Scalars['Int']['output'];
}

export interface TiktokUserTagPostDetailsPayload {
  comments: Scalars['Int']['output'];
  content?: Maybe<Scalars['String']['output']>;
  engagement: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  likes: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  postDate: Scalars['Date']['output'];
  postUrl: Scalars['String']['output'];
  saves: Scalars['Int']['output'];
  shares: Scalars['Int']['output'];
  thumbnail?: Maybe<Scalars['String']['output']>;
  views: Scalars['Int']['output'];
}

export interface TiktokUserTagPostsSortInput {
  field?: InputMaybe<TiktokUserTagPostsSortOrder>;
  order?: InputMaybe<Order>;
}

export enum TiktokUserTagPostsSortOrder {
  COMMENTS = 'COMMENTS',
  ENGAGEMENT = 'ENGAGEMENT',
  LIKES = 'LIKES',
  POST_DATE = 'POST_DATE',
  RANK = 'RANK',
  SAVES = 'SAVES',
  SHARES = 'SHARES',
  VIEWS = 'VIEWS',
}

export interface TokenAuthLongLive {
  role: UserRoles;
  token: Scalars['String']['output'];
  userId: Scalars['Int']['output'];
}

export interface TokenAuthLongLiveInput {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
}

export interface TotalCmsRevenue {
  currency: Scalars['String']['output'];
  isOwner: Scalars['Boolean']['output'];
  rpm?: Maybe<Scalars['Float']['output']>;
  total: Scalars['Float']['output'];
}

export interface TotalInfluencerReport {
  click?: Maybe<Scalars['Int']['output']>;
  comment: Scalars['Int']['output'];
  conversion?: Maybe<Scalars['Int']['output']>;
  currency: Scalars['String']['output'];
  like: Scalars['Int']['output'];
  pendingRevenue: Scalars['Float']['output'];
  revenue: Scalars['Float']['output'];
  share: Scalars['Int']['output'];
  view: Scalars['Int']['output'];
}

export interface TotalNumber {
  totalNumber: Scalars['Int']['output'];
}

export interface TrackYoutubeMusicDownload {
  ok: Scalars['Boolean']['output'];
}

export interface TrackYoutubeMusicDownloadInput {
  musicId: Scalars['Int']['input'];
}

export interface TransferMoneyToAdAccount {
  ok: Scalars['Boolean']['output'];
}

export interface TransferMoneyToAdAccountInput {
  adAccountId: Scalars['String']['input'];
  amount: Scalars['Float']['input'];
}

export interface TrendingFacebookInfluencer {
  age?: Maybe<Scalars['Int']['output']>;
  avatar?: Maybe<Scalars['String']['output']>;
  country: CountryName;
  facebookType: SocialAccountType;
  followersGrowthRate: Scalars['Float']['output'];
  gender: Genders;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  popularPosts?: Maybe<Array<SocialAccountPopularPost>>;
  rank: Scalars['Int']['output'];
  socialAccountEngagementRate: Scalars['Float']['output'];
  socialAccountFollowers: Scalars['Int']['output'];
  socialAccountId: Scalars['ID']['output'];
  status: SocialAccountStatus;
}

export interface TrendingFacebookInfluencerSearchResult {
  totalNumber: Scalars['Int']['output'];
  trending: Array<TrendingFacebookInfluencer>;
}

export interface TrendingInstagramInfluencerSearchResultV2 {
  totalNumber: Scalars['Int']['output'];
  trendingAccounts: Array<TrendingInstagramInfluencerV2>;
}

export interface TrendingInstagramInfluencerV2 {
  age?: Maybe<Scalars['Int']['output']>;
  avatar?: Maybe<Scalars['String']['output']>;
  country: CountryName;
  followersGrowthRate: Scalars['Float']['output'];
  gender: Genders;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  popularPosts?: Maybe<Array<SocialAccountPopularPost>>;
  rank: Scalars['Int']['output'];
  socialAccountEngagementRate: Scalars['Float']['output'];
  socialAccountFollowers: Scalars['Int']['output'];
  socialAccountId: Scalars['ID']['output'];
  status: SocialAccountStatus;
}

export interface TrendingYoutubeInfluencerSearchResultV2 {
  totalNumber: Scalars['Int']['output'];
  trendingAccounts: Array<TrendingYoutubeInfluencerV2>;
}

export interface TrendingYoutubeInfluencerV2 {
  age?: Maybe<Scalars['Int']['output']>;
  avatar?: Maybe<Scalars['String']['output']>;
  country: CountryName;
  followersGrowthRate: Scalars['Float']['output'];
  gender: Genders;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  popularPosts?: Maybe<Array<SocialAccountPopularPost>>;
  rank: Scalars['Int']['output'];
  socialAccountAverageViews: Scalars['Float']['output'];
  socialAccountFollowers: Scalars['Int']['output'];
  socialAccountId: Scalars['ID']['output'];
  status: SocialAccountStatus;
}

/** An enumeration. */
export enum TutorialName {
  PAYMENT = 'PAYMENT',
  SEARCH_JOB = 'SEARCH_JOB',
  YOUR_JOB = 'YOUR_JOB',
}

export interface TwitterAnalyticsFeedPostPayload {
  id: Scalars['Int']['output'];
  likes: Scalars['Int']['output'];
  replies: Scalars['Int']['output'];
  retweets: Scalars['Int']['output'];
  thumbnail?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
}

export interface TwitterAnalyticsOverviewHistoryItem {
  count: Scalars['Int']['output'];
  date: Scalars['String']['output'];
}

export interface TwitterAnalyticsOverviewHistoryRealItem {
  count: Scalars['Float']['output'];
  date: Scalars['String']['output'];
}

export interface TwitterAnalyticsOverviewItem {
  growth: Scalars['Int']['output'];
  history: Array<TwitterAnalyticsOverviewHistoryItem>;
  percentage: Scalars['Float']['output'];
  total: Scalars['Int']['output'];
}

export interface TwitterAnalyticsOverviewPayload {
  engagement: TwitterAnalyticsOverviewItem;
  engagementRate: TwitterAnalyticsOverviewRealTotalItem;
  engagementRates?: Maybe<TwitterAnalyticsOverviewRealItem>;
  follower: TwitterAnalyticsOverviewItem;
  impression: TwitterAnalyticsOverviewItem;
  lastUpdated: Scalars['DateTime']['output'];
  like: TwitterAnalyticsOverviewItem;
  post: TwitterAnalyticsOverviewItem;
  profileClick: TwitterAnalyticsOverviewTotalItem;
  profileClicks?: Maybe<TwitterAnalyticsOverviewItem>;
  quotedTweet: TwitterAnalyticsOverviewItem;
  reply: TwitterAnalyticsOverviewItem;
  retweet: TwitterAnalyticsOverviewItem;
  urlClick: TwitterAnalyticsOverviewTotalItem;
  urlClicks?: Maybe<TwitterAnalyticsOverviewItem>;
  view: TwitterAnalyticsOverviewItem;
}

export interface TwitterAnalyticsOverviewRealItem {
  growth: Scalars['Float']['output'];
  history: Array<TwitterAnalyticsOverviewHistoryRealItem>;
  percentage: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
}

export interface TwitterAnalyticsOverviewRealTotalItem {
  growth: Scalars['Float']['output'];
  percentage: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
}

export interface TwitterAnalyticsOverviewTotalItem {
  growth: Scalars['Int']['output'];
  percentage: Scalars['Float']['output'];
  total: Scalars['Int']['output'];
}

export interface TwitterAnalyticsPostByIdPayload {
  avatar: Scalars['String']['output'];
  followers: Scalars['Int']['output'];
  post: TwitterAnalyticsPostPayload;
  username: Scalars['String']['output'];
}

export interface TwitterAnalyticsPostComment {
  commentId: Scalars['String']['output'];
  content: Scalars['String']['output'];
  posterName: Scalars['String']['output'];
}

export interface TwitterAnalyticsPostItemPayload {
  engagement: Scalars['Int']['output'];
  engagementRate: Scalars['Float']['output'];
  id: Scalars['Int']['output'];
  impression: Scalars['Int']['output'];
  like: Scalars['Int']['output'];
  postDate: Scalars['String']['output'];
  postUrl: Scalars['String']['output'];
  reply: Scalars['Int']['output'];
  retweet: Scalars['Int']['output'];
  thumbnail?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  view?: Maybe<Scalars['Int']['output']>;
}

export interface TwitterAnalyticsPostPayload {
  comments: Array<TwitterAnalyticsPostComment>;
  content: Scalars['String']['output'];
  engagement: Scalars['Float']['output'];
  id: Scalars['Int']['output'];
  images: Array<Scalars['String']['output']>;
  impression: Scalars['Int']['output'];
  like: Scalars['Int']['output'];
  postDate: Scalars['Date']['output'];
  postUrl: Scalars['String']['output'];
  quoteTweet: Scalars['Int']['output'];
  reply: Scalars['Int']['output'];
  retweet: Scalars['Int']['output'];
  view: Scalars['Int']['output'];
}

export interface TwitterAnalyticsPostsByDatePayload {
  avatar: Scalars['String']['output'];
  followers: Scalars['Int']['output'];
  posts: Array<TwitterAnalyticsPostPayload>;
  username: Scalars['String']['output'];
}

export interface TwitterAnalyticsPostsPayload {
  posts: Array<TwitterAnalyticsPostItemPayload>;
}

export interface TwitterAnalyticsPostsSortInput {
  field?: InputMaybe<TwitterAnalyticsPostsSortOrder>;
  order?: InputMaybe<Order>;
}

export enum TwitterAnalyticsPostsSortOrder {
  ENGAGEMENT = 'ENGAGEMENT',
  ENGAGEMENT_RATE = 'ENGAGEMENT_RATE',
  IMPRESSIONS = 'IMPRESSIONS',
  LIKES = 'LIKES',
  NEGATIVE_RATE = 'NEGATIVE_RATE',
  POSITIVE_RATE = 'POSITIVE_RATE',
  POST_DATE = 'POST_DATE',
  REPLIES = 'REPLIES',
  RETWEETS = 'RETWEETS',
  VIEWS = 'VIEWS',
}

export interface TwitterAnalyticsPostsStatisticPayload {
  averageEngagement: TwitterAverageEngagementPayload;
  postingHabits: Array<TwitterPostingHabitsPayload>;
}

export interface TwitterAverageEngagementItemPayload {
  growth: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
}

export interface TwitterAverageEngagementPayload {
  avgImpression: TwitterAverageEngagementItemPayload;
  avgLike: TwitterAverageEngagementItemPayload;
  avgPostsWeek?: Maybe<TwitterAverageEngagementItemPayload>;
  avgReply: TwitterAverageEngagementItemPayload;
  avgRetweet: TwitterAverageEngagementItemPayload;
  avgView?: Maybe<TwitterAverageEngagementItemPayload>;
}

export interface TwitterForProfileV2 {
  analyticsEnabled: Scalars['Boolean']['output'];
  avatar?: Maybe<Scalars['String']['output']>;
  followersCount?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  profile?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
}

export interface TwitterInfluencer {
  age?: Maybe<Scalars['Int']['output']>;
  analyticsWarning: Scalars['Boolean']['output'];
  avatar?: Maybe<Scalars['String']['output']>;
  averageLikes?: Maybe<Scalars['Float']['output']>;
  categories: Array<CategoryName>;
  country: CountryName;
  engagementPostedCount?: Maybe<Scalars['Int']['output']>;
  engagementProposedCount?: Maybe<Scalars['Int']['output']>;
  gender: Genders;
  /** Influencer Id */
  id: Scalars['Int']['output'];
  influencerEngagement?: Maybe<Scalars['Int']['output']>;
  influencerEngagementRate?: Maybe<Scalars['Float']['output']>;
  influencerFollowers?: Maybe<Scalars['Int']['output']>;
  isBrandAccount: Scalars['Boolean']['output'];
  likes?: Maybe<Scalars['Int']['output']>;
  marketplaceJoinedCount?: Maybe<Scalars['Int']['output']>;
  marketplacePostedCount?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  popularPosts?: Maybe<Array<SocialAccountPopularPost>>;
  replies?: Maybe<Scalars['Int']['output']>;
  retweets?: Maybe<Scalars['Int']['output']>;
  /** only for Twitter to show on UI */
  socialAccountAverageEngagement?: Maybe<Scalars['Int']['output']>;
  socialAccountEngagement?: Maybe<Scalars['Int']['output']>;
  socialAccountEngagementFollowersRate?: Maybe<Scalars['Float']['output']>;
  socialAccountFollowers?: Maybe<Scalars['Int']['output']>;
  /** Social Account System Id */
  socialAccountId: Scalars['Int']['output'];
  /** Social Account Status */
  socialAccountStatus: SocialAccountStatus;
  views?: Maybe<Scalars['Int']['output']>;
}

export interface TwitterPostingHabitsPayload {
  averageComments: Scalars['Float']['output'];
  averageLikes: Scalars['Float']['output'];
  engagementRate: Scalars['Float']['output'];
  hour: Scalars['Int']['output'];
  weekday: Scalars['Int']['output'];
}

export enum UGCPostStatus {
  APPROVED = 'APPROVED',
  NOT_REQUESTED = 'NOT_REQUESTED',
  REJECTED = 'REJECTED',
  REQUESTED = 'REQUESTED',
}

export enum UGCPostType {
  HASHTAG = 'HASHTAG',
  MENTIONED = 'MENTIONED',
  TAGGED = 'TAGGED',
}

export interface UUUMSocialAuthSignUpInput {
  categoryIds: Array<Scalars['Int']['input']>;
  countryId: Scalars['String']['input'];
  dateOfBirth: Scalars['String']['input'];
  email: Scalars['String']['input'];
  gender: Genders;
  name: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  regionId: Scalars['Int']['input'];
  uuid: Scalars['ID']['input'];
}

export interface UUUMSocialAuthSignUpPayload {
  token?: Maybe<Scalars['String']['output']>;
}

export interface UnApproveApplicantInfluencers {
  ok: Scalars['Boolean']['output'];
}

export interface UnApproveApplicantInfluencersInput {
  influencerIds: Array<Scalars['Int']['input']>;
  marketplaceCampaignId: Scalars['Int']['input'];
}

export interface UnassignChatPICInput {
  chatId: Scalars['String']['input'];
}

export interface UnassignChatPICPayload {
  ok: Scalars['Boolean']['output'];
}

export interface UnblockInfluencerMarketplace {
  ok: Scalars['Boolean']['output'];
}

export interface UnblockInfluencerMarketplaceInput {
  campaignId: Scalars['Int']['input'];
  influencerIds: Array<Scalars['Int']['input']>;
}

/**  `UnreadNotificationCountByAppPayload` payload houses a `count` integers to show how many notification are not read yet. */
export interface UnreadNotificationCountByAppPayload {
  count: Scalars['Long']['output'];
}

/**  `UnreadNotificationCountPayload` payload houses a `count` integers to show how many notification are not read yet. */
export interface UnreadNotificationCountPayload {
  count: Scalars['Long']['output'];
}

/**
 *  `UnreadNotificationInput` input accepts a `checkpoint` to get notifications beyond the checkpoint. A value of `null`
 *  can be provided to start from the latest notification.
 */
export interface UnreadNotificationInput {
  checkpoint?: InputMaybe<Scalars['Long']['input']>;
}

export interface UnresolveChatInput {
  chatId: Scalars['String']['input'];
}

export interface UnresolveChatPayload {
  ok: Scalars['Boolean']['output'];
}

export interface UpdateAccountInformationInput {
  brandName: Scalars['String']['input'];
  categoryIds: Array<Scalars['Int']['input']>;
  countryId: Scalars['String']['input'];
  dateOfBirth: Scalars['String']['input'];
  gender: Genders;
  introduce: Scalars['String']['input'];
  name: Scalars['String']['input'];
  regionId?: InputMaybe<Scalars['Int']['input']>;
}

export interface UpdateAccountInformationPayload {
  ok: Scalars['Boolean']['output'];
}

export interface UpdateAddress {
  ok: Scalars['Boolean']['output'];
}

export interface UpdateAddressInformationInput {
  address: Scalars['String']['input'];
  address2: Scalars['String']['input'];
  city: Scalars['String']['input'];
  fullName: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  postalCode: Scalars['String']['input'];
  province: Scalars['String']['input'];
}

export interface UpdateAddressInformationPayload {
  ok: Scalars['Boolean']['output'];
}

export interface UpdateAddressInput {
  address: Scalars['String']['input'];
  address2: Scalars['String']['input'];
  city: Scalars['String']['input'];
  fullName: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  province: Scalars['String']['input'];
}

export interface UpdateAdmin {
  ok: Scalars['Boolean']['output'];
}

export interface UpdateAdminInput {
  countryId?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<CustomPermissionsInput>;
}

export interface UpdateAdvertiser {
  ok: Scalars['Boolean']['output'];
}

export interface UpdateAdvertiserInput {
  anyxMasterId?: InputMaybe<Scalars['Int']['input']>;
  companyUrl?: InputMaybe<Scalars['String']['input']>;
  countryId?: InputMaybe<Scalars['String']['input']>;
  hubspotId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  internalNetsuiteId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateAdvertiserUser {
  ok: Scalars['Boolean']['output'];
}

export interface UpdateAdvertiserUserInput {
  advertiserId?: InputMaybe<Scalars['Int']['input']>;
  countryId?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  notificationSetting?: InputMaybe<Scalars['Boolean']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<CustomPermissionsInput>;
}

export interface UpdateAdvertiserUserSettings {
  ok: Scalars['Boolean']['output'];
}

export interface UpdateAdvertiserUserSettingsInput {
  advertiserCompanyUrl: Scalars['String']['input'];
  advertiserCountryId: Scalars['String']['input'];
  advertiserName: Scalars['String']['input'];
  advertiserPhoneNumber: Scalars['String']['input'];
  countryId: Scalars['String']['input'];
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  notificationSetting: Scalars['Boolean']['input'];
}

export interface UpdateAgency {
  ok: Scalars['Boolean']['output'];
}

export interface UpdateAgencyInput {
  agencyId: Scalars['Int']['input'];
  countryId?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<CustomPermissionsInput>;
}

export interface UpdateAutoManagedAffiliateCommissionRates {
  ok: Scalars['Boolean']['output'];
}

export interface UpdateAutoManagedAffiliateCommissionRatesInput {
  id: Scalars['Int']['input'];
  influencers: Array<AutoManagedAffiliateCommissionRateInput>;
}

export interface UpdateAutoManagedCampaignInput {
  campaignId: Scalars['Int']['input'];
  marginRate: Scalars['Float']['input'];
  requirement: Scalars['String']['input'];
  serviceInfo: Scalars['String']['input'];
  status: AutoManagedCampaignStatus;
}

export interface UpdateAutoManagedCampaignPayload {
  ok: Scalars['Boolean']['output'];
}

export interface UpdateAutoManagedCampaignsStatusInput {
  campaignIds: Array<Scalars['Int']['input']>;
  status: AutoManagedCampaignStatus;
}

export interface UpdateAutoManagedCampaignsStatusPayload {
  ok: Scalars['Boolean']['output'];
}

export interface UpdateBioSectionsInput {
  sections: Array<BioSectionsInput>;
}

export interface UpdateBioSectionsPayload {
  ok: Scalars['Boolean']['output'];
}

export interface UpdateCmsAssetShareRate {
  ok: Scalars['Boolean']['output'];
}

export interface UpdateCmsAssetShareRateInput {
  assets: Array<CmsAssetShareRateInput>;
  /** Channel System Id */
  cmsChannelId: Scalars['Int']['input'];
  month: Scalars['Date']['input'];
}

export interface UpdateCmsChannelShareRate {
  ok: Scalars['Boolean']['output'];
}

export interface UpdateCmsChannelShareRateInput {
  channels: Array<CmsChannelShareRateInput>;
  month: Scalars['Date']['input'];
}

export interface UpdateCommentInput {
  activityId: Scalars['Long']['input'];
  comment: Scalars['String']['input'];
}

export interface UpdateCommentPayload {
  ok: Scalars['Boolean']['output'];
}

export interface UpdateEmailTemplateInput {
  fans: Array<Scalars['Long']['input']>;
  id: Scalars['Long']['input'];
  isDraft: Scalars['Boolean']['input'];
  message: Scalars['String']['input'];
  scheduleDate?: InputMaybe<Scalars['String']['input']>;
  sendNow: Scalars['Boolean']['input'];
  sender: Scalars['String']['input'];
  subject: Scalars['String']['input'];
  tags: Array<Scalars['Long']['input']>;
}

export interface UpdateEmailTemplatePayload {
  ok: Scalars['Boolean']['output'];
}

export interface UpdateEngagement {
  ok: Scalars['Boolean']['output'];
}

export interface UpdateEngagementBreakDownMode {
  ok: Scalars['Boolean']['output'];
}

export interface UpdateEngagementBreakDownModeInput {
  /** Engagement campaign id */
  campaignId: Scalars['Int']['input'];
  /** breakdown mode */
  jdMode: ProposalJobDescriptionBreakDownMode;
}

export interface UpdateEngagementCouponList {
  ok: Scalars['Boolean']['output'];
}

export interface UpdateEngagementCouponListInput {
  couponUploadFileUrl: Scalars['String']['input'];
  id: Scalars['Int']['input'];
}

export interface UpdateEngagementInfluencerProposalList {
  ok: Scalars['Boolean']['output'];
}

export interface UpdateEngagementInfluencerProposalListInput {
  campaignId: Scalars['Int']['input'];
  influencerIds: Array<Scalars['Int']['input']>;
}

export interface UpdateEngagementInfluencersBudget {
  ok: Scalars['Boolean']['output'];
}

export interface UpdateEngagementInfluencersBudgetInput {
  campaignId: Scalars['Int']['input'];
  influencers: Array<EngagementInfluencerBudgetInput>;
}

export interface UpdateEngagementInput {
  adAgencyMarginRate?: InputMaybe<Scalars['Float']['input']>;
  advertiserId: Scalars['Int']['input'];
  budget: Scalars['Float']['input'];
  campaignCategoryId: Scalars['Int']['input'];
  categoryIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  countryIds: Array<Scalars['String']['input']>;
  couponList?: InputMaybe<Scalars['String']['input']>;
  couponUploadFiles?: InputMaybe<Array<Scalars['String']['input']>>;
  currency?: InputMaybe<Scalars['String']['input']>;
  defaultPostRequirement?: InputMaybe<Scalars['String']['input']>;
  endDate: Scalars['Date']['input'];
  hashtags?: InputMaybe<Array<Scalars['String']['input']>>;
  hubspotDealIds?: InputMaybe<Array<Scalars['String']['input']>>;
  id: Scalars['Int']['input'];
  influencerManagementPicIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  isTtcmCampaign?: InputMaybe<Scalars['Boolean']['input']>;
  landingPageUrl?: InputMaybe<Scalars['String']['input']>;
  marginRate?: InputMaybe<Scalars['Float']['input']>;
  materialUrl?: InputMaybe<Scalars['String']['input']>;
  materials?: InputMaybe<Array<Scalars['String']['input']>>;
  numberOfInfluencers?: InputMaybe<Scalars['Int']['input']>;
  objective: Scalars['String']['input'];
  salesPicIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  serviceInformation?: InputMaybe<Scalars['String']['input']>;
  serviceUrl?: InputMaybe<Scalars['String']['input']>;
  socialMedias: Array<CampaignSocialMediaType>;
  startDate: Scalars['Date']['input'];
  thumbNail: Scalars['String']['input'];
  title: Scalars['String']['input'];
  trackingOptions?: InputMaybe<Array<CampaignTrackingOption>>;
}

export interface UpdateEngagementPostForInfluencerV2 {
  ok: Scalars['Boolean']['output'];
}

export interface UpdateEngagementPostInputForInfluencerV2 {
  campaignId: Scalars['Int']['input'];
  content: Scalars['String']['input'];
  id: Scalars['Int']['input'];
  materialsUrl?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  planedPostDate: Scalars['Date']['input'];
  /** Represents social account system id */
  socialAccountId: Scalars['Int']['input'];
  /** Represents social account media type */
  socialAccountMedia: SocialAccountType;
  socialMedia: CampaignSocialMediaType;
}

export interface UpdateEngagementPostInputV2 {
  campaignId: Scalars['Int']['input'];
  content?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  influencerId: Scalars['Int']['input'];
  insightDataAcquisition?: InputMaybe<Scalars['Date']['input']>;
  insightUrls?: InputMaybe<Array<Scalars['String']['input']>>;
  materialsUrl?: InputMaybe<Array<Scalars['String']['input']>>;
  planedPostDate?: InputMaybe<Scalars['Date']['input']>;
  postUrl?: InputMaybe<Scalars['String']['input']>;
  /** Represents social account system id */
  socialAccountId: Scalars['Int']['input'];
  /** Represents social account media type */
  socialAccountMedia: SocialAccountType;
}

export interface UpdateEngagementPostStatus {
  ok: Scalars['Boolean']['output'];
}

export interface UpdateEngagementPostStatusInput {
  id: Scalars['Int']['input'];
  status: EngagementCampaignPostStatus;
}

export interface UpdateEngagementPostV2 {
  ok: Scalars['Boolean']['output'];
}

export interface UpdateEngagementSocialAccountProposalList {
  ok: Scalars['Boolean']['output'];
}

export interface UpdateEngagementSocialAccountProposalListInput {
  campaignId: Scalars['Int']['input'];
  influencerIds: Array<Scalars['Int']['input']>;
  socialAccountIds: Array<Scalars['Int']['input']>;
  socialAccountMedia: SocialAccountType;
}

export interface UpdateEngagementSocialAccountsBudget {
  ok: Scalars['Boolean']['output'];
}

export interface UpdateEngagementSocialAccountsBudgetInput {
  campaignId: Scalars['Int']['input'];
  socialAccounts: Array<EngagementSocialAcccountBudgetInput>;
}

export interface UpdateEngagementStatus {
  ok: Scalars['Boolean']['output'];
}

export interface UpdateEngagementStatusInput {
  id: Scalars['Int']['input'];
  status: EngagementCampaignStatus;
}

export interface UpdateFanInput {
  address1?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  birthday?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  contactNumber?: InputMaybe<Scalars['String']['input']>;
  countryId?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  gender?: InputMaybe<FanGender>;
  id: Scalars['Long']['input'];
  lastName: Scalars['String']['input'];
  postalCode?: InputMaybe<Scalars['String']['input']>;
  province?: InputMaybe<Scalars['String']['input']>;
  tags: Array<Scalars['String']['input']>;
}

export interface UpdateFanPayload {
  ok: Scalars['Boolean']['output'];
}

export interface UpdateFormInput {
  description: Scalars['String']['input'];
  id: Scalars['Long']['input'];
  questions: Array<UpdateQuestionInput>;
  thankDescription: Scalars['String']['input'];
  thankTitle: Scalars['String']['input'];
  title: Scalars['String']['input'];
}

export interface UpdateFormPayload {
  ok: Scalars['Boolean']['output'];
}

export interface UpdateFormStatusInput {
  id: Scalars['Long']['input'];
  status: FormStatus;
}

export interface UpdateFormStatusPayload {
  ok: Scalars['Boolean']['output'];
}

export interface UpdateInfluencerBioInput {
  isFinish: Scalars['Boolean']['input'];
  isPublic: Scalars['Boolean']['input'];
  linkName: Scalars['String']['input'];
  profile: BioProfileInput;
  sections: Array<BioSectionsInputV2>;
  theme?: InputMaybe<BioTheme>;
  themeV2?: InputMaybe<BioThemeV2>;
}

export interface UpdateInfluencerBioPayload {
  ok: Scalars['Boolean']['output'];
}

export interface UpdateInfluencerInputV2 {
  addressInformation?: InputMaybe<AddressInput>;
  campaignDisplay: Scalars['Boolean']['input'];
  categoryIds: Array<Scalars['Int']['input']>;
  /** allow only scraping influencer */
  countryId: Scalars['String']['input'];
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  defaultEngagementSelectionReason?: InputMaybe<Scalars['String']['input']>;
  douyinAccounts?: InputMaybe<Array<InfluencerSocialNetworkAccountInput>>;
  email?: InputMaybe<Scalars['String']['input']>;
  facebookAccount?: InputMaybe<InfluencerSocialNetworkAccountInput>;
  facebookPages?: InputMaybe<Array<InfluencerSocialNetworkAccountInput>>;
  gender: Genders;
  id: Scalars['Int']['input'];
  instagramAccounts?: InputMaybe<Array<InfluencerSocialNetworkAccountInput>>;
  internalMemo?: InputMaybe<Scalars['String']['input']>;
  introduce?: InputMaybe<Scalars['String']['input']>;
  isBrandAccount?: InputMaybe<Scalars['Boolean']['input']>;
  isProAccount?: InputMaybe<Scalars['Boolean']['input']>;
  managerIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  name: Scalars['String']['input'];
  notificationSetting: Scalars['Boolean']['input'];
  partnerIds: Array<Scalars['Int']['input']>;
  paymentInformation?: InputMaybe<PaymentInformationInput>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  rateCards?: InputMaybe<InfluencerRateCardsInput>;
  regionId?: InputMaybe<Scalars['Int']['input']>;
  tags: Array<Scalars['String']['input']>;
  talentAgencyIds: Array<Scalars['Int']['input']>;
  threadsAccounts?: InputMaybe<Array<InfluencerSocialNetworkAccountInput>>;
  tiktokAccounts?: InputMaybe<Array<InfluencerSocialNetworkAccountInput>>;
  twitterAccounts?: InputMaybe<Array<InfluencerSocialNetworkAccountInput>>;
  xhsAccounts?: InputMaybe<Array<InfluencerSocialNetworkAccountInput>>;
  youtubeAccounts?: InputMaybe<Array<InfluencerSocialNetworkAccountInput>>;
}

export interface UpdateInfluencerPhoneNumberInput {
  phoneNumber: Scalars['String']['input'];
}

export interface UpdateInfluencerPhoneNumberPayload {
  ok: Scalars['Boolean']['output'];
}

export interface UpdateInfluencerProfile {
  ok: Scalars['Boolean']['output'];
}

export interface UpdateInfluencerProfileInput {
  categoryIds: Array<Scalars['Int']['input']>;
  dateOfBirth: Scalars['Date']['input'];
  email: Scalars['String']['input'];
  gender: Genders;
  id: Scalars['Int']['input'];
  introduce?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  notificationSetting: Scalars['Boolean']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  regionId: Scalars['Int']['input'];
}

export interface UpdateInfluencerRateCardInput {
  price: Scalars['Float']['input'];
  socialAccountType: SocialMediaTypeForRateCard;
}

export interface UpdateInfluencerRateCardPayload {
  ok: Scalars['Boolean']['output'];
}

export interface UpdateInfluencerRateCardsByStaff {
  ok: Scalars['Boolean']['output'];
}

export interface UpdateInfluencerRateCardsByStaffInput {
  influencerId: Scalars['Int']['input'];
  rateCards: InfluencerRateCardsInput;
}

export interface UpdateInfluencerRateCardsInput {
  facebook?: InputMaybe<Scalars['Float']['input']>;
  instagram?: InputMaybe<Scalars['Float']['input']>;
  instagramStory?: InputMaybe<Scalars['Float']['input']>;
  tiktok?: InputMaybe<Scalars['Float']['input']>;
  twitter?: InputMaybe<Scalars['Float']['input']>;
  youtube?: InputMaybe<Scalars['Float']['input']>;
}

export interface UpdateInfluencerRateCardsPayload {
  ok: Scalars['Boolean']['output'];
}

export interface UpdateInfluencerTagsByStaff {
  ok: Scalars['Boolean']['output'];
}

export interface UpdateInfluencerTagsByStaffInput {
  influencerId: Scalars['Int']['input'];
  tags: Array<Scalars['String']['input']>;
}

export interface UpdateInfluencerV2 {
  ok: Scalars['Boolean']['output'];
}

export interface UpdateInternalMemo {
  ok: Scalars['Boolean']['output'];
}

export interface UpdateInternalMemoInput {
  id: Scalars['Int']['input'];
  internalMemo: Scalars['String']['input'];
}

export interface UpdateLineBroadcastMessageInput {
  deliveryTiming: DeliveryTimingItemInput;
  lineBroadcastMessageId: Scalars['String']['input'];
  lineContent: Array<LineContentItemInput>;
  recipient: RecipientItemInput;
  title: Scalars['String']['input'];
}

export interface UpdateLineBroadcastMessagePayload {
  ok: Scalars['Boolean']['output'];
}

export interface UpdateLinkBioThemeColorInput {
  bgColor?: InputMaybe<Scalars['String']['input']>;
  button?: InputMaybe<BioButtonStyle>;
  buttonBgColor?: InputMaybe<Scalars['String']['input']>;
  buttonFontColor?: InputMaybe<Scalars['String']['input']>;
  font?: InputMaybe<BioFontStyle>;
  fontColor?: InputMaybe<Scalars['String']['input']>;
  media?: InputMaybe<Scalars['String']['input']>;
  theme?: InputMaybe<BioTheme>;
}

export interface UpdateLinkBioThemeColorPayload {
  ok: Scalars['Boolean']['output'];
}

export interface UpdateMarketplace {
  ok: Scalars['Boolean']['output'];
}

export interface UpdateMarketplaceAffiliateCommissionFees {
  ok: Scalars['Boolean']['output'];
}

export interface UpdateMarketplaceAffiliateCommissionFeesInput {
  influencers: Array<MarketplaceAffiliateCommissionFeeInput>;
  marketplaceId: Scalars['Int']['input'];
}

export interface UpdateMarketplaceAffiliateCommissionRates {
  ok: Scalars['Boolean']['output'];
}

export interface UpdateMarketplaceAffiliateCommissionRatesInput {
  influencers: Array<MarketplaceAffiliateCommissionRateInput>;
  marketplaceId: Scalars['Int']['input'];
}

export interface UpdateMarketplaceCouponList {
  ok: Scalars['Boolean']['output'];
}

export interface UpdateMarketplaceCouponListInput {
  couponUploadFileUrl: Scalars['String']['input'];
  id: Scalars['Int']['input'];
}

export interface UpdateMarketplaceInfluencerCost {
  ok: Scalars['Boolean']['output'];
}

export interface UpdateMarketplaceInfluencerCostInput {
  influencers: Array<MarketplaceInfluencerRevenueInput>;
  marketplaceId: Scalars['Int']['input'];
}

export interface UpdateMarketplaceInput {
  additionalRequirement?: InputMaybe<Scalars['String']['input']>;
  advertiserId: Scalars['Int']['input'];
  agencyMarginRate?: InputMaybe<Scalars['Float']['input']>;
  allowNewInfluencer: Scalars['Boolean']['input'];
  brandName?: InputMaybe<Scalars['String']['input']>;
  budget: Scalars['Float']['input'];
  campaignCategoryId: Scalars['Int']['input'];
  campaignType: MarketplaceCampaignDetailType;
  campaignUrl?: InputMaybe<Scalars['String']['input']>;
  categoryIds: Array<Scalars['Int']['input']>;
  clickUrl?: InputMaybe<Scalars['String']['input']>;
  /** for TUNE Tracking */
  costPerAction?: InputMaybe<Scalars['Float']['input']>;
  /** for TUNE Tracking */
  costPerClick?: InputMaybe<Scalars['Float']['input']>;
  costPerComment?: InputMaybe<Scalars['Float']['input']>;
  costPerFollower?: InputMaybe<Scalars['Float']['input']>;
  costPerLike?: InputMaybe<Scalars['Float']['input']>;
  /** for referral code Tracking */
  costPerOrder?: InputMaybe<Scalars['Float']['input']>;
  costPerPost?: InputMaybe<Scalars['Float']['input']>;
  /** for referral code Tracking */
  costPerSaleReferral?: InputMaybe<Scalars['Float']['input']>;
  /** for TUNE Tracking */
  costPerSaleTune?: InputMaybe<Scalars['Float']['input']>;
  costPerShare?: InputMaybe<Scalars['Float']['input']>;
  costPerView?: InputMaybe<Scalars['Float']['input']>;
  countryId: Scalars['String']['input'];
  couponUploadFiles?: InputMaybe<Array<Scalars['String']['input']>>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  genders: Array<Genders>;
  hashtags: Array<Scalars['String']['input']>;
  hubspotDealIds?: InputMaybe<Array<Scalars['String']['input']>>;
  id: Scalars['Int']['input'];
  influencerManagementPicIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  isAllowMultiplePosts?: InputMaybe<Scalars['Boolean']['input']>;
  isAutoInfluencerApproval?: InputMaybe<Scalars['Boolean']['input']>;
  isGiftingCampaign?: InputMaybe<Scalars['Boolean']['input']>;
  landingPageUrl?: InputMaybe<Scalars['String']['input']>;
  marginRate: Scalars['Float']['input'];
  materialUrl?: InputMaybe<Scalars['String']['input']>;
  materials?: InputMaybe<Array<Scalars['String']['input']>>;
  maxAge: Scalars['Int']['input'];
  maxFollowers: Scalars['Int']['input'];
  maximumRevenuePerInfluencer?: InputMaybe<Scalars['Float']['input']>;
  minAge: Scalars['Int']['input'];
  minFollowers: Scalars['Int']['input'];
  mkpServiceCampaignId?: InputMaybe<Scalars['Int']['input']>;
  preLaunchDate?: InputMaybe<Scalars['Date']['input']>;
  productThumbNail?: InputMaybe<Scalars['String']['input']>;
  regionIds: Array<Scalars['Int']['input']>;
  requireDraftPost?: InputMaybe<Scalars['Boolean']['input']>;
  requirement: Scalars['String']['input'];
  salesPicIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  sampleUrl?: InputMaybe<Scalars['String']['input']>;
  serviceInformation: Scalars['String']['input'];
  socialMedia: CampaignSocialMediaType;
  startDate: Scalars['Date']['input'];
  thumbnails: Array<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  trackingOptions?: InputMaybe<Array<CampaignTrackingOption>>;
}

export interface UpdateMarketplacePostStatusInputV2 {
  influencerIds: Array<Scalars['Int']['input']>;
  /** Campaign Id */
  marketplaceId: Scalars['Int']['input'];
  postIds: Array<InputMaybe<Scalars['Int']['input']>>;
  status: MarketplaceCampaignPostStatus;
}

export interface UpdateMarketplacePostStatusV2 {
  ok: Scalars['Boolean']['output'];
}

export interface UpdateMarketplacePostTrackingUrl {
  ok: Scalars['Boolean']['output'];
}

export interface UpdateMarketplacePostTrackingUrlInput {
  campaignId: Scalars['Int']['input'];
  postTrackingUrl: Scalars['String']['input'];
}

export interface UpdateOptionInput {
  id?: InputMaybe<Scalars['Long']['input']>;
  optionTitle: Scalars['String']['input'];
  order: Scalars['Int']['input'];
}

export interface UpdatePackage {
  ok: Scalars['Boolean']['output'];
}

export interface UpdatePackageInput {
  companyAndRolePairs: Array<CompanyAndRolePair>;
  countryId: Scalars['String']['input'];
  id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
}

export interface UpdatePackageProposalInfluencersBudget {
  ok: Scalars['Boolean']['output'];
}

export interface UpdatePackageProposalInfluencersBudgetInput {
  influencers: Array<PackageProposalInfluencerBudgetInput>;
  packageId: Scalars['Int']['input'];
}

export interface UpdatePackageProposalMode {
  ok: Scalars['Boolean']['output'];
}

export interface UpdatePackageProposalModeInput {
  /** breakdown mode */
  jdMode: ProposalJobDescriptionBreakDownMode;
  packageId: Scalars['Int']['input'];
}

export interface UpdatePackageProposalSocialAccountsBudget {
  ok: Scalars['Boolean']['output'];
}

export interface UpdatePackageProposalSocialAccountsBudgetInput {
  packageId: Scalars['Int']['input'];
  socialAccounts: Array<PackageProposalSocialAcccountBudgetInput>;
}

export interface UpdatePartnerAgency {
  ok: Scalars['Boolean']['output'];
}

export interface UpdatePartnerAgencyInput {
  advertiserIds: Array<Scalars['Int']['input']>;
  agencyIds: Array<Scalars['Int']['input']>;
  companyUrl?: InputMaybe<Scalars['String']['input']>;
  countryId: Scalars['String']['input'];
  id: Scalars['Int']['input'];
  influencerCostPermission: Scalars['Boolean']['input'];
  logo?: InputMaybe<Scalars['String']['input']>;
  netsuiteId?: InputMaybe<Scalars['String']['input']>;
  partnerAgencyName: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  privateInfluencersIds: Array<Scalars['Int']['input']>;
}

export interface UpdatePartnerUser {
  ok: Scalars['Boolean']['output'];
}

export interface UpdatePartnerUserInput {
  /** Partner User Country */
  countryId: Scalars['String']['input'];
  /** Partner User Email */
  email: Scalars['String']['input'];
  /** Partner User Id */
  id: Scalars['Int']['input'];
  /** Partner User Name */
  name: Scalars['String']['input'];
  /** Partner User Password */
  password?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<CustomPermissionsInput>;
}

export interface UpdatePasswordByAdvertiserUser {
  ok: Scalars['Boolean']['output'];
}

export interface UpdatePasswordByAdvertiserUserInput {
  newPassword: Scalars['String']['input'];
  originalPassword: Scalars['String']['input'];
}

export interface UpdatePaymentInformation {
  ok: Scalars['Boolean']['output'];
}

export interface UpdatePaymentInformationInput {
  accountName: Scalars['String']['input'];
  accountNumber: Scalars['String']['input'];
  address?: InputMaybe<Scalars['String']['input']>;
  bankId: Scalars['Int']['input'];
  /** Branch ID */
  branchId?: InputMaybe<Scalars['Int']['input']>;
  /** Branch Name when user manually inserts it */
  branchName?: InputMaybe<Scalars['String']['input']>;
  cityId?: InputMaybe<Scalars['Int']['input']>;
  email: Scalars['String']['input'];
  fullName?: InputMaybe<Scalars['String']['input']>;
  /** IBAN */
  iban?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  /** SWIFT Code */
  swiftCode?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdatePaymentStatus {
  ok: Scalars['Boolean']['output'];
}

export interface UpdatePaymentStatusInput {
  id: Scalars['Int']['input'];
  status: PaymentRequestStatus;
}

export interface UpdatePersoanlUserDetailPayload {
  ok: Scalars['Boolean']['output'];
}

export interface UpdatePersonalUserDetailInput {
  crmNotificationSetting?: InputMaybe<Scalars['Boolean']['input']>;
  displayLanguage: DisplayLanguage;
  eggNotificationSetting?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  mkpNotificationSetting?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  notificationSetting?: InputMaybe<Scalars['Boolean']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdatePlainAgency {
  ok: Scalars['Boolean']['output'];
}

export interface UpdatePlainAgencyInput {
  advertiserIds: Array<Scalars['Int']['input']>;
  agencyName: Scalars['String']['input'];
  companyUrl?: InputMaybe<Scalars['String']['input']>;
  countryId: Scalars['String']['input'];
  hubspotId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  internalNetsuiteId?: InputMaybe<Scalars['String']['input']>;
  logo?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateQuestionInput {
  id?: InputMaybe<Scalars['Long']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  isRequired: Scalars['Boolean']['input'];
  options: Array<UpdateOptionInput>;
  order: Scalars['Int']['input'];
  questionType: QuestionType;
  title: Scalars['String']['input'];
}

export interface UpdateSocialAccountsInput {
  influencerId: Scalars['Int']['input'];
}

export interface UpdateSocialAccountsPayload {
  ok: Scalars['Boolean']['output'];
}

export interface UpdateSocialUsername {
  ok: Scalars['Boolean']['output'];
}

export interface UpdateSocialUsernameInput {
  influencerId: Scalars['Int']['input'];
  socialAccountId: Scalars['Int']['input'];
  socialMedia: SocialAccountType;
  username: Scalars['String']['input'];
}

export interface UpdateStaff {
  ok: Scalars['Boolean']['output'];
}

export interface UpdateStaffInput {
  advertiserIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  autoLinkToAdvertiser: Scalars['Boolean']['input'];
  countryId?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<CustomPermissionsInput>;
}

export interface UpdateSubscriptionPlan {
  ok: Scalars['Boolean']['output'];
}

export interface UpdateSubscriptionPlanInput {
  priceId: Scalars['Int']['input'];
}

export interface UpdateTalentAgency {
  ok: Scalars['Boolean']['output'];
}

export interface UpdateTalentAgencyInput {
  advertiserIds: Array<Scalars['Int']['input']>;
  agencyIds: Array<Scalars['Int']['input']>;
  cmsMarginRate?: InputMaybe<Scalars['Float']['input']>;
  companyUrl?: InputMaybe<Scalars['String']['input']>;
  countryId: Scalars['String']['input'];
  id: Scalars['Int']['input'];
  netsuiteId?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  privateInfluencersIds: Array<Scalars['Int']['input']>;
  talentAgencyName: Scalars['String']['input'];
}

export interface UpdateTalentAgencyUser {
  ok: Scalars['Boolean']['output'];
}

export interface UpdateTalentAgencyUserInput {
  /** Talent Agency User Country */
  countryId: Scalars['String']['input'];
  /** Talent Agency User Email */
  email: Scalars['String']['input'];
  /** Talent Agency User Id */
  id: Scalars['Int']['input'];
  /** Talent Agency User Name */
  name: Scalars['String']['input'];
  /** Talent Agency User Password */
  password?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<CustomPermissionsInput>;
}

export interface UpdateTalentInfluencerInput {
  categoryIds: Array<Scalars['Int']['input']>;
  dateOfBirth: Scalars['String']['input'];
  defaultEngagementSelectionReason?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  gender: Genders;
  influencerId: Scalars['Int']['input'];
  internalMemo?: InputMaybe<Scalars['String']['input']>;
  introduce?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  rateCards?: InputMaybe<RateCardsInput>;
  regionId: Scalars['Int']['input'];
  selectedSocialAccounts: SelectedSocialAccounts;
  tags: Array<Scalars['String']['input']>;
}

export interface UpdateTalentInfluencerPayload {
  ok: Scalars['Boolean']['output'];
}

export interface UpdateTikTokSpecialCampaign {
  ok: Scalars['Boolean']['output'];
}

export interface UpdateTikTokSpecialCampaignInput {
  campaignCategoryId?: InputMaybe<Scalars['Int']['input']>;
  /** nullable for draft */
  endDate?: InputMaybe<Scalars['Date']['input']>;
  hashtags: Array<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  isDraft: Scalars['Boolean']['input'];
  materialUrl?: InputMaybe<Scalars['String']['input']>;
  materials: Array<Scalars['String']['input']>;
  preLaunchDate?: InputMaybe<Scalars['Date']['input']>;
  productUrl?: InputMaybe<Scalars['String']['input']>;
  requirement: Scalars['String']['input'];
  sampleUrl?: InputMaybe<Scalars['String']['input']>;
  serviceInformation: Scalars['String']['input'];
  /** nullable for draft */
  startDate?: InputMaybe<Scalars['Date']['input']>;
  thumbnails: Array<Scalars['String']['input']>;
  title: Scalars['String']['input'];
}

export interface UpdateTiktokAdvertiserInfo {
  ok: Scalars['Boolean']['output'];
}

export interface UpdateTiktokAdvertiserInfoInput {
  advertiserName: Scalars['String']['input'];
  advertiserUrl: Scalars['String']['input'];
  countryId: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
}

export interface UpdateTiktokSpecialPostStatus {
  ok: Scalars['Boolean']['output'];
}

export interface UpdateTiktokSpecialPostStatusInput {
  /** Campaign Id */
  campaignId: Scalars['Int']['input'];
  postIds: Array<InputMaybe<Scalars['Int']['input']>>;
  status: TikTokSpecialCampaignPostStatus;
}

export interface UpdateTtcmPost {
  ok: Scalars['Boolean']['output'];
}

export interface UpdateTtcmPostInput {
  content: Scalars['String']['input'];
  /** Post id */
  id: Scalars['Int']['input'];
  thumbnailUrl: Scalars['String']['input'];
}

export interface UpdateUserDetailInput {
  crmNotificationSetting?: InputMaybe<Scalars['Boolean']['input']>;
  eggNotificationSetting?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  mkpNotificationSetting?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  notificationSetting?: InputMaybe<Scalars['Boolean']['input']>;
  userId: Scalars['Int']['input'];
}

export interface UpdateUserDetailPayload {
  ok: Scalars['Boolean']['output'];
}

export interface UpdateUserProposalBreakdownMode {
  ok: Scalars['Boolean']['output'];
}

export interface UpdateUserProposalBreakdownModeInput {
  /** breakdown mode */
  jdMode: ProposalJobDescriptionBreakDownMode;
}

export interface UpdateUserProposalInfluencersBudget {
  ok: Scalars['Boolean']['output'];
}

export interface UpdateUserProposalInfluencersBudgetInput {
  influencers: Array<UserProposalInfluencerBudgetInput>;
}

export interface UpdateUserProposalSocialAccountsBudget {
  ok: Scalars['Boolean']['output'];
}

export interface UpdateUserProposalSocialAccountsBudgetInput {
  socialAccounts: Array<UserProposalSocialAcccountBudgetInput>;
}

export interface UpdateYoutubeMCNCredentialsInput {
  callbackUrl: Scalars['String']['input'];
  response: Scalars['String']['input'];
}

export interface UpdateYoutubeMCNCredentialsPayload {
  ok: Scalars['Boolean']['output'];
}

export interface UploadEngagementReportFile {
  ok: Scalars['Boolean']['output'];
}

export interface UploadEngagementReportFileInput {
  /** Engagement Campaign Id */
  id: Scalars['Int']['input'];
  /** Upload file url */
  uploadFileUrl: Scalars['String']['input'];
}

export interface UploadEngagementReportFromInsightCheck {
  ok: Scalars['Boolean']['output'];
}

export interface UploadEngagementReportFromInsightCheckInput {
  audienceRetention?: InputMaybe<Scalars['Float']['input']>;
  averageViewDurationHours?: InputMaybe<Scalars['Float']['input']>;
  averageViewDurationMinutes?: InputMaybe<Scalars['Float']['input']>;
  back?: InputMaybe<Scalars['Int']['input']>;
  comments?: InputMaybe<Scalars['Int']['input']>;
  exited?: InputMaybe<Scalars['Int']['input']>;
  followerReach?: InputMaybe<Scalars['Int']['input']>;
  /** for Douyin only */
  followersCount?: InputMaybe<Scalars['Int']['input']>;
  forward?: InputMaybe<Scalars['Int']['input']>;
  impressions?: InputMaybe<Scalars['Int']['input']>;
  impressionsClickThroughRate?: InputMaybe<Scalars['Float']['input']>;
  impressionsFromDiscovery?: InputMaybe<Scalars['Int']['input']>;
  impressionsFromHashtag?: InputMaybe<Scalars['Int']['input']>;
  impressionsFromHome?: InputMaybe<Scalars['Int']['input']>;
  impressionsFromOther?: InputMaybe<Scalars['Int']['input']>;
  impressionsFromProfile?: InputMaybe<Scalars['Int']['input']>;
  interaction?: InputMaybe<Scalars['Int']['input']>;
  likes?: InputMaybe<Scalars['Int']['input']>;
  linkClicks?: InputMaybe<Scalars['Int']['input']>;
  navigation?: InputMaybe<Scalars['Int']['input']>;
  newFollowers?: InputMaybe<Scalars['Int']['input']>;
  nextStory?: InputMaybe<Scalars['Int']['input']>;
  nonFollowerReach?: InputMaybe<Scalars['Int']['input']>;
  /** Engagement Campaign Post Id */
  postId: Scalars['Int']['input'];
  profileActivity?: InputMaybe<Scalars['Int']['input']>;
  reaches?: InputMaybe<Scalars['Int']['input']>;
  saves?: InputMaybe<Scalars['Int']['input']>;
  shares?: InputMaybe<Scalars['Int']['input']>;
  stickerTaps?: InputMaybe<Scalars['Int']['input']>;
  views?: InputMaybe<Scalars['Int']['input']>;
}

export interface UploadEngagementXhsInsightCheck {
  ok: Scalars['Boolean']['output'];
}

export interface UploadEngagementXhsInsightCheckInput {
  age45UpPercentage?: InputMaybe<Scalars['Float']['input']>;
  age1824Percentage?: InputMaybe<Scalars['Float']['input']>;
  age2534Percentage?: InputMaybe<Scalars['Float']['input']>;
  age3544Percentage?: InputMaybe<Scalars['Float']['input']>;
  ageUp17Percentage?: InputMaybe<Scalars['Float']['input']>;
  averageViewDurationSeconds?: InputMaybe<Scalars['Int']['input']>;
  comments?: InputMaybe<Scalars['Int']['input']>;
  femalePercentage?: InputMaybe<Scalars['Float']['input']>;
  likes?: InputMaybe<Scalars['Int']['input']>;
  malePercentage?: InputMaybe<Scalars['Float']['input']>;
  newFollowers?: InputMaybe<Scalars['Int']['input']>;
  postId: Scalars['Int']['input'];
  saves?: InputMaybe<Scalars['Int']['input']>;
  views?: InputMaybe<Scalars['Int']['input']>;
  viewsFromFollowing?: InputMaybe<Scalars['Int']['input']>;
  viewsFromHome?: InputMaybe<Scalars['Int']['input']>;
  viewsFromOther?: InputMaybe<Scalars['Int']['input']>;
  viewsFromProfile?: InputMaybe<Scalars['Int']['input']>;
  viewsFromSearch?: InputMaybe<Scalars['Int']['input']>;
}

export interface UploadOpenAIImages {
  ok: Scalars['Boolean']['output'];
}

export interface UploadOpenAIImagesInput {
  images?: InputMaybe<Array<Scalars['String']['input']>>;
  threadId: Scalars['Int']['input'];
}

export type UserAudioMessageEvent = ChatEvent & {
  contentUrl: Scalars['String']['output'];
  duration: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  timestamp: Scalars['String']['output'];
};

export interface UserDefaultPermissions {
  permissions?: Maybe<CustomPermissions>;
}

export interface UserDetail {
  advertisers: Array<AdvertiserName>;
  /** Agency Id */
  agencyId?: Maybe<Scalars['Int']['output']>;
  autoLinkToAdvertiser?: Maybe<Scalars['Boolean']['output']>;
  country: CountryName;
  email: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  notificationSetting?: Maybe<Scalars['Boolean']['output']>;
  /** Partner Id */
  partnerId?: Maybe<Scalars['Int']['output']>;
  permissions?: Maybe<CustomPermissions>;
  role: UserRoles;
  /** Talent Agency Id */
  talentAgencyId?: Maybe<Scalars['Int']['output']>;
}

export interface UserDetailInput {
  userId: Scalars['Int']['input'];
}

export interface UserDetailPayload {
  email?: Maybe<Scalars['String']['output']>;
  isAvailableCountry: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  notificationSetting?: Maybe<Scalars['Boolean']['output']>;
}

export type UserImageMessageEvent = ChatEvent & {
  id: Scalars['String']['output'];
  imageUrl: Scalars['String']['output'];
  timestamp: Scalars['String']['output'];
};

export type UserPostbackEvent = ChatEvent & {
  displayText: Scalars['String']['output'];
  id: Scalars['String']['output'];
  timestamp: Scalars['String']['output'];
};

export interface UserProposal {
  currency: Scalars['String']['output'];
  /** Proposal Id */
  id: Scalars['Int']['output'];
  influencers: Array<UserProposalInfluencer>;
  jdMode: ProposalJobDescriptionBreakDownMode;
  summary: UserProposalJobSummary;
}

export interface UserProposalInfluencer {
  accountList: Array<UserProposalSocialAccount>;
  addedDate?: Maybe<Scalars['Date']['output']>;
  avatar?: Maybe<Scalars['String']['output']>;
  /** Influencer id */
  id: Scalars['Int']['output'];
  isDenied?: Maybe<Scalars['Boolean']['output']>;
  /** Influencer name */
  name: Scalars['String']['output'];
  status: SocialAccountStatus;
  summary: UserProposalJobSummary;
}

export interface UserProposalInfluencerBudgetInput {
  budget: Scalars['Float']['input'];
  influencerCost: Scalars['Float']['input'];
  influencerId: Scalars['Int']['input'];
  profit: Scalars['Float']['input'];
}

export interface UserProposalJobSummary {
  averageEngagement?: Maybe<Scalars['Float']['output']>;
  budget?: Maybe<Scalars['Float']['output']>;
  engagementFollowerRate?: Maybe<Scalars['Float']['output']>;
  engagementPostedCount?: Maybe<Scalars['Int']['output']>;
  engagementProposedCount?: Maybe<Scalars['Int']['output']>;
  engagementViewRate?: Maybe<Scalars['Float']['output']>;
  followersCount?: Maybe<Scalars['BigInt']['output']>;
  influencerCost?: Maybe<Scalars['Float']['output']>;
  profit?: Maybe<Scalars['Float']['output']>;
}

export interface UserProposalSocialAcccountBudgetInput {
  budget: Scalars['Float']['input'];
  influencerCost: Scalars['Float']['input'];
  influencerId: Scalars['Int']['input'];
  profit: Scalars['Float']['input'];
  socialAccountId: Scalars['Int']['input'];
  socialAccountMedia: UserProposalSocialMediaType;
}

export interface UserProposalSocialAccount {
  addedDate?: Maybe<Scalars['Date']['output']>;
  /** Social account id */
  id: Scalars['Int']['output'];
  /** Social account name */
  name?: Maybe<Scalars['String']['output']>;
  /** Social account type */
  socialMedia: UserProposalSocialMediaType;
  summary: UserProposalJobSummary;
}

/** An enumeration. */
export enum UserProposalSocialMediaType {
  DOUYIN = 'DOUYIN',
  FACEBOOK = 'FACEBOOK',
  FACEBOOK_PAGE = 'FACEBOOK_PAGE',
  INSTAGRAM = 'INSTAGRAM',
  INSTAGRAM_STORY = 'INSTAGRAM_STORY',
  THREADS = 'THREADS',
  TIKTOK = 'TIKTOK',
  TWITTER = 'TWITTER',
  XHS = 'XHS',
  YOUTUBE = 'YOUTUBE',
}

/** An enumeration. */
export enum UserRoles {
  ADMINISTRATOR = 'ADMINISTRATOR',
  ADVERTISER = 'ADVERTISER',
  AGENCY = 'AGENCY',
  CREATOR_STAFF = 'CREATOR_STAFF',
  EXTERNAL = 'EXTERNAL',
  INFLUENCER = 'INFLUENCER',
  PARTNER = 'PARTNER',
  STAFF = 'STAFF',
  TALENT_AGENCY = 'TALENT_AGENCY',
}

export type UserTextMessageEvent = ChatEvent & {
  id: Scalars['String']['output'];
  text: Scalars['String']['output'];
  timestamp: Scalars['String']['output'];
};

export type UserVideoMessageEvent = ChatEvent & {
  contentUrl: Scalars['String']['output'];
  duration: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  timestamp: Scalars['String']['output'];
};

export interface VerifyInput {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
}

export interface VerifyLineIDTokenInput {
  idToken: Scalars['String']['input'];
}

export interface VerifyLineIDTokenPayload {
  ok: Scalars['Boolean']['output'];
}

export interface VerifyPayload {
  company?: Maybe<Scalars['String']['output']>;
  /** Users's country's id */
  countryId?: Maybe<Scalars['String']['output']>;
  creatorType?: Maybe<InfluencerType>;
  /** Users's country's currency id */
  currencyId?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  hash?: Maybe<Scalars['String']['output']>;
  limitedLogin: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  ok: Scalars['Boolean']['output'];
  role?: Maybe<UserRoles>;
  subscriptionPaymentFailed?: Maybe<Scalars['Boolean']['output']>;
  subscriptionPlan?: Maybe<PaymentPlanName>;
  userId?: Maybe<Scalars['Int']['output']>;
}

export interface VideoInput {
  fileName: Scalars['String']['input'];
  mimeType: Scalars['String']['input'];
  previewUrl: Scalars['String']['input'];
  url: Scalars['String']['input'];
}

export interface VideoPayload {
  fileName: Scalars['String']['output'];
  mimeType: Scalars['String']['output'];
  previewUrl: Scalars['String']['output'];
  url: Scalars['String']['output'];
}

/** An enumeration. */
export enum WarningReason {
  AD_FRAUD = 'AD_FRAUD',
  API_ERROR = 'API_ERROR',
  COUPON_MISSING = 'COUPON_MISSING',
  DELETED_POST = 'DELETED_POST',
  EXPIRED_TOKEN = 'EXPIRED_TOKEN',
  INVALID_CONTENT = 'INVALID_CONTENT',
  INVALID_TOKEN = 'INVALID_TOKEN',
  SCRAPING_ERROR = 'SCRAPING_ERROR',
}

export interface WithdrawMoneyFromAdAccount {
  ok: Scalars['Boolean']['output'];
}

export interface XhsAccountInterest {
  deviceRates?: Maybe<Array<AccountAudienceDataRate>>;
  interestRates?: Maybe<Array<AccountAudienceDataRate>>;
}

export interface XhsAudienceSection {
  ageRates: TiktokFollowersAgeGroup;
  cityRates?: Maybe<Array<AccountAudienceDataRate>>;
  femaleRate?: Maybe<Scalars['Float']['output']>;
  maleRate?: Maybe<Scalars['Float']['output']>;
  provinceRates?: Maybe<Array<AccountAudienceDataRate>>;
}

export interface XhsEngagementInsightSection {
  averageLikes?: Maybe<Scalars['Float']['output']>;
  averagePostsPerWeek: Scalars['Float']['output'];
  averageSaved?: Maybe<Scalars['Float']['output']>;
  averageViews?: Maybe<Scalars['Float']['output']>;
  engagementViewsRate?: Maybe<Scalars['Float']['output']>;
  postCategories?: Maybe<Array<AccountAudienceDataRate>>;
  postsHabit: Array<InfluencerNewProfilePostsHabit>;
}

export interface XhsInfluencer {
  age?: Maybe<Scalars['Int']['output']>;
  avatar?: Maybe<Scalars['String']['output']>;
  averageLikes?: Maybe<Scalars['Float']['output']>;
  averageViews?: Maybe<Scalars['Float']['output']>;
  bio?: Maybe<Scalars['String']['output']>;
  country: CountryName;
  gender: Genders;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  popularPosts: Array<SocialAccountPopularPost>;
  socialAccountEngagement?: Maybe<Scalars['Int']['output']>;
  socialAccountEngagementFollowersRate?: Maybe<Scalars['Float']['output']>;
  socialAccountEngagementViewsRate?: Maybe<Scalars['Float']['output']>;
  socialAccountFollowers?: Maybe<Scalars['Int']['output']>;
  socialAccountId: Scalars['ID']['output'];
  socialAccountStatus: SocialAccountStatus;
}

export interface XhsInfluencerSearchResult {
  totalNumber: Scalars['Int']['output'];
  xhsAccounts: Array<XhsInfluencer>;
}

export interface XhsPostSection {
  picturePosts: Array<InfluencerFeedPost>;
  videoPosts: Array<InfluencerFeedPost>;
}

/** An enumeration. */
export enum XhsPostType {
  NORMAL = 'NORMAL',
  SPONSORED = 'SPONSORED',
}

export interface YouTubeBrandAccount {
  averageViews: Scalars['Float']['output'];
  /** youtube mentioned brand account id */
  id: Scalars['Int']['output'];
  mentionPostsCount: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  postIds: Array<Scalars['Int']['output']>;
}

export interface YouTubeBrandAccounts {
  averageEngagementRate?: Maybe<Scalars['Float']['output']>;
  averageViews?: Maybe<Scalars['Float']['output']>;
  brandAccounts?: Maybe<Array<YouTubeBrandAccount>>;
}

export interface YouTubeCmsRevenueForInfluencer {
  revenue?: Maybe<TotalCmsRevenue>;
}

export interface YouTubeCmsVideoAudience {
  ageGenderRates: YoutubeAgeGenderGroup;
  countryRates: Array<YoutubeAudienceCountryRate>;
  genderRates: YoutubeAudienceGenderRate;
}

export interface YouTubeCmsVideoStats {
  assetTitle?: Maybe<Scalars['String']['output']>;
  avgPercentageView: Scalars['Float']['output'];
  avgViewDuration: Scalars['Float']['output'];
  id: Scalars['String']['output'];
  videoThumbnail: Scalars['String']['output'];
  videoTitle?: Maybe<Scalars['String']['output']>;
  views: Scalars['Int']['output'];
}

export interface YouTubeInfluencer {
  age?: Maybe<Scalars['Int']['output']>;
  analyticsWarning: Scalars['Boolean']['output'];
  avatar?: Maybe<Scalars['String']['output']>;
  averageLikes?: Maybe<Scalars['Float']['output']>;
  averageViews?: Maybe<Scalars['Float']['output']>;
  categories: Array<CategoryName>;
  country: CountryName;
  engagementPostedCount?: Maybe<Scalars['Int']['output']>;
  engagementProposedCount?: Maybe<Scalars['Int']['output']>;
  gender: Genders;
  /** Influencer Id */
  id: Scalars['Int']['output'];
  influencerEngagement?: Maybe<Scalars['Int']['output']>;
  influencerEngagementRate?: Maybe<Scalars['Float']['output']>;
  influencerFollowers?: Maybe<Scalars['Int']['output']>;
  isBrandAccount: Scalars['Boolean']['output'];
  marketplaceJoinedCount?: Maybe<Scalars['Int']['output']>;
  marketplacePostedCount?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  popularPosts?: Maybe<Array<SocialAccountPopularPost>>;
  socialAccountEngagement?: Maybe<Scalars['Int']['output']>;
  socialAccountEngagementFollowersRate?: Maybe<Scalars['Float']['output']>;
  socialAccountEngagementViewsRate?: Maybe<Scalars['Float']['output']>;
  socialAccountFollowers?: Maybe<Scalars['Int']['output']>;
  /** Social Account System Id */
  socialAccountId: Scalars['Int']['output'];
  /** Social Account Status */
  socialAccountStatus: SocialAccountStatus;
}

export interface YoutubeAccountForProfileV2 {
  analyticsEnabled: Scalars['Boolean']['output'];
  avatar?: Maybe<Scalars['String']['output']>;
  followersCount?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  profile?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
}

export interface YoutubeAgeGenderGroup {
  ageGroup: Array<FollowersAgeGroup>;
  femaleRate: Array<Scalars['Float']['output']>;
  maleRate: Array<Scalars['Float']['output']>;
  otherRate: Array<Scalars['Float']['output']>;
}

export interface YoutubeAnalyticsAccountCard {
  accountId: Scalars['Int']['output'];
  avatarUrl: Scalars['String']['output'];
  channelName: Scalars['String']['output'];
  elementId: Scalars['Int']['output'];
  profileUrl: Scalars['String']['output'];
}

export interface YoutubeAnalyticsAccountOverview {
  accountId: Scalars['Int']['output'];
  graph: YoutubeCompareOverviewHistory;
  summary: YoutubeCompareOverviewSummary;
}

export interface YoutubeAnalyticsAccountPosts {
  accountId: Scalars['Int']['output'];
  postHabit: Array<YoutubeAnalyticsPostsPostingHabit>;
  posts: Array<YoutubeCompareAccountPost>;
}

export interface YoutubeAnalyticsAccountPostsInDate {
  accountId: Scalars['Int']['output'];
  posts: Array<YoutubeAnalyticsPostPayload>;
}

export interface YoutubeAnalyticsAccountTagRanking {
  accountId: Scalars['Int']['output'];
  tagsRanking: Array<YoutubeTagRanking>;
}

export interface YoutubeAnalyticsAudienceAgeBreakdownAgeGroupPayload {
  end: Scalars['Int']['output'];
  start: Scalars['Int']['output'];
}

export interface YoutubeAnalyticsAudienceAgeBreakdownPayload {
  ageGroup: Array<YoutubeAnalyticsAudienceAgeBreakdownAgeGroupPayload>;
  femaleRate: Array<Scalars['Float']['output']>;
  maleRate: Array<Scalars['Float']['output']>;
  otherRate: Array<Scalars['Float']['output']>;
}

export interface YoutubeAnalyticsAudienceGenderBreakdownPayload {
  femaleRate: Scalars['Float']['output'];
  maleRate: Scalars['Float']['output'];
  otherRate: Scalars['Float']['output'];
}

export interface YoutubeAnalyticsAudiencePayload {
  ageRate: YoutubeAnalyticsAudienceAgeBreakdownPayload;
  genderRate: YoutubeAnalyticsAudienceGenderBreakdownPayload;
  regionRate: Array<YoutubeAnalyticsAudienceRegionPayload>;
}

export interface YoutubeAnalyticsAudienceRegionPayload {
  name: Scalars['String']['output'];
  rate: Scalars['Float']['output'];
}

export interface YoutubeAnalyticsCategory {
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
}

export interface YoutubeAnalyticsCommentsPayload {
  comments: Array<YoutubeAnalyticsPostCommentItem>;
}

export interface YoutubeAnalyticsCompareAccountCard {
  accountId: Scalars['Int']['output'];
  avatarUrl: Scalars['String']['output'];
  channelName: Scalars['String']['output'];
  elementId: Scalars['Int']['output'];
  profileUrl: Scalars['String']['output'];
}

export interface YoutubeAnalyticsCompareAccountCards {
  compareAccounts: Array<YoutubeAnalyticsCompareAccountCard>;
  mainAccount?: Maybe<YoutubeAnalyticsAccountCard>;
}

export interface YoutubeAnalyticsComparePostComment {
  commentId: Scalars['String']['output'];
  content: Scalars['String']['output'];
  posterName: Scalars['String']['output'];
}

export interface YoutubeAnalyticsComparePostsSort {
  field?: InputMaybe<YoutubeAnalyticsComparePostsSortOrder>;
  order?: InputMaybe<Order>;
}

export enum YoutubeAnalyticsComparePostsSortOrder {
  COMMENT = 'COMMENT',
  DISLIKE = 'DISLIKE',
  DURATION = 'DURATION',
  LIKE = 'LIKE',
  NEGATIVE_RATE = 'NEGATIVE_RATE',
  POSITIVE_RATE = 'POSITIVE_RATE',
  POST_DATE = 'POST_DATE',
  VIEWS = 'VIEWS',
}

export interface YoutubeAnalyticsCompareRelatedPost {
  channelName: Scalars['String']['output'];
  comments: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  postDate: Scalars['DateTime']['output'];
  videoCaption: Scalars['String']['output'];
  videoDuration: Scalars['Int']['output'];
  videoId: Scalars['String']['output'];
  videoThumbnail?: Maybe<Scalars['String']['output']>;
  videoTitle: Scalars['String']['output'];
  views: Scalars['Int']['output'];
}

export interface YoutubeAnalyticsCompareRelatedPosts {
  posts: Array<YoutubeAnalyticsCompareRelatedPost>;
  tag: Scalars['String']['output'];
}

export interface YoutubeAnalyticsCountriesCategoriesPayload {
  categories: Array<YoutubeAnalyticsCategory>;
  countries: Array<YoutubeAnalyticsCountry>;
  date?: Maybe<Scalars['String']['output']>;
}

export interface YoutubeAnalyticsCountry {
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
}

export interface YoutubeAnalyticsHistoryDataPoint {
  count: Scalars['Int']['output'];
  date: Scalars['Date']['output'];
}

export interface YoutubeAnalyticsOverviewHistoryItem {
  count: Scalars['Int']['output'];
  date: Scalars['Date']['output'];
}

export interface YoutubeAnalyticsOverviewItem {
  growth: Scalars['Long']['output'];
  history: Array<YoutubeAnalyticsOverviewHistoryItem>;
  percentage: Scalars['Float']['output'];
  total: Scalars['Long']['output'];
}

export interface YoutubeAnalyticsOverviewPayload {
  comment: YoutubeAnalyticsOverviewItem;
  dislike: YoutubeAnalyticsOverviewItem;
  lastUpdated: Scalars['DateTime']['output'];
  like: YoutubeAnalyticsOverviewItem;
  post: YoutubeAnalyticsOverviewItem;
  subscriber: YoutubeAnalyticsOverviewItem;
  view: YoutubeAnalyticsOverviewItem;
}

export interface YoutubeAnalyticsPostComment {
  commentId: Scalars['String']['output'];
  content: Scalars['String']['output'];
  posterName: Scalars['String']['output'];
}

export interface YoutubeAnalyticsPostCommentItem {
  commentId: Scalars['String']['output'];
  content: Scalars['String']['output'];
  created: Scalars['Date']['output'];
  postUrl: Scalars['String']['output'];
  username: Scalars['String']['output'];
}

export interface YoutubeAnalyticsPostPayload {
  averageViewDuration?: Maybe<Scalars['Long']['output']>;
  averageViewPercentage?: Maybe<Scalars['Float']['output']>;
  comments: Scalars['Long']['output'];
  dislikes: Scalars['Long']['output'];
  id: Scalars['Int']['output'];
  likes: Scalars['Long']['output'];
  postDate: Scalars['Date']['output'];
  subscribers: Scalars['Int']['output'];
  videoCaption: Scalars['String']['output'];
  videoComments: Array<YoutubeAnalyticsPostComment>;
  videoDuration: Scalars['Int']['output'];
  videoEmbed: Scalars['String']['output'];
  videoId: Scalars['String']['output'];
  videoTags: Array<Scalars['String']['output']>;
  videoThumbnail?: Maybe<Scalars['String']['output']>;
  videoTitle: Scalars['String']['output'];
  views: Scalars['Long']['output'];
}

export interface YoutubeAnalyticsPostPayloads {
  avatar: Scalars['String']['output'];
  channelName: Scalars['String']['output'];
  posts: Array<YoutubeAnalyticsPostPayload>;
  subscribers: Scalars['Int']['output'];
}

export interface YoutubeAnalyticsPostWithChannelDetailsPayload {
  avatar: Scalars['String']['output'];
  averageViewDuration?: Maybe<Scalars['Long']['output']>;
  averageViewPercentage?: Maybe<Scalars['Float']['output']>;
  channelName: Scalars['String']['output'];
  comments: Scalars['Long']['output'];
  dislikes: Scalars['Long']['output'];
  id: Scalars['Int']['output'];
  likes: Scalars['Long']['output'];
  postDate: Scalars['Date']['output'];
  subscribers: Scalars['Int']['output'];
  videoCaption: Scalars['String']['output'];
  videoComments: Array<YoutubeAnalyticsPostComment>;
  videoDuration: Scalars['Int']['output'];
  videoEmbed: Scalars['String']['output'];
  videoId: Scalars['String']['output'];
  videoTags: Array<Scalars['String']['output']>;
  videoThumbnail?: Maybe<Scalars['String']['output']>;
  videoTitle: Scalars['String']['output'];
  views: Scalars['Long']['output'];
}

export interface YoutubeAnalyticsPostsAverageEngagement {
  averageComments: YoutubeStatisticsData;
  averageDislikes: YoutubeStatisticsData;
  averageLikes: YoutubeStatisticsData;
  averagePostsPerWeek?: Maybe<YoutubeStatisticsData>;
  averageViews: YoutubeStatisticsData;
}

/**
 *  YoutubeAnalyticsPostsPayload is the individual data
 *    for a list of YoutubeAnalyticsPost
 *  Some video information such as video embed, comments, descriptions are not included
 *  Some video analytics data such as revenue are not included
 *  Duration is in seconds
 */
export interface YoutubeAnalyticsPostsPayload {
  averageViewDuration?: Maybe<Scalars['Long']['output']>;
  averageViewPercentage?: Maybe<Scalars['Float']['output']>;
  comments: Scalars['Long']['output'];
  dislikes: Scalars['Long']['output'];
  id: Scalars['Int']['output'];
  isSponsoredPost: Scalars['Boolean']['output'];
  likes: Scalars['Long']['output'];
  postDate: Scalars['DateTime']['output'];
  shares: Scalars['Long']['output'];
  videoDuration: Scalars['Int']['output'];
  videoId: Scalars['String']['output'];
  videoThumbnail?: Maybe<Scalars['String']['output']>;
  videoTitle: Scalars['String']['output'];
  views: Scalars['Long']['output'];
}

export interface YoutubeAnalyticsPostsPayloads {
  posts: Array<YoutubeAnalyticsPostsPayload>;
}

export interface YoutubeAnalyticsPostsPostingHabit {
  averageComments: Scalars['Float']['output'];
  averageLikes: Scalars['Float']['output'];
  engagementRate: Scalars['Float']['output'];
  hour: Scalars['Int']['output'];
  weekday: Scalars['Int']['output'];
}

export interface YoutubeAnalyticsPostsSortInput {
  field?: InputMaybe<YoutubeAnalyticsPostsSortOrder>;
  order?: InputMaybe<Order>;
}

export enum YoutubeAnalyticsPostsSortOrder {
  AVERAGE_VIEW_DURATION = 'AVERAGE_VIEW_DURATION',
  AVERAGE_VIEW_PERCENTAGE = 'AVERAGE_VIEW_PERCENTAGE',
  COMMENT = 'COMMENT',
  DISLIKE = 'DISLIKE',
  DURATION = 'DURATION',
  ENGAGEMENT = 'ENGAGEMENT',
  LIKE = 'LIKE',
  NEGATIVE_RATE = 'NEGATIVE_RATE',
  POSITIVE_RATE = 'POSITIVE_RATE',
  POST_DATE = 'POST_DATE',
  SHARE = 'SHARE',
  VIEWS = 'VIEWS',
}

export interface YoutubeAnalyticsPostsStatisticsPayload {
  averageEngagement: YoutubeAnalyticsPostsAverageEngagement;
  postHabit: Array<YoutubeAnalyticsPostsPostingHabit>;
}

export interface YoutubeAnalyticsRelatedPostPayload {
  comments: Scalars['Long']['output'];
  id: Scalars['Int']['output'];
  postDate: Scalars['DateTime']['output'];
  videoCaption: Scalars['String']['output'];
  videoDuration: Scalars['Int']['output'];
  videoEmbed: Scalars['String']['output'];
  videoId: Scalars['String']['output'];
  videoThumbnail?: Maybe<Scalars['String']['output']>;
  videoTitle: Scalars['String']['output'];
  views: Scalars['Long']['output'];
}

export interface YoutubeAnalyticsRelatedPostsPayload {
  posts: Array<YoutubeAnalyticsRelatedPostPayload>;
}

export interface YoutubeAnalyticsShortsPayload {
  averageViewDuration?: Maybe<Scalars['Long']['output']>;
  averageViewPercentage?: Maybe<Scalars['Float']['output']>;
  comments: Scalars['Long']['output'];
  dislikes: Scalars['Long']['output'];
  id: Scalars['Int']['output'];
  isSponsoredPost: Scalars['Boolean']['output'];
  likes: Scalars['Long']['output'];
  postDate: Scalars['DateTime']['output'];
  shares: Scalars['Long']['output'];
  videoDuration: Scalars['Int']['output'];
  videoId: Scalars['String']['output'];
  videoThumbnail?: Maybe<Scalars['String']['output']>;
  videoTitle: Scalars['String']['output'];
  views: Scalars['Long']['output'];
}

export interface YoutubeAnalyticsShortsPayloads {
  shorts: Array<YoutubeAnalyticsShortsPayload>;
}

export interface YoutubeAnalyticsSponsoredPostsSortInput {
  field?: InputMaybe<YoutubeAnalyticsSponsoredPostsSortOrder>;
  order?: InputMaybe<Order>;
}

export enum YoutubeAnalyticsSponsoredPostsSortOrder {
  COMMENT = 'COMMENT',
  DURATION = 'DURATION',
  LIKES = 'LIKES',
  POST_DATE = 'POST_DATE',
  SHARED = 'SHARED',
  VIEWS = 'VIEWS',
}

export interface YoutubeAnalyticsTagRankingPayload {
  averageComment: Scalars['Float']['output'];
  averageDislike: Scalars['Float']['output'];
  averageEngagement: Scalars['Float']['output'];
  averageLike: Scalars['Float']['output'];
  averageShare: Scalars['Float']['output'];
  engagementRate: Scalars['Float']['output'];
  posts: Scalars['Int']['output'];
  tag: Scalars['String']['output'];
  views: Scalars['Long']['output'];
}

export interface YoutubeAnalyticsTagRankingPayloads {
  tags: Array<YoutubeAnalyticsTagRankingPayload>;
}

export interface YoutubeAnalyticsTagRankingSortInput {
  field?: InputMaybe<YoutubeAnalyticsTagRankingSortOrder>;
  order?: InputMaybe<Order>;
}

export enum YoutubeAnalyticsTagRankingSortOrder {
  AVERAGE_COMMENT = 'AVERAGE_COMMENT',
  AVERAGE_DISLIKE = 'AVERAGE_DISLIKE',
  AVERAGE_ENGAGEMENT = 'AVERAGE_ENGAGEMENT',
  AVERAGE_LIKE = 'AVERAGE_LIKE',
  AVERAGE_SHARE = 'AVERAGE_SHARE',
  ENGAGEMENT_RATE = 'ENGAGEMENT_RATE',
  POST = 'POST',
  VIEWS = 'VIEWS',
}

export interface YoutubeAnalyticsTrendPayload {
  channel: Scalars['String']['output'];
  channelAvatar: Scalars['String']['output'];
  comments: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  influencerId?: Maybe<Scalars['Int']['output']>;
  likes: Scalars['Int']['output'];
  position: Scalars['Int']['output'];
  postDate: Scalars['DateTime']['output'];
  socialAccountId?: Maybe<Scalars['Int']['output']>;
  videoCaption: Scalars['String']['output'];
  videoDuration: Scalars['Int']['output'];
  videoId: Scalars['String']['output'];
  videoThumbnail?: Maybe<Scalars['String']['output']>;
  videoTitle: Scalars['String']['output'];
  views: Scalars['Int']['output'];
}

export interface YoutubeAnalyticsTrendPostDetailsPayload {
  channel: Scalars['String']['output'];
  channelAvatar: Scalars['String']['output'];
  comments: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  likes: Scalars['Int']['output'];
  postDate: Scalars['DateTime']['output'];
  videoCaption: Scalars['String']['output'];
  videoDuration: Scalars['Int']['output'];
  videoId: Scalars['String']['output'];
  videoThumbnail?: Maybe<Scalars['String']['output']>;
  videoTitle: Scalars['String']['output'];
  views: Scalars['Int']['output'];
}

export interface YoutubeAnalyticsTrendsPayloads {
  posts: Array<YoutubeAnalyticsTrendPayload>;
}

export interface YoutubeAudienceCountryRate {
  id: Scalars['String']['output'];
  rate: Scalars['Float']['output'];
}

export interface YoutubeAudienceGenderRate {
  femaleRate: Scalars['Float']['output'];
  maleRate: Scalars['Float']['output'];
  otherRate: Scalars['Float']['output'];
}

export interface YoutubeCmsChannelDetail {
  avatar?: Maybe<Scalars['String']['output']>;
  channelId: Scalars['String']['output'];
  channelName?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  influencerId?: Maybe<Scalars['Int']['output']>;
  shareRate?: Maybe<Scalars['Float']['output']>;
  socialAccountId?: Maybe<Scalars['Int']['output']>;
  updatedDate?: Maybe<Scalars['Date']['output']>;
}

export interface YoutubeCmsChannelForInfluencer {
  currency: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  revenue: Scalars['Float']['output'];
  rpm?: Maybe<Scalars['Float']['output']>;
  shareRate: Scalars['Float']['output'];
  thumbnail?: Maybe<Scalars['String']['output']>;
}

export interface YoutubeCmsChannelInfo {
  avatar?: Maybe<Scalars['String']['output']>;
  channelId: Scalars['String']['output'];
  channelName?: Maybe<Scalars['String']['output']>;
}

export interface YoutubeCmsConfirmedAsset {
  assetId: Scalars['String']['output'];
  assetName?: Maybe<Scalars['String']['output']>;
  currency: Scalars['String']['output'];
  ownerChannelId?: Maybe<Scalars['String']['output']>;
  ownerChannelName?: Maybe<Scalars['String']['output']>;
  partnerRevenue?: Maybe<Scalars['Float']['output']>;
  profit?: Maybe<Scalars['Float']['output']>;
  shareCreator?: Maybe<Scalars['Float']['output']>;
  shareRate?: Maybe<Scalars['Float']['output']>;
}

export interface YoutubeCmsConfirmedChannel {
  avatar?: Maybe<Scalars['String']['output']>;
  channelId: Scalars['String']['output'];
  channelName?: Maybe<Scalars['String']['output']>;
  currency: Scalars['String']['output'];
  partnerRevenue?: Maybe<Scalars['Float']['output']>;
  profit?: Maybe<Scalars['Float']['output']>;
  shareCreator?: Maybe<Scalars['Float']['output']>;
  shareRate?: Maybe<Scalars['Float']['output']>;
}

export interface YoutubeCmsConfirmedChannelSummary {
  currency: Scalars['String']['output'];
  partnerRevenue?: Maybe<Scalars['Float']['output']>;
  profit?: Maybe<Scalars['Float']['output']>;
  shareCreator: Scalars['Float']['output'];
}

export interface YoutubeCmsConfirmedRevenueLatestMonth {
  month: Scalars['Date']['output'];
}

export interface YoutubeCmsEstimateAsset {
  assetId: Scalars['String']['output'];
  assetName?: Maybe<Scalars['String']['output']>;
  belongMultipleChannels: Scalars['Boolean']['output'];
  cmsChannelId?: Maybe<Scalars['Int']['output']>;
  currency: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  isOwner: Scalars['Boolean']['output'];
  lastUpdatedDate?: Maybe<Scalars['Date']['output']>;
  ownerChannelId?: Maybe<Scalars['String']['output']>;
  ownerChannelName?: Maybe<Scalars['String']['output']>;
  partnerRevenue?: Maybe<Scalars['Float']['output']>;
  profit?: Maybe<Scalars['Float']['output']>;
  rpm?: Maybe<Scalars['Float']['output']>;
  shareCreator?: Maybe<Scalars['Float']['output']>;
  shareRate?: Maybe<Scalars['Float']['output']>;
  taProfit?: Maybe<Scalars['Float']['output']>;
  taRevenue?: Maybe<Scalars['Float']['output']>;
  taRpm?: Maybe<Scalars['Float']['output']>;
  taShareRate?: Maybe<Scalars['Float']['output']>;
}

export interface YoutubeCmsEstimateChannel {
  avatar?: Maybe<Scalars['String']['output']>;
  channelId: Scalars['String']['output'];
  channelName?: Maybe<Scalars['String']['output']>;
  currency: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  lastUpdatedDate?: Maybe<Scalars['Date']['output']>;
  partnerRevenue?: Maybe<Scalars['Float']['output']>;
  profit?: Maybe<Scalars['Float']['output']>;
  rpm?: Maybe<Scalars['Float']['output']>;
  shareCreator?: Maybe<Scalars['Float']['output']>;
  shareRate?: Maybe<Scalars['Float']['output']>;
  taProfit?: Maybe<Scalars['Float']['output']>;
  taRevenue?: Maybe<Scalars['Float']['output']>;
  taRpm?: Maybe<Scalars['Float']['output']>;
  taShareRate?: Maybe<Scalars['Float']['output']>;
}

export interface YoutubeCmsEstimateChannelSummary {
  currency: Scalars['String']['output'];
  partnerRevenue?: Maybe<Scalars['Float']['output']>;
  profit?: Maybe<Scalars['Float']['output']>;
  rpm?: Maybe<Scalars['Float']['output']>;
  shareCreator: Scalars['Float']['output'];
  taProfit?: Maybe<Scalars['Float']['output']>;
  taRevenue?: Maybe<Scalars['Float']['output']>;
  taRpm?: Maybe<Scalars['Float']['output']>;
}

export interface YoutubeCmsInvoice {
  approvedDate?: Maybe<Scalars['Date']['output']>;
  createdDate: Scalars['Date']['output'];
  currency: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  influencerName: Scalars['String']['output'];
  /** Invoice name */
  name: Scalars['String']['output'];
  paymentTotal: Scalars['Float']['output'];
}

export interface YoutubeCmsInvoiceDownloadUrl {
  url: Scalars['String']['output'];
}

export interface YoutubeCmsInvoiceForInfluencer {
  id: Scalars['Int']['output'];
  month: Scalars['Date']['output'];
  updatedDate: Scalars['Date']['output'];
}

export interface YoutubeCmsMusicForInfluencer {
  currency: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  revenue: Scalars['Float']['output'];
  rpm?: Maybe<Scalars['Float']['output']>;
  shareRate: Scalars['Float']['output'];
}

export interface YoutubeCmsStartMonth {
  month?: Maybe<Scalars['Date']['output']>;
}

export interface YoutubeCmsVideo {
  currency: Scalars['String']['output'];
  id: Scalars['String']['output'];
  revenue: Scalars['Float']['output'];
  rpm?: Maybe<Scalars['Float']['output']>;
  shareRate: Scalars['Float']['output'];
  thumbnail?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
}

export interface YoutubeCompareAccountOverview {
  accountId: Scalars['Int']['output'];
  graph: YoutubeCompareOverviewHistory;
  summary: YoutubeCompareOverviewSummary;
}

export interface YoutubeCompareAccountPost {
  comments: Scalars['Int']['output'];
  duration: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  likes: Scalars['Int']['output'];
  thumbnail?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  views: Scalars['Int']['output'];
}

export interface YoutubeCompareAccountPosts {
  accountId: Scalars['Int']['output'];
  postHabit: Array<YoutubeAnalyticsPostsPostingHabit>;
  posts: Array<YoutubeCompareAccountPost>;
}

export interface YoutubeCompareAccountPostsInDate {
  accountId: Scalars['Int']['output'];
  posts: Array<YoutubeComparePost>;
}

export interface YoutubeCompareAccountTagRanking {
  accountId: Scalars['Int']['output'];
  tagsRanking: Array<YoutubeTagRanking>;
}

export interface YoutubeCompareAllPostsInDate {
  compareAccounts: Array<YoutubeCompareAccountPostsInDate>;
  mainAccount: YoutubeAnalyticsAccountPostsInDate;
}

export interface YoutubeCompareOverview {
  compareAccounts: Array<YoutubeCompareAccountOverview>;
  mainAccount: YoutubeCompareAccountOverview;
}

export interface YoutubeCompareOverviewHistory {
  comments: Array<YoutubeAnalyticsHistoryDataPoint>;
  dislikes: Array<YoutubeAnalyticsHistoryDataPoint>;
  likes: Array<YoutubeAnalyticsHistoryDataPoint>;
  posts: Array<YoutubeAnalyticsHistoryDataPoint>;
  subscribers: Array<YoutubeAnalyticsHistoryDataPoint>;
  views: Array<YoutubeAnalyticsHistoryDataPoint>;
}

export interface YoutubeCompareOverviewSummary {
  averageComments: YoutubeCompareOverviewSummaryData;
  averageLikes: YoutubeCompareOverviewSummaryData;
  averageViews: YoutubeCompareOverviewSummaryData;
  maximumLikes: YoutubeCompareOverviewSummaryData;
  maximumViews: YoutubeCompareOverviewSummaryData;
  totalPosts: YoutubeCompareOverviewSummaryData;
  totalSubscribers: YoutubeCompareOverviewSummaryData;
}

export interface YoutubeCompareOverviewSummaryData {
  growth: Scalars['Int']['output'];
  percentage: Scalars['Float']['output'];
  total: Scalars['Int']['output'];
}

export interface YoutubeComparePost {
  comments: Scalars['Int']['output'];
  dislikes: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  likes: Scalars['Int']['output'];
  postDate: Scalars['Date']['output'];
  subscribers: Scalars['Int']['output'];
  videoCaption: Scalars['String']['output'];
  videoComments: Array<YoutubeAnalyticsComparePostComment>;
  videoDuration: Scalars['Int']['output'];
  videoEmbed: Scalars['String']['output'];
  videoId: Scalars['String']['output'];
  videoTags: Array<Scalars['String']['output']>;
  videoThumbnail?: Maybe<Scalars['String']['output']>;
  videoTitle: Scalars['String']['output'];
  views: Scalars['Int']['output'];
}

export interface YoutubeComparePostList {
  accountId: Scalars['Int']['output'];
  posts: Array<YoutubeComparePostListItem>;
}

export interface YoutubeComparePostListItem {
  comments: Scalars['Int']['output'];
  dislikes: Scalars['Int']['output'];
  duration: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  likes: Scalars['Int']['output'];
  postDate: Scalars['Date']['output'];
  subscribers: Scalars['Int']['output'];
  thumbnail?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  url: Scalars['String']['output'];
  views: Scalars['Int']['output'];
}

export interface YoutubeComparePosts {
  compareAccounts: Array<YoutubeCompareAccountPosts>;
  mainAccount: YoutubeAnalyticsAccountPosts;
}

export interface YoutubeCompareTagRanking {
  compareAccounts: Array<YoutubeCompareAccountTagRanking>;
  mainAccount: YoutubeAnalyticsAccountTagRanking;
}

export interface YoutubeInfluencerSearchResultV3 {
  totalNumber: Scalars['Int']['output'];
  youtubeAccounts: Array<YoutubeInfluencerV3>;
}

export interface YoutubeInfluencerV3 {
  age?: Maybe<Scalars['Int']['output']>;
  avatar?: Maybe<Scalars['String']['output']>;
  averageLikes?: Maybe<Scalars['Float']['output']>;
  averageViews?: Maybe<Scalars['Float']['output']>;
  country: CountryName;
  gender: Genders;
  hasSimilarAccounts: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isBrandAccount: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  popularPosts?: Maybe<Array<SocialAccountPopularPost>>;
  socialAccountEngagement?: Maybe<Scalars['Int']['output']>;
  socialAccountEngagementFollowersRate?: Maybe<Scalars['Float']['output']>;
  socialAccountEngagementViewsRate?: Maybe<Scalars['Float']['output']>;
  socialAccountFollowers?: Maybe<Scalars['Int']['output']>;
  socialAccountId: Scalars['ID']['output'];
  socialAccountStatus: SocialAccountStatus;
}

export interface YoutubeMCNAdminRedirectUrlInput {
  callbackUrl: Scalars['String']['input'];
}

export interface YoutubeMCNAdminRedirectUrlPayload {
  redirectUri: Scalars['String']['output'];
}

export interface YoutubeMusicCategory {
  category: Scalars['String']['output'];
  id: Scalars['Int']['output'];
}

export interface YoutubeMusicItem {
  downloadUrl: Scalars['String']['output'];
  /** Duration in seconds */
  duration?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  thumbnailUrl?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
}

export interface YoutubeStatisticsData {
  growth: Scalars['Float']['output'];
  value: Scalars['Float']['output'];
}

export interface YoutubeTagRanking {
  rank: Scalars['Int']['output'];
  tag: Scalars['String']['output'];
  views: Scalars['Int']['output'];
}

/** An enumeration. */
export enum YoutubeTrafficSource {
  ADVERTISING = 'ADVERTISING',
  ANNOTATION = 'ANNOTATION',
  CAMPAIGN_CARD = 'CAMPAIGN_CARD',
  END_SCREEN = 'END_SCREEN',
  EXT_URL = 'EXT_URL',
  HASHTAGS = 'HASHTAGS',
  LIVE_REDIRECT = 'LIVE_REDIRECT',
  NOTIFICATION = 'NOTIFICATION',
  NO_LINK_EMBEDDED = 'NO_LINK_EMBEDDED',
  NO_LINK_OTHER = 'NO_LINK_OTHER',
  PLAYLIST = 'PLAYLIST',
  PRODUCT_PAGE = 'PRODUCT_PAGE',
  PROMOTED = 'PROMOTED',
  RELATED_VIDEO = 'RELATED_VIDEO',
  SHORTS = 'SHORTS',
  SHORTS_CONTENT_LINKS = 'SHORTS_CONTENT_LINKS',
  SOUND_PAGE = 'SOUND_PAGE',
  SUBSCRIBER = 'SUBSCRIBER',
  VIDEO_REMIXES = 'VIDEO_REMIXES',
  YT_CHANNEL = 'YT_CHANNEL',
  YT_OTHER_PAGE = 'YT_OTHER_PAGE',
  YT_PLAYLIST_PAGE = 'YT_PLAYLIST_PAGE',
  YT_SEARCH = 'YT_SEARCH',
}

export interface _Service {
  sdl: Scalars['String']['output'];
}

export type AddCommentMutationVariables = Exact<{
  input: AddCommentInput;
}>;

export interface AddCommentMutation {
  addComment?: { ok: boolean } | null;
}

export type AddEmailTemplateMutationVariables = Exact<{
  input: AddEmailTemplateInput;
}>;

export interface AddEmailTemplateMutation {
  addEmailTemplate?: { ok: boolean } | null;
}

export type AddFanMutationVariables = Exact<{
  input: AddFanInput;
}>;

export interface AddFanMutation {
  addFan?: { ok: boolean } | null;
}

export type AddFanTagsMutationVariables = Exact<{
  input: AddFanTagsInput;
}>;

export interface AddFanTagsMutation {
  addFanTags?: { ok: boolean } | null;
}

export type AddFormMutationVariables = Exact<{
  input: AddFormInput;
}>;

export interface AddFormMutation {
  addForm?: { ok: boolean } | null;
}

export type AnyXCheckEmailMutationVariables = Exact<{
  input: AnyXCheckEmailInput;
}>;

export interface AnyXCheckEmailMutation {
  anyXCheckEmail?: { ok: boolean } | null;
}

export type AnyXSocialAuthConnectMutationVariables = Exact<{
  input: AnyXSocialAuthConnectInput;
}>;

export interface AnyXSocialAuthConnectMutation {
  anyXSocialAuthConnect?: { ok: boolean } | null;
}

export type AnyXSocialAuthConnectForLinkBioMutationVariables = Exact<{
  input: AnyXSocialAuthConnectForLinkBioInput;
}>;

export interface AnyXSocialAuthConnectForLinkBioMutation {
  anyXSocialAuthConnectForLinkBio?: {
    connectedAccounts: Array<{
      avatar: string;
      followersCount?: number | null;
      id: string;
      name: string;
      socialAccountType: SocialAccountType;
      url: string;
    }>;
  } | null;
}

export type AnyXSocialAuthDisconnectMutationVariables = Exact<{
  input: AnyXSocialAuthDisconnectInput;
}>;

export interface AnyXSocialAuthDisconnectMutation {
  anyXSocialAuthDisconnect?: { ok: boolean } | null;
}

export type AnyXSocialAuthReconnectForLinkBioMutationVariables = Exact<{
  input: AnyXSocialAuthReconnectForLinkBioInput;
}>;

export interface AnyXSocialAuthReconnectForLinkBioMutation {
  anyXSocialAuthReconnectForLinkBio?: {
    accounts: Array<{
      avatar: string;
      followersCount?: number | null;
      id: string;
      name: string;
      socialAccountType: SocialAccountType;
      url: string;
    }>;
  } | null;
}

export type AnyXSocialAuthReconnectTikTokMutationVariables = Exact<{
  input: AnyXSocialAuthReconnectTikTokInput;
}>;

export interface AnyXSocialAuthReconnectTikTokMutation {
  anyXSocialAuthReconnectTikTok?: { ok: boolean } | null;
}

export type AnyXSocialAuthReconnectTtcmMutationVariables = Exact<{
  input: AnyXSocialAuthReconnectTTCMInput;
}>;

export interface AnyXSocialAuthReconnectTtcmMutation {
  anyXSocialAuthReconnectTtcm?: { ok: boolean } | null;
}

export type AnyXSocialAuthSignInMutationVariables = Exact<{
  input: AnyXSocialAuthSignInInput;
}>;

export interface AnyXSocialAuthSignInMutation {
  anyXSocialAuthSignIn?: { token: string } | null;
}

export type AnyXSocialAuthSignInInstagramLineMutationVariables = Exact<{
  input: AnyXSocialAuthSignInInstagramLineInput;
}>;

export interface AnyXSocialAuthSignInInstagramLineMutation {
  anyXSocialAuthSignInInstagramLine?: { ok: boolean } | null;
}

export type AnyXSocialAuthSignInOrSignUpMutationVariables = Exact<{
  input: AnyXSocialAuthSignInOrSignUpInput;
}>;

export interface AnyXSocialAuthSignInOrSignUpMutation {
  anyXSocialAuthSignInOrSignUp?: {
    hasIgAccount: boolean;
    signIn?: { token: string } | null;
    signUp?: { email?: string | null; name: string; uuid: string } | null;
  } | null;
}

export type AnyXSocialAuthSignUpMutationVariables = Exact<{
  input: AnyXSocialAuthSignUpInput;
}>;

export interface AnyXSocialAuthSignUpMutation {
  anyXSocialAuthSignUp?: { token: string } | null;
}

export type AnyXSocialAuthSignUpPartnerMutationVariables = Exact<{
  input: AnyXSocialAuthSignUpPartnerInput;
}>;

export interface AnyXSocialAuthSignUpPartnerMutation {
  anyXSocialAuthSignUpPartner?: { token: string } | null;
}

export type AnyXSocialAuthSignUpTalentMutationVariables = Exact<{
  input: AnyXSocialAuthSignUpTalentInput;
}>;

export interface AnyXSocialAuthSignUpTalentMutation {
  anyXSocialAuthSignUpTalent?: { token: string } | null;
}

export type AssignChatPicMutationVariables = Exact<{
  input: AssignChatPICInput;
}>;

export interface AssignChatPicMutation {
  assignChatPic?: { ok: boolean } | null;
}

export type ClickNotificationMutationVariables = Exact<{
  input?: InputMaybe<ClickNotificationInput>;
}>;

export interface ClickNotificationMutation {
  clickNotification?: { ok: boolean } | null;
}

export type ConnectFacebookPagesAndInstagramAccountsMutationVariables = Exact<{
  input?: InputMaybe<ConnectFacebookPagesAndInstagramAccountsInput>;
}>;

export interface ConnectFacebookPagesAndInstagramAccountsMutation {
  connectFacebookPagesAndInstagramAccounts?: { ok: boolean } | null;
}

export type ConnectPromotionMethodsMutationVariables = Exact<{
  input: ConnectPromotionMethodsInput;
}>;

export interface ConnectPromotionMethodsMutation {
  connectPromotionMethods?: { ok: boolean } | null;
}

export type CreateCreatorStaffMutationVariables = Exact<{
  input: CreateCreatorStaffInput;
}>;

export interface CreateCreatorStaffMutation {
  createCreatorStaff?: { ok: boolean } | null;
}

export type CreateEngagementPostForInfluencerV2MutationVariables = Exact<{
  input: CreateEngagementPostInputForInfluencerV2;
}>;

export interface CreateEngagementPostForInfluencerV2Mutation {
  createEngagementPostForInfluencerV2?: { ok: boolean } | null;
}

export type CreateInfluencerBioMutationVariables = Exact<{
  input: CreateInfluencerBioInput;
}>;

export interface CreateInfluencerBioMutation {
  createInfluencerBio?: { ok: boolean } | null;
}

export type CreateLineBroadcastMessageMutationVariables = Exact<{
  input: CreateLineBroadcastMessageInput;
}>;

export interface CreateLineBroadcastMessageMutation {
  createLineBroadcastMessage?: { messageId: string } | null;
}

export type CreateLineChannelMutationVariables = Exact<{
  input: CreateLineChannelInput;
}>;

export interface CreateLineChannelMutation {
  createLineChannel?: { ok: boolean } | null;
}

export type CreateMarketplaceDraftPostMutationVariables = Exact<{
  input: CreateMarketplaceDraftPostInput;
}>;

export interface CreateMarketplaceDraftPostMutation {
  createMarketplaceDraftPost?: { ok: boolean } | null;
}

export type DeleteCommentMutationVariables = Exact<{
  input: DeleteCommentInput;
}>;

export interface DeleteCommentMutation {
  deleteComment?: { ok: boolean } | null;
}

export type DeleteEngagementPostForInfluencerMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export interface DeleteEngagementPostForInfluencerMutation {
  deleteEngagementPostForInfluencer?: { ok: boolean } | null;
}

export type DeleteFanTagsMutationVariables = Exact<{
  input: DeleteFanTagsInput;
}>;

export interface DeleteFanTagsMutation {
  deleteFanTags?: { ok: boolean } | null;
}

export type DisconnectPromotionMethodsMutationVariables = Exact<{
  input: DisconnectPromotionMethodsInput;
}>;

export interface DisconnectPromotionMethodsMutation {
  disconnectPromotionMethods?: { ok: boolean } | null;
}

export type GenerateMarketplacePostCaptionMutationVariables = Exact<{
  input: GenerateMarketplacePostCaptionInput;
}>;

export interface GenerateMarketplacePostCaptionMutation {
  generateMarketplacePostCaption?: { accepted: boolean } | null;
}

export type GenerateTikTokSpecialPostCaptionMutationVariables = Exact<{
  input: GenerateTikTokSpecialPostCaptionInput;
}>;

export interface GenerateTikTokSpecialPostCaptionMutation {
  generateTikTokSpecialPostCaption?: { accepted: boolean } | null;
}

export type ImportFansMutationVariables = Exact<{
  input: ImportFansInput;
}>;

export interface ImportFansMutation {
  importFans?: { ok: boolean } | null;
}

export type InfluencerAddYoutubeCompareAccountAnalyticsMutationVariables = Exact<{
  input: InfluencerAddYoutubeCompareAccountAnalyticsInput;
}>;

export interface InfluencerAddYoutubeCompareAccountAnalyticsMutation {
  influencerAddYoutubeCompareAccountAnalytics: { ok: boolean };
}

export type InfluencerAnalyticsTwitterRefreshMutationVariables = Exact<{
  input: InfluencerAnalyticsTwitterRefreshInput;
}>;

export interface InfluencerAnalyticsTwitterRefreshMutation {
  influencerAnalyticsTwitterRefresh: { ok: boolean };
}

export type InfluencerDeleteYoutubeCompareAccountAnalyticsMutationVariables = Exact<{
  input: InfluencerDeleteYoutubeCompareAccountAnalyticsInput;
}>;

export interface InfluencerDeleteYoutubeCompareAccountAnalyticsMutation {
  influencerDeleteYoutubeCompareAccountAnalytics: { ok: boolean };
}

export type InfluencerSignUpMutationVariables = Exact<{
  input: InfluencerSignUpInput;
}>;

export interface InfluencerSignUpMutation {
  influencerSignUp?: { ok: boolean } | null;
}

export type InfluencerSocialAuthReconnectInstagramAccountAnalyticsMutationVariables = Exact<{
  input: InfluencerSocialAuthReconnectInstagramAccountAnalyticsInput;
}>;

export interface InfluencerSocialAuthReconnectInstagramAccountAnalyticsMutation {
  influencerSocialAuthReconnectInstagramAccountAnalytics?: { ok: boolean } | null;
}

export type JoinAutoManagedCampaignMutationVariables = Exact<{
  input: JoinAutoManagedCampaignInput;
}>;

export interface JoinAutoManagedCampaignMutation {
  joinAutoManagedCampaign?: { id: string; ok: boolean } | null;
}

export type JoinMarketplaceV2MutationVariables = Exact<{
  input: JoinMarketplaceV2Input;
}>;

export interface JoinMarketplaceV2Mutation {
  joinMarketplaceV2?: { ok: boolean } | null;
}

export type JoinTiktokSpecialCampaignMutationVariables = Exact<{
  input: JoinTiktokSpecialCampaignInput;
}>;

export interface JoinTiktokSpecialCampaignMutation {
  joinTiktokSpecialCampaign?: { ok: boolean } | null;
}

export type MadeMarketplaceCampaignPostMutationVariables = Exact<{
  input: MadeMarketplaceCampaignPostInput;
}>;

export interface MadeMarketplaceCampaignPostMutation {
  madeMarketplaceCampaignPost?: { ok: boolean } | null;
}

export type MarkReadNotificationMutationVariables = Exact<{ [key: string]: never }>;

export interface MarkReadNotificationMutation {
  markReadNotification: { ok: boolean };
}

export type PublishInfluencerBioMutationVariables = Exact<{
  input: PublishInfluencerBioInput;
}>;

export interface PublishInfluencerBioMutation {
  publishInfluencerBio?: { ok: boolean } | null;
}

export type RemoveAllFormResponsesMutationVariables = Exact<{
  input: RemoveAllFormResponsesInput;
}>;

export interface RemoveAllFormResponsesMutation {
  removeAllFormResponses?: { ok: boolean } | null;
}

export type RemoveCreatorStaffMutationVariables = Exact<{
  input: RemoveCreatorStaffInput;
}>;

export interface RemoveCreatorStaffMutation {
  removeCreatorStaff?: { ok: boolean } | null;
}

export type RemoveEmailsMutationVariables = Exact<{
  input: RemoveEmailsInput;
}>;

export interface RemoveEmailsMutation {
  removeEmails?: { ok: boolean } | null;
}

export type RemoveFansMutationVariables = Exact<{
  input: RemoveFansInput;
}>;

export interface RemoveFansMutation {
  removeFans?: { ok: boolean } | null;
}

export type RemoveFormResponseMutationVariables = Exact<{
  input: RemoveFormResponseInput;
}>;

export interface RemoveFormResponseMutation {
  removeFormResponse?: { ok: boolean } | null;
}

export type RemoveFormsMutationVariables = Exact<{
  input: RemoveFormsInput;
}>;

export interface RemoveFormsMutation {
  removeForms?: { ok: boolean } | null;
}

export type RemoveShopifyAccountMutationVariables = Exact<{
  input: RemoveShopifyAccountInput;
}>;

export interface RemoveShopifyAccountMutation {
  removeShopifyAccount?: { ok: boolean } | null;
}

export type RequestDeleteAccountMutationVariables = Exact<{
  input: RequestDeleteAccountInput;
}>;

export interface RequestDeleteAccountMutation {
  requestDeleteAccount?: { ok: boolean } | null;
}

export type ResendInfluencerForgotPasswordEmailMutationVariables = Exact<{
  input: ResendInfluencerForgotPasswordEmailInput;
}>;

export interface ResendInfluencerForgotPasswordEmailMutation {
  resendInfluencerForgotPasswordEmail?: { ok: boolean } | null;
}

export type ResendInfluencerSignUpEmailMutationVariables = Exact<{
  input: ResendInfluencerSignUpEmailInput;
}>;

export interface ResendInfluencerSignUpEmailMutation {
  resendInfluencerSignUpEmail?: { ok: boolean } | null;
}

export type ResetPasswordAndLoginMutationVariables = Exact<{
  input: ResetPasswordInput;
}>;

export interface ResetPasswordAndLoginMutation {
  resetPasswordAndLogin?: {
    hash?: string | null;
    refreshToken?: string | null;
    role: UserRoles;
    token: string;
    userId: number;
  } | null;
}

export type ResolveChatMutationVariables = Exact<{
  input: ResolveChatInput;
}>;

export interface ResolveChatMutation {
  resolveChat?: { ok: boolean } | null;
}

export type SendContactMutationVariables = Exact<{
  input: SendContactInput;
}>;

export interface SendContactMutation {
  sendContact?: { ok: boolean } | null;
}

export type SendLineBroadcastMessageMutationVariables = Exact<{
  input: SendLineBroadcastMessageInput;
}>;

export interface SendLineBroadcastMessageMutation {
  sendLineBroadcastMessage?: { ok: boolean } | null;
}

export type SendMessageMutationVariables = Exact<{
  input: SendMessageInput;
}>;

export interface SendMessageMutation {
  sendMessage?: { ok: boolean } | null;
}

export type ShopifyValidateAuthMutationVariables = Exact<{
  input: ShopifyValidateAuthInput;
}>;

export interface ShopifyValidateAuthMutation {
  shopifyValidateAuth?: { ok: boolean } | null;
}

export type SocialAuthForJoinCampaignMutationVariables = Exact<{
  input: SocialAuthForJoinCampaignInput;
}>;

export interface SocialAuthForJoinCampaignMutation {
  socialAuthForJoinCampaign?: { ok: boolean } | null;
}

export type SubmitFormMutationVariables = Exact<{
  input: SubmitFormInput;
}>;

export interface SubmitFormMutation {
  submitForm?: { ok: boolean } | null;
}

export type SubmitFormInternalMutationVariables = Exact<{
  input: SubmitFormInternalInput;
}>;

export interface SubmitFormInternalMutation {
  submitFormInternal?: { ok: boolean } | null;
}

export type SwitchInfluencerForStaffMutationVariables = Exact<{
  input: SwitchInfluencerForStaffInput;
}>;

export interface SwitchInfluencerForStaffMutation {
  switchInfluencerForStaff?: { token: string } | null;
}

export type TokenAuthMutationVariables = Exact<{
  input: ObtainJSONWebTokenInput;
}>;

export interface TokenAuthMutation {
  tokenAuth?: {
    countryId?: string | null;
    currencyId: string;
    hash?: string | null;
    name?: string | null;
    refreshToken?: string | null;
    role: UserRoles;
    token: string;
    userId: number;
  } | null;
}

export type TrackYoutubeMusicDownloadMutationVariables = Exact<{
  input: TrackYoutubeMusicDownloadInput;
}>;

export interface TrackYoutubeMusicDownloadMutation {
  trackYoutubeMusicDownload?: { ok: boolean } | null;
}

export type UnassignChatPicMutationVariables = Exact<{
  input: UnassignChatPICInput;
}>;

export interface UnassignChatPicMutation {
  unassignChatPic?: { ok: boolean } | null;
}

export type UnresolveChatMutationVariables = Exact<{
  input: UnresolveChatInput;
}>;

export interface UnresolveChatMutation {
  unresolveChat?: { ok: boolean } | null;
}

export type UpdateAccountInformationMutationVariables = Exact<{
  input: UpdateAccountInformationInput;
}>;

export interface UpdateAccountInformationMutation {
  updateAccountInformation?: { ok: boolean } | null;
}

export type UpdateAddressInformationMutationVariables = Exact<{
  input: UpdateAddressInformationInput;
}>;

export interface UpdateAddressInformationMutation {
  updateAddressInformation?: { ok: boolean } | null;
}

export type UpdateBioSectionsMutationVariables = Exact<{
  input: UpdateBioSectionsInput;
}>;

export interface UpdateBioSectionsMutation {
  updateBioSections?: { ok: boolean } | null;
}

export type UpdateCommentMutationVariables = Exact<{
  input: UpdateCommentInput;
}>;

export interface UpdateCommentMutation {
  updateComment?: { ok: boolean } | null;
}

export type UpdateEmailTemplateMutationVariables = Exact<{
  input: UpdateEmailTemplateInput;
}>;

export interface UpdateEmailTemplateMutation {
  updateEmailTemplate?: { ok: boolean } | null;
}

export type UpdateEngagementPostForInfluencerV2MutationVariables = Exact<{
  input: UpdateEngagementPostInputForInfluencerV2;
}>;

export interface UpdateEngagementPostForInfluencerV2Mutation {
  updateEngagementPostForInfluencerV2?: { ok: boolean } | null;
}

export type UpdateFanMutationVariables = Exact<{
  input: UpdateFanInput;
}>;

export interface UpdateFanMutation {
  updateFan?: { ok: boolean } | null;
}

export type UpdateFormMutationVariables = Exact<{
  input: UpdateFormInput;
}>;

export interface UpdateFormMutation {
  updateForm?: { ok: boolean } | null;
}

export type UpdateFormStatusMutationVariables = Exact<{
  input: UpdateFormStatusInput;
}>;

export interface UpdateFormStatusMutation {
  updateFormStatus?: { ok: boolean } | null;
}

export type UpdateInfluencerBioMutationVariables = Exact<{
  input: UpdateInfluencerBioInput;
}>;

export interface UpdateInfluencerBioMutation {
  updateInfluencerBio?: { ok: boolean } | null;
}

export type UpdateInfluencerPhoneNumberMutationVariables = Exact<{
  input: UpdateInfluencerPhoneNumberInput;
}>;

export interface UpdateInfluencerPhoneNumberMutation {
  updateInfluencerPhoneNumber?: { ok: boolean } | null;
}

export type UpdateInfluencerRateCardsMutationVariables = Exact<{
  input: UpdateInfluencerRateCardsInput;
}>;

export interface UpdateInfluencerRateCardsMutation {
  updateInfluencerRateCards?: { ok: boolean } | null;
}

export type UpdateLineBroadcastMessageMutationVariables = Exact<{
  input: UpdateLineBroadcastMessageInput;
}>;

export interface UpdateLineBroadcastMessageMutation {
  updateLineBroadcastMessage?: { ok: boolean } | null;
}

export type UpdateLinkBioThemeColorMutationVariables = Exact<{
  input: UpdateLinkBioThemeColorInput;
}>;

export interface UpdateLinkBioThemeColorMutation {
  updateLinkBioThemeColor?: { ok: boolean } | null;
}

export type UpdateMarketplacePostTrackingUrlMutationVariables = Exact<{
  input: UpdateMarketplacePostTrackingUrlInput;
}>;

export interface UpdateMarketplacePostTrackingUrlMutation {
  updateMarketplacePostTrackingUrl?: { ok: boolean } | null;
}

export type UpdatePaymentInformationMutationVariables = Exact<{
  input: UpdatePaymentInformationInput;
}>;

export interface UpdatePaymentInformationMutation {
  updatePaymentInformation?: { ok: boolean } | null;
}

export type UpdatePersonalUserDetailMutationVariables = Exact<{
  input: UpdatePersonalUserDetailInput;
}>;

export interface UpdatePersonalUserDetailMutation {
  updatePersonalUserDetail?: { ok: boolean } | null;
}

export type UpdateUserDetailMutationVariables = Exact<{
  input: UpdateUserDetailInput;
}>;

export interface UpdateUserDetailMutation {
  updateUserDetail?: { ok: boolean } | null;
}

export type UuumSocialAuthSignUpMutationVariables = Exact<{
  input: UUUMSocialAuthSignUpInput;
}>;

export interface UuumSocialAuthSignUpMutation {
  uuumSocialAuthSignUp?: { token?: string | null } | null;
}

export type VerifyLineIDTokenMutationVariables = Exact<{
  input: VerifyLineIDTokenInput;
}>;

export interface VerifyLineIDTokenMutation {
  verifyLineIDToken?: { ok: boolean } | null;
}

export type VerifyTokenMutationVariables = Exact<{
  input: VerifyInput;
}>;

export interface VerifyTokenMutation {
  verifyToken?: {
    company?: string | null;
    countryId?: string | null;
    creatorType?: InfluencerType | null;
    currencyId?: string | null;
    email?: string | null;
    hash?: string | null;
    limitedLogin: boolean;
    name?: string | null;
    ok: boolean;
    role?: UserRoles | null;
    subscriptionPaymentFailed?: boolean | null;
    subscriptionPlan?: PaymentPlanName | null;
    userId?: number | null;
  } | null;
}

export type AllAnyCreatorCategoriesQueryVariables = Exact<{ [key: string]: never }>;

export interface AllAnyCreatorCategoriesQuery {
  allAnycreatorCategories?: Array<{ id: number; name: string }> | null;
}

export type AllAvailableCountriesQueryVariables = Exact<{ [key: string]: never }>;

export interface AllAvailableCountriesQuery {
  allAvailableCountries: Array<{ currency: string; id: string; name: string }>;
}

export type AllBankBranchesForInfluencerQueryVariables = Exact<{
  bankId: Scalars['Int']['input'];
}>;

export interface AllBankBranchesForInfluencerQuery {
  allBankBranchesForInfluencer?: Array<{ id: number; name: string } | null> | null;
}

export type AllBanksForInfluencerQueryVariables = Exact<{ [key: string]: never }>;

export interface AllBanksForInfluencerQuery {
  allBanksForInfluencer?: Array<{ hasBranches: boolean; id: number; name: string } | null> | null;
}

export type AllCampaignCategoriesQueryVariables = Exact<{ [key: string]: never }>;

export interface AllCampaignCategoriesQuery {
  allCampaignCategories?: Array<{ categoryName: string; id: number }> | null;
}

export type AllCampaignsForYourJobQueryVariables = Exact<{
  isFinished: Scalars['Boolean']['input'];
}>;

export interface AllCampaignsForYourJobQuery {
  allCampaignsForYourJob?: Array<{
    createdDate: any;
    type: CampaignType;
    autoManaged?: {
      brandName?: string | null;
      commissionRate?: number | null;
      currency: string;
      endDate?: any | null;
      hasReport: boolean;
      id: number;
      mkpServerId: number;
      startDate?: any | null;
      status: AutoManagedAffiliateCampaignStatus;
      title: string;
      type: CampaignType;
      thumbnail: string;
    } | null;
    engagement?: {
      currency: string;
      endDate: any;
      hasDraftPosts: boolean;
      hasReport: boolean;
      hasWarningPosts: boolean;
      id: number;
      isTtcmCampaign: boolean;
      rewardAmount?: number | null;
      socialMedias: Array<CampaignSocialMediaType>;
      startDate: any;
      status: AnyXEngagementCampaignStatus;
      title: string;
      ttcmStatus?: TTCMStatus | null;
      type: CampaignType;
      thumbnail: string;
    } | null;
    marketplace?: {
      appliedStatus?: MarketplaceCampaignAppliedStatus | null;
      campaignDetailType: MarketplaceCampaignDetailType;
      campaignStatus?: CampaignStatusForInfluencer | null;
      commissionRate?: number | null;
      currency: string;
      endDate?: any | null;
      hasPostsCouponMissing?: boolean | null;
      hasReport: boolean;
      id: number;
      isApplying?: boolean | null;
      isAutoInfluencerApproval?: boolean | null;
      isGiftingCampaign?: boolean | null;
      isPostTracking?: boolean | null;
      isSelectionCampaign?: boolean | null;
      isWaiting: boolean;
      mkpServerId?: number | null;
      preLaunchDate?: any | null;
      remainingAmount?: number | null;
      revenuePerAction?: number | null;
      revenuePerClick?: number | null;
      revenuePerComment?: number | null;
      revenuePerFollower?: number | null;
      revenuePerLike?: number | null;
      revenuePerOrder?: number | null;
      revenuePerPost?: number | null;
      revenuePerShare?: number | null;
      revenuePerView?: number | null;
      socialMedias: Array<CampaignSocialMediaType>;
      startDate: any;
      status?: AnyXMarketplaceCampaignStatus | null;
      title: string;
      totalAmount?: number | null;
      type: CampaignType;
      thumbnail: string;
    } | null;
    tiktokSpecial?: {
      campaignStatus: CampaignStatusForInfluencer;
      currency: string;
      endDate: any;
      hasReport: boolean;
      id: number;
      mkpServerId: number;
      preLaunchDate?: any | null;
      revenuePerPost: number;
      socialMedia: CampaignSocialMediaType;
      startDate: any;
      title: string;
      type: CampaignType;
      thumbnail: string;
    } | null;
  }> | null;
}

export type AllCampaignsForYourJobCountsQueryVariables = Exact<{ [key: string]: never }>;

export interface AllCampaignsForYourJobCountsQuery {
  allCampaignsForYourJobCounts?: { finishedCampaignCount: number; progressCampaignCount: number } | null;
}

export type AllCitiesForInfluencerQueryVariables = Exact<{ [key: string]: never }>;

export interface AllCitiesForInfluencerQuery {
  allCitiesForInfluencer?: Array<{ id: number; name: string } | null> | null;
}

export type AllCountriesQueryVariables = Exact<{ [key: string]: never }>;

export interface AllCountriesQuery {
  allCountries: Array<{ currency: string; id: string; isAvailableCountry: boolean; name: string }>;
}

export type AllEngagementPostsForInfluencerQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  engagementId: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
}>;

export interface AllEngagementPostsForInfluencerQuery {
  allEngagementPostsForInfluencer?: Array<{
    anyXStatus: AnyXEngagementCampaignStatus;
    content: string;
    id: number;
    inappropriateDetailedReason?: string | null;
    inappropriateReason?: string | null;
    isPreviouslyModified: boolean;
    planedPostDate: any;
    postedDate?: any | null;
    socialMedia: CampaignSocialMediaType;
    status: EngagementCampaignPostStatus;
    thumbNail?: string | null;
    ttcmInviteLink?: string | null;
  }> | null;
}

export type AllInfluencerCategoriesQueryVariables = Exact<{ [key: string]: never }>;

export interface AllInfluencerCategoriesQuery {
  allInfluencerCategories?: Array<{ description: string; id: number }> | null;
}

export type AllRegionsQueryVariables = Exact<{
  countryId: Scalars['String']['input'];
}>;

export interface AllRegionsQuery {
  allRegions?: Array<{ id: string; name: string } | null> | null;
}

export type AnyXCheckConnectedSocialAccountsQueryVariables = Exact<{ [key: string]: never }>;

export interface AnyXCheckConnectedSocialAccountsQuery {
  anyXCheckConnectedSocialAccounts?: {
    facebookPages: Array<{
      avatar?: string | null;
      followersCount?: number | null;
      id: string;
      idForDisconnect: string;
      name: string;
      needReconnect: boolean;
      url?: string | null;
    }>;
    facebooks: Array<{
      avatar?: string | null;
      followersCount?: number | null;
      id: string;
      idForDisconnect: string;
      name: string;
      needReconnect: boolean;
      url?: string | null;
    }>;
    instagramAccounts: Array<{
      avatar?: string | null;
      followersCount?: number | null;
      id: string;
      idForDisconnect: string;
      isBusiness?: boolean | null;
      name: string;
      needReconnect: boolean;
      url?: string | null;
    }>;
    tiktokAccounts: Array<{
      avatar?: string | null;
      followersCount?: number | null;
      id: string;
      idForDisconnect: string;
      name: string;
      needReconnect: boolean;
      url?: string | null;
    }>;
    twitterAccounts: Array<{
      avatar?: string | null;
      followersCount?: number | null;
      id: string;
      idForDisconnect: string;
      name: string;
      url?: string | null;
    }>;
    youtubeAccounts: Array<{
      avatar?: string | null;
      followersCount?: number | null;
      id: string;
      idForDisconnect: string;
      name: string;
      url?: string | null;
      childAccounts: Array<{ avatar?: string | null; channelId: string; name: string }>;
    }>;
  } | null;
}

export type AnyXCheckConnectedSocialAccountsForLinkBioQueryVariables = Exact<{ [key: string]: never }>;

export interface AnyXCheckConnectedSocialAccountsForLinkBioQuery {
  anyXCheckConnectedSocialAccountsForLinkBio?: {
    facebooks: Array<{
      avatar: string;
      followersCount?: number | null;
      id: string;
      name: string;
      needReconnect: boolean;
      socialAccountType: SocialAccountType;
      url: string;
      post?: { id: string; thumbnail: string; totalPostsCount: number } | null;
    }>;
    instagramAccounts: Array<{
      __typename: 'AnyXConnectedSocialAccountForLinkBio';
      avatar: string;
      followersCount?: number | null;
      id: string;
      name: string;
      needReconnect: boolean;
      url: string;
    }>;
    tiktokAccounts: Array<{
      __typename: 'AnyXConnectedSocialAccountForLinkBio';
      avatar: string;
      followersCount?: number | null;
      id: string;
      name: string;
      needReconnect: boolean;
      url: string;
    }>;
    twitterAccounts: Array<{
      __typename: 'AnyXConnectedSocialAccountForLinkBio';
      avatar: string;
      followersCount?: number | null;
      id: string;
      name: string;
      needReconnect: boolean;
      url: string;
    }>;
    youtubeAccounts: Array<{
      __typename: 'AnyXConnectedSocialAccountForLinkBio';
      avatar: string;
      followersCount?: number | null;
      id: string;
      name: string;
      needReconnect: boolean;
      url: string;
    }>;
  } | null;
}

export interface AnyXConnectedSocialAccountForLinkBioFieldsFragment {
  __typename: 'AnyXConnectedSocialAccountForLinkBio';
  avatar: string;
  followersCount?: number | null;
  id: string;
  name: string;
  needReconnect: boolean;
  url: string;
}

export type AutoManagedJoinedMethodsV2QueryVariables = Exact<{
  input: AutoManagedJoinedMethodsInput;
}>;

export interface AutoManagedJoinedMethodsV2Query {
  autoManagedJoinedMethodsV2?: { methods: Array<CampaignPromotionMethod> } | null;
}

export type AutoManagedReportForInfluencerQueryVariables = Exact<{
  campaignId: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
}>;

export interface AutoManagedReportForInfluencerQuery {
  autoManagedReportForInfluencer?: Array<{ clicks: number; commission: number; conversions: number; date: any }> | null;
  autoManagedReportCountForInfluencer?: { totalNumber: number } | null;
}

export type AutoManagedReportSummaryForInfluencerQueryVariables = Exact<{
  campaignId: Scalars['Int']['input'];
}>;

export interface AutoManagedReportSummaryForInfluencerQuery {
  autoManagedReportSummaryForInfluencer?: {
    clicks: number;
    commission: number;
    conversions: number;
    currency: string;
  } | null;
}

export type BrandAccountsQueryVariables = Exact<{
  influencerId: Scalars['Int']['input'];
  socialMedia: SocialAccountType;
  socialAccountId: Scalars['Int']['input'];
}>;

export interface BrandAccountsQuery {
  brandAccounts?: {
    averageEngagement?: number | null;
    averageEngagementRate?: number | null;
    brandAccounts?: Array<{
      avatar: string;
      averageEngagementRate: number;
      id: number;
      mentionPostsCount: number;
      name: string;
      postIds: Array<number>;
    }> | null;
  } | null;
}

export type CmsInvoiceDownloadUrlQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export interface CmsInvoiceDownloadUrlQuery {
  cmsInvoiceDownloadUrl?: { url: string } | null;
}

export type ConnectableFacebookPagesQueryVariables = Exact<{ [key: string]: never }>;

export interface ConnectableFacebookPagesQuery {
  connectableFacebookPages?: {
    pages: Array<{
      avatar?: string | null;
      followersCount: number;
      id: string;
      isAlreadyConnected: boolean;
      name: string;
    }>;
  } | null;
}

export type ConnectableFacebookPagesAndInstagramAccountsQueryVariables = Exact<{ [key: string]: never }>;

export interface ConnectableFacebookPagesAndInstagramAccountsQuery {
  connectableFacebookPagesAndInstagramAccounts?: {
    accounts: Array<{
      igAccount?: {
        avatar?: string | null;
        followersCount: number;
        id: string;
        isAlreadyConnected: boolean;
        name: string;
      } | null;
      page: { avatar?: string | null; followersCount: number; id: string; isAlreadyConnected: boolean; name: string };
    }>;
  } | null;
}

export type ConnectableInstagramAccountsQueryVariables = Exact<{
  fbPageIds: Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>;
}>;

export interface ConnectableInstagramAccountsQuery {
  connectableInstagramAccounts?: {
    igAccounts: Array<{ avatar?: string | null; followersCount: number; id: string; name: string }>;
  } | null;
}

export type CountUnreadNotificationsQueryVariables = Exact<{ [key: string]: never }>;

export interface CountUnreadNotificationsQuery {
  countUnreadNotifications?: { count: any } | null;
}

export type CreatorAddressInformationQueryVariables = Exact<{ [key: string]: never }>;

export interface CreatorAddressInformationQuery {
  creatorAddressInformation?: {
    address: string;
    address2: string;
    city: string;
    fullName: string;
    phoneNumber?: string | null;
    postalCode: string;
    province: string;
  } | null;
}

export type EngagementCampaignPostReportForInfluencerQueryVariables = Exact<{
  campaignId: Scalars['Int']['input'];
}>;

export interface EngagementCampaignPostReportForInfluencerQuery {
  engagementCampaignPostReportForInfluencer?: {
    currency: string;
    id: number;
    title: string;
    postReport: Array<{
      anyXStatus: AnyXEngagementCampaignStatus;
      click?: number | null;
      comment: number;
      conversion?: number | null;
      id: number;
      like: number;
      postCaption: string;
      postSocialMedia: CampaignSocialMediaType;
      postUrl: string;
      share: number;
      status: EngagementCampaignPostStatus;
      thumbNail?: string | null;
      view: number;
    }>;
  } | null;
}

export type EngagementDraftPostHistoryForInfluencerQueryVariables = Exact<{
  pk: Scalars['Int']['input'];
}>;

export interface EngagementDraftPostHistoryForInfluencerQuery {
  engagementDraftPostHistoryForInfluencer?: {
    historyRecords?: Array<{
      createdDate: any;
      detailedReason?: string | null;
      id?: number | null;
      postStatus?: EngagementCampaignPostStatus | null;
      reason?: string | null;
    }> | null;
  } | null;
}

export type EngagementForInfluencerQueryVariables = Exact<{
  pk: Scalars['Int']['input'];
}>;

export interface EngagementForInfluencerQuery {
  engagementForInfluencer?: {
    anyXStatus: AnyXEngagementCampaignStatus;
    couponCode?: string | null;
    createdDate: any;
    currency: string;
    endDate?: any | null;
    hasDraftPosts: boolean;
    hasReport: boolean;
    hasWarningPosts: boolean;
    hashtags: Array<string>;
    id: number;
    isCouponCampaign?: boolean | null;
    isJoined: boolean;
    isTtcmCampaign: boolean;
    isTtcmReady?: boolean | null;
    materialUrl?: string | null;
    objective?: string | null;
    reason?: string | null;
    rewardAmount: number;
    serviceInformation: string;
    serviceUrl?: string | null;
    socialMedias: Array<CampaignSocialMediaType>;
    startDate: any;
    status: CampaignStatusForInfluencer;
    thumbNail: string;
    title: string;
    trackingUrl?: string | null;
    ttcmCampaignCode?: string | null;
    ttcmStatus?: TTCMStatus | null;
    type: CampaignType;
    requirement?: string | null;
    materials?: Array<{ id: number; url: string }> | null;
  } | null;
}

export type EngagementPostCommentsQueryVariables = Exact<{
  postId: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
}>;

export interface EngagementPostCommentsQuery {
  engagementPostComments?: Array<{
    commentedDate?: any | null;
    content?: string | null;
    username?: string | null;
  }> | null;
}

export type EngagementPostDetailQueryVariables = Exact<{
  pk: Scalars['Int']['input'];
}>;

export interface EngagementPostDetailQuery {
  engagementPostDetail?: {
    caption: string;
    comments?: number | null;
    detailedReason?: string | null;
    id: number;
    images?: Array<string> | null;
    likes?: number | null;
    postUrl?: string | null;
    postedDate?: any | null;
    reason?: string | null;
    shares?: number | null;
    socialMedia: CampaignSocialMediaType;
    status?: EngagementCampaignPostStatus | null;
    thumbNail?: string | null;
    views?: number | null;
    warningReason?: WarningReason | null;
    engagementPostDetailInfluencer: { avatar: string; followersCount: number; id: number; name: string };
  } | null;
}

export type EngagementPostDetailForInfluencerQueryVariables = Exact<{
  pk: Scalars['Int']['input'];
}>;

export interface EngagementPostDetailForInfluencerQuery {
  engagementPostDetailForInfluencer?: {
    caption: string;
    id: number;
    images?: Array<string> | null;
    postedDate?: any | null;
    socialMedia: CampaignSocialMediaType;
    engagementPostDetailInfluencer: { avatar: string; followersCount: number; id: number; name: string };
  } | null;
}

export type EngagementPostForInfluencerV2QueryVariables = Exact<{
  pk: Scalars['Int']['input'];
}>;

export interface EngagementPostForInfluencerV2Query {
  engagementPostForInfluencerV2?: {
    content: string;
    id: number;
    inappropriateDetailedReason?: string | null;
    inappropriateReason?: string | null;
    planedPostDate: any;
    postStatus: EngagementCampaignPostStatus;
    socialAccountId?: number | null;
    socialAccountMedia?: SocialAccountType | null;
    socialAccountName?: string | null;
    socialMedia: CampaignSocialMediaType;
    materials?: Array<{ id: number; url: string }> | null;
  } | null;
}

export type EngagementPostSocialAccountsForInfluencerQueryVariables = Exact<{
  pk: Scalars['Int']['input'];
}>;

export interface EngagementPostSocialAccountsForInfluencerQuery {
  engagementPostSocialAccountsForInfluencer?: Array<{
    id: number;
    name: string;
    socialMedia: SocialAccountType;
    status: SocialAccountStatus;
    ttcmCampaignInviteLink?: string | null;
    ttcmStatus?: TTCMStatus | null;
    username?: string | null;
  }> | null;
}

export type GenerateImportFansUploadURLQueryVariables = Exact<{ [key: string]: never }>;

export interface GenerateImportFansUploadURLQuery {
  generateImportFansUploadURL?: { fileName: string; signedUrl: string } | null;
}

export type GeneratePostFileSignedUrlsQueryVariables = Exact<{
  fileNames: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export interface GeneratePostFileSignedUrlsQuery {
  generatePostFileSignedUrls: { fileUploadUrls: Array<{ fileName: string; signedUrl: string }> };
}

export type GetAccountInformationQueryVariables = Exact<{ [key: string]: never }>;

export interface GetAccountInformationQuery {
  getAccountInformation?: {
    name: string;
    brandName?: string | null;
    categoryIds: Array<number>;
    dateOfBirth?: string | null;
    countryId: string;
    regionId?: number | null;
    gender: Genders;
    introduce: string;
  } | null;
}

export type GetAllCampaignsCountForSearchJobQueryVariables = Exact<{
  input: GetAllCampaignsCountForSearchJobInput;
}>;

export interface GetAllCampaignsCountForSearchJobQuery {
  getAllCampaignsCountForSearchJob?: { count?: number | null } | null;
}

export type GetAllCampaignsForSearchJobQueryVariables = Exact<{
  input: GetAllCampaignsForSearchJobInput;
}>;

export interface GetAllCampaignsForSearchJobQuery {
  getAllCampaignsForSearchJob?: {
    campaigns: Array<{
      commissionRate?: number | null;
      createdDate: string;
      currency: string;
      endDate?: string | null;
      id: number;
      isAutoInfluencerApproval: boolean;
      isGiftingCampaign: boolean;
      isSelectionCampaign: boolean;
      marketplaceCampaignType?: MarketplaceCampaignDetailType | null;
      minFollowers?: number | null;
      preLaunchDate?: string | null;
      revenuePerAction?: number | null;
      revenuePerClick?: number | null;
      revenuePerComment?: number | null;
      revenuePerFollower?: number | null;
      revenuePerLike?: number | null;
      revenuePerOrder?: number | null;
      revenuePerPost?: number | null;
      revenuePerShare?: number | null;
      revenuePerView?: number | null;
      socialMedias: Array<CampaignSocialMediaType>;
      startDate?: string | null;
      thumbnail: string;
      title: string;
      trackingOptions: Array<CampaignTrackingOption>;
      type: CampaignType;
      campaignStatus: MarketplaceCampaignStatus;
    }>;
  } | null;
}

export type GetAutoManagedCampaignForInfluencerSearchJobQueryVariables = Exact<{
  input: GetAutoManagedCampaignForInfluencerSearchJobInput;
}>;

export interface GetAutoManagedCampaignForInfluencerSearchJobQuery {
  getAutoManagedCampaignForInfluencerSearchJob?: {
    campaignUrl?: string | null;
    commissionRate?: number | null;
    createdDate: string;
    endDate?: string | null;
    id: string;
    isJoined: boolean;
    minimumPaymentAmount: number;
    requirement?: string | null;
    startDate?: string | null;
    status: AutoManagedAffiliateCampaignStatus;
    thumbNail?: string | null;
    title: string;
    type: AutoManagedCampaignType;
    currency?: string | null;
    serviceInformation?: string | null;
  } | null;
}

export type GetAutoManagedCampaignForInfluencerYourJobQueryVariables = Exact<{
  input: GetAutoManagedCampaignForInfluencerYourJobInput;
}>;

export interface GetAutoManagedCampaignForInfluencerYourJobQuery {
  getAutoManagedCampaignForInfluencerYourJob?: {
    campaignUrl?: string | null;
    commissionRate?: number | null;
    createdDate: string;
    endDate?: string | null;
    id: string;
    isJoined: boolean;
    minimumPaymentAmount: number;
    requirement?: string | null;
    startDate?: string | null;
    status: AutoManagedAffiliateCampaignStatus;
    thumbNail?: string | null;
    title: string;
    trackingUrl: string;
    type: AutoManagedCampaignType;
    currency?: string | null;
    serviceInformation?: string | null;
  } | null;
}

export type GetAvailableInfluencersForStaffQueryVariables = Exact<{ [key: string]: never }>;

export interface GetAvailableInfluencersForStaffQuery {
  getAvailableInfluencersForStaff?: { influencers: Array<{ id: string; name: string; selected: boolean }> } | null;
}

export type GetChatEventsQueryVariables = Exact<{
  input: GetChatEventsInput;
}>;

export interface GetChatEventsQuery {
  getChatEvents?: {
    __typename: 'GetChatEventsPayload';
    lastCheckedAt?: string | null;
    userName: string;
    chatEvents: Array<
      | { __typename: 'BotTextMessageEvent'; id: string; text: string; authorName?: string | null; timestamp: string }
      | { __typename: 'InquiryEvent'; id: string; inquiry: string; timestamp: string }
      | { __typename: 'InquiryFinishEvent'; id: string; username?: string | null; timestamp: string }
      | { __typename: 'MessageTemplateEvent'; id: string; templateId: string; templateName: string; timestamp: string }
      | { __typename: 'StateUpdateEvent'; id: string; timestamp: string }
      | { __typename: 'UserAudioMessageEvent'; id: string; timestamp: string; contentUrl: string; duration: number }
      | { __typename: 'UserImageMessageEvent'; id: string; timestamp: string; imageUrl: string }
      | { __typename: 'UserPostbackEvent'; id: string; displayText: string; timestamp: string }
      | { __typename: 'UserTextMessageEvent'; id: string; text: string; timestamp: string }
      | { __typename: 'UserVideoMessageEvent'; id: string; timestamp: string; contentUrl: string; duration: number }
    >;
  } | null;
}

export type GetCreatorStaffsQueryVariables = Exact<{
  input: CreatorStaffsInput;
}>;

export interface GetCreatorStaffsQuery {
  getCreatorStaffs?: {
    avatar: string;
    id: number;
    name: string;
    staffs: Array<{ email: string; id: number; name: string }>;
  } | null;
}

export type GetCreatorStaffsCountQueryVariables = Exact<{ [key: string]: never }>;

export interface GetCreatorStaffsCountQuery {
  getCreatorStaffsCount?: { total: number } | null;
}

export type GetCreatorTagsQueryVariables = Exact<{
  input: GetCreatorTagsInput;
}>;

export interface GetCreatorTagsQuery {
  getCreatorTags?: { tags: Array<{ id: any; tag: string }> } | null;
}

export type GetCreatorTagsByIdQueryVariables = Exact<{
  input: GetCreatorTagsByIdInput;
}>;

export interface GetCreatorTagsByIdQuery {
  getCreatorTagsById?: { tags: Array<{ id: any; tag: string }> } | null;
}

export type GetCustomerTagsQueryVariables = Exact<{
  input: GetCustomerTagsInput;
}>;

export interface GetCustomerTagsQuery {
  getCustomerTags?: { tags: Array<{ name: string }> } | null;
}

export type GetEmailListQueryVariables = Exact<{
  input: GetEmailListInput;
}>;

export interface GetEmailListQuery {
  getEmailList?: {
    emails: Array<{
      clickRate?: number | null;
      id: any;
      openRate?: number | null;
      scheduleDate?: string | null;
      sentAt?: string | null;
      status: EmailTemplateStatus;
      subject: string;
    }>;
  } | null;
}

export type GetEmailListCountQueryVariables = Exact<{
  input: GetEmailListCountInput;
}>;

export interface GetEmailListCountQuery {
  getEmailListCount?: { total: any } | null;
}

export type GetEmailOverviewQueryVariables = Exact<{
  input: GetEmailOverviewInput;
}>;

export interface GetEmailOverviewQuery {
  getEmailOverview?: {
    info: {
      fromEmail: string;
      message: string;
      sentAt: string;
      subject: string;
      tags?: Array<string> | null;
      toEmails?: Array<string> | null;
    };
    stats: { bounceCount: any; clickCount: any; openCount: any; recipientsCount: any; unsubscribeCount: any };
  } | null;
}

export type GetEmailRecipientsQueryVariables = Exact<{
  input: GetEmailRecipientsInput;
}>;

export interface GetEmailRecipientsQuery {
  getEmailRecipients?: {
    recipients: Array<{ email: string; fanId: any; name: string; statuses: Array<EmailEventStatus> }>;
  } | null;
}

export type GetEmailRecipientsCountQueryVariables = Exact<{
  input: GetEmailRecipientsCountInput;
}>;

export interface GetEmailRecipientsCountQuery {
  getEmailRecipientsCount?: { total: any } | null;
}

export type GetEmailTemplateQueryVariables = Exact<{
  input: GetEmailTemplateInput;
}>;

export interface GetEmailTemplateQuery {
  getEmailTemplate?: {
    fans: Array<any>;
    id: any;
    message: string;
    scheduleDate?: string | null;
    sendNow?: boolean | null;
    sender: string;
    status: EmailTemplateStatus;
    subject: string;
    tags: Array<any>;
  } | null;
}

export type GetFanQueryVariables = Exact<{
  input: GetFanInput;
}>;

export interface GetFanQuery {
  getFan?: {
    address1?: string | null;
    address2?: string | null;
    birthday?: string | null;
    city?: string | null;
    contactNumber?: string | null;
    countryId?: string | null;
    editable: boolean;
    email?: string | null;
    firstName: string;
    gender?: FanGender | null;
    id: any;
    lastName: string;
    postalCode?: string | null;
    province?: string | null;
    tags: Array<string>;
  } | null;
}

export type GetFanActivitiesQueryVariables = Exact<{
  input: GetFanActivitiesInput;
}>;

export interface GetFanActivitiesQuery {
  getFanActivities?: {
    total: number;
    activities: Array<{
      activityType: FanActivityType;
      commenterId?: any | null;
      created: string;
      id: any;
      changes: Array<{ key: ChangeType; newValue?: string | null; oldValue?: string | null }>;
    }>;
  } | null;
}

export type GetFanCountriesQueryVariables = Exact<{ [key: string]: never }>;

export interface GetFanCountriesQuery {
  getFanCountries?: { countries: Array<{ id: string; name: string }> } | null;
}

export type GetFanFormResponseQueryVariables = Exact<{
  input: GetFanFormResponseInput;
}>;

export interface GetFanFormResponseQuery {
  getFanFormResponse?: {
    formId: any;
    formTitle: string;
    answers: Array<{
      answerOptionTitles: Array<string>;
      answerText?: string | null;
      order: number;
      questionId: any;
      questionTitle: string;
      questionType: QuestionType;
    }>;
  } | null;
}

export type GetFanHeaderQueryVariables = Exact<{
  input: GetFanInput;
}>;

export interface GetFanHeaderQuery {
  getFanHeader?: {
    avatar: string;
    created: string;
    currencyId?: string | null;
    firstName: string;
    id: any;
    lastName: string;
    lastOrder?: string | null;
    lineAccount?: string | null;
    shopifyAccount?: any | null;
    totalOrders?: number | null;
    totalSpent?: number | null;
  } | null;
}

export type GetFanOrdersQueryVariables = Exact<{
  input: GetFanOrdersInput;
}>;

export interface GetFanOrdersQuery {
  getFanOrders?: {
    orders: Array<{
      currency: string;
      orderId: string;
      orderTime: string;
      paymentStatus: ShopifyOrderPaymentStatus;
      shippingStatus?: ShopifyOrderShippingStatus | null;
      total: number;
      items: Array<{
        image?: string | null;
        price: number;
        productName: string;
        quantity: number;
        variant?: string | null;
      }>;
    }>;
  } | null;
}

export type GetFanParticipationFormListQueryVariables = Exact<{
  input: GetFanParticipationFormListInput;
}>;

export interface GetFanParticipationFormListQuery {
  getFanParticipationFormList?: { forms: Array<{ formName: string; submissionId: any; submittedAt: string }> } | null;
}

export type GetFanProvincesQueryVariables = Exact<{
  input: GetFanProvincesInput;
}>;

export interface GetFanProvincesQuery {
  getFanProvinces?: { provinces: Array<{ id: string; name: string }> } | null;
}

export type GetFansQueryVariables = Exact<{
  input: GetFansInput;
}>;

export interface GetFansQuery {
  getFans?: {
    fans: Array<{
      contactNumber?: string | null;
      countryId?: string | null;
      created: string;
      email?: string | null;
      id: any;
      lineAccount?: string | null;
      name: string;
      shopifyAccount?: any | null;
      tags: Array<{ id: any; tag: string }>;
    }>;
  } | null;
}

export type GetFansCountQueryVariables = Exact<{
  input: GetFansCountInput;
}>;

export interface GetFansCountQuery {
  getFansCount?: { total: number } | null;
}

export type GetFormQueryVariables = Exact<{
  input: GetFormInput;
}>;

export interface GetFormQuery {
  getForm?: {
    description: string;
    hash: string;
    id: any;
    status: FormStatus;
    thankDescription: string;
    thankTitle: string;
    title: string;
    questions: Array<{
      id: any;
      image?: string | null;
      isRequired: boolean;
      order: number;
      questionType: QuestionType;
      title: string;
      options: Array<{ id: any; optionTitle: string; order: number }>;
    }>;
  } | null;
}

export type GetFormReportSummaryQueryVariables = Exact<{
  input: FormReportSummaryInput;
}>;

export interface GetFormReportSummaryQuery {
  getFormReportSummary?: {
    answers: Array<{
      answerTexts: Array<string>;
      numberResponses: number;
      questionId: any;
      questionTitle: string;
      questionType: QuestionType;
      answerOptions: Array<{ count: number; optionTitle: string }>;
    }>;
  } | null;
}

export type GetFormRespondentsCountQueryVariables = Exact<{
  input: FormRespondentsCountInput;
}>;

export interface GetFormRespondentsCountQuery {
  getFormRespondentsCount?: { total: any } | null;
}

export type GetFormRespondentsListQueryVariables = Exact<{
  input: FormRespondentsListInput;
}>;

export interface GetFormRespondentsListQuery {
  getFormRespondentsList?: {
    respondents: Array<{
      email?: string | null;
      fanId?: any | null;
      firstName: string;
      lastName?: string | null;
      page?: any | null;
      submissionId: any;
    }>;
  } | null;
}

export type GetFormResponseByPageQueryVariables = Exact<{
  input: GetFormResponseByPageInput;
}>;

export interface GetFormResponseByPageQuery {
  getFormResponseByPage?: {
    created: string;
    formTitle: string;
    submissionId: any;
    answers: Array<{
      answerOptionIds: Array<any>;
      answerText?: string | null;
      questionId: any;
      questionTitle: string;
      questionType: QuestionType;
    }>;
  } | null;
}

export type GetFormTemplateQueryVariables = Exact<{
  input: GetFormTemplateInput;
}>;

export interface GetFormTemplateQuery {
  getFormTemplate?: {
    description: string;
    id: any;
    thankDescription: string;
    thankTitle: string;
    title: string;
    questions: Array<{
      id: any;
      image?: string | null;
      isRequired: boolean;
      order: number;
      questionType: QuestionType;
      title: string;
      options: Array<{ id: any; optionTitle: string; order: number }>;
    }>;
  } | null;
}

export type GetFormsCountQueryVariables = Exact<{
  input: GetFormsCountInput;
}>;

export interface GetFormsCountQuery {
  getFormsCount?: { total: any } | null;
}

export type GetFormsListQueryVariables = Exact<{
  input: GetFormsListInput;
}>;

export interface GetFormsListQuery {
  getFormsList?: {
    forms: Array<{ id: any; lastUpdated: string; status: FormStatus; submissionCount: any; title: string }>;
  } | null;
}

export type GetGeneratedMarketplacePostCaptionQueryVariables = Exact<{
  input: GetGeneratedMarketplacePostCaptionInput;
}>;

export interface GetGeneratedMarketplacePostCaptionQuery {
  getGeneratedMarketplacePostCaption?: {
    attemptsRemaining: number;
    generatedCaption?: string | null;
    recentRequestStatus: GetGeneratedPostCaptionRecentRequestStatus;
  } | null;
}

export type GetGeneratedTikTokSpecialPostCaptionQueryVariables = Exact<{
  input: GetGeneratedTikTokSpecialPostCaptionInput;
}>;

export interface GetGeneratedTikTokSpecialPostCaptionQuery {
  getGeneratedTikTokSpecialPostCaption?: {
    attemptsRemaining: number;
    generatedCaption?: string | null;
    recentRequestStatus: GetGeneratedPostCaptionRecentRequestStatus;
  } | null;
}

export type GetInfluencerBioQueryVariables = Exact<{ [key: string]: never }>;

export interface GetInfluencerBioQuery {
  getInfluencerBio?: {
    id: number;
    isPublic: boolean;
    linkName: string;
    sections: Array<{
      id: number;
      isPublic: boolean;
      order: number;
      sectionType: BioSectionType;
      image?: { image: string; linkUrl?: string | null } | null;
      link?: { linkIcon?: BioLinkIcon | null; linkName: string; linkUrl: string } | null;
      profile?: { avatar?: string | null; description?: string | null; title?: string | null } | null;
      socialMedia?: {
        linkName: string;
        linkUrl: string;
        socialAccountId?: string | null;
        socialAccountType?: SocialAccountType | null;
        socialMedia: BioSocialMediaType;
      } | null;
      text?: { text: string } | null;
    }>;
  } | null;
}

export type GetInfluencerBioAnalyticsQueryVariables = Exact<{
  input: GetInfluencerBioAnalyticsInput;
}>;

export interface GetInfluencerBioAnalyticsQuery {
  getInfluencerBioAnalytics?: {
    audience?: {
      cities: Array<{ city: string; count: number }>;
      countries: Array<{ count: number; country: string }>;
      otherLinkClicks: Array<{ clicks: number; id: number; linkName: string }>;
      referral: Array<{ count: number; url: string }>;
      socialMediaClicks: Array<{ clicks: number; id: number; linkName: string; socialMediaType: BioSocialMediaType }>;
      totalClicks: Array<{ clicks: number; sectionType: BioSectionType }>;
    } | null;
    clicks?: {
      growth: number;
      percentage: number;
      total: number;
      history: Array<{ count: number; date: string }>;
    } | null;
    sessions?: {
      growth: number;
      percentage: number;
      total: number;
      history: Array<{ count: number; date: string }>;
    } | null;
    views?: {
      growth: number;
      percentage: number;
      total: number;
      history: Array<{ count: number; date: string }>;
    } | null;
  } | null;
}

export interface InfluencerBioAnalyticsOverviewFieldsFragment {
  growth: number;
  percentage: number;
  total: number;
  history: Array<{ count: number; date: string }>;
}

export type GetInfluencerBioV2QueryVariables = Exact<{ [key: string]: never }>;

export interface GetInfluencerBioV2Query {
  getInfluencerBioV2?: {
    id: number;
    isFinish: boolean;
    isPublic: boolean;
    linkName?: string | null;
    theme?: BioTheme | null;
    themeV2?: BioThemeV2 | null;
    profile: { avatar?: string | null; description?: string | null; title?: string | null };
    sections: Array<{
      id: number;
      order: number;
      sectionType: BioSectionType;
      image?: { image: string; linkUrl?: string | null } | null;
      link?: { linkIcon?: BioLinkIcon | null; linkName: string; linkUrl: string } | null;
      socialMedia?: {
        linkName: string;
        linkUrl: string;
        socialAccountId?: string | null;
        socialAccountType?: SocialAccountType | null;
        socialMedia: BioSocialMediaType;
      } | null;
      text?: { text: string } | null;
    }>;
  } | null;
}

export type GetInfluencerChatServicesQueryVariables = Exact<{ [key: string]: never }>;

export interface GetInfluencerChatServicesQuery {
  getInfluencerChatServices?: { line?: { liffUrl: string } | null } | null;
}

export type GetInfluencerRateCardForInfluencerQueryVariables = Exact<{ [key: string]: never }>;

export interface GetInfluencerRateCardForInfluencerQuery {
  getInfluencerRateCardForInfluencer?: {
    currencyId: string;
    facebook?: { __typename: 'InfluencerRateCardData'; price: number } | null;
    instagram?: { __typename: 'InfluencerRateCardData'; price: number } | null;
    instagramStory?: { __typename: 'InfluencerRateCardData'; price: number } | null;
    tiktok?: { __typename: 'InfluencerRateCardData'; price: number } | null;
    twitter?: { __typename: 'InfluencerRateCardData'; price: number } | null;
    youtube?: { __typename: 'InfluencerRateCardData'; price: number } | null;
  } | null;
}

export interface InfluencerRateCardDataInfoFragment {
  __typename: 'InfluencerRateCardData';
  price: number;
}

export type GetLineBroadcastEstimateRecipientsQueryVariables = Exact<{
  input: GetLineBroadcastEstimateRecipientsInput;
}>;

export interface GetLineBroadcastEstimateRecipientsQuery {
  getLineBroadcastEstimateRecipients?: { total: number } | null;
}

export type GetLineBroadcastMessageQueryVariables = Exact<{
  input: GetLineBroadcastMessageInput;
}>;

export interface GetLineBroadcastMessageQuery {
  getLineBroadcastMessage?: {
    createdDateTime: string;
    deliveredDateTime?: string | null;
    id: string;
    imageUrl: string;
    status?: LineBroadcastStatus | null;
    title: string;
    content: Array<{
      type: LineMessageType;
      buttonMessage?: {
        cards: Array<{
          imageUrl?: string | null;
          actionLabels: Array<{ label: string; url: string }>;
          description?: { fontSize: FontSize; text: string; weighted: boolean } | null;
          title?: { fontSize: FontSize; text: string; weighted: boolean } | null;
        }>;
      } | null;
      imageMessage?: { images: Array<{ imageUrl: string; label?: string | null; url: string }> } | null;
      mediaMessage?: {
        media: {
          type: MediaType;
          image?: { fileName: string; mimeType: string; previewUrl: string; url: string } | null;
          video?: { fileName: string; mimeType: string; previewUrl: string; url: string } | null;
        };
      } | null;
      plainTextMessage?: { text: string } | null;
      richMessage?: { imageUrl: string; url?: string | null } | null;
    }>;
    deliveryTiming: { scheduleDate?: string | null; type: DeliveryTimingType };
    recipient: { tagNames: Array<string>; type: RecipientType };
  } | null;
}

export type GetLineBroadcastSummaryQueryVariables = Exact<{
  input: GetLineBroadcastSummaryInput;
}>;

export interface GetLineBroadcastSummaryQuery {
  getLineBroadcastSummary?: {
    clickRate: { count: number; growth?: number | null; percentage?: number | null };
    delivered: { count: number; growth?: number | null; percentage?: number | null };
    openRate: { count: number; growth?: number | null; percentage?: number | null };
  } | null;
}

export type GetLineBroadcastsQueryVariables = Exact<{
  input: GetLineBroadcastsInput;
}>;

export interface GetLineBroadcastsQuery {
  getLineBroadcasts?: {
    broadcasts: Array<{
      click?: number | null;
      clickRate?: number | null;
      createdDateTime: string;
      delivered?: number | null;
      deliveredDateTime?: string | null;
      id: string;
      imageUrl: string;
      open?: number | null;
      openRate?: number | null;
      status: LineBroadcastStatus;
      tags: Array<string>;
      title: string;
    }>;
  } | null;
}

export type GetLineBroadcastsCountQueryVariables = Exact<{
  input: GetLineBroadcastsCountInput;
}>;

export interface GetLineBroadcastsCountQuery {
  getLineBroadcastsCount?: { total: number } | null;
}

export type GetLineChannelsQueryVariables = Exact<{ [key: string]: never }>;

export interface GetLineChannelsQuery {
  getLineChannels?: {
    id: any;
    lineChannelId: string;
    lineChannelSecret: string;
    lineKid: string;
    publicKey: string;
    publicKeyId: any;
  } | null;
}

export type GetLineChatQueryVariables = Exact<{
  input: GetLineChatInput;
}>;

export interface GetLineChatQuery {
  getLineChat?: { avatar?: string | null; chatId: string; name: string } | null;
}

export type GetLineChatCountQueryVariables = Exact<{
  input: GetLineChatCountInput;
}>;

export interface GetLineChatCountQuery {
  getLineChatCount?: { activatedCount: number; assignedToMe: number } | null;
}

export type GetLineChatsQueryVariables = Exact<{
  input: GetLineChatsInput;
}>;

export interface GetLineChatsQuery {
  getLineChats?: {
    chats: Array<{
      chatId: string;
      checked: boolean;
      checkedAt?: any | null;
      fanId?: any | null;
      lastMessage: string;
      lastMessageAt: any;
      resolved: boolean;
      userAvatar?: string | null;
      userEmail?: string | null;
      userId: string;
      userName: string;
      pic?: { id: any; name: string } | null;
    }>;
  } | null;
}

export type GetLinePublicKeyQueryVariables = Exact<{ [key: string]: never }>;

export interface GetLinePublicKeyQuery {
  getLinePublicKey?: { id: any; publicKey: string } | null;
}

export type GetLinkBioFacebookAccountPostsQueryVariables = Exact<{
  input: GetLinkBioFacebookAccountPostsInput;
}>;

export interface GetLinkBioFacebookAccountPostsQuery {
  getLinkBioFacebookAccountPosts?: { posts: Array<{ postSystemId: string; thumbnail: string }> } | null;
}

export type GetLinkBioFacebookPagePostsQueryVariables = Exact<{
  input: GetLinkBioFacebookPagePostsInput;
}>;

export interface GetLinkBioFacebookPagePostsQuery {
  getLinkBioFacebookPagePosts?: { posts: Array<{ postSystemId: string; thumbnail: string }> } | null;
}

export type GetLinkBioThemeColorQueryVariables = Exact<{ [key: string]: never }>;

export interface GetLinkBioThemeColorQuery {
  getLinkBioThemeColor?: {
    button?: BioButtonStyle | null;
    font?: BioFontStyle | null;
    theme?: BioTheme | null;
    bg: { bgColor?: string | null; media?: string | null };
    color: { buttonColor?: string | null; buttonFontColor?: string | null; fontColor?: string | null };
  } | null;
}

export type GetMarketplaceCampaignForInfluencerQueryVariables = Exact<{
  pk: Scalars['Int']['input'];
}>;

export interface GetMarketplaceCampaignForInfluencerQuery {
  getMarketplaceCampaignForInfluencer?: {
    ableToJoin?: boolean | null;
    anyXStatus?: AnyXMarketplaceCampaignStatus | null;
    appliedStatus?: MarketplaceCampaignAppliedStatus | null;
    campaignDetailType: MarketplaceCampaignDetailType;
    clickUrl?: string | null;
    commissionRate?: number | null;
    couponCode?: string | null;
    createdDate: any;
    currency: string;
    endDate?: any | null;
    hasReport: boolean;
    hashtags: Array<string>;
    id: number;
    isApplied: boolean;
    isAutoInfluencerApproval?: boolean | null;
    isCouponCampaign?: boolean | null;
    isGiftingCampaign?: boolean | null;
    isJoined: boolean;
    isPostTracking?: boolean | null;
    isSelectionCampaign?: boolean | null;
    materialUrl?: string | null;
    maxFollowers: number;
    maximumRevenuePerInfluencer?: number | null;
    minFollowers: number;
    minimumPaymentAmount: number;
    postStatus?: CampaignPostStatusForInfluencer | null;
    postUrl?: string | null;
    preLaunchDate?: any | null;
    remainingAmount: number;
    requireDraftPost: boolean;
    requirement: string;
    revenuePerAction?: number | null;
    revenuePerClick?: number | null;
    revenuePerComment?: number | null;
    revenuePerFollower?: number | null;
    revenuePerLike?: number | null;
    revenuePerOrder?: number | null;
    revenuePerPost?: number | null;
    revenuePerShare?: number | null;
    revenuePerView?: number | null;
    sampleUrl?: string | null;
    serviceInformation: string;
    socialMedias: Array<CampaignSocialMediaType>;
    startDate: any;
    status: CampaignStatusForInfluencer;
    thumbNail: string;
    thumbnails: Array<string>;
    title: string;
    totalAmount: number;
    trackingOptions?: Array<CampaignTrackingOption> | null;
    trackingUrl?: string | null;
    type: CampaignType;
    warningReason?: WarningReason | null;
    materials?: Array<{ id: number; url: string }> | null;
  } | null;
}

export type GetMessageTemplatesQueryVariables = Exact<{ [key: string]: never }>;

export interface GetMessageTemplatesQuery {
  getMessageTemplates?: { templates: Array<{ name: string; templateId: string; text: string }> } | null;
}

export type GetNotificationsQueryVariables = Exact<{
  input?: InputMaybe<UnreadNotificationInput>;
}>;

export interface GetNotificationsQuery {
  getNotifications?: {
    checkpoint: any;
    hasMore: boolean;
    notifications: Array<{ content: any; created: any; id: any; isClicked: boolean; notificationId: NotificationId }>;
  } | null;
}

export type GetPersonalUserDetailQueryVariables = Exact<{ [key: string]: never }>;

export interface GetPersonalUserDetailQuery {
  getPersonalUserDetail?: {
    displayLanguage?: DisplayLanguage | null;
    email: string;
    isAvailableCountry: boolean;
    name: string;
    notificationSetting?: boolean | null;
  } | null;
}

export type GetShopifyAccountQueryVariables = Exact<{ [key: string]: never }>;

export interface GetShopifyAccountQuery {
  getShopifyAccount?: { id: any; shopName: string } | null;
}

export type GetTagsAndFansRecipientsQueryVariables = Exact<{
  input: GetTagsAndFansRecipientsInput;
}>;

export interface GetTagsAndFansRecipientsQuery {
  getTagsAndFansRecipients?: {
    fans: Array<{ email?: string | null; id: any; name: string }>;
    tags: Array<{ count: any; id: any; tag: string }>;
  } | null;
}

export type GetUserDetailQueryVariables = Exact<{
  input: UserDetailInput;
}>;

export interface GetUserDetailQuery {
  getUserDetail?: {
    email?: string | null;
    isAvailableCountry: boolean;
    name: string;
    notificationSetting?: boolean | null;
  } | null;
}

export type InfluencerAllInstagramFeedPostsQueryVariables = Exact<{
  socialAccountId: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<InstagramFeedPostOrderBy>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
}>;

export interface InfluencerAllInstagramFeedPostsQuery {
  influencerAllInstagramFeedPosts?: Array<{
    comments?: number | null;
    content?: string | null;
    engagement?: number | null;
    engagementRate?: number | null;
    follow?: number | null;
    id: number;
    impressionFromDiscovery?: number | null;
    impressionFromHashtag?: number | null;
    impressions?: number | null;
    interaction?: number | null;
    likes?: number | null;
    nonFollowerReach?: number | null;
    postDate?: any | null;
    postUrl: string;
    productButtonClick?: number | null;
    productPageView?: number | null;
    reach?: number | null;
    saved?: number | null;
    share?: number | null;
    thumbNail?: string | null;
    views?: number | null;
  }> | null;
}

export type InfluencerAllInstagramFeedPostsCountQueryVariables = Exact<{
  socialAccountId: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
}>;

export interface InfluencerAllInstagramFeedPostsCountQuery {
  influencerAllInstagramFeedPostsCount?: { totalNumber: number } | null;
}

export type InfluencerAllInstagramInteractionUsersQueryVariables = Exact<{
  socialAccountId: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<InstagramInteractionUserOrderBy>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
}>;

export interface InfluencerAllInstagramInteractionUsersQuery {
  influencerAllInstagramInteractionUsers: {
    totalNumber: number;
    usernames?: Array<{
      avgMentionedComments: number;
      avgMentionedLikes: number;
      avgTaggedComments: number;
      avgTaggedLikes: number;
      avgTotalComments: number;
      avgTotalLikes: number;
      mentionedPosts: number;
      taggedPosts: number;
      totalPostsCount: number;
      username: string;
    }> | null;
  };
}

export type InfluencerAllInstagramMentionedPostsQueryVariables = Exact<{
  socialAccountId: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<InstagramFeedPostOrderBy>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
}>;

export interface InfluencerAllInstagramMentionedPostsQuery {
  influencerAllInstagramMentionedPosts?: Array<{
    comments?: number | null;
    content?: string | null;
    id: number;
    likes?: number | null;
    postDate?: any | null;
    postUrl: string;
    thumbNail?: string | null;
  }> | null;
}

export type InfluencerAllInstagramMentionedPostsCountQueryVariables = Exact<{
  socialAccountId: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
}>;

export interface InfluencerAllInstagramMentionedPostsCountQuery {
  influencerAllInstagramMentionedPostsCount?: { totalNumber: number } | null;
}

export type InfluencerAllInstagramPostsInDateQueryVariables = Exact<{
  socialAccountId: Scalars['Int']['input'];
  postDate: Scalars['Date']['input'];
}>;

export interface InfluencerAllInstagramPostsInDateQuery {
  influencerAllInstagramPostsInDate?: {
    feedPosts?: Array<{
      avatarUrl: string;
      content: string;
      followers?: number | null;
      id: number;
      instagramAccountName: string;
      mediaUrls?: Array<string> | null;
      postDate: any;
      postUrl: string;
      analysis?: {
        comments: number;
        impressions: number;
        likes: number;
        reach: number;
        saves: number;
        views: number;
      } | null;
      commentList?: Array<{ content: string; id: number; username?: string | null }> | null;
    }> | null;
    reelPosts?: Array<{
      avatarUrl: string;
      content: string;
      followers?: number | null;
      id: number;
      instagramAccountName: string;
      postDate: any;
      postUrl: string;
      thumbnail?: string | null;
      analysis?: {
        comments: number;
        likes: number;
        reach: number;
        saves: number;
        shares: number;
        views: number;
      } | null;
      commentList?: Array<{ content: string; id: number; username?: string | null }> | null;
    }> | null;
    storyPosts?: Array<{
      avatarUrl: string;
      comments: number;
      content: string;
      exits: number;
      followers?: number | null;
      id: number;
      impressions: number;
      instagramAccountName: string;
      postDate: any;
      postImageUrl?: string | null;
      postUrl: string;
      reach: number;
      storyVideoUrl?: string | null;
      tapsBack: number;
      tapsForward: number;
    }> | null;
  } | null;
}

export type InfluencerAllInstagramReelPostsQueryVariables = Exact<{
  socialAccountId: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<InstagramReelPostOrderBy>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
}>;

export interface InfluencerAllInstagramReelPostsQuery {
  influencerAllInstagramReelPosts?: Array<{
    comments?: number | null;
    content?: string | null;
    id: number;
    likes?: number | null;
    postDate?: any | null;
    postUrl: string;
    reach?: number | null;
    saved?: number | null;
    shares?: number | null;
    thumbNail?: string | null;
    views?: number | null;
  }> | null;
}

export type InfluencerAllInstagramReelPostsCountQueryVariables = Exact<{
  socialAccountId: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
}>;

export interface InfluencerAllInstagramReelPostsCountQuery {
  influencerAllInstagramReelPostsCount?: { totalNumber: number } | null;
}

export type InfluencerAllInstagramStoryPostsQueryVariables = Exact<{
  socialAccountId: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<InstagramStoryPostOrderBy>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
}>;

export interface InfluencerAllInstagramStoryPostsQuery {
  influencerAllInstagramStoryPosts?: Array<{
    comments?: number | null;
    content?: string | null;
    exits?: number | null;
    follow?: number | null;
    id: number;
    impressions?: number | null;
    interaction?: number | null;
    linkClicks?: number | null;
    postDate?: any | null;
    postUrl: string;
    productButtonClick?: number | null;
    productPageView?: number | null;
    reach?: number | null;
    stickerTaps?: number | null;
    tapsBack?: number | null;
    tapsForward?: number | null;
    thumbNail?: string | null;
  }> | null;
}

export type InfluencerAllInstagramStoryPostsCountQueryVariables = Exact<{
  socialAccountId: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
}>;

export interface InfluencerAllInstagramStoryPostsCountQuery {
  influencerAllInstagramStoryPostsCount?: { totalNumber: number } | null;
}

export type InfluencerAllInstagramTaggedPostsQueryVariables = Exact<{
  socialAccountId: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<InstagramFeedPostOrderBy>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
}>;

export interface InfluencerAllInstagramTaggedPostsQuery {
  influencerAllInstagramTaggedPosts?: Array<{
    comments?: number | null;
    content?: string | null;
    id: number;
    likes?: number | null;
    postDate?: any | null;
    postUrl: string;
    thumbNail?: string | null;
  }> | null;
}

export type InfluencerAllInstagramTaggedPostsCountQueryVariables = Exact<{
  socialAccountId: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
}>;

export interface InfluencerAllInstagramTaggedPostsCountQuery {
  influencerAllInstagramTaggedPostsCount?: { totalNumber: number } | null;
}

export type InfluencerAnalyticsInstagramAccessTokenWarningQueryVariables = Exact<{
  socialAccountId: Scalars['Int']['input'];
}>;

export interface InfluencerAnalyticsInstagramAccessTokenWarningQuery {
  influencerAnalyticsInstagramAccessTokenWarning: {
    needReconnect: boolean;
    dataForReconnect: { analyticsAccountId: number };
  };
}

export type InfluencerAnalyticsTwitterRefreshAvailabilityQueryVariables = Exact<{
  socialAccountId: Scalars['Int']['input'];
}>;

export interface InfluencerAnalyticsTwitterRefreshAvailabilityQuery {
  influencerAnalyticsTwitterRefreshAvailability: { isRefreshAvailable: boolean };
}

export type InfluencerFacebookPageAnalyticsOverviewQueryVariables = Exact<{
  socialAccountId: Scalars['Int']['input'];
  influencerId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
}>;

export interface InfluencerFacebookPageAnalyticsOverviewQuery {
  influencerFacebookPageAnalyticsOverview: {
    lastUpdated: any;
    comment: {
      __typename: 'FacebookPageAnalyticsOverviewItem';
      growth: any;
      percentage: number;
      total: any;
      history: Array<{ count: number; date: any }>;
    };
    engagement: {
      __typename: 'FacebookPageAnalyticsOverviewItem';
      growth: any;
      percentage: number;
      total: any;
      history: Array<{ count: number; date: any }>;
    };
    engagementRate: { growth: number; percentage: number; total: number; history: Array<{ count: number; date: any }> };
    follower: {
      __typename: 'FacebookPageAnalyticsOverviewItem';
      growth: any;
      percentage: number;
      total: any;
      history: Array<{ count: number; date: any }>;
    };
    impression: {
      __typename: 'FacebookPageAnalyticsOverviewItem';
      growth: any;
      percentage: number;
      total: any;
      history: Array<{ count: number; date: any }>;
    };
    pageCta: {
      __typename: 'FacebookPageAnalyticsOverviewItem';
      growth: any;
      percentage: number;
      total: any;
      history: Array<{ count: number; date: any }>;
    };
    pageImpression: {
      __typename: 'FacebookPageAnalyticsOverviewItem';
      growth: any;
      percentage: number;
      total: any;
      history: Array<{ count: number; date: any }>;
    };
    pageLikes: {
      __typename: 'FacebookPageAnalyticsOverviewItem';
      growth: any;
      percentage: number;
      total: any;
      history: Array<{ count: number; date: any }>;
    };
    post: {
      __typename: 'FacebookPageAnalyticsOverviewItem';
      growth: any;
      percentage: number;
      total: any;
      history: Array<{ count: number; date: any }>;
    };
    reaction: {
      __typename: 'FacebookPageAnalyticsOverviewItem';
      growth: any;
      percentage: number;
      total: any;
      history: Array<{ count: number; date: any }>;
    };
    share: {
      __typename: 'FacebookPageAnalyticsOverviewItem';
      growth: any;
      percentage: number;
      total: any;
      history: Array<{ count: number; date: any }>;
    };
    view: {
      __typename: 'FacebookPageAnalyticsOverviewItem';
      growth: any;
      percentage: number;
      total: any;
      history: Array<{ count: number; date: any }>;
    };
  };
}

export interface FacebookPageAnalyticsOverviewItemFieldsFragment {
  __typename: 'FacebookPageAnalyticsOverviewItem';
  growth: any;
  percentage: number;
  total: any;
  history: Array<{ count: number; date: any }>;
}

export type InfluencerFacebookPageAnalyticsPostQueryVariables = Exact<{
  socialAccountId: Scalars['Int']['input'];
  influencerId: Scalars['Int']['input'];
  postId: Scalars['Int']['input'];
}>;

export interface InfluencerFacebookPageAnalyticsPostQuery {
  influencerFacebookPageAnalyticsPost: {
    adImpressions: any;
    comments: number;
    content: string;
    id: number;
    image?: string | null;
    postDate: any;
    postUrl: string;
    reactions: number;
    shares: number;
    subscribers: number;
    views: number;
    postComments: Array<{ commentId: string; content: string; posterName: string }>;
  };
}

export type InfluencerFacebookPageAnalyticsPostsQueryVariables = Exact<{
  socialAccountId: Scalars['Int']['input'];
  influencerId: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  orderBy: FacebookPageAnalyticsPostsSortInput;
  startDate: Scalars['Date']['input'];
  endDate: Scalars['Date']['input'];
}>;

export interface InfluencerFacebookPageAnalyticsPostsQuery {
  influencerFacebookPageAnalyticsPosts: {
    posts: Array<{
      adImpressions: any;
      comments: number;
      content: string;
      engagement: number;
      id: number;
      image?: string | null;
      postDate: any;
      postUrl: string;
      reactions: number;
      shares: number;
      views: number;
    }>;
  };
}

export type InfluencerFacebookPageAnalyticsPostsAnalyticsQueryVariables = Exact<{
  socialAccountId: Scalars['Int']['input'];
  influencerId: Scalars['Int']['input'];
  startDate: Scalars['Date']['input'];
  endDate: Scalars['Date']['input'];
}>;

export interface InfluencerFacebookPageAnalyticsPostsAnalyticsQuery {
  influencerFacebookPageAnalyticsPostsAnalytics: {
    averageEngagement?: {
      averageComments?: { __typename: 'FacebookPageStatisticsData'; growth: number; value: number } | null;
      averageImpressions?: { __typename: 'FacebookPageStatisticsData'; growth: number; value: number } | null;
      averagePostsPerWeek?: { __typename: 'FacebookPageStatisticsData'; growth: number; value: number } | null;
      averageReactions?: { __typename: 'FacebookPageStatisticsData'; growth: number; value: number } | null;
      averageShares?: { __typename: 'FacebookPageStatisticsData'; growth: number; value: number } | null;
      averageViews?: { __typename: 'FacebookPageStatisticsData'; growth: number; value: number } | null;
    } | null;
    fanActivity: Array<{ hour: number; onlineCount: number; weekday: number }>;
  };
}

export interface FacebookPageStatisticsDataFieldsFragment {
  __typename: 'FacebookPageStatisticsData';
  growth: number;
  value: number;
}

export type InfluencerFacebookPageAnalyticsPostsByDateQueryVariables = Exact<{
  socialAccountId: Scalars['Int']['input'];
  influencerId: Scalars['Int']['input'];
  date: Scalars['Date']['input'];
}>;

export interface InfluencerFacebookPageAnalyticsPostsByDateQuery {
  influencerFacebookPageAnalyticsPostsByDate: {
    posts: Array<{
      adImpressions: any;
      comments: number;
      content: string;
      id: number;
      image?: string | null;
      postDate: any;
      postUrl: string;
      reactions: number;
      shares: number;
      subscribers: number;
      views: number;
      postComments: Array<{ commentId: string; content: string; posterName: string }>;
    }>;
  };
}

export type InfluencerFacebookPageAnalyticsPostsCountQueryVariables = Exact<{
  socialAccountId: Scalars['Int']['input'];
  influencerId: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
}>;

export interface InfluencerFacebookPageAnalyticsPostsCountQuery {
  influencerFacebookPageAnalyticsPostsCount: { totalNumber: number };
}

export type InfluencerHasEstimateApiQueryVariables = Exact<{
  pk: Scalars['Int']['input'];
  socialMedia: SocialAccountType;
  socialAccountId: Scalars['Int']['input'];
}>;

export interface InfluencerHasEstimateApiQuery {
  influencerHasEstimateApi: {
    accountInterest: boolean;
    audienceDemographics: boolean;
    message?: ConstantHasEstimateMessage | null;
  };
}

export type InfluencerInstagramAnalyticsPostDetailsQueryVariables = Exact<{
  postId: Scalars['Int']['input'];
  socialAccountId: Scalars['Int']['input'];
}>;

export interface InfluencerInstagramAnalyticsPostDetailsQuery {
  influencerInstagramAnalyticsPostDetails?: {
    avatarUrl: string;
    content: string;
    followers?: number | null;
    id: number;
    instagramAccountName: string;
    mediaUrls?: Array<string> | null;
    postDate: any;
    postUrl: string;
    analysis?: {
      comments: number;
      impressions: number;
      likes: number;
      reach: number;
      saves: number;
      views: number;
    } | null;
    commentList?: Array<{ content: string; id: number; username?: string | null }> | null;
  } | null;
}

export type InfluencerInstagramAnalyticsReelDetailsQueryVariables = Exact<{
  postId: Scalars['Int']['input'];
  socialAccountId: Scalars['Int']['input'];
}>;

export interface InfluencerInstagramAnalyticsReelDetailsQuery {
  influencerInstagramAnalyticsReelDetails?: {
    avatarUrl: string;
    content: string;
    followers?: number | null;
    id: number;
    instagramAccountName: string;
    postDate: any;
    postUrl: string;
    thumbnail?: string | null;
    analysis?: { comments: number; likes: number; reach: number; saves: number; shares: number; views: number } | null;
    commentList?: Array<{ content: string; id: number; username?: string | null }> | null;
  } | null;
}

export type InfluencerInstagramAnalyticsStoryDetailsQueryVariables = Exact<{
  socialAccountId: Scalars['Int']['input'];
  storyId: Scalars['Int']['input'];
}>;

export interface InfluencerInstagramAnalyticsStoryDetailsQuery {
  influencerInstagramAnalyticsStoryDetails?: {
    avatarUrl: string;
    comments: number;
    content: string;
    exits: number;
    followers?: number | null;
    id: number;
    impressions: number;
    instagramAccountName: string;
    postDate: any;
    postImageUrl?: string | null;
    postUrl: string;
    reach: number;
    storyVideoUrl?: string | null;
    swipeForward: number;
    tapsBack: number;
    tapsForward: number;
  } | null;
}

export type InfluencerInstagramDashboardFollowerAnalyticsQueryVariables = Exact<{
  socialAccountId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
}>;

export interface InfluencerInstagramDashboardFollowerAnalyticsQuery {
  influencerInstagramDashboardFollowerAnalytics?: {
    followerAgeRate: {
      femaleRate?: Array<number> | null;
      maleRate?: Array<number> | null;
      otherRate?: Array<number> | null;
      ageGroup?: Array<{ end: number; start: number }> | null;
    };
    followerCountryRate?: Array<{ id: string; rate: number }> | null;
    followerGenderRate: { femaleRate?: number | null; maleRate?: number | null; otherRate?: number | null };
    followerRegionRate?: Array<{ name: string; rate: number }> | null;
  } | null;
}

export type InfluencerInstagramDashboardOverviewQueryVariables = Exact<{
  socialAccountId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
}>;

export interface InfluencerInstagramDashboardOverviewQuery {
  influencerInstagramDashboardOverview?: {
    lastUpdated?: any | null;
    comments: {
      __typename: 'InstagramAnalyticsOverviewData';
      growth?: number | null;
      percentage?: number | null;
      total?: number | null;
      history?: Array<{ count?: number | null; date?: any | null }> | null;
    };
    engagement: {
      __typename: 'InstagramAnalyticsOverviewData';
      growth?: number | null;
      percentage?: number | null;
      total?: number | null;
      history?: Array<{ count?: number | null; date?: any | null }> | null;
    };
    engagementRate: {
      growth?: number | null;
      percentage?: number | null;
      total?: number | null;
      history?: Array<{ count?: number | null; date?: any | null }> | null;
    };
    followers: {
      __typename: 'InstagramAnalyticsOverviewData';
      growth?: number | null;
      percentage?: number | null;
      total?: number | null;
      history?: Array<{ count?: number | null; date?: any | null }> | null;
    };
    impressions: {
      __typename: 'InstagramAnalyticsOverviewData';
      growth?: number | null;
      percentage?: number | null;
      total?: number | null;
      history?: Array<{ count?: number | null; date?: any | null }> | null;
    };
    likes: {
      __typename: 'InstagramAnalyticsOverviewData';
      growth?: number | null;
      percentage?: number | null;
      total?: number | null;
      history?: Array<{ count?: number | null; date?: any | null }> | null;
    };
    posts: {
      __typename: 'InstagramAnalyticsOverviewData';
      growth?: number | null;
      percentage?: number | null;
      total?: number | null;
      history?: Array<{ count?: number | null; date?: any | null }> | null;
    };
    profileViews: {
      __typename: 'InstagramAnalyticsOverviewData';
      growth?: number | null;
      percentage?: number | null;
      total?: number | null;
      history?: Array<{ count?: number | null; date?: any | null }> | null;
    };
    reach: {
      __typename: 'InstagramAnalyticsOverviewData';
      growth?: number | null;
      percentage?: number | null;
      total?: number | null;
      history?: Array<{ count?: number | null; date?: any | null }> | null;
    };
    saved: {
      __typename: 'InstagramAnalyticsOverviewData';
      growth?: number | null;
      percentage?: number | null;
      total?: number | null;
      history?: Array<{ count?: number | null; date?: any | null }> | null;
    };
    websiteClicks: {
      __typename: 'InstagramAnalyticsOverviewData';
      growth?: number | null;
      percentage?: number | null;
      total?: number | null;
      history?: Array<{ count?: number | null; date?: any | null }> | null;
    };
  } | null;
}

export interface InstagramAnalyticsOverviewDataFieldsFragment {
  __typename: 'InstagramAnalyticsOverviewData';
  growth?: number | null;
  percentage?: number | null;
  total?: number | null;
  history?: Array<{ count?: number | null; date?: any | null }> | null;
}

export type InfluencerInstagramDashboardPostAnalyticsQueryVariables = Exact<{
  socialAccountId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
}>;

export interface InfluencerInstagramDashboardPostAnalyticsQuery {
  influencerInstagramDashboardPostAnalytics?: {
    averageEngagement?: {
      averageComments?: number | null;
      averageCommentsGrowth?: number | null;
      averageLikes?: number | null;
      averageLikesGrowth?: number | null;
      averagePostsGrowth?: number | null;
      averagePostsPerWeek?: number | null;
      averageSaved?: number | null;
      averageSavedGrowth?: number | null;
    } | null;
    commentAnalytics?: {
      negativeRatio?: number | null;
      neutralRatio?: number | null;
      positiveRatio?: number | null;
    } | null;
    comments?: Array<{
      commentId?: string | null;
      content?: string | null;
      created?: any | null;
      postUrl?: string | null;
      username?: string | null;
    }> | null;
    feedPosts?: Array<{
      comments?: number | null;
      content?: string | null;
      engagement?: number | null;
      engagementRate?: number | null;
      follow?: number | null;
      id: number;
      impressionFromDiscovery?: number | null;
      impressionFromHashtag?: number | null;
      impressions?: number | null;
      interaction?: number | null;
      likes?: number | null;
      nonFollowerReach?: number | null;
      postDate?: any | null;
      postUrl: string;
      productButtonClick?: number | null;
      productPageView?: number | null;
      reach?: number | null;
      saved?: number | null;
      share?: number | null;
      thumbNail?: string | null;
      views?: number | null;
    }> | null;
    postsHabit?: Array<{
      averageComments: number;
      averageLikes: number;
      engagementRate: number;
      hour: number;
      weekday: number;
    }> | null;
    reelsPosts?: Array<{
      comments?: number | null;
      id: number;
      likes?: number | null;
      postUrl: string;
      thumbNail?: string | null;
    }> | null;
    storyPosts?: Array<{
      comments?: number | null;
      content?: string | null;
      exits?: number | null;
      follow?: number | null;
      id: number;
      impressions?: number | null;
      interaction?: number | null;
      linkClicks?: number | null;
      postDate?: any | null;
      postUrl: string;
      productButtonClick?: number | null;
      productPageView?: number | null;
      reach?: number | null;
      stickerTaps?: number | null;
      tapsBack?: number | null;
      tapsForward?: number | null;
      thumbNail?: string | null;
    }> | null;
  } | null;
}

export type InfluencerInstagramInteractionMentionedPostDetailsQueryVariables = Exact<{
  socialAccountId: Scalars['Int']['input'];
  postId: Scalars['Int']['input'];
}>;

export interface InfluencerInstagramInteractionMentionedPostDetailsQuery {
  influencerInstagramInteractionMentionedPostDetails?: {
    avatarUrl?: string | null;
    comments: number;
    content: string;
    id: number;
    instagramAccountName?: string | null;
    likes: number;
    postDate: any;
    postImageUrl?: string | null;
    postUrl: string;
    commentList?: Array<{ content: string; id: number; username?: string | null }> | null;
  } | null;
}

export type InfluencerInstagramInteractionOverviewQueryVariables = Exact<{
  socialAccountId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
}>;

export interface InfluencerInstagramInteractionOverviewQuery {
  influencerInstagramInteractionOverview?: {
    graph: {
      followers: Array<{ __typename: 'InstagramAnalyticsHistoryDataPoint'; count?: number | null; date?: any | null }>;
      mentionedComments: Array<{
        __typename: 'InstagramAnalyticsHistoryDataPoint';
        count?: number | null;
        date?: any | null;
      }>;
      mentionedLikes: Array<{
        __typename: 'InstagramAnalyticsHistoryDataPoint';
        count?: number | null;
        date?: any | null;
      }>;
      mentionedPosts: Array<{
        __typename: 'InstagramAnalyticsHistoryDataPoint';
        count?: number | null;
        date?: any | null;
      }>;
      taggedComments: Array<{
        __typename: 'InstagramAnalyticsHistoryDataPoint';
        count?: number | null;
        date?: any | null;
      }>;
      taggedLikes: Array<{
        __typename: 'InstagramAnalyticsHistoryDataPoint';
        count?: number | null;
        date?: any | null;
      }>;
      taggedPosts: Array<{
        __typename: 'InstagramAnalyticsHistoryDataPoint';
        count?: number | null;
        date?: any | null;
      }>;
    };
    summaries: {
      mentionComments: {
        __typename: 'InstagramInteractionOverviewSummaryData';
        growth?: number | null;
        total?: number | null;
        rate?: number | null;
      };
      mentionLikes: {
        __typename: 'InstagramInteractionOverviewSummaryData';
        growth?: number | null;
        total?: number | null;
        rate?: number | null;
      };
      mentionPosts: {
        __typename: 'InstagramInteractionOverviewSummaryData';
        growth?: number | null;
        total?: number | null;
        rate?: number | null;
      };
      taggedComments: {
        __typename: 'InstagramInteractionOverviewSummaryData';
        growth?: number | null;
        total?: number | null;
        rate?: number | null;
      };
      taggedLikes: {
        __typename: 'InstagramInteractionOverviewSummaryData';
        growth?: number | null;
        total?: number | null;
        rate?: number | null;
      };
      taggedPosts: {
        __typename: 'InstagramInteractionOverviewSummaryData';
        growth?: number | null;
        total?: number | null;
        rate?: number | null;
      };
    };
  } | null;
}

export interface InstagramAnalyticsHistoryDataPointFieldsFragment {
  __typename: 'InstagramAnalyticsHistoryDataPoint';
  count?: number | null;
  date?: any | null;
}

export interface InstagramInteractionOverviewSummaryDataFieldsFragment {
  __typename: 'InstagramInteractionOverviewSummaryData';
  growth?: number | null;
  total?: number | null;
  rate?: number | null;
}

export type InfluencerInstagramInteractionPostsQueryVariables = Exact<{
  socialAccountId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
}>;

export interface InfluencerInstagramInteractionPostsQuery {
  influencerInstagramInteractionPosts?: {
    mentionedPosts: Array<{
      comments?: number | null;
      content?: string | null;
      id: number;
      likes?: number | null;
      postDate: any;
      postUrl: string;
      thumbNail?: string | null;
    }>;
    taggedPosts: Array<{
      comments?: number | null;
      content?: string | null;
      id: number;
      likes?: number | null;
      postDate: any;
      postUrl: string;
      thumbNail?: string | null;
    }>;
    userAnalytics: Array<{
      comments?: number | null;
      likes?: number | null;
      postsCount?: number | null;
      username: string;
    }>;
  } | null;
}

export type InfluencerInstagramInteractionTaggedPostDetailsQueryVariables = Exact<{
  socialAccountId: Scalars['Int']['input'];
  postId: Scalars['Int']['input'];
}>;

export interface InfluencerInstagramInteractionTaggedPostDetailsQuery {
  influencerInstagramInteractionTaggedPostDetails?: {
    avatarUrl?: string | null;
    comments: number;
    content: string;
    id: number;
    instagramAccountName?: string | null;
    likes: number;
    postDate: any;
    postImageUrl?: string | null;
    postUrl: string;
    commentList?: Array<{ content: string; id: number; username?: string | null }> | null;
  } | null;
}

export type InfluencerInterestsQueryVariables = Exact<{
  influencerId: Scalars['Int']['input'];
  socialMedia: SocialAccountType;
  socialAccountId: Scalars['Int']['input'];
}>;

export interface InfluencerInterestsQuery {
  influencerInterests?: Array<{ category: string; rate: number }> | null;
}

export type InfluencerPaymentInformationQueryVariables = Exact<{ [key: string]: never }>;

export interface InfluencerPaymentInformationQuery {
  influencerPaymentInformation?: {
    accountName?: string | null;
    accountNumber?: string | null;
    address?: string | null;
    bankId?: number | null;
    branchId?: number | null;
    branchName?: string | null;
    cityId?: number | null;
    fullName?: string | null;
    iban?: string | null;
    paymentEmail?: string | null;
    postalCode?: string | null;
    subject?: string | null;
    swiftCode?: string | null;
  } | null;
}

export type InfluencerPaymentRequestsQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
}>;

export interface InfluencerPaymentRequestsQuery {
  influencerPaymentRequests: {
    currency: string;
    totalNetRevenue: number;
    paymentRequests: Array<{
      consumptionTax?: number | null;
      currency: string;
      dueDate: any;
      fee: number;
      grossRevenue: number;
      id: number;
      netRevenue: number;
      paidDate?: any | null;
      requestedDate: any;
      status: PaymentRequestStatusForInfluencer;
      withholdingFee?: number | null;
      campaigns?: Array<{ campaignType: CampaignType; id: number; revenue: number; title: string }> | null;
    }>;
  };
}

export type InfluencerPostDetailQueryVariables = Exact<{
  pk: Scalars['Int']['input'];
  postId: Scalars['Int']['input'];
  needSimilarPosts?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export interface InfluencerPostDetailQuery {
  influencerPostDetail?: {
    avatar?: string | null;
    caption: string;
    comments?: number | null;
    engagement?: number | null;
    followersCount?: number | null;
    id: number;
    influencerId: number;
    influencerName: string;
    likes?: number | null;
    postDate?: any | null;
    postUrl?: string | null;
    reactions?: number | null;
    sentimentNegativeRatio?: number | null;
    sentimentPositiveRatio?: number | null;
    shares?: number | null;
    socialMedia: SocialPostType;
    thumbNail?: string | null;
    title?: string | null;
    views?: number | null;
    commentsContent?: Array<{ content?: string | null; id?: number | null; username?: string | null }> | null;
    similarPosts?: Array<{
      image?: string | null;
      influencerId: number;
      postId: number;
      postType: SocialPostType;
      socialAccountId: number;
      username: string;
    }> | null;
  } | null;
}

export type InfluencerProfileAudienceQueryVariables = Exact<{
  pk: Scalars['Int']['input'];
  socialMedia: SocialAccountType;
  socialAccountId: Scalars['Int']['input'];
}>;

export interface InfluencerProfileAudienceQuery {
  influencerProfileAudience?: {
    femaleRate?: number | null;
    maleRate?: number | null;
    ageGenderRates: {
      femaleRate?: Array<number> | null;
      maleRate?: Array<number> | null;
      ageGroup?: Array<{ end: number; start: number }> | null;
    };
  } | null;
}

export type InfluencerProfileDetailV2QueryVariables = Exact<{
  pk: Scalars['Int']['input'];
  socialMedia: SocialAccountType;
  socialAccountId: Scalars['Int']['input'];
}>;

export interface InfluencerProfileDetailV2Query {
  influencerProfileDetailV2?: {
    lastUpdatedDatetime: any;
    followers: {
      femaleRate?: number | null;
      maleRate?: number | null;
      ageGenderRates?: {
        femaleRate?: Array<number> | null;
        maleRate?: Array<number> | null;
        ageGroup?: Array<{ end: number; start: number }> | null;
      } | null;
      countryRates?: Array<{ id: string; name: string; rate: number }> | null;
      followersGrowth?: Array<{ followersCount?: number | null; time: any }> | null;
    };
    popularPosts: Array<{
      comments: number;
      content?: string | null;
      id: number;
      likes: number;
      shares?: number | null;
      socialMedia: SocialAccountType;
      thumbNail?: string | null;
      views?: number | null;
    }>;
    postsHabit: Array<{
      averageComments: number;
      averageLikes: number;
      averageShares: number;
      averageViews: number;
      engagementRate: number;
      hour: number;
      weekday: number;
    }>;
    postsStats: {
      averageComments?: number | null;
      averageEngagement?: number | null;
      averageLikes?: number | null;
      averageShares?: number | null;
      averageViews?: number | null;
      engagementFollowersRate?: number | null;
      engagementViewsRate?: number | null;
    };
  } | null;
}

export type InfluencerProfileFollowerGrowthQueryVariables = Exact<{
  pk: Scalars['Int']['input'];
  socialMedia: SocialAccountType;
  socialAccountId: Scalars['Int']['input'];
}>;

export interface InfluencerProfileFollowerGrowthQuery {
  influencerProfileFollowerGrowth?: {
    followersCount: number;
    hasPost?: boolean | null;
    lastUpdatedDatetime: any;
    postsCount: number;
    followersGrowth: Array<{ followersCount?: number | null; time: any }>;
    postsGrowth: Array<{ date: any; postIds: Array<number>; postsCount: number }>;
  } | null;
}

export type InfluencerProfilePostsQueryVariables = Exact<{
  pk: Scalars['Int']['input'];
  socialMedia: SocialAccountType;
  socialAccountId: Scalars['Int']['input'];
}>;

export interface InfluencerProfilePostsQuery {
  influencerProfilePosts?: {
    averageComments: number;
    averageLikes?: number | null;
    averagePostsPerWeek: number;
    averageReactions?: number | null;
    averageSaved?: number | null;
    averageShares?: number | null;
    averageViews?: number | null;
    engagementFollowersRate: number;
    engagementViewsRate?: number | null;
    estimateReach?: number | null;
    accountInterests: Array<{ category: string; rate: number }>;
    feedPosts: Array<{
      caption?: string | null;
      comments?: number | null;
      id: number;
      likes?: number | null;
      reactions?: number | null;
      shares?: number | null;
      socialMedia: SocialAccountType;
      thumbnail?: string | null;
      title?: string | null;
      views?: number | null;
    }>;
    postsHabit: Array<{
      averageComments: number;
      averageLikes: number;
      averageShares: number;
      averageViews: number;
      engagementRate: number;
      hour: number;
      weekday: number;
    }>;
    reelPosts?: Array<{
      caption?: string | null;
      comments: number;
      id: number;
      likes: number;
      socialMedia: SocialPostType;
      thumbnail?: string | null;
      title?: string | null;
    }> | null;
  } | null;
}

export type InfluencerProfileV2QueryVariables = Exact<{
  pk: Scalars['Int']['input'];
}>;

export interface InfluencerProfileV2Query {
  influencerProfileV2?: {
    age?: number | null;
    avatar?: string | null;
    cover?: string | null;
    defaultSelectionReason?: string | null;
    followersCount?: number | null;
    gender: Genders;
    hideSensitiveMetrics: boolean;
    id: number;
    internalMemo?: string | null;
    introduce?: string | null;
    isBrandAccount: boolean;
    isTaggedToCompany?: boolean | null;
    name: string;
    partnerIds?: Array<number> | null;
    partnerTalentAgencies: Array<string>;
    status: SocialAccountStatus;
    tags: Array<string>;
    talentAgencyIds?: Array<number> | null;
    categories: Array<{ description: string; id: number }>;
    country: { id: string; isMena: boolean; name: string };
    douyinAccounts?: Array<{
      __typename: 'SocialAccountForProfileV2';
      avatar?: string | null;
      followersCount?: number | null;
      id: number;
      name?: string | null;
      profile?: string | null;
      url?: string | null;
    }> | null;
    emailNewsLetters?: Array<{ description?: string | null; email?: string | null; id: number }> | null;
    engagement: {
      averageEngagement?: number | null;
      engagementFollowersRate?: number | null;
      engagementViewsRate?: number | null;
    };
    facebookAccount?: {
      __typename: 'SocialAccountForProfileV2';
      avatar?: string | null;
      followersCount?: number | null;
      id: number;
      name?: string | null;
      profile?: string | null;
      url?: string | null;
    } | null;
    facebookPages?: Array<{
      analyticsEnabled: boolean;
      avatar?: string | null;
      followersCount?: number | null;
      id: number;
      name?: string | null;
      profile?: string | null;
      url?: string | null;
    }> | null;
    instagramAccounts?: Array<{
      analyticsEnabled: boolean;
      avatar?: string | null;
      followersCount?: number | null;
      id: number;
      isBusiness: boolean;
      name?: string | null;
      profile?: string | null;
      url?: string | null;
    }> | null;
    mobileApps?: Array<{ id: number; mobileAppType?: PromotionMobileAppType | null; url?: string | null }> | null;
    offlines?: Array<{ description?: string | null; id: number }> | null;
    podCasts?: Array<{ description?: string | null; id: number; url?: string | null }> | null;
    region?: { id: number; name: string } | null;
    threadsAccounts?: Array<{
      __typename: 'SocialAccountForProfileV2';
      avatar?: string | null;
      followersCount?: number | null;
      id: number;
      name?: string | null;
      profile?: string | null;
      url?: string | null;
    }> | null;
    tiktokAccounts?: Array<{
      avatar?: string | null;
      followersCount?: number | null;
      id: number;
      name?: string | null;
      profile?: string | null;
      ttcmStatus?: TTCMStatus | null;
      url?: string | null;
    }> | null;
    twitterAccounts?: Array<{
      analyticsEnabled: boolean;
      avatar?: string | null;
      followersCount?: number | null;
      id: number;
      name?: string | null;
      profile?: string | null;
      url?: string | null;
    }> | null;
    websites?: Array<{ description?: string | null; id: number; url?: string | null }> | null;
    youtubeAccounts?: Array<{
      analyticsEnabled: boolean;
      avatar?: string | null;
      followersCount?: number | null;
      id: number;
      name?: string | null;
      profile?: string | null;
      url?: string | null;
    }> | null;
  } | null;
}

export interface SocialAccountForProfileV2FieldsFragment {
  __typename: 'SocialAccountForProfileV2';
  avatar?: string | null;
  followersCount?: number | null;
  id: number;
  name?: string | null;
  profile?: string | null;
  url?: string | null;
}

export type InfluencerPromotionMethodsQueryVariables = Exact<{ [key: string]: never }>;

export interface InfluencerPromotionMethodsQuery {
  influencerPromotionMethods?: {
    emailNewsLetters: Array<{ description?: string | null; email?: string | null; id: string }>;
    mobileApps: Array<{ id: string; mobileAppType?: PromotionMobileAppType | null; url?: string | null }>;
    offlines: Array<{ description?: string | null; id: string }>;
    podCasts: Array<{ description?: string | null; id: string; url?: string | null }>;
    websites: Array<{ description?: string | null; id: string; url?: string | null }>;
  } | null;
}

export type InfluencerUpdateDetailV2QueryVariables = Exact<{
  pk: Scalars['Int']['input'];
}>;

export interface InfluencerUpdateDetailV2Query {
  influencerUpdateDetailV2?: {
    campaignDisplay: boolean;
    dateOfBirth?: any | null;
    defaultEngagementSelectionReason?: string | null;
    email?: string | null;
    gender: Genders;
    id: number;
    internalMemo?: string | null;
    introduce?: string | null;
    isBrandAccount: boolean;
    isProAccount: boolean;
    managerIds?: Array<number> | null;
    name: string;
    notificationSetting: boolean;
    phoneNumber?: string | null;
    status: SocialAccountStatus;
    tags: Array<string>;
    categories: Array<{ description: string; id: number }>;
    country: { id: string; isMena: boolean; name: string };
    facebookAccount?: {
      __typename: 'InfluencerSocialNetworkAccountOutput';
      socialAccountId: number;
      status: SocialAccountStatus;
      username: string;
    } | null;
    facebookPages?: Array<{
      __typename: 'InfluencerSocialNetworkAccountOutput';
      socialAccountId: number;
      status: SocialAccountStatus;
      username: string;
    }> | null;
    instagramAccounts?: Array<{
      __typename: 'InfluencerSocialNetworkAccountOutput';
      socialAccountId: number;
      status: SocialAccountStatus;
      username: string;
    }> | null;
    partners?: Array<{ id: number; name: string }> | null;
    region?: { id: number; name: string } | null;
    talentAgencies?: Array<{ id: number; name: string }> | null;
    tiktokAccounts?: Array<{
      __typename: 'InfluencerSocialNetworkAccountOutput';
      socialAccountId: number;
      status: SocialAccountStatus;
      username: string;
    }> | null;
    twitterAccounts?: Array<{
      __typename: 'InfluencerSocialNetworkAccountOutput';
      socialAccountId: number;
      status: SocialAccountStatus;
      username: string;
    }> | null;
    youtubeAccounts?: Array<{
      __typename: 'InfluencerSocialNetworkAccountOutput';
      socialAccountId: number;
      status: SocialAccountStatus;
      username: string;
    }> | null;
  } | null;
}

export interface InfluencerSocialNetworkAccountOutputFieldsFragment {
  __typename: 'InfluencerSocialNetworkAccountOutput';
  socialAccountId: number;
  status: SocialAccountStatus;
  username: string;
}

export type InfluencerYoutubeAnalyticsAudienceQueryVariables = Exact<{
  socialAccountId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
}>;

export interface InfluencerYoutubeAnalyticsAudienceQuery {
  influencerYoutubeAnalyticsAudience: {
    ageRate: {
      femaleRate: Array<number>;
      maleRate: Array<number>;
      otherRate: Array<number>;
      ageGroup: Array<{ end: number; start: number }>;
    };
    genderRate: { femaleRate: number; maleRate: number; otherRate: number };
    regionRate: Array<{ name: string; rate: number }>;
  };
}

export type InfluencerYoutubeAnalyticsChannelDetailsQueryVariables = Exact<{
  socialAccountId: Scalars['Int']['input'];
}>;

export interface InfluencerYoutubeAnalyticsChannelDetailsQuery {
  influencerYoutubeAnalyticsChannelDetails: { channelAvatar: string; channelId: string; channelName: string };
}

export type InfluencerYoutubeAnalyticsCompareAccountCardsQueryVariables = Exact<{
  socialAccountId: Scalars['Int']['input'];
}>;

export interface InfluencerYoutubeAnalyticsCompareAccountCardsQuery {
  influencerYoutubeAnalyticsCompareAccountCards: {
    compareAccounts: Array<{
      accountId: number;
      avatarUrl: string;
      channelName: string;
      elementId: number;
      profileUrl: string;
    }>;
    mainAccount?: {
      avatarUrl: string;
      channelName: string;
      elementId: number;
      profileUrl: string;
      accountId: number;
    } | null;
  };
}

export type InfluencerYoutubeAnalyticsOverviewQueryVariables = Exact<{
  socialAccountId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
}>;

export interface InfluencerYoutubeAnalyticsOverviewQuery {
  influencerYoutubeAnalyticsOverview: {
    lastUpdated: any;
    comments: {
      __typename: 'YoutubeAnalyticsOverviewItem';
      growth: any;
      percentage: number;
      total: any;
      history: Array<{ count: number; date: any }>;
    };
    dislikes: {
      __typename: 'YoutubeAnalyticsOverviewItem';
      growth: any;
      percentage: number;
      total: any;
      history: Array<{ count: number; date: any }>;
    };
    likes: {
      __typename: 'YoutubeAnalyticsOverviewItem';
      growth: any;
      percentage: number;
      total: any;
      history: Array<{ count: number; date: any }>;
    };
    posts: {
      __typename: 'YoutubeAnalyticsOverviewItem';
      growth: any;
      percentage: number;
      total: any;
      history: Array<{ count: number; date: any }>;
    };
    subscriber: {
      __typename: 'YoutubeAnalyticsOverviewItem';
      growth: any;
      percentage: number;
      total: any;
      history: Array<{ count: number; date: any }>;
    };
    views: {
      __typename: 'YoutubeAnalyticsOverviewItem';
      growth: any;
      percentage: number;
      total: any;
      history: Array<{ count: number; date: any }>;
    };
  };
}

export interface YoutubeAnalyticsOverviewItemFieldsFragment {
  __typename: 'YoutubeAnalyticsOverviewItem';
  growth: any;
  percentage: number;
  total: any;
  history: Array<{ count: number; date: any }>;
}

export type InfluencerYoutubeAnalyticsPostQueryVariables = Exact<{
  socialAccountId: Scalars['Int']['input'];
  youtubeAnalyticsPostId: Scalars['Int']['input'];
}>;

export interface InfluencerYoutubeAnalyticsPostQuery {
  influencerYoutubeAnalyticsPost: {
    averageViewDuration?: any | null;
    averageViewPercentage?: number | null;
    comments: any;
    dislikes: any;
    id: number;
    likes: any;
    postDate: any;
    subscribers: number;
    videoCaption: string;
    videoDuration: number;
    videoEmbed: string;
    videoId: string;
    videoTags: Array<string>;
    videoThumbnail?: string | null;
    videoTitle: string;
    views: any;
    videoComments: Array<{ content: string; id: string; username: string }>;
  };
}

export type InfluencerYoutubeAnalyticsPostsQueryVariables = Exact<{
  socialAccountId: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  orderBy: YoutubeAnalyticsPostsSortInput;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
}>;

export interface InfluencerYoutubeAnalyticsPostsQuery {
  influencerYoutubeAnalyticsPosts: {
    posts: Array<{
      averageViewDuration?: any | null;
      averageViewPercentage?: number | null;
      comments: any;
      dislikes: any;
      id: number;
      likes: any;
      postDate: any;
      shares: any;
      videoDuration: number;
      videoId: string;
      views: any;
      thumbNail?: string | null;
      title: string;
    }>;
  };
}

export type InfluencerYoutubeAnalyticsPostsByDateQueryVariables = Exact<{
  socialAccountId: Scalars['Int']['input'];
  date: Scalars['Date']['input'];
}>;

export interface InfluencerYoutubeAnalyticsPostsByDateQuery {
  influencerYoutubeAnalyticsPostsByDate: {
    posts: Array<{
      averageViewDuration?: any | null;
      averageViewPercentage?: number | null;
      comments: any;
      dislikes: any;
      id: number;
      likes: any;
      postDate: any;
      subscribers: number;
      videoCaption: string;
      videoDuration: number;
      videoEmbed: string;
      videoId: string;
      videoTags: Array<string>;
      videoThumbnail?: string | null;
      videoTitle: string;
      views: any;
      videoComments: Array<{ content: string; id: string; username: string }>;
    }>;
  };
}

export type InfluencerYoutubeAnalyticsPostsCountQueryVariables = Exact<{
  socialAccountId: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
}>;

export interface InfluencerYoutubeAnalyticsPostsCountQuery {
  influencerYoutubeAnalyticsPostsCount: { totalNumber: number };
}

export type InfluencerYoutubeAnalyticsPostsStatisticsQueryVariables = Exact<{
  socialAccountId: Scalars['Int']['input'];
  startDate: Scalars['Date']['input'];
  endDate: Scalars['Date']['input'];
}>;

export interface InfluencerYoutubeAnalyticsPostsStatisticsQuery {
  influencerYoutubeAnalyticsPostsStatistics: {
    averageEngagement: {
      averageComments: { __typename: 'YoutubeStatisticsData'; growth: number; value: number };
      averageDislikes: { __typename: 'YoutubeStatisticsData'; growth: number; value: number };
      averageLikes: { __typename: 'YoutubeStatisticsData'; growth: number; value: number };
      averagePostsPerWeek?: { __typename: 'YoutubeStatisticsData'; growth: number; value: number } | null;
      averageViews: { __typename: 'YoutubeStatisticsData'; growth: number; value: number };
    };
    postHabit: Array<{
      averageComments: number;
      averageLikes: number;
      engagementRate: number;
      hour: number;
      weekday: number;
    }>;
  };
}

export interface YoutubeStatisticsDataFieldsFragment {
  __typename: 'YoutubeStatisticsData';
  growth: number;
  value: number;
}

export type InfluencerYoutubeAnalyticsRelatedPostsQueryVariables = Exact<{
  socialAccountId: Scalars['Int']['input'];
  youtubeAnalyticsPostId: Scalars['Int']['input'];
  tag: Scalars['String']['input'];
}>;

export interface InfluencerYoutubeAnalyticsRelatedPostsQuery {
  influencerYoutubeAnalyticsRelatedPosts: {
    posts: Array<{
      comments: any;
      id: number;
      postDate: any;
      videoCaption: string;
      videoDuration: number;
      videoEmbed: string;
      videoId: string;
      videoThumbnail?: string | null;
      videoTitle: string;
      views: any;
    }>;
  };
}

export type InfluencerYoutubeAnalyticsTagsTopRankingQueryVariables = Exact<{
  socialAccountId: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  orderBy: YoutubeAnalyticsTagRankingSortInput;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
}>;

export interface InfluencerYoutubeAnalyticsTagsTopRankingQuery {
  influencerYoutubeAnalyticsTagsTopRanking: {
    tags: Array<{
      averageComment: number;
      averageDislike: number;
      averageEngagement: number;
      averageLike: number;
      averageShare: number;
      engagementRate: number;
      posts: number;
      tag: string;
      views: any;
    }>;
  };
}

export type InfluencerYoutubeAnalyticsTagsTopRankingCountQueryVariables = Exact<{
  socialAccountId: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
}>;

export interface InfluencerYoutubeAnalyticsTagsTopRankingCountQuery {
  influencerYoutubeAnalyticsTagsTopRankingCount: { totalNumber: number };
}

export type InfluencerYoutubeCompareOverviewQueryVariables = Exact<{
  socialAccountId: Scalars['Int']['input'];
  startDate: Scalars['Date']['input'];
  endDate: Scalars['Date']['input'];
}>;

export interface InfluencerYoutubeCompareOverviewQuery {
  influencerYoutubeCompareOverview: {
    compareAccounts: Array<{
      id: number;
      graph: {
        comments: Array<{ __typename: 'YoutubeAnalyticsHistoryDataPoint'; count: number; date: any }>;
        dislikes: Array<{ __typename: 'YoutubeAnalyticsHistoryDataPoint'; count: number; date: any }>;
        likes: Array<{ __typename: 'YoutubeAnalyticsHistoryDataPoint'; count: number; date: any }>;
        posts: Array<{ __typename: 'YoutubeAnalyticsHistoryDataPoint'; count: number; date: any }>;
        subscribers: Array<{ __typename: 'YoutubeAnalyticsHistoryDataPoint'; count: number; date: any }>;
        views: Array<{ __typename: 'YoutubeAnalyticsHistoryDataPoint'; count: number; date: any }>;
      };
      summary: {
        averageComments: {
          __typename: 'YoutubeCompareOverviewSummaryData';
          growth: number;
          total: number;
          rate: number;
        };
        averageLikes: { __typename: 'YoutubeCompareOverviewSummaryData'; growth: number; total: number; rate: number };
        averageViews: { __typename: 'YoutubeCompareOverviewSummaryData'; growth: number; total: number; rate: number };
        maximumLikes: { __typename: 'YoutubeCompareOverviewSummaryData'; growth: number; total: number; rate: number };
        maximumViews: { __typename: 'YoutubeCompareOverviewSummaryData'; growth: number; total: number; rate: number };
        totalPosts: { __typename: 'YoutubeCompareOverviewSummaryData'; growth: number; total: number; rate: number };
        totalSubscribers: {
          __typename: 'YoutubeCompareOverviewSummaryData';
          growth: number;
          total: number;
          rate: number;
        };
      };
    }>;
    mainAccount: {
      id: number;
      graph: {
        comments: Array<{ __typename: 'YoutubeAnalyticsHistoryDataPoint'; count: number; date: any }>;
        dislikes: Array<{ __typename: 'YoutubeAnalyticsHistoryDataPoint'; count: number; date: any }>;
        likes: Array<{ __typename: 'YoutubeAnalyticsHistoryDataPoint'; count: number; date: any }>;
        posts: Array<{ __typename: 'YoutubeAnalyticsHistoryDataPoint'; count: number; date: any }>;
        subscribers: Array<{ __typename: 'YoutubeAnalyticsHistoryDataPoint'; count: number; date: any }>;
        views: Array<{ __typename: 'YoutubeAnalyticsHistoryDataPoint'; count: number; date: any }>;
      };
      summary: {
        averageComments: {
          __typename: 'YoutubeCompareOverviewSummaryData';
          growth: number;
          total: number;
          rate: number;
        };
        averageLikes: { __typename: 'YoutubeCompareOverviewSummaryData'; growth: number; total: number; rate: number };
        averageViews: { __typename: 'YoutubeCompareOverviewSummaryData'; growth: number; total: number; rate: number };
        maximumLikes: { __typename: 'YoutubeCompareOverviewSummaryData'; growth: number; total: number; rate: number };
        maximumViews: { __typename: 'YoutubeCompareOverviewSummaryData'; growth: number; total: number; rate: number };
        totalPosts: { __typename: 'YoutubeCompareOverviewSummaryData'; growth: number; total: number; rate: number };
        totalSubscribers: {
          __typename: 'YoutubeCompareOverviewSummaryData';
          growth: number;
          total: number;
          rate: number;
        };
      };
    };
  };
}

export interface YoutubeAnalyticsHistoryDataPointFieldsFragment {
  __typename: 'YoutubeAnalyticsHistoryDataPoint';
  count: number;
  date: any;
}

export interface YoutubeCompareOverviewSummaryDataFieldsFragment {
  __typename: 'YoutubeCompareOverviewSummaryData';
  growth: number;
  total: number;
  rate: number;
}

export type InfluencerYoutubeComparePostByIdQueryVariables = Exact<{
  postId: Scalars['Int']['input'];
  socialAccountId: Scalars['Int']['input'];
  compareAccountId: Scalars['Int']['input'];
}>;

export interface InfluencerYoutubeComparePostByIdQuery {
  influencerYoutubeComparePostById?: {
    comments: number;
    dislikes: number;
    id: number;
    likes: number;
    postDate: any;
    subscribers: number;
    videoCaption: string;
    videoDuration: number;
    videoEmbed: string;
    videoId: string;
    videoTags: Array<string>;
    videoThumbnail?: string | null;
    videoTitle: string;
    views: number;
    videoComments: Array<{ content: string; id: string; username: string }>;
  } | null;
}

export type InfluencerYoutubeComparePostListQueryVariables = Exact<{
  socialAccountId: Scalars['Int']['input'];
  compareAccountId: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  orderBy: YoutubeAnalyticsComparePostsSort;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
}>;

export interface InfluencerYoutubeComparePostListQuery {
  influencerYoutubeComparePostList: {
    accountId: number;
    posts: Array<{
      comments: number;
      dislikes: number;
      duration: number;
      id: number;
      likes: number;
      postDate: any;
      subscribers: number;
      thumbnail?: string | null;
      title: string;
      url: string;
      views: number;
    }>;
  };
}

export type InfluencerYoutubeComparePostsQueryVariables = Exact<{
  socialAccountId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
}>;

export interface InfluencerYoutubeComparePostsQuery {
  influencerYoutubeComparePosts: {
    compareAccounts: Array<{
      accountId: number;
      postHabit: Array<{
        averageComments: number;
        averageLikes: number;
        engagementRate: number;
        hour: number;
        weekday: number;
      }>;
      posts: Array<{
        comments: number;
        duration: number;
        id: number;
        likes: number;
        thumbnail?: string | null;
        title: string;
        views: number;
      }>;
    }>;
    mainAccount: {
      socialAccountId: number;
      postHabit: Array<{
        averageComments: number;
        averageLikes: number;
        engagementRate: number;
        hour: number;
        weekday: number;
      }>;
      posts: Array<{
        comments: number;
        duration: number;
        id: number;
        likes: number;
        thumbnail?: string | null;
        title: string;
        views: number;
      }>;
    };
  };
}

export type InfluencerYoutubeComparePostsCountQueryVariables = Exact<{
  socialAccountId: Scalars['Int']['input'];
  compareAccountId: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
}>;

export interface InfluencerYoutubeComparePostsCountQuery {
  influencerYoutubeComparePostsCount: { totalNumber: number };
}

export type InfluencerYoutubeCompareTagRankingQueryVariables = Exact<{
  socialAccountId: Scalars['Int']['input'];
  startDate: Scalars['Date']['input'];
  endDate: Scalars['Date']['input'];
}>;

export interface InfluencerYoutubeCompareTagRankingQuery {
  influencerYoutubeCompareTagRanking: {
    compareAccounts: Array<{ accountId: number; tagsRanking: Array<{ rank: number; tag: string; views: number }> }>;
    mainAccount: { socialAccountId: number; tagsRanking: Array<{ rank: number; tag: string; views: number }> };
  };
}

export type IsInfluencerUuumQueryVariables = Exact<{ [key: string]: never }>;

export interface IsInfluencerUuumQuery {
  isInfluencerUuum?: { isUuum: boolean } | null;
}

export type MarketplaceAffiliateReportForInfluencerQueryVariables = Exact<{
  campaignId: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
}>;

export interface MarketplaceAffiliateReportForInfluencerQuery {
  marketplaceAffiliateReportForInfluencer?: Array<{
    date: any;
    conversions: number;
    commission: number;
    clicks: number;
  }> | null;
  marketplaceAffiliateReportCountForInfluencer?: { totalNumber: number } | null;
}

export type MarketplaceAffiliateReportSummaryForInfluencerQueryVariables = Exact<{
  campaignId: Scalars['Int']['input'];
}>;

export interface MarketplaceAffiliateReportSummaryForInfluencerQuery {
  marketplaceAffiliateReportSummaryForInfluencer?: {
    conversions: number;
    clicks: number;
    commission: number;
    currency: string;
  } | null;
}

export type MarketplaceCampaignPostReportForInfluencerQueryVariables = Exact<{
  campaignId: Scalars['Int']['input'];
}>;

export interface MarketplaceCampaignPostReportForInfluencerQuery {
  marketplaceCampaignPostReportForInfluencer?: {
    currency: string;
    id: number;
    revenue: number;
    title: string;
    postReports: Array<{
      anyXStatus?: AnyXMarketplaceCampaignStatus | null;
      click?: number | null;
      comment: number;
      conversion?: number | null;
      id: number;
      inappropriateDetailedReason?: string | null;
      inappropriateReason?: string | null;
      like: number;
      missingCoupon?: string | null;
      postCaption: string;
      postSocialMedia: CampaignSocialMediaType;
      postUrl: string;
      share: number;
      status: MarketplaceCampaignPostStatus;
      thumbNail?: string | null;
      view: number;
    }>;
  } | null;
}

export type MarketplaceDraftPostsForInfluencerQueryVariables = Exact<{
  marketplaceId: Scalars['Int']['input'];
}>;

export interface MarketplaceDraftPostsForInfluencerQuery {
  marketplaceDraftPostsForInfluencer?: Array<{
    content: string;
    materials: Array<string>;
    rejectedMessage?: string | null;
    status: MarketplaceCampaignDraftPostStatus;
  }> | null;
}

export type MarketplaceForInfluencerQueryVariables = Exact<{
  pk: Scalars['Int']['input'];
}>;

export interface MarketplaceForInfluencerQuery {
  marketplaceForInfluencer?: {
    ableToJoin?: boolean | null;
    additionalRequirement?: string | null;
    anyXStatus?: AnyXMarketplaceCampaignStatus | null;
    appliedStatus?: MarketplaceCampaignAppliedStatus | null;
    campaignDetailType: MarketplaceCampaignDetailType;
    campaignType: MarketplaceCampaignType;
    clickUrl?: string | null;
    commissionRate?: number | null;
    confirmMadePostAvailability?: boolean | null;
    couponCode?: string | null;
    createdDate: any;
    currency: string;
    endDate?: any | null;
    hasReport: boolean;
    hashtags: Array<string>;
    id: number;
    isApplied: boolean;
    isAutoInfluencerApproval?: boolean | null;
    isCouponCampaign?: boolean | null;
    isGiftingCampaign?: boolean | null;
    isJoined: boolean;
    isPostTracking?: boolean | null;
    isSelectionCampaign?: boolean | null;
    isWaiting: boolean;
    materialUrl?: string | null;
    maxFollowers: number;
    maximumRevenuePerInfluencer?: number | null;
    minFollowers: number;
    minimumPaymentAmount: number;
    postStatus?: CampaignPostStatusForInfluencer | null;
    postTrackingUrl?: string | null;
    postUrl?: string | null;
    preLaunchDate?: any | null;
    remainingAmount: number;
    requireDraftPost: boolean;
    requirement: string;
    revenuePerAction?: number | null;
    revenuePerClick?: number | null;
    revenuePerComment?: number | null;
    revenuePerFollower?: number | null;
    revenuePerLike?: number | null;
    revenuePerOrder?: number | null;
    revenuePerPost?: number | null;
    revenuePerShare?: number | null;
    revenuePerView?: number | null;
    sampleUrl?: string | null;
    serviceInformation: string;
    socialMedias: Array<CampaignSocialMediaType>;
    startDate: any;
    status: CampaignStatusForInfluencer;
    thumbNail: string;
    thumbnails: Array<string>;
    title: string;
    totalAmount: number;
    trackingOptions?: Array<CampaignTrackingOption> | null;
    trackingUrl?: string | null;
    type: CampaignType;
    warningReason?: WarningReason | null;
    materials?: Array<{ id: number; url: string }> | null;
  } | null;
}

export type MarketplaceJoinableAccountsQueryVariables = Exact<{
  campaignId: Scalars['Int']['input'];
}>;

export interface MarketplaceJoinableAccountsQuery {
  marketplaceJoinableAccounts?: {
    facebookAccount?: {
      activeToken: boolean;
      avatar?: string | null;
      disabled: boolean;
      followersCount: number;
      id: number;
      userId?: string | null;
      name: string;
    } | null;
    facebookPages: Array<{
      __typename: 'JoinableAccount';
      activeToken: boolean;
      avatar?: string | null;
      disabled: boolean;
      followersCount: number;
      id: number;
      name: string;
    }>;
    instagramAccounts: Array<{
      activeToken: boolean;
      avatar?: string | null;
      disabled: boolean;
      followersCount: number;
      id: number;
      isBusinessAccount: boolean;
      name: string;
    }>;
    tiktokAccounts: Array<{
      __typename: 'JoinableAccount';
      activeToken: boolean;
      avatar?: string | null;
      disabled: boolean;
      followersCount: number;
      id: number;
      name: string;
    }>;
    twitterAccounts: Array<{
      __typename: 'JoinableAccount';
      activeToken: boolean;
      avatar?: string | null;
      disabled: boolean;
      followersCount: number;
      id: number;
      name: string;
    }>;
    youtubeAccounts: Array<{
      __typename: 'JoinableAccount';
      activeToken: boolean;
      avatar?: string | null;
      disabled: boolean;
      followersCount: number;
      id: number;
      name: string;
    }>;
  } | null;
}

export interface JoinableAccountInfoFragment {
  __typename: 'JoinableAccount';
  activeToken: boolean;
  avatar?: string | null;
  disabled: boolean;
  followersCount: number;
  id: number;
  name: string;
}

export type MarketplaceJoinedAccountQueryVariables = Exact<{
  campaignId: Scalars['Int']['input'];
}>;

export interface MarketplaceJoinedAccountQuery {
  marketplaceJoinedAccount?: {
    avatar?: string | null;
    followersCount: number;
    profileUrl: string;
    socialAccountType: SocialAccountType;
    name: string;
  } | null;
}

export type MarketplaceJoinedMethodsV2QueryVariables = Exact<{
  input: MarketplaceJoinedMethodsInput;
}>;

export interface MarketplaceJoinedMethodsV2Query {
  marketplaceJoinedMethodsV2?: { methods: Array<CampaignPromotionMethod> } | null;
}

export type MarketplacePostQueryVariables = Exact<{
  pk: Scalars['Int']['input'];
}>;

export interface MarketplacePostQuery {
  marketplacePost?: {
    caption: string;
    comments?: number | null;
    detailedReason?: string | null;
    id?: number | null;
    images?: Array<string> | null;
    likes?: number | null;
    postUrl?: string | null;
    postedDate?: any | null;
    reason?: string | null;
    shares?: number | null;
    socialMedia: CampaignSocialMediaType;
    status?: MarketplaceCampaignPostStatus | null;
    views?: number | null;
    warningReason?: WarningReason | null;
    postDetailInfluencer: { avatar: string; followersCount: number; id: number; name: string };
  } | null;
}

export type MarketplacePostCommentsQueryVariables = Exact<{
  postId: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
}>;

export interface MarketplacePostCommentsQuery {
  marketplacePostComments?: Array<{
    commentedDate?: any | null;
    content?: string | null;
    username?: string | null;
  }> | null;
}

export type NeedReconnectForFacebookQueryVariables = Exact<{ [key: string]: never }>;

export interface NeedReconnectForFacebookQuery {
  needReconnectForFacebook?: { need: boolean } | null;
}

export type PayableCampaignQueryVariables = Exact<{ [key: string]: never }>;

export interface PayableCampaignQuery {
  payableCampaign?: Array<{
    campaignType: CampaignType;
    currency: string;
    id: number;
    revenue: number;
    thumbNail: string;
    title: string;
  }> | null;
}

export type PaymentRequestQueryVariables = Exact<{ [key: string]: never }>;

export interface PaymentRequestQuery {
  paymentRequest?: { currency: string; fee: number; grossRevenue: number; minimumPaymentAmount: number } | null;
}

export type ShopifyAuthRedirectUrlQueryVariables = Exact<{
  input: ShopifyAuthRedirectUrlInput;
}>;

export interface ShopifyAuthRedirectUrlQuery {
  shopifyAuthRedirectUrl?: { redirectUri: string } | null;
}

export type SocialAuthRedirectUrlForInfluencerQueryVariables = Exact<{
  input: SocialAuthRedirectUrlForInfluencerInput;
}>;

export interface SocialAuthRedirectUrlForInfluencerQuery {
  socialAuthRedirectUrlForInfluencer?: { redirectUri: string } | null;
}

export type SponsoredPostsQueryVariables = Exact<{
  influencerId: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<SponsoredPostOrderBy>;
  socialMedia: SocialAccountType;
  socialAccountId: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;

export interface SponsoredPostsQuery {
  sponsoredPosts?: {
    averageEngagement?: number | null;
    averageEngagementRate?: number | null;
    averageReach?: number | null;
    averageSaved?: number | null;
    posts: Array<{
      campaignType?: CampaignType | null;
      comments: number;
      content?: string | null;
      id: number;
      likes: number;
      postUrl: string;
      postedDate: any;
      reaches?: number | null;
      saved?: number | null;
      shares?: number | null;
      socialMedia: SocialPostType;
      thumbNail?: string | null;
      views?: number | null;
    }>;
  } | null;
}

export type TiktokProfileAudienceQueryVariables = Exact<{
  pk: Scalars['Int']['input'];
  socialAccountId: Scalars['Int']['input'];
}>;

export interface TiktokProfileAudienceQuery {
  tiktokProfileAudience?: {
    femaleRate?: number | null;
    maleRate?: number | null;
    ageRates: { rate?: Array<number> | null; ageGroup?: Array<{ end: number; start: number }> | null };
    countryRates?: Array<{ countryId: string; name: string; rate: number }> | null;
  } | null;
}

export type TiktokSpecialCampaignForInfluencerSearchJobQueryVariables = Exact<{
  pk: Scalars['Int']['input'];
}>;

export interface TiktokSpecialCampaignForInfluencerSearchJobQuery {
  tiktokSpecialCampaignForInfluencerSearchJob?: {
    currency: string;
    endDate: any;
    hashtags: Array<string>;
    id: number;
    isJoined: boolean;
    materialUrl?: string | null;
    minimumPaymentAmount: number;
    preLaunchDate?: any | null;
    productUrl?: string | null;
    requirement?: string | null;
    revenuePerPost: number;
    sampleUrl?: string | null;
    serviceInformation?: string | null;
    socialMedias: Array<CampaignSocialMediaType>;
    startDate: any;
    status: CampaignStatusForInfluencer;
    thumbnails: Array<string>;
    title: string;
    materials?: Array<{ id: number; url: string }> | null;
  } | null;
}

export type TiktokSpecialCampaignForInfluencerYourJobQueryVariables = Exact<{
  pk: Scalars['Int']['input'];
}>;

export interface TiktokSpecialCampaignForInfluencerYourJobQuery {
  tiktokSpecialCampaignForInfluencerYourJob?: {
    currency: string;
    endDate: any;
    hasReport: boolean;
    hashtags: Array<string>;
    id: number;
    isJoined: boolean;
    materialUrl?: string | null;
    preLaunchDate?: any | null;
    productUrl?: string | null;
    requirement?: string | null;
    revenuePerPost: number;
    sampleUrl?: string | null;
    serviceInformation?: string | null;
    socialMedias: Array<CampaignSocialMediaType>;
    startDate: any;
    status: CampaignStatusForInfluencer;
    thumbnails: Array<string>;
    title: string;
    materials?: Array<{ id: number; url: string }> | null;
  } | null;
}

export type TiktokSpecialCampaignPostReportForInfluencerQueryVariables = Exact<{
  campaignId: Scalars['Int']['input'];
}>;

export interface TiktokSpecialCampaignPostReportForInfluencerQuery {
  tiktokSpecialCampaignPostReportForInfluencer?: {
    currency: string;
    id: number;
    revenue: number;
    title: string;
    postReports: Array<{
      click?: number | null;
      comment: number;
      conversion?: number | null;
      id: number;
      like: number;
      postCaption: string;
      postSocialMedia: CampaignSocialMediaType;
      postUrl: string;
      share: number;
      status: TikTokSpecialCampaignPostStatus;
      thumbNail?: string | null;
      view: number;
    }>;
  } | null;
}

export type TiktokSpecialJoinableAccountsQueryVariables = Exact<{
  campaignId: Scalars['Int']['input'];
}>;

export interface TiktokSpecialJoinableAccountsQuery {
  tiktokSpecialJoinableAccounts?: {
    tiktokAccounts: Array<{
      activeToken: boolean;
      avatar?: string | null;
      disabled: boolean;
      followersCount: number;
      id: number;
      name: string;
    }>;
  } | null;
}

export type TiktokSpecialJoinedAccountQueryVariables = Exact<{
  campaignId: Scalars['Int']['input'];
}>;

export interface TiktokSpecialJoinedAccountQuery {
  tiktokSpecialJoinedAccount?: {
    avatar?: string | null;
    followersCount: number;
    profileUrl: string;
    socialAccountType: SocialAccountType;
    name: string;
  } | null;
}

export type WordCloudListQueryVariables = Exact<{
  influencerId: Scalars['Int']['input'];
  socialMedia: SocialAccountType;
  socialAccountId: Scalars['Int']['input'];
}>;

export interface WordCloudListQuery {
  wordCloudList?: Array<{ count: number; hashtag: string }> | null;
}

export type YoutubeCmsVideoStatsQueryVariables = Exact<{
  videoId: Scalars['String']['input'];
  month: Scalars['Date']['input'];
}>;

export interface YoutubeCmsVideoStatsQuery {
  youtubeCmsVideoStats?: {
    assetTitle?: string | null;
    avgPercentageView: number;
    avgViewDuration: number;
    id: string;
    videoThumbnail: string;
    videoTitle?: string | null;
    views: number;
  } | null;
}

export type YoutubeCmsChannelsForInfluencerQueryVariables = Exact<{
  month?: InputMaybe<Scalars['Date']['input']>;
}>;

export interface YoutubeCmsChannelsForInfluencerQuery {
  youtubeCmsChannelsForInfluencer: Array<{
    currency: string;
    id: string;
    name?: string | null;
    revenue: number;
    rpm?: number | null;
    shareRate: number;
    thumbnail?: string | null;
  }>;
}

export type YoutubeCmsConfirmedChannelsForInfluencerQueryVariables = Exact<{
  month: Scalars['Date']['input'];
}>;

export interface YoutubeCmsConfirmedChannelsForInfluencerQuery {
  youtubeCmsConfirmedChannelsForInfluencer: Array<{
    currency: string;
    id: string;
    name?: string | null;
    revenue: number;
    rpm?: number | null;
    shareRate: number;
    thumbnail?: string | null;
  }>;
}

export type YoutubeCmsConfirmedMusicsForInfluencerQueryVariables = Exact<{
  month: Scalars['Date']['input'];
}>;

export interface YoutubeCmsConfirmedMusicsForInfluencerQuery {
  youtubeCmsConfirmedMusicsForInfluencer: Array<{
    currency: string;
    id: string;
    name?: string | null;
    revenue: number;
    rpm?: number | null;
    shareRate: number;
    thumbnail?: string | null;
  }>;
}

export type YoutubeCmsConfirmedRevenueForInfluencerQueryVariables = Exact<{
  month?: InputMaybe<Scalars['Date']['input']>;
}>;

export interface YoutubeCmsConfirmedRevenueForInfluencerQuery {
  youtubeCmsConfirmedRevenueForInfluencer?: {
    revenue?: { currency: string; isOwner: boolean; rpm?: number | null; total: number } | null;
  } | null;
}

export type YoutubeCmsConfirmedVideosForInfluencerQueryVariables = Exact<{
  month: Scalars['Date']['input'];
}>;

export interface YoutubeCmsConfirmedVideosForInfluencerQuery {
  youtubeCmsConfirmedVideosForInfluencer: Array<{
    currency: string;
    id: string;
    name?: string | null;
    revenue: number;
    rpm?: number | null;
    shareRate: number;
    thumbnail?: string | null;
  }>;
}

export type YoutubeCmsInvoiceListForInfluencerQueryVariables = Exact<{
  year: Scalars['Int']['input'];
}>;

export interface YoutubeCmsInvoiceListForInfluencerQuery {
  youtubeCmsInvoiceListForInfluencer?: Array<{ id: number; month: any; updatedDate: any } | null> | null;
}

export type YoutubeCmsMusicsForInfluencerQueryVariables = Exact<{
  maxDate?: InputMaybe<Scalars['Date']['input']>;
  minDate?: InputMaybe<Scalars['Date']['input']>;
  month?: InputMaybe<Scalars['Date']['input']>;
}>;

export interface YoutubeCmsMusicsForInfluencerQuery {
  youtubeCmsMusicsForInfluencer: Array<{
    currency: string;
    id: string;
    name?: string | null;
    revenue: number;
    rpm?: number | null;
    shareRate: number;
  }>;
}

export type YoutubeCmsRevenueForInfluencerQueryVariables = Exact<{
  maxDate?: InputMaybe<Scalars['Date']['input']>;
  minDate?: InputMaybe<Scalars['Date']['input']>;
  month?: InputMaybe<Scalars['Date']['input']>;
}>;

export interface YoutubeCmsRevenueForInfluencerQuery {
  youtubeCmsRevenueForInfluencer?: {
    revenue?: { currency: string; isOwner: boolean; rpm?: number | null; total: number } | null;
  } | null;
}

export type YoutubeCmsStartMonthForInfluencerQueryVariables = Exact<{ [key: string]: never }>;

export interface YoutubeCmsStartMonthForInfluencerQuery {
  youtubeCmsStartMonthForInfluencer?: { month?: any | null } | null;
}

export type YoutubeCmsVideoAudienceQueryVariables = Exact<{
  videoId: Scalars['String']['input'];
  month: Scalars['Date']['input'];
}>;

export interface YoutubeCmsVideoAudienceQuery {
  youtubeCmsVideoAudience?: {
    ageGenderRates: {
      femaleRate: Array<number>;
      maleRate: Array<number>;
      otherRate: Array<number>;
      ageGroup: Array<{ end: number; start: number }>;
    };
    countryRates: Array<{ id: string; rate: number }>;
    genderRates: { femaleRate: number; maleRate: number; otherRate: number };
  } | null;
}

export type YoutubeCmsVideosForInfluencerQueryVariables = Exact<{
  month?: InputMaybe<Scalars['Date']['input']>;
}>;

export interface YoutubeCmsVideosForInfluencerQuery {
  youtubeCmsVideosForInfluencer: Array<{
    currency: string;
    id: string;
    revenue: number;
    rpm?: number | null;
    shareRate: number;
    thumbnail?: string | null;
    title?: string | null;
  }>;
}

export type YoutubeMusicCategoriesQueryVariables = Exact<{ [key: string]: never }>;

export interface YoutubeMusicCategoriesQuery {
  youtubeMusicCategories: Array<{ category: string; id: number }>;
}

export type YoutubeMusicListQueryVariables = Exact<{
  categoryId?: InputMaybe<Scalars['Int']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
}>;

export interface YoutubeMusicListQuery {
  youtubeMusicList: Array<{
    downloadUrl: string;
    duration?: number | null;
    id: number;
    thumbnailUrl?: string | null;
    title: string;
  }>;
}
