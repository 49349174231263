import React, { useEffect, useState, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, Navigate } from 'react-router-dom';
import { sendAmplitudeEvent } from '@src/amplitude';
import Layout, { LayoutProps } from '@src/components/organisms/Layout';
import { DYNAMIC_TITLE } from '@src/components/organisms/LayoutV2';
import { checkSignedIn } from '@src/libs/auth';
import { useGaTracker } from '@src/libs/withTracker';
import { useDocumentTitle, useGlobalLayout } from '@src/libs/hooks';
import { InitialLoading } from '@src/components/atoms';
import { useUuumInfluencerView } from '@src/libs/hooks';
import { redirectState, useRecoil } from '@src/recoilAtoms';
import { ROUTES } from '@src/shared/routes';
import { useIsInfluencerUuumQuery } from '@src/graphql/hooks';

export type AppRouteProps = LayoutProps & {
  amplitudeEvent?: string;
  documentTitle?: string;
  element: ReactElement;
};

const AppRoute = ({
  amplitudeEvent,
  backPath,
  documentTitle,
  element,
  hideFooter,
  toolbarTitle,
  skipBottomPadding,
}: AppRouteProps) => {
  const location = useLocation();
  const { i18n } = useTranslation();
  const isSignedIn = checkSignedIn();
  const { isUuumView } = useUuumInfluencerView();
  // we will show initial loading screen if we need to call IS_INFLUENCER_UUUM, but for iframe (where isUuumView == true) we don't need to do it
  const [loading, setLoading] = useState(!isUuumView);
  const { setGlobalLayout } = useGlobalLayout();
  const { setRecoilState } = useRecoil(redirectState);
  useDocumentTitle({ title: (toolbarTitle !== DYNAMIC_TITLE && (documentTitle || toolbarTitle)) || '' });

  useGaTracker(isSignedIn);

  useIsInfluencerUuumQuery({
    // for Iframe users isUuumView always true which means we don't need to check it is UUUM influencer
    skip: !isSignedIn || isUuumView,
    onCompleted: ({ isInfluencerUuum }) => {
      setGlobalLayout({ isUuum: !!isInfluencerUuum?.isUuum });
      setLoading(false);
      if (!!isInfluencerUuum?.isUuum) {
        i18n.changeLanguage('ja');
      }
    },
    onError: () => {
      setLoading(false);
    },
  });

  useEffect(() => {
    if (amplitudeEvent) {
      sendAmplitudeEvent(amplitudeEvent);
    }
  }, [amplitudeEvent]);

  useEffect(() => {
    if (!isSignedIn) {
      setRecoilState({ accessUrlBeforeSignIn: location.pathname });
    }
  }, [isSignedIn, location.pathname]);

  if (!isSignedIn) {
    return <Navigate state={{ from: location }} to={ROUTES.ROOT} />;
  }
  if (loading) {
    return <InitialLoading />;
  }

  return (
    <Layout
      backPath={backPath}
      hideFooter={hideFooter}
      toolbarTitle={toolbarTitle}
      skipBottomPadding={skipBottomPadding}
    >
      {element}
    </Layout>
  );
};

export default AppRoute;
