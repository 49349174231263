import { atom } from 'recoil';
import { FormInformation } from '@src/components/shared/FormsForm/Form';
import { FormStatus, QuestionType } from '@src/__generated__/globalTypes';

export const formState = atom<FormInformation | null>({
  key: 'formState',
  default: null,
});

export const submitFormState = atom<{
  description: string;
  hash: string;
  id: string;
  questions: {
    id: string;
    image: string | null;
    isRequired: boolean;
    options: {
      id: string;
      optionTitle: string;
      order: number;
    }[];
    order: number;
    questionType: QuestionType;
    title: string;
  }[];
  status: FormStatus;
  thankDescription: string;
  thankTitle: string;
  title: string;
} | null>({
  key: 'submitFormState',
  default: null,
});
