import React from 'react';
import { Navigate } from 'react-router-dom';
import { css } from '@emotion/react';
import FanOrders from '@src/components/shared/FanOrders';
import FanActivity from '@src/components/shared/FanActivity';
import { useAuthData, usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import { ROUTES } from '@src/shared/routes';
import FanDetails from './FanDetails';
import FanDetailsHeader from './FanDetails/FanDetailsHeader';
import FanForms from './FanForms';
import FormsResponse from './FanForms/FormsResponse';
import FanNavigation from './FanNavigation';

const FanDetailsPage = () => {
  const { limitedLogin } = useAuthData();
  if (limitedLogin) {
    return <Navigate to={ROUTES.MY_PAGE} />;
  }

  const {
    matchPath,
    params: { id },
    pathname,
  } = useQueryHelper();

  const { isMobileView } = usePageLayout();
  const showMobileTabs = isMobileView && matchPath(ROUTES.MY_PAGE_FANS_EDIT, pathname);
  const showFanNavigation = !isMobileView || showMobileTabs;

  const renderContent = () => {
    if (matchPath(ROUTES.MY_PAGE_FANS_EDIT_ACTIVITY, pathname)) {
      return <FanActivity />;
    } else if (matchPath(ROUTES.MY_PAGE_FANS_EDIT_ORDERS, pathname)) {
      return <FanOrders />;
    } else if (matchPath(ROUTES.MY_PAGE_FANS_EDIT_FORMS_SUBMISSION_ID, pathname)) {
      return <FormsResponse />;
    } else if (matchPath(ROUTES.MY_PAGE_FANS_EDIT_FORMS, pathname)) {
      return <FanForms />;
    } else {
      // works for both /fans/:id and /fans/:id/details
      return <FanDetails />;
    }
  };

  return (
    <div css={styles.container}>
      {showFanNavigation && <FanDetailsHeader id={id as string} />}
      <div css={[styles.mainContent, isMobileView && styles.mobileContent]}>
        {showFanNavigation && <FanNavigation />}
        {renderContent()}
      </div>
    </div>
  );
};

const styles = {
  container: css({
    padding: 16,

    [`media (min-width: ${ViewportType.TABLET}px)`]: {
      padding: '16px 24px',
    },
  }),
  mainContent: css({
    background: THEME.colors.white,
    boxShadow: THEME.box.shadows.outer,
    borderRadius: 5,
  }),
  mobileContent: css({
    boxShadow: 'none',
    backgroundColor: 'transparent',
  }),
};

export default FanDetailsPage;
