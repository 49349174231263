import isEqual from 'lodash/isEqual';
import React, { useState, SyntheticEvent } from 'react';
import { useFormContext } from 'react-hook-form';
import { css } from '@emotion/react';
import { BroadcastStatus } from '@src/components/atoms';
import { BackNavigator } from '@src/components/molecules';
import Dialog, { blackCircleCloseStyles, smallButtonStyles } from '@src/components/molecules/Dialog';
import { useQueryHelper } from '@src/libs/hooks';
import { THEME } from '@src/libs/theme';
import { ROUTES } from '@src/shared/routes';
import FormInput, { FormInformation, FormType } from './FormInput';
import FormPreview from './FormPreview';

export type { FormInformation };
export { FormType };

interface FormProps {
  isDisabled?: boolean;
  isEdit?: boolean;
  onSubmit: (information: FormInformation) => Promise<void>;
}

const Form = ({ isDisabled, isEdit, onSubmit }: FormProps) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const { navigate, t } = useQueryHelper();
  const {
    formState: { defaultValues },
    handleSubmit,
    setValue,
    watch,
  } = useFormContext<FormInformation>();
  const values = watch();
  const { formType, status } = values;

  const subBreadcrumbs = (() => {
    switch (formType) {
      case FormType.BUTTON_TYPE:
        return [{ title: 'Button Type' }];
      case FormType.BUTTON_TYPE_CONTENT:
        return [{ title: 'Button Type', onClick: () => setValue('formType', FormType.BUTTON_TYPE) }, { title: 'Card' }];
      case FormType.IMAGE_TYPE:
        return [{ title: 'Image Type' }];
      case FormType.IMAGE_TYPE_CONTENT:
        return [{ title: 'Image Type', onClick: () => setValue('formType', FormType.IMAGE_TYPE) }, { title: 'Card' }];
      case FormType.IMAGE_VIDEO_TYPE:
        return [{ title: 'Image / Video' }];
      case FormType.PLAIN_TEXT:
        return [{ title: 'Plain Text' }];
      case FormType.RICH_MESSAGE:
        return [{ title: 'Rich Message' }];
      default:
        return [];
    }
  })();

  const onCancel = () => {
    // to remove dialog lock scroll
    setDialogOpen(false);
    // redirect after dialog closed 0.1s
    setTimeout(() => {
      navigate(ROUTES.MY_PAGE_MESSAGE_BROADCAST);
    }, 100);
  };

  const onClickBack = (e: SyntheticEvent<HTMLAnchorElement>) => {
    if (formType !== FormType.DEFAULT) {
      e.stopPropagation();
      e.preventDefault();

      setValue(
        'formType',
        formType === FormType.BUTTON_TYPE_CONTENT
          ? FormType.BUTTON_TYPE
          : formType === FormType.IMAGE_TYPE_CONTENT
          ? FormType.IMAGE_TYPE
          : FormType.DEFAULT
      );
    } else if (!isEqual(defaultValues, values)) {
      e.stopPropagation();
      e.preventDefault();
      setDialogOpen(true);
    }
  };

  const onClickSaveDraft = () => {
    handleSubmit(onSubmit)();
    setDialogOpen(false);
  };

  return (
    <div>
      <Dialog
        closeButtonProps={{
          styles: blackCircleCloseStyles,
        }}
        contentStyles={{ borderRadius: 3, maxWidth: 480 }}
        cancelButtonProps={{
          styles: smallButtonStyles,
          text: 'Leave Page',
          onClick: onCancel,
        }}
        nextButtonProps={{
          styles: smallButtonStyles,
          text: 'Save draft',
          theme: 'blue',
          onClick: onClickSaveDraft,
        }}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      >
        <div css={styles.dialog}>
          <div>{t('Unsaved changes')}</div>
          <div>{t('Annotation.Broadcast Leave Page')}</div>
        </div>
      </Dialog>

      <div css={styles.backNavigatorContainer}>
        <BackNavigator
          breadcrumbs={[
            {
              title: isEdit ? 'Edit Broadcast' : 'Add Broadcast',
              onClick: () => formType !== FormType.DEFAULT && setValue('formType', FormType.DEFAULT),
            },
            ...subBreadcrumbs,
          ]}
          to={ROUTES.MY_PAGE_MESSAGE_BROADCAST}
          onClick={onClickBack}
        />
        {isEdit && status && <BroadcastStatus status={status} />}
      </div>

      <div css={styles.contentContainer}>
        <FormInput isDisabled={isDisabled} onSubmit={onSubmit} />
        <FormPreview />
      </div>
    </div>
  );
};

const styles = {
  backNavigatorContainer: css({
    alignItems: 'center',
    display: 'flex',
    gap: THEME.box.gaps.l,
  }),
  contentContainer: css({
    display: 'flex',
    marginTop: 16,
    gap: 64,
  }),
  dialog: css({
    '& > div:nth-of-type(1)': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.heading,
      fontWeight: 600,
      marginBottom: 24,
    },

    '& > div:nth-of-type(2)': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.normal,
    },
  }),
};

export default Form;
