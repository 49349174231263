import React, { ReactElement } from 'react';
import { createBrowserRouter, Navigate, Outlet, RouterProvider } from 'react-router-dom';
import { eventTypes, sendAmplitudeEvent } from './amplitude';
import { AppRoute, NavigateWithParams, PublicRoute } from './components/organisms/Route';
import AppRouteV2, { DYNAMIC_TITLE } from './components/organisms/Route/AppRouteV2';
import AuthSetUp from './components/organisms/AuthSetUp';
import * as Pages from './pages';
import ScrollToTop from './ScrollToTop';
import { useUuumInfluencerView, usePageLayout } from './libs/hooks';
import { ROUTES } from './shared/routes';

declare const IS_DISABLED_ON_PROD: boolean;

const App = () => (
  <AuthSetUp
    skipAuthPaths={[ROUTES.CAMPAIGN_DETAILS_PUBLIC, ROUTES.CREATOR_ACCESS_PREVIEW]}
    hideLoadingPaths={[ROUTES.LIFF_ID, ROUTES.CAMPAIGN_DETAILS_PUBLIC]}
  >
    <ScrollToTop>
      <Outlet />
    </ScrollToTop>
  </AuthSetUp>
);

const AppRoutes = () => {
  const { isUuumView } = useUuumInfluencerView();
  const { isMobileView } = usePageLayout();
  const fansBackPath = isMobileView ? ROUTES.MY_PAGE_FANS_EDIT : ROUTES.MY_PAGE_FANS;

  const renderWithAmplitudeEvent = (component: ReactElement, event: string) => {
    sendAmplitudeEvent(event);

    return component;
  };

  const routes = [
    {
      path: ROUTES.ROOT,
      element: (
        <PublicRoute
          amplitudeEvent={isUuumView ? eventTypes.viewSignUp : eventTypes.viewSignIn}
          element={isUuumView ? <Pages.UuumSignUp /> : <Pages.Entry />}
        />
      ),
    },
    // Sign Up
    {
      path: ROUTES.SIGN_UP,
      children: [
        {
          index: true,
          element: (
            <PublicRoute
              amplitudeEvent={eventTypes.viewSignUp}
              element={isUuumView ? <Pages.UuumSignUp /> : <Pages.SignUp />}
            />
          ),
        },
        {
          path: ROUTES.SIGN_UP_IG,
          element: <PublicRoute amplitudeEvent={eventTypes.viewSignUpIstagram} element={<Pages.SignUpInstagram />} />,
        },
        {
          path: ROUTES.SIGN_UP_IG_UNABLE_CONNECT,
          element: (
            <PublicRoute
              amplitudeEvent={eventTypes.viewSignUpInstagramUnableConnect}
              element={<Pages.SignUpInstagram />}
            />
          ),
        },
        {
          path: ROUTES.SIGN_UP_TIKTOK_GRANT_PERMISSION,
          element: (
            <PublicRoute
              amplitudeEvent={eventTypes.viewSignUpTiktokGrantPermission}
              element={<Pages.SignUpTiktokGrantPermission />}
            />
          ),
        },
        {
          path: ROUTES.SIGN_UP_FACEBOOK,
          element: <PublicRoute amplitudeEvent={eventTypes.viewSignUpFacebook} element={<Pages.SignUpFacebook />} />,
        },
        {
          path: ROUTES.SIGN_UP_FACEBOOK_GRANT_PERMISSION,
          element: (
            <PublicRoute
              amplitudeEvent={eventTypes.viewSignUpFacebookGrantPermission}
              element={<Pages.SignUpFacebookGrantPermission />}
            />
          ),
        },
        {
          path: ROUTES.SIGN_UP_SUCCESS,
          element: <PublicRoute amplitudeEvent={eventTypes.viewSignUpSuccess} element={<Pages.SignUpSuccess />} />,
        },
        {
          path: ROUTES.SIGN_UP_PROFILE,
          element: <PublicRoute amplitudeEvent={eventTypes.viewSignUpEmail} element={<Pages.SignUpProfile />} />,
        },
      ],
    },
    // Sign In
    {
      path: ROUTES.SIGN_IN,
      children: [
        { index: true, element: <PublicRoute amplitudeEvent={eventTypes.viewSignIn} element={<Pages.SignIn />} /> },
        {
          path: ROUTES.SIGN_IN_IG_UNABLE_CONNECT,
          element: (
            <PublicRoute
              amplitudeEvent={eventTypes.viewSignInInstagramUnableConnect}
              element={<Pages.SignInInstagramUnableConnect />}
            />
          ),
        },
        {
          path: ROUTES.SIGN_IN_TIKTOK_GRANT_PERMISSION,
          element: (
            <PublicRoute
              amplitudeEvent={eventTypes.viewSignInTiktokGrantPermission}
              element={<Pages.SignInTiktokGrantPermission />}
            />
          ),
        },
        {
          path: ROUTES.SIGN_IN_FACEBOOK_GRANT_PERMISSION,
          element: (
            <PublicRoute
              amplitudeEvent={eventTypes.viewSignInFacebookGrantPermission}
              element={<Pages.SignInFacebookGrantPermission />}
            />
          ),
        },
      ],
    },
    // Password
    {
      path: ROUTES.PASSWORD_FORGOT,
      element: renderWithAmplitudeEvent(<Pages.PasswordForgot />, eventTypes.viewPasswordForgot),
    },
    {
      path: ROUTES.PASSWORD_REGISTER_TOKEN,
      element: renderWithAmplitudeEvent(<Pages.PasswordRegister />, eventTypes.viewPasswordRegister),
    },
    {
      path: ROUTES.PASSWORD_RESET_TOKEN,
      element: renderWithAmplitudeEvent(<Pages.PasswordReset />, eventTypes.viewPasswordReset),
    },
    // Redirect
    {
      path: ROUTES.REDIRECT_SIGN_UP_ENABLED_PROVIDER,
      element: <Pages.RedirectSignUpEnabled />,
    },
    {
      path: ROUTES.REDIRECT_TIKTOK_RECONNECT,
      element: <Pages.RedirectTiktokReconnect />,
    },
    {
      path: ROUTES.REDIRECT_TWITTER,
      element: <Pages.RedirectTwitter />,
    },
    {
      path: ROUTES.REDIRECT_SIGN_UP_PROVIDER,
      element: <Pages.RedirectSignUp />,
    },
    {
      path: ROUTES.REDIRECT_SIGN_IN_PROVIDER,
      element: <Pages.RedirectSignIn />,
    },
    {
      path: ROUTES.REDIRECT_CONNECT_PROVIDER,
      element: <Pages.RedirectConnect />,
    },
    {
      path: ROUTES.REDIRECT_ANALYTICS_RECONNECT_PROVIDER,
      element: <Pages.RedirectAnalyticsReconnect />,
    },
    {
      path: ROUTES.REDIRECT_SHOPIFY_CONNECT,
      element: <Pages.RedirectShopifyConnect />,
    },
    {
      path: ROUTES.REDIRECT_SIGN_IN_WITH_LINE_PROVIDER,
      element: <Pages.RedirectLineInstagramSignIn />,
    },
    {
      path: ROUTES.REDIRECT_LINK_IN_BIO_CONNECT,
      element: <Pages.RedirectLinkInBioConnect />,
    },
    {
      path: ROUTES.REDIRECT_MOBILE_SOCIAL_AUTH,
      element: <Pages.RedirectMobileSocialAuth />,
    },
    {
      path: ROUTES.REDIRECT_MOBILE_TIKTOK_CONNECT,
      element: <Pages.RedirectMobileTiktokConnect />,
    },
    {
      path: ROUTES.REDIRECT_TTCM_RECONNECT,
      element: <Pages.RedirectTtcmReconnect />,
    },
    // BE email notification still using old url, handle redirect to new UI to avoid changes from BE
    {
      path: ROUTES.SIGN_IN_STAFF,
      element: <NavigateWithParams replace to={ROUTES.SIGN_IN} />,
    },
    {
      path: ROUTES.INITIAL_ENGAGEMENT_JOB_ID,
      element: <NavigateWithParams replace to={ROUTES.MY_JOBS_CASTING_YOUR_JOB_ID} />,
    },
    {
      path: ROUTES.INITIAL_ENGAGEMENT_JOB_ID_POST,
      element: <NavigateWithParams replace to={ROUTES.MY_JOBS_CASTING_YOUR_JOB_ID_POST} />,
    },
    {
      path: ROUTES.INITIAL_ENGAGEMENT_JOB_ID_REPORT,
      element: <NavigateWithParams replace to={ROUTES.MY_JOBS_CASTING_YOUR_JOB_ID_REPORT} />,
    },
    {
      path: ROUTES.INITIAL_MARKETPLACE_JOB_ID,
      element: <NavigateWithParams replace to={ROUTES.MY_JOBS_MARKETPLACE_ID} />,
    },
    {
      path: ROUTES.INITIAL_MARKETPLACE_SEARCH,
      element: <Navigate replace to={ROUTES.FIND_JOBS} />,
    },
    {
      path: ROUTES.INITIAL_MARKETPLACE_SEARCH_ID,
      element: <NavigateWithParams replace to={ROUTES.FIND_JOBS_MARKETPLACE_ID} />,
    },
    // Youtube CMS Dashboard
    {
      path: ROUTES.YOUTUBE_CMS_REVENUE,
      children: [
        {
          index: true,
          element: (
            <AppRouteV2
              amplitudeEvent={eventTypes.viewYoutubeCmsRevenue}
              documentTitle="Revenue"
              element={<Pages.YoutubeCmsRevenue />}
            />
          ),
        },
        {
          path: ROUTES.YOUTUBE_CMS_REVENUE_VIDEO_VIDEOID,
          element: (
            <AppRouteV2
              amplitudeEvent={eventTypes.viewYoutubeCmsRevenueVideoDetails}
              documentTitle="Video Detail"
              element={<Pages.YoutubeCmsRevenueVideoDetails />}
            />
          ),
        },
      ],
    },
    {
      path: ROUTES.YOUTUBE_CMS_MUSIC,
      element: (
        <AppRouteV2
          amplitudeEvent={eventTypes.viewYoutubeCmsMusic}
          documentTitle="Music"
          element={<Pages.YoutubeCmsMusic />}
          hideToolbar
        />
      ),
    },
    {
      path: ROUTES.YOUTUBE_CMS_MUSIC_CATEGORYID,
      element: (
        <AppRouteV2
          amplitudeEvent={eventTypes.viewYoutubeCmsMusic}
          element={<Pages.YoutubeCmsMusicCategoryId />}
          toolbarProps={{
            back: ROUTES.YOUTUBE_CMS_MUSIC,
            title: DYNAMIC_TITLE,
          }}
        />
      ),
    },
    // TODO: to enable on production when the feature is ready
    ...(!IS_DISABLED_ON_PROD
      ? [
          {
            path: ROUTES.YOUTUBE_CMS_INVOICE,
            element: (
              <AppRouteV2
                amplitudeEvent={eventTypes.viewYoutubeCmsInvoice}
                documentTitle="Invoice"
                element={<Pages.YoutubeCmsInvoice />}
              />
            ),
          },
        ]
      : []),
    {
      path: ROUTES.YOUTUBE_CMS_MY_PAGE,
      children: [
        {
          index: true,
          element: <AppRouteV2 amplitudeEvent={eventTypes.viewMyPage} element={<Pages.MyPage />} hideToolbar />,
        },
        {
          path: ROUTES.YOUTUBE_CMS_MY_PAGE_ACCOUNT,
          children: [
            {
              index: true,
              element: (
                <AppRouteV2
                  amplitudeEvent={eventTypes.viewMyPageAccount}
                  element={<Pages.MyPageAccount />}
                  toolbarProps={{
                    back: ROUTES.YOUTUBE_CMS_MY_PAGE,
                    title: 'Account',
                  }}
                />
              ),
            },
            {
              path: ROUTES.YOUTUBE_CMS_MY_PAGE_ACCOUNT_DELETE,
              element: (
                <AppRouteV2
                  amplitudeEvent={eventTypes.viewMyPageAccountDelete}
                  element={<Pages.MyPageAccountDelete />}
                  toolbarProps={{
                    back: ROUTES.YOUTUBE_CMS_MY_PAGE_ACCOUNT,
                    title: 'Delete Account',
                  }}
                />
              ),
            },
          ],
        },
        {
          path: ROUTES.YOUTUBE_CMS_MY_PAGE_ADDRESS,
          element: (
            <AppRouteV2
              amplitudeEvent={eventTypes.viewMyPageAddress}
              element={<Pages.MyPageAddress />}
              toolbarProps={{
                back: ROUTES.YOUTUBE_CMS_MY_PAGE,
                title: 'Address',
              }}
            />
          ),
        },
        {
          path: ROUTES.YOUTUBE_CMS_MY_PAGE_SOCIAL_CONNECT,
          children: [
            {
              index: true,
              element: (
                <AppRouteV2
                  amplitudeEvent={eventTypes.viewMyPageSocialConnect}
                  element={<Pages.MyPageSocialConnect />}
                  toolbarProps={{
                    back: ROUTES.YOUTUBE_CMS_MY_PAGE,
                    title: 'Social Connect',
                  }}
                />
              ),
            },
            {
              path: ROUTES.YOUTUBE_CMS_MY_PAGE_SOCIAL_CONNECT_INSTAGRAM_CONNECT,
              element: (
                <AppRouteV2
                  amplitudeEvent={eventTypes.viewMyPageSocialConnectInstagram}
                  element={<Pages.MyPageSocialConnectInstagramConnect />}
                  toolbarProps={{
                    back: ROUTES.YOUTUBE_CMS_MY_PAGE_SOCIAL_CONNECT,
                    title: 'Social Connect',
                  }}
                />
              ),
            },
            {
              path: ROUTES.YOUTUBE_CMS_MY_PAGE_SOCIAL_CONNECT_INSTAGRAM_UNABLE_CONNECT,
              element: (
                <AppRouteV2
                  amplitudeEvent={eventTypes.viewMyPageSocialConnectInstagram}
                  documentTitle="Social Connect"
                  element={<Pages.MyPageSocialConnectInstagramConnect />}
                  toolbarProps={{
                    back: ROUTES.YOUTUBE_CMS_MY_PAGE_SOCIAL_CONNECT,
                    title: 'Unable to connect',
                  }}
                />
              ),
            },
            {
              path: ROUTES.YOUTUBE_CMS_MY_PAGE_SOCIAL_CONNECT_FACEBOOK_GRANT_PERMISSION,
              element: (
                <AppRouteV2
                  amplitudeEvent={eventTypes.viewMyPageSocialConnectFacebookGrantPermission}
                  documentTitle="Social Connect"
                  element={<Pages.MyPageSocialConnectFacebookGrantPermission />}
                  toolbarProps={{
                    back: ROUTES.YOUTUBE_CMS_MY_PAGE_SOCIAL_CONNECT,
                    title: 'Grant permission',
                  }}
                />
              ),
            },
            {
              path: ROUTES.YOUTUBE_CMS_MY_PAGE_SOCIAL_CONNECT_TIKTOK_GRANT_PERMISSION,
              element: (
                <AppRouteV2
                  amplitudeEvent={eventTypes.viewMyPageSocialConnectTiktokGrantPermission}
                  documentTitle="Social Connect"
                  element={<Pages.MyPageSocialConnectTiktokGrantPermission />}
                  toolbarProps={{
                    back: ROUTES.YOUTUBE_CMS_MY_PAGE_SOCIAL_CONNECT,
                    title: 'Grant permission',
                  }}
                />
              ),
            },
            {
              path: ROUTES.YOUTUBE_CMS_MY_PAGE_SOCIAL_CONNECT_ADD_METHODS,
              element: (
                <AppRouteV2
                  amplitudeEvent={eventTypes.viewMyPageSocialConnectAddMethods}
                  element={<Pages.MyPageSocialConnectAddMethods />}
                  toolbarProps={{
                    back: ROUTES.YOUTUBE_CMS_MY_PAGE_SOCIAL_CONNECT,
                    title: DYNAMIC_TITLE,
                  }}
                />
              ),
            },
          ],
        },
        {
          path: ROUTES.YOUTUBE_CMS_MY_PAGE_CONTACT,
          element: (
            <AppRouteV2
              amplitudeEvent={eventTypes.viewContact}
              element={<Pages.MyPageContact />}
              toolbarProps={{
                back: ROUTES.YOUTUBE_CMS_MY_PAGE,
                title: 'Contact',
              }}
            />
          ),
        },
      ],
    },
    // Find Jobs
    {
      path: ROUTES.FIND_JOBS,
      children: [
        {
          index: true,
          element: (
            <AppRouteV2
              amplitudeEvent={eventTypes.viewFindJobs}
              documentTitle="Find Jobs"
              element={<Pages.FindJobsListings />}
              hideToolbar
            />
          ),
        },
        {
          path: ROUTES.FIND_JOBS_AUTO_MANAGED_ID,
          element: (
            <AppRouteV2
              amplitudeEvent={eventTypes.viewFindJobDetails}
              documentTitle="Find Jobs"
              element={<Pages.FindJobsAutoManaged />}
              toolbarProps={{
                back: ROUTES.FIND_JOBS,
                title: 'Campaign Details',
              }}
            />
          ),
        },
        {
          path: ROUTES.FIND_JOBS_MARKETPLACE_ID,
          element: (
            <AppRouteV2
              amplitudeEvent={eventTypes.viewFindJobDetails}
              documentTitle="Find Jobs"
              element={<Pages.FindJobsMarketplace />}
              toolbarProps={{
                back: ROUTES.FIND_JOBS,
                title: 'Campaign Details',
              }}
            />
          ),
        },
        {
          path: ROUTES.FIND_JOBS_TIKTOK_SPECIAL_ID,
          element: (
            <AppRouteV2
              amplitudeEvent={eventTypes.viewFindJobDetails}
              documentTitle="Find Jobs"
              element={<Pages.FindJobsMarketplace />}
              toolbarProps={{
                back: ROUTES.FIND_JOBS,
                title: 'Campaign Details',
              }}
            />
          ),
        },
        {
          path: ROUTES.FIND_JOBS_CAMPAIGN_FINISHED,
          element: (
            <AppRouteV2
              documentTitle="Find Jobs"
              element={<Pages.FindJobsCampaignFinished />}
              toolbarProps={{
                back: ROUTES.FIND_JOBS,
                title: 'Campaign has finished',
              }}
            />
          ),
        },
      ],
    },
    // My Jobs
    {
      path: ROUTES.MY_JOBS,
      children: [
        {
          index: true,
          element: (
            <AppRouteV2 amplitudeEvent={eventTypes.viewMyJobs} documentTitle="My Jobs" element={<Pages.MyJobs />} />
          ),
        },
        {
          path: ROUTES.MY_JOBS_AUTO_MANAGED_ID,
          children: [
            {
              index: true,
              element: (
                <AppRouteV2
                  amplitudeEvent={eventTypes.viewMyJobDetails}
                  documentTitle="My Jobs"
                  element={<Pages.MyJobsAutoManagedDetails />}
                  toolbarProps={{
                    back: ROUTES.MY_JOBS,
                    title: 'Campaign Details',
                  }}
                />
              ),
            },
            {
              path: ROUTES.MY_JOBS_AUTO_MANAGED_ID_REPORT,
              element: (
                <AppRouteV2
                  amplitudeEvent={eventTypes.viewMyJobReport}
                  element={<Pages.MyJobsAutoManagedReport />}
                  toolbarProps={{
                    back: ROUTES.MY_JOBS_AUTO_MANAGED_ID,
                    title: 'CV Report',
                  }}
                />
              ),
            },
          ],
        },
        {
          path: ROUTES.MY_JOBS_MARKETPLACE_ID,
          children: [
            {
              index: true,
              element: (
                <AppRouteV2
                  amplitudeEvent={eventTypes.viewMyJobDetails}
                  documentTitle="My Jobs"
                  element={<Pages.MyJobsMarketplaceDetails />}
                  hideToolbar
                />
              ),
            },
            {
              path: ROUTES.MY_JOBS_MARKETPLACE_ID_SUBMIT_DRAFT_POST,
              element: (
                <AppRouteV2
                  amplitudeEvent={eventTypes.viewMyJobSubmitDraftPost}
                  documentTitle="Submit draft"
                  element={<Pages.MyJobsMarketplaceSubmitDraftPost />}
                  toolbarProps={{
                    back: ROUTES.MY_JOBS_MARKETPLACE_ID,
                    title: 'Submit draft',
                  }}
                />
              ),
            },
            {
              path: ROUTES.MY_JOBS_MARKETPLACE_ID_REPORT,
              element: (
                <AppRouteV2
                  amplitudeEvent={eventTypes.viewMyJobReport}
                  element={<Pages.MyJobsMarketplaceReport />}
                  toolbarProps={{
                    back: ROUTES.MY_JOBS_MARKETPLACE_ID,
                    title: 'CV Report',
                  }}
                />
              ),
            },
            {
              path: ROUTES.MY_JOBS_MARKETPLACE_ID_POST_REPORT,
              element: (
                <AppRouteV2
                  amplitudeEvent={eventTypes.viewMyJobReport}
                  element={<Pages.MyJobsMarketplacePostReport />}
                  toolbarProps={{
                    back: ROUTES.MY_JOBS_MARKETPLACE_ID,
                    title: 'Post Report',
                  }}
                />
              ),
            },
          ],
        },
        {
          path: ROUTES.MY_JOBS_CASTING_YOUR_JOB_ID,
          children: [
            {
              index: true,
              element: (
                <AppRouteV2
                  amplitudeEvent={eventTypes.viewMyJobDetails}
                  element={<Pages.MyJobsCastingDetails />}
                  toolbarProps={{
                    back: ROUTES.MY_JOBS,
                    title: 'My Jobs',
                  }}
                />
              ),
            },
            {
              path: ROUTES.MY_JOBS_CASTING_YOUR_JOB_ID_POST,
              children: [
                {
                  index: true,
                  element: (
                    <AppRouteV2
                      amplitudeEvent={eventTypes.viewEngagementYourJobDetailsPosts}
                      element={<Pages.MyJobsCastingPosts />}
                      toolbarProps={{
                        back: ROUTES.MY_JOBS_CASTING_YOUR_JOB_ID,
                        title: 'Post List',
                      }}
                    />
                  ),
                },
                {
                  path: ROUTES.MY_JOBS_CASTING_YOUR_JOB_ID_POST_ADD,
                  element: (
                    <AppRouteV2
                      amplitudeEvent={eventTypes.viewEngagementYourJobDetailsPostAdd}
                      element={<Pages.MyJobsCastingAddPost />}
                      toolbarProps={{
                        back: ROUTES.MY_JOBS_CASTING_YOUR_JOB_ID_POST,
                        title: 'Upload Draft Post',
                      }}
                    />
                  ),
                },
                {
                  path: ROUTES.MY_JOBS_CASTING_YOUR_JOB_ID_POST_EDIT,
                  element: (
                    <AppRouteV2
                      amplitudeEvent={eventTypes.viewEngagementYourJobDetailsPostDetails}
                      element={<Pages.MyJobsCastingEditPost />}
                      toolbarProps={{
                        back: ROUTES.MY_JOBS_CASTING_YOUR_JOB_ID_POST,
                        title: 'Edit Post',
                      }}
                    />
                  ),
                },
              ],
            },
            {
              path: ROUTES.MY_JOBS_CASTING_YOUR_JOB_ID_REPORT,
              element: (
                <AppRouteV2
                  amplitudeEvent={eventTypes.viewEngagementYourJobDetailsReport}
                  documentTitle="Campaign's Report"
                  element={<Pages.MyJobsCastingReports />}
                  toolbarProps={{
                    back: ROUTES.MY_JOBS_CASTING_YOUR_JOB_ID,
                    title: `Campaign's Report`,
                  }}
                />
              ),
            },
          ],
        },
        {
          path: ROUTES.MY_JOBS_TIKTOK_SPECIAL_ID,
          children: [
            {
              index: true,
              element: (
                <AppRouteV2
                  amplitudeEvent={eventTypes.viewMyJobDetails}
                  documentTitle="My Jobs"
                  element={<Pages.MyJobsMarketplaceDetails />}
                  hideToolbar
                />
              ),
            },
            {
              path: ROUTES.MY_JOBS_TIKTOK_SPECIAL_ID_POST_REPORT,
              element: (
                <AppRouteV2
                  amplitudeEvent={eventTypes.viewMyJobReport}
                  element={<Pages.MyJobsMarketplacePostReport />}
                  toolbarProps={{
                    back: ROUTES.MY_JOBS_TIKTOK_SPECIAL_ID,
                    title: 'Post Report',
                  }}
                />
              ),
            },
          ],
        },
        {
          path: ROUTES.MY_JOBS_WAITING_ID,
          element: (
            <AppRouteV2
              amplitudeEvent={eventTypes.viewMyJobDetails}
              documentTitle="My Jobs"
              element={<Pages.FindJobsMarketplace />}
              toolbarProps={{
                back: ROUTES.MY_JOBS,
                title: 'Campaign Details',
              }}
            />
          ),
        },
      ],
    },
    // My Page
    {
      path: ROUTES.MY_PAGE,
      children: [
        {
          index: true,
          element: (
            <AppRouteV2
              amplitudeEvent={eventTypes.viewMyPage}
              documentTitle="My Page"
              element={<Pages.MyPage />}
              hideToolbar
            />
          ),
        },
        {
          path: ROUTES.MY_PAGE_PAYMENT_HISTORY,
          element: (
            <AppRouteV2
              amplitudeEvent={eventTypes.viewMyPagePaymentHistory}
              element={<Pages.MyPagePaymentHistory />}
              toolbarProps={{
                back: ROUTES.MY_PAGE,
                title: 'Payment History',
              }}
            />
          ),
        },
        // profile registration
        {
          path: ROUTES.MY_PAGE_BANK_ACCOUNT,
          element: (
            <AppRouteV2
              amplitudeEvent={eventTypes.viewMyPageBankAccount}
              element={<Pages.MyPageBankAccount />}
              toolbarProps={{
                back: ROUTES.MY_PAGE,
                title: 'Bank Account',
              }}
            />
          ),
        },
        {
          path: ROUTES.MY_PAGE_ACCOUNT,
          children: [
            {
              index: true,
              element: (
                <AppRouteV2
                  amplitudeEvent={eventTypes.viewMyPageAccount}
                  element={<Pages.MyPageAccount />}
                  toolbarProps={{
                    back: ROUTES.MY_PAGE,
                    title: 'Account',
                  }}
                />
              ),
            },
            {
              path: ROUTES.MY_PAGE_ACCOUNT_DELETE,
              element: (
                <AppRouteV2
                  amplitudeEvent={eventTypes.viewMyPageAccountDelete}
                  element={<Pages.MyPageAccountDelete />}
                  toolbarProps={{
                    back: ROUTES.MY_PAGE_ACCOUNT,
                    title: 'Delete Account',
                  }}
                />
              ),
            },
          ],
        },
        {
          path: ROUTES.MY_PAGE_ADDRESS,
          element: (
            <AppRouteV2
              amplitudeEvent={eventTypes.viewMyPageAddress}
              element={<Pages.MyPageAddress />}
              toolbarProps={{
                back: ROUTES.MY_PAGE,
                title: 'Address',
              }}
            />
          ),
        },
        {
          path: ROUTES.MY_PAGE_USERS,
          element: (
            <AppRouteV2
              amplitudeEvent={eventTypes.viewMyPageUsers}
              element={<Pages.MyPageUsers />}
              toolbarProps={{
                back: ROUTES.MY_PAGE,
                title: 'Users',
              }}
            />
          ),
        },
        {
          path: ROUTES.MY_PAGE_SNS_BUDGET,
          element: (
            <AppRouteV2
              amplitudeEvent={eventTypes.viewMyPageSnsBudget}
              element={<Pages.MyPageSnsBudget />}
              toolbarProps={{
                back: ROUTES.MY_PAGE,
                title: 'SNS budget',
              }}
            />
          ),
        },
        // social connection
        {
          path: ROUTES.MY_PAGE_CHANNEL_CONNECT,
          children: [
            {
              index: true,
              element: (
                <AppRouteV2
                  amplitudeEvent={eventTypes.viewMyPageChannelConnect}
                  element={<Pages.MyPageChannelConnect />}
                  toolbarProps={{
                    back: ROUTES.MY_PAGE,
                    title: 'Channel Connect',
                  }}
                />
              ),
            },
            {
              path: ROUTES.MY_PAGE_CHANNEL_CONNECT_ADD_LINE,
              element: (
                <AppRouteV2
                  amplitudeEvent={eventTypes.viewMyPageChannelConnectAddLine}
                  element={<Pages.MyPageChannelConnectAddLine />}
                  toolbarProps={{
                    back: ROUTES.MY_PAGE_CHANNEL_CONNECT,
                    title: 'LINE Connection',
                  }}
                />
              ),
            },
            {
              path: ROUTES.MY_PAGE_CHANNEL_CONNECT_ADD_SHOPIFY,
              element: (
                <AppRouteV2
                  amplitudeEvent={eventTypes.viewMyPageChannelConnectAddShopify}
                  element={<Pages.MyPageChannelConnectAddShopify />}
                  toolbarProps={{
                    back: ROUTES.MY_PAGE_CHANNEL_CONNECT,
                    title: 'Shopify Connection',
                  }}
                />
              ),
            },
          ],
        },
        {
          path: ROUTES.MY_PAGE_SOCIAL_CONNECT,
          children: [
            {
              index: true,
              element: (
                <AppRouteV2
                  amplitudeEvent={eventTypes.viewMyPageSocialConnect}
                  element={<Pages.MyPageSocialConnect />}
                  toolbarProps={{
                    back: ROUTES.MY_PAGE,
                    title: 'Social Connect',
                  }}
                />
              ),
            },
            {
              path: ROUTES.MY_PAGE_SOCIAL_CONNECT_INSTAGRAM_CONNECT,
              element: (
                <AppRouteV2
                  amplitudeEvent={eventTypes.viewMyPageSocialConnectInstagram}
                  element={<Pages.MyPageSocialConnectInstagramConnect />}
                  toolbarProps={{
                    back: ROUTES.MY_PAGE_SOCIAL_CONNECT,
                    title: 'Social Connect',
                  }}
                />
              ),
            },
            {
              path: ROUTES.MY_PAGE_SOCIAL_CONNECT_INSTAGRAM_UNABLE_CONNECT,
              element: (
                <AppRouteV2
                  amplitudeEvent={eventTypes.viewMyPageSocialConnectInstagram}
                  documentTitle="Social Connect"
                  element={<Pages.MyPageSocialConnectInstagramConnect />}
                  toolbarProps={{
                    back: ROUTES.MY_PAGE_SOCIAL_CONNECT,
                    title: 'Unable to connect',
                  }}
                />
              ),
            },
            {
              path: ROUTES.MY_PAGE_SOCIAL_CONNECT_FACEBOOK_GRANT_PERMISSION,
              element: (
                <AppRouteV2
                  amplitudeEvent={eventTypes.viewMyPageSocialConnectFacebookGrantPermission}
                  documentTitle="Social Connect"
                  element={<Pages.MyPageSocialConnectFacebookGrantPermission />}
                  toolbarProps={{
                    back: ROUTES.MY_PAGE_SOCIAL_CONNECT,
                    title: 'Grant permission',
                  }}
                />
              ),
            },
            {
              path: ROUTES.MY_PAGE_SOCIAL_CONNECT_TIKTOK_GRANT_PERMISSION,
              element: (
                <AppRouteV2
                  amplitudeEvent={eventTypes.viewMyPageSocialConnectTiktokGrantPermission}
                  documentTitle="Social Connect"
                  element={<Pages.MyPageSocialConnectTiktokGrantPermission />}
                  toolbarProps={{
                    back: ROUTES.MY_PAGE_SOCIAL_CONNECT,
                    title: 'Grant permission',
                  }}
                />
              ),
            },
            {
              path: ROUTES.MY_PAGE_SOCIAL_CONNECT_ADD_METHODS,
              element: (
                <AppRouteV2
                  amplitudeEvent={eventTypes.viewMyPageSocialConnectAddMethods}
                  element={<Pages.MyPageSocialConnectAddMethods />}
                  toolbarProps={{
                    back: ROUTES.MY_PAGE_SOCIAL_CONNECT,
                    title: DYNAMIC_TITLE,
                  }}
                />
              ),
            },
          ],
        },
        // fan management
        // fan
        {
          path: ROUTES.MY_PAGE_FANS,
          children: [
            {
              index: true,
              element: (
                <AppRouteV2
                  amplitudeEvent={eventTypes.viewFans}
                  element={<Pages.MyPageFans />}
                  toolbarProps={{
                    back: ROUTES.MY_PAGE,
                    title: 'Fans',
                  }}
                />
              ),
            },
            {
              path: ROUTES.MY_PAGE_FANS_ADD,
              element: (
                <AppRouteV2
                  element={<Pages.MyPageFansAdd />}
                  toolbarProps={{
                    back: ROUTES.MY_PAGE_FANS,
                    title: 'Add Fan',
                  }}
                />
              ),
            },
            {
              path: ROUTES.MY_PAGE_FANS_EDIT,
              children: [
                {
                  index: true,
                  element: (
                    <AppRouteV2
                      amplitudeEvent={eventTypes.viewFanDetails}
                      element={<Pages.MyPageFansDetails />}
                      toolbarProps={{
                        back: ROUTES.MY_PAGE_FANS,
                        title: `Fan's Details`,
                      }}
                    />
                  ),
                },
                {
                  path: ROUTES.MY_PAGE_FANS_EDIT_DETAILS,
                  element: (
                    <AppRouteV2
                      amplitudeEvent={eventTypes.viewFanDetails}
                      element={<Pages.MyPageFansDetails />}
                      toolbarProps={{
                        back: fansBackPath,
                        title: "Fan's Details",
                      }}
                    />
                  ),
                },
                {
                  path: ROUTES.MY_PAGE_FANS_EDIT_ACTIVITY,
                  element: (
                    <AppRouteV2
                      amplitudeEvent={eventTypes.viewFanActivities}
                      element={<Pages.MyPageFansDetails />}
                      toolbarProps={{
                        back: fansBackPath,
                        title: 'Activity',
                      }}
                    />
                  ),
                },
                {
                  path: ROUTES.MY_PAGE_FANS_EDIT_ORDERS,
                  element: (
                    <AppRouteV2
                      amplitudeEvent={eventTypes.viewFanOrders}
                      element={<Pages.MyPageFansDetails />}
                      toolbarProps={{
                        back: fansBackPath,
                        title: 'Orders',
                      }}
                    />
                  ),
                },
                {
                  path: ROUTES.MY_PAGE_FANS_EDIT_FORMS,
                  children: [
                    {
                      index: true,
                      element: (
                        <AppRouteV2
                          amplitudeEvent={eventTypes.viewFanForms}
                          element={<Pages.MyPageFansDetails />}
                          toolbarProps={{
                            back: fansBackPath,
                            title: 'Forms',
                          }}
                        />
                      ),
                    },
                    {
                      path: ROUTES.MY_PAGE_FANS_EDIT_FORMS_SUBMISSION_ID,
                      element: (
                        <AppRouteV2
                          amplitudeEvent={eventTypes.viewFanFormDetails}
                          element={<Pages.MyPageFansDetails />}
                          toolbarProps={{
                            back: ROUTES.MY_PAGE_FANS_EDIT_FORMS,
                            title: 'Forms',
                          }}
                        />
                      ),
                    },
                  ],
                },
              ],
            },
          ],
        },
        // email
        {
          path: ROUTES.MY_PAGE_EMAILS,
          children: [
            {
              index: true,
              element: (
                <AppRouteV2
                  amplitudeEvent={eventTypes.viewEmails}
                  element={<Pages.MyPageEmails />}
                  toolbarProps={{
                    back: ROUTES.MY_PAGE,
                    title: 'Emails',
                  }}
                />
              ),
            },
            {
              path: ROUTES.MY_PAGE_EMAILS_COMPOSE,
              element: (
                <AppRouteV2
                  element={<Pages.MyPageEmailCompose />}
                  toolbarProps={{
                    back: ROUTES.MY_PAGE_EMAILS,
                    title: 'Compose',
                  }}
                />
              ),
            },
            {
              path: ROUTES.MY_PAGE_EMAILS_EDIT_COMPOSE,
              element: (
                <AppRouteV2
                  amplitudeEvent={eventTypes.viewEmailDetails}
                  element={<Pages.MyPageEmailEdit />}
                  toolbarProps={{
                    back: ROUTES.MY_PAGE_EMAILS,
                    title: 'Compose',
                  }}
                />
              ),
            },
            {
              path: ROUTES.MY_PAGE_EMAILS_EDIT_OVERVIEW,
              element: (
                <AppRouteV2
                  amplitudeEvent={eventTypes.viewEmailReport}
                  element={<Pages.MyPageEmailReports />}
                  toolbarProps={{
                    back: ROUTES.MY_PAGE_EMAILS,
                    title: 'Email Report',
                  }}
                />
              ),
            },
            {
              path: ROUTES.MY_PAGE_EMAILS_EDIT_RECIPIENT,
              element: (
                <AppRouteV2
                  amplitudeEvent={eventTypes.viewEmailReport}
                  element={<Pages.MyPageEmailReports />}
                  toolbarProps={{
                    back: ROUTES.MY_PAGE_EMAILS,
                    title: 'Email Report',
                  }}
                />
              ),
            },
          ],
        },
        // message
        {
          path: ROUTES.MY_PAGE_MESSAGE,
          children: [
            {
              index: true,
              element: (
                <AppRouteV2
                  amplitudeEvent={eventTypes.viewMyPage}
                  element={<Pages.MyPageMessage />}
                  toolbarProps={{
                    back: ROUTES.MY_PAGE,
                    title: 'Message',
                  }}
                />
              ),
            },
            {
              path: ROUTES.MY_PAGE_MESSAGE_BROADCAST,
              children: [
                {
                  index: true,
                  element: (
                    <AppRouteV2
                      amplitudeEvent={eventTypes.viewBroadcast}
                      element={<Pages.MyPageMessageBroadcastListing />}
                      toolbarProps={{
                        back: ROUTES.MY_PAGE,
                        title: 'Broadcast',
                      }}
                    />
                  ),
                },
                {
                  path: ROUTES.MY_PAGE_MESSAGE_BROADCAST_ADD,
                  element: (
                    <AppRouteV2
                      element={<Pages.MyPageMessageBroadcastAdd />}
                      toolbarProps={{
                        title: 'Broadcast',
                      }}
                    />
                  ),
                },
                {
                  path: ROUTES.MY_PAGE_MESSAGE_BROADCAST_EDIT,
                  element: (
                    <AppRouteV2
                      amplitudeEvent={eventTypes.viewBroadcastDetails}
                      element={<Pages.MyPageMessageBroadcastEdit />}
                      toolbarProps={{
                        title: 'Broadcast',
                      }}
                    />
                  ),
                },
              ],
            },
            {
              path: ROUTES.MY_PAGE_MESSAGE_CHAT,
              children: [
                {
                  index: true,
                  element: (
                    <AppRouteV2
                      amplitudeEvent={eventTypes.viewChat}
                      element={<Pages.MyPageMessageChat />}
                      toolbarProps={{
                        back: ROUTES.MY_PAGE_MESSAGE,
                        title: 'Chat',
                      }}
                    />
                  ),
                },
                {
                  path: ROUTES.MY_PAGE_MESSAGE_CHAT_ACTIVE_ID,
                  element: (
                    <AppRouteV2
                      amplitudeEvent={eventTypes.viewChat}
                      element={<Pages.MyPageMessageChat />}
                      toolbarProps={{
                        back: ROUTES.MY_PAGE_MESSAGE_CHAT,
                        title: DYNAMIC_TITLE,
                      }}
                    />
                  ),
                },
                {
                  path: ROUTES.MY_PAGE_MESSAGE_CHAT_ACTIVE_ID_FAN_ID,
                  element: (
                    <AppRouteV2
                      amplitudeEvent={eventTypes.viewChat}
                      element={<Pages.MyPageMessageChat />}
                      toolbarProps={{
                        back: ROUTES.MY_PAGE_MESSAGE_CHAT_ACTIVE_ID,
                        title: 'Details',
                      }}
                    />
                  ),
                },
                {
                  path: ROUTES.MY_PAGE_MESSAGE_CHAT_ACTIVE_ID_FAN_ID_FAN_DETAILS,
                  element: (
                    <AppRouteV2
                      amplitudeEvent={eventTypes.viewChat}
                      element={<Pages.MyPageMessageChat />}
                      toolbarProps={{
                        back: ROUTES.MY_PAGE_MESSAGE_CHAT_ACTIVE_ID,
                        title: 'Fan Detail',
                      }}
                    />
                  ),
                },
                {
                  path: ROUTES.MY_PAGE_MESSAGE_CHAT_ACTIVE_ID_FAN_ID_ACTIVITY,
                  element: (
                    <AppRouteV2
                      amplitudeEvent={eventTypes.viewChat}
                      element={<Pages.MyPageMessageChat />}
                      toolbarProps={{
                        back: ROUTES.MY_PAGE_MESSAGE_CHAT_ACTIVE_ID_FAN_ID,
                        title: 'Activity',
                      }}
                    />
                  ),
                },
                {
                  path: ROUTES.MY_PAGE_MESSAGE_CHAT_ACTIVE_ID_FAN_ID_ORDERS,
                  element: (
                    <AppRouteV2
                      amplitudeEvent={eventTypes.viewChat}
                      element={<Pages.MyPageMessageChat />}
                      toolbarProps={{
                        back: ROUTES.MY_PAGE_MESSAGE_CHAT_ACTIVE_ID_FAN_ID,
                        title: 'Orders',
                      }}
                    />
                  ),
                },
              ],
            },
          ],
        },
        {
          path: ROUTES.MY_PAGE_FORMS,
          children: [
            {
              index: true,
              element: (
                <AppRouteV2
                  amplitudeEvent={eventTypes.viewForms}
                  element={<Pages.MyPageForms />}
                  toolbarProps={{
                    back: ROUTES.MY_PAGE,
                    title: 'Form',
                  }}
                />
              ),
            },
            {
              path: ROUTES.MY_PAGE_FORMS_ADD,
              children: [
                {
                  index: true,
                  element: (
                    <AppRouteV2
                      element={<Pages.MyPageAddForm />}
                      toolbarProps={{
                        back: ROUTES.MY_PAGE_FORMS,
                        title: 'Add Form',
                      }}
                    />
                  ),
                },
                {
                  path: ROUTES.MY_PAGE_FORMS_ADD_LIVE_PREVIEW,
                  element: (
                    <AppRouteV2
                      amplitudeEvent={eventTypes.viewFormLivePreview}
                      element={<Pages.MyPageAddPreviewForm />}
                      toolbarProps={{
                        back: ROUTES.MY_PAGE_FORMS_ADD,
                        title: 'Live Preview',
                      }}
                    />
                  ),
                },
              ],
            },
            {
              path: ROUTES.MY_PAGE_FORMS_EDIT_ENTRY,
              element: (
                <AppRouteV2
                  amplitudeEvent={eventTypes.viewFormDetails}
                  element={<Pages.MyPageEditForm />}
                  toolbarProps={{
                    back: ROUTES.MY_PAGE_FORMS,
                    title: 'Edit Form',
                  }}
                />
              ),
            },
            {
              path: ROUTES.MY_PAGE_FORMS_EDIT_REPORT,
              children: [
                {
                  index: true,
                  element: (
                    <AppRouteV2
                      amplitudeEvent={eventTypes.viewFormDetails}
                      element={<Pages.MyPageEditForm />}
                      toolbarProps={{
                        back: ROUTES.MY_PAGE_FORMS,
                        title: 'Edit Form',
                      }}
                    />
                  ),
                },
                {
                  path: ROUTES.MY_PAGE_FORMS_EDIT_REPORT_RESPONDENT,
                  element: (
                    <AppRouteV2
                      amplitudeEvent={eventTypes.viewFormDetails}
                      element={<Pages.MyPageEditForm />}
                      toolbarProps={{
                        back: ROUTES.MY_PAGE_FORMS,
                        title: 'Edit Form',
                      }}
                    />
                  ),
                },
                {
                  path: ROUTES.MY_PAGE_FORMS_EDIT_REPORT_INDIVIDUAL,
                  children: [
                    {
                      index: true,
                      element: (
                        <AppRouteV2
                          amplitudeEvent={eventTypes.viewFormDetails}
                          element={<Pages.MyPageEditForm />}
                          toolbarProps={{
                            back: ROUTES.MY_PAGE_FORMS,
                            title: 'Edit Form',
                          }}
                        />
                      ),
                    },
                    {
                      path: ROUTES.MY_PAGE_FORMS_EDIT_REPORT_INDIVIDUAL_PAGE,
                      element: (
                        <AppRouteV2
                          amplitudeEvent={eventTypes.viewFormDetails}
                          element={<Pages.MyPageEditForm />}
                          toolbarProps={{
                            back: ROUTES.MY_PAGE_FORMS,
                            title: 'Edit Form',
                          }}
                        />
                      ),
                    },
                  ],
                },
              ],
            },
            {
              path: ROUTES.MY_PAGE_FORMS_EDIT_LIVE_PREVIEW,
              element: (
                <AppRouteV2
                  amplitudeEvent={eventTypes.viewFormLivePreview}
                  element={<Pages.MyPageEditPreviewForm />}
                  toolbarProps={{
                    back: ROUTES.MY_PAGE_FORMS_EDIT_ENTRY,
                    title: 'Live Preview',
                  }}
                />
              ),
            },
          ],
        },
        {
          path: ROUTES.MY_PAGE_CONTACT,
          element: (
            <AppRouteV2
              amplitudeEvent={eventTypes.viewContact}
              element={<Pages.MyPageContact />}
              toolbarProps={{
                back: ROUTES.MY_PAGE,
                title: 'Contact',
              }}
            />
          ),
        },
      ],
    },
    // Notification
    {
      path: ROUTES.NOTIFICATION,
      element: (
        <AppRouteV2
          amplitudeEvent={eventTypes.viewNotification}
          element={<Pages.Notification />}
          toolbarProps={{
            back: ROUTES.FIND_JOBS,
            title: 'Notification',
          }}
        />
      ),
    },
    // Link In Bio
    {
      path: ROUTES.LINK_IN_BIO,
      element: (
        <AppRouteV2
          amplitudeEvent={eventTypes.viewLinkInBio}
          element={<Pages.LinkInBio />}
          toolbarProps={{
            title: DYNAMIC_TITLE,
          }}
        />
      ),
    },
    // My Link, Analytics,
    {
      path: ROUTES.ANALYTICS_LINK_IN_BIO,
      element: (
        <AppRouteV2
          amplitudeEvent={eventTypes.viewAnalyticsLinkInBio}
          element={<Pages.MyLinkAnalytics />}
          hideToolbar
        />
      ),
    },
    {
      path: ROUTES.ANALYTICS,
      element: isUuumView ? (
        <AppRoute element={<Pages.Analytics />} />
      ) : (
        <AppRouteV2 element={<Pages.Analytics />} hideToolbar />
      ),
    },
    {
      path: ROUTES.ANALYTICS_ACTIVE_TAB,
      element: isUuumView ? (
        <AppRoute element={<Pages.Analytics />} />
      ) : (
        <AppRouteV2 element={<Pages.Analytics />} hideToolbar />
      ),
    },
    {
      path: ROUTES.ANALYTICS_COMPARE,
      element: isUuumView ? (
        <AppRoute element={<Pages.Analytics />} />
      ) : (
        <AppRouteV2 element={<Pages.Analytics />} hideToolbar />
      ),
    },
    {
      path: ROUTES.ANALYTICS_IG_INTERACTION,
      element: isUuumView ? (
        <AppRoute element={<Pages.Analytics />} />
      ) : (
        <AppRouteV2 element={<Pages.Analytics />} hideToolbar />
      ),
    },
    {
      path: ROUTES.ANALYTICS_IG_POST_FEED,
      element: isUuumView ? (
        <AppRoute
          amplitudeEvent={eventTypes.viewAnalyticsInstagramPostFeed}
          backPath={ROUTES.ANALYTICS}
          element={<Pages.InstagramStoryReelPostFeed />}
          toolbarTitle="Post List"
        />
      ) : (
        <AppRouteV2
          amplitudeEvent={eventTypes.viewAnalyticsInstagramPostFeed}
          element={<Pages.InstagramStoryReelPostFeed />}
          toolbarProps={{
            back: ROUTES.ANALYTICS,
            title: 'Post List',
          }}
        />
      ),
    },
    {
      path: ROUTES.ANALYTICS_IG_STORY_FEED,
      element: isUuumView ? (
        <AppRoute
          amplitudeEvent={eventTypes.viewAnalyticsInstagramStoryFeed}
          backPath={ROUTES.ANALYTICS}
          element={<Pages.InstagramStoryReelPostFeed />}
          toolbarTitle="Story List"
        />
      ) : (
        <AppRouteV2
          amplitudeEvent={eventTypes.viewAnalyticsInstagramStoryFeed}
          element={<Pages.InstagramStoryReelPostFeed />}
          toolbarProps={{
            back: ROUTES.ANALYTICS,
            title: 'Story List',
          }}
        />
      ),
    },
    {
      path: ROUTES.ANALYTICS_IG_REEL_POSTS,
      element: isUuumView ? (
        <AppRoute
          amplitudeEvent={eventTypes.viewAnalyticsInstagramReelPosts}
          backPath={ROUTES.ANALYTICS}
          element={<Pages.InstagramStoryReelPostFeed />}
          toolbarTitle="Reel List"
        />
      ) : (
        <AppRouteV2
          amplitudeEvent={eventTypes.viewAnalyticsInstagramReelPosts}
          element={<Pages.InstagramStoryReelPostFeed />}
          toolbarProps={{
            back: ROUTES.ANALYTICS,
            title: 'Reel List',
          }}
        />
      ),
    },
    {
      path: ROUTES.ANALYTICS_FB_POST_FEED,
      element: isUuumView ? (
        <AppRoute
          amplitudeEvent={eventTypes.viewAnalyticsFacebookPostFeed}
          backPath={ROUTES.ANALYTICS}
          element={<Pages.FacebookPostFeed />}
          toolbarTitle="Post List"
        />
      ) : (
        <AppRouteV2
          amplitudeEvent={eventTypes.viewAnalyticsFacebookPostFeed}
          element={<Pages.FacebookPostFeed />}
          toolbarProps={{
            back: ROUTES.ANALYTICS,
            title: 'Post List',
          }}
        />
      ),
    },
    {
      path: ROUTES.ANALYTICS_YT_VIDEOS_FEED,
      element: isUuumView ? (
        <AppRoute
          amplitudeEvent={eventTypes.viewAnalyticsYoutubeVideosFeed}
          backPath={ROUTES.ANALYTICS}
          element={<Pages.YoutubeVideoList />}
          toolbarTitle="Video List"
        />
      ) : (
        <AppRouteV2
          amplitudeEvent={eventTypes.viewAnalyticsYoutubeVideosFeed}
          element={<Pages.YoutubeVideoList />}
          toolbarProps={{
            back: ROUTES.ANALYTICS,
            title: 'Video List',
          }}
        />
      ),
    },
    {
      path: ROUTES.ANALYTICS_YT_TAG_RANKING_LIST,
      element: isUuumView ? (
        <AppRoute
          amplitudeEvent={eventTypes.viewAnalyticsYoutubeTagRankingList}
          backPath={ROUTES.ANALYTICS}
          element={<Pages.TagRanking />}
          toolbarTitle="Tag Ranking"
        />
      ) : (
        <AppRouteV2
          amplitudeEvent={eventTypes.viewAnalyticsYoutubeTagRankingList}
          element={<Pages.TagRanking />}
          toolbarProps={{
            back: ROUTES.ANALYTICS,
            title: 'Tag Ranking',
          }}
        />
      ),
    },
    {
      path: ROUTES.ANALYTICS_YT_COMPARE_VIDEOS_FEED,
      element: isUuumView ? (
        <AppRoute
          amplitudeEvent={eventTypes.viewAnalyticsYoutubeCompareVideosFeed}
          backPath={ROUTES.ANALYTICS}
          element={<Pages.YoutubeCompareFeedVideos />}
          toolbarTitle="Video List"
        />
      ) : (
        <AppRouteV2
          amplitudeEvent={eventTypes.viewAnalyticsYoutubeCompareVideosFeed}
          element={<Pages.YoutubeCompareFeedVideos />}
          toolbarProps={{
            back: ROUTES.ANALYTICS,
            title: 'Video List',
          }}
        />
      ),
    },
    {
      path: ROUTES.ANALYTICS_IG_INTERACTION_USER_ANALYSIS,
      element: isUuumView ? (
        <AppRoute
          amplitudeEvent={eventTypes.viewAnalyticsInstagramInteractionUserAnalysis}
          backPath={ROUTES.ANALYTICS_IG_INTERACTION}
          element={<Pages.UserAnalysisList />}
          toolbarTitle={DYNAMIC_TITLE}
        />
      ) : (
        <AppRouteV2
          amplitudeEvent={eventTypes.viewAnalyticsInstagramInteractionUserAnalysis}
          element={<Pages.UserAnalysisList />}
          toolbarProps={{
            back: ROUTES.ANALYTICS_IG_INTERACTION,
            title: DYNAMIC_TITLE,
          }}
        />
      ),
    },
    {
      path: ROUTES.ANALYTICS_IG_INTERACTION_TAGGED_POSTS,
      element: isUuumView ? (
        <AppRoute
          amplitudeEvent={eventTypes.viewAnalyticsInstagramInteractionTaggedPosts}
          element={<Pages.IgTaggedOrMentionedPostsList />}
          backPath={ROUTES.ANALYTICS_IG_INTERACTION}
          toolbarTitle={DYNAMIC_TITLE}
        />
      ) : (
        <AppRouteV2
          amplitudeEvent={eventTypes.viewAnalyticsInstagramInteractionTaggedPosts}
          element={<Pages.IgTaggedOrMentionedPostsList />}
          toolbarProps={{
            back: ROUTES.ANALYTICS_IG_INTERACTION,
            title: DYNAMIC_TITLE,
          }}
        />
      ),
    },
    {
      path: ROUTES.ANALYTICS_IG_INTERACTION_MENTIONED_POSTS,
      element: isUuumView ? (
        <AppRoute
          amplitudeEvent={eventTypes.viewAnalyticsInstagramInteractionMentionedPosts}
          backPath={ROUTES.ANALYTICS_IG_INTERACTION}
          element={<Pages.IgTaggedOrMentionedPostsList />}
          toolbarTitle={DYNAMIC_TITLE}
        />
      ) : (
        <AppRouteV2
          amplitudeEvent={eventTypes.viewAnalyticsInstagramInteractionMentionedPosts}
          element={<Pages.IgTaggedOrMentionedPostsList />}
          toolbarProps={{
            back: ROUTES.ANALYTICS_IG_INTERACTION,
            title: DYNAMIC_TITLE,
          }}
        />
      ),
    },
    {
      path: ROUTES.FORM_HASH,
      element: renderWithAmplitudeEvent(<Pages.SubmitForm />, eventTypes.viewFormSubmit),
    },
    // Settings
    {
      path: ROUTES.SETTINGS,
      children: [
        { index: true, element: <AppRoute element={<Pages.SettingsMenu />} toolbarTitle="Settings" /> },
        {
          path: ROUTES.SETTINGS_ACCOUNT,
          children: [
            {
              index: true,
              element: (
                <AppRoute
                  amplitudeEvent={eventTypes.viewSettingsAccount}
                  backPath={ROUTES.SETTINGS}
                  element={<Pages.SettingsAccount />}
                  toolbarTitle="Account"
                />
              ),
            },
            {
              path: ROUTES.SETTINGS_ACCOUNT_DELETE,
              element: (
                <AppRoute
                  amplitudeEvent={eventTypes.viewSettingsAccountDelete}
                  backPath={ROUTES.SETTINGS_ACCOUNT}
                  element={<Pages.SettingAccountDelete />}
                  toolbarTitle="Delete Account"
                />
              ),
            },
          ],
        },
        {
          path: ROUTES.SETTINGS_ADDRESS,
          element: (
            <AppRoute
              amplitudeEvent={eventTypes.viewSettingsAddress}
              backPath={ROUTES.SETTINGS}
              element={<Pages.SettingsAddress />}
              toolbarTitle="Address"
            />
          ),
        },
        {
          path: ROUTES.SETTINGS_SOCIAL_CONNECT,
          children: [
            {
              index: true,
              element: (
                <AppRoute
                  amplitudeEvent={eventTypes.viewSettingsSocialConnect}
                  backPath={ROUTES.SETTINGS}
                  element={<Pages.SettingsSocialConnect />}
                  toolbarTitle="Social Connect"
                />
              ),
            },
            {
              path: ROUTES.SETTINGS_SOCIAL_CONNECT_ADD_METHODS,
              element: (
                <AppRoute
                  backPath={ROUTES.SETTINGS_SOCIAL_CONNECT}
                  element={<Pages.SettingsSocialConnectAddMethods />}
                />
              ),
            },
            {
              path: ROUTES.SETTINGS_SOCIAL_CONNECT_IG_CONNECT,
              element: (
                <AppRoute
                  amplitudeEvent={eventTypes.viewSettingsSocialConnectInstagramConnect}
                  backPath={ROUTES.SETTINGS_SOCIAL_CONNECT}
                  element={<Pages.MyPageSocialConnectInstagramConnect />}
                  skipBottomPadding
                  toolbarTitle="Social connect"
                />
              ),
            },
            {
              path: ROUTES.SETTINGS_SOCIAL_CONNECT_IG_UNABLE_CONNECT,
              element: (
                <AppRoute
                  amplitudeEvent={eventTypes.viewSettingsSocialConnectInstagramUnableConnect}
                  backPath={ROUTES.SETTINGS_SOCIAL_CONNECT}
                  element={<Pages.MyPageSocialConnectInstagramConnect />}
                  skipBottomPadding
                  toolbarTitle="Unable to connect"
                />
              ),
            },
            {
              path: ROUTES.SETTINGS_SOCIAL_CONNECT_TIKTOK_GRANT_PERMISSION,
              element: (
                <AppRoute
                  amplitudeEvent={eventTypes.viewSettingsSocialConnectTiktokGrantPermission}
                  backPath={ROUTES.SETTINGS_SOCIAL_CONNECT}
                  element={<Pages.MyPageSocialConnectTiktokGrantPermission />}
                  toolbarTitle="Grant permission"
                />
              ),
            },
            {
              path: ROUTES.SETTINGS_SOCIAL_CONNECT_FACEBOOK_GRANT_PERMISSION,
              element: (
                <AppRoute
                  amplitudeEvent={eventTypes.viewSettingsSocialConnectFacebookGrantPermission}
                  backPath={ROUTES.SETTINGS_SOCIAL_CONNECT}
                  element={<Pages.MyPageSocialConnectFacebookGrantPermission />}
                  toolbarTitle="Grant permission"
                />
              ),
            },
          ],
        },
        {
          path: ROUTES.SETTINGS_USERS,
          children: [
            {
              index: true,
              element: (
                <AppRoute
                  amplitudeEvent={eventTypes.viewSettingsUsers}
                  backPath={ROUTES.SETTINGS}
                  element={<Pages.SettingsUsers />}
                  toolbarTitle="Users"
                />
              ),
            },
            {
              path: ROUTES.SETTINGS_USERS_USER_ID,
              element: (
                <AppRoute
                  amplitudeEvent={eventTypes.viewSettingsUserDetails}
                  backPath={ROUTES.SETTINGS_USERS}
                  element={<Pages.SettingsUserDetail />}
                  toolbarTitle="User Detail"
                />
              ),
            },
          ],
        },
      ],
    },
    // LIFF
    {
      path: ROUTES.LIFF_ID,
      element: <Pages.LineIdTokenVerification />,
    },
    {
      path: ROUTES.LIFF_SIGN_IN_ID,
      element: <PublicRoute element={<Pages.LineIdTokenVerification />} />,
    },
    // AnyCreator Mobile
    {
      path: ROUTES.MOBILE_SOCIAL_AUTH,
      element: <Pages.MobileSocialAuth />,
    },
    {
      path: ROUTES.MOBILE_TIKTOK_CONNECT,
      element: <Pages.MobileTiktokConnect />,
    },
    {
      path: ROUTES.MOBILE_TIKTOK_GRANT_PERMISSION,
      element: <AppRoute element={<Pages.MobileTiktokGrantPermission />} />,
    },
    // AnyTag
    {
      path: ROUTES.CAMPAIGN_DETAILS_PUBLIC,
      element: <Pages.CampaignDetailsPublic />,
    },
    {
      path: ROUTES.CREATOR_ACCESS_PREVIEW,
      element: <Pages.CreatorAccessPreview />,
    },
    // Terms & Privacy
    {
      path: ROUTES.TERMS,
      element: renderWithAmplitudeEvent(<Pages.Terms />, eventTypes.viewTerms),
    },
    {
      path: ROUTES.PRIVACY,
      element: renderWithAmplitudeEvent(<Pages.Privacy />, eventTypes.viewPrivacy),
    },
    // Not Found inside the App
    {
      path: '*',
      element: <AppRouteV2 documentTitle="Not Found" element={<Pages.NotFound />} />,
    },
  ];

  const router = createBrowserRouter([{ element: <App />, children: routes }]);

  return <RouterProvider router={router} />;
};

export default AppRoutes;
