import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Avatar, Icomoon, TextCutter } from '@src/components/atoms';
import { Dialog, ThemeButton } from '@src/components/molecules';
import { SocialButton } from '@src/components/shared';
import { useAnyXSocialAuthDisconnectMutation } from '@src/graphql/hooks';
import { ErrorTypes } from '@src/libs/error';
import { formatNumberWithCommas } from '@src/libs/format';
import { useAuthData, usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { switchImage, switchSocialBackgroundColor, switchText } from '@src/libs/socialMedia';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import { AnyXConnectedChildYoutubeAccount, SocialAccountType } from '@src/__generated__/globalTypes';

interface ChilAccounts extends AnyXConnectedChildYoutubeAccount {
  type: SocialAccountType;
}

interface Account {
  avatar?: string | null;
  childAccounts?: ChilAccounts[];
  followersCount?: number | null;
  id: string;
  idForDisconnect: string;
  isBusiness?: boolean | null;
  name: string;
  needReconnect?: boolean;
  type: SocialAccountType;
}

interface SocialAccountConnectorProps {
  accounts: Account[];
  isEnableDisconnect: boolean;
  type: SocialAccountType;
  onClickConnect: () => void;
  onClickReconnect?: (socialAccountId: string) => void;
}

enum DialogNotAbleDisconnect {
  ACC_JOINING_CAMPAIGN = 'ACC_JOINING_CAMPAIGN',
  CONNECTED_TO_IG_BUSINESS_ACC = 'CONNECTED_TO_IG_BUSINESS_ACC',
}

const SocialAccountConnector = ({
  accounts,
  isEnableDisconnect,
  type,
  onClickConnect,
  onClickReconnect,
}: SocialAccountConnectorProps) => {
  const [dialogNotAbleDisconnect, setDialogNotAbleDisconnect] = useState<DialogNotAbleDisconnect | null>(null);
  const [disconnectAccount, setDisconnectAccount] = useState<Account | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { limitedLogin } = useAuthData();
  const { isMobileView } = usePageLayout();
  const { enqueueSnackbar, t } = useQueryHelper();
  const [anyXSocialAuthDisconnect] = useAnyXSocialAuthDisconnectMutation({
    refetchQueries: ['AnyXCheckConnectedSocialAccounts'],
  });
  const actionButtonStyles = css((!isMobileView || dialogNotAbleDisconnect) && { width: isMobileView ? 152 : 112 });
  const isFacebookSocialMedia = [SocialAccountType.FACEBOOK, SocialAccountType.FACEBOOK_PAGE].includes(type);

  const onClickClose = () => {
    setDisconnectAccount(null);
    setDialogNotAbleDisconnect(null);
  };

  const onClickRemove = async () => {
    try {
      if (disconnectAccount) {
        setIsLoading(true);
        await anyXSocialAuthDisconnect({
          variables: {
            input: {
              socialAccountId: disconnectAccount.idForDisconnect,
              socialAccountType: disconnectAccount.type,
            },
          },
        });
        setDisconnectAccount(null);
        enqueueSnackbar(t('succeededInDeleting'), { variant: 'success' });
      }
    } catch (err) {
      if (err.message === ErrorTypes.SNS_ACCOUNT_JOINED_IN_PROGRESS_CAMPAIGN) {
        setDialogNotAbleDisconnect(DialogNotAbleDisconnect.ACC_JOINING_CAMPAIGN);
      } else if (err.message === ErrorTypes.ACCOUNT_OR_PAGE_CONNECTED_TO_IG_BUSINESS_ACCOUNT) {
        setDialogNotAbleDisconnect(DialogNotAbleDisconnect.CONNECTED_TO_IG_BUSINESS_ACC);
      } else {
        enqueueSnackbar(err.message, { variant: 'error' });
      }
    }
    setIsLoading(false);
  };

  return (
    <div>
      <Dialog
        contentStyles={{ maxWidth: 648 }}
        cancelButtonProps={{
          disabled: isLoading,
          styles: actionButtonStyles,
          text: 'Close',
          onClick: onClickClose,
        }}
        open={!!disconnectAccount}
        removeContentPadding
        onClose={onClickClose}
        {...(!dialogNotAbleDisconnect && {
          nextButtonProps: {
            loading: isLoading,
            styles: actionButtonStyles,
            text: 'Remove',
            theme: 'red',
            onClick: onClickRemove,
          },
        })}
      >
        <div css={styles.dialogDisconnect}>
          <div className="title">
            <Icomoon color="#ffb63d" icon="warning" size={24} />
            <label>
              {t(
                dialogNotAbleDisconnect
                  ? 'Title.Your account cannot be removed'
                  : 'Title.Are you sure you want to disconnect'
              )}
            </label>
          </div>
          <label className="description">
            <Trans
              components={{ 1: <b /> }}
              i18nKey={
                dialogNotAbleDisconnect === DialogNotAbleDisconnect.ACC_JOINING_CAMPAIGN
                  ? 'Annotation.This account is in a campaign that is In Progress'
                  : dialogNotAbleDisconnect === DialogNotAbleDisconnect.CONNECTED_TO_IG_BUSINESS_ACC
                  ? 'Annotation.You cannot remove it because it is connected to the Instagram account'
                  : 'Annotation.This process cannot be undone'
              }
            />
          </label>
          <div className="profile">
            <div>
              <Avatar src={disconnectAccount?.avatar || ''} size={40} title={disconnectAccount?.name} />
              <SocialMediaIndicator background={switchSocialBackgroundColor(type)} isPositionAbsolute>
                <Icomoon color="#fff" icon={switchImage({ dynamicColor: true, socialMedia: type })} />
              </SocialMediaIndicator>
            </div>
            <div>
              <TextCutter lines={1} text={disconnectAccount?.name || ''} />
              <label>
                {t(isFacebookSocialMedia ? 'Total friends' : 'Total followers', {
                  count: formatNumberWithCommas(disconnectAccount?.followersCount, 0) as never,
                })}
              </label>
            </div>
          </div>
        </div>
      </Dialog>

      <div css={styles.contentContainer}>
        <div css={styles.header}>
          <Icomoon icon={switchImage({ socialMedia: type })} size={15} />
          <label>{switchText(type)}</label>
        </div>
        {accounts.map(acc => {
          const reconnect = (type === SocialAccountType.INSTAGRAM && !acc.isBusiness) || !!acc.needReconnect;
          const childAccountsLength = acc.childAccounts?.length;

          return (
            <div css={styles.socialAccountContainer} key={acc.id}>
              <SocialAccount reconnect={reconnect}>
                <div className="avatar">
                  <Avatar src={acc.avatar || ''} size={40} title={acc.name} />
                  <SocialMediaIndicator background={switchSocialBackgroundColor(type)} isPositionAbsolute>
                    <Icomoon color="#fff" icon={switchImage({ dynamicColor: true, socialMedia: type })} />
                  </SocialMediaIndicator>
                </div>
                <div className="followers-container">
                  <div>
                    <TextCutter lines={1} text={acc.name} />
                    {reconnect && <Icomoon color="#ff5f5f" icon="link" size={18} />}
                  </div>
                  <label>
                    {t(isFacebookSocialMedia ? 'Total friends' : 'Total followers', {
                      count: formatNumberWithCommas(acc.followersCount, 0) as never,
                    })}
                  </label>
                </div>
                <div className="actions-container">
                  {reconnect && (
                    <SocialButton
                      disabled={limitedLogin}
                      hasIcon={false}
                      light
                      socialMedia={type}
                      title="Reconnect"
                      width="fit-content"
                      onClick={() => onClickReconnect?.(acc.id)}
                    />
                  )}
                  {isEnableDisconnect && (
                    <ThemeButton
                      disabled={limitedLogin}
                      text="Remove"
                      width="fit-content"
                      onClick={() => setDisconnectAccount(acc)}
                    />
                  )}
                </div>
              </SocialAccount>
              {acc.childAccounts?.map((subAcc, subIndex) => (
                <SubSocialAccount
                  isLastItem={!!childAccountsLength && subIndex === childAccountsLength - 1}
                  key={subIndex}
                  reconnect={reconnect}
                >
                  <div className="connector" />
                  <div className="channel-container">
                    <div className="avatar">
                      <Avatar src={subAcc.avatar || ''} size={40} title={subAcc.name} />
                      <SocialMediaIndicator background={switchSocialBackgroundColor(subAcc.type)} isPositionAbsolute>
                        <Icomoon color="#fff" icon={switchImage({ dynamicColor: true, socialMedia: subAcc.type })} />
                      </SocialMediaIndicator>
                    </div>
                    <TextCutter className="name" lines={1} text={subAcc.name} />
                  </div>
                </SubSocialAccount>
              ))}
            </div>
          );
        })}
        <div css={styles.addAccountContainer}>
          <ThemeButton
            css={limitedLogin && { pointerEvents: 'none' }}
            height={isMobileView ? '56px' : '64px'}
            prefixIcon={<Icomoon icon="plus" />}
            text="Add Account"
            onClick={onClickConnect}
          />
        </div>
      </div>
    </div>
  );
};

const SocialAccount = styled.div<{ reconnect: boolean }>(({ reconnect }) => ({
  alignItems: 'center',
  display: 'flex',
  gap: THEME.box.gaps.l,

  '& > .avatar': {
    position: 'relative',
    width: 40,
    ...(reconnect && { opacity: 0.3 }),
  },

  '& > .followers-container': {
    color: THEME.font.colors.black.main,
    width: 'fill-available',

    '& > div': {
      alignItems: 'center',
      display: 'flex',
      fontSize: THEME.font.sizes.normal,
      fontWeight: 600,
      gap: THEME.box.gaps.xs,

      '& > div': {
        ...(reconnect && { opacity: 0.3 }),
      },
    },

    '& > label': {
      fontSize: THEME.font.sizes.subordinate,
      ...(reconnect && { opacity: 0.3 }),
    },
  },

  '& > .actions-container': {
    alignItems: 'center',
    display: 'flex',
    gap: THEME.box.gaps.s,
    justifyContent: 'flex-end',
    width: 'fill-available',

    '& > button': {
      borderRadius: THEME.box.borderRadius.m,
    },
  },
}));

const SocialMediaIndicator = styled.div<{ background: string; isPositionAbsolute?: boolean }>(
  ({ background, isPositionAbsolute }) => ({
    alignItems: 'center',
    background,
    borderRadius: '50%',
    display: 'flex',
    height: 22,
    justifyContent: 'center',
    maxWidth: 22,
    minWidth: 22,
    ...(isPositionAbsolute && {
      border: '2px solid #fff',
      bottom: 0,
      position: 'absolute',
      right: -12,
    }),
  })
);

const SubSocialAccount = styled.div<{ isLastItem: boolean; reconnect: boolean }>(({ isLastItem, reconnect }) => ({
  alignItems: 'center',
  display: 'flex',

  '& > .connector': {
    display: 'flex',
    height: 54,
    minWidth: 46,
    position: 'relative',

    '&::before': {
      backgroundImage: 'linear-gradient(black 33%, rgba(255,255,255,0) 0%)',
      backgroundPosition: 'right',
      backgroundRepeat: 'repeat-y',
      backgroundSize: '1px 3px',
      content: '""',
      display: 'inline-block',
      width: 'fill-available',
      ...(isLastItem && { height: 27 }),
    },

    '&::after': {
      backgroundImage: 'linear-gradient(to right, black 33%, rgba(255,255,255,0) 0%)',
      backgroundPosition: 'bottom',
      backgroundRepeat: 'repeat-x',
      backgroundSize: '3px 1px',
      content: '""',
      display: 'inline-block',
      height: 27,
      width: 'fill-available',
    },
  },

  '& > .channel-container': {
    alignItems: 'center',
    display: 'flex',
    gap: THEME.box.gaps.l,

    '& > .avatar': {
      position: 'relative',
      width: 40,
      ...(reconnect && { opacity: 0.3 }),
    },

    '& > .name': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.normal,
      fontWeight: 600,
      ...(reconnect && { opacity: 0.3 }),
    },
  },
}));

const styles = {
  addAccountContainer: css({
    borderTop: THEME.box.borders.gray.main,

    '& > button': {
      border: 'none',
      fontSize: THEME.font.sizes.subHeading,
      fontWeight: 600,

      '&:hover': {
        border: 'none',
      },
    },
  }),
  contentContainer: css({
    background: THEME.colors.white,
    boxShadow: THEME.box.shadows.outer,

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      borderRadius: THEME.box.borderRadius.l,
      overflow: 'hidden',
    },
  }),
  dialogDisconnect: css({
    color: THEME.colors.black.main,
    padding: '40px 16px 32px 16px',

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      padding: '40px 24px 32px 24px',
    },

    '& > .title': {
      alignItems: 'flex-start',
      display: 'flex',
      fontSize: THEME.font.sizes.heading,
      fontWeight: 600,
      gap: THEME.box.gaps.xs,
      marginBottom: 12,
    },
    '& > .description': {
      fontSize: THEME.font.sizes.normal,
      marginBottom: 24,
    },
    '& > .profile': {
      alignItems: 'center',
      border: THEME.box.borders.gray.main,
      borderRadius: THEME.box.borderRadius.m,
      display: 'flex',
      gap: THEME.box.gaps.l,
      padding: '12px 16px',
      '& > div:nth-of-type(1)': {
        position: 'relative',
        width: 'fit-content',
      },
      '& > div:nth-of-type(2)': {
        color: THEME.font.colors.black.main,
        display: 'grid',
        fontSize: THEME.font.sizes.subordinate,
        '& > div': {
          fontSize: THEME.font.sizes.normal,
          fontWeight: 600,
        },
      },
    },
  }),
  header: css({
    alignItems: 'center',
    color: THEME.font.colors.black.main,
    display: 'flex',
    fontSize: THEME.font.sizes.normal,
    fontWeight: 600,
    gap: THEME.box.gaps.m,
    padding: 16,

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      padding: '16px 24px',
    },
  }),
  socialAccountContainer: css({
    borderTop: THEME.box.borders.gray.main,
    padding: 16,

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      padding: '16px 24px',
    },
  }),
};

export default SocialAccountConnector;
