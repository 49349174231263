import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Navigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { css } from '@emotion/react';
import { eventTypes, sendAmplitudeEvent } from '@src/amplitude';
import { ErrorMessage } from '@src/components/atoms';
import { BackNavigatorV2, ListLoading, ThemeButton } from '@src/components/molecules';
import { SelectV2, TextFormV2 } from '@src/components/shared';
import {
  useAllBankBranchesForInfluencerLazyQuery,
  useAllBanksForInfluencerQuery,
  useAllCitiesForInfluencerQuery,
  useInfluencerPaymentInformationQuery,
  useInfluencerProfileV2Query,
  useUpdatePaymentInformationMutation,
} from '@src/graphql/hooks';
import { MENA_COUNTRIES } from '@src/libs/constant';
import { getBankOptions, getOptions } from '@src/libs/functions';
import { useAuthData, useDeepCompareEffect, usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { mainBlack } from '@src/libs/palette';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import { JPPaymentInformationSchema, PaymentInformationSchema } from '@src/libs/validation';
import { toolbarBackState, useRecoil } from '@src/recoilAtoms';
import { ROUTES } from '@src/shared/routes';

export interface Information {
  accountName: string;
  accountNumber: string;
  address: string;
  bankId: string;
  branchId: string;
  branchName: string;
  cityId: string;
  email: string;
  fullName: string;
  iban: string;
  postalCode: string;
  subject: string;
  swiftCode: string;
}

const BankAccount = () => {
  const { isAvailableCountry, limitedLogin, userId } = useAuthData();
  if (!isAvailableCountry) {
    return <Navigate to={ROUTES.MY_PAGE} />;
  }

  const { recoilState: toolbarState, setRecoilState: setToolbarState } = useRecoil(toolbarBackState);
  const { isMobileView } = usePageLayout();
  const { enqueueSnackbar, navigate, t } = useQueryHelper();
  const [getAllBankBranchesForInfluencer, { data: dataAllBankBranches }] = useAllBankBranchesForInfluencerLazyQuery();
  const { data: dataAllBanks, loading: loadingAllBanks } = useAllBanksForInfluencerQuery();
  const { data: dataAllCities, loading: loadingAllCities } = useAllCitiesForInfluencerQuery();
  const { data: dataPaymentInformation, loading: loadingPaymentInformation } = useInfluencerPaymentInformationQuery();
  const { data: dataInfluencer, loading: loadingInfluencer } = useInfluencerProfileV2Query({
    fetchPolicy: 'no-cache',
    variables: { pk: Number(userId) },
  });
  const [updatePaymentInformation] = useUpdatePaymentInformationMutation();

  const allBanks = getBankOptions(dataAllBanks?.allBanksForInfluencer);
  const allBankBranches = getOptions(dataAllBankBranches?.allBankBranchesForInfluencer);
  const allCities = getOptions(dataAllCities?.allCitiesForInfluencer);
  const backPath = toolbarState.previousPath || ROUTES.MY_PAGE;
  const country = dataInfluencer?.influencerProfileV2?.country;
  const hasBranchId = allBanks.some(({ hasBranches }) => hasBranches);
  const isLoading = loadingAllBanks || loadingAllCities || loadingInfluencer || loadingPaymentInformation;
  const isJPInfluencer = country?.id === 'JP';
  const isTHInfluencer = country?.id === 'TH';
  const isMenaInfluencer = !!MENA_COUNTRIES.find(menaCountry => menaCountry.value === country?.id);
  const paymentInformation = dataPaymentInformation?.influencerPaymentInformation;

  const subjectOptions = [
    { value: '普通', label: '普通' },
    { value: '当座', label: '当座' },
    { value: '貯蓄', label: '貯蓄' },
  ];

  const defaultValues = {
    accountName: paymentInformation?.accountName || '',
    accountNumber: paymentInformation?.accountNumber || '',
    address: paymentInformation?.address || '',
    bankId: paymentInformation?.bankId?.toString() || '',
    branchId: paymentInformation?.branchId?.toString() || '',
    branchName: paymentInformation?.branchName || '',
    cityId: paymentInformation?.cityId?.toString() || '',
    email: dataPaymentInformation?.influencerPaymentInformation?.paymentEmail || '',
    fullName: paymentInformation?.fullName || '',
    hasBranchId,
    iban: paymentInformation?.iban || '',
    isMenaInfluencer,
    postalCode: paymentInformation?.postalCode || '',
    subject: paymentInformation?.subject || '',
    swiftCode: paymentInformation?.swiftCode || '',
  };

  const onSubmit = async (values: Information) => {
    const {
      accountName,
      accountNumber,
      address,
      bankId,
      branchId,
      branchName,
      cityId,
      email,
      fullName,
      iban,
      postalCode,
      subject,
      swiftCode,
    } = values;

    try {
      await updatePaymentInformation({
        variables: {
          input: {
            accountName,
            accountNumber,
            address,
            bankId: Number(bankId),
            branchId: branchId ? Number(branchId) : null,
            branchName,
            cityId: cityId ? Number(cityId) : null,
            email,
            fullName,
            iban,
            postalCode,
            subject,
            swiftCode,
          },
        },
      });
      enqueueSnackbar(t('succeededInUpdating'), { variant: 'success' });
      sendAmplitudeEvent(eventTypes.editBankAccount);
      if (toolbarState.previousPath) {
        navigate(backPath);
      }
    } catch (err) {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    }
  };

  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    register,
    reset,
    setValue,
    watch,
  } = useForm<Information>({
    defaultValues,
    resolver: yupResolver(isJPInfluencer ? JPPaymentInformationSchema : PaymentInformationSchema),
  });
  const [bankId, branchId, cityId, subject] = watch(['bankId', 'branchId', 'cityId', 'subject']);

  useEffect(() => {
    setToolbarState({ ...toolbarState, backMethod: () => navigate(backPath) });

    return () => {
      setToolbarState({ ...toolbarState, backMethod: null, previousPath: null });
    };
  }, []);

  useEffect(() => {
    getAllBankBranchesForInfluencer({
      variables: {
        bankId: Number(bankId),
      },
    });
  }, [bankId]);

  useDeepCompareEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  return (
    <form css={styles.container} onSubmit={handleSubmit(onSubmit)}>
      {!isMobileView && (
        <BackNavigatorV2
          actionItem={
            <div>
              <ThemeButton
                css={{ borderRadius: 9, height: 40, width: 226 }}
                customPalette={mainBlack}
                disabled={isLoading || isSubmitting || limitedLogin}
                text="Save"
                type="submit"
              />
            </div>
          }
          backPath={backPath}
          title="Bank Account"
        />
      )}
      <div css={styles.contentContainer}>
        <div css={styles.description}>
          {t('Annotation.To receive payments, please fill in and register the bank account information below')}
        </div>
        <div css={styles.inputContainer}>
          {isLoading ? (
            <ListLoading />
          ) : isJPInfluencer ? (
            <>
              <div>
                <TextFormV2
                  disabled={limitedLogin}
                  error={!!errors.fullName}
                  isRequired
                  title="Full Name"
                  placeholder={t('TextForm.Full Name')}
                  {...register('fullName')}
                />
                <ErrorMessage message={errors.fullName?.message} />
              </div>

              <div>
                <SelectV2
                  disabled={limitedLogin}
                  error={!!errors.bankId}
                  isRequired
                  options={allBanks}
                  title="Bank Name"
                  value={bankId}
                  onChange={value => setValue('bankId', value)}
                />
                <ErrorMessage message={errors.bankId?.message} />
              </div>

              <div>
                <SelectV2
                  disabled={limitedLogin}
                  error={!!errors.branchId}
                  isRequired
                  options={allBankBranches}
                  title="Branch"
                  value={branchId}
                  onChange={value => setValue('branchId', value)}
                />
                <ErrorMessage message={errors.branchId?.message} />
              </div>

              <div>
                <SelectV2
                  disabled={limitedLogin}
                  error={!!errors.subject}
                  isRequired
                  options={subjectOptions}
                  title="Subject"
                  value={subject || ''}
                  onChange={value => setValue('subject', value)}
                />
                <ErrorMessage message={errors.subject?.message} />
              </div>

              <div>
                <TextFormV2
                  disabled={limitedLogin}
                  error={!!errors.accountNumber}
                  isRequired
                  title="Account Number"
                  placeholder={t('TextForm.Account Number')}
                  type="number"
                  {...register('accountNumber')}
                />
                <ErrorMessage message={errors.accountNumber?.message} />
              </div>

              <div>
                <TextFormV2
                  disabled={limitedLogin}
                  error={!!errors.accountName}
                  isRequired
                  title="Account Name"
                  placeholder={t('TextForm.Account Name')}
                  {...register('accountName')}
                />
                <ErrorMessage message={errors.accountName?.message} />
              </div>
            </>
          ) : isMenaInfluencer ? (
            <>
              <div>
                <TextFormV2
                  disabled={limitedLogin}
                  error={!!errors.accountName}
                  isRequired
                  title="Account Holder Name"
                  placeholder={t('TextForm.Account Holder Name')}
                  {...register('accountName')}
                />
                <ErrorMessage message={errors.accountName?.message} />
              </div>

              <div>
                <TextFormV2
                  disabled={limitedLogin}
                  error={!!errors.email}
                  isRequired
                  title="Payment Email Address"
                  placeholder={t('TextForm.Payment Email Address')}
                  {...register('email')}
                />
                <ErrorMessage message={errors.email?.message} />
              </div>

              <div>
                <TextFormV2
                  disabled={limitedLogin}
                  error={!!errors.swiftCode}
                  isRequired
                  title="Swift Code"
                  placeholder={t('TextForm.Swift Code')}
                  {...register('swiftCode')}
                />
                <ErrorMessage message={errors.swiftCode?.message} />
              </div>

              <div>
                <SelectV2
                  disabled={limitedLogin}
                  error={!!errors.bankId}
                  isRequired
                  options={allBanks}
                  title="Bank Name"
                  value={bankId || ''}
                  onChange={value => setValue('bankId', value)}
                />
                <ErrorMessage message={errors.bankId?.message} />
              </div>

              <div>
                <TextFormV2
                  disabled={limitedLogin}
                  error={!!errors.branchName}
                  isRequired
                  title="Branch Name"
                  placeholder={t('TextForm.Branch Name')}
                  {...register('branchName')}
                />
                <ErrorMessage message={errors.branchName?.message} />
              </div>

              <div>
                <TextFormV2
                  disabled={limitedLogin}
                  error={!!errors.accountNumber}
                  isRequired
                  title="Account Number"
                  placeholder={t('TextForm.Account Number')}
                  type="number"
                  {...register('accountNumber')}
                />
                <ErrorMessage message={errors.accountNumber?.message} />
              </div>

              <div>
                <TextFormV2
                  disabled={limitedLogin}
                  error={!!errors.iban}
                  isRequired
                  title="IBAN"
                  placeholder={t('TextForm.IBAN')}
                  {...register('iban')}
                />
                <ErrorMessage message={errors.iban?.message} />
              </div>
            </>
          ) : (
            <>
              <div>
                <TextFormV2
                  disabled={limitedLogin}
                  error={!!errors.accountName}
                  isRequired
                  title={isTHInfluencer ? 'Account Holder Name TH' : 'Account Holder Name(Full Name)'}
                  placeholder={t('TextForm.Account Holder Name(Full Name)')}
                  {...register('accountName')}
                />
                <ErrorMessage message={errors.accountName?.message} />
              </div>

              <div>
                <TextFormV2
                  disabled={limitedLogin}
                  error={!!errors.email}
                  isRequired
                  title="Payment Email Address"
                  placeholder={t('TextForm.Payment Email Address')}
                  {...register('email')}
                />
                <ErrorMessage message={errors.email?.message} />
              </div>

              <div>
                <SelectV2
                  disabled={limitedLogin}
                  error={!!errors.bankId}
                  isRequired
                  options={allBanks}
                  title="Bank Name"
                  value={bankId || ''}
                  onChange={value => setValue('bankId', value)}
                />
                <ErrorMessage message={errors.bankId?.message} />
              </div>

              <div>
                <TextFormV2
                  disabled={limitedLogin}
                  error={!!errors.accountNumber}
                  isRequired
                  title="Account Number"
                  placeholder={t('TextForm.Account Number')}
                  type="number"
                  {...register('accountNumber')}
                />
                <ErrorMessage message={errors.accountNumber?.message} />
              </div>

              <div>
                <TextFormV2
                  disabled={limitedLogin}
                  error={!!errors.address}
                  isRequired
                  title="Your Address"
                  placeholder={t('TextForm.Your Address')}
                  {...register('address')}
                />
                <ErrorMessage message={errors.address?.message} />
              </div>

              <div>
                <SelectV2
                  disabled={limitedLogin}
                  error={!!errors.cityId}
                  isRequired
                  options={allCities}
                  title="City"
                  value={cityId || ''}
                  onChange={value => setValue('cityId', value)}
                />
                <ErrorMessage message={errors.cityId?.message} />
              </div>

              <div>
                <TextFormV2
                  disabled={limitedLogin}
                  error={!!errors.postalCode}
                  isRequired
                  title="Postal Code"
                  placeholder={t('TextForm.Postal Code')}
                  {...register('postalCode')}
                />
                <ErrorMessage message={errors.postalCode?.message} />
              </div>
            </>
          )}

          {isMobileView && (
            <ThemeButton
              css={{ borderRadius: 9, height: 48 }}
              customPalette={mainBlack}
              disabled={isLoading || isSubmitting || limitedLogin}
              text="Save"
              type="submit"
            />
          )}
        </div>
      </div>
    </form>
  );
};

const styles = {
  container: css({
    padding: '24px 16px',
  }),
  contentContainer: css({
    [`@media (min-width: ${ViewportType.TABLET}PX)`]: {
      padding: 24,
    },
  }),
  description: css({
    color: THEME.font.colors.black.main,
    fontSize: THEME.font.sizes.normal,
    marginBottom: 16,

    ['@media (min-width: ${ViewportType.TABLET}px)']: {
      marginBottom: 20,
    },
  }),
  inputContainer: css({
    background: THEME.colors.white,
    borderRadius: 15,
    boxShadow: THEME.box.shadows.outer,
    display: 'grid',
    gap: THEME.box.gaps.xxl,
    padding: '24px 16px',
  }),
};

export default BankAccount;
