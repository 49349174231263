import React from 'react';
import { css } from '@emotion/react';
import { Logo } from '@src/components/atoms';
import Footer from '@src/components/organisms/LayoutV2/Footer';
import { usePageLayout } from '@src/libs/hooks';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import Form from './Form';

const PasswordRegister = () => {
  const { isMobileView } = usePageLayout();

  return (
    <div css={styles.container}>
      {!isMobileView && <Logo width="187px" />}
      <Form />
      {!isMobileView && <Footer />}
    </div>
  );
};

const styles = {
  container: css({
    display: 'grid',
    justifyItems: 'center',

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      alignContent: 'center',
      gap: THEME.box.gaps.l,
      height: '100%',
      justifyItems: 'unset',
      justifyContent: 'center',
    },
  }),
};

export default PasswordRegister;
