import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { yupResolver } from '@hookform/resolvers/yup';
import { Empty, Icomoon, Icon } from '@src/components/atoms';
import Popover, { MenuItem } from '@src/components/atoms/Popover';
import { ListLoading, ThemeButton } from '@src/components/molecules';
import Dialog, { blackCircleCloseStyles, smallButtonStyles } from '@src/components/molecules/Dialog';
import { getActivityList } from '@src/components/shared/FanActivity/helper';
import useStateHandler from '@src/components/shared/FanActivity/ActivityTimeline/useStateHandler';
import ActivityRow from '@src/components/shared/FanActivity/ActivityTimeline/ActivityRow';
import { useGetCreatorStaffsQuery, useGetFanActivitiesQuery } from '@src/graphql/hooks';
import { LIMIT, LIMIT_100 } from '@src/libs/constant';
import { usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { THEME } from '@src/libs/theme';
import { FanActivityCommentSchema } from '@src/libs/validation';
import { generatePath, ROUTES } from '@src/shared/routes';
import AddCommentInput, { FormValues } from './AddCommentInput';

const Activity = () => {
  const { params, t } = useQueryHelper();
  const { isMobileView } = usePageLayout();
  const { activeChat, fanId } = params;
  const { data: dataCreatorStaffs } = useGetCreatorStaffsQuery({
    variables: {
      input: {
        limit: LIMIT_100,
        offset: 0,
      },
    },
  });
  const { data, loading, fetchMore } = useGetFanActivitiesQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      input: {
        fanId: Number(fanId),
        offset: 0,
        limit: LIMIT,
      },
    },
  });
  const pathOptions = activeChat && fanId ? { activeChat, fanId } : undefined;

  const methods = useForm<FormValues>({
    defaultValues: { comment: '' },
    resolver: yupResolver(FanActivityCommentSchema),
  });

  const {
    activity,
    handleAddComment,
    handleClickMore,
    handleClickDelete,
    handleClickEdit,
    handleClose,
    handleDeleteConfirm,
    handleUpdateComment,
  } = useStateHandler(Number(fanId));

  const onClickViewMore = () => {
    fetchMore({
      variables: {
        input: { fanId: Number(fanId), offset: data?.getFanActivities?.activities.length || 0, limit: LIMIT },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev;
        }

        return {
          ...prev,
          getFanActivities: {
            total: fetchMoreResult.getFanActivities?.total || 0,
            __typename: 'GetFanActivitiesPayload',
            activities: [
              ...(prev.getFanActivities?.activities || []),
              ...(fetchMoreResult.getFanActivities?.activities || []),
            ],
          },
        };
      },
    });
  };

  const onSubmit = (information: FormValues) => {
    handleAddComment(information);
    methods.reset();
  };

  const activities = getActivityList(data?.getFanActivities?.activities || [], t);
  const creatorStaffs =
    dataCreatorStaffs?.getCreatorStaffs?.staffs.reduce<{ [key: string]: string }>(
      (prev, curr) => ({ ...prev, [curr.id]: curr.name }),
      {
        [dataCreatorStaffs?.getCreatorStaffs?.id]: dataCreatorStaffs?.getCreatorStaffs?.name,
      }
    ) || {};
  const total = data?.getFanActivities?.total || 0;

  return (
    <div css={styles.container}>
      {/* Delete confirmation dialog */}
      <Dialog
        cancelButtonProps={{
          styles: smallButtonStyles,
          onClick: handleClose,
        }}
        closeButtonProps={{
          styles: blackCircleCloseStyles,
        }}
        contentStyles={{ maxWidth: '400px' }}
        nextButtonProps={{
          styles: smallButtonStyles,
          text: 'Delete',
          theme: 'blue',
          onClick: handleDeleteConfirm,
        }}
        open={activity.showDeleteConfirmation}
        onClose={handleClose}
      >
        <div css={styles.dialogContent}>
          <div>{t('Confirmation required')}</div>
          <div>{t('Are you sure you want to delete the activity?')}</div>
        </div>
      </Dialog>

      {!isMobileView && (
        <>
          <div css={styles.navigatorContainer}>
            <Link to={generatePath(ROUTES.MY_PAGE_MESSAGE_CHAT_ACTIVE_ID_FAN_ID, pathOptions)}>
              <Icon>
                <Icomoon css={{ transform: 'rotate(90deg)' }} icon="arrow-down" size={10} />
              </Icon>
            </Link>
            <Icomoon icon="clock-deep-blue-light" size={24} />
            <div>{t('MenuTitle.Activity')}</div>
          </div>
        </>
      )}

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <AddCommentInput />
        </form>
      </FormProvider>

      <ContentContainer isMobileView={isMobileView}>
        {loading ? (
          <ListLoading />
        ) : activities.length > 0 ? (
          <div>
            {activities.map((item, index) => (
              <ActivityRow
                key={(item.activity || '') + index}
                created={item.created}
                activity={item.activity}
                activityType={item.activityType}
                commenter={item.commenterId ? creatorStaffs?.[item.commenterId] || '' : ''}
                showCommentInsideForm={activity.showCommentInsideForm && item.id === activity.id}
                onUpdate={handleUpdateComment}
                popoverNode={
                  <Popover
                    renderTrigger={
                      <ThemeButton
                        skipTranslation
                        prefixIcon="&middot;&middot;&middot;"
                        width="max-content"
                        onClick={() => handleClickMore(item.id)}
                        css={{
                          alignSelf: 'center',
                          border: 'none',

                          '.prefix-icon': {
                            fontSize: THEME.font.sizes.title,
                          },
                        }}
                      />
                    }
                  >
                    <div css={{ minWidth: '80px' }}>
                      <MenuItem onClick={handleClickEdit}>{t('Edit')}</MenuItem>
                      <MenuItem onClick={handleClickDelete}>{t('Delete')}</MenuItem>
                    </div>
                  </Popover>
                }
              />
            ))}
          </div>
        ) : (
          <Empty />
        )}
        {activities.length < total && (
          <div css={styles.showMoreContainer}>
            <ThemeButton text="More" width="max-content" onClick={onClickViewMore} />
          </div>
        )}
      </ContentContainer>
    </div>
  );
};

const ContentContainer = styled.div<{ isMobileView: boolean }>(({ isMobileView }) => ({
  display: 'grid',
  height: isMobileView ? '80vh' : '55vh',
  overflowY: 'auto',
  paddingTop: 18,
  ...(isMobileView && { paddingBottom: 52 }),
}));

const styles = {
  container: css({
    display: 'grid',
    position: 'relative',
  }),
  dialogContent: css({
    '& > div:nth-of-type(1)': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.heading,
      fontWeight: 600,
      lineHeight: '22px',
      marginBottom: 24,
    },

    '& > div:nth-of-type(2)': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.normal,
      marginBottom: 16,
    },
  }),
  navigatorContainer: css({
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: 16,
    gap: THEME.box.gaps.s,

    '& > a': {
      alignItems: 'center',
      backgroundColor: THEME.colors.white,
      borderRadius: 5,
      boxShadow: THEME.box.shadows.outer,
      display: 'grid',
      height: 32,
      justifyContent: 'center',
      width: 32,

      '& > i': {
        margin: 0,
      },
    },

    '& > div': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.subHeading,
      fontWeight: 600,
      marginLeft: 8,
    },
  }),
  showMoreContainer: css({
    background: 'linear-gradient(180deg, rgba(247, 248, 250, 0) 0%, #f7f8fa 49.4%)',
    display: 'flex',
    marginTop: -56,
    justifyContent: 'center',
    zIndex: 5,
  }),
};

export default Activity;
