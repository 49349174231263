import { css } from '@emotion/react';
import { NavLink } from 'react-router-dom';
import Dialog, { blackCircleCloseStyles, smallButtonStyles } from '@src/components/molecules/Dialog';
import { getCurrentPage } from '@src/components/molecules/Pager/helpers';
import { usePageLayout, useQueryHelper } from '@src/libs/hooks';
import React from 'react';
import { Icomoon } from '@src/components/atoms';
import Popover, { MenuItem } from '@src/components/atoms/Popover';
import { ThemeButton } from '@src/components/molecules';
import { useGetFormRespondentsCountQuery } from '@src/graphql/hooks';
import { THEME } from '@src/libs/theme';
import { generatePath, ROUTES } from '@src/shared/routes';
import FormRespondent from './FormRespondent';
import FormSummary from './FormSummary';
import FormIndividual from './FormIndividual';
import useStateHandler from './useStateHandler';

const Index = () => {
  const { matchPath, params, pathname, t } = useQueryHelper();
  const { isMobileView } = usePageLayout();
  const {
    deleteConfirmation,
    hideDeleteConfirmation,
    handleDeleteConfirm,
    handleClickDeleteAllResponses,
    handleClickDownloadResponses,
  } = useStateHandler();
  const currentPage = getCurrentPage();

  const { id } = params as { id: string };
  let onCloseMenu: () => void;

  const { data } = useGetFormRespondentsCountQuery({
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        id: Number(id),
      },
    },
  });

  const totalResponse = data?.getFormRespondentsCount?.total || 0;

  const renderContent = () => {
    if (matchPath(ROUTES.MY_PAGE_FORMS_EDIT_REPORT_RESPONDENT, pathname)) {
      return <FormRespondent currentPage={currentPage} total={totalResponse} />;
    } else if (
      [ROUTES.MY_PAGE_FORMS_EDIT_REPORT_INDIVIDUAL, ROUTES.MY_PAGE_FORMS_EDIT_REPORT_INDIVIDUAL_PAGE].find(path =>
        matchPath(path, pathname)
      )
    ) {
      return <FormIndividual totalResponse={totalResponse} />;
    } else {
      return <FormSummary />;
    }
  };

  return (
    <>
      <div css={styles.container}>
        <div css={styles.headingRow}>
          <p css={styles.responseCount}>{t('response', { count: totalResponse })}</p>

          <Popover
            renderTrigger={
              <ThemeButton
                css={{ padding: 0 }}
                width="32px"
                prefixIcon={
                  <span css={{ fontSize: '16px', transform: 'rotate(90deg)' }}>&middot;&middot;&middot;</span>
                }
              />
            }
            emitEvents={({ onClose }) => {
              onCloseMenu = onClose;
            }}
          >
            <div>
              <MenuItem onClick={() => handleClickDownloadResponses(onCloseMenu)} css={{ gap: '8px', display: 'flex' }}>
                <Icomoon icon="import" size={16} />
                {t('Download responses (csv)')}
              </MenuItem>
              <MenuItem
                onClick={() => handleClickDeleteAllResponses(onCloseMenu)}
                css={{ gap: '8px', display: 'flex' }}
              >
                <Icomoon icon="trash-outlined" size={16} />
                {t('Delete all responses')}
              </MenuItem>
            </div>
          </Popover>
        </div>
        <div css={styles.navWrapper}>
          <NavLink
            className={({ isActive }) => (isActive ? 'selected' : '')}
            end
            to={generatePath(ROUTES.MY_PAGE_FORMS_EDIT_REPORT, { id })}
          >
            {t('Summary')}
          </NavLink>
          {!isMobileView ? (
            <>
              <NavLink
                className={({ isActive }) => (isActive ? 'selected' : '')}
                end
                to={generatePath(ROUTES.MY_PAGE_FORMS_EDIT_REPORT_RESPONDENT, { id })}
              >
                {t('Respondent')}
              </NavLink>
              <NavLink
                className={({ isActive }) => (isActive ? 'selected' : '')}
                end
                to={generatePath(ROUTES.MY_PAGE_FORMS_EDIT_REPORT_INDIVIDUAL, { id })}
              >
                {t('Individual')}
              </NavLink>
            </>
          ) : null}
        </div>
        {renderContent()}
      </div>

      <Dialog
        closeButtonProps={{
          styles: blackCircleCloseStyles,
        }}
        cancelButtonProps={{
          styles: smallButtonStyles,
          onClick: hideDeleteConfirmation,
        }}
        contentStyles={{ borderRadius: 3, maxWidth: 648 }}
        nextButtonProps={{
          styles: smallButtonStyles,
          text: 'Delete',
          theme: 'blue',
          onClick: handleDeleteConfirm,
        }}
        open={deleteConfirmation}
        onClose={hideDeleteConfirmation}
      >
        <p css={styles.heading}>{t('Are you sure you want to permanently delete this?')}</p>
        <p css={styles.bodyText}>
          {t('You might lost response data forever Before continuing, export your responses')}
        </p>
        <p css={styles.warning}>{t('Do you want to delete this question and lost responses?')}</p>
      </Dialog>
    </>
  );
};

const styles = {
  container: css({
    backgroundColor: THEME.colors.white,
    padding: 24,
  }),
  headingRow: css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 16,
  }),
  button: css({
    width: 32,
    height: 32,
    border: THEME.box.borders.gray.main,
    borderRadius: 3,
    lineHeight: '30px',
    marginLeft: 8,
    fontWeight: 600,

    i: {
      marginRight: 0,
    },
  }),
  responseCount: css({
    fontWeight: 600,
    fontSize: THEME.font.sizes.subHeading,
    lineHeight: '100%',
    color: THEME.font.colors.black.main,
  }),
  navWrapper: css({
    display: 'flex',
    boxShadow: THEME.box.shadows.outer,
    marginBottom: 24,

    '& > a': {
      minWidth: 70,
      color: THEME.font.colors.black.main,
      padding: '0 8px 14px',
      fontWeight: 'normal',
      fontSize: THEME.font.sizes.normal,
      textAlign: 'center',
      marginRight: 16,

      '&.selected': {
        borderBottom: '4px solid #3892e5',
        fontWeight: 600,
        fontSize: THEME.font.sizes.normal,
      },
    },
  }),
  heading: css({
    marginBottom: 24,
    fontWeight: 600,
    fontSize: THEME.font.sizes.heading,
    color: THEME.font.colors.black.main,
  }),
  bodyText: css({
    fontSize: THEME.font.sizes.normal,
    color: THEME.font.colors.black.main,
    marginBottom: 16,
  }),
  warning: css({
    fontSize: THEME.font.sizes.normal,
    color: THEME.font.colors.error,
  }),
};

export default Index;
