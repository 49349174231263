import React, { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Icomoon, RadioIcon } from '@src/components/atoms';
import { ThemeButton } from '@src/components/molecules';
import Dialog, { smallButtonStyles } from '@src/components/molecules/Dialog';
import { useUpdateFormStatusMutation } from '@src/graphql/hooks';
import { useCopy, useQueryHelper } from '@src/libs/hooks';
import { THEME } from '@src/libs/theme';
import { FormStatus } from '@src/__generated__/globalTypes';
import { mainBlack } from '@src/libs/palette';
import { TextForm } from '../TextForm';

interface ShareButtonProps {
  hash: string;
  id: string;
  status: FormStatus;
}

const ShareButton = ({ hash, id, status }: ShareButtonProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const isShareOptions = [FormStatus.PRIVATE, FormStatus.PUBLIC].includes(status);
  const [selectedOption, setSelectedOption] = useState<FormStatus>(isShareOptions ? status : FormStatus.PRIVATE);
  const { enqueueSnackbar, t } = useQueryHelper();
  const { handleCopyHasText, isCopying } = useCopy();
  const formUrl = `${origin}/form/${hash}`;
  const options = [
    { description: 'Annotation.View only by me', value: FormStatus.PRIVATE },
    { description: 'Annotation.Anyone can access the internet', value: FormStatus.PUBLIC },
  ];

  useEffect(() => {
    setSelectedOption(isShareOptions ? status : FormStatus.PRIVATE);
  }, [status]);

  const [updateFormStatus] = useUpdateFormStatusMutation({
    refetchQueries: ['GetForm'],
    onCompleted: () => {
      enqueueSnackbar(t('succeededInUpdating'), { variant: 'success' });
      setIsDialogOpen(false);
    },
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  const onClickSave = () => {
    setIsUpdating(true);
    updateFormStatus({
      variables: {
        input: {
          id: Number(id),
          status: selectedOption,
        },
      },
    }).finally(() => setIsUpdating(false));
  };

  return (
    <div>
      <Dialog
        cancelButtonProps={{
          styles: smallButtonStyles,
          onClick: () => setIsDialogOpen(false),
        }}
        contentStyles={styles.dialog}
        nextButtonProps={{
          disabled: isUpdating,
          styles: smallButtonStyles,
          text: 'Save',
          theme: 'blue',
          onClick: onClickSave,
        }}
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
      >
        <div css={styles.dialogContent}>
          <div>{t('Dialog.Share the form')}</div>
          <div>{t('Public Settings')}</div>
          <div css={styles.dialogOptionsContainer}>
            {options.map(option => {
              const { description, value } = option;
              const isActive = value === selectedOption;

              return (
                <Option isActive={isActive} key={value}>
                  <div>
                    <RadioIcon isChecked={isActive} onClick={() => setSelectedOption(value)} />
                  </div>
                  <div>
                    <div>
                      <div>{t(value)}</div>
                      <div>{t(description)}</div>
                    </div>
                  </div>
                </Option>
              );
            })}
          </div>

          {selectedOption === FormStatus.PUBLIC && (
            <div css={styles.publicLinkContainer}>
              <div>{t('Annotation.Get the link')}</div>
              <div>
                <TextForm css={styles.textForm} disabled placeholder="" value={formUrl} />
                <ThemeButton
                  disabled={isCopying}
                  text="Copy"
                  width="man-content"
                  onClick={() => handleCopyHasText(formUrl)}
                />
              </div>
            </div>
          )}
        </div>
      </Dialog>
      <ThemeButton
        disabled={!id}
        prefixIcon={<Icomoon color="#fff" icon="share" />}
        text="Share"
        width="max-content"
        customPalette={mainBlack}
        onClick={() => setIsDialogOpen(true)}
        css={{
          '.btn-text': {
            padding: '0 8px',
          },
        }}
      />
    </div>
  );
};

const Option = styled.div<{ isActive: boolean }>(({ isActive }) => ({
  backgroundColor: isActive ? '#eaf5ff' : '#fff',
  border: `1px solid ${isActive ? '#3892e5' : '#dee5ec'}`,
  borderRadius: 5,
  display: 'flex',
  flexWrap: 'wrap',
  height: 64,
  width: 'fill-available',

  '& > div:nth-of-type(1)': {
    alignItems: 'center',
    display: 'flex',
    flexBasis: '15%',
    justifyContent: 'center',
  },

  '& > div:nth-of-type(2)': {
    alignItems: 'center',
    display: 'flex',
    flexBasis: '85%',

    '& > div': {
      '& > div:nth-of-type(1)': {
        color: THEME.font.colors.black.main,
        fontSize: THEME.font.sizes.normal,
        fontWeight: 600,
      },

      '& > div:nth-of-type(2)': {
        color: THEME.font.colors.gray.main,
        fontSize: THEME.font.sizes.subordinate,
      },
    },
  },
}));

const styles = {
  dialog: {
    borderRadius: '5px',
    boxShadow: THEME.box.shadows.outer,
    maxWidth: 648,
    padding: 0,
    width: '90%',
  },
  dialogContent: css({
    padding: '0 8px 0 8px',

    '& > div:nth-of-type(1)': {
      color: '##27313b',
      fontSize: THEME.font.sizes.heading,
      fontWeight: 600,
      marginBottom: 24,
    },

    '& > div:nth-of-type(2)': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.normal,
      fontWeight: 600,
      marginBottom: 16,
    },
  }),
  dialogOptionsContainer: css({
    display: 'flex',

    '& > div:nth-of-type(1)': {
      marginRight: 8,
    },

    '& > div:nth-of-type(2)': {
      marginLeft: 8,
    },
  }),
  publicLinkContainer: css({
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: 24,

    '& > div:nth-of-type(1)': {
      color: THEME.font.colors.black.main,
      display: 'flex',
      flexBasis: '100%',
      fontSize: THEME.font.sizes.normal,
      fontWeight: 600,
      marginBottom: 16,
    },

    '& > div:nth-of-type(2)': {
      display: 'flex',
      flexBasis: '100%',
      flexWrap: 'wrap',

      '& > div:nth-of-type(1)': {
        display: 'flex',
        flex: 1,
        marginRight: 8,

        '& > div': {
          width: 'fill-available',
        },
      },
    },
  }),
  shareBtn: css({
    borderRadius: 3,
    height: 32,
    width: 96,
  }),
  textForm: css({
    '& > label': {
      fontSize: THEME.font.sizes.normal,
      fontWeight: 600,
    },

    '& input': {
      borderRadius: 3,
      height: 32,
    },
  }),
};

export default ShareButton;
