import isEqual from 'lodash/isEqual';
import { useRecoilCallback } from 'recoil';
import { layoutState, useRecoil, LayoutStateProps } from '@src/recoilAtoms';

type Params = Partial<Record<keyof LayoutStateProps, boolean>>;

interface Queue {
  params: Params;
  resolve: () => void;
}

const useGlobalLayout = () => {
  const { recoilState } = useRecoil(layoutState);

  // multiple components might called setGlobalLayout in useEffect simultaneously
  // to avoid conflicts, the update function is queued to ensure the state is updated one by one
  const updateGlobalLayout = useRecoilCallback(({ set }) => {
    const queue: Queue[] = [];
    let isProcessing = false;

    const processQueue = async () => {
      if (isProcessing) {
        return;
      }
      isProcessing = true;

      while (queue.length > 0) {
        const { params, resolve } = queue.shift()!;

        // Ensure we always work with the latest state
        set(layoutState, prevState => {
          const newState = { ...prevState, ...params };
          if (!isEqual(prevState, newState)) {
            return newState;
          }

          return prevState;
        });
        resolve();
      }
      isProcessing = false;
    };

    return (params: Params) =>
      new Promise<void>(resolve => {
        queue.push({ params, resolve });
        processQueue();
      });
  });

  return {
    ...recoilState,
    setGlobalLayout: updateGlobalLayout,
  };
};

export default useGlobalLayout;
