import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import { CheckBox, ErrorMessage } from '@src/components/atoms';
import { ThemeButton } from '@src/components/molecules';
import { Label, SelectV2, TextFormV2 } from '@src/components/shared';
import { useAuthData, usePageLayout, useUuumInfluencerView } from '@src/libs/hooks';
import { LANGUAGE_OPTIONS } from '@src/libs/i18n';
import { mainBlack } from '@src/libs/palette';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import { ROUTES } from '@src/shared/routes';
import { DisplayLanguage, UserRoles } from '@src/__generated__/globalTypes';

export const LanguageMapping = {
  ch: DisplayLanguage.SIMPLIFIED_CHINESE,
  id: DisplayLanguage.BAHASA,
  en: DisplayLanguage.ENGLISH,
  ja: DisplayLanguage.JAPANESE,
  km: DisplayLanguage.CAMBODIA,
  my: DisplayLanguage.MYANMAR,
  th: DisplayLanguage.THAI,
  tw: DisplayLanguage.TRADITIONAL_CHINESE,
  vi: DisplayLanguage.VIETNAMESE,
  ar: DisplayLanguage.ARABIC,
  ko: DisplayLanguage.KOREAN,
} as Record<string, DisplayLanguage>;

export interface PersonalInformation {
  displayLanguage: DisplayLanguage;
  notificationSetting: boolean | null;
  email: string;
  name: string;
  password: string;
  passwordConfirm: string;
}

const YourAccount = () => {
  const { isYoutubeCmsPath } = usePageLayout();
  const { t } = useTranslation();
  const { limitedLogin, role } = useAuthData();
  const { isUuumView } = useUuumInfluencerView();
  const {
    formState: { errors, isSubmitting },
    register,
    setValue,
    watch,
  } = useFormContext<PersonalInformation>();
  const [displayLanguage, notificationSetting] = watch(['displayLanguage', 'notificationSetting']);
  const languageOptions = LANGUAGE_OPTIONS.map(language => ({
    value: LanguageMapping[language.value],
    label: language.label,
  }));

  return (
    <div css={styles.container}>
      <div css={styles.title}>{t('Title.Your Account')}</div>
      <div css={styles.description}>{t('Annotation.Edit your information and set notifications to receive')}</div>
      <div css={styles.contentContainer}>
        <div>
          <TextFormV2
            disabled={limitedLogin}
            error={!!errors.name}
            isRequired
            title="Name"
            placeholder="Naoya Kurita"
            {...register('name')}
          />
          <ErrorMessage message={errors.name?.message} />
        </div>

        <div>
          <TextFormV2
            disabled={limitedLogin}
            error={!!errors.email}
            isRequired
            title="Email Address"
            placeholder="take@anymindgroup.com"
            {...register('email')}
          />
          <ErrorMessage message={errors.email?.message} />
        </div>

        <div>
          <TextFormV2
            disabled={limitedLogin}
            error={!!errors.password}
            title="Password"
            placeholder=""
            type="password"
            {...register('password')}
          />
          <ErrorMessage message={errors.password?.message} />
        </div>

        <div>
          <TextFormV2
            disabled={limitedLogin}
            error={!!errors.passwordConfirm}
            title="Confirm Password"
            placeholder=""
            type="password"
            {...register('passwordConfirm')}
          />
          <ErrorMessage message={errors.passwordConfirm?.message} />
        </div>

        <div>
          <SelectV2
            disabled={limitedLogin}
            options={languageOptions}
            title="Display Language"
            value={displayLanguage || ''}
            onChange={value => setValue('displayLanguage', value as DisplayLanguage)}
          />
          <ErrorMessage message={errors.displayLanguage?.message} />
        </div>

        {!isUuumView && role === UserRoles.INFLUENCER && (
          <div>
            <Label title="Notification" />
            <div css={styles.checkboxContainer}>
              <CheckBox
                checked={!!notificationSetting}
                disabled={limitedLogin}
                onChange={checked => setValue('notificationSetting', checked)}
                label="Notify me of campaign information and payment"
              />
            </div>
          </div>
        )}

        <ThemeButton
          css={{ borderRadius: 9, height: 48 }}
          customPalette={mainBlack}
          disabled={isSubmitting || limitedLogin}
          text="Save"
          type="submit"
        />
      </div>

      {role === UserRoles.INFLUENCER && (
        <Link
          css={[styles.deleteBtn, limitedLogin && { pointerEvents: 'none' }]}
          to={isYoutubeCmsPath ? ROUTES.YOUTUBE_CMS_MY_PAGE_ACCOUNT_DELETE : ROUTES.MY_PAGE_ACCOUNT_DELETE}
        >
          {t('Button.Delete Account')}
        </Link>
      )}
    </div>
  );
};

const styles = {
  checkboxContainer: css({
    '& label': {
      fontSize: THEME.font.sizes.subHeading,
    },
  }),
  container: css({
    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      padding: '0 24px',
    },
  }),
  contentContainer: css({
    background: THEME.colors.white,
    borderRadius: 15,
    boxShadow: THEME.box.shadows.outer,
    display: 'grid',
    gap: THEME.box.gaps.xxl,
    marginBottom: 16,
    padding: '24px 16px',
  }),
  deleteBtn: css({
    color: THEME.font.colors.gray.main,
    fontSize: THEME.font.sizes.normal,

    '&:hover': {
      opacity: 0.7,
    },
  }),
  description: css({
    color: THEME.font.colors.black.main,
    fontSize: THEME.font.sizes.normal,
    marginBottom: 16,
  }),
  title: css({
    color: THEME.font.colors.black.main,
    fontSize: THEME.font.sizes.heading,
    fontWeight: 600,
    marginBottom: 8,
  }),
};

export default YourAccount;
