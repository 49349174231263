import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Icomoon, Image } from '@src/components/atoms';
import { CURRENCY_SYMBOLS } from '@src/libs/constant';
import { formatNumberWithCommas, formatPercent } from '@src/libs/format';
import { countDownDays, isDateWithinTheRange, isNumber } from '@src/libs/functions';
import { switchImage } from '@src/libs/socialMedia';
import { THEME } from '@src/libs/theme';
import { generatePath, ROUTES } from '@src/shared/routes';
import { CampaignsForSearchJobPayload, CampaignSocialMediaType, CampaignType } from '@src/__generated__/globalTypes';
import { ViewportType } from '@src/libs/types';

interface CardProps {
  campaign: Omit<CampaignsForSearchJobPayload, 'status'>;
}

const Card = ({
  campaign: {
    commissionRate,
    createdDate,
    currency,
    endDate,
    id,
    preLaunchDate,
    revenuePerClick,
    revenuePerComment,
    revenuePerLike,
    revenuePerPost,
    revenuePerShare,
    revenuePerView,
    socialMedias,
    startDate,
    thumbnail,
    title,
    type,
  },
}: CardProps) => {
  const { t } = useTranslation();
  const currencySymbol = currency ? CURRENCY_SYMBOLS[currency] : '';
  const daysLeft = endDate ? countDownDays(endDate) : 0;
  const isNewCampaign = createdDate && isDateWithinTheRange(createdDate, 5);
  const isPreLaunchComingSoon = preLaunchDate && startDate && new Date(startDate) > new Date();
  const isTikTokSpecialCampaign = type === CampaignType.TIKTOK_SPECIAL;
  const isOtherPromotionMethods = !!socialMedias.find(sm => sm === CampaignSocialMediaType.OTHER);
  const isUnselectSocialMedia =
    !socialMedias.length || !!socialMedias.find(sm => sm === CampaignSocialMediaType.UNSELECT);
  const revenues = [
    ...(isNumber(revenuePerPost) ? [{ label: 'per Post', value: revenuePerPost }] : []),
    ...(isNumber(revenuePerLike) ? [{ label: 'per Like', value: revenuePerLike }] : []),
    ...(isNumber(revenuePerView) ? [{ label: 'per View', value: revenuePerView }] : []),
    ...(isNumber(revenuePerShare) ? [{ label: 'per Share', value: revenuePerShare }] : []),
    ...(isNumber(revenuePerComment) ? [{ label: 'per Comment', value: revenuePerComment }] : []),
    ...(isNumber(revenuePerClick) ? [{ label: 'per Click', value: revenuePerClick }] : []),
    ...(isNumber(commissionRate) ? [{ isPercentage: true, label: 'per Comm Rate', value: commissionRate }] : []),
  ];

  return (
    <Link
      css={styles.card}
      to={generatePath(
        type === CampaignType.AUTO_MANAGED
          ? ROUTES.FIND_JOBS_AUTO_MANAGED_ID
          : type === CampaignType.ENGAGEMENT
          ? ROUTES.MY_JOBS_CASTING_YOUR_JOB_ID
          : isTikTokSpecialCampaign
          ? ROUTES.FIND_JOBS_TIKTOK_SPECIAL_ID
          : ROUTES.FIND_JOBS_MARKETPLACE_ID,
        { id }
      )}
    >
      <div css={styles.imageContainer}>
        {isNewCampaign ? (
          <Tag color={THEME.colors.red.fe6565}>{t('New')}</Tag>
        ) : isPreLaunchComingSoon ? (
          <Tag color="#7dd996">{t('Coming soon')}</Tag>
        ) : daysLeft && daysLeft < 7 ? (
          <Tag color="#8C71fb">{t('days left', { days: daysLeft })}</Tag>
        ) : null}
        <Image className="image" src={thumbnail} />
      </div>
      <div css={styles.socialMediaContainer}>
        {isUnselectSocialMedia
          ? [
              CampaignSocialMediaType.INSTAGRAM,
              CampaignSocialMediaType.TWITTER,
              CampaignSocialMediaType.YOUTUBE,
              CampaignSocialMediaType.TIKTOK,
              CampaignSocialMediaType.FACEBOOK,
            ].map((socialMedia, index) => <Icomoon icon={switchImage({ socialMedia })} key={index} />)
          : socialMedias.map((socialMedia, index) => <Icomoon icon={switchImage({ socialMedia })} key={index} />)}
        {(isOtherPromotionMethods || isUnselectSocialMedia) && <div className="other">{t('Option.Other')}</div>}
      </div>
      <div css={styles.title}>{title}</div>
      <div css={styles.revenues}>
        {revenues.filter(Boolean).map(({ isPercentage, label, value }, index) => (
          <div key={index}>
            <label className="value">
              {isPercentage
                ? formatPercent(value)
                : `${currencySymbol}${formatNumberWithCommas(value, undefined, currency)}`}
            </label>
            <label className="label">{`/ ${t(`Option.${label}`)}`}</label>
          </div>
        ))}
      </div>
    </Link>
  );
};

const Tag = styled.div<{ color: string }>(({ color }) => ({
  background: color,
  borderBottomRightRadius: THEME.box.borderRadius.m,
  color: THEME.font.colors.white,
  fontSize: THEME.font.sizes.hint,
  fontWeight: 600,
  padding: '5px 8px',
  position: 'absolute',
  top: 0,

  [`@media (min-width: ${ViewportType.SMALL}px)`]: {
    fontSize: THEME.font.sizes.subordinate,
  },

  '[dir="rtl"] &': {
    borderBottomLeftRadius: THEME.box.borderRadius.m,
    borderBottomRightRadius: 'unset',
  },
}));

const styles = {
  card: css({
    alignContent: 'flex-start',
    display: 'grid',
    gap: THEME.box.gaps.s,
  }),
  imageContainer: css({
    borderRadius: THEME.box.borderRadius.m,
    overflow: 'hidden',
    position: 'relative',

    '& > .image': {
      '& > img': {
        aspectRatio: '16/9',
        objectFit: 'contain',
        width: '100%',
      },
    },
  }),
  revenues: css({
    display: 'grid',
    gap: THEME.box.gaps.xs,

    '& > div': {
      alignItems: 'center',
      display: 'flex',
      gap: THEME.box.gaps.xs,

      '& > .value': {
        color: THEME.font.colors.black.main,
        fontSize: THEME.font.sizes.subHeading,
        fontWeight: 500,

        [`@media (min-width: ${ViewportType.SMALL}px)`]: {
          fontSize: THEME.font.sizes.subTitle,
        },
      },

      '& > .label': {
        color: THEME.font.colors.gray.a8b4bf,
        fontSize: THEME.font.sizes.subordinate,
      },
    },
  }),
  socialMediaContainer: css({
    alignItems: 'center',
    display: 'flex',
    gap: THEME.box.gaps.xs,

    '& > .other': {
      border: THEME.box.borders.gray.c5d0da,
      borderRadius: THEME.box.borderRadius.xxl,
      color: THEME.font.colors.gray.main,
      fontSize: 8,
      fontWeight: 500,
      padding: '4px 6px',
    },
  }),
  title: css({
    color: THEME.font.colors.black.main,
    fontSize: THEME.font.sizes.hint,
    wordBreak: 'break-word',

    [`@media (min-width: ${ViewportType.SMALL}px)`]: {
      fontSize: THEME.font.sizes.subordinate,
    },
  }),
};

export default Card;
