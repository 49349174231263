import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { CheckBox, Switch } from '@src/components/atoms';
import { Dialog } from '@src/components/molecules';
import { useDimensions, usePageLayout } from '@src/libs/hooks';
import { THEME } from '@src/libs/theme';
import EditAccess, { defaultWidth } from '../TiktokInstructions/EditAccess';
import ManageAccess from '../TiktokInstructions/ManageAccess';
import * as SC from './StyledComponents';

interface DialogTikTokGrantPermissionProps {
  open: boolean;
  onClose: () => void;
}

const DialogTikTokGrantPermission = ({ open, onClose }: DialogTikTokGrantPermissionProps) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [confirmedNotification, setConfirmedNotification] = useState<boolean>(false);
  const { t } = useTranslation();
  const { isMobileView } = usePageLayout();
  const { width } = useDimensions(containerRef);
  const imageMinWidth = width / 2 - 40;
  const imageWidth = !isMobileView ? defaultWidth : Math.min(imageMinWidth, defaultWidth);
  const tiktokGrantPermissionSteps = [
    {
      description: <StepDescription>{t('Annotation.Click Edit access')}</StepDescription>,
      image: <EditAccess width={imageWidth} />,
    },
    {
      description: (
        <StepDescription>
          <div>{t('Annotation.Turn on toggle')}</div>
          <Switch css={styles.switch} checked color="#66dca0" />
        </StepDescription>
      ),
      image: <ManageAccess width={imageWidth} />,
    },
  ];

  return (
    <Dialog
      closeButtonProps={{
        styles: css({ display: 'none' }),
      }}
      closeOnDocumentClick={false}
      contentStyles={{
        backgroundColor: THEME.colors.white,
        borderRadius: 9,
        boxShadow: THEME.box.shadows.outer,
        height: isMobileView ? '100%' : 548,
        maxHeight: '90%',
        maxWidth: 648,
        width: '90%',
      }}
      footerNode={
        <div css={styles.dialogActionContainer}>
          <SC.StyledButton
            css={{ width: 'fill-available' }}
            disabled={!confirmedNotification}
            text="Close"
            onClick={onClose}
          />
          <div>{`*${t('Annotation.Enable button with checking a checkbox below')}`}</div>
        </div>
      }
      open={open}
      onClose={onClose}
    >
      <div css={{ padding: '16px 0' }} ref={containerRef}>
        <div css={styles.dialogTitle}>{t('Connecting TikTok account')}</div>
        <div css={styles.infoContainer}>
          <div>
            <ul>
              <li>{t('Annotation.Make sure that your TikTok account is public')}</li>
              <li>{t('Annotation.Make sure you will grant us by turning on the permission below')}</li>
            </ul>
          </div>
          <div>{t('Annotation.Read your public videos on TikTok')}</div>
          <div>{t('Annotation.TikTok’s access permission page')}</div>
          <div>
            {tiktokGrantPermissionSteps.map(({ description, image }, index) => (
              <div css={styles.stepContainer} key={index}>
                <div>{index + 1}</div>
                <div>{image}</div>
                <div>{description}</div>
              </div>
            ))}
          </div>
        </div>
        <div css={{ display: 'flex', justifyContent: 'center' }}>
          <CheckBox
            checked={confirmedNotification}
            label="I have confirmed this notification"
            onChange={setConfirmedNotification}
          />
        </div>
      </div>
    </Dialog>
  );
};

const StepDescription = styled.div({
  alignItems: 'center',
  color: THEME.font.colors.black.main,
  display: 'flex',
  fontSize: 11,
  fontWeight: 700,
  gap: THEME.box.gaps.xs,
});

const styles = {
  dialogActionContainer: css({
    borderTop: '1px solid #dee5ec',
    display: 'grid',
    gap: THEME.box.gaps.xs,
    padding: 16,

    '& > div': {
      color: THEME.font.colors.gray.main,
      fontSize: 11,
    },
  }),
  dialogTitle: css({
    color: THEME.font.colors.black.main,
    fontSize: THEME.font.sizes.heading,
    fontWeight: 600,
    marginBottom: 16,
  }),
  infoContainer: css({
    border: THEME.box.borders.gray.main,
    borderRadius: 5,
    display: 'grid',
    marginBottom: 32,
    padding: '24px 16px',

    '& > div:nth-of-type(1)': {
      color: THEME.font.colors.black.main,
      display: 'flex',
      fontSize: THEME.font.sizes.normal,
      gap: THEME.box.gaps.l,
      marginBottom: 8,

      '& > ul': {
        display: 'grid',
        gap: THEME.box.gaps.l,

        '& > li': {
          listStyle: 'disc',
          textAlign: 'left',
          whiteSpace: 'pre-line',
          margin: '0 16px',
        },
      },
    },

    '& > div:nth-of-type(2)': {
      borderBottom: '1px solid #eef3f7',
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.normal,
      fontWeight: 700,
      marginBottom: 16,
      paddingBottom: 16,
      textAlign: 'center',
      width: 'fill-available',
    },

    '& > div:nth-of-type(3)': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.normal,
      justifySelf: 'center',
      marginBottom: 24,
    },

    '& > div:nth-of-type(4)': {
      display: 'flex',
      gap: THEME.box.gaps.m,
      justifyContent: 'center',
    },
  }),
  stepContainer: css({
    position: 'relative',

    '& > div:nth-of-type(1)': {
      alignItems: 'center',
      backgroundColor: THEME.colors.black.main,
      borderRadius: '50%',
      color: THEME.font.colors.white,
      display: 'flex',
      fontSize: THEME.font.sizes.subordinate,
      fontWeight: 600,
      height: 24,
      justifyContent: 'center',
      left: -10,
      position: 'absolute',
      top: -10,
      width: 24,
      zIndex: 1,
    },

    '& > div:nth-of-type(2)': {
      marginBottom: 8,
    },

    '& > div:nth-of-type(3)': {
      display: 'flex',
      justifyContent: 'center',
    },
  }),
  switch: css({
    height: 18,
    width: 42,

    '& > .slider:before': {
      height: 12,
      left: -2,
      width: 12,
    },

    '& > .label-on': {
      fontSize: THEME.font.sizes.hint,
    },
  }),
};

export default DialogTikTokGrantPermission;
