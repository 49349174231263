import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Navigate } from 'react-router-dom';
import * as yup from 'yup';
import { css } from '@emotion/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { eventTypes, sendAmplitudeEvent } from '@src/amplitude';
import { ErrorMessage, Icomoon } from '@src/components/atoms';
import { BackNavigatorV2, ThemeButton } from '@src/components/molecules';
import { SelectV2, SentenceFormV2, TextFormV2 } from '@src/components/shared';
import { useConnectPromotionMethodsMutation } from '@src/graphql/hooks';
import { useAuthData, usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { mainBlack } from '@src/libs/palette';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import { dynamicTitleState, useRecoil } from '@src/recoilAtoms';
import { ROUTES } from '@src/shared/routes';
import { CampaignPromotionMethod, PromotionMobileAppType } from '@src/__generated__/globalTypes';

interface PromotionMethod {
  description?: string;
  email?: string;
  method: CampaignPromotionMethod;
  mobileAppType?: PromotionMobileAppType;
  url?: string;
}

const AddMethods = () => {
  const { limitedLogin } = useAuthData();
  if (limitedLogin) {
    return <Navigate to={ROUTES.MY_PAGE_SOCIAL_CONNECT} />;
  }

  const { setRecoilState } = useRecoil(dynamicTitleState);
  const { isMobileView, isYoutubeCmsPath } = usePageLayout();
  const { enqueueSnackbar, navigate, search, t } = useQueryHelper();
  const [connectPromotionMethods] = useConnectPromotionMethodsMutation();
  const searchParams = new URLSearchParams(search);
  const method = (searchParams.get('method') as CampaignPromotionMethod) || CampaignPromotionMethod.OFFLINE;
  const item = {
    method,
    ...(method === CampaignPromotionMethod.EMAIL_NEWSLETTER && { description: '', email: '' }),
    ...(method === CampaignPromotionMethod.MOBILE_APP && { mobileAppType: '' as PromotionMobileAppType, url: '' }),
    ...(method === CampaignPromotionMethod.OFFLINE && { description: '' }),
    ...(method === CampaignPromotionMethod.PODCAST && { description: '', url: '' }),
    ...(method === CampaignPromotionMethod.WEBSITE && { description: '', url: '' }),
  };
  const methods = [item];
  const mobileType = [
    { label: 'Android', value: PromotionMobileAppType.ANDROID },
    { label: 'Apple', value: PromotionMobileAppType.APPLE },
  ];
  const validationSchema = yup.object().shape({
    methods: yup.array().of(
      yup.object().shape({
        description: yup.string().when('method', {
          is: (type: CampaignPromotionMethod) =>
            [
              CampaignPromotionMethod.EMAIL_NEWSLETTER,
              CampaignPromotionMethod.OFFLINE,
              CampaignPromotionMethod.PODCAST,
              CampaignPromotionMethod.WEBSITE,
            ].includes(type),
          then: yup.string().required(),
        }),
        email: yup.string().when('method', {
          is: (type: CampaignPromotionMethod) => type === CampaignPromotionMethod.EMAIL_NEWSLETTER,
          then: yup.string().required(),
        }),
        mobileAppType: yup.string().when('method', {
          is: (type: CampaignPromotionMethod) => type === CampaignPromotionMethod.MOBILE_APP,
          then: yup.string().required(),
        }),
        url: yup.string().when('method', {
          is: (type: CampaignPromotionMethod) =>
            [
              CampaignPromotionMethod.MOBILE_APP,
              CampaignPromotionMethod.PODCAST,
              CampaignPromotionMethod.WEBSITE,
            ].includes(type),
          then: yup.string().required(),
        }),
      })
    ),
  });

  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    setValue,
    watch,
  } = useForm<{ methods: PromotionMethod[] }>({
    defaultValues: {
      methods,
    },
    resolver: yupResolver(validationSchema),
  });
  const isError = !!errors.methods;
  const { methods: promotionMethods } = watch();

  const { hint, removeButtonTitle, title } = (() => {
    switch (method) {
      case CampaignPromotionMethod.EMAIL_NEWSLETTER:
        return {
          hint: 'Please input your Email/Newsletter information and tell us what kind of Email/Newsletter you send',
          removeButtonTitle: 'Remove this Email/Newsletter',
          title: 'Email / Newsletter',
        };
      case CampaignPromotionMethod.MOBILE_APP:
        return {
          hint: `Please input your Mobile App's URL below`,
          removeButtonTitle: 'Remove this app',
          title: 'Mobile App',
        };
      case CampaignPromotionMethod.PODCAST:
        return {
          hint: `Please input your Podcast's URL and tell us what kind of Podcast you have`,
          removeButtonTitle: 'Remove this podcast',
          title: 'Podcast',
        };
      case CampaignPromotionMethod.WEBSITE:
        return {
          hint: 'Please input your Website URL and tell us what kind of Website you have',
          removeButtonTitle: 'Remove this website',
          title: 'Website',
        };
      default:
        return {
          hint: 'Please tell us what kind of offline method you will use',
          removeButtonTitle: 'Remove this Offline / Other',
          title: 'Offline / Other',
        };
    }
  })();

  useEffect(() => {
    setRecoilState({ interpolation: { method: title }, title: 'Add Method' });
  }, [title]);

  const onChangeSetValue = (index: number, key: keyof PromotionMethod, value: string) => {
    const items = [...promotionMethods];
    items[index][key] = value as never;
    setValue('methods', items);
  };

  const onClickAddMethod = () => {
    const items = [...promotionMethods];
    items.push(item);
    setValue('methods', items);
  };

  const onSubmit = async (value: { methods: PromotionMethod[] }) => {
    try {
      await connectPromotionMethods({
        variables: {
          input: {
            methods: value.methods,
          },
        },
      });
      sendAmplitudeEvent(eventTypes.addPromotionMethods);
      enqueueSnackbar(t('succeededInCreating'), { variant: 'success' });
      navigate(isYoutubeCmsPath ? ROUTES.YOUTUBE_CMS_MY_PAGE_SOCIAL_CONNECT : ROUTES.MY_PAGE_SOCIAL_CONNECT);
    } catch (err) {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    }
  };

  return (
    <form css={styles.container} onSubmit={handleSubmit(onSubmit)}>
      {!isMobileView && (
        <BackNavigatorV2
          actionItem={
            <div css={styles.actionContainer}>
              <ThemeButton customPalette={mainBlack} disabled={isSubmitting} text="Save" type="submit" />
            </div>
          }
          backPath={isYoutubeCmsPath ? ROUTES.YOUTUBE_CMS_MY_PAGE_SOCIAL_CONNECT : ROUTES.MY_PAGE_SOCIAL_CONNECT}
          skipTranslation
          title={t<string>('Add Method', { method: title })}
        />
      )}
      <div css={styles.contentContainer}>
        <div css={styles.description}>{t(`Annotation.${hint}`)}</div>

        {promotionMethods.map(({ description, email, mobileAppType, url }, index) => (
          <div css={styles.inputContainer} key={index}>
            {method === CampaignPromotionMethod.EMAIL_NEWSLETTER ? (
              <>
                <div>
                  <TextFormV2
                    error={isError && !email}
                    isRequired
                    placeholder="https://www.myprofile.com"
                    title="Email Address / Register page's URL"
                    value={email || ''}
                    onChange={e => onChangeSetValue(index, 'email', e.target.value)}
                  />
                  {isError && !email && <ErrorMessage message="requiredFieldMessage" />}
                </div>
                <div>
                  <SentenceFormV2
                    error={isError && !description}
                    isRequired
                    placeholder={t('Email / Newsletter Description')}
                    title="Description"
                    value={description || ''}
                    onChange={e => onChangeSetValue(index, 'description', e.target.value)}
                  />
                  {isError && !description && <ErrorMessage message="requiredFieldMessage" />}
                </div>
              </>
            ) : method === CampaignPromotionMethod.MOBILE_APP ? (
              <>
                <div>
                  <SelectV2
                    error={isError && !mobileAppType}
                    isRequired
                    options={mobileType}
                    title="Type"
                    value={mobileAppType || ''}
                    onChange={value => onChangeSetValue(index, 'mobileAppType', value)}
                  />
                  {isError && !mobileAppType && <ErrorMessage message="requiredFieldMessage" />}
                </div>
                <div>
                  <TextFormV2
                    error={isError && !url}
                    isRequired
                    placeholder="https//www.mobileapp.com"
                    title="App URL"
                    value={url || ''}
                    onChange={e => onChangeSetValue(index, 'url', e.target.value)}
                  />
                  {isError && !url && <ErrorMessage message="requiredFieldMessage" />}
                </div>
              </>
            ) : method === CampaignPromotionMethod.PODCAST ? (
              <>
                <div>
                  <TextFormV2
                    error={isError && !url}
                    isRequired
                    placeholder="https://www.myprofile.com"
                    title="Podcast URL"
                    value={url || ''}
                    onChange={e => onChangeSetValue(index, 'url', e.target.value)}
                  />
                  {isError && !url && <ErrorMessage message="requiredFieldMessage" />}
                </div>
                <div>
                  <SentenceFormV2
                    error={isError && !description}
                    isRequired
                    placeholder={t('Podcast Description')}
                    title="Description"
                    value={description || ''}
                    onChange={e => onChangeSetValue(index, 'description', e.target.value)}
                  />
                  {isError && !description && <ErrorMessage message="requiredFieldMessage" />}
                </div>
              </>
            ) : method === CampaignPromotionMethod.WEBSITE ? (
              <>
                <div>
                  <TextFormV2
                    error={isError && !url}
                    isRequired
                    placeholder="https://www.myprofile.com"
                    title="Website URL"
                    value={url || ''}
                    onChange={e => onChangeSetValue(index, 'url', e.target.value)}
                  />
                  {isError && !url && <ErrorMessage message="requiredFieldMessage" />}
                </div>
                <div>
                  <SentenceFormV2
                    error={isError && !description}
                    isRequired
                    placeholder={t('Website Description')}
                    title="Description"
                    value={description || ''}
                    onChange={e => onChangeSetValue(index, 'description', e.target.value)}
                  />
                  {isError && !description && <ErrorMessage message="requiredFieldMessage" />}
                </div>
              </>
            ) : (
              <div>
                <SentenceFormV2
                  error={isError && !description}
                  isRequired
                  placeholder={t('Offline / Others Description')}
                  title="Description"
                  value={description || ''}
                  onChange={e => onChangeSetValue(index, 'description', e.target.value)}
                />
                {isError && !description && <ErrorMessage message="requiredFieldMessage" />}
              </div>
            )}

            {promotionMethods.length > 1 && (
              <div
                css={styles.removeItemContainer}
                onClick={() => {
                  setValue('methods', [...promotionMethods.slice(0, index), ...promotionMethods.slice(index + 1)]);
                }}
              >
                <Icomoon icon="trash-outlined" size={16} />
                <div>{t(`Button.${removeButtonTitle}`)}</div>
              </div>
            )}
          </div>
        ))}

        <ThemeButton
          css={styles.addMethodBtn}
          prefixIcon={<Icomoon icon="plus" />}
          skipTranslation
          text={t<string>('Button.Add Another Method', { method: t(title) })}
          onClick={onClickAddMethod}
        />
      </div>

      {isMobileView && (
        <div css={styles.floatActionContainer}>
          <ThemeButton customPalette={mainBlack} disabled={isSubmitting} text="Save" type="submit" />
        </div>
      )}
    </form>
  );
};

const styles = {
  actionContainer: css({
    display: 'grid',
    justifyContent: 'flex-end',
    width: 'fill-available',

    '& > button': {
      borderRadius: 9,
      height: 40,
      width: 226,
    },
  }),
  addMethodBtn: css({
    borderRadius: 9,
    height: 48,

    '& span': {
      fontSize: THEME.font.sizes.subHeading,
      fontWeight: 600,
    },
  }),
  container: css({
    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      padding: '24px 16px',
    },
  }),
  contentContainer: css({
    display: 'grid',
    gap: THEME.box.gaps.l,
    padding: 24,
  }),
  description: css({
    color: THEME.font.colors.black.main,
    fontSize: THEME.font.sizes.normal,
  }),
  floatActionContainer: css({
    background: THEME.colors.white,
    bottom: 0,
    boxShadow: THEME.box.shadows.outer,
    padding: '16px 12px 32px 12px',
    position: 'fixed',
    width: 'fill-available',

    '& > button': {
      borderRadius: 9,
      height: 56,
    },
  }),
  inputContainer: css({
    background: THEME.colors.white,
    borderRadius: 15,
    boxShadow: THEME.box.shadows.outer,
    display: 'grid',
    gap: THEME.box.gaps.xxl,
    padding: '24px 16px',
  }),
  removeItemContainer: css({
    alignItems: 'center',
    color: THEME.font.colors.gray.main,
    cursor: 'pointer',
    display: 'flex',
    fontSize: THEME.font.sizes.normal,
    gap: THEME.box.gaps.xs,
    justifySelf: 'flex-end',
  }),
};

export default AddMethods;
