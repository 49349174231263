import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { Icomoon, FormStatusIndicator, List } from '@src/components/atoms';
import { ListLoading, Pager, SliderTableComponents, SliderTableStyledComponents } from '@src/components/molecules';
import Dialog, { smallButtonStyles } from '@src/components/molecules/Dialog';
import { useGetFormsCountQuery, useGetFormsListQuery, useRemoveFormsMutation } from '@src/graphql/hooks';
import { LIMIT } from '@src/libs/constant';
import { useDirLayout, usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { getCurrentPage, getOffset } from '@src/components/molecules/Pager/helpers';
import { THEME } from '@src/libs/theme';
import { generatePath, ROUTES } from '@src/shared/routes';
import { FormListSortableField, Order } from '@src/__generated__/globalTypes';
import EmptyList from './EmptyList';
import { FilterItems } from './FormListFilter';

interface FormListProps {
  filter: FilterItems;
}

const FormList = ({ filter }: FormListProps) => {
  const [checkedIds, setCheckedIds] = useState<number[]>([]);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const { enqueueSnackbar, t } = useQueryHelper();
  const { isMobileView } = usePageLayout();
  const { isRtl } = useDirLayout();
  const currentPage = getCurrentPage();

  const { data, loading } = useGetFormsListQuery({
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        keyword: filter.keyword,
        limit: LIMIT,
        offset: getOffset(currentPage),
        orderBy: { field: FormListSortableField.UPDATED, order: Order.DESC },
        ...(filter.status && { status: filter.status }),
      },
    },
  });
  const { data: dataFormsCount } = useGetFormsCountQuery({
    variables: {
      input: {
        keyword: filter.keyword,
        ...(filter.status && { status: filter.status }),
      },
    },
  });
  const [removeForms] = useRemoveFormsMutation({
    refetchQueries: ['GetFormsList'],
    onCompleted: () => {
      setCheckedIds([]);
      setDialogOpen(false);
      enqueueSnackbar(t('succeededInDeleting'), { variant: 'success' });
    },
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  useEffect(() => {
    if (isMobileView) {
      setCheckedIds([]);
      setDialogOpen(false);
    }
  }, [isMobileView]);

  const onClickAllChecked = (status: boolean) => {
    const items = [...checkedIds];
    formsList.forEach(form => {
      const index = items.findIndex(checkedId => checkedId === form.id);
      if (status && index >= 0) {
        items.splice(index, 1);
      } else if (!status && index < 0) {
        items.push(form.id);
      }
    });
    setCheckedIds(items);
  };

  const onClickCheck = (id: number) => {
    const items = [...checkedIds];
    const index = items.findIndex(checkedId => checkedId === id);
    if (index >= 0) {
      items.splice(index, 1);
    } else {
      items.push(id);
    }
    setCheckedIds(items);
  };

  const onDelete = () => {
    removeForms({
      variables: {
        input: {
          formIds: checkedIds,
        },
      },
    });
  };

  const checkedIdsLength = checkedIds.length;
  const formsList = data?.getFormsList?.forms || [];
  const isEmptyForms = !formsList.length;
  const pageFirstIndex = LIMIT * (currentPage - 1) + 1;
  const pageLastIndex = pageFirstIndex + formsList.length - 1;
  const totalCount = dataFormsCount?.getFormsCount?.total || 0;
  const totalPages = totalCount < LIMIT ? 1 : Math.ceil(totalCount / LIMIT);

  const getCheckedItemStatus = () => {
    let isAllCheckedStatus = checkedIds.length > 0;
    let isItemSelectedStatus = false;

    formsList.every(email => {
      if (!checkedIds.includes(email.id)) {
        isAllCheckedStatus = false;
      } else {
        isItemSelectedStatus = true;
      }

      if (!isAllCheckedStatus && isItemSelectedStatus) {
        return false;
      }

      return true;
    });

    return {
      isAllChecked: isAllCheckedStatus,
      isItemSelected: isItemSelectedStatus,
    };
  };
  const { isAllChecked, isItemSelected } = getCheckedItemStatus();

  if (loading) {
    return <ListLoading />;
  }

  return (
    <>
      <Dialog
        cancelButtonProps={{
          styles: smallButtonStyles,
          onClick: () => setDialogOpen(false),
        }}
        contentStyles={styles.dialog}
        nextButtonProps={{
          styles: smallButtonStyles,
          text: 'Delete',
          theme: 'blue',
          onClick: onDelete,
        }}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      >
        <div css={styles.dialogContent}>
          <div>{t('Are you sure you want to permanently delete this?')}</div>
          <div>{t('Annotation.You might lost response data forever Before continuing, export your responses')}</div>
          <div>{t('Annotation.Do you want to delete this question and lost responses')}</div>
        </div>
      </Dialog>
      {checkedIdsLength > 0 && (
        <div css={styles.deleteActionContainer}>
          <div>{t('Selected', { count: checkedIdsLength })}</div>
          <div>
            <div onClick={() => setDialogOpen(true)}>
              <Icomoon icon="trash-outlined" />
            </div>
          </div>
        </div>
      )}
      <SliderTableComponents.Wrapper css={styles.container}>
        <SliderTableComponents.Container>
          <SliderTableComponents.SliderSection>
            <SliderTableComponents.SliderTable>
              <thead css={styles.tableHeader}>
                <tr>
                  {!isMobileView && (
                    <List.HeaderCheckboxColumn
                      checked={isAllChecked || isItemSelected}
                      css={isRtl ? { padding: '0 16px 0 0' } : { padding: '0 0 0 16px' }}
                      indeterminate={!isAllChecked && isItemSelected}
                      onChange={checked => onClickAllChecked(!checked)}
                    />
                  )}
                  <List.HeaderColumn title="Name" css={{ width: isMobileView ? '70%' : '50%', paddingLeft: '8px' }} />
                  <List.HeaderColumn title="Status" />
                  {!isMobileView && (
                    <>
                      <List.HeaderColumn title="Number of Submissions" />
                      <List.HeaderColumn title="Last Update" />
                    </>
                  )}
                </tr>
              </thead>
              {!isEmptyForms && (
                <tbody>
                  {formsList.map(form => {
                    const { id, lastUpdated, status, submissionCount, title } = form;
                    const isChecked = checkedIds.includes(id);

                    return (
                      <SliderTableStyledComponents.StyledRowNew css={isChecked ? styles.activeTableRow : {}} key={id}>
                        {!isMobileView && (
                          <List.HeaderCheckboxColumn
                            checked={isChecked}
                            css={styles.columnCheckbox}
                            onChange={() => onClickCheck(id)}
                          />
                        )}
                        <List.TextLinkColumn
                          css={styles.linkColumn}
                          data={title}
                          href={generatePath(ROUTES.MY_PAGE_FORMS_EDIT_ENTRY, { id })}
                        />
                        <List.TextColumn data={<FormStatusIndicator status={status} />} />
                        {!isMobileView && (
                          <>
                            <List.TextColumn data={`${submissionCount.toString()} ${t('Submissions')}`} />
                            <List.TextColumn data={format(new Date(lastUpdated + 'Z'), 'dd/MM/yyyy hh:mm')} />
                          </>
                        )}
                      </SliderTableStyledComponents.StyledRowNew>
                    );
                  })}
                </tbody>
              )}
            </SliderTableComponents.SliderTable>
          </SliderTableComponents.SliderSection>
        </SliderTableComponents.Container>
      </SliderTableComponents.Wrapper>
      {isEmptyForms && <EmptyList />}
      <Pager
        currentPage={currentPage}
        totalPages={totalPages}
        totalCount={totalCount}
        first={pageFirstIndex}
        last={pageLastIndex}
      />
    </>
  );
};

const styles = {
  activeTableRow: css({
    backgroundColor: '#fffde7',

    '& > td': {
      backgroundColor: '#fffde7',
    },
  }),
  columnCheckbox: css({
    borderTop: '1px solid #efefef',
    padding: '0 0 0 16px',

    '[dir="rtl"] &': {
      padding: '0 16px 0 0',
    },
  }),
  container: css({
    border: 'none',
    boxShadow: THEME.box.shadows.outer,
    margin: 0,
    padding: 0,
  }),
  deleteActionContainer: css({
    alignItems: 'center',
    backgroundColor: THEME.colors.black.main,
    display: 'flex',
    flexWrap: 'wrap',
    height: 48,
    padding: '8px 16px',

    '& > div:nth-of-type(1)': {
      display: 'flex',
      color: THEME.font.colors.white,
      flexBasis: '50%',
      fontSize: THEME.font.sizes.normal,
    },

    '& > div:nth-of-type(2)': {
      display: 'flex',
      flexBasis: '50%',
      justifyContent: 'flex-end',

      '& > div': {
        alignItems: 'center',
        backgroundColor: THEME.colors.white,
        borderRadius: 2,
        cursor: 'pointer',
        display: 'flex',
        height: 32,
        justifyContent: 'center',
        width: 32,
      },
    },
  }),
  dialog: {
    borderRadius: '5px',
    boxShadow: THEME.box.shadows.outer,
    maxWidth: 648,
    padding: 0,
    width: '90%',
  },
  dialogContent: css({
    padding: '0 8px',

    '& > div:nth-of-type(1)': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.heading,
      fontWeight: 600,
      marginBottom: 24,
    },

    '& > div:nth-of-type(2)': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.normal,
      marginBottom: 16,
    },

    '& > div:nth-of-type(3)': {
      color: THEME.font.colors.error,
      fontSize: THEME.font.sizes.normal,
    },
  }),
  linkColumn: css({
    '& a': {
      color: THEME.font.colors.blue.main,
      fontSize: THEME.font.sizes.normal,
      fontWeight: 600,
    },
  }),
  tableHeader: css({
    backgroundColor: '#f6f8fa',
    borderBottom: '1px solid #dfe7ec',
    borderTop: '1px solid #dfe7ec',
  }),
};

export default FormList;
