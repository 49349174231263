import React, { useEffect } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { css } from '@emotion/react';
import { useYoutubeCmsVideoAudienceQuery, useYoutubeCmsVideoStatsQuery } from '@src/graphql/hooks';
import { Icomoon, Image, YoutubeCmsServerError } from '@src/components/atoms';
import { ListLoading } from '@src/components/molecules';
import AgeBreakdown from '@src/components/organisms/AnalyticsShared/AudienceChartTemplate/AgeBreakdown';
import AreaBreakdown from '@src/components/organisms/AnalyticsShared/AudienceChartTemplate/AreaBreakdown';
import GenderBreakdown from '@src/components/organisms/AnalyticsShared/AudienceChartTemplate/GenderBreakdown';
import { Toolbar, YearMonthPicker } from '@src/components/shared';
import { CURRENCY_SYMBOLS } from '@src/libs/constant';
import { ErrorTypes } from '@src/libs/error';
import { formatDuration, formatNumberWithCommas } from '@src/libs/format';
import { getFirstDayOfTheMonth } from '@src/libs/functions';
import { useFilter, usePageLayout, useQueryHelper, useUrl } from '@src/libs/hooks';
import { getSocialMediaUrl } from '@src/libs/socialMedia';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import { generatePath, ROUTES } from '@src/shared/routes';
import { SocialAccountType } from '@src/__generated__/globalTypes';
import { Types } from '../helpers';

const Details = () => {
  const { isMobileView } = usePageLayout();
  const {
    params: { videoId },
    t,
  } = useQueryHelper();
  const { searchParams } = useUrl();
  const { filter, setFilter } = useFilter({
    month: searchParams.get('month') || '',
  });
  const backPath = generatePath(ROUTES.YOUTUBE_CMS_REVENUE, undefined, {
    month: filter.month,
    p: 1,
    type: Types.VIDEO,
  });
  const confirmedRevenue = searchParams.get('confirmedRevenue');
  const currency = searchParams.get('currency') || '';
  const estimatedRevenue = searchParams.get('estimatedRevenue');
  const currencySymbol = CURRENCY_SYMBOLS[currency] || '';
  const rpm = searchParams.get('rpm');

  const { data, error, loading } = useYoutubeCmsVideoAudienceQuery({
    skip: !filter.month,
    variables: {
      month: filter.month,
      videoId: videoId as string,
    },
  });
  const {
    data: dataStats,
    error: errorStats,
    loading: loadingStats,
  } = useYoutubeCmsVideoStatsQuery({
    skip: !filter.month,
    variables: {
      month: filter.month,
      videoId: videoId as string,
    },
  });

  useEffect(() => {
    if (!filter.month) {
      setFilter({ ...filter, month: getFirstDayOfTheMonth() });
    }
  }, [filter.month]);

  const audience = data?.youtubeCmsVideoAudience;
  const stats = dataStats?.youtubeCmsVideoStats;
  const engagement = [
    { title: 'RPM', value: rpm ? `${currencySymbol}${formatNumberWithCommas(Number(rpm), undefined, currency)}` : '-' },
    { title: 'Total Views', value: stats?.views ? formatNumberWithCommas(stats.views, 0) : '-' },
    { title: 'Avg view duration', value: stats?.avgViewDuration ? formatDuration(stats.avgViewDuration) : '-' },
    {
      title: 'Avg Percentage viewed',
      value: stats?.avgPercentageView ? `${stats.avgPercentageView.toFixed(2)}%` : '-',
    },
  ];
  const isServerError = [error?.message, errorStats?.message].includes(ErrorTypes.DATA_TIMEOUT);

  if (
    filter.month &&
    !loading &&
    !loadingStats &&
    (!data?.youtubeCmsVideoAudience || !dataStats?.youtubeCmsVideoStats) &&
    !isServerError
  ) {
    return <Navigate to={backPath} />;
  }

  return (
    <div css={{ display: 'grid', gap: THEME.box.gaps.xl }}>
      <Toolbar backPath={backPath} hasNotification title="Video Detail" />
      <div css={styles.contentContainer}>
        <div css={styles.pickerContainer}>
          <YearMonthPicker
            disabledDates={{ after: new Date() }}
            value={filter.month || ''}
            onChange={month => setFilter({ ...filter, month })}
          />
        </div>
        {loading || loadingStats ? (
          <ListLoading hideBorder />
        ) : isServerError ? (
          <div css={styles.serverErrorContainer}>
            <YoutubeCmsServerError pageTitle="Dashboard" />
          </div>
        ) : (
          <>
            <div css={styles.infoContainer}>
              <div className="info">
                <Image
                  css={{ borderRadius: THEME.box.borderRadius.m }}
                  height={isMobileView ? '114px' : '170px'}
                  objectFit="cover"
                  src={stats?.videoThumbnail}
                  width={isMobileView ? '64px' : '96px'}
                />
                <div>
                  <div className="title-container">
                    <label className="title">{stats?.videoTitle}</label>
                    <div className="asset-title">
                      <Icomoon icon="music-note-single-bar" size={12} />
                      <label>{stats?.assetTitle}</label>
                    </div>
                  </div>
                  <Link
                    className="check-video-button"
                    target="_blank"
                    to={`${getSocialMediaUrl(SocialAccountType.YOUTUBE)}/watch?v=${stats?.id}` || ''}
                  >
                    <div>{t('Button.Check Video')}</div>
                    <Icomoon color={THEME.colors.blue.main} icon="share-arrow-top-right" />
                  </Link>
                </div>
              </div>

              <div css={styles.engagementContainer}>
                <div className="revenue">
                  <label className="title">{t(confirmedRevenue ? 'Confirmed Revenue' : 'Est Revenue')}</label>
                  <label className="value">{`${currencySymbol}${formatNumberWithCommas(
                    Number(confirmedRevenue ? confirmedRevenue : estimatedRevenue),
                    undefined,
                    currency
                  )}`}</label>
                </div>
                <div className="engagement">
                  {engagement.map(({ title, value }) => (
                    <div key={title}>
                      <label className="title">{t(title)}</label>
                      <label className="value">{value}</label>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div css={styles.audienceContainer}>
              <div className="title">
                <Icomoon icon="user-grey" size={22} />
                <label>{t('Audience (Followers)')}</label>
              </div>
              <GenderBreakdown className="gender-breakdown" {...audience?.genderRates} />
              <AgeBreakdown className="age-breakdown" {...audience?.ageGenderRates} />
              <AreaBreakdown
                className="area-breakdown"
                followerCountryRate={audience?.countryRates || []}
                followerRegionRate={[]}
                hasCountriesRates
                hideSelector
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const styles = {
  audienceContainer: css({
    background: THEME.colors.white,
    display: 'grid',
    gap: 32,
    padding: '24px 16px',

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      padding: 24,
    },

    '& > .title': {
      alignItems: 'center',
      color: THEME.font.colors.black.main,
      display: 'flex',
      fontSize: THEME.font.sizes.subHeading,
      fontWeight: 600,
      gap: THEME.box.gaps.s,
    },

    '& > .gender-breakdown': {
      '& > div:nth-of-type(1)': {
        fontSize: THEME.font.sizes.normal,
        padding: 0,
      },
    },

    '& > .age-breakdown': {
      display: 'grid',

      '& > div:nth-of-type(1)': {
        fontSize: THEME.font.sizes.normal,
        padding: 0,
      },

      '& > div:nth-of-type(2)': {
        justifySelf: 'center',
        maxWidth: 350,
        width: '100%',
      },
    },

    '& > .area-breakdown': {
      display: 'grid',

      '& > div:nth-of-type(1)': {
        fontSize: THEME.font.sizes.normal,
        padding: 0,
      },

      '& > div:nth-of-type(2)': {
        justifySelf: 'center',
        maxWidth: 350,
        width: '100%',
      },

      '& a': {
        bottom: 0,
      },
    },
  }),
  contentContainer: css({
    display: 'grid',
    gap: 1,
    justifySelf: 'center',
    maxWidth: 614,
    overflow: 'hidden',
    width: '100%',

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      border: THEME.box.borders.gray.main,
      borderRadius: THEME.box.borderRadius.l,
    },
  }),
  engagementContainer: css({
    background: THEME.colors.gray.dee5ec,
    border: THEME.box.borders.gray.main,
    borderRadius: THEME.box.borderRadius.l,
    display: 'grid',
    gap: 1,
    overflow: 'hidden',

    '& > .revenue': {
      background: THEME.colors.white,
      color: THEME.font.colors.black.main,
      display: 'grid',
      gap: THEME.box.gaps.xs,
      padding: 16,

      '& > .title': {
        fontSize: 11,
      },

      '& > .value': {
        fontSize: THEME.font.sizes.title,
        fontWeight: 600,
      },
    },

    '& > .engagement': {
      background: THEME.colors.white,
      display: 'grid',
      gap: THEME.box.gaps.l,
      gridTemplateColumns: 'repeat(2, 1fr)',
      padding: 16,

      '& > div': {
        borderLeft: THEME.box.borders.gray.main,
        display: 'grid',
        color: THEME.font.colors.black.main,
        gap: THEME.box.gaps.xs,
        padding: '0 12px',

        '& > .title': {
          fontSize: 11,
        },

        '& > .value': {
          fontSize: THEME.font.sizes.subHeading,
          fontWeight: 600,
        },
      },
    },
  }),
  infoContainer: css({
    background: THEME.colors.white,
    display: 'grid',
    gap: THEME.box.gaps.l,
    padding: '24px 16px',

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      padding: 16,
    },

    '& > .info': {
      display: 'flex',
      gap: THEME.box.gaps.s,

      '& > div:nth-of-type(2)': {
        display: 'grid',
        width: 'fill-available',

        '& > .title-container': {
          display: 'grid',
          gap: THEME.box.gaps.xs,
          height: 'min-content',

          '& > .title': {
            color: THEME.font.colors.black.main,
            fontSize: THEME.font.sizes.subHeading,
          },

          '& > .asset-title': {
            alignItems: 'center',
            color: THEME.font.colors.gray.main,
            display: 'flex',
            fontSize: THEME.font.sizes.normal,
            gap: THEME.box.gaps.xs,
          },
        },

        '& > .check-video-button': {
          alignItems: 'center',
          alignSelf: 'flex-end',
          color: THEME.font.colors.blue.main,
          cursor: 'pointer',
          display: 'flex',
          fontSize: THEME.font.sizes.subHeading,
          gap: THEME.box.gaps.xs,

          [`@media (max-width: ${ViewportType.TABLET}px)`]: {
            justifySelf: 'flex-end',
          },
        },
      },
    },
  }),
  pickerContainer: css({
    background: THEME.colors.white,
    padding: '12px 16px',

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      padding: 24,
    },
  }),
  serverErrorContainer: css({
    alignItems: 'center',
    display: 'grid',
    height: 'calc(100vh - 168px)',
    justifyContent: 'center',

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      border: THEME.box.borders.gray.main,
      borderRadius: THEME.box.borderRadius.l,
      height: 400,
    },
  }),
};

export default Details;
